/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import query from 'query-string';
import _ from 'lodash';

import {
  deleteQuickbooksToken,
  getQuickbooksToken,
  getQuickBooksURI,
  setQuickbooksToken,
  getQuickbooksStatus,
  getChargezoomStatus,
  getDavoStatus,
  getNextSignature
} from '../../actions/accountProfileActions';

import { getIntegrations } from '../../actions/integrationsActions';

import {
  setModalVisibility,
  togglePlanListDialog,
  toggleShowGiftCardProgIntegration,
} from '../../actions/userExperienceActions';
import {
  getMerchantSettings,
  partialUpdateSettings
} from '../../actions/merchantSettingsActions';
import {
  disableGiftCardProvider,
  enableGiftCardProvider
} from '../../actions/giftCardActions';
import { getReputationSettings } from '../../actions/reputationActions'


import IconUtils from '../util/IconUtil';
import UserUtil from '../util/UserUtil';
import LabelUtil from '../util/LabelUtil';

import FilterPanel from '../shared/FilterPanel';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import MessageDialog from '../shared/MessageDialog';
import Image from '../shared/Image'

import giftCardPrograms from '../../constants/giftCardPrograms';
import actionTypes from '../../constants/actionTypes';
import routes from '../../constants/routes';

import Chargezoom from '../Chargezoom';
import DavoIntegrationDetails from './DavoIntegrationDetails';
import QuickBooksButton from './../QuickBooksButton';
import YelpIntegrationDetails from './YelpIntegrationDetails';
import NavIntegrationDetails from './NavIntegrationDetails';
import NextIntegrationDetails from './NextIntegrationDetails';
import Page from '../shared/Page';
import {integrationStyles, featureIntegrationStyles} from '../../jss/integrationStyles';
import Modal from '../shared/Modal';
import PlanList from './plans/PlanList';
import planTypes from '../../constants/planTypes';
import ManageIntegrationsAccessory
  from '../appBar/accessories/ManageIntegrationsAccessory';
import ManageIntegrations from './ManageIntegrations';

export default class Integrations extends Component {

  constructor(props) {

    super(props);

    const { t } = props;

    this.tags = React.createRef();
    this.tags.current = {
      davo: [
        t('IntegrationsPage.Tags.Accounting'),
        t('IntegrationsPage.Tags.Tax')
      ],
      quickBooks: [t('IntegrationsPage.Tags.Accounting')],
      homebase: [
        t('IntegrationsPage.Tags.Partnership'),
        t('IntegrationsPage.Tags.Payroll'),
        t('IntegrationsPage.Tags.Scheduling'),
        t('IntegrationsPage.Tags.Staff'),
      ],
      yelpCard: [t('IntegrationsPage.Tags.BusinessListing')],
      navCard: [t('IntegrationsPage.Tags.BusinessFunding')],
      nextCard: [t('IntegrationsPage.Tags.Insurance')],
      googleReputation: [
        t('IntegrationsPage.Tags.Partnership'),
        t('IntegrationsPage.Tags.Reputation'),
        t('IntegrationsPage.Tags.Reviews'),
      ]
    }
    this.state = {
      giftCards: [],
      gc_type: null,
      factor4Data: null,
      tags: this.tags.current,
      davoIntegrationPanelOpen: false,
      tccData: null,
      giftCardToShow: {
        type: '',
        logo: '',
        title: '',
        price: {
          pricePerLocation: '',
          priceDetails: {
            text: '',
            url: ''
          }
        },
        description: '',
        contactPhone: ''
      },
      showGiftCardSubscriptionDialog: false,
      shouldShowChargezoomDetails: false,
      yelpIntegrationPanelOpen: false,
      navIntegrationPanelOpen: false,
      nextIntegrationPanelOpen: false
    };

    this.handleDisconnectQuickBooks = this.handleDisconnectQuickBooks.bind(this);
    this.showGiftCardDetailsIntegration = this.showGiftCardDetailsIntegration.bind(this);
    this.checkTokens = this.checkTokens.bind(this);
    this.getQuickBooksURI = this.getQuickBooksURI.bind(this);
    this.setGiftCardDetails = this.setGiftCardDetails.bind(this);
    this.getGiftCardDetails = this.getGiftCardDetails.bind(this);
    this.enableDisableGiftCardProvider = this.enableDisableGiftCardProvider.bind(this);
    this.hideSubscriptionDialog = this.hideSubscriptionDialog.bind(this);
    this.toggleShouldShowChargezoomDetails = this.toggleShouldShowChargezoomDetails.bind(this);
    this.openHomebaseURI = this.openHomebaseURI.bind(this);
    this.tagsContainer = this.tagsContainer.bind(this);
    this.collapsedTagsContainer = this.collapsedTagsContainer.bind(this);
    this.toggleDavoIntegrationPanel = this.toggleDavoIntegrationPanel.bind(this);
    this.toggleNavIntegrationPanel = this.toggleNavIntegrationPanel.bind(this);
    this.handleClosePlanListDialog = this.handleClosePlanListDialog.bind(this);
    this.handleOpenPlanListDialog = this.handleOpenPlanListDialog.bind(this);
    this.handleDavoPanel = this.handleDavoPanel.bind(this);
    this.redirectToReputation = this.redirectToReputation.bind(this);
    this.setTagsHelper = this.setTagsHelper.bind(this);

    this.debouncedSetTagsHelper = _.throttle(this.setTagsHelper.bind(this), 400);

    const createCardsRefs = () => ({
      davo: React.createRef(),
      quickBooks: React.createRef(),
      homebase: React.createRef(),
      yelpCard: React.createRef(),
      navCard: React.createRef(),
      nextCard: React.createRef(),
      googleReputation:  React.createRef(),
    });

    this.cardRefs = createCardsRefs();
    this.tagsRef = createCardsRefs();
  }

  loadData(props) {

    const {
      user,
      userExperience,
    } = props;

    const isPremiumAccount = UserUtil.isPremiumAccount(user);
    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusWithLoyaltyAccount = UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const shouldLoadReputation = isPremiumAccount || isPremiumPlusAccount || isPremiumPlusWithLoyaltyAccount;

    props.dispatch(getQuickbooksStatus(user));
    props.dispatch(getChargezoomStatus(user));
    props.dispatch(getNextSignature(user));
    props.dispatch(getIntegrations(user));

    if (shouldLoadReputation) {
      props.dispatch(getReputationSettings(user, userExperience.selectedDate.dateRange));
    }

    props.dispatch(getMerchantSettings(user));

    props.dispatch(getQuickbooksToken(user)).then(this.checkTokens);
  }

  componentDidMount() {

    const { location, user } = this.props;

    this.loadData(this.props);

    const isPA = UserUtil.userType(user) === 'PA';
    const params = query.parse(location.search);

    if (isPA && params?.davo === 'true') {
      this.handleDavoPanel();
    }

    window.addEventListener('resize', this.debouncedSetTagsHelper);
    this.setTags(this.state);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedSetTagsHelper);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {merchantSettings, user, dispatch} = this.props;

    const merchantSettingsFetching = merchantSettings.isProcessing !== prevProps.merchantSettings.isProcessing;

    if (!merchantSettings.isProcessing && merchantSettingsFetching && !_.isNull(merchantSettings.merchantSettings)) {
      merchantSettings.merchantSettings.davo_enabled && dispatch(getDavoStatus(user));
      this.setGiftCardDetails();
    }

    this.setTags(prevState);

  }

  handleClosePlanListDialog () {
    this.props.dispatch(togglePlanListDialog(false));
  }

  handleOpenPlanListDialog() {
    this.props.dispatch(togglePlanListDialog(true));
  }

  setTagsHelper() {
    this.setState({ tags: this.tags.current }, () => {
      this.setTags(this.state);
    });
  }

  setTags(state) {
    const prevTags = { tags: { ...state.tags }};
    let newTagsState = _.cloneDeep(prevTags);

    const cardRefs = this.cardRefs;
    const tagsRef = this.tagsRef;

    Object.keys(this.cardRefs).map((cardType) => {
      const cardElement = cardRefs[cardType].current;
      let tagContainerElement = tagsRef[cardType].current;

      if (!cardElement || !tagContainerElement) return;

      const {
        top: cardTop,
        bottom: cardBottom,
        left: cardLeft,
        right: cardRight
      } = cardElement.getBoundingClientRect();

      const tagElements = [...tagContainerElement.childNodes].filter(node => node.id !== 'outsideTags');
      const outsideTagElements = tagElements.filter((tag) => {
        const {
          top: tagTop,
          bottom: tagBottom,
          left: tagLeft,
          right: tagRight
        } = tag.getBoundingClientRect();

        const extraPadding = 7;
        return (
          tagTop < cardTop ||
          tagRight > cardRight ||
          tagBottom + extraPadding > cardBottom ||
          tagLeft < cardLeft
        );
      }).map(tag => tag.id);

      if (!outsideTagElements.length) return

      let tags = tagElements.filter((tag) => {
        return !outsideTagElements.includes(tag.id);
      }).map(tag => tag.id);

      // move one chip from tags into outsideTags, this in order to create space for the collapsed chip
      const outsideTags = tags.length > 0 ? [...outsideTagElements, tags[tags.length - 1]] : [...outsideTagElements];
      tags = tags.slice(0, -1); // remove last element

      newTagsState.tags[cardType] = {
        tags,
        outsideTags,
      };

    });

    if (JSON.stringify(newTagsState) !== JSON.stringify(prevTags)) {
      this.setState(newTagsState);
    }
  }

  showGiftCardDetailsIntegration() {
    const { userExperience, dispatch } = this.props;
    dispatch(toggleShowGiftCardProgIntegration(!userExperience.showGiftCardProgIntegration));
  }

  toggleShouldShowChargezoomDetails() {
    this.setState({ shouldShowChargezoomDetails: !this.state.shouldShowChargezoomDetails });
  }

  checkTokens(quickbooksTokenRequest) {
    const qb = quickbooksData;
    if (quickbooksTokenRequest?.response?.is_integrated === false) {
      if (qb && qb.access_token && qb.refresh_token && qb.realm_id) {
        this.props.dispatch(setQuickbooksToken(this.props.user, qb));
      }
    }
  }

  setGiftCardDetails() {
    const { merchantSettings } = this.props;
    const gc_type = merchantSettings?.merchantSettings?.gc_type;
    const primaryColor = LabelUtil.getLabel().primaryColor;
    const Launch = IconUtils.getIcon('Launch', primaryColor);
    const Connect = IconUtils.getIcon('Connect', primaryColor);
    const factor4 = giftCardPrograms.factor4;
    const tcc = giftCardPrograms.tcc;

    this.setState({
      gc_type,
      giftCards: [
        {
          ...factor4.mainDetails,
          price: factor4.price,
          details: [
            {
              onClick: this.enableDisableGiftCardProvider,
              text: `${gc_type === giftCardPrograms.gcTypeStates.factor4 ? this.props.t(factor4.unsubscribe) : gc_type === giftCardPrograms.gcTypeStates.root + '_' + giftCardPrograms.gcTypeStates.factor4 ? giftCardPrograms.pending : this.props.t(factor4.subscribe)}`,
              disabled: gc_type === giftCardPrograms.gcTypeStates.root + '_' + giftCardPrograms.gcTypeStates.factor4,
              icon: Connect
            },
            {
              icon: Launch,
              text: this.props.t(factor4.launch.text),
              href: factor4.launch.href
            },
          ],
        }
      ]
    });
  }

  enableDisableGiftCardProvider() {
    const { gc_type } = this.state;
    const { type } = this.state.giftCardToShow;
    let body, provider;
    const { user, dispatch } = this.props;

    provider = !!gc_type && gc_type.toLowerCase() === type ? 'none' : type;
    body = {
      gift_card_provider: provider,
      generic_gc_enabled: `'${provider !== 'none'}'`
    };
    dispatch(
      provider === 'none' ?
        disableGiftCardProvider(user, body) :
        enableGiftCardProvider(user, body)
    ).then(res => {
      if (res?.type !== actionTypes.giftCardEnableSuccess && res?.type !== actionTypes.giftCardDisableSuccess) {
        this.setState({ showGiftCardSubscriptionDialog: true });
      }
    })
      .finally(() => {
        this.showGiftCardDetailsIntegration();
        dispatch(getMerchantSettings(user)).then((settings) => {
          const gcType = settings?.response?.merchant_settings?.gc_type && settings.response.merchant_settings.gc_type.toUpperCase();
          const giftCardSettings = {
            merchant_settings: {
              gc_type: (!gcType || gcType === 'PENDING_NONE') ? 'NONE' : gcType,
              generic_gc_enabled: body.generic_gc_enabled
            }
          };
          dispatch(partialUpdateSettings(giftCardSettings, user)).finally(() => this.setGiftCardDetails());
        });
      });
  }

  getGiftCardDetails(type) {
    const { user } = this.props;
    if (!type) {
      return;
    }

    const isFactor4 = type === 'factor4';

    if (isFactor4 && UserUtil.accountCurrentPlan(user) === planTypes.free) {
      this.handleOpenPlanListDialog();
    } else {
      const state = this.state.giftCards.filter(giftCard => giftCard.type === type)[0];
      this.setState({ giftCardToShow: { ...state } });
      this.showGiftCardDetailsIntegration();
    }
  }

  getQuickBooksURI() {
    return this.props.dispatch(getQuickBooksURI());
  }

  handleDisconnectQuickBooks() {
    const { user, dispatch } = this.props;
    dispatch(deleteQuickbooksToken(user));
  }

  hideSubscriptionDialog() {
    this.setState({ showGiftCardSubscriptionDialog: false });
  }

  openHomebaseURI() {
    window.open('https://joinhomebase.com/payanywhere/', '_blank', 'noopener, noreferrer');
  }

  toggleDavoIntegrationPanel() {
    this.setState({ davoIntegrationPanelOpen: !this.state.davoIntegrationPanelOpen });
  }

  handleDavoPanel() {
    const { user } = this.props;

    if (UserUtil.accountCurrentPlan(user) !== planTypes.free) {
      this.toggleDavoIntegrationPanel();
    } else {
      this.handleOpenPlanListDialog();
    }
  }

  closeDrawer = ()=>  {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  toggleYelpIntegrationPanel = () => this.setState(prevState => ({
    yelpIntegrationPanelOpen: !prevState.yelpIntegrationPanelOpen
  }));

  toggleNextIntegrationPanel = () => this.setState(prevState => ({
    nextIntegrationPanelOpen: !prevState.nextIntegrationPanelOpen
  }))

  yelpReferralAction = () => {
    const { user, dispatch } = this.props;
    const yelpReferralPayload = {
      merchant_settings: {
        yelp_clicked: true
      }
    };
    dispatch(partialUpdateSettings(yelpReferralPayload, user));
    window.open('https://biz.yelp.com/signup?promo=NABANCARD300&utm_source=nab&utm_medium=partner&utm_campaign=nab_partnership', '_blank', 'noopener, noreferrer');
  }

  toggleNavIntegrationPanel = () => this.setState(prevState => ({
    navIntegrationPanelOpen: !prevState.navIntegrationPanelOpen
  }));

  navReferralAction = () => {
    const { user, dispatch } = this.props;
    const navReferralPayload = {
      merchant_settings: {
        nav_clicked: true
      }
    };
    dispatch(partialUpdateSettings(navReferralPayload, user));
    window.open('https://www.nav.com/north/', '_blank', 'noopener, noreferrer');
  };

  nextReferralAction = () => {
   const { user, dispatch } = this.props;
    const nextReferralPayload = {
      merchant_settings: {
        next_clicked: true
      }
    };
    dispatch(partialUpdateSettings(nextReferralPayload, user));
    this.setState({ nextIntegrationPanelOpen: false });
  };

  tagsContainer(tags) {
    return (
      <div className={'tagsContainer'}>
        {tags.map(tag => (
          <Chip
              className={'tag'}
              key={tag}
              label={tag}
          />
        ))}
      </div>
    );
  }

  collapsedTagsContainer(allTags = [], type) {

    if (Array.isArray(allTags)) {
      return allTags.length > 0 ? (
        <div className={'tagsContainer'}
            ref={this.tagsRef[type]}
        >
          {allTags.map((tag) =>(
            <Chip
                className={'tag'}
                id={tag}
                key={tag}
                label={tag}
            />
          ))}
        </div>
      ) : null;

    }

    const { outsideTags = [], tags = [] } = allTags;
    return (
      <div className={'tagsContainer'}
          ref={this.tagsRef[type]}
      >
        {tags.map((tag) =>(
          <Chip
              className={'tag'}
              id={tag}
              key={tag}
              label={tag}
          />
        ))}
        {
          outsideTags.length > 0 && (
            <div id='outsideTags'>
              <Chip
                  className={'tag'}
                  label={`+${outsideTags.length}`}
              />
              <div className='outsideTagTooltip'>
                {outsideTags.map((tag) => (
                  <Chip
                      className={'outsideTag'}
                      id={tag}
                      key={tag}
                      label={tag}
                  />
                ))}
              </div>
            </div>
          )
        }
      </div>
    );
  }

  redirectToReputation() {
    const {history} = this.props;
    const appRoutePrefix = globalApplicationLabel.path;
    history.push(`${appRoutePrefix}${routes.business.root}${routes.business.reputation}`);
  }

  render() {

    const {
      accountProfile,
      auth,
      dispatch,
      merchantSettings,
      reputationManagement,
      t,
      user,
      userExperience,
      integrations: { data: isvData },
    } = this.props;
    const loading = accountProfile.quickbooksFetching ||
      merchantSettings.isProcessing ||
      reputationManagement.isFetching ||
      accountProfile.isChargezoomFetching ||
      accountProfile.isDavoFetching ||
      accountProfile.isNextFetching ||
      userExperience.isFetching;

    const pageAccessories = [
      {
        name: 'manageIntegrations',
        accessory: <ManageIntegrationsAccessory t={t} dispatch={dispatch}/>,
        showInMobile: true,
        hide: isvData.length === 0
      },
    ];

    if (loading) {
      return (
        <Page
          loading
          title={t('Business Settings')}
        />
      )
    }

    const actionButtonDisabled = auth.isSpr;
    const linkClasses = actionButtonDisabled ? ' linkLikeDisabled' : ' linkLike';

    const isPA = UserUtil.userType(user) === 'PA';
    const isDAVOEnabled = merchantSettings?.merchantSettings?.davo_enabled;
    const isDAVOConnected = accountProfile.davo?.is_integrated;

    const gc_type = this.state.gc_type ? this.state.gc_type.toLowerCase() : '';
    const showGiftCardDetails = userExperience.showGiftCardProgIntegration;

    const isChargezoomIntegrated = accountProfile.chargezoom?.is_integrated;
    const isYelpClicked = merchantSettings?.merchantSettings?.yelp_clicked;
    const isNavClicked = merchantSettings?.merchantSettings?.nav_clicked;
    const isNextClicked = !loading && merchantSettings?.merchantSettings?.next_clicked;
    const filterPanel = (
      <FilterPanel
          {...this.props}
          filterData={this.props.settingsMenuItems}
          selectFilterCallback={this.props.handleMenuSelection}
      />
    )

    const isQuickBooksConnected = (!_.isNull(accountProfile.quickbooks) && !_.isUndefined(accountProfile.quickbooks) && accountProfile.quickbooks.is_integrated === true);

    const isReputationManagementEnabled = (!_.isNull(reputationManagement.googleAccount) && !_.isUndefined(reputationManagement.googleAccount) && !_.isEmpty(reputationManagement.googleAccount.client_id));

    const disconnectQuickBooks = (
      <div className='actionButtonContainer'>
        <div className={'disconnectQuickBooks' + linkClasses}
            onClick={!actionButtonDisabled ? this.handleDisconnectQuickBooks : null}
        >{t('Disconnect')}</div>
      </div>
    );

    const planListDialog = (
      <Modal
          centerSubtitle
          centerTitle
          className={'planListDialog'}
          floatingCloseButton
          hideCancelButton
          hideConfirmButton
          isClosableIconEnable
          maxWidth='lg'
          onClose={this.handleClosePlanListDialog}
          open={userExperience.openPlanListDialog}
          subtitle={t('IntegrationsPage.planListDialog.subtitle')}
          title={t('IntegrationsPage.planListDialog.title')}
      >
        <PlanList {...this.props} />
      </Modal>
    );

    const DAVOCard = isDAVOEnabled && isPA && (
      <Box
          className={'card'}
          id={'davo'}
          onClick={this.handleDavoPanel}
          ref={this.cardRefs['davo']}
      >
        <img
            alt={'DAVO logo'}
            className={'logo'}
            src={`${serverDomainUrl}images/davo/logo.png`}
        />
        <p>{t('IntegrationsPage.Davo.Card.Text')}</p>
        {this.collapsedTagsContainer(this.state.tags.davo, 'davo')}
        {isDAVOConnected && (
          <div className={'connected'}>
            <div className={'iconWrapper'}>
              {IconUtils.getIcon('CheckIcon', 'white')}
            </div>
          </div>
        )}

      </Box>
    );

    const quickBooks = isPA ? (
      <Box
          className={'card noClick'}
          id={'quickBooks'}
          ref={this.cardRefs['quickBooks']}
      >
        <img
            alt={'Quickbooks logo'}
            className={'logo'}
            src={`${serverDomainUrl}images/quickbooks/logo.png`}
        />
        <p>{t('IntegrationsPage.IntuitQuickBooksOnline.SubText')}</p>
        <div className='integrationButton'>
          {(isQuickBooksConnected) ? disconnectQuickBooks :
            <QuickBooksButton
                disabled={actionButtonDisabled}
                getQuickBooksURI={this.getQuickBooksURI}
            />}
        </div>
        {this.collapsedTagsContainer(this.state.tags.quickBooks, 'quickBooks')}
        {isQuickBooksConnected && (
          <div className={'connected'}>
            <div className={'iconWrapper'}>
              {IconUtils.getIcon('CheckIcon', 'white')}
            </div>
          </div>
        )}
      </Box>
    ) : null;

    const homebase =  (
      <Box
          className={'card'}
          id={'homebase'}
          onClick={this.openHomebaseURI}
          ref={this.cardRefs['homebase']}
      >
        <div className={'title'}>
          <img
              alt={'Homebase logo'}
              className={'logo'}
              src={`${serverDomainUrl}images/homebase/logo.png`}
          />
          {IconUtils.getIcon('LaunchIcon', LabelUtil.getLabelColor())}
        </div>
        <p>{t('IntegrationsPage.Homebase.SubText')}</p>
        {this.collapsedTagsContainer(this.state.tags.homebase,  'homebase')}
      </Box>
    );

    const chargezoomCard = (
      <Box
          className={'featuredCard'}
          id={'chargeZoomCard'}
          onClick={this.toggleShouldShowChargezoomDetails}
          ref={this.cardRefs['chargeZoomCard']}
      >
        <img
            alt={'header'}
            className={'cardHeader'}
            src={`${serverDomainUrl}images/chargezoom/header.png`}
        />
        <div className={'content'}>
          <div className={'logoContainer'}>
            <img
                alt={'Chargezoom'}
                className={'logo'}
                src={`${serverDomainUrl}images/chargezoom/logo.png`}
            />
          </div>
          <p>{t('IntegrationsPage.Chargezoom.Card.Text')}</p>
          {this.tagsContainer([t('IntegrationsPage.Tags.Accounting')])}
          {isChargezoomIntegrated && (
            <div className={'connected'}>
              <div className={'iconWrapper'}>
                {IconUtils.getIcon('CheckIcon', 'white')}
              </div>
            </div>
          )}
        </div>
      </Box>
    );

    const YelpCard = isPA && (
      <Box
          className={'card'}
          id={'YelpCard'}
          onClick={this.toggleYelpIntegrationPanel}
          ref={this.cardRefs['yelpCard']}
      >
        <Image
            alt={'Yelp logo'}
            src={'/images/yelp/yelpCardLogo.png'}
            sx={{ width: '77px' }}
        />
        <p>{t('IntegrationsPage.Yelp.Card.Text')}</p>
        {this.collapsedTagsContainer(this.state.tags.yelpCard, 'yelpCard')}
          </Box>
    );

    const NextCard = isPA && (
      <Box
          className={'card'}
          id={'NextCard'}
          onClick={this.toggleNextIntegrationPanel}
          ref={this.cardRefs['nextCard']}
      >
        <Image
            alt={'Next logo'}
            src={'/images/next/nextCardLogo.png'}
            sx={{ width: '77px' }}
        />
        <p>{t('IntegrationsPage.Next.Card.Text')}</p>
        {this.collapsedTagsContainer(this.state.tags.nextCard, 'nextCard')}
        {isNextClicked && (
          <div className={'connected'}>
            <div className={'iconWrapper'}>
              {IconUtils.getIcon('CheckIcon', 'white')}
            </div>
          </div>
        )}

      </Box>
    );

    const navCard = isPA && (
      <Box
          className={'card'}
          id={'navCard'}
          onClick={this.toggleNavIntegrationPanel}
          ref={this.cardRefs['navCard']}
      >
        <Image
            alt={'NAV logo'}
            src={'/images/nav/navLogo.png'}
            sx={{ width: '77px' }}
        />
        <p>{t('IntegrationsPage.Nav.Card.Text')}</p>
        {this.collapsedTagsContainer(this.state.tags.navCard, 'navCard')}
      </Box>
    );

    const googleReputation = (
      <Box
          className={'card noClick googleReputation'}
          ref={this.cardRefs['googleReputation']}
      >
        <div className={'title'}>
          <img
              alt={'Reputation logo'}
              className={'logo'}
              src={`${serverDomainUrl}images/reputation/reputationIcon.png`}
          />
          <h4
              className={'text'}
              onClick={this.redirectToReputation}
          >{t('ReputationScreen.ReputationManagement')}</h4>
        </div>
        <p>{t('ReputationScreen.Description')}</p>
        {this.collapsedTagsContainer(this.state.tags.googleReputation, 'googleReputation')}
        {isReputationManagementEnabled && (
          <div className={'connected'}>
            <div className={'iconWrapper'}>
              {IconUtils.getIcon('CheckIcon', 'white')}
            </div>
          </div>
        )}
      </Box>
    );

    const { contactPhone } = this.state.giftCardToShow;

    const giftCardInnerDetails = (
      <div>
        <h3>
          {t('Details')}
        </h3>
        <p>{t('Factor4.Details')}</p>
        <ol>
          <li
              className='giftCardDetail'
          >{t('IntegrationsPage.GiftCardInnerDetails.Detail1')}</li>
          <li className='giftCardDetail'>
            <Trans i18nKey={'IntegrationsPage.GiftCardInnerDetails.Detail2'}>
              {'Once subscribed, Factor4 will reach out within 1-3 business days to complete the setup process. If you prefer, you can contact Factor4 directly at '}<a
                  className='linkLike'
                  href='mailto:sales@factor4gift.com'
                  target='_blank'
                                                                                                                                                                     >{'sales@factor4gift.com'}</a>{' or '}{{ contactPhone }}{'.'}
            </Trans>
          </li>
          <li
              className='giftCardDetail'
          >{t('IntegrationsPage.GiftCardInnerDetails.Detail3')}</li>
        </ol>
      </div>
    );

    const giftCardProgDetails = (showGiftCardDetails &&
      <DetailPanel
          heading={t(this.state.giftCardToShow.title)}
          logo={this.state.giftCardToShow.logo}
          onCloseClick={this.showGiftCardDetailsIntegration}
          shouldDisplay={userExperience.showGiftCardProgIntegration}
          subheading={t(this.state.giftCardToShow.description)}
      >

        {/* // TODO Uncomment this when price details are comming from API */}
        {/* {this.state.giftCardToShow?.price && (
            <div className='giftCardPrice'>
              <p>
                {this.state.giftCardToShow.price.pricePerLocation}
              </p>
              <a href={this.state.giftCardToShow.price.priceDetails.url} target='blank'>
                {this.state.giftCardToShow.price.priceDetails.text}
              </a>
            </div>
          )} */}

        {( this.state?.giftCardToShow?.details &&
          <DetailPanelOptions options={this.state.giftCardToShow.details}/>)}
        {giftCardInnerDetails}
      </DetailPanel>
    );

    const DAVODetail = isDAVOEnabled && (
      <DetailPanel
          onCloseClick={this.handleDavoPanel}
          open={this.state.davoIntegrationPanelOpen}
          shouldDisplay
      >
        <DavoIntegrationDetails
            accountProfile={accountProfile}
            dispatch={dispatch}
            merchantSettings={merchantSettings}
            t={t}
            user={user}
        />
      </DetailPanel>
    );

    const chargezoomDetails = this.state.shouldShowChargezoomDetails ? (
      <DetailPanel
          logo={`${serverDomainUrl}images/chargezoom/logo.png`}
          onCloseClick={this.toggleShouldShowChargezoomDetails}
          shouldDisplay={this.state.shouldShowChargezoomDetails}
          subheading={t('IntegrationsPage.Chargezoom.Card.Text')}
      >
        <Chargezoom {...this.props}
            closeDetails={this.toggleShouldShowChargezoomDetails}
        />
      </DetailPanel>
    ) : null;

    const YelpDetails = this.state.yelpIntegrationPanelOpen && (
      <DetailPanel
          onCloseClick={this.toggleYelpIntegrationPanel}
          open={this.state.yelpIntegrationPanelOpen}
          shouldDisplay
      >
        <YelpIntegrationDetails
            buttonAction={this.yelpReferralAction}
            isYelpClicked={isYelpClicked}
            t={t}
        />
      </DetailPanel>
    );

    const NextDetails = !loading && this.state.nextIntegrationPanelOpen && (
      <DetailPanel
          onCloseClick={this.toggleNextIntegrationPanel}
          open={this.state.nextIntegrationPanelOpen}
          shouldDisplay
      >
        <NextIntegrationDetails
            accountProfile={accountProfile}
            buttonAction={this.nextReferralAction}
            dispatch={dispatch}
            isNextClicked={isNextClicked}
            t={t}
            user={user}
        />
      </DetailPanel>
    );

    const navDetails = this.state.navIntegrationPanelOpen && (
      <DetailPanel
          onCloseClick={this.toggleNavIntegrationPanel}
          open={this.state.navIntegrationPanelOpen}
          shouldDisplay
      >
        <NavIntegrationDetails
            connectAction={this.navReferralAction}
            isConnected={isNavClicked}
            t={t}
        />
      </DetailPanel>
    );

    const integrationsHeader = isPA && (
      <Box className={'featuredIntegrations'}
          sx={featureIntegrationStyles}
      >
        <div className={'featuredHeader'}>
          <h3>{t('IntegrationsPage.FeaturedIntegrations')}</h3>
          <p className={'subText'}>{t('IntegrationsPage.SubText')}</p>
        </div>
        <div className={'cardContainer'}>
          {isDAVOEnabled && (
            <div
                className={'featuredCard'}
                onClick={this.handleDavoPanel}
            >
              <img
                  alt={'header'}
                  className={'cardHeader'}
                  src={`${serverDomainUrl}images/davo/header.png`}
              />
              <div className={'content'}>
                <img
                    alt={'DAVO logo'}
                    className={'logo'}
                    src={`${serverDomainUrl}images/davo/logo.png`}
                />
                <p>{t('IntegrationsPage.Davo.Card.Text')}</p>
                {this.tagsContainer([
                  t('IntegrationsPage.Tags.Accounting'),
                  t('IntegrationsPage.Tags.Tax'),
                ])}
                {isDAVOConnected && (
                  <div className={'connected'}>
                    <div className={'iconWrapper'}>
                      {IconUtils.getIcon('CheckIcon', 'white')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {chargezoomCard}

        </div>
      </Box>
    );

    const gifCardProgramList = this.state.giftCards.map((giftCard, i) => {

        const isGiftCardEnrolled = gc_type === giftCard?.type;

        return (
          <Box
              className={'card'}
              id={'giftCard'}
              key={giftCard.type}
              onClick={() => this.getGiftCardDetails(giftCard.type)}
          >
            <img
                alt={'Factor4 logo'}
                className={'logo'}
                src={`${serverDomainUrl}images/giftCards/factor4/logo_no_tagline.png`}
            />
            <p>{t('Factor4.Description')}</p>
            {this.tagsContainer([t('IntegrationsPage.Tags.GiftCard')])}
            {isGiftCardEnrolled && (
              <div className={'connected'}>
                <div className={'iconWrapper'}>
                  {IconUtils.getIcon('CheckIcon', 'white')}
                </div>
              </div>
            )}
          </Box>
        );
      }
    );

    const subscriptionBodyText = (
      <p className={'giftCardSubscriptionText'}>
        {`${t('IntegrationsPage.GiftCardSubscriptionText')} `}
        <a className={'linkLike'}
            href={'tel:877.464.4218'}
        >{'877.464.4218.'}</a>
      </p>
    );

    const giftCardSubscriptionDialog = (this.state.showGiftCardSubscriptionDialog &&
      <MessageDialog
          bodyText={subscriptionBodyText}
          confirmText={t('Ok')}
          dialogActionsClassName={'giftCardSubscriptionActions'}
          dialogBodyClassName={'giftCardSubscriptionDialog'}
          hideCloseIcon
          isChoiceRequired={false}
          onConfirm={this.hideSubscriptionDialog}
          open
      />);

    return (
      <div className='integrationsPage pageWrap'>
        <Drawer
          open={userExperience.modalVisibility.manageIntegrations}
          anchor='right'
          onClose={this.closeDrawer}>
          <ManageIntegrations data={isvData} t={t} closeDrawer={this.closeDrawer}/>
        </Drawer>
        <Page
            initialSubpage={t('Integrations')}
            loading={loading}
            accessories={pageAccessories}
            title={t('Business Settings')}
        >
          <div className='flexContainer flexContainerResponsiveLayout'>
          {filterPanel}
          <div className='businessSettingsSubsection integrationsHolder'>

            {integrationsHeader}

            <Box sx={integrationStyles}>
              <h3>{t('IntegrationsPage.AllIntegrations')}</h3>
              <Grid container
                  spacing={2}
              >
                {
                  YelpCard &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                  {YelpCard}
                  </Grid>
                }
                {
                  DAVOCard &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {DAVOCard}
                  </Grid >
                }
                {
                  NextCard &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {NextCard}
                  </Grid>
                }
                {
                  quickBooks &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                   {quickBooks}
                  </Grid>
                }
                {
                  gifCardProgramList &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {gifCardProgramList}
                  </Grid>
                }
                {
                  homebase &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {homebase}
                  </Grid>
                }
                {
                  googleReputation &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {googleReputation}
                  </Grid>
                }
                {
                  navCard &&
                  <Grid item
                      lg={4}
                      xs={12}
                  >
                    {navCard}
                  </Grid>
                }
              </Grid>
            </Box>

            <Box className='accountIntegrations'
                marginBottom={7}
            >
              {chargezoomDetails}
              {giftCardProgDetails}
              {giftCardSubscriptionDialog}
              {DAVODetail}
              {YelpDetails}
              {navDetails}
              {NextDetails}
            </Box>
          </div>
        </div>
          {planListDialog}
        </Page>
      </div>
    );
  }
}

Integrations.defaultProps = {};
