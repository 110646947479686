/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Modal from '../shared/Modal';

/**
 * Shows a simple message dialog with cancel and confirm buttons.
 * Parameters:
 * bodyText: Text for the body
 * cancelDisabled: Disables the cancel button
 * cancelText: Text for the cancel button. If it's not present then the cancel button will not be rendered.
 * confirmIcon: Optional icon for the confirm button
 * confirmLabelPosition: Optional, indicates if the text should go before or after the icon for the confirm button
 * confirmText: Text for the confirm button
 * disabled: Disables the confirm button
 * externalClassName: A class added to the parent component for the dialog
 * isChoiceRequired: Boolean, indicates if a choice (cancel or confirm) is required to close the dialog. Otherwise it can be closed by clicking outside the dialog.
 * onConfirm: Function called on confirm
 * onRequestClose: Function called when the dialog is closed by any means. It will receive as a parameter:
 *   - An event object if user selected either cancel or confirm. The value can be easily determined checking event.target to see
 *     which button was clicked.
 *   - Boolean false if the user closed the dialog by clicking outside the dialog.
 * open: Boolean indicating whether the dialog is open.
 * scrollable
 * titleText: Text for the title
 */
const MessageDialog = ({
  bodyText,
  cancelDisabled,
  cancelText,
  confirmIcon,
  confirmText,
  dialogBodyClassName,
  disabled = false,
  externalClassName = '',
  isChoiceRequired,
  onConfirm,
  onRequestClose,
  open,
  titleText,
  closeAction,
  hideCloseIcon = false,
  hideConfirmButton,
  sx = null,
}) => {

  const dialogActions = !hideConfirmButton ? [
    <Button
      onClick={onConfirm}
      label={confirmText}
      endIcon={confirmIcon}
      disabled={disabled}
    />
  ] : [];
  if (cancelText) {
    dialogActions.unshift(<Button variant='text' onClick={onRequestClose} label={cancelText} disabled={cancelDisabled} />);
  }

  return (
    <Modal
      title={titleText}
      open={open}
      confirmText={confirmText}
      onClose={onRequestClose}
      onConfirm={onConfirm}
      onCloseIcon={closeAction}
      actionsClassName='dialogActions'
      contentClassName={`dialogBody ${dialogBodyClassName}`}
      externalClassName={`messageDialog ${externalClassName}`}
      actions={dialogActions}
      sx={sx}
      isChoiceRequired={isChoiceRequired}
      isClosableIconEnable={!hideCloseIcon}
    >
      {bodyText}
    </Modal>
  );

};

MessageDialog.propTypes = {
  bodyText: PropTypes.node.isRequired,
  cancelDisabled: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmIcon: PropTypes.element,
  confirmLabelPosition: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  dialogBodyClassName: PropTypes.string,
  dialogActionsClassName: PropTypes.string,
  disabled: PropTypes.bool,
  externalClassName: PropTypes.string,
  isChoiceRequired: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  scrollable: PropTypes.bool,
  titleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  hideCloseIcon: PropTypes.bool,
  hideConfirmButton: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ])
};

export default MessageDialog;
