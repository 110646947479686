/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  getFormValues,
  change
} from 'redux-form';
import numeral from 'numeral';
import moment from 'moment';
import FormatTextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import FormUtil from '../util/FormUtil';
import {
  setSubmitButtonEnabled,
  setViewOrderSummary,
  togglePaymentMethodsEditDialog
} from '../../actions/userExperienceActions';
import { setInvoiceForPaymentTip } from '../../actions/invoicesActions';
import VisibilityToggle from '../visibilityToggle';
import Button from '../shared/Button';
import TextField from '../shared/TextField';
import CardPresentUtil from '../util/CardPresentUtil';
import ApplePayButton from '../ApplePayButton';
import BrowserUtil from '../util/BrowserUtil';
import { roundToTwoDecimals } from '../util/CommonUtil';
import {checkIsSpanish} from '../../locales/i18n';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Modal from '../shared/Modal';
import Select from '../shared/Select';
import { MenuItem } from '@mui/material';
import PaymentMethodSummary from '../shared/enhancedInvoices/PaymentMethodSummary';
import {
  highTransactionLimitAmount
} from '../../constants/applicationConstants';
import paymentTypes from '../../constants/paymentTypes';

const validate = (values) => {
  return Validator.validateOnlinePayments(values);
};

export class OnlinePaymentFormComponent extends Component {

  constructor(props) {
    super(props);

    this.viewOrderSummary = this.viewOrderSummary.bind(this);
    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.cardPresentInputRequest = this.cardPresentInputRequest.bind(this);
    this.validateApplePay = this.validateApplePay.bind(this);
    this.state = {
      cardVisibility: false,
      cardPresentUtil: new CardPresentUtil(this.props.dispatch)
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(change('payNowInvoiceForm', 'selectedPaymentMethod', this.getDefaultPaymentMethod()));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let enableSubmit = FormUtil.shouldEnableSubmit(nextProps);
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
    if (this.props.currentValues.tip_amount !== nextProps.currentValues.tip_amount) {
      this.props.dispatch(setInvoiceForPaymentTip(nextProps.currentValues.tip_amount));
    }
  }

  getDefaultPaymentMethod = () => {
    const { customers } = this.props;
    let selectedPaymentMethod = null;

    if (customers?.customerPaymentData?.payment_methods?.length) {
      const paymentMethods = customers.customerPaymentData.payment_methods;

      const defaultPaymentMethod = paymentMethods.find((p) => p.is_default);
      if (defaultPaymentMethod) {
        selectedPaymentMethod = defaultPaymentMethod;
      } else {
        selectedPaymentMethod = paymentMethods[0];
      }
    }

    return selectedPaymentMethod;
  };

  openEditPaymentDialog = () => {
    const { dispatch } = this.props;

    dispatch(togglePaymentMethodsEditDialog(true));
  };

  closeEditPaymentDialog = () => {
    const { dispatch } = this.props;

    dispatch(togglePaymentMethodsEditDialog(false));
  };

  handlePaymentTypeChange = (paymentChangeEvent) => {
    const { dispatch } = this.props;
    const paymentChangeType = paymentChangeEvent.target.value;

    switch (paymentChangeType) {
      case 'new':
        dispatch(change('payNowInvoiceForm', 'selectedPaymentMethod', null));
        break;
      case 'saved':
        dispatch(change('payNowInvoiceForm', 'selectedPaymentMethod', this.getDefaultPaymentMethod()));
        break;
      default:
        break;
    }
  };

  handleEditPaymentChange = (paymentChangeEvent) => {
    const { customers, dispatch } = this.props;
    const paymentMethods = customers?.customerPaymentData?.payment_methods;

    const paymentChangeType = paymentChangeEvent.target.value;
    const selectedPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod.id === paymentChangeType);

    dispatch(change('payNowInvoiceForm', 'selectedPaymentMethod', selectedPaymentMethod));
  };

  viewOrderSummary() {
    this.props.dispatch(setViewOrderSummary(!this.props.userExperience.viewOrderSummary));
  }

  handleCardPresentAction(cardPresentEvent, form, action) {

    const { cardPresent, handleSubmit, currentValues } = this.props;
    action(cardPresentEvent, currentValues, validate, handleSubmit, cardPresent.state || {}.state || null);
  }

  cardPresentInputRequest(cardPresentState) {
    return this.state.cardPresentUtil.cardPresentInputRequest(cardPresentState, this.props.submitting);
  }

  toggleCardNumberVisibility() {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  async validateApplePay() {
    const { dispatch, currentValues ,openHighAmountModal} = this.props;
    const tipAmount = !!currentValues?.tip_amount;
    const totalAmount = numeral(currentValues.total_amt).value();
    let isValid = totalAmount <= highTransactionLimitAmount;
    if(tipAmount && (totalAmount + numeral(currentValues?.tip_amount).value()) > highTransactionLimitAmount){
      isValid = false;
      openHighAmountModal();
    }
    return Promise.resolve(isValid);
  }

  render() {

    const {
      handleSubmit, submitting, handleSubmitApplePay, invoices,
      initialValues, virtualTerminal, currentValues, cardPresent,
      t, loyalty, isPremiumPlusLoyalty, userExperience, customers
    } = this.props;

    const paymentMethods = customers?.customerPaymentData?.payment_methods;
    const selectedPaymentMethod = currentValues.selectedPaymentMethod;

    const isSpanishLanguage = checkIsSpanish() ? 'spanishTranslation' : '';
    const labelColor = LabelUtil.getLabel().primaryColor;

    let paidText = null;
    let thankYouText = t('OnlinePaymentForm.ThankYouText');
    const loyaltyIcon = IconUtils.getIcon('LoyaltyProgramIcon', labelColor);
    const loyaltyStatus = loyalty;
    let hasEarned = false;

      if (loyaltyStatus && loyaltyStatus?.points_earned ) {
          hasEarned = loyaltyStatus.points_to_earn_reward === loyaltyStatus.points_earned;
          if (loyaltyStatus.reward_amount_type === 'dollar') {
            loyaltyStatus.dollar = '$';
            loyaltyStatus.percent = '';
          } else {
            loyaltyStatus.percent = '%';
            loyaltyStatus.dollar = '';
          }
          loyaltyStatus.points_label = (loyaltyStatus.points_name === 'points') ? t('LoyaltyPrograms.Points') : loyaltyStatus.points_name;
          loyaltyStatus.program_label = (loyaltyStatus.program_name === 'Loyalty Program') ? t('LoyaltyPrograms.TitleSingular') : loyaltyStatus.program_name;
      }

    const isSafari = BrowserUtil.isSafari();

    if (initialValues.paid_date) {
      paidText = t('OnlinePaymentForm.PaidWithDate', { date: moment(initialValues.paid_date).format(t('OnlinePaymentForm.PaidDateFormat')) });
      thankYouText = t('OnlinePaymentForm.ThankYouText');
    }

    if (initialValues.pre_auth_validated || (initialValues.is_pre_auth && this.props.paymentCompleted)) {
      const status = initialValues.pre_auth_completed ?
        t('OnlinePaymentForm.CompletedSaleId', {saleId: initialValues.credit_card_sale_id}) :
        t('OnlinePaymentForm.Processed');

      paidText = this.props.entityId ? t('OnlinePaymentForm.PreAuthorized') + this.props.entityId : null;
      thankYouText = t('OnlinePaymentForm.PreAuthorization') + status;
    }

    const taxAmount = numeral(roundToTwoDecimals(initialValues.tax_amt)).value();

    //Rounding again after subtracting discount to avoid JS math bugs
    const subTotalAmount = numeral(roundToTwoDecimals(initialValues.sub_total_amt)).value();
    const totalAmount = numeral(roundToTwoDecimals(initialValues.total_amt)).value(); ////Invoice total, doesnt include tips
    const tipAmount = numeral(currentValues?.tip_amount).value();

    const applePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      shippingMethods: [],
      lineItems: [
        {
          label: t('OnlinePaymentForm.AppleInvoice', { invoice: this.props.initialValues.invoice }),
          amount: subTotalAmount.toString(),
          type: 'final'
        },
        {
          label: t('OnlinePaymentForm.AppleTax'),
          amount: taxAmount.toString(),
          type: 'final'
        }
      ],
      total: {
        label: t('OnlinePaymentForm.AppleTotal'),
        amount: totalAmount.toString(),
        type: 'final'
      },
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      requiredBillingContactFields: ['postalAddress']
    };

    if (tipAmount > 0) {
      applePayPaymentRequest.lineItems.push({
        label: t('OnlinePaymentForm.AppleTip'),
        amount: tipAmount.toString(),
        type: 'final'
      });
      applePayPaymentRequest.total.amount = roundToTwoDecimals(totalAmount + tipAmount).toString();
    }

    const applePayEnabled = !_.isUndefined(initialValues.apple_pay_enabled) ?
      initialValues.apple_pay_enabled : true;

    const applePayButton = isSafari && applePayEnabled ?
      (<div className='applePayContainer'>
        <ApplePayButton
          {...this.props}
          tipAmount = {tipAmount}
          paymentRequest={applePayPaymentRequest}
          paymentData={invoices.invoiceForPayment}
          paymentType={paymentTypes.payNow}
          handlePaymentProcessing={handleSubmitApplePay}
          validate={this.validateApplePay}
        />
      </div>) : null;

    const transactionAmountText = initialValues.is_pre_auth ?
      t('OnlinePaymentForm.PreAuthorizationDue') :
      t('OnlinePaymentForm.Due');

    const amountErrorMessageValues = {
      limit: numeral(highTransactionLimitAmount).format('$0,0.00')
    };

    const formHasCdigits = virtualTerminal.cardType && currentValues &&
      currentValues.cdigits && currentValues.cdigits.length > 0;

    const cardReaderActionButtons = cardPresent?.activityActions ?
      Object.keys(cardPresent.activityActions).map((key, index) => {
        const onClick = (e) => this.handleCardPresentAction(e, this, cardPresent.activityActions[key]);
        return (<div key={index}><a onClick={onClick}>{t(key)}</a></div>);
      }) : '';

    const editPaymentModal = (
      <Modal
        title={t('EditPaymentMethod')}
        open={userExperience.editPaymentMethodDialog}
        contentClassName='paymentMethodSelectDialog'
        maxWidth='md'
        onConfirm={this.closeEditPaymentDialog}
        hideCancelButton={true}
      >
        <div>
          <p className='contentTitle'>{t('ReplaceExistingPaymentMethodInvoice')}</p>
          <div>
            <Select
              label={t('PaymentMethod')}
              value={selectedPaymentMethod?.id || this.getDefaultPaymentMethod()?.id}
              onChange={this.handleEditPaymentChange}
            >
              {paymentMethods?.map((cc, index) =>
                <MenuItem value={cc.id} key={`paymentMethod${index}`}>
                  <PaymentMethodSummary
                    backgroundColor='transparent'
                    paymentMethod={cc}
                    showDefault={true}
                    showEditButton={false}
                    t={t}
                  />
                </MenuItem>)
              }
            </Select>
          </div>
        </div>
      </Modal>
    );

    const paymentContainer = cardPresent?.isUsing ? (
      <div className='cardReaderStatusWell'>
        <div className='cardReaderStatusIcon'><i></i></div>
        <div className={cardPresent.activityDetails ? 'cardReaderStatusInfo' : 'cardReaderStatusInfo noSublabel'}>
          <h2 className='cardReaderStatusTitle'>
            {t(cardPresent.activityTitle)}
          </h2>
          <h3 className='cardReaderStatusSubtitle'>
            {t(this.cardPresentInputRequest(cardPresent))}
          </h3>
        </div>
        <div className='cardReaderStatusAction'>
          {cardReaderActionButtons}
        </div>
      </div>
    ) : (
      <div className='paymentInformation'>

        {applePayButton}
        <div className='formItems'>

          {initialValues?.is_tip_enabled && !initialValues.is_pre_auth && (
            <div className='flex'>
              <Field
                component={TextField}
                name='tip_amount'
                label={t('OnlinePaymentForm.Field.TipAmountLabel')}
                maxLength='20'
                hintText={t('OnlinePaymentForm.Field.TipAmountHint')}
                normalize={FormatTextUtil.formatCurrencyWithMaxDigit}
                className='halfToFullScreen textField tip_amount'
                errorMessageValue={amountErrorMessageValues}
              />
            </div>
          )}

          { paymentMethods?.length > 0 ?
            <div className='flex'>
              <Select
                className='halfToFullScreen'
                label={t('PaymentMethod')}
                value={selectedPaymentMethod?.id ? 'saved' : 'new'}
                onChange={this.handlePaymentTypeChange}
              >
                <MenuItem value='new'>
                  {t('EnterCardManually')}
                </MenuItem>
                <MenuItem value='saved'>
                  {t('UseSavedPaymentMethod')}
                </MenuItem>
              </Select>
            </div> : null
          }

          { !!selectedPaymentMethod ?
            <div className='toPay'>
              <h4>{t('InvoiceDetail.SavedPaymentDetails')}</h4>
              <PaymentMethodSummary
                paymentMethod={selectedPaymentMethod}
                onClick={this.openEditPaymentDialog}
                showEditButton={!invoices?.isProcessing}
                showDefault={true}
                isTipEnabled={true}
                t={t}
              />
            </div> :
            <>
              <div className='flex'>
                <div className='cardContainerWithVisibilityToggle halfToFullScreen'>
                  <Field
                    className={`cardNumber textField ${this.state.cardVisibility ? 'visible' : ''} ${isSpanishLanguage}`}
                    component={TextField}
                    disabled={submitting}
                    label=' '
                    maxLength='25'
                    name='cdigits'
                    id='cdigits'
                    normalize={value => FormatTextUtil.formatCardNumber(value)}
                  />
                  <span className='cardImage'>
                      {formHasCdigits ?
                        <img width='50' src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                        : <img width='50' src={`${serverDomainUrl}images/cards/unknown_card.png`}/>
                      }
                    </span>
                  <VisibilityToggle
                    visibility={this.state.cardVisibility}
                    onClick={this.toggleCardNumberVisibility}/>
                </div>

                <Field
                  component={TextField}
                  name='edate'
                  id='edate'
                  label={t('OnlinePaymentForm.Field.EDateLabel')}
                  hintText={t('OnlinePaymentForm.Field.EDateHint')}
                  normalize={FormatTextUtil.formatCreditCardExpiration}
                  className='quarterToHalfScreen textField cardDate'
                  disabled={submitting}
                  maxLength='5'
                />
                <Field
                  name='cvv'
                  id='cvv'
                  component={TextField}
                  label={virtualTerminal.isAmex ? t('OnlinePaymentForm.Field.CidLabel') : t('OnlinePaymentForm.Field.CvvLabel')}
                  hintText={virtualTerminal.isAmex ? t('OnlinePaymentForm.Field.CidLabel') : t('OnlinePaymentForm.Field.CvvLabel')}
                  maxLength='10'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterToHalfScreen textField cardCvv'
                />

              </div>

              <div className='flex'>
                <Field
                  component={TextField}
                  name='street_number'
                  id='street_number'
                  label={t('OnlinePaymentForm.Field.StreetNumber')}
                  maxLength='50'
                  normalize={FormatTextUtil.formatStreetNumber}
                  className='halfScreen textField cardBillingStreet'
                />
                <Field
                  component={TextField}
                  name='zip'
                  id='zip'
                  label={t('OnlinePaymentForm.Field.Zip')}
                  maxLength='5'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField cardZip'
                />
                <Field
                  component={TextField}
                  name='zipPlus4'
                  id='zipPlus4'
                  label={t('OnlinePaymentForm.Field.ZipPlus4')}
                  hintText={t('OnlinePaymentForm.Field.ZipPlus4Hint')}
                  maxLength='4'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField cardZipPlus4'
                />
              </div>
            </>
          }
        </div>
      </div>
    )

    return (
      <form onSubmit={handleSubmit}>
        <div className='invoiceFormContainer virtualTerminalForm'>
          {this.props.paymentCompleted ? (
            <>
                <div className='thankYou'>
                    <div className='invoiceTitle'>{t('ThankYou')}</div>
                    <div className='invoiceSubtitle'>{thankYouText}</div>
                    { !!paidText && (
                        <div className='invoiceSubtitle'>{paidText}</div>
                    )}
                </div>

                {loyaltyStatus?.points_earned && isPremiumPlusLoyalty ? <div className='payNowLoyaltyStatusContainer'>
                    <div className='payNowLoyaltyStatus'>
                        <div className='icon'>{loyaltyIcon}</div>
                        <div className='textContainer'>
                            <p className='bold'>
                                {hasEarned
                                    ? t('LoyaltyPrograms.Clp.Earned', loyaltyStatus)
                                    : loyaltyStatus.program_name}
                            </p>
                            <p>
                                {hasEarned
                                    ? t('LoyaltyPrograms.Clp.PresentText')
                                    : t('LoyaltyPrograms.Clp.Status', loyaltyStatus)}
                            </p>
                        </div>
                    </div>
                </div> : null}
            </>

            ) :
            (
              <div className='invoiceDue'>
                <div className='invoiceTitle'>{`${numeral(totalAmount).format('$0,0.00')} ${transactionAmountText}`}</div>
                <div className='invoiceSubtitle'>{t('OnlinePaymentForm.PayBy', { date: moment.utc(initialValues.due_date).format(t('OnlinePaymentForm.PayByDateFormat')) })}</div>
              </div>
            )
          }
          <Button
            label={t('OnlinePaymentForm.ViewOrderSummary')}
            className='viewOrderSummaryButton'
            onClick={this.viewOrderSummary}
          />
          <br/> <br/>

          {!this.props.paymentCompleted ? paymentContainer : null}
          {editPaymentModal}
        </div>
      </form>
    );
  }
}

export const OnlinePaymentForm = reduxForm({
  form: 'payNowInvoiceForm',
  fields: ['cdigits', 'edate', 'cvv', 'street_number', 'zip', 'zipPlus4', 'selectedPaymentMethod'],
  validate,
  enableReinitialize: false
})(OnlinePaymentFormComponent);

function mapStateToProps(state, ownProps) {

  const currentValues = getFormValues('payNowInvoiceForm')(state) || {};
  const invoice = ownProps?.invoices?.invoiceForPayment;
  return {
    initialValues: {...invoice, creditCardPaymentFlag: true},
    paymentCompleted: ownProps.paymentCompleted,
    currentValues
  };
}

export default connect(mapStateToProps)(OnlinePaymentForm);

