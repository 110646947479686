import actionTypes from '../constants/actionTypes';
import _ from 'lodash';
import labels from '../constants/labels';

const userReducer = (
  state = {
    isFetching: false,
    isUpdating: false,
    selectedMerchantAccount: localStorage.getItem('pa-ma') ? localStorage.getItem('pa-ma') : null,
    data: localStorage.getItem('pa-u') ? JSON.parse(localStorage.getItem('pa-u')) : {},
    twoFactorSaved: false,
    updateError: null,
    pci: null,
    pciEnvelopes: null,
    isRememberedDevicesFetching: false,
    rememberedDevices: null,
    closedAccount: false
  },
  action
) => {
  switch (action.type) {
    case actionTypes.accountRequest:
      return Object.assign({}, state, {
        isFetching: true,
        data: {}
      });
    case actionTypes.accountSuccess:
    case actionTypes.forceResetPasswordRequired:
    case actionTypes.force2FASetupRequired:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.user,
        selectedMerchantAccount: action.selectedMerchantAccount
      });
    case actionTypes.accountFailure:
      return Object.assign({}, state, {
        isFetching: false,
        data: _.pick(state.data, ['user_id', 'is_ecp', 'merchant_accounts'])
      });
    case actionTypes.userMerchantApplicationSuccess:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          viewed_application_date: true
        }
      });
    case actionTypes.agreementSaveSuccess:
       return Object.assign({}, state, {
        data: action.response.user,
        selectedMerchantAccount: action.response?.merchant?.mea_id
      });
    case actionTypes.userUpdateLoginInfoRequest:
      return Object.assign({}, state, {
        isUpdating: true,
        updateError: null
      });
    case actionTypes.userUpdateLoginInfoSuccess:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: null
      });
    case actionTypes.userUpdateLoginInfoFailure:
      let error = action.error;
      if (error === 'Server.Error.UnsuccessfulSignInPA' &&
        [labels.ph.abbreviation, labels.nh.abbreviation].includes(globalApplicationLabel.abbreviation) ) {
        error = 'Server.Error.UnsuccessfulSignInPH';
      }
      if (error === '{"reason":"conflict"}') {
       error = 'Server.Error.DuplicateEmail';
      }
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: error
      });
    case actionTypes.clearUpdatePasswordError:
      return Object.assign({}, state, {
        updateError: null
      });
    case actionTypes.logoutSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdating: false,
        updateError: null,
        selectedMerchantAccount: null,
        twoFactorSaved: false,
        data: {}
      });
    case actionTypes.addAccountsSuccess:
      let merchantAccounts = _.cloneDeep((state.data && state.data.merchantAccounts) || []),
        newMerchantAccounts = (action.response.successes || []).map(result => {
          let merchantAccount = result.merchantAccount;
          return merchantAccount;
        });
      merchantAccounts = merchantAccounts.concat(newMerchantAccounts);
      // check that the merchant account doesn't already exist
      merchantAccounts = _.uniqBy(merchantAccounts, function (merchantAccount) {
        return merchantAccount.mea_id;
      });
      // assign the data in memory to set in local storage
      let userData = Object.assign({}, state.data || {}, {
        merchantAccounts: merchantAccounts
      });

      localStorage.setItem('pa-u', JSON.stringify(userData));

      return Object.assign({}, state, {
        data: userData
      });
    case actionTypes.changeSelectedMerchantAccount:
      return Object.assign({}, state, {
        selectedMerchantAccount: action.selectedMerchantAccount?.toString() ?? ''
      });
    case actionTypes.twoFactorModifySettingsRequest:
      return Object.assign({}, state, {
        twoFactorSaved: false,
        isUpdating: true
      });
    case actionTypes.twoFactorModifySettingsFailure:
      return Object.assign({}, state, {
        twoFactorSaved: false,
        isUpdating: false,
        errorMessage: action.error
      });
    case actionTypes.twoFactorModifySettingsSuccess:
      const {two_factor_cell_phone, two_factor_enabled} = action.response;

      let user = JSON.parse(localStorage.getItem('pa-u'));
      user.is_ecp = two_factor_enabled;
      localStorage.setItem('pa-u', JSON.stringify(user));

      return Object.assign({}, state, {
        isUpdating: false,
        twoFactorSaved: true,
        data: {
          ...state.data,
          is_ecp: two_factor_enabled,
          hasTwoFactorEnabled: two_factor_enabled,
          mobile_number: two_factor_cell_phone.slice(-4)
        }
      });
    case actionTypes.twoFactorPhoneVerifySuccess:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          has_cell: true,
          mobile_number: action.metadata?.phoneNumber
        }
      });
    case actionTypes.userProfileSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.response.user
      });
    case actionTypes.pciSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        pci: action.response.data
      });
    case actionTypes.pciEnvelopeSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        pciEnvelopes: action.response
      });
    case actionTypes.rememberedDevicesRequest: {
      return {
        ...state,
        isRememberedDevicesFetching: true,
        rememberedDevices: null
      };
    }
    case actionTypes.rememberedDevicesSuccess: {
      return {
        ...state,
        isRememberedDevicesFetching: false,
        rememberedDevices: action.response?.data
      };
    }
    case actionTypes.rememberedDevicesFailure: {
      return {
        ...state,
        isRememberedDevicesFetching: false,
        rememberedDevices: null
      };
    }
    case actionTypes.deleteRememberedDevicesSuccess: {
      return {
        ...state,
        rememberedDevices: action.response?.data
      };
    }
    case actionTypes.userNeedsInfoRequest:
      return { ...state, isFetching: true };
    case actionTypes.userNeedsInfofailure:
      return { ...state, isFetching: false };
    case actionTypes.userNeedsInfoSuccess:
      const current_mea = Number(localStorage.getItem('pa-ma'));
      const oldData = { ...JSON.parse(localStorage.getItem('pa-u')) };
      const newData = { ...oldData, merchantAccounts: oldData.merchantAccounts && oldData.merchantAccounts.map(mea => mea.mea_id === current_mea ? { ...mea, needs_info: !mea.needs_info } : { ...mea }) };
      localStorage.setItem('pa-u', JSON.stringify(newData));
      return {
        ...state,
        isFetching: false,
        data: newData,
      };
    case actionTypes.closedAccount:
      return { ...state, closedAccount: true }
    default:
      return state;
  }
};

export default userReducer;

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

