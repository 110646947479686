/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit } from 'redux-form';
import MasterDetailLayout from '../MasterDetailLayout';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import IconUtils from './../util/IconUtil';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '../shared/IconButton';
import { getDiscounts, saveDiscounts, deleteDiscount } from '../../actions/discountsActions';
import MessageDialog from '../shared/MessageDialog';
import NoData from '../NoData';
import FormatTextUtil from '../util/FormatTextUtil';
import DiscountsDetailForm from './DiscountsDetail';
import UserUtil from '../util/UserUtil';
import FilterPanel from '../shared/FilterPanel';
import TableHeader from '../shared/TableHeader';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import LabelUtil from '../util/LabelUtil';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import Page from '../shared/Page';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';

export default class Discounts extends Component {

  constructor(props) {
    super(props);

    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCloseDetail = this.onCloseDetail.bind(this);
    this.handleFilterSelection = this.props.handleFilterSelection.bind(this);
    this.handleCreateMenuClose = this.props.handleCreateMenuClose.bind(this);
    this.handleCreateNew = this.props.handleCreateNew;
    this.renderCreateMenu = this.props.renderCreateMenu.bind(this);
    this.handleCreateClick = this.props.handleCreateClick.bind(this);
    this.displayDeleteDialogDetail = this.displayDeleteDialogDetail.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.state = {
      selectedIndex: 0,
      openDetail: false,
      openDeleteDialog: false
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  componentDidMount() {
    if (this.props.location?.state?.openCreateNewFromTheStart) {
      this.showDetailForRow('new');
    }
  }

  displayDeleteDialogDetail() {
    this.setState({ openDeleteDialog: true });
  }

  closeDeleteDialog() {
    this.setState({ openDeleteDialog: false });
  }

  loadData(props) {
    props.dispatch(getDiscounts(props.user));
  }

  save(values) {
    let that = this;

    this.setState({ showLoadingIndicator: true });
    return this.props.dispatch(saveDiscounts(this.props.user, this.state.selectedIndex, values)).then(function () {
      that.setState({ selectedIndex: 0, showLoadingIndicator: false, openDetail: false });
      that.loadData(that.props);
    });
  }

  onSaveClick() {
    this.props.dispatch(submit('discountsDetailForm'));
  }

  onCloseDetail() {
    this.setState({ selectedIndex: -1, openDetail: false });
  }

  onCellClick(selectedIndex) {
    this.setState({ selectedIndex, openDetail: true });
  }

  showDetailForRow(selectedIndex) {
    this.onCellClick(selectedIndex);
  }

  newItem() {
    return {
      name: '',
      color: '',
      type: 'amount',
      amount: FormatTextUtil.formatCurrencyOff('0'),
      percentage: FormatTextUtil.formatPercentOff('0')
    }
  }

  delete() {

    const { dispatch, items, user } = this.props;

    let that = this;
    this.setState({ openDeleteDialog: false, showLoadingIndicator: true });
    return dispatch(deleteDiscount(items.filteredDiscounts[this.state.selectedIndex], user)).then(() => {
      that.setState({ selectedIndex: 0, showLoadingIndicator: false, openDetail: false });
      that.loadData(that.props);
    });
  }

  render() {

    const { isFetching, items, t } = this.props;

    const isEmpty = items.discounts === null;

    const readOnly = !UserUtil.isActive(this.props.user);

    const pageLoading = (isEmpty && isFetching) || this.state.showLoadingIndicator;

    if (pageLoading) {
      return (
        <Page loading
            title={t('Inventory')}
        />
      )
    }


    const isNewDiscount = this.state.selectedIndex === 'new';
    let discounts = this.props.items.filteredDiscounts || items.discounts;
    let selectedDiscount = !isNewDiscount && discounts[this.state.selectedIndex] || this.newItem();
    const labelColor = LabelUtil.getLabelColor();
    const deleteIcon = IconUtils.getIcon('DeleteIcon', labelColor);

    const ammountOfDiscounts = discounts.length ? discounts.length : 0;

    const listHeaders = ((discounts.length > 0) &&
      <TableRow className={'tableRow'}>
        <TableHeader
            attribute={''}
            headerId={'categoriesHeaderOne'}
            name={''}
            type='table'
            {...this.props}
        />
        <TableHeader
            attribute={'name'}
            clickable
            headerId={'modifiersHeaderTwo'}
            name={t('DiscountName')}
            type='table'
            {...this.props}
        />
        <TableHeader
            attribute={'value'}
            clickable
            headerId={'modifiersHeaderThree'}
            name={t('DiscountValue')}
            type='table'
            {...this.props}
        />
      </TableRow>
    ) || null;

    let discountRows = discounts.length > 0 ?
      discounts.map((item, i) => {
        let tableRowClassName = this.state.selectedIndex === i ? 'selectedListRow discountRow' : 'discountRow';
        let formattedColor = item.color ? '#' + item.color : '#888C8D';
        const itemAmount = item.amount ? `${item.amount}` : 'not available';
        const itemPercentage = item.percentage ? `${item.percentage}` : 'not available';
        return (
          <TableRow className={`tableRow ${tableRowClassName}`}
              key={i}
              onClick={this.onCellClick.bind(this, i)}
          >
            <TableCell className='discountIndicatorColumn'>
              <IconButton className='discountIcon'
                  disableTouchRipple
                  size='large'
              >
                <Avatar
                    style={{ backgroundColor: formattedColor, width: 50, height: 50 }}
                >{IconUtils.getIcon('DiscountIcon', '#FFFFFF')}</Avatar>
              </IconButton>
            </TableCell>
            <TableCell>
              <div className='name'>{item.name}</div>
            </TableCell>
            <TableCell className='discountValueColumn'>
              {item.type === 'amount' ?
                <span className='name'>{FormatTextUtil.formatCurrencyOff(itemAmount)}</span> :
                <span className='name'>{FormatTextUtil.formatPercentage(itemPercentage)}</span>}
            </TableCell>
          </TableRow>
        );

      }
      ) : (<NoData text='No Discounts'
          type='table'
           />);

    let itemsTableLayout = (
      <TableBody>
        {listHeaders}
        {discountRows}
      </TableBody>
    );

    const searchBar = (
      <SearchBarAccessory {...this.props}
          searchPlaceholder='Search Discounts'
      />
    );

    const createNewButton = <CreateButtonAccessory 
      onClick={this.handleCreateClick}
      disabled={readOnly}
      isMobileOnly
    />

    const filterPanel = (
      <FilterPanel
          buttonIcon={null}
          buttonOnClick={this.handleCreateClick}
          buttonText={'Create'}
          buttonWithCreateIcon
          {...this.props}
          disabled={readOnly}
          filterData={UserUtil.getInventoryOptions('Discounts', ammountOfDiscounts, this.props.auth, this.props.user, this.props.t)}
          selectFilterCallback={this.handleFilterSelection}
      />
    );

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
    ];


    const deleteOption =
    {
      onClick: this.displayDeleteDialogDetail,
      text: 'Delete',
      icon: deleteIcon
    };

    const discountOptions = !isNewDiscount && selectedDiscount && !readOnly ? [deleteOption] : [];
    const selecteddiscountColor = selectedDiscount.color ? '#' + selectedDiscount.color : '#888C8D';

    let discountsDetailPanel =
      (
        <DetailPanel
            confirmationText={t('Save')}
            heading={isNewDiscount ? t('AddDiscount') : <div><span>{IconUtils.getIcon('DiscountIcon', selecteddiscountColor)}</span><span>{`   ${selectedDiscount.name}`}</span></div>}
            isNew
            isSubmitButtonVisible={!readOnly}
            onClick={this.onSaveClick}
            onCloseClick={this.onCloseDetail}
            open={this.state.openDetail}
            shouldDisplay
        >
          <DetailPanelOptions options={discountOptions} />
          <DiscountsDetailForm item={selectedDiscount}
              onDelete={this.delete}
              onSubmit={this.save}
              readOnly={readOnly}
              t={t}
          />
        </DetailPanel>
      );

    const deleteConfirmationDialog = (
      <MessageDialog
          bodyText={
          <p className='confirmationText'>{'Are you sure you want to delete this discount?'}</p>
        }
          cancelText='No'
          confirmText='Yes'
          externalClassName='deleteConfirmationDialog'
          isChoiceRequired={false}
          onConfirm={this.delete}
          onRequestClose={this.closeDeleteDialog}
          open={this.state.openDeleteDialog}
          scrollable
          titleText='Delete discount.'
      />
    );

    return (
      <section className='inventoryDiscounts'>
        <MasterDetailLayout
            {...this.props}
            filterData={this.state.filterData}
            filterPanel={filterPanel}
            forceOpenDetail={isNewDiscount}
            header={null}
            listRowHeight='80px'
            pageAccessories={pageAccessories}
            pageInitialSubpage={t('Discounts')}
            pageLoading={pageLoading}
            pageTitle={t('Inventory')}
            tableData={this.props.items.filteredDiscounts}
            tableRowLayout={itemsTableLayout}
        />
        {discountsDetailPanel}
        {deleteConfirmationDialog}
        {this.renderCreateMenu()}
      </section>
    );
  }
}

Discounts.defaultProps = {
  isFetching: true,
};
