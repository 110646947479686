/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import labels from '../constants/labels';
import {createTheme} from '@mui/material';
import bodyStyles from './body';

const baseTheme = createTheme();
const { augmentColor } = baseTheme.palette;
const componentsOverwriteStyles = (styles) => ({
  styleOverrides: styles
})
const fontFamily = {
  interRegular: 'Inter Regular',
  interMedium: 'Inter Medium',
  interSemiBold: 'Inter Semi Bold',
  interBold: 'Inter Bold',
  centuryGothicRegular: 'Century Gothic Regular',
  centuryGothicBold: 'Century Gothic Bold',
  // Fallback fonts
  roboto: 'Roboto',
  raleway: 'Raleway',
  helvetica: 'Helvetica',
  arial: 'Arial',
  sansSerif: 'sans-serif',
};
const fallbackFonts = [
  `"${fontFamily.raleway}"`,
  `"${fontFamily.roboto}"`,
  `"${fontFamily.helvetica}"`,
  `"${fontFamily.arial}"`,
  `${fontFamily.sansSerif}`
];
const baseFontFamily = (font) => {
  const filteredFallbackFonts = fallbackFonts
    .filter((fontItem ) => font && !fontItem.includes(font));
  return [`"${font}"`, ...filteredFallbackFonts].join(',');
};

/* Theme Overwrites */
/*--------------------*/
const paletteOverwrites = (labelColor, mode, labelData) => {
  return {
    mode,
    labelColor: augmentColor({
      color: {
        main: labelColor
      },
      name: 'labelColor'
    }),
    primary: augmentColor({
      color: {
        main: labelColor,
        light: labelColor,
        dark: labelColor,
      },
      name: 'primary'
    }),
    error: augmentColor({
      color: {
        main: labels.base.errorColor
      },
      name: 'error'
    }),
    iconBlack: augmentColor({
      color: {
        main: '#000000'
      },
      name: 'iconBlack'
    }),
    text: {
      primary: labels.base.primaryTextColor,
      secondary: labels.base.secondaryTextColor
    },
    common: {
      black2: 'rgba(0, 0, 0, 0.87)',
      black3: '#222529',
      lightGray: '#F2F3F5',
      mediumGray: '#AEAEAE',
      rule: '#707070',
      subtext: '#666F72',
      featureBgcolor: '#F7F7F7',
      cardMediaBgcolor: '#E2E2E2',
      hazinessWhite: 'rgba(255, 255, 255, 0.61)',
      whiteSmoke: '#F7F7F7',
      borderGray2: '#848484',
      darkGray: '#747373',
      darkGray2: '#2F3339',

      // Old sass colors
      regularColor: '#777777',
      backgroundColor: '#FFFFFF',
      phoneSwipeColor: '#C2CB52',
      phoneSwipeColorAlternativeColor: '#A3A3A6',
      paymentHubsColor: '#00B4AA',
      paymentHubsColorAlternativeColor: '#A3A3A6', // do not use, use labelColor / primary instead
      paymentHubsButtonAlternativeColor: '#05A899', // do not use, use labelColor / primary instead
      northColor: '#1857F7', // do not use, use labelColor / primary instead
      backgroundColor2: '#E9E9EC',
      backgroundColor3: '#F2F2F4',
      backgroundColor4: '#F4F4F4',
      backgroundColor5: '#f7f8fa',
      borderBlue: '#0043ED',
      payAnywhereColor: '#F57F38',
      payAnywhereAlternativeColor: '#BF5E04',
      genericColor: '#5BADC9',
      primaryTextColor: '#404143',
      disabled: 'rgba(153, 153, 153, 0.2)',
      secondaryTextColor: '#888C8D',
      placeholderTextColor: '#393A3F',
      subNavBackgroundColor: '#EFEFF2',
      mobileTitlePanelBackgroundColor: '#393A3F',
      todaySecondaryDataColor: '#A3A3A6',
      iconColor: '#3E3935',
      lineColor: '#DDDDDD',
      lineColor2: '#D9D9D9',
      lineColor3: 'rgba(0, 0, 0, 0.23)',
      selectedRow: '#EFEFF2',
      positiveColor: 'green',
      negativeColor: 'red',
      warningColor: '#ffcc00',
      errorColor: 'red',
      disputeError: '#EC3830',
      unread: '#FF0000',
      disputePositive: '#36B1FF',
      elevioHotSpot: '#36B1FF',
      navyBlue: '#7195f5',
      cardBorderColor: '#E0E0E0',
      cardBorderColor2: '#D3D3D3',
      inventoryTrendDiscount: '#6CE232',
      inventoryTrendCategory: '#3FC3FA',
      loginHeadingColor: '#404143',
      paymentPageGrey: '#F2F2F2',
      paymentAddressColor: '#505152',
      paymentDetailText: '#5D5E5F',
      paymentCardBackground: '#F2F2F2',
      customerPaymentPrimaryColor: '#000',
      loyaltyEmailGray: '#F2F2F2',
      receiptSeparatorColor: '#707070',
      loyaltyBorderColor: '#E1E1E1',
      loyaltyResendGray: '#F2F2F4',
      loyaltyBorderColor2: '#B5B5B5',
      loyaltyBackgroundColor: '#EBEBEB',
      grayButtonColor: '#F5F5F5',
      loginTextColor: '#4A5460',
      listItemBackground: '#E6E6EA',
      paGradientOne: '#f5af19',
      paGradientTwo: '#f12711',
      phGradientOne: '#00D0C4',
      phGradientTwo: '#00B4AA',
      green: '#49C930',
      gray: '#8A8C8C',
      gray2: '#ACABAB',
      borderGray: '#cccccc',
      grayText: '#5d5e5f',
      fieldsHintText: 'rgba(0, 0, 0, 0.3)',
      disabledGray: '#D9D9D9',
      borderGrayColor: 'rgba(157,157,157,0.3)',
      borderShadowColor: 'rgba(0, 0, 0, 0.04)',
      phColorStart: '#0EC4FA',
      phColorEnd: '#1CD1BE',
      nhColorStart: '#1147D1',
      nhColorEnd: '#9919F7',
      transparent: 'rgba(0, 0, 0, 0)',

      /* ----------------------------------------
          TODO: remove duplicate colors
            "#00B4AA"
            "rgba(0, 0, 0, 0.87)"
            "#A3A3A6"
            "#393A3F"
            "#EFEFF2"
            "#404143"
            "#F2F2F2"
            "red"
            "#36B1FF"
            "#E0E0E0"
            "#D3D3D3"
            "#F5F5F5"
            "#fff"
            "rgba(0, 0, 0, 0.12)"
            "rgba(0, 0, 0, 0.04)"
            "rgba(0, 0, 0, 0.08)"
            "rgba(0, 0, 0, 0.26)"
            "rgba(0, 0, 0, 0.38)"
      ---------------------------------------- */

    },
    gradient: {
      start: labelData.gradientStart,
      end: labelData.gradientStop,
      create: (start, end, deg = '90') => `transparent linear-gradient(${deg}deg, ${start} 0%, ${end} 100%) 0% 0% no-repeat padding-box`,
    }
  };
};

const typographyOverwrites = () => {

  const fonts = {
    interRegular: baseFontFamily(fontFamily.interRegular),
    interMedium: baseFontFamily(fontFamily.interMedium),
    interSemiBold: baseFontFamily(fontFamily.interSemiBold),
    interBold: baseFontFamily(fontFamily.interBold),
    centuryGothicRegular: baseFontFamily(fontFamily.centuryGothicRegular),
    centuryGothicBold: baseFontFamily(fontFamily.centuryGothicBold),
    roboto: baseFontFamily(fontFamily.roboto),
  };
  fonts.fontFamily = fonts.interMedium;

  const variants = {
    h1: { fontSize: '2.5rem' },
    subtitle1: { fontSize: '1rem',  color: baseTheme.palette.grey[500] },
  };

  // Old sass typography
  fonts.primaryFontFamily = fonts.interMedium;
  fonts.primaryFontFamilyRegular = fonts.interRegular;
  fonts.primaryFontFamilyMedium = fonts.interMedium;
  fonts.primaryFontFamilySemiBold = fonts.interSemiBold;
  fonts.primaryFontFamilyBold = fonts.interBold;
  fonts.secondaryFontFamily = fonts.centuryGothicRegular;
  fonts.secondaryFontFamilyBold = fonts.centuryGothicBold;
  fonts.tertiaryFontFamily = fonts.centuryGothicRegular;

  variants.primaryFontFamilyBold = { fontFamily: fonts.primaryFontFamilyBold };
  variants.primaryFontFamilySemiBold = { fontFamily: fonts.primaryFontFamilySemiBold };
  variants.primaryFontFamilyRegular = { fontFamily: fonts.primaryFontFamilyRegular, fontSize:'12px' , lineHeight:'18px' };
  variants.primaryFontFamilyMedium = { fontFamily: fonts.primaryFontFamilyMedium, fontSize:'12px' , lineHeight:'18px' };
  variants.secondaryFontFamilySemiBold = { fontFamily: fonts.secondaryFontFamily, fontWeight: '600 !important' };
  variants.secondaryFontFamilyExtraBold = { fontFamily: fonts.secondaryFontFamily, fontWeight: '800 !important' };
  variants.tertiaryFontFamilyRegular = { fontFamily: fonts.tertiaryFontFamily, fontWeight: '400 !important' };
  variants.tertiaryFontFamilyExtraBold = { fontFamily: fonts.tertiaryFontFamily, fontWeight: '800 !important' };
  variants.heading1 = { fontFamily: fonts.secondaryFontFamilyBold, fontSize: '30px !important' };
  variants.heading1a = { fontFamily: fonts.primaryFontFamily, fontSize: '30px !important' };
  variants.heading2 = { fontFamily: fonts.secondaryFontFamily, fontSize: '13.5px !important', textTransform: 'uppercase' };
  variants.heading2RegularCase = { fontFamily: fonts.secondaryFontFamily, fontSize: '13.5px !important' };
  variants.heading3 = { fontFamily: fonts.secondaryFontFamily, fontSize: '11px !important', textTransform: 'uppercase' };
  variants.heading4 = { fontFamily: fonts.tertiaryFontFamily, fontSize: '17px !important' };
  variants.body = { fontFamily: fonts.tertiaryFontFamily, fontSize: '17px !important' };
  variants.body3 = { fontFamily: fonts.tertiaryFontFamily, fontSize: '14px !important' };

  return {
    useNextVariants: true,
    ...fonts,
    ...variants
  };
};

const breakpointsOverwrites = {
  values: {
    ...baseTheme.breakpoints.values,
    md: 960,
    lg: 1280,
    xl: 1920,
    dashboardXl: 2200,
    dashboardLg: 1750,
    dashboardMd: 1200,
    dashboardSm: 1000,
    // Old sass breakpoints
    sizeDesktopMax: 1439,
    sizeDesktopMid: 1339,
    sizeDesktopMedium: 1309,
    sizeDesktopMin: 992,
    sizeTabletMax: 991,
    sizeTabletMedium: 791,
    sizeTabletMin: 768,
    sizeMobileMaxPortrait: 767,
    sizeMobileLoginMinPortrait: 690,
    sizeMobileMax: 479,
    sizeMobileMedium: 375,
    sizeMobileMin: 320,
  },
  upVertical: function(value) {
    return `@media (min-height:${value}px)`;
  },
  downVertical: function(value) {
    return `@media (max-height:${value - 0.05}px)`;
  },
};

const otherOverwrites = {
  measures: {
    buttonHeight: '50px',
    noticeHeight: '60px',
    noticeHeightMobile: '100px',
    centeredLoginContentWidth: '30%',
    centeredLoginContentMobileWidth: '70%',
  },
  transitions: {
    ...baseTheme.transitions,
    cubic: 'cubic-bezier(.64, .09, .08, 1)'
  },
  zIndex: {
    ...baseTheme.zIndex,
    zIndexTopEverything: 999,
  },
  mergeStyles: (...stylesArray) => _.merge({}, ...stylesArray),
}

/*  Global Individual Overwrite for Mui components */
/*---------------------------------------------------*/
const MuiCssBaseline = componentsOverwriteStyles(bodyStyles);

const MuiOutlinedInput = componentsOverwriteStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      margin: '0'
    }
  }
});

const MuiInputLabel = componentsOverwriteStyles({
  root: {
    color: labels.base.primaryTextColor,
  }
});

const componentsOverwrites = (theme) => ({
  MuiCssBaseline,
  MuiOutlinedInput,
  MuiInputLabel,
  MuiTableRow: {
    root: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.common.selectedRow,
        '&:hover': {
          backgroundColor: theme.palette.common.selectedRow,
        }
      }
    }
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        primaryFontFamilySemiBold: 'p',
        primaryFontFamilyMedium: 'p'
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true
    }
  },
  MuiTextField: componentsOverwriteStyles({
    root: {
      input: {
        color: theme.palette.text.primary
      },
    }
  }),
  MuiSelect: componentsOverwriteStyles({
    root: {
      color: theme.palette.text.primary
    }
  }),
  MuiAutocomplete: componentsOverwriteStyles({
    root: {
      '& .MuiAutocomplete-inputRoot': {
        padding: '4px 0'
      },
      fieldset: {
        border: 'none',
        legend: {
          display: 'none'
        }
      }
    },
  }),
  MuiPaginationItem: componentsOverwriteStyles({
    root: {
      '&.Mui-selected': {
        color: theme.palette.common.backgroundColor
      }
    }
  })
});

otherOverwrites.sharedStyles = (() => {

  const shared = {
    // Old sass shared styles
    customLine: {
      height: '1px',
      width: '100%',
      backgroundColor: 'common.lineColor',
    },
    pageBar: {
      height: '62px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: ({palette}) => `solid 1px ${palette.lineColor}`,
      padding: '0 10px',
    },
    disabledLink: {
      textDecoration: 'none',
      color: 'text.secondary',
      fontFamily: ({typography}) => typography.secondaryFontFamily,
      cursor: 'not-allowed',
    },
    flip: {
      MozTransform: 'scaleX(-1)',
      OTransform: 'scaleX(-1)',
      WebkitTransform: 'scaleX(-1)',
      transform: 'scaleX(-1)',
      msFilter: 'FlipH',
    },
    centerWithin: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    headerTextMedium: (theme) => ({
      ...theme.typography.secondaryFontFamilyExtraBold,
      fontSize: '15px',
      marginBottom: '5px',
    }),
    detailsHeader: (theme) => ({
      ...theme.typography.heading3,
      color: 'text.secondary',
    }),
    detailsLabel: (theme) => ({
      ...theme.typography.heading3,
      transform: 'translate(0px, -28px) !important',
    }),
    detailsListColumn: {
      width: '10%',
      fontSize: '9.5px',
      display: 'inline-block',
      textAlign: 'center',
      textTransform: 'capitalize',
    },
    xSmallIcon: {
      width: '12px !important',
      height: '12px !important',
    },
    smallIcon: {
      width: '16px !important',
      height: '16px !important',
    },
    mediumIcon: {
      width: '20px !important',
      height: '20px !important',
    },
    largeIcon: {
      width: '24px !important',
      height: '24px !important',
    },
    heading: (theme) => ({
      fontFamily: theme.typography.secondaryFontFamilyBold,
      fontSize: '37px',
      color: theme.palette.text.primary,
      fontWeight: 'normal',
      margin: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
        fontSize: '27px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '24px',
      },
    }),
    subheading: (theme) => ({
      fontFamily: theme.typography.primaryFontFamilyBold,
      fontSize: '28px',
      color: theme.palette.text.primary,
      fontWeight: 500,
      margin: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
        fontSize: '21px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '20px',
      },
    }),
    bodyText: (theme) => ({
      fontFamily: theme.typography.primaryFontFamilyMedium,
      fontSize: '15px',
      color: theme.palette.text.primary,
      margin: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '14px',
      },
    }),
    bodyTextSubHeading: (theme) => ({
      fontFamily: theme.typography.primaryFontFamilySemiBold,
      fontSize: '17px',
      color: theme.palette.text.primary,
      margin: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '14px',
      },
    }),
    columnLabel: (theme) => ({
      fontFamily: theme.typography.primaryFontFamilyBold,
      fontSize: '12px',
      color: theme.palette.text.primary,
      fontWeight: 500,
      margin: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '11px',
      },
    }),
    contentLabel: (theme) => ({
      fontFamily: theme.typography.primaryFontFamilySemiBold,
      fontSize: '12px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        fontSize: '11px',
      },
    }),
    disclaimerLabel: (theme) => ({
      fontSize: '10px',
      lineHeight: '6px',
      textTransform: 'none',
      textDecoration: 'none'
    }),
    active: {
      color: 'labelColor.main',
    },
    button: (theme, color) => ({
      borderRadius: '50px',
      height: theme.measures.buttonHeight,
      px: 3,
      py: 0,
      mr: 1,
      overflow: 'hidden',
      span: theme.mergeStyles(
        theme.sharedStyles.contentLabel(theme),
        theme.sharedStyles.responsiveFontSize(theme, 14, { sizeTabletMax: 14 }),
        {
          textTransform: 'none',
          color: theme.palette.common.white,
        }
      ),
      '&:disabled': {
        background: theme.palette.common.disabled,
        span: {
          color: theme.palette.common.gray,
        }
      },
      '&:hover': {
        backgroundColor: theme.palette.labelColor.dark,
      },
    }),
    reverseButton: (theme, color) => theme.mergeStyles(
      theme.sharedStyles.button(theme, color),
      {
        backgroundColor: color || theme.palette.common.transparent,
        span: theme.mergeStyles(
          theme.sharedStyles.contentLabel(theme),
          theme.sharedStyles.responsiveFontSize(theme, 14, { sizeTabletMax: 14 }),
          {
            textTransform: 'none',
            color: theme.palette.labelColor.main,
          }
        ),
        '&:hover': {
          backgroundColor: theme.palette.common.disabled,
        },
      }
    ),
    buttonLink: (theme, color) => theme.mergeStyles(
      theme.sharedStyles.reverseButton(theme, color),
      {
        backgroundColor: theme.palette.common.transparent,
        borderRadius: 0,
        span: theme.mergeStyles(
          theme.sharedStyles.contentLabel(theme),
          theme.sharedStyles.responsiveFontSize(theme, 14, { sizeTabletMax: 14 }),
          {
            textTransform: 'none',
            color: theme.palette.labelColor.main,
          }
        ),
        '&:hover': {
          backgroundColor: theme.palette.common.transparent,
        }
      },
    ),
    linkButton: (theme) => theme.mergeStyles(
      theme.sharedStyles.button(theme),
      theme.sharedStyles.contentLabel(theme),
      theme.sharedStyles.responsiveFontSize(theme, 14, { sizeTabletMax: 14 }),
      {
        background: theme.palette.labelColor.main,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        textDecoration: 'none',
        textTransform: 'none',
        '&:disabled': {
          background: theme.palette.common.disabled,
        },
        '&:hover': {
          backgroundColor: theme.palette.labelColor.dark,
        },
      }
    ),
    reverseLinkButton: (theme) =>  ({
      zIndex: 1,
      fontSize: '14px',
      fontFamily: theme.typography.primaryFontFamilyMedium,
      borderRadius: '50px',
      height: theme.measures.buttonHeight,
      background: theme.palette.common.white,
      boxShadow: '2px 2px 10px 0 rgba(163, 163, 166, 1)',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 24px',
      color: 'labelColor.main',
      textDecoration: 'none',
    }),
    outlinedButton: (theme, color) => theme.mergeStyles(
      theme.sharedStyles.reverseButton(theme, color),
      {
        backgroundColor: color || theme.palette.common.white,
        border: `2px solid ${theme.palette.labelColor.main}`,
        span: {
          color: theme.palette.labelColor.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.common.transparent,
          borderWidth: '2px',
        }
      }
    ),
    gradientButton: (theme, deg) => theme.mergeStyles(
      theme.sharedStyles.button(theme),
      {
        background: theme.palette.gradient.create(theme.palette.gradient.start, theme.palette.gradient.end, deg),
        '&:hover': {
          background: theme.palette.gradient.create(theme.palette.gradient.start, theme.palette.gradient.end, deg),
        }
      },
    ),
    contentRow: {
      marginBottom: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    responsiveFontSize: (theme, base, breakpointsValues) => {
      const {
        sizeMobileMax,
        sizeMobileMaxPortrait,
        sizeTabletMax,
        sizeDesktopMax,
      } = breakpointsValues;

      return ({
        fontSize: `${base}px`,
        ...(!!sizeMobileMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
            fontSize: `${sizeMobileMax}px`,
          },
        }),
        ...(!!sizeMobileMaxPortrait && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMaxPortrait)]: {
            fontSize: `${sizeMobileMaxPortrait}px`,
          },
        }),
        ...(!!sizeTabletMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
            fontSize: `${sizeTabletMax}px`,
          },
        }),
        ...(!!sizeDesktopMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
            fontSize: `${sizeDesktopMax}px`,
          },
        }),
      })
    },
    responsiveStyles: (theme, baseStyles, breakpointsValues) => {
      const {
        sizeMobileMax,
        sizeMobileMaxPortrait,
        sizeTabletMax,
        sizeDesktopMax,
      } = breakpointsValues;

      return ({
        ...baseStyles,
        ...(!!sizeMobileMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
            ...sizeMobileMax
          },
        }),
        ...(!!sizeMobileMaxPortrait && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMaxPortrait)]: {
            ...sizeMobileMaxPortrait
          },
        }),
        ...(!!sizeTabletMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
            ...sizeTabletMax
          },
        }),
        ...(!!sizeDesktopMax && {
          [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
            ...sizeDesktopMax
          },
        }),
      })
    },
  };
  shared.link = (theme) => ({
    ...shared.bodyText(theme),
    textDecoration: 'none',
    color: 'labelColor.main',
    cursor: 'pointer',
  });
  shared.subheadingInvoicing = (theme) => ({
    ...shared.subheading(theme),
    fontSize: '25px',
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      fontSize: '20px',
    },
  });
  shared.settingsWrapper = (theme) => ({
    ...shared.contentLabel(theme),
    backgroundColor: '#f2f2f4',
    padding: '20px 15px',
    borderRadius: '10px',
  });

  return shared;
})();

export default {
  paletteOverwrites,
  typographyOverwrites,
  breakpointsOverwrites,
  componentsOverwrites,
  otherOverwrites,
};
