/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import moment from 'moment-timezone';
import LabelUtil from '../components/util/LabelUtil';
import FormatTextUtil from '../components/util/FormatTextUtil';
import InvoiceUtil, {
  InvoiceFilterTypes,
  InvoiceFilterTypesMapping
} from '../components/util/InvoiceUtil';

export function getInvoices(user, dateRange) {

  const query = dateRange && dateRange.value !== 'all' ? '?start=' + moment(dateRange.startDate).tz('America/New_York').format() + '&end=' + moment(dateRange.endDate).tz('America/New_York').format() : '';

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/invoices' + query;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.invoicesRequest, actionTypes.invoicesSuccess, actionTypes.invoicesFailure]
    }
  };
}

function getInvoiceById(user, query) {
  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/invoices' + query;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.invoiceSelectedRequest, actionTypes.invoiceSelectedSuccess, actionTypes.invoiceSelectedFailure]
    }
  };
}

export function getInvoice(user, id) {
  const query = '?id=' + id;
  return getInvoiceById(user, query);
}

export function getInvoiceSeries(user, id) {
  const query = '?seriesId=' + id;
  return getInvoiceById(user, query);
}

export function deleteRecurringPayment(user, recurringId) {

  const endpoint = `sales-recurring/${user.selectedMerchantAccount}/${recurringId}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.recurringPaymentCancelRequest, actionTypes.recurringPaymentCancelSuccess, actionTypes.recurringPaymentCancelFailure]
    }
  };
}

export function getRecurringPayment(user, recurringId) {

  const endpoint = `sales-recurring/${user.selectedMerchantAccount}/${recurringId}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.recurringPaymentGetRequest, actionTypes.recurringPaymentGetSuccess, actionTypes.recurringPaymentGetFailure]
    }
  };
}

export function updateRecurringPayment(user, recurringId, payload) {

  const endpoint = `sales-recurring/${user.selectedMerchantAccount}/${recurringId}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.recurringPaymentUpdateRequest, actionTypes.recurringPaymentUpdateSuccess, actionTypes.recurringPaymentUpdateFailure],
      body: payload
    }
  };

}

export function sendInvoice(user, invoiceId) {

  const merchantId = user.selectedMerchantAccount;
  const endpoint = `users/accounts/${merchantId}/send-invoice`;
  const body = {
    invoices: [invoiceId],
    type: 'notify'
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.sendInvoiceRequest, actionTypes.sendInvoiceSuccess, actionTypes.sendInvoiceFailure],
      body
    }
  };
}

export function updateInvoice(user, invoiceId, payload) {

  const merchantId = user.selectedMerchantAccount;
  const endpoint = `users/accounts/${merchantId}/invoices/${invoiceId}`;
  const obfuscatedPayload = InvoiceUtil.obfuscatePayload(payload);
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.updateInvoiceRequest, actionTypes.updateInvoiceSuccess, actionTypes.updateInvoiceFailure],
      body: obfuscatedPayload
    }
  };
}

export function setInvoice(invoice) {
  return {
    type: actionTypes.invoiceSelected,
    selectedInvoice: invoice
  };
}

export function setInvoiceForPaymentTip(tip) {
  return {
    type: actionTypes.invoiceForPaymentTipSelected,
    selectedTip: tip
  };
}

export function setClpInvoiceWithoutCd(invoice) {
  return {
    type: actionTypes.invoiceWithoutCdSelected,
    selectedInvoice: invoice
  };

}

export function setClpInvoiceOriginal(invoice) {
  return {
    type: actionTypes.invoiceOriginalSelected,
    selectedInvoice: invoice
  };

}

export function getInvoiceToPay(token, type) {

  const query = `?token=${encodeURIComponent(token)}`;

  let endpoint = 'invoice';

  const isPayNow = type === 'payNow';
  let types;

  if (isPayNow) {
    endpoint += '/pay-now';
    types = [actionTypes.invoicePayNowGetRequest, actionTypes.invoicePayNowGetSuccess, actionTypes.invoicePayNowGetFailure];
  } else {
    types = [actionTypes.invoiceGetRequest, actionTypes.invoiceGetSuccess, actionTypes.invoiceGetFailure];
  }

  return {
    [CALL_API]: {
      endpoint: `${endpoint}${query}`,
      method: 'GET',
      authenticated: false,
      types
    }
  };
}

export function getCustomerInvoices(token) {

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `invoice/customer-invoices${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.customerInvoicesGetRequest, actionTypes.customerInvoicesGetSuccess, actionTypes.customerInvoicesGetFailure]
    }
  };
}

export function addInvoiceNumberToReceipt(receiptId, invoice) {

  const endpoint = 'transactions-receipts/invoice/' + receiptId;

  const body = {
    invoice_number: invoice
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.invoiceNumberSaveRequest, actionTypes.invoiceNumberSaveSuccess, actionTypes.invoiceNumberSaveFailure],
      body
    }
  };
}

export function patchInvoice(user, selectedInvoice, payload) {

  const endpoint = `sales-invoice/${user.selectedMerchantAccount}/${selectedInvoice.id}`;
  const body = payload;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.patchInvoiceRequest, actionTypes.patchInvoiceSuccess, actionTypes.patchInvoiceFailure],
      body
    }
  };
}

export function payInvoice(token, invoiceId, payload) {

  const endpoint = `pay-invoice?token=${encodeURIComponent(token)}`;
  const transactionSource = LabelUtil.getLabel().transactionSource;
  let body;

  const { paymentMethodId, paymentMethodType, paymentMethod, tip, saveOrAutoPay, receipt } = payload;

  if (paymentMethodId) {
    body = {
      payment_method: paymentMethodId,
      payment_method_type: paymentMethodType
    };
  } else {
    if (paymentMethod?.cvv) {
      body = {
        card: {
         ...paymentMethod
        }
      };
    } else {
      body = {
        ach: {
          ...paymentMethod
        }
      }
    }
  }

  if (tip) {
    body = {
      ...body,
      tip_amount: tip
    };
  }

  if (saveOrAutoPay) {
    body = {
      ...body,
      save_payment_method: true
    };
  }

  body = {
    ...body,
    type: 'invoice_payment',
    invoice: invoiceId,
    transaction_source:  transactionSource,
    receipt
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.payInvoiceRequest, actionTypes.payInvoiceSuccess, actionTypes.payInvoiceFailure],
      body
    }
  };

}

export function payInvoiceWithoutToken(user, invoiceId, values) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/pay-invoice`;

  const expirationMonth = moment(values.edate, 'MM/YY').format('MM');
  const expirationYear = moment(values.edate, 'MM/YY').format('YYYY');

  const body = {
    type: 'vt_invoice_payment',
    card: {
      exp_month: expirationMonth,
      exp_year: expirationYear,
      number: FormatTextUtil.parseCardNumberDigits(values.cdigits),
      cvv: values.cvv,
      input_type: 'KEYED'
    },
    invoice: invoiceId,
    transaction_source: 'PA-PORTAL'
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.payInvoiceRequest, actionTypes.payInvoiceSuccess, actionTypes.payInvoiceFailure],
      body
    }
  };
}

export function sendInvoiceNotification(token, invoices) {

  const endpoint = `send-invoice?token=${encodeURIComponent(token)}`;

  const body = {
    type: 'notify',
    invoices
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      types: [actionTypes.sendInvoiceRequest, actionTypes.sendInvoiceSuccess, actionTypes.sendInvoiceFailure],
      body
    }
  };
}

export function refreshInvoiceToken(accountId, token) {
  const endpoint = `send-invoice?token=${encodeURIComponent(token)}`;
  const body = {
    type: 'refresh'
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      types: [actionTypes.refreshInvoiceTokenRequest, actionTypes.refreshInvoiceTokenSuccess, actionTypes.refreshInvoiceTokenFailure],
      body
    }
  };
}

export function createInvoiceReceiptPdf(token, receipt) {

  const endpoint = `create-invoice-pdf/${receipt.receipt_id}?token=${encodeURIComponent(token)}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      types: [actionTypes.createInvoiceReceiptRequest, actionTypes.createInvoiceReceiptSuccess, actionTypes.createInvoiceReceiptFailure]
    }
  };
}

export function updateInvoiceToPay(token, invoiceId, payload) {

  const endpoint = `update-invoice/${invoiceId}?token=${encodeURIComponent(token)}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.updateInvoiceRequest, actionTypes.updateInvoiceSuccess, actionTypes.updateInvoiceFailure],
      body: payload
    }
  };
}

export function updateInvoiceToPayNow(token, invoiceId, payload) {

  const query = `?token=${encodeURIComponent(token)}`;
  const endpoint = `update-invoice-paynow/${invoiceId}${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.updateInvoiceRequest, actionTypes.updateInvoiceSuccess, actionTypes.updateInvoiceFailure],
      body: payload
    }
  };
}
export function uploadBulkInvoice(user,payload) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/bulk-invoices`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.postBulkInvoicesRequest, actionTypes.postBulkInvoicesSuccess, actionTypes.postBulkInvoicesFailure],
      body: payload,
      multipart: true
    }
  };
}

export function getBulkInvoices(user, status) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/bulk-invoices?status=${status}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.getBulkInvoicesRequest, actionTypes.getBulkInvoicesSuccess, actionTypes.getBulkInvoicesFailure],
    }
  };
}

export function getBulkInvoice(user, id, verbose = false) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/bulk-invoices/${id}?verbose=${verbose}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.getBulkInvoiceRequest, actionTypes.getBulkInvoiceSuccess, actionTypes.getBulkInvoiceFailure],
    }
  };
}

export function cancelBulkInvoice(user, id) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/bulk-invoices/${id}`;

  const body = {
    action: 'cancel'
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.cancelBulkInvoiceRequest, actionTypes.cancelBulkInvoiceSuccess, actionTypes.cancelBulkInvoiceFailure],
      body
    }
  };
}

export function sendBulkInvoices(user, id) {
  const endpoint = `users/accounts/${user?.selectedMerchantAccount}/bulk-invoices/${id}`;
  const transactionSource = LabelUtil.getLabel().transactionSource;
  const body = {
    action: 'send',
    transaction_source: transactionSource,
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.sendBulkInvoicesRequest, actionTypes.sendBulkInvoicesSuccess, actionTypes.sendBulkInvoicesFailure],
      body
    }
  };
}

export function completeBulkImport (){
  return {
    type: actionTypes.completeBulkImport
  }
}

export function getPaginatedSeries(user, dateRange, page) {
  const url = `users/accounts/${user.selectedMerchantAccount}/paginated-series`
  const searchParams = new URLSearchParams();

  if(dateRange && dateRange.value !== 'all'){
    searchParams.append('start',moment(dateRange.startDate).tz('America/New_York').format());
    searchParams.append('end', moment(dateRange.endDate).tz('America/New_York').format());
  }

  searchParams.append('pageSize','100');
  searchParams.append('page',page);
  const endpoint = `${url}?${searchParams.toString()}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.seriesPaginatedRequest, actionTypes.seriesPaginatedSuccess, actionTypes.seriesPaginatedFailure]
    }
  };
}

export function getPaginatedInvoices(user, dateRange, page, filter) {
  const url = `users/accounts/${user.selectedMerchantAccount}/paginated-invoices`
  const searchParams = new URLSearchParams();

  if (dateRange && dateRange.value !== 'all') {
    searchParams.append('start', moment(dateRange.startDate).tz('America/New_York').format());
    searchParams.append('end', moment(dateRange.endDate).tz('America/New_York').format());
  }

  if (filter && filter !== InvoiceFilterTypes.INVOICES) {
    searchParams.append('filter', InvoiceFilterTypesMapping[filter]);
  }

  searchParams.append('pageSize', '100');
  searchParams.append('page', page);
  const endpoint = `${url}?${searchParams.toString()}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.invoicesPaginatedRequest, actionTypes.invoicesPaginatedSuccess, actionTypes.invoicesPaginatedFailure]
    }
  };
}

export function getPaginatedInvoicesAndSeries(user, dateRange, page, filter) {
  const url = `users/accounts/${user.selectedMerchantAccount}/invoices-series`
  const searchParams = new URLSearchParams();

  if (dateRange && dateRange.value !== 'all') {
    searchParams.append('start', moment(dateRange.startDate).tz('America/New_York').format());
    searchParams.append('end', moment(dateRange.endDate).tz('America/New_York').format());
  }

  if (filter) {
    searchParams.append('filter', InvoiceFilterTypesMapping[filter]);
  }

  searchParams.append('pageSize', '100');
  searchParams.append('page', page);
  const endpoint = `${url}?${searchParams.toString()}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.invoicesAndSeriesPaginatedRequest, actionTypes.invoicesAndSeriesPaginatedSuccess, actionTypes.invoicesAndSeriesPaginatedFailure]
    }
  };
}

export function shouldRefreshInvoiceData(value) {
  return {
    type: actionTypes.shouldRefreshInvoiceData,
    shouldRefresh: value
  }
}
/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
