/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';

import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import { accountMenuStyles, accountButtonStyles } from '../../jss/accountMenuStyles';

import {
  setOpenAccountSwitchDialog,
  setOpenAddAccountDialog,
  setOpenRelatedMidsDialog,
  toggleHistoricHardwareMode
} from '../../actions/userExperienceActions';
import { logoutUser } from '../../actions/authActions';
import { NavLink } from 'react-router-dom';
import routes from '../../constants/routes';
import AddAccountDialog from '../account/AddAccountDialog';
import RelatedMidsDialog from '../account/RelatedMidsDialog';
import LabelUtil from '../util/LabelUtil';
import _ from 'lodash';
import PropTypes from 'prop-types';
import UserUtil from '../util/UserUtil';
import { getAccountProfile } from '../../actions/accountProfileActions';
import { getEmployees } from '../../actions/employeesActions';
import {buttonText} from '../../constants/planInformation';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconUtil from '../util/IconUtil';
import IconButton from '../shared/IconButton'

const actionsMap = {
  'default': {
    dispatchAction: false
  },
  'logoutUser': {
    dispatchAction: logoutUser()
  },
  'toggleHistoricHardwareMode': {
    dispatchAction: toggleHistoricHardwareMode()
  },
  'openAddAccountDialog': {
    dispatchAction: setOpenAddAccountDialog(true)
  },
  'openRelatedMidsDialog': {
    dispatchAction: setOpenRelatedMidsDialog(true)
  },
  'openAccountSwitchDialog': {
    dispatchAction: setOpenAccountSwitchDialog(true)
  }
};

export default class AccountMenu extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);

    this.state = {
      openAccountSwitchDialog: false,
      openAddAccountDialog: false,
      accountMenuAnchorEl: null,
      theme: 'light'
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {

    const { user } = this.props;

    if ((user.selectedMerchantAccount !== prevProps.user.selectedMerchantAccount) &&
      (user && user.selectedMerchantAccount)) {
      this.loadData();
    }
  }

   loadData() {
    const { user, accountProfile, dispatch, employees } = this.props;
    const userExists = user && user.selectedMerchantAccount;
    const accountProfileIsEmptyAndNotFetching = _.isEmpty(accountProfile?.data) && !accountProfile?.isFetching;
    const employeesAreEmptyAndNotFetching = _.isEmpty(employees?.data) && !employees?.isFetching;

    if (userExists && accountProfileIsEmptyAndNotFetching) dispatch(getAccountProfile(user));
    if (userExists && employeesAreEmptyAndNotFetching) dispatch(getEmployees(user));
  }

  onClick(event){
    const { dispatch } = this.props;
    const {value} = event.currentTarget.dataset;
    const action = actionsMap[value] || actionsMap.default;
    action.dispatchAction && dispatch(action.dispatchAction);
    this.handleMenuClose();
  }

  //Returns first and last initials of a name, without any middle names
  getInitials(profileName) {

    return profileName.split(' ').map((currentWord, index, fullArray) => {
      return (index === 0 || index === (fullArray.length - 1))
        ? currentWord[0]
        : ''
    }).join('').toUpperCase();

  }

  handleMenuOpen(event) {
    this.setState({accountMenuAnchorEl: event.currentTarget})
  }

  handleMenuClose() {
    this.setState({accountMenuAnchorEl: null})
  }

  render() {

    const { user, auth, userExperience: {openAddAccountDialog, openRelatedMidsDialog, historicHardwareMode}, employees, t } = this.props;
    const { isOwner, isSpr } = auth;
    const { accountMenuAnchorEl } = this.state;

    const membershipStatus = user && user.data && user.data.username ? UserUtil.membershipStatus(user) : null;
    const currentPlanAccount = user && user.data && user.data.username ? UserUtil.accountCurrentPlan(user) : null

    const version = applicationVersionCommit;
    const appRoutePrefix = globalApplicationLabel.path;
    const accountRoute = appRoutePrefix + routes.account.root;
    const label = LabelUtil.getLabel();
    const isMbpUser = this.props.user && UserUtil.isMybizperks(this.props.user);
    const isHbtUser = this.props.user && UserUtil.isHumbolt(this.props.user);
    const isCsOrCsWriteUser = user && auth && (UserUtil.isUserCS(user, auth) || UserUtil.isUserCSWrite(user));

    const selectedMerchantAccount = user && user.data && user.data.merchantAccounts && user.data.merchantAccounts[0] ?
      _.find(user.data.merchantAccounts, {mea_id: Number(user.selectedMerchantAccount)}) : null;

    const LoadingIndicator = <CircularProgress size={15} thickness={2} className='spinner' color='inherit'/>;

    const employeeData = selectedMerchantAccount && employees && employees.data && _.find(employees.data , {id: Number(selectedMerchantAccount.mid_user_id)})
    const profilePrincipalName = isCsOrCsWriteUser ? t('CustomerService') : UserUtil.getPrincipalName(employeeData);
    const profilePrincipalInitials = profilePrincipalName === 'Name Missing' ? LoadingIndicator : this.getInitials(profilePrincipalName);

    const hasUserData = user && user.data && user.data.username
    const userText =  hasUserData ? user.data.username : t('NoAccountName');

    const LogoutIcon = IconUtil.getIcon('Logout');
    const ArrowForwardIcon = IconUtil.getIcon('Deposit');
    const ChatBubbleOutlineIconIcon = IconUtil.getIcon('ChatBubbleOutlineIcon');
    const ManageAccountsIconIcon = IconUtil.getIcon('ManageAccountsIcon');
    
    const menuTitle = (
      <Box className={'menuTitle'}>
        <h2>{profilePrincipalName}</h2>
        <p>{userText}</p>
        <p className={'capitalize'}>
          {`${selectedMerchantAccount?.role}, `}
          <span className={'membershipType'}>{buttonText[currentPlanAccount]}</span>
        </p>
        {selectedMerchantAccount?.mid && (
          <p>MID <span className={'midInfo'}>{selectedMerchantAccount?.mid}</span></p>
        )}
      </Box>
    );

    const relatedMidsMenuItem = !!(isSpr && user.data.relatedMids && user.data.relatedMids.length) && (
      <MenuItem
          className={'userAccountMenuRelatedMids'}
          data-value='openRelatedMidsDialog'
          onClick={this.onClick}
      >
        {t('Related Mids')}
      </MenuItem>
    );

    const addAccountMenuItem = isOwner && (user || null) && (isMbpUser || isHbtUser) && (
      <MenuItem
          className={'userAccountMenuAddAccount'}
          data-value='openAddAccountDialog'
          onClick={this.onClick}
      >
        {t('Add an Account')}
      </MenuItem>
    );

    const chatSupportItem = !isHbtUser && (
      <MenuItem
          onClick={this.onClick}
          value={4}
      >
        <a
            className={'userAccountMenuLink'}
            href={label.chatSupport}
            rel='noopener noreferrer'
            target='_blank'
            onClick={this.props.closeMainMenu}
            data-test-id='chatSupportItemLink'
        >
          {t('LiveChat')}
          <ListItemIcon>
            {ChatBubbleOutlineIconIcon}
          </ListItemIcon>
        </a>
      </MenuItem>
    );

    // TODO: Need a task to move out this two dialogs to be inside the Menu
    const addAccountDialog = openAddAccountDialog && (user || null) && (isMbpUser || isHbtUser) && isOwner && (
      <AddAccountDialog id='addAccountDialog' {...this.props} />
    );

    const relatedMidsDialog = (isSpr && openRelatedMidsDialog && <RelatedMidsDialog {...this.props}/>);

    
    const isPayanywhereMerchant = UserUtil.isPayanywhere(user);
    const hardwareModeClass = historicHardwareMode ? '' : 'multiLineItem';
    const hardwareModeItemText = historicHardwareMode
      ? t('HistoricHardwareMode.ExitTerminalView')
      : (<div className='itemText'>
          <div className='itemTitle'>{t('HistoricHardwareMode.SwitchToTerminalView')}</div>
          <div className='itemDescription'>{t('HistoricHardwareMode.Description')}</div>
        </div>);

    const hardwareModeIcon = historicHardwareMode ? (
      <IconButton
        className='closeButtonGradient'
        size='large'>
        {IconUtil.getIcon('Clear')}
      </IconButton>
    ) : ArrowForwardIcon;

    const historicHardwareModeToggle = isPayanywhereMerchant ? (
      <Box>
        <MenuItem
          data-value='toggleHistoricHardwareMode'
          onClick={this.onClick}
          className={`toggleHistoricHardwareMode ${hardwareModeClass}`}
        >
          {hardwareModeItemText}

          <ListItemIcon>
            {hardwareModeIcon}
          </ListItemIcon>
        </MenuItem>
        <Divider/>
      </Box> ) : null;

    return (
      <Box
          className={'accountMenu'}
          sx={accountButtonStyles}
      >

        <Avatar
          className={'accountMenuAvatar'}
          aria-controls='account-menu'
          aria-haspopup='true'
          onClick={this.handleMenuOpen}
        >
          {profilePrincipalInitials}
        </Avatar>

        <Menu
            anchorEl={accountMenuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            id='account-menu'
            onClose={this.handleMenuClose}
            open={Boolean(accountMenuAnchorEl)}
            sx={accountMenuStyles}
        >

          {menuTitle}

          {historicHardwareModeToggle}

          <MenuItem onClick={this.onClick}>
            <NavLink
                onClick={this.props.closeMainMenu}
                to={accountRoute}
            >
              {t('User Settings')}
              <ListItemIcon>
                {ManageAccountsIconIcon}
              </ListItemIcon>
            </NavLink>
          </MenuItem>

          <Divider/>
          {relatedMidsMenuItem}
          {addAccountMenuItem}
          {chatSupportItem}
          <Divider/>

          <MenuItem
              data-value='logoutUser'
              onClick={this.onClick}
          >
            <span>{t('Log Out')}</span>
            <ListItemIcon>
              {LogoutIcon}
            </ListItemIcon>
          </MenuItem>
        </Menu>

        {addAccountDialog}
        {relatedMidsDialog}
      </Box>
    );
  }

}

AccountMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object
};


