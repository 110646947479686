/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

// applicationStatuses

export const VALIDATION_ERROR = 'validation error';
export const SYSTEM_ERROR = 'system error';
export const FAILED = 'failed';
export const FINALIZED = 'finalized';
export const SENT_TO_SYSTEM = 'sent to system';

// underwritingStatuses

export const DECLINED = 'Declined';
export const DECLINED_THREATMATRIX = 'Declined - Threatmatrix';

export const OWNER = 'owner';
export const BUSINESS = 'business';
export const BANK_VALIDATED = 'bank_validated';
export const HARDWARE = 'hardware';

export const WORK_IN_PROGRESS = 'work in progress';
export const IN_PROGRESS = 'in progress';
export const WAITING_FOR_MERCHANT = 'waiting for merchant';
export const MERCHANT_APPROVED = 'merchant approved';

export const APPROVED = 'Approved';
export const APPROVED_COLLECTIONS = 'Approved - Collections';

export const PENDING_SUBMISSION = 'Pending Submission';

export const APPROVED_STATUS_DAYS = 53;

export default {
  VALIDATION_ERROR,
  APPROVED_STATUS_DAYS,
  MERCHANT_APPROVED,
  SYSTEM_ERROR,
  APPROVED_COLLECTIONS,
  FAILED,
  IN_PROGRESS,
  WAITING_FOR_MERCHANT,
  SENT_TO_SYSTEM,
  DECLINED,
  DECLINED_THREATMATRIX,
  OWNER,
  BUSINESS,
  BANK_VALIDATED,
  HARDWARE,
  WORK_IN_PROGRESS,
  FINALIZED,
  APPROVED,
  PENDING_SUBMISSION
};

