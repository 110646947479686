/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Validator from './util/Validator';
import {Field, getFormValues, isValid, reduxForm, submit} from 'redux-form';
import Button from './shared/Button';
import IconUtils from './util/IconUtil';
import TextField from './shared/TextField';
import TextUtil from './util/FormatTextUtil';
import {logoutUser} from '../actions/authActions';
import twoFactorCodeUtil from './util/twoFactorCodeStatusUtil';
import twoFactorCodeStatusUtil from './util/twoFactorCodeStatusUtil';
import {requestTokenStyles} from '../jss/loginStyles';
import Typography from '@mui/material/Typography';

const validate = values => {
  return Validator.validateMFASetupForm(values);
};

export class MFASetupFormComponent extends Component {

  constructor(props) {
    super(props);

    this.handleEnterPressed = this.handleEnterPressed.bind(this);
    this.requestToken = this.requestToken.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleEnterPressed);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnterPressed);
  }

  handleEnterPressed(event) {
    const { auth, currentValues, isFormValid } = this.props;
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (isFormValid && !twoFactorCodeStatusUtil.isSendingCode(auth.twoFactorCodeStatus) && !twoFactorCodeStatusUtil.isValidatingCode(auth.twoFactorCodeStatus)) {
        const willRequestCode = currentValues.cellPhone && !currentValues.token;
        if (!willRequestCode && twoFactorCodeStatusUtil.isCodeSent(auth.twoFactorCodeStatus)) {
          this.onSubmitClick();
        } else {
          this.requestToken();
        }
      }
      event.preventDefault();
    }
  }

  requestToken = () => {
    this.props.onRequestToken(this.props.currentValues);
  };

  onSubmitClick = () => {
    this.props.dispatch(submit('MFASetupForm'))
  };

  onGoBackClick = () => {
    this.props.dispatch(logoutUser());
  };

  render() {

    const {handleSubmit, t, auth, currentValues, isFormValid, errorMessageComponent} = this.props;

    const willRequestCode = currentValues.cellPhone && !currentValues.token;

    const submitButtonDisabled = (!currentValues.cellPhone && !isFormValid) ||
      twoFactorCodeStatusUtil.isSendingCode(auth.twoFactorCodeStatus)||
      twoFactorCodeStatusUtil.isValidatingCode(auth.twoFactorCodeStatus);
    const submitButtonOnClick =
      !willRequestCode && twoFactorCodeStatusUtil.isCodeSent(auth.twoFactorCodeStatus) && this.onSubmitClick || this.requestToken;

    const cellphoneDisable =  !twoFactorCodeStatusUtil.isDefaultCode(auth.twoFactorCodeStatus);

    return (
      <form className='MFASetupForm' onSubmit={handleSubmit}>

        <div className='disclaimerBox'>
          <span className='icon'>
            <img src='/images/shieldProtect.svg' alt='protect'/>
          </span>
          <div className='content'>
            <h4>{t('MFASetupDisclaimer.Title')}</h4>
            <p>{t('MFASetupDisclaimer.Description')}</p>
            <span>{t('MFASetupDisclaimer.Footnote')}</span>
          </div>
        </div>

        <div className='formRow'>
          <Field
            label={t('TwoFactorForm.MobileNumber')}
            hintText={t('TwoFactorForm.EnterMobileNumber')}
            disabled={cellphoneDisable}
            component={TextField}
            fullWidth={false}
            normalize={TextUtil.formatPhoneNumber}
            name='cellPhone'
          />
        </div>

        {auth.twoFactorSetupEnabled && (
          <>
            <div className='formRow'>
              <Field
                label={t('VerificationCodeLogin')}
                hintText={t('EnterCode')}
                component={TextField}
                fullWidth={false}
                normalize={TextUtil.formatWholeNumber}
                name='token'
                id='token'
                disabled={auth.isFetching || !currentValues.cellPhone || twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus)}
              />
            </div>

            <div className='formRow'>
              <div className='requestTokenContainer'>
                <Typography sx={requestTokenStyles} variant='span' onClick={this.requestToken}>
                  {t('ResendCode')}
                </Typography>
              </div>
            </div>
          </>
        )}


        <div className='formRow'>
          {errorMessageComponent}
        </div>

        <div className='flexRow actionsRow'>
          <Button
            className='enableHover'
            disabled={submitButtonDisabled}
            onClick={submitButtonOnClick}
            label={t(auth.twoFactorCodeStatus)}
            endIcon={IconUtils.getIcon('ArrowRight')}
          />

          <Button
            onClick={this.onGoBackClick}
            label={t('GoBackAction')}
            variant='text'
          />
        </div>

      </form>
    )
  }

}

let MFASetupForm = reduxForm({
  form: 'MFASetupForm',
  validate,
  fields: [
    'cellPhone',
    'token'
  ],
  enableReinitialize: true,
})(MFASetupFormComponent);

function mapStateToProps(state, ownProps) {
  const currentValues = getFormValues('MFASetupForm')(state) || {};
  const isFormValid = isValid('MFASetupForm')(state);
  return {
    initialValues: {
      cellPhone: '',
      token: ''
    },
    currentValues,
    isFormValid
  }
}

MFASetupForm = connect(mapStateToProps)(MFASetupForm);

MFASetupForm.displayName = 'MFASetupForm';
export default MFASetupForm;
