/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import React, {Component} from 'react';
import TitlePanel from './../TitlePanel';
import moment from 'moment';
import BarChart from '../shared/charts/BarChart';
import Toggle from './../Toggle';
import PropTypes from 'prop-types';
import LanguageSelector from '../shared/LanguageSelector';

export default class TopSellers extends Component {

  constructor(props) {
    super(props);

    this.handleMobileFooterToggle = this.handleMobileFooterToggle.bind(this);
    this.handleItemSalesToggle = this.handleItemSalesToggle.bind(this);

    this.state = {
      formatToggle: '$',
      typeToggle: 'ITEM',
    };

  }

  handleMobileFooterToggle(value) {
    this.setState({typeToggle: value});
  };

  handleItemSalesToggle(value) {
    this.setState({formatToggle: value});
  };

  render() {

    const { t } = this.props;
    const itemChartLabels = ['Silver Watch', 'Brown Leather Laptop Bag', 'Black Men\'s Sunglasses', 'Brown Men\'s Watch', 'Khaki Backpack'];
    const itemChartPreviousCount = [25, 100, 2, 100, 201];
    const itemChartCurrentCount = [175, 100, 66, 100, 21];

    const itemSalesTotalChartLabels = ['Silver Watch', 'Brown Leather Laptop Bag', 'Black Men\'s Sunglasses', 'Brown Men\'s Watch', 'Khaki Backpack'];
    const itemSalesTotalChartPreviousCount = [2, 5, 1, 10, 5];
    const itemSalesTotalChartCurrentCount = [12, 9, 2, 6, 2];

    const categoriesChartLabels = ['Women\'s clothes', 'Women\'s Accessories', 'Men\'s clothes', 'Mens\'s Accessories'];
    const categoriesChartPreviousCount = [250, 25, 301, 100];
    const categoriesChartCurrentCount = [55, 87, 175, 100];

    const categoriesSalesTotalChartLabels = ['Women\'s clothes', 'Women\'s Accessories', 'Men\'s clothes', 'Mens\'s Accessories'];
    const categoriesSalesTotalChartPreviousCount = [5, 3, 20, 6];
    const categoriesSalesTotalChartCurrentCount = [1, 5, 17, 5];


    let itemsChart = (
      <BarChart
        chartLabels={itemChartLabels}
        currentData={itemChartCurrentCount}
        previousData={itemChartPreviousCount}
        t={t}
        layoutOptions={{
          padding: {
            bottom: 100,
          }
        }}
      />
    );

    let itemsSalesTotalChart = (
      <BarChart
        chartLabels={itemSalesTotalChartLabels}
        currentData={itemSalesTotalChartCurrentCount}
        previousData={itemSalesTotalChartPreviousCount}
        layoutOptions={{
          padding: {
            bottom: 50,
          }
        }}
        t={t}
      />
    );

    let categoriesChart = (
      <BarChart
        chartLabels={categoriesChartLabels}
        currentData={categoriesChartCurrentCount}
        previousData={categoriesChartPreviousCount}
        layoutOptions={{
          padding: {
            bottom: 100,
          }
        }}
        t={t}
      />
    );

    let categoriesSalesTotalChart = (
      <BarChart
        chartLabels={categoriesSalesTotalChartLabels}
        currentData={categoriesSalesTotalChartCurrentCount}
        previousData={categoriesSalesTotalChartPreviousCount}
        layoutOptions={{
          padding: {
            bottom: 100,
          }
        }}
        t={t}
      />
    );

    let selectedItemChart = this.state.formatToggle === '#' ? itemsChart : itemsSalesTotalChart;
    let selectedCategoryChart = this.state.formatToggle === '#' ? categoriesChart : categoriesSalesTotalChart;

    return (
      <div className='testDriveTopSellers pageScrollableArea'>
        <TitlePanel title='Top Sellers'
                    subText={moment(this.props.dateRange.startDate).format('MM/DD/YYYY h:mm:ss a') + ' - ' +
                    moment(this.props.dateRange.endDate).format('MM/DD/YYYY h:mm:ss a')}/>
        <Toggle rightToggle='#' leftToggle='$' toggleChanged={this.handleItemSalesToggle}/>
        {this.state.typeToggle === 'CATEGORY' ? selectedCategoryChart : selectedItemChart}
        <Toggle toggleClass='mobileFooterToggle' rightToggle='CATEGORY' leftToggle='ITEM' toggleChanged={this.handleMobileFooterToggle}/>
        <LanguageSelector  {...this.props} />
      </div>
    )
  }
}

TopSellers.propTypes = {
  dateRange: PropTypes.object,
};
