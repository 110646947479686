/*
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and
 are protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.
 */

'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, touch, change } from 'redux-form';

import { Link, Stack, Box } from '@mui/material';

import TextField from '../shared/TextField';
import Button from '../shared/Button';
import Validator from '../util/Validator';
import FormatTextUtil from '../util/FormatTextUtil';
import { MultiFactorAuthenticationFormStyles, verifyButtonStyles } from '../../jss/leadSignup/MultiFactorAuthenticationFormStyles';
import applicationConstants from '../../constants/applicationConstants';
import twoFactorRequestTypes from '../../constants/twoFactorRequestTypes';
import { toastr } from 'react-redux-toastr';
import { t } from 'i18next';
import IconUtils from '../util/IconUtil';

const validate = (values) => {
  return Validator.validateMultiFactorAuthentication(values);
};

export class MultiFactorAuthenticationFormComponent extends Component {

  constructor(props) {
    super(props);

    props.initialValues.codeArray.forEach((value, index) => {
      this[`codeFieldRef-${index}`] = React.createRef();
    });
  }

  componentDidMount() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.focusCodeField(0);
        resolve();
      }, 500);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const codeArray = this.props?.currentValues?.codeArray;
    const prevCodeArray = prevProps?.currentValues?.codeArray;

    const prevCodeText = prevCodeArray?.join('');
    const codeText = codeArray?.join('');

    const areStrings = _.isString(prevCodeText) && _.isString(codeText);

    if (areStrings && prevCodeText !== codeText) {
      if (prevCodeText.length < codeText.length) {
        this.focusCodeField(codeText.length);
      }
      this.setFormDirty();
    }

  }

  setFormDirty = () => {
    this.props.dispatch(touch('signupForm', 'codeArray'));
  }

  focusCodeField = (index) => {
    if (index >= 0) {
      this?.[`codeFieldRef-${index}`]?.current?.focus();
    }
  }

  onCodeFieldKeyUp = (index) => (ev) => {
    const { currentValues } = this.props;
    const { codeArray } = currentValues;

    if(['Backspace', 'ArrowLeft'].includes(ev.key) && index > 0) {
      this.focusCodeField(index - 1);
      return;
    }

    if(ev.key === 'ArrowRight' && index < codeArray.length) {
      this.focusCodeField(index + 1);
      return;
    }

    if(ev.key === 'Enter') {
      this.props.submitClick();
    }

  };

  onCodeFieldKeyDown = async (ev) => {
    const code = ev.which || ev.keyCode;

    let charCode = String.fromCharCode(code).toLowerCase();
    if (((ev.ctrlKey || ev.metaKey) && charCode === 'v')) {
      try {
        const clipboardText = (await navigator.clipboard.readText())?.trim();
        if (clipboardText.length === 6) {
          this.props.dispatch(change('signupForm', 'codeArray', [...clipboardText]));
        }
      } catch(e) {
        toastr.error('Error', t('ClipboardPermissonError'));
      }
    }
  };

  render() {
    const { auth, handleSubmit, currentValues, phoneNumLastFourDigits, handleResendCode, t, error } = this.props;

    const formDisabled = auth.twoFactorCodeStatus === applicationConstants.codeStatus.validating ||
      auth.twoFactorCodeStatus === applicationConstants.codeStatus.sending || auth.isFetching;

      const actionLabel = t(auth.twoFactorCodeStatus === applicationConstants.codeStatus.default ? applicationConstants.codeStatus.sent : auth.twoFactorCodeStatus);

    const createAccountButton = (
      <Button
        className='verifyButton enableHover signupBtn'
        id='loginButton'
        type='submit'
        label={actionLabel}
        endIcon={IconUtils.getIcon('ChevronRightIcon')}
        sx={verifyButtonStyles}
        disabled={formDisabled}
        form='multiFactorAuthenticationForm'
      />
    );

    return (
      <Stack
        id='twoFactorStep'
        direction='column'
        sx={MultiFactorAuthenticationFormStyles}>

        <h1>{t('Signup.Mfa.Title')}</h1>

        <p className='mfaInstructionText'>{t('Signup.Mfa.Description', { phoneNumLastFourDigits })}</p>

        <Box className={'linkWrapper'}>
          <Link
            className='resendLink'
            onClick={handleResendCode}
            disabled={formDisabled}>
            {t('Signup.Mfa.ResendCode')}
          </Link>
        </Box>

        <form id='multiFactorAuthenticationForm' className='formRoot multiFactorAuthenticationForm' onSubmit={handleSubmit}>

          <div className='flexRow codeArrayRow'>
            {currentValues?.codeArray?.map((code, index) => (
              <Field
                key={`codeArrayKey[${index}]`}
                component={TextField}
                name={`codeArray[${index}]`}
                className={'sixthScreen'}
                inputRef={this[`codeFieldRef-${index}`]}
                onKeyUp={this.onCodeFieldKeyUp(index)}
                onKeyDown={this.onCodeFieldKeyDown}
                maxLength={1}
                normalize={FormatTextUtil.formatWholeNumber}
                autoComplete='off'
                disabled={formDisabled}
              />
            ))}
          </div>

          {!!auth.leadSignupError && <div className='mfaError'>{auth.leadSignupError}</div>}
          {error && <div className='mfaError'>{t(error)}</div>}

          <Box className={'linkWrapper'}>
            <Link
              className='getCodeByEmail'
              disabled={formDisabled}
              onClick={() => handleResendCode(twoFactorRequestTypes.EMAIL)}>
              {t('Signup.Mfa.EmailVerify')}
            </Link>
          </Box>

          <Box className={'linkWrapper'}>
            <Link
              className='getCodeByVoice'
              disabled={formDisabled}
              onClick={() => handleResendCode(twoFactorRequestTypes.VOICE)}>
              {t('Signup.Mfa.PhoneVerify')}
            </Link>
          </Box>
        </form>

        {createAccountButton}

      </Stack>
    );
  }
}

const MultiFactorAuthenticationForm = reduxForm({
  form: 'signupForm',
  fields: [
    'codeArray',
  ],
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnBlur: false,
})(MultiFactorAuthenticationFormComponent);

export function mapStateToProps(state, ownProps) {
  const currentValues = getFormValues('signupForm')(state) || {};
  const initialValues = {
    codeArray: Array(6).fill(''),
    ...currentValues
  };

  return {
    initialValues,
    currentValues
  };
}

export default connect(mapStateToProps)(MultiFactorAuthenticationForm);
