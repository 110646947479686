/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {useEffect, Fragment, useState} from 'react';
import {
  Dialog,
  Divider,
  IconButton,
  Typography,
  Slide,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import HeaderLabelLogo from '../../shared/HeaderLabelLogo';
import {
  BulkInvoicesProgressDialogStyles
} from '../../../jss/inlineStyles'
import Button from '../../shared/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (<Slide direction='up'
                 ref={ref}
                 {...props}
  />);
});

export default function UploadProgressDialog({
                                               isOpen,
                                               onClose,
                                               data,
                                               getProgress,
                                               onCancel,
                                               t,
                                               importInProgress,
                                               uploadError
                                             }) {

  useEffect(() => {
    const timer = setInterval(() => {
      getProgress();
    }, 1000);
    if(uploadError) getErrorMessages(uploadError);
    return () => {
      clearInterval(timer);
    };
  }, [data, uploadError]);

  const [errorTitle, setErrorTitle] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const progressValue = data?.progress ?? 0;
  const isCompleted = progressValue === 100;

  const getErrorMessages = (error)=>{
    const fileErrorMapping = {
      'CSV File headers are missing or incorrect': {
        title: t('BulkInvoices.MissingColumns'),
        description: t('BulkInvoices.MissingColumnsDescription'),
      },
      'CSV File is missing header row':{
        title: t('BulkInvoices.MissingHeaderRow'),
        description: t('BulkInvoices.MissingHeaderRowDescription'),
      },
      defaultError: {
        title: t('BulkInvoices.UploadFailed'),
        description: t('BulkInvoices.UploadErrorDescription')
      }
    };
    const errorTexts= fileErrorMapping[uploadError] ?? fileErrorMapping.defaultError;
    setErrorTitle(errorTexts.title);
    setErrorDescription(errorTexts.description);
  }

  return (
    <Fragment>
      <Dialog
        TransitionComponent={Transition}
        data-testid='uploadProgressDialog'
        fullScreen
        onClose={onClose}
        open={isOpen}
      >
        <Box sx={BulkInvoicesProgressDialogStyles.wrapper}>
          <Box alignItems='center'
               display='flex'
               flexDirection='row'
               gap='10px'
          >
            <Box className='appBarItem appBarItemLabelLogo'>
              <HeaderLabelLogo/>
            </Box>
            <Divider flexItem
                     orientation='vertical'
            />
            <Typography sx={BulkInvoicesProgressDialogStyles.topLabel}>
              {t('ImportInvoices')}
            </Typography>
          </Box>
          <IconButton
            aria-label='close'
            color='inherit'
            edge='start'
            onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        {uploadError ?
          <Box sx={BulkInvoicesProgressDialogStyles.textContainer}>
            <Typography
              data-testid='uploadProgressDialogTitle'
              sx={BulkInvoicesProgressDialogStyles.textTitle}
            >
              {errorTitle}
            </Typography>
            <Typography
              data-testid='uploadProgressDialogText'
              sx={{
              ...BulkInvoicesProgressDialogStyles.text,
              width: '50%'
            }}>
              {errorDescription}
            </Typography>
          </Box>
          : <Box sx={BulkInvoicesProgressDialogStyles.textContainer}>
            <Typography
              data-testid='uploadProgressDialogTitle'
              sx={BulkInvoicesProgressDialogStyles.textTitle}
            >{data?.status === 'ready' ? t('BulkInvoices.UploadComplete') : t('BulkInvoices.UploadInProgress')}</Typography>
            <Typography sx={BulkInvoicesProgressDialogStyles.text}>
              {t('BulkInvoices.ProgressDialogDescription')}
            </Typography>
            <Box sx={BulkInvoicesProgressDialogStyles.progressWrapper}>
              <CircularProgress
                size={200}
                value={100}
                variant='determinate'
                sx={{
                  color: '#D8DDE3'
                }}
              />
              <CircularProgress
                size={200}
                value={progressValue}
                variant={importInProgress ? 'indeterminate' : 'determinate'}
                sx={{
                  position: 'absolute',
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  }
                }}
              />
              <Box
                sx={BulkInvoicesProgressDialogStyles.progressLabelWrapper}
              >
                <Typography
                  component='div'
                  data-testid='progressLabel'
                  sx={BulkInvoicesProgressDialogStyles.progressLabel}
                  variant='caption'
                >
                  {importInProgress ? t('BulkInvoices.Uploading') : `${Math.round(progressValue)}%`}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
        {!importInProgress &&
          <Box sx={BulkInvoicesProgressDialogStyles.buttonContainer}>
            <Button
              label={t(isCompleted ? 'Continue' : 'HideProgress')}
              onClick={onClose}
              sx={BulkInvoicesProgressDialogStyles.continueButton}
            />
            {!uploadError && !isCompleted && <Button
              label={t('BulkInvoices.ProgressDialogCancelButton')}
              onClick={onCancel}
              sx={BulkInvoicesProgressDialogStyles.cancelButton}
            />}
          </Box>}
        {uploadError &&
          <Box sx={BulkInvoicesProgressDialogStyles.buttonContainer} data-testid='uploadErrorButtonContainer'>
            <Button
              label={t('StartOver')}
              onClick={onClose}
              sx={BulkInvoicesProgressDialogStyles.continueButton}
            />
          </Box>}
      </Dialog>
    </Fragment>
  );
}
