/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

export default function InvoicePaidImg({brandColor}) {
  return (
      <svg height='311.212'
           viewBox='0 0 309.166 311.212'
           width='309.166'
           xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <style>
            {'.a{fill:#fff;}'}
            {'.b,.d,.g{fill:#1a1e22;}'}
            {'.c{fill:#f9f9f9;}'}
            {'.c,.d,.g{stroke:#1a1e22;}'}
            {'.c,.h{stroke - width:2px;}'}
            {'.d{stroke - width:0.3px;}'}
            {`.e{fill:${brandColor}}`}
            {'.f{font - size:17px;font-family:ArialRoundedMTBold, Arial Rounded MT Bold;}'}
            {'.h{fill:none;stroke:#000;stroke-linecap:round;}'}
          </style>
        </defs>
        <g transform='translate(-472.552 -514.4)'>
          <path className='a'
                d='M402.707,951.775q3.218-6.378,6.02-12.957c11.53-27.166,17.8-58.119,9.733-87.131-1.4-5.052-2.966-13.46-5.893-17.762-3.2-4.709-13.2-6.745-18.41-8.119a90.574,90.574,0,0,0-43.233-.581,82.6,82.6,0,0,0-28.585,12.2c-4.012,2.806-4.677,5.2-6.777,9.326-2.54,5-6.34,9.621-9.99,13.84-11.456,13.244-25.55,24.214-36.5,37.841-23.662,29.434-27.95,73.143,2.342,99.757,34.546,30.352,91.353,13.233,117.3-20.2C395.009,969.873,398.16,960.788,402.707,951.775Z'
                transform='translate(223.583 -189.387)'
          />
          <g transform='translate(534.527 514.4)'>
            <g transform='translate(5.616 20.803)'>
              <path className='a'
                    d='M673.668,451.536c-10.739,14.759-25.264,25.177-43.05,29.275s-40.037.923-52.664-12.257a71.349,71.349,0,0,0,8.566-29.724c1.047-19.214-7.812-38.161-3.15-57.227,2.582-10.558,9-20.362,18.325-25.943s21.592-6.479,31.136-1.278c11.524,6.279,17.178,19.651,20.133,32.438,3.005,13,4.108,26.412,7.715,39.256C663.269,435.3,667.4,444.436,673.668,451.536Z'
                    transform='translate(-576.922 -349.907)'
              />
              <path className='b'
                    d='M611.63,479.536c-13.855,0-28.642-4.061-38.392-14.237a1.03,1.03,0,0,1-.165-1.2,70.3,70.3,0,0,0,8.447-29.3c.437-8.026-.908-16.185-2.209-24.076-1.795-10.887-3.651-22.144-.913-33.34,2.792-11.417,9.643-21.105,18.8-26.582,10-5.984,22.624-6.494,32.159-1.3,9.862,5.374,16.808,16.514,20.644,33.111,1.3,5.638,2.264,11.432,3.193,17.035a208.143,208.143,0,0,0,4.51,22.174c2.9,10.312,7.192,18.742,12.769,25.057a1.03,1.03,0,0,1,.061,1.288A79.732,79.732,0,0,1,651.4,467.052a71.158,71.158,0,0,1-24.522,10.8A68.518,68.518,0,0,1,611.63,479.536ZM575.245,464.4c5.746,5.749,13.8,9.816,23.344,11.782a66.675,66.675,0,0,0,27.828-.345,69.1,69.1,0,0,0,23.814-10.484,77.417,77.417,0,0,0,18.15-17.734c-5.518-6.474-9.776-14.958-12.663-25.235a209.631,209.631,0,0,1-4.559-22.394c-.924-5.572-1.879-11.333-3.167-16.908-3.7-16-10.3-26.685-19.623-31.765-8.905-4.853-20.727-4.359-30.114,1.257-8.684,5.2-15.191,14.418-17.853,25.3-2.638,10.786-.817,21.832.944,32.515,1.319,8,2.683,16.273,2.233,24.524A72.131,72.131,0,0,1,575.245,464.4Z'
                    transform='translate(-572.953 -345.939)'
              />
            </g>
            <g transform='translate(0 75.674)'>
              <path className='a'
                    d='M555.9,638.4c2.181.511,3.5-.355,5.364-1.208,1.7-.777,3.117-1.14,3.733-3.206a16.341,16.341,0,0,0,.2-6.433,8.925,8.925,0,0,0-1.578-3.725c-.849-1.174-2.12-1.674-3.038-2.666-1.552-1.678-1.69-3.978-4.687-3.983C545.838,617.158,546.746,636.253,555.9,638.4Z'
                    transform='translate(-548.074 -616.144)'
              />
              <path className='b'
                    d='M555.2,635.591a6.589,6.589,0,0,1-1.495-.163c-5.773-1.352-8.595-8.651-7.9-14.639.619-5.316,3.728-8.616,8.115-8.616h.015a5.558,5.558,0,0,1,5.417,4.953,1.031,1.031,0,1,1-2.041.285,3.491,3.491,0,0,0-3.379-3.177h-.012c-4.9,0-5.882,5.2-6.067,6.793a14.712,14.712,0,0,0,1.262,7.822,7.815,7.815,0,0,0,5.059,4.572c1.971.461,4.667-.519,5.441-1.979a1.031,1.031,0,1,1,1.821.966C560.337,634.483,557.58,635.591,555.2,635.591Z'
                    transform='translate(-545.704 -612.174)'
              />
            </g>
            <g transform='translate(77.808 53.141)'>
              <path className='a'
                    d='M935.368,526.383c-1.794,1.131-3.241.527-5.037-.1-1.349-.469-.87-.278-2.157.185a3.2,3.2,0,0,1-2.444-.076,4.473,4.473,0,0,1-2.283-2.955c-.549-2.062.117-4.087.355-6.133a7.119,7.119,0,0,1,.419-1.959,2.4,2.4,0,0,1,1.367-1.391c.329-.109.685-.121,1.011-.239,1.064-.387.516-2.136.818-3.033A3.8,3.8,0,0,1,929.6,508.3C939.02,504.65,941.831,522.306,935.368,526.383Z'
                    transform='translate(-923.235 -506.784)'
              />
              <g transform='translate(2.92)'>
                <path className='b'
                      d='M944.069,524.152a5.477,5.477,0,0,1-3.953-1.515,1.03,1.03,0,0,1,1.5-1.414,3.947,3.947,0,0,0,4.453.345c3.448-2.175,3.822-9.164,1.71-13.36-1.89-3.757-4.611-3.644-6.561-2.889a3.1,3.1,0,0,0-1.687,3.552,1.031,1.031,0,1,1-2.026.376,5.152,5.152,0,0,1,2.969-5.85c3.769-1.46,7.188-.008,9.147,3.884,2.558,5.084,2.045,13.194-2.452,16.03A5.846,5.846,0,0,1,944.069,524.152Z'
                      transform='translate(-937.405 -502.84)'
                />
              </g>
            </g>
            <g transform='translate(34.102 75.66)'>
              <path className='b'
                    d='M719.355,620.75c3.361-1.157,1.6-4.7-1.589-3.36C714.792,618.642,716.289,621.805,719.355,620.75Z'
                    transform='translate(-715.126 -616.063)'
              />
              <path className='b'
                    d='M714.206,618.037a2.962,2.962,0,0,1-2.877-1.8c-.457-1.2,0-2.881,2.08-3.757a3.736,3.736,0,0,1,3.815.3,2.589,2.589,0,0,1,.984,2.484,3.412,3.412,0,0,1-2.475,2.5h0A4.692,4.692,0,0,1,714.206,618.037Zm.978-3.875a2.557,2.557,0,0,0-.976.219c-.712.3-1.1.75-.954,1.122.153.4.839.648,1.807.314h0c.638-.22,1.055-.557,1.114-.9a.552.552,0,0,0-.234-.522A1.2,1.2,0,0,0,715.184,614.162Z'
                    transform='translate(-711.168 -612.105)'
              />
            </g>
            <g transform='translate(61.949 65.22)'>
              <path className='b'
                    d='M854.585,570.293c3.3-1.322,1.3-5.014-1.813-3.5C849.865,568.2,851.574,571.5,854.585,570.293Z'
                    transform='translate(-850.244 -565.407)'
              />
              <path className='b'
                    d='M849.36,567.631a3.036,3.036,0,0,1-2.849-1.741c-.541-1.223-.193-2.99,1.85-3.982a3.687,3.687,0,0,1,3.788.127,2.8,2.8,0,0,1,1.186,2.6,3.406,3.406,0,0,1-2.328,2.655A4.429,4.429,0,0,1,849.36,567.631Zm.889-4.124a2.3,2.3,0,0,0-.988.256c-.719.349-1.059.857-.865,1.294s.9.7,1.845.324h0c.621-.249,1-.608,1.048-.987a.734.734,0,0,0-.336-.679A1.193,1.193,0,0,0,850.249,563.507Z'
                    transform='translate(-846.283 -561.451)'
              />
            </g>
            <g transform='translate(51.308 84.859)'>
              <path className='b'
                    d='M803.742,672.878a6.747,6.747,0,0,0,8.059-1.172c1.727-1.769,1.922-4.077,1.79-6.418-.023-.41-.446-3.549-.446-3.549-3.692,3.376-10.882,4.512-13.419,4.869-.377.053.889,3.121.988,3.3A7.281,7.281,0,0,0,803.742,672.878Z'
                    transform='translate(-798.625 -660.709)'
              />
              <path className='b'
                    d='M803.067,670.762a8.147,8.147,0,0,1-3.775-.939,8.287,8.287,0,0,1-3.457-3.388c-.1-.175-.431-1-.687-1.7-.58-1.607-.58-2.092-.366-2.517a1.1,1.1,0,0,1,.827-.6c7.644-1.074,11.216-3.1,12.867-4.609a1.03,1.03,0,0,1,1.717.623c.072.531.43,3.2.454,3.629.133,2.368-.01,5.074-2.082,7.2A7.646,7.646,0,0,1,803.067,670.762Zm-5.413-5.3A6.231,6.231,0,0,0,800.246,668h0a5.69,5.69,0,0,0,6.844-.979c1.509-1.545,1.612-3.624,1.5-5.64-.008-.141-.093-.835-.2-1.7-2.639,1.741-6.549,3.05-11.479,3.832C797.139,664.219,797.517,665.176,797.654,665.465Z'
                    transform='translate(-794.651 -656.74)'
              />
            </g>
            <g transform='translate(4.472 20.887)'>
              <path className='b'
                    d='M580.849,359.237a26.317,26.317,0,0,1,7.917-6.262c3.358-1.726,7.5-2.353,10.905-.726,9.343,4.461,2.539,20.52-.8,26.96a61.1,61.1,0,0,1-22.193,24.009c-.346.209-3.618-10.149-3.788-11.251C571.194,380.938,573.4,367.862,580.849,359.237Z'
                    transform='translate(-571.332 -350.282)'
              />
              <path className='b'
                    d='M572.741,400.314a1.066,1.066,0,0,1-.451-.1c-.461-.216-.716-.335-2.532-5.915-.639-1.962-1.7-5.357-1.812-6.113-1.523-9.877-.17-23.88,8.193-33.56h0a27.39,27.39,0,0,1,8.226-6.505c4.032-2.071,8.451-2.348,11.82-.739a8.749,8.749,0,0,1,4.669,5.3c2.466,7.348-2.671,18.576-4.994,23.061a62.048,62.048,0,0,1-22.576,24.418A1.05,1.05,0,0,1,572.741,400.314Zm.774-1.68h0ZM591.354,348.4a13.373,13.373,0,0,0-6.047,1.552,25.329,25.329,0,0,0-7.608,6.019h0c-7.9,9.149-9.169,22.477-7.716,31.9.143.93,2.244,7.533,3.176,9.947A60.173,60.173,0,0,0,594.03,374.8c6.8-13.124,5.446-19.742,4.87-21.458a6.672,6.672,0,0,0-3.6-4.1A9.133,9.133,0,0,0,591.354,348.4Zm-14.435,6.9h0Z'
                    transform='translate(-567.402 -346.345)'
              />
            </g>
            <g transform='translate(31.512 0)'>
              <path className='b'
                    d='M711.459,299.6c7.582,6.614,19.215,8.972,28.3,4.171,9.028-4.771,14.588-14.838,15.312-25.023S752.134,258.4,746.328,250c-.086,5.986-4.909,12.117-10.345,14.622s-11.611,2.789-17.6,2.732c-9.664-.093-15.157,6.874-14.768,16.542A21.972,21.972,0,0,0,711.459,299.6Z'
                    transform='translate(-702.569 -248.969)'
              />
              <path className='b'
                    d='M725.2,303.364a28.252,28.252,0,0,1-18.385-6.953,23.033,23.033,0,0,1-8.193-16.443c-.217-5.384,1.35-10.089,4.411-13.246a15.207,15.207,0,0,1,11.4-4.368c9.844.1,14.063-1.212,17.156-2.637,5.48-2.526,9.67-8.416,9.746-13.7a1.03,1.03,0,0,1,1.878-.571c6.441,9.321,9.61,19.765,8.925,29.411a34.214,34.214,0,0,1-4.684,15,28.827,28.827,0,0,1-11.174,10.865A23.665,23.665,0,0,1,725.2,303.364Zm-17.03-8.506c7.725,6.739,18.886,8.4,27.142,4.036,8.235-4.352,14.031-13.845,14.766-24.185.6-8.4-1.9-17.465-7.057-25.791a19.768,19.768,0,0,1-10.573,12.671c-5.6,2.583-11.984,2.884-18.038,2.826h-.178a13.134,13.134,0,0,0-9.718,3.743c-2.665,2.748-4.026,6.913-3.832,11.728a20.994,20.994,0,0,0,7.489,14.973Z'
                    transform='translate(-698.601 -245)'
              />
            </g>
          </g>
          <g transform='translate(616.306 601.735)'>
            <g transform='translate(0 15.129)'>
              <g transform='translate(0 0)'>
                <path className='c'
                      d='M524.479,367.908l0,147.8a6.831,6.831,0,0,1-6.842,6.82l-139.42,0a6.831,6.831,0,0,1-6.842-6.821V491.582l.115-13.292,0-146.949a6.831,6.831,0,0,1,6.842-6.82l135.464,0a10.682,10.682,0,0,1,10.682,10.682v32.7Z'
                      transform='translate(-370.546 -324.119)'
                />
                <path className='b'
                      d='M522.017,367.314q0,9.047,0,18.094,0,20.888,0,41.775,0,23.564,0,47.128,0,17.114,0,34.228c0,2.137.048,4.28,0,6.417-.075,3.312-2.269,6.26-5.757,6.391-.6.023-1.207,0-1.809,0H396.834c-6.628,0-13.3.235-19.93,0-4.079-.145-6.392-3.57-6.392-7.355q0-4.531,0-9.061c0-5.2-.034-10.407.011-15.609q.088-10.19.1-20.378,0-18,0-36,0-23.618,0-47.236V345.22q0-5.867,0-11.734c0-3.536-.157-7.746,4.069-9.147,1.937-.642,4.539-.258,6.532-.258H510.666a14.088,14.088,0,0,1,3.3.261,10.666,10.666,0,0,1,8.052,10.464c.074,6.333,0,12.671,0,19q0,6.751,0,13.5c0,.34,1.6.088,1.6-.43,0-10.894.2-21.8,0-32.7a10.992,10.992,0,0,0-9.01-10.656,17.674,17.674,0,0,0-3.142-.185H381.129c-2.545,0-5.331-.287-7.7.8a7.4,7.4,0,0,0-4.4,6.794c-.01.758,0,1.516,0,2.274q0,14.609,0,29.218,0,23.383,0,46.765,0,22.393,0,44.786,0,11.64,0,23.281c-.016,9.847-.116,19.693-.116,29.542,0,2.694-.054,5.394,0,8.087a7.206,7.206,0,0,0,7.312,7.185c1.862.043,3.731,0,5.593,0H514.323c.585,0,1.178.023,1.764-.015a7.749,7.749,0,0,0,7.529-7.413c.175-6.512,0-13.053,0-19.567q0-21.613,0-43.225,0-23.8,0-47.592,0-16.334,0-32.669v-4.717C523.62,366.545,522.017,366.8,522.017,367.314Z'
                      transform='translate(-368.885 -323.31)'
                />
              </g>
              <g transform='translate(14.58 161.352)'>
                <g transform='translate(0 9.959)'>
                  <path className='d'
                        d='M413.49,840.512h55.077c.91,0,1.236-1.159.146-1.159H413.636c-.91,0-1.236,1.159-.146,1.159Z'
                        transform='translate(-412.803 -839.353)'
                  />
                </g>
                <path className='d'
                      d='M413.49,810.512h55.077c.91,0,1.236-1.159.146-1.159H413.636c-.91,0-1.236,1.159-.146,1.159Z'
                      transform='translate(-412.803 -809.353)'
                />
              </g>
              <g transform='translate(16.746 68.068)'>
                <g transform='translate(0 9.959)'>
                  <path className='d'
                        d='M622.582,559.512h55.077c.91,0,1.236-1.159.146-1.159H622.728c-.909,0-1.235,1.159-.146,1.159Z'
                        transform='translate(-621.896 -558.353)'
                  />
                </g>
                <path className='d'
                      d='M622.582,529.512h55.077c.91,0,1.236-1.159.146-1.159H622.728c-.909,0-1.235,1.159-.146,1.159Z'
                      transform='translate(-621.896 -528.353)'
                />
              </g>
              <g transform='translate(14.434 90.55)'>
                <path className='e'
                      d='M5.809,0h109.52a5.809,5.809,0,1,1,0,11.619H5.81A5.81,5.81,0,0,1,0,5.809H0A5.809,5.809,0,0,1,5.809,0Z'
                      transform='translate(2.566 2)'
                />
                <path className='e'
                      d='M2.382,0H20.524a2.382,2.382,0,0,1,2.382,2.382V9.237a2.382,2.382,0,0,1-2.382,2.382H2.382A2.382,2.382,0,0,1,0,9.237V2.382A2.382,2.382,0,0,1,2.382,0Z'
                      transform='translate(103.122 54.783)'
                />
                <path className='b'
                      d='M535.491,649.226H418.4a20.609,20.609,0,0,1-2.415,0c-2.413-.292-1.917-3.08-1.917-4.8V600.23c0-1.33-.069-2.98,1.639-3.183a31.269,31.269,0,0,1,3.593,0H534.074c1.444,0,2.745.282,2.882,2.158.12,1.642,0,3.338,0,4.983v55.26c0,.4.038.82,0,1.217-.19,1.994-1.424,1.838-2.828,1.838H514.245c-.621,0-1.7.937-.616.937h20.537c2.3,0,4.354-.834,4.4-3.429.033-2.06,0-4.123,0-6.184V604.59c0-1.83.042-3.665,0-5.494-.045-1.978-1.407-2.949-3.265-2.986-3.656-.073-7.321,0-10.978,0h-97.8c-3.438,0-6.887-.081-10.324,0-2.092.049-3.686,1.194-3.729,3.366-.047,2.4,0,4.8,0,7.2v37.061c0,1.414-.4,3.814.385,5.1.865,1.41,2.4,1.33,3.81,1.33H534.875c.621,0,1.7-.937.616-.937Z'
                      transform='translate(-412.364 -596.074)'
                />
                <g transform='translate(4.639 13.296)'>
                  <path className='b'
                        d='M427.011,637.3H539.226a42.184,42.184,0,0,0,4.586,0,1.886,1.886,0,0,1,.208,0c.887,0,1.256-1.1.163-1.1H431.968a42.169,42.169,0,0,0-4.586,0,1.915,1.915,0,0,1-.208,0c-.886,0-1.255,1.1-.163,1.1Z'
                        transform='translate(-426.337 -636.125)'
                  />
                </g>
                <g transform='translate(4.469 26.575)'>
                  <path className='b'
                        d='M426.5,677.3H538.716a42.178,42.178,0,0,0,4.586,0,1.938,1.938,0,0,1,.209,0c.886,0,1.255-1.1.163-1.1H431.457a42.186,42.186,0,0,0-4.586,0,1.9,1.9,0,0,1-.208,0c-.886,0-1.255,1.1-.163,1.1Z'
                        transform='translate(-425.826 -676.125)'
                  />
                </g>
                <g transform='translate(4.469 39.854)'>
                  <path className='b'
                        d='M426.5,717.3H538.716a42.193,42.193,0,0,0,4.586,0,1.938,1.938,0,0,1,.209,0c.886,0,1.255-1.1.163-1.1H431.457a42.17,42.17,0,0,0-4.586,0,1.885,1.885,0,0,1-.208,0c-.886,0-1.255,1.1-.163,1.1Z'
                        transform='translate(-425.826 -716.125)'
                  />
                </g>
                <g transform='translate(100.933 1.789)'>
                  <path className='b'
                        d='M716.405,602.093V664.8c0,.795,1.618.524,1.618-.143V601.949c0-.795-1.618-.524-1.618.143Z'
                        transform='translate(-716.405 -601.462)'
                  />
                </g>
                <g transform='translate(75.205 1.789)'>
                  <path className='b'
                        d='M638.905,602.093v49.429c0,.795,1.618.524,1.618-.143v-49.43c0-.795-1.618-.524-1.618.143Z'
                        transform='translate(-638.905 -601.462)'
                  />
                </g>
                <g transform='translate(13.348 1.789)'>
                  <path className='b'
                        d='M452.571,602.093v49.429c0,.795,1.619.524,1.619-.143v-49.43c0-.795-1.619-.524-1.619.143Z'
                        transform='translate(-452.571 -601.462)'
                  />
                </g>
              </g>
              <text className='f'
                    transform='translate(16.7 36.883)'
              >
                <tspan x='0'
                       y='0'
                >Paid!
                </tspan>
              </text>
            </g>
            <g transform='translate(108.415 0)'>
              <path className='e'
                    d='M1181.246,1098.921c-1.131,4.293-.191,9.091,4.016,13.309,7.149,7.167,20.334,7.219,26.135-4.288,5.058-10.034-4.9-21.756-15.532-21.073A16.638,16.638,0,0,0,1181.246,1098.921Z'
                    transform='translate(-1180.804 -1055.508)'
              />
              <g transform='translate(42.843)'>
                <g transform='translate(0)'>
                  <path className='g'
                        d='M1520.545,813.217a.49.49,0,0,1-.1-.01.468.468,0,0,1-.362-.554l2-9.519a.468.468,0,0,1,.917.192l-2,9.519A.469.469,0,0,1,1520.545,813.217Z'
                        transform='translate(-1520.076 -802.761)'
                  />
                </g>
                <g transform='translate(6.229 7.696)'>
                  <path className='g'
                        d='M1587.059,891.7a.468.468,0,0,1-.312-.817l6.488-5.817a.468.468,0,1,1,.625.7l-6.488,5.817A.466.466,0,0,1,1587.059,891.7Z'
                        transform='translate(-1586.591 -884.944)'
                  />
                </g>
              </g>
              <path className='h'
                    d='M-8973.291-5894.836c.037.163,1.82,4.615,4.093,5.095s6.409-6.9,9.354-10.665'
                    transform='translate(8982.542 5942.336)'
              />
            </g>
          </g>
          <g transform='translate(472.552 652.821)'>
            <path className='a'
                  d='M317.591,921.622a94.52,94.52,0,0,1-12.017,17.351c-11.456,13.244-25.55,24.214-36.5,37.841-23.662,29.434-27.95,73.142,2.342,99.757,34.546,30.352,91.353,13.233,117.3-20.2,5.708-7.355,10.652-16.965,14.521-27.628l-33.966-13.822a61.922,61.922,0,0,1-7.027,10.134c-9.191,10.746-34.53,22.826-45.984,9.314a16.936,16.936,0,0,1-2.415-17.331Z'
                  transform='translate(-248.969 -920.591)'
            />
            <g transform='translate(0 0)'>
              <path className='b'
                    d='M311.284,1089.411a72.816,72.816,0,0,1-19.768-2.65,63.893,63.893,0,0,1-41.5-36.437,65.81,65.81,0,0,1-5.011-26.558,79.709,79.709,0,0,1,5.251-27.023A89.369,89.369,0,0,1,264.3,972.2c5.971-7.427,12.96-14.16,19.72-20.671,5.7-5.486,11.584-11.158,16.809-17.2a93.588,93.588,0,0,0,11.887-17.162,1.031,1.031,0,1,1,1.819.968,95.683,95.683,0,0,1-12.148,17.542c-5.286,6.111-11.21,11.816-16.938,17.334-6.713,6.467-13.655,13.153-19.544,20.478a87.314,87.314,0,0,0-13.715,23.979,77.648,77.648,0,0,0-5.12,26.325,63.761,63.761,0,0,0,4.851,25.732,61.83,61.83,0,0,0,40.156,35.252,73.414,73.414,0,0,0,26.931,2.2,95.817,95.817,0,0,0,36.309-11.344,91.849,91.849,0,0,0,28.615-23.87c5.3-6.834,10.143-15.952,14.025-26.417l-32.2-13.106a63.068,63.068,0,0,1-6.694,9.508,47.8,47.8,0,0,1-14.219,10.669c-4.5,2.289-11.369,5.015-18.5,5-6.281-.014-11.273-2.153-14.837-6.357a18.039,18.039,0,0,1-2.582-18.39,1.031,1.031,0,0,1,1.906.785,15.959,15.959,0,0,0,2.248,16.272c3.155,3.722,7.62,5.616,13.269,5.628h.055c11.31,0,24.936-7.75,31.091-14.945a61.225,61.225,0,0,0,6.91-9.966,1.029,1.029,0,0,1,1.289-.452l33.965,13.822a1.03,1.03,0,0,1,.58,1.306c-4.011,11.055-9.085,20.7-14.675,27.909a93.924,93.924,0,0,1-29.258,24.416,97.9,97.9,0,0,1-37.1,11.585Q315.193,1089.411,311.284,1089.411Z'
                    transform='translate(-244.999 -916.62)'
              />
            </g>
          </g>
          <g transform='translate(591.821 671.91)'>
            <path className='a'
                  d='M862.666,1102.267c10.363-28.564,13.009-64.681,1.943-85.231-.575-1.068-3.987-4.564-5.985-1.686a5.46,5.46,0,0,0-.472,4.268,206.3,206.3,0,0,1,3.482,23.219c0-5.359-1.75-14.636-3.79-23.383a3.4,3.4,0,0,0-1.688-2.331,2.71,2.71,0,0,0-3.169,1.065,6.863,6.863,0,0,0-.883,3.625c-.458,9.074-.944,16.288-.931,21.261.341-6.9.477-13.8.818-20.7.088-1.786.344-2.316-.195-4.021-.854-2.7-5.642-1.823-6.845,3.733-2.366,10.924-4.06,21.03-5.721,32.069-1.811,12.036-4.707,23.845-10.532,34.291a87.811,87.811,0,0,0,14.907,9.524A43.234,43.234,0,0,0,862.666,1102.267Z'
                  transform='translate(-827.67 -1013.208)'
            />
            <path className='b'
                  d='M857.886,1099.346a44.916,44.916,0,0,1-18.695-4.416,87.836,87.836,0,0,1-15.11-9.652,1.031,1.031,0,0,1-.251-1.3c4.957-8.89,8.266-19.675,10.413-33.943,1.381-9.176,3.141-20.17,5.733-32.133.87-4.017,3.436-5.917,5.592-6.016a3.126,3.126,0,0,1,2.88,1.408,3.659,3.659,0,0,1,4.181-1.067,3.373,3.373,0,0,1,.452.259,4.7,4.7,0,0,1,.726-1.689,3.256,3.256,0,0,1,2.954-1.549c2.324.131,4.348,2.524,4.784,3.334,5.321,9.882,7.806,23.761,7.187,40.138a155.656,155.656,0,0,1-9.068,45.933,1.031,1.031,0,0,1-.913.678C858.688,1099.331,858.388,1099.346,857.886,1099.346Zm.81-1.047h0Zm-32.641-14.093a86.161,86.161,0,0,0,14.03,8.865,43.42,43.42,0,0,0,17.883,4.209,153.566,153.566,0,0,0,8.706-44.641c.605-16.01-1.795-29.524-6.942-39.083-.343-.637-1.9-2.187-3.086-2.254a1.226,1.226,0,0,0-1.145.666c-.541.78-.636,1.852-.308,3.477.634,3.142,1.184,6.117,1.658,8.977a85.239,85.239,0,0,1,1.844,14.445,1.03,1.03,0,0,1-2.057.09c-.4-4.521-.982-9.117-1.8-14.063-.542-2.808-1.2-5.883-1.968-9.176a2.384,2.384,0,0,0-1.127-1.635,1.735,1.735,0,0,0-1.895.742,6.3,6.3,0,0,0-.686,3.068q-.175,3.468-.347,6.568l-.064,1.187q-.057,1.579-.11,3.135c-.117,3.4-.239,6.911-.41,10.37a1.031,1.031,0,0,1-1.029.98h-.025a1.03,1.03,0,0,1-1.006-1.027c-.009-3.489.22-7.96.521-13.55.086-2.389.181-4.809.3-7.2.022-.438.053-.8.08-1.12a5.216,5.216,0,0,0-.228-2.541,1.076,1.076,0,0,0-1.182-.753c-.951.044-2.942,1.019-3.673,4.394-2.579,11.907-4.333,22.86-5.709,32C834.158,1064.451,830.9,1075.245,826.055,1084.206Z'
                  transform='translate(-823.7 -1009.24)'
            />
          </g>
          <g transform='translate(750.308 639.957)'>
            <path className='a'
                  d='M1622.322,811.887c-2.778,1.014-7.23,3.378-9.553,6.555-1.026,1.4-1.818,3.414-.75,4.785,1.032,1.325,3.067,1.124,4.7.716,3.319-.831,10.579-3.852,9.935-3.545-3.507,1.676-10.25,4.708-11.842,8.534-1.964,4.722,3.168,5.008,6.486,4.583,8.16-1.043,15.762-6.414,17.793-14.663,1.566-6.357-.751-14.034-6.529-17.6-6.539-4.031-16.772.426-20.646,6.369-2.572,3.945-.02,7.8,4.93,6.506,2.224-.581,7.221-3.06,9.533-3.851Z'
                  transform='translate(-1609.868 -798.672)'
            />
            <path className='b'
                  d='M1615.224,830.688c-1.911,0-4.063-.367-5.162-1.806-.844-1.106-.9-2.569-.158-4.345a9.8,9.8,0,0,1,2.787-3.518c-2.561.577-4.39.187-5.439-1.159-1.187-1.524-.913-3.777.731-6.026a14.873,14.873,0,0,1,2.316-2.456,4.858,4.858,0,0,1-3.536-1.936c-1.239-1.72-1.112-4.165.331-6.379a19.786,19.786,0,0,1,9.847-7.335c4.582-1.607,8.917-1.375,12.2.651,5.836,3.6,8.775,11.47,6.989,18.72a19.363,19.363,0,0,1-6.961,10.6,24.048,24.048,0,0,1-11.7,4.842A17.947,17.947,0,0,1,1615.224,830.688Zm7.562-15.327a.937.937,0,0,1,.9.6,1.04,1.04,0,0,1-.541,1.363l-.587.279c-3.282,1.56-9.393,4.463-10.747,7.721-.436,1.049-.472,1.824-.106,2.3.661.867,2.824,1.206,5.51.862a21.958,21.958,0,0,0,10.687-4.416,17.319,17.319,0,0,0,6.236-9.471,16.449,16.449,0,0,0-.483-9.26,13.529,13.529,0,0,0-5.587-7.212c-2.748-1.694-6.457-1.858-10.441-.461a17.926,17.926,0,0,0-8.8,6.516c-.982,1.506-1.126,3.02-.385,4.048.757,1.05,2.324,1.386,4.192.9a19.761,19.761,0,0,0,2.165-.77,26.22,26.22,0,0,1,3.21-1.441l1.9-.753c.835-.355,1.587-.66,2.185-.865a1.03,1.03,0,0,1,.713,1.933l-2.117.839c-.586.25-1.22.527-1.868.81-1.07.468-2.158.944-3.148,1.346a18.414,18.414,0,0,0-6.021,4.817c-.788,1.078-1.463,2.652-.769,3.543.679.871,2.35.671,3.633.35,2.42-.606,7.187-2.485,8.982-3.192l.638-.251A1.823,1.823,0,0,1,1622.786,815.361Z'
                  transform='translate(-1605.917 -794.671)'
            />
          </g>
        </g>
      </svg>
  );
}
