import React, {Component} from 'react';
import {Button, ButtonGroup, Stack} from '@mui/material';
import SharedButton from '../shared/Button';
import TextField from '../shared/TextField';
import IconUtils from '../util/IconUtil';
import Typography from '@mui/material/Typography';
import {
  IncreaseDecreaseButtonStyles
} from '../../jss/IncreaseDecreaseButtonStyles';

class IncreaseDecreaseButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props?.currentQuantity ?? 0,
    };

    this.handleIncreaseCount = this.handleIncreaseCount.bind(this);
    this.handleDecreaseCount = this.handleDecreaseCount.bind(this);
    this.handleCountManually = this.handleCountManually.bind(this);
  }

  componentDidMount() {
    if (this.props.currentQuantity === 0) {
      this.setState({
        count: this.props.currentQuantity,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuantity !== this.props.currentQuantity) {
      this.setState({
        count: this.props.currentQuantity,
      });
    }
  }

  handleIncreaseCount() {
    if (this.state.count < this.props.maxCount) {
      this.setState({
        count: this.state.count + 1
      });
      this.props.handleOnClick(this.state.count + 1);
    }
  }

  handleDecreaseCount() {
    this.setState({
      count: this.state.count - 1
    });
    this.props.handleOnClick(this.state.count - 1);
  }

  handleCountManually(e) {
    this.setState({
      count: e.target.value
    });
    this.props.handleOnClick(e.target.value);
  }

  render() {
    const { t, maxCount, disabled, children, totalDevices, errorMessage } = this.props;
    const { count } = this.state;
    return count === 0 ? (
      <SharedButton
        className='action'
        onClick={this.handleIncreaseCount}
        label={t('Add')}
        endIcon={IconUtils.getIcon('PlusIcon')}
        disabled={disabled}
      />
    ) : (
      <Stack
        sx={IncreaseDecreaseButtonStyles}
        direction='column'
        alignItems='center'
      >
        { totalDevices > maxCount && <Typography className='increaseDecreaseButtonErrorMessage'>{errorMessage}</Typography> }
        <span className='stepperControl'>
          <ButtonGroup className='stepper'>
            <Button className='flatButton decrease' onClick={this.handleDecreaseCount}>{IconUtils.getIcon('HorizontalRuleIcon')}</Button>
            <TextField
              type='number'
              value={count}
              onChange={this.handleCountManually}
              name='count'
              className='counter'
            />
            <Button className='flatButton increase' onClick={this.handleIncreaseCount}>{IconUtils.getIcon('PlusIcon')}</Button>
          </ButtonGroup>
          {children}
        </span>
      </Stack>
    );
  }
}

export default IncreaseDecreaseButton;
