/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { getDepositTransactionData } from '../../actions/depositsActions';
import UserUtil from './../util/UserUtil';
import Loading from '../Loading';
import PropTypes from 'prop-types';
import CustomToggle from '../shared/Toggle';

export default class DepositDetailTransactions extends Component {

  constructor(props) {

    super(props);

    this.toggleShowHideTransactions = this.toggleShowHideTransactions.bind(this);
    this.loadDepositTransactionData = this.loadDepositTransactionData.bind(this);
    this.toggleShowHideFees = this.toggleShowHideFees.bind(this);
    this.toggleShowHideChargebacks = this.toggleShowHideChargebacks.bind(this);
    this.toggleShowHideRepresentments = this.toggleShowHideRepresentments.bind(this);

    this.state = {
      showTransactions: false,
      showFees: false,
      showRepresentments: false,
      showChargebacks: false
    };
  }

  UNSAFE_componentWillMount() {
    this.loadDepositTransactionData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.depositId !== prevProps.depositId) {

      this.setState({
        showTransactions: false,
        showFees: false,
        showRepresentments: false,
        showChargebacks: false
      });

      this.loadDepositTransactionData(this.props);
    }
  }

  loadDepositTransactionData(props) {

    props.dispatch(getDepositTransactionData(props.user, props.depositId, props.userExperience?.allAccounts))

  }

  toggleShowHideTransactions() {
    this.setState({showTransactions: !this.state.showTransactions});
  }

  toggleShowHideFees() {
    this.setState({showFees: !this.state.showFees});
  }

  toggleShowHideChargebacks() {
    this.setState({showChargebacks: !this.state.showChargebacks});
  }

  toggleShowHideRepresentments() {
    this.setState({showRepresentments: !this.state.showRepresentments});
  }

  render() {

    const {deposits, user} = this.props;

    if (deposits.isFetchingDepositTransactions) {
      return (
        <Loading/>
      )
    } else {

      const isHbt = UserUtil.isHumbolt(user);
      const hbTd  = !isHbt ? 'tdHb' : '';
      const hbTh  = !isHbt ? 'thHb' : '';

      const {selectedDeposit} = deposits;

      if (selectedDeposit && selectedDeposit.no_details && selectedDeposit.no_details === true) {
        return null;
      } else {

        const transactionCount = selectedDeposit.transactions && selectedDeposit.transactions.length;
        let transactionTotal = 0;
        const feeCount = selectedDeposit.fees && selectedDeposit.fees.length;
        let feeTotal = 0;
        const chargebackCount = selectedDeposit.chargebacks && selectedDeposit.chargebacks.length;
        const representmentCount = selectedDeposit.representments && selectedDeposit.representments.length;

        if (transactionCount > 0) {
          transactionTotal = selectedDeposit.transactions.reduce(
            (accumulator, transaction) => numeral(accumulator).add(transaction.total),
            transactionTotal
          );
        }

        if (feeCount > 0) {
          feeTotal = selectedDeposit.fees.reduce(
            (accumulator, fee) => numeral(accumulator).add(fee.amount),
            feeTotal
          );
        }

        return (
          <div className='depositsDetailTransactions'>
            {transactionCount ? <div className='detailDescription'>
              <div className='detailDescriptionText detailDescriptionRow'>
                <div className='buttonHolder'>
                  <span className='transactionCount'>{`${transactionCount} Transactions`}</span>
                  <div className='toggleHolder'>
                    <CustomToggle
                      labelPosition={'start'}
                      label={'Show'}
                      input={{
                        value: this.state.showTransactions,
                        onChange: this.toggleShowHideTransactions
                      }}
                    />
                  </div>
                  <span className='transactionCount'>{`Total Transactions: ${numeral(transactionTotal).format('$0,0.00')}`}</span>
                </div>
                {this.state.showTransactions &&
                <div className='depositsTransList'>
                  <div>
                    <span className={`th ${hbTh} detailTime hideMobile`}>{'Time'}</span>
                    <span className={`th ${hbTh} hideMobile`}>{'Brand'}</span>
                    <span className={`th ${hbTh} hideMobile`}>{'Card'}</span>
                    <span className={`th transactionTh ${hbTh}`}>{'Type'}</span>
                    <span className={`th transactionTh ${hbTh} detailAmount`}>{'Amount'}</span>
                    {!isHbt ? <span className={`th transactionTh ${hbTh}`}>{'Rate'}</span> : null}
                    {!isHbt ? <span className={`th transactionTh ${hbTh}`}>{'Fees'}</span> : null}
                    <span className={`th transactionTh ${hbTh}`}>{'Total'}</span>
                  </div>
                  {selectedDeposit.transactions.map((depositTransaction, i) =>
                    <div key={i} className='tr'>
                      <span className={`td ${hbTd} detailTime hideMobile`}>{moment(depositTransaction.date).format('MM/DD/YYYY')}</span>
                      <span className={`td ${hbTd} hideMobile`}>{depositTransaction.brand === 'E' ? 'EBT' : depositTransaction.brand}</span>
                      <span className={`td ${hbTd} hideMobile`}>{depositTransaction.last4}</span>
                      <span className={`td transactionTd ${hbTd}`}>{depositTransaction.type}</span>
                      <span className={`td transactionTd ${hbTd} detailAmount`}>{numeral(depositTransaction.amount).format('$0,0.00')}</span>
                      {!isHbt ? <span className={`td transactionTd ${hbTd}`}>{depositTransaction.rate}</span> : null}
                      {!isHbt ? <span className={`td transactionTd ${hbTd}`}>{numeral(depositTransaction.fee).format('$0,0.00')}</span> : null}
                      <span className={`td transactionTd ${hbTd}`}>{numeral(depositTransaction.total).format('$0,0.00')}</span>
                    </div>)}
                </div>}
              </div>
            </div> : null}
            {feeCount ? <div className='detailDescription'>
              <div className='detailDescriptionText detailDescriptionRow'>
                <div className='buttonHolder'>
                  <span className='transactionCount'>{`${feeCount} Fees`}</span>
                  <div className='toggleHolder'>
                    <CustomToggle
                      labelPosition={'start'}
                      label={'Show'}
                      input={{
                        value: this.state.showFees,
                        onChange: this.toggleShowHideFees
                      }}
                    />
                  </div>
                  <span className='transactionCount'>{`Total Fees: ${numeral(feeTotal).format('$0,0.00')}`}</span>
                </div>
                {this.state.showFees &&
                <div className='feesTransList'>
                  <div>
                    <span className='th feeTh thLong'>{'Date'}</span>
                    <span className='th feeTh thLong' style={{width: '38%'}}>{'Description'}</span>
                    <span className='th feeTh thLong' style={{width: '28.67%'}}>{'Amount'}</span>
                  </div>
                  {selectedDeposit.fees.map((depositFee, i) =>
                    <div key={i} className='tr'>
                      <span className='td feeTd tdLong'>{moment(depositFee.date).format('MM/DD/YYYY h:mm a')}</span>
                      <span className='td feeTd tdLong' style={{width: '38%'}}>{depositFee.description}</span>
                      <span className='td feeTd tdLong' style={{width: '28.67%'}}>{numeral(depositFee.amount).format('$0,0.00')}</span>
                    </div>)}
                </div>}
              </div>
            </div> : null}
            {chargebackCount ? <div className='detailDescription'>
              <div className='detailDescriptionText detailDescriptionRow'>
                <div className='buttonHolder'>
                  <span
                    className='transactionCount'>{`${chargebackCount} Chargebacks`}</span>
                  <div className='toggleHolder'>
                    <CustomToggle
                      labelPosition={'start'}
                      label={'Show'}
                      input={{
                        value: this.state.showChargebacks,
                        onChange: this.toggleShowHideChargebacks
                      }}
                    />
                  </div>
                </div>
                {this.state.showChargebacks &&
                <div className='chargebacksTransList'>
                  <div>
                    <span className='th thFour'>{'Date'}</span>
                    <span className='th thFour'>{'Brand'}</span>
                    <span className='th thFour'>{'Guid'}</span>
                    <span className='th thFour'>{'Amount'}</span>
                  </div>
                  {selectedDeposit.chargebacks.map((chargeback, i) =>
                    <div key={i} className='tr'>
                      <span className='td tdFour'>{moment(chargeback.date).format('MM/DD/YYYY h:mm a')}</span>
                      <span className='td tdFour'>{chargeback.brand}</span>
                      <span className='td tdFour'>{chargeback.guid}</span>
                      <span className='td tdFour'>{numeral(chargeback.amount).format('$0,0.00')}</span>
                    </div>)}
                </div>}
              </div>
            </div> : null}
            {representmentCount ? <div className='detailDescription'>
              <div className='detailDescriptionText detailDescriptionRow'>
                <div className='buttonHolder'>
                  <span className='transactionCount'>{`${representmentCount} Representments`}</span>
                  <div className='toggleHolder'>
                    <CustomToggle
                      labelPosition={'start'}
                      label={'Show'}
                      input={{
                        value: this.state.showRepresentments,
                        onChange: this.toggleShowHideRepresentments
                      }}
                    />
                  </div>
                </div>
                {this.state.showRepresentments &&
                <div className='chargebacksTransList'>
                  <div>
                    <span className='th thFour'>{'Date'}</span>
                    <span className='th thFour'>{'Bric'}</span>
                    <span className='th thFour'>{'Guid'}</span>
                    <span className='th thFour'>{'Amount'}</span>
                  </div>
                  {selectedDeposit.representments.map((repres, i) =>
                    <div key={i} className='tr'>
                      <span className='td tdFour'>{moment(repres.date).format('MM/DD/YYYY h:mm a')}</span>
                      <span className='td tdFour'>{repres.bric}</span>
                      <span className='td tdFour'>{repres.guid}</span>
                      <span className='td tdFour'>{numeral(repres.amount).format('$0,0.00')}</span>
                    </div>)}
                </div>}
              </div>
            </div> : null}
          </div>
        );

      }

    }
  }
}

DepositDetailTransactions.propTypes = {
  depositId: PropTypes.string.isRequired
};

DepositDetailTransactions.defaultProps = {
  isFetching: true,
};

