/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {useEffect} from 'react';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import Image from '../shared/Image'
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Button from '../shared/Button';
import {
  NextIntegrationDetailsStyles
} from '../../jss/NextIntegrationDetailsStyles';
import {sharedIntegrationDialogStyles} from '../../jss/inlineStyles';

const NextIntegrationDetails = (
  {
    accountProfile,
    buttonAction,
    isNextClicked,
    t
  }
) => {
  const label = LabelUtil.getLabel();
  const syncAltIcon = IconUtils.getIcon('SyncAltIcon', label.primaryColor, 'medium');
  const checkIcon = IconUtils.getIcon('CheckIcon', label.white, 'medium');
  const externalLinkIcon = IconUtils.getIcon('OpenInNewIcon', label.primaryColor);
  const requestQuoteIcon = IconUtils.getIcon('RequestQuoteIcon', label.primaryColor);
  const externalDocumentIcon = IconUtils.getIcon('DocumentIcon', label.primaryColor, 22);

  useEffect(() => {

    const script = document.createElement('script');

    script.src = nextInsuranceURL;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Box
      id={'NextIntegrationDialog'}
      sx={sharedIntegrationDialogStyles}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography
          sx={sharedIntegrationDialogStyles.integrationHeader}
          variant='primaryFontFamilyBold'
        >
          {t('IntegrationsPage.DialogHeader')}
        </Typography>
        <Box sx={sharedIntegrationDialogStyles.borderBox}>
          <Image
            alt={'Next logo'}
            src={'/images/next/nextCardLogo.png'}
            sx={NextIntegrationDetailsStyles.navLogo}
          />
          <Box sx={sharedIntegrationDialogStyles.brandBox}>
            <Typography
              sx={sharedIntegrationDialogStyles.brandText}
              variant='primaryFontFamilySemiBold'
            >
              {'Payments Hub'}
            </Typography>
            <span>{syncAltIcon}</span>
            <Typography
              sx={sharedIntegrationDialogStyles.brandText}
              variant='primaryFontFamilySemiBold'
            >
              {'Next'}
            </Typography>
          </Box>
          <Typography
            sx={NextIntegrationDetailsStyles.navDescription}
            variant='primaryFontFamilyMedium'
          >
            {t('IntegrationsPage.Next.Dialog.Description')}
          </Typography>
          <Chip
            label={t('IntegrationsPage.Tags.Insurance')}
            sx={NextIntegrationDetailsStyles.tagChip}
          />
          {!isNextClicked && (
            <>
              <Divider
                sx={NextIntegrationDetailsStyles.divider}
                variant='fullWidth'
              />
              <Typography variant='primaryFontFamilyBold'>
                {t('IntegrationsPage.Next.Dialog.Subtext')}
              </Typography>
            </>
          )}
        </Box>
        {isNextClicked && (
          <Box id={'NextLinkList'}>
            <div
              className='next-insurance-button'
              data-affiliate-name='North American Bancard, LLC'
              data-env={nextInsurance === 'uat' ? 'sandbox' : ''}
              data-signature={accountProfile.next?.signature}
              data-timestamp={accountProfile.next?.timestamp}
              data-use-next-button='false'
            >
              <Button
                sx={NextIntegrationDetailsStyles.nextLinkButton}
                label={t('IntegrationsPage.Next.Dialog.Links.GetQuote')}
                endIcon={requestQuoteIcon}
              />
            </div>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a
              href='https://portal.nextinsurance.com/login/authentication'
              target='_blank'
            >
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>
                  {t('IntegrationsPage.Next.Dialog.Links.VisitNextPortal')}
                </Typography>
                {externalLinkIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a
              href='https://www.nextinsurance.com/'
              target='_blank'
            >
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>
                  {t('IntegrationsPage.Next.Dialog.Links.VisitNextWebsite')}
                </Typography>
                {externalLinkIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a
              href='https://www.nextinsurance.com/terms-of-service/'
              target='_blank'
            >
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>
                  {t('IntegrationsPage.Next.Dialog.Links.TermsAndConditions')}
                </Typography>
                {externalDocumentIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a
              href='https://www.nextinsurance.com/privacy-policy/'
              target='_blank'
            >
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>
                  {t('IntegrationsPage.Next.Dialog.Links.PrivacyPolicy')}
                </Typography>
                {externalDocumentIcon}
              </Box>
            </a>
          </Box>
        )}
        <Box sx={sharedIntegrationDialogStyles.featureBox}>
          <Typography
            sx={sharedIntegrationDialogStyles.featureText}
            variant='primaryFontFamilySemiBold'
          >
            {t('IntegrationsPage.Next.Dialog.Features.First.Title')}
          </Typography>
          <Typography variant='primaryFontFamilyMedium'>
            {t('IntegrationsPage.Next.Dialog.Features.First.Description')}
          </Typography>
        </Box>
        <Box sx={sharedIntegrationDialogStyles.featureBox}>
          <Typography
            sx={sharedIntegrationDialogStyles.featureText}
            variant='primaryFontFamilySemiBold'
          >
            {t('IntegrationsPage.Next.Dialog.Features.Second.Title')}
          </Typography>
          <Typography variant='primaryFontFamilyMedium'>
            {t('IntegrationsPage.Next.Dialog.Features.Second.Description')}
          </Typography>
        </Box>
        <Box sx={sharedIntegrationDialogStyles.featureBox}>
          <Typography variant='primaryFontFamilySemiBold'>
            {t('IntegrationsPage.Next.Dialog.Features.Third.Title')}
          </Typography>
          <List>
            <ListItem disableGutters>
              <ListItemIcon sx={sharedIntegrationDialogStyles.listItemIcon}>
                {checkIcon}
              </ListItemIcon>
              <ListItemText>
                <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                  <Typography variant='primaryFontFamilyMedium' component='div'>
                    <Box sx={sharedIntegrationDialogStyles.listItemText}
                         display='inline'><Typography
                      variant='primaryFontFamilyBold'>{t('IntegrationsPage.Next.Dialog.Features.Third.List.First.Bold')}</Typography></Box>
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.First.Regular')}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon sx={sharedIntegrationDialogStyles.listItemIcon}>
                {checkIcon}
              </ListItemIcon>
              <ListItemText>
                <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                  <Typography variant='primaryFontFamilyMedium' component='div'>
                    <Box sx={sharedIntegrationDialogStyles.listItemText}
                         display='inline'><Typography
                      variant='primaryFontFamilyBold'>{t('IntegrationsPage.Next.Dialog.Features.Third.List.Second.Bold')}</Typography></Box>
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.Second.Regular')}
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.Second.SecondaryRegular')}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon sx={sharedIntegrationDialogStyles.listItemIcon}>
                {checkIcon}
              </ListItemIcon>
              <ListItemText>
                <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                  <Typography variant='primaryFontFamilyMedium' component='div'>
                    <Box sx={sharedIntegrationDialogStyles.listItemText}
                         display='inline'><Typography
                      variant='primaryFontFamilyBold'>{t('IntegrationsPage.Next.Dialog.Features.Third.List.Third.Bold')}</Typography></Box>
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.Third.Regular')}
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.Third.SecondaryRegular')}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon sx={sharedIntegrationDialogStyles.listItemIcon}>
                {checkIcon}
              </ListItemIcon>
              <ListItemText>
                <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                  <Typography variant='primaryFontFamilyMedium' component='div'>
                    <Box sx={sharedIntegrationDialogStyles.listItemText}
                         display='inline'><Typography
                      variant='primaryFontFamilyBold'>{t('IntegrationsPage.Next.Dialog.Features.Third.List.Fourth.Bold')}</Typography></Box>
                    {t('IntegrationsPage.Next.Dialog.Features.Third.List.Fourth.Regular')}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          </List>
          <Box sx={NextIntegrationDetailsStyles.marketingImageContainer}>
            <Image
              alt={'Next phone'}
              src={'/images/next/nextPhone.png'}
              sx={NextIntegrationDetailsStyles.marketingImage}
            />
          </Box>
        </Box>
      </Stack>
      {!isNextClicked && (
        <Box sx={NextIntegrationDetailsStyles.connectContainer}>
          <div
            className='next-insurance-button'
            data-affiliate-name='North American Bancard, LLC'
            data-env='sandbox'
            data-signature={accountProfile.next?.signature}
            data-timestamp={accountProfile.next?.timestamp}
            data-use-next-button='false'
          >
            <Button
              id={'nextButton'}
              label={t('IntegrationsPage.Next.Dialog.ConnectButton')}
              onClick={buttonAction}
              sx={NextIntegrationDetails.connectButton}
            />
          </div>
        </Box>
      )}
    </Box>
  );
};

export default NextIntegrationDetails;
