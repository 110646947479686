/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import React from 'react';
import _ from 'lodash';
import UserTermsPA from '../legal/UserAgreementPA';
import UserTermsNorth from '../legal/UserAgreementNorth';
import UserAgreementGlobal from '../legal/UserAgreementGlobal';
import UserFDAgreement from '../legal/UserFDAgreement';
import MerchantProcessingAgreement from '../legal/MerchantProcessingAgreement';
import routes from '../../constants/routes';
import LabelUtil from './LabelUtil';
import moment from 'moment';
import planTypes from '../../constants/planTypes';
import FeatureFlagsUtil from './FeatureFlagsUtil';
import {buttonText} from '../../constants/planInformation';
import FreeEquipmentPlacementAgreement from '../legal/FreeEquipmentPlacementAgreement';

const UserUtil = {

  getActiveAccount: (user) => user?.data && Object.keys(user.data).length && user.data.merchantAccounts && user.data.merchantAccounts.find(account => account?.mea_id === parseInt(user.selectedMerchantAccount)),

  isEPX: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return !_.isUndefined(selectedMerchantAccount) && selectedMerchantAccount.processor === 'EPX';
  },

  isVtCapable: (user, auth, settings) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    if (selectedMerchantAccount?.is_demo) {
      return true;
    }
    if (selectedMerchantAccount?.type?.[0] === 'hybrid') {
      return true;
    }
    if (selectedMerchantAccount?.type?.[0] === 'mbp') {
      return selectedMerchantAccount?.vti_enabled && !selectedMerchantAccount?.needs_info;
    }
    if (auth.isSpr && settings?.csSettings) {
      if (selectedMerchantAccount?.type?.[0] === 'pa' && settings?.csSettings?.is_pavt_invoice_enabled) {
        return true;
      }
    }
    if (selectedMerchantAccount?.type?.[0] === 'pa' && selectedMerchantAccount?.is_pavt_invoice_enabled) {
      return true;
    }
    return false;
  },

  isBatchCapable: (user, historicHardwareMode) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);

    if (selectedMerchantAccount) {
      if (UserUtil.isHumbolt(user) || UserUtil.isMybizperks(user)) {
        return true;
      } else {
        return !!(UserUtil.isPayanywhere(user) && UserUtil.isEPX(user) && (selectedMerchantAccount.manual_close || historicHardwareMode));
      }
    } else {
      return false;
    }

  },

  isLargeProcessor: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.is_big_data === true;
  },

  isHumboltMbpOrLargeProcessor: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && (UserUtil.isLargeProcessor(user) || UserUtil.isMybizperks(user) || UserUtil.isHumbolt(user));
  },

  isHumbolt: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.type?.[0] === 'hbt';
  },

  isPayanywhere: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.type?.[0] === 'pa';
  },

  isMybizperks: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.type?.[0] === 'mbp';
  },

  isNeedsInfo: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.needs_info;
  },

  passwordResetNeeded: (user) => {
    return !(UserUtil.isHumbolt(user) && user.data.pwd_reset_needed);
  },

  userType: (user) => {

    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    if (selectedMerchantAccount && selectedMerchantAccount.type && !_.isUndefined(selectedMerchantAccount.type) && Array.isArray(selectedMerchantAccount?.type)) {

      if (selectedMerchantAccount.type.length > 1) {
        return 'Hybrid';
      }
      if (selectedMerchantAccount.type[0] === 'mbp') {
        return 'MBP';
      }
      if (selectedMerchantAccount.type[0] === 'hbt') {
        return 'HBT';
      }
    }
    return 'PA';
  },

  processorType: (user) => {

    if (UserUtil.isEPX(user)) {
      return 'EPX';
    }
    return 'GLB';
  },

  userMenu: (user, historicHardwareMode, auth, settings, divider) => {

    const vtAccounts = UserUtil.isVtCapable(user, auth, settings) ? ['PA', 'HYBRID', 'MBP'] : ['HYBRID'];
    const isAccountBatchCapable = UserUtil.isBatchCapable(user, historicHardwareMode);
    const batchAccounts = isAccountBatchCapable ? ['MBP', 'PA', 'HYBRID', 'HBT'] : ['MBP', 'HYBRID', 'HBT'];
    const isActiveAccount = UserUtil.isActive(user);

    let userMenuItems = [
      [
        {
          text: 'Dashboard',
          icon: 'MainMenu_DashboardIcon',
          path: routes.activity.root + routes.activity.dashboard,
          show: {
            byRole: ['report', 'owner', 'admin', 'manager'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settings: [],
          showWhenHistoricHardwareMode: true
        },
        {
          text: 'Transactions',
          icon: 'MainMenu_TransactionsIcon',
          path: routes.activity.root + routes.activity.transactions,
          show: {
            byRole: ['owner', 'admin'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: 'isManagerActivity',
          showWhenHistoricHardwareMode: true
        },
        {
          text: 'Deposits',
          icon: 'MainMenu_DepositsIcon',
          path: routes.activity.root + routes.activity.deposits,
          show: {
            byRole: ['owner', 'admin'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: 'isManagerActivity',
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Batches',
          icon: 'MainMenu_BatchesIcon',
          path: routes.activity.root + routes.activity.batches,
          show: {
            byRole: ['owner', 'admin'],
            byUserType: batchAccounts,
            byProcessorType: ['EPX']
          },
          settingAllowed: isAccountBatchCapable ? 'isManagerActivity' : null,
          showWhenHistoricHardwareMode: true
        },
        {
          text: 'Reports',
          icon: 'MainMenu_ReportsIcon',
          path: routes.activity.root + routes.activity.reports,
          show: {
            byRole: ['report', 'owner', 'admin'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: 'isManagerActivity',
          showWhenHistoricHardwareMode: true
        },
        {
          text: 'Statements',
          icon: 'MainMenu_StatementsIcon',
          path: routes.activity.root + routes.activity.statements,
          show: {
            byRole: ['owner', 'admin'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: 'isManagerActivity',
          showWhenHistoricHardwareMode: false
        }],
      [
        {
          text: 'Virtual Terminal',
          icon: 'MainMenu_VirtualTerminalIcon',
          path: routes.business.root + routes.business.onlinePayments,
          show: {
            byRole: ['owner', 'admin'],
            byActiveStatus: UserUtil.isActive(user),
            byUserType: vtAccounts
          },
          settingAllowed: 'isManagerVT',
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Invoices',
          icon: 'MainMenu_InvoicesIcon',
          path: routes.business.root + routes.business.invoices,
          show: {byRole: ['owner', 'admin'], byUserType: vtAccounts},
          settingAllowed: 'isManagerInvoice',
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Payment Links',
          icon: 'MainMenu_PaymentLinksIcon',
          path: routes.business.root + routes.business.paymentLinks,
          show: {byRole: ['owner', 'admin'], byUserType: ['PA']},
          settingAllowed: 'isManagerPaymentLinks',
          showWhenHistoricHardwareMode: false,
          mandatorySetting: settings?.paymentLinksEnabled
        },
        {
          text: 'Customers',
          icon: 'MainMenu_CustomersIcon',
          path: routes.business.root + routes.business.customers,
          show: {
            byRole: ['owner', 'admin', 'manager'],
            byUserType: ['PA', 'HYBRID', 'HBT']
          },
          settingAllowed: null,
          showWhenHistoricHardwareMode: false
        },
      ],
      [
        {
          text: 'Inventory',
          icon: 'MainMenu_InventoryIcon',
          path: routes.inventory.root + routes.inventory.items,
          show: {
            byRole: ['owner', 'admin', 'manager'],
            byUserType: ['PA', 'Hybrid']
          },
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Employees',
          icon: 'MainMenu_EmployeesIcon',
          path: routes.business.root + routes.business.employees,
          show: {
            byRole: ['owner', 'admin', 'manager'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: null,
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Hardware & Supplies',
          icon: 'MainMenu_HardwareIcon',
          path: routes.account.root + routes.account.supplies,
          show: {
            byRole: ['owner', 'admin'],
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: null,
          showWhenHistoricHardwareMode: false
        },
        {
          text: 'Business Settings',
          icon: 'MainMenu_SettingsIcon',
          path: isActiveAccount
            ? routes.account.root + routes.account.settings
            : routes.account.root + routes.account.business,
          show: {
            byRole: ['owner', 'admin', 'manager'],
            byActiveStatus: null,
            byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
          },
          settingAllowed: null,
          showWhenHistoricHardwareMode: false
        }
      ],
    ];

    if (UserUtil.isActive(user)) {
      const reputationMenuItem = {
        text: 'Reputation',
        icon: 'MainMenu_ReputationIcon',
        path: routes.business.root + routes.business.reputation,
        show: {
          byRole: ['owner', 'admin'],
          byUserType: ['MBP', 'PA', 'HYBRID']
        },
        settingAllowed: 'isManagerReputation',
        showWhenHistoricHardwareMode: false
      };
      userMenuItems[2].splice(2, 0, reputationMenuItem);
    }

    if (UserUtil.isEPX(user)) {
      const disputesMenuItem = {
        text: 'Disputes',
        icon: 'MainMenu_DisputesIcon',
        path: routes.business.root + routes.business.disputes,
        show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['MBP', 'PA', 'HYBRID', 'HBT'],
        },
        settingAllowed: null
      };
      userMenuItems[0].splice(5, 0, disputesMenuItem);
    }

    const isLargeProcessor = UserUtil.isLargeProcessor(user);

    if (!isLargeProcessor) {
      const salesMenuItem = {
        text: 'Sales',
        icon: 'MainMenu_SalesIcon',
        path: routes.activity.root + routes.activity.sales,
        show: {
          byRole: ['owner', 'admin'],
          byUserType: ['MBP', 'PA', 'HYBRID', 'HBT']
        },
        settingAllowed: 'isManagerActivity',
        showWhenHistoricHardwareMode: true
      }

      userMenuItems[0].splice(1, 0, salesMenuItem);

    }

    const userMenuItemsLength = userMenuItems.length;
    userMenuItems = userMenuItems.reduce((menuAccumulator, sectionMenuItems, index) => {
      const isLastMenuItemSection = index === userMenuItemsLength - 1;
      let item = [];

      if (isLastMenuItemSection) {
        item = [ ...menuAccumulator, ...sectionMenuItems];
      } else {
        const menuItems = [...sectionMenuItems];
        const lastIndex = menuItems.length - 1;
        const lastMenuItem = { ...menuItems[lastIndex] };

        lastMenuItem.divider = divider;
        menuItems[lastIndex] = lastMenuItem;

        item = [ ...menuAccumulator, ...menuItems]
      }

      return item;
    }, [])
    return userMenuItems;

  },

  /**
   * Generates the correct filterData for inventory filterPannel.
   * @param {string} selectedFilter Name of current filter.
   * @param {number} amountInSelectedFilter Amount in selected filter.
   * @returns {array} filterData Array
   */
  getInventoryOptions(selectedFilter, amountInSelectedFilter, auth, user, translate = null) {

    const userType = UserUtil.userType(user);

    const inventoryItems = [
      {
          text: 'Items',
          icon: 'ItemsIcon',
          path: routes.inventory.root + routes.inventory.items,
          show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['PA', 'HYBRID']
          }
      },
      {
          text: 'Categories',
          icon: 'CategoriesIcon',
          path: routes.inventory.root + routes.inventory.categories,
          show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['PA', 'HYBRID']
          }
      },
      {
          text: 'Modifier sets',
          icon: 'ModifierIcon',
          path: routes.inventory.root + routes.inventory.modifiers,
          show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['PA', 'HYBRID']
          }
      },
      {
          text: 'Discounts',
          icon: 'DiscountIcon',
          path: routes.inventory.root + routes.inventory.discounts,
          show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['PA', 'HYBRID']
          }
      },
      {
        text: 'Archived items',
        icon: 'ArchivedItemsIcon',
        path: routes.inventory.root + routes.inventory.archivedItems,
        show: {
          byRole: ['owner', 'admin', 'manager'],
          byUserType: ['PA', 'HYBRID']
        }
      }
    ];

    const labelColor = LabelUtil.getLabelColor();

    const generateFilterItem = (itemName) => {
      selectedFilter = !!selectedFilter && translate ? translate(selectedFilter) : selectedFilter;
      const isItemSelected = itemName && selectedFilter && selectedFilter.toLowerCase && (itemName.toLowerCase() === selectedFilter.toLowerCase());
      return isItemSelected ?
        <span
            style={{color: labelColor}}
        >{`${itemName} (${amountInSelectedFilter})`}</span> :
        `${itemName}`
    };

    const filterData = inventoryItems.map((item) => {
      const itemShouldBeDisplayed = (item.show
        && _.includes(item.show.byRole, auth.role)
        && _.includes(item.show.byUserType, userType))
        || (auth.isSpr && _.includes(item.show.byUserType, userType));
      if (itemShouldBeDisplayed) {
        return {
          text: item.text,
          name: generateFilterItem(translate ? translate(item.text) : item.text),
          icon: item.icon,
          color: labelColor
        }
      }
      return null
    });

    return filterData;
  },

  isActive: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount && selectedMerchantAccount.active ? true : false;
  },

  filterReportsMBPandHBT: (reports) => {
    const filters = [
      'Customer Experience',
      'Customer Export',
      'Customer Performance',
      'Declined Transactions',
      'Employee Flash Report',
      'Employee Performance',
      'Gift Card Summary',
      'Item Export',
      'Item Performance',
      'Loyalty Rewards Report',
      'Modifier Performance',
      'Product Sales',
      'Refund Summary',
      'Transactions by Category',
      'Transactions by Item',
    ];
    return reports.filter(report => !filters.includes(report.name));
  },

  filterLargeProcessor: (reports) => {
    const filters = ['Account Summary', 'Batch Detail', 'Batch Summary', 'Card Brand Summary', 'Deposit Detail', 'Deposit Summary', 'Disputes Report', 'Expanded Transaction Report', 'Flash Report', 'Fraud Monitoring', 'Payment Type Summary', 'Tax', 'Transactions', 'Refund Summary'];
    return reports.filter(report => filters.includes(report.name));
  },

  filterReportsPA: (reports, isManualClose) => {
    const filters = ['Chargeback'];

    if (!isManualClose) {
      filters.push('Batch Detail', 'Batch Summary');
    }

    return reports && reports.filter(report => !filters.includes(report.name));
  },

  legalToShow: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    if (UserUtil.userType(user) === 'MBP') {
      if (selectedMerchantAccount?.processor === 'EPX') {
        if (selectedMerchantAccount?.sponsor === 'B') {
          return <MerchantProcessingAgreement/>;
        } else if (selectedMerchantAccount?.sponsor === 'M') {
          return <MerchantProcessingAgreement/>;
        } else if (selectedMerchantAccount?.sponsor === 'C') {
          return <MerchantProcessingAgreement/>;
        } else if (selectedMerchantAccount?.sponsor === 'F') {
          return <MerchantProcessingAgreement/>;
        }
      } else if (selectedMerchantAccount?.processor === 'GLB') {
        return <UserAgreementGlobal/>;
      } else if (selectedMerchantAccount?.processor === 'FD') {
        return 'FD';
      }
    } else {
      if (selectedMerchantAccount?.processor === 'EPX') {
        return <MerchantProcessingAgreement/>;
      } else {
        return <UserTermsPA/>;
      }
    }

  },

  nonAuthLegalToShow: (type) => {
    switch (type) {
      case 'PA':
        return <UserTermsPA/>;
      case 'GLB':
        return <UserAgreementGlobal/>;
      case 'EPXB':
        return <MerchantProcessingAgreement/>;
      case 'EPXM':
        return <MerchantProcessingAgreement/>;
      case 'EPXC':
        return <MerchantProcessingAgreement/>;
      case 'FD':
        return <UserFDAgreement/>;
      case 'MPA':
        return <MerchantProcessingAgreement />;
      case 'FEPA':
        return <FreeEquipmentPlacementAgreement/>;
      case 'NH':
        return <UserTermsNorth/>;
      default:
        return <UserTermsPA/>;
    }
  },

  isPremiumAccount: (account) => {
    const validPlanTypes = [planTypes.premium];
    const currentAccount = UserUtil.getActiveAccount(account);
    return validPlanTypes.includes(currentAccount?.plan_type) || validPlanTypes.includes(account?.plan_type);
  },

  isPremiumPlusAccount: (account) => {
    const validPlanTypes = [planTypes.premiumPlus, planTypes.premiumTrial];
    const currentAccount = UserUtil.getActiveAccount(account);
    return validPlanTypes.includes(currentAccount?.plan_type) || validPlanTypes.includes(account?.plan_type);
  },

  isPremiumPlusWithLoyaltyAccount: (account) => {
    const validPlanTypes = [planTypes.premiumPlusLoyalty];
    const currentAccount = UserUtil.getActiveAccount(account);
    return validPlanTypes.includes(currentAccount?.plan_type) || validPlanTypes.includes(account?.plan_type);
  },

  isAnyPremiumAccount: (account) => {
    const validPlanTypes = [planTypes.premium, planTypes.premiumPlus, planTypes.premiumTrial, planTypes.premiumPlusLoyalty];
    const currentAccount = UserUtil.getActiveAccount(account);
    return validPlanTypes.includes(currentAccount?.plan_type) || validPlanTypes.includes(account?.plan_type);
  },

  accountCurrentPlan: (account) => {
    const currentAccount = UserUtil.getActiveAccount(account);
    if (UserUtil.isNorthDemoAccount(account)) {
      return planTypes.demo;
    }
    return currentAccount?.plan_type || account?.planType;
  },

  isPlanUpgrade: (account, desiredPlan) => {
    const accountPlan = UserUtil.accountCurrentPlan(account);
    const plans = {
      [planTypes.free]: 1,
      [planTypes.premium]: 2,
      [planTypes.premiumTrial]: 3,
      [planTypes.premiumPlus]: 4,
      [planTypes.premiumPlusLoyalty]: 5
    };
    return plans[desiredPlan] > plans[accountPlan];
  },

  membershipStatus: (account) => {

    account = UserUtil.getActiveAccount(account);

    if (account && account.plan_type) {
      const daysRemaining = UserUtil.membershipTrialDaysRemaining(account);
      const planType = buttonText[account?.plan_type]

      return {
        daysRemaining,
        enrolledDate: moment(account.created_date).format('LL'),
        isInTrial: account?.plan_type === planTypes.premiumTrial && daysRemaining > 0,
        planType,
      };

    } else {

      return null;

    }

  },

  membershipTrialDaysRemaining: (account) => {

    return account?.trial_days_remaining || 0;

  },

  isPremiumForFree: (account) => {

    account = UserUtil.getActiveAccount(account) || {};

    if (account?.is_premium_free) {

      return account.is_premium_free

    } else {

      return false;

    }

  },

  hasMultipleMids: (user) => {
    return user.data?.merchantAccounts?.length > 1;
  },

  hasRelatedMids: (user) => {
    return user.data?.relatedMids?.length > 0;
  },

  isUserCSWrite: (user) => {
    return user?.data?.roles?.includes('cswrite');
  },

  isUserCS: (user, auth) => {
    return auth.isSpr && user?.data?.roles?.includes('cs') && !user?.data?.roles?.includes('cswrite');
  },

  getSettingsMenu: (state, user, auth, translate = null, merchantSettings = {}) => {
    const color = LabelUtil.getLabelColor();
    const route = state.location.pathname;
    const path = route.substring(route.lastIndexOf('/') + 1);

    const menuItems = [];
    const mainAccessRoles = ['owner', 'admin'];
    const isActive = UserUtil.isActive(user);
    const userType = UserUtil.userType(user);

    // Receipt setting Menu Item
    if ((mainAccessRoles.includes(auth.role) || auth.role === 'manager') && isActive) {
      menuItems.push({
        name: 'Payment Settings',
        key: 'settings'
      }, {
        name: 'Receipt & invoice settings',
        key: 'salesSettings',
        route: 'sales-settings'
      });
    }

    // Business profile Menu Item
    if (mainAccessRoles.includes(auth.role) || auth.isSpr) {
      menuItems.push({name: 'Business Profile', key: 'business'});
    }

    // Merchant Store
    if (FeatureFlagsUtil.isFeatureFlagEnabled('showStore') && mainAccessRoles.includes(auth.role)) {
      menuItems.push({name: 'Online store', key: 'store'});
    }

    //PCI menu Item
    if (mainAccessRoles.includes(auth.role) || auth.isSpr) {
      menuItems.push({name: 'PCI Compliance', key: 'pci'});
    }

    if (mainAccessRoles.includes(auth.role) || auth.isSpr) {
      menuItems.push({name: '1099K & tax', key: 'fullTin', route: 'validate'});
    }

    // Funding Menu Item
    if (mainAccessRoles.includes(auth.role)) {
      menuItems.push({name: 'Funding', key: 'funding'});
    }

    // Communication Settings Menu Item
    if (mainAccessRoles.includes(auth.role) && isActive) {
      menuItems.push({name: 'Communication Settings', key: 'communicationSettings', route: 'communication-settings'});
    }

    // Devices Menu Item
    if (mainAccessRoles.includes(auth.role) && isActive && ['PA'].includes(userType)) {
      menuItems.push({name: 'Devices', key: 'devices'});
    }

    const hasApiAccess = ['MBP', 'PA', 'HYBRID'].includes(userType) && UserUtil.isIsvAccount(user, auth, merchantSettings);
    const hasIntegration = mainAccessRoles.includes(auth.role) && isActive && ['MBP', 'PA', 'HYBRID'].includes(userType);

    // Integrations Menu Item
    if (hasIntegration) {
      const items = hasApiAccess ?
        [{ name: 'divider', key: 'divider'} ,{ name: 'Integrations', key: 'integrations' }] :
        [{ name: 'Integrations', key: 'integrations' }];
      menuItems.push(...items);
    }

    // API Access
    if (hasApiAccess ) {
      const items = hasIntegration ?
        [{ name: 'API Access', key: 'apiAccess', route: 'api-access' }] :
        [{ name: 'divider', key: 'divider' }, { name: 'API access', key: 'apiAccess', route: 'api-access' }]
      menuItems.push(...items);
    }

    menuItems.map((filter) => {
      if (filter.key === path || filter.route === path) {
        filter.name = <span style={{color: color}}>{translate ? translate(filter.name) : filter.name}</span>
      } else {
        filter.name = translate ? translate(filter.name) : filter.name;
      }
    });

    return menuItems;

  },

  getSaq: function (envelopes) {
    const newestEnvelope = _.head(envelopes); //current envelope will always be first in array
    const searchCriteria = 'SAQ';
    const found = newestEnvelope?.envelopeDocuments?.find(doc => doc.name?.toUpperCase().includes(searchCriteria));
    return {
      name: found?.name,
      envelopeId: newestEnvelope?.envelopeId,
      documentId: found?.documentId
    };
  },

  isHbt: function (user) {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return !!selectedMerchantAccount?.isHbt || selectedMerchantAccount?.type?.[0] === 'hbt';
  },

  isSalido: function (user) {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return !!selectedMerchantAccount?.is_salido;
  },

  isOwner: function (user) {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount?.role === 'owner';
  },

  isOwnerOrAdminOfAny: function (user) {
    const acceptedRoles = ['owner', 'admin'];

    return !_.isEmpty(user?.data) && user.data.merchantAccounts?.length && !!(
      user.data.merchantAccounts.filter(({active, role}) => (
        active && acceptedRoles.includes(role)
      )).length
    );
  },

  isAgentMid: function (user) {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount?.agent_access_status !== null;
  },

  getAgentStatus: function (value) {
    return !(value === null || value === 'PENDING' || value === 'DECLINED');
  },

  getPrincipalName: (employeeData) => {
    if (!employeeData) return 'Name Missing';
    if (!employeeData.first_name && !employeeData.last_name) return 'Unnamed Employee';
    return `${employeeData.first_name || ''} ${employeeData.last_name || ''}`;
  },

  extractErrorMessage: (data) => {
    let errorMessage;

    if (typeof data === 'string') {
      try {
        const updateError = data && JSON.parse(data);

        errorMessage = updateError && updateError.reason ? updateError.reason : updateError;
      } catch (error) {
        errorMessage = data;
      }
    }

    return errorMessage;
  },

  isCsCbdCannabisAccount: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && (settings.is_cannabis_enabled || settings.is_cbd_enabled));
    }
    return false;
  },

  isCsCbdAccount: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && settings.is_cbd_enabled);
    }
    return false;
  },

  isCsCannabisAccount: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && settings.is_cannabis_enabled);
    }
    return false;
  },

  isCbdCannabisMerchant: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return (selectedMerchantAccount?.is_cannabis_enabled || selectedMerchantAccount?.is_cbd_enabled);
  },

  isPaVtInvoiceEnabled: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount?.is_pavt_invoice_enabled;
  },

  isSmsEnabled: (user) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount?.is_sms_enabled;
  },

  isCsSmsEnabled: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && settings.is_sms_enabled);
    }
    return false;
  },

  isCsPaVtInvoiceEnabled: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && settings.is_pavt_invoice_enabled);
    }
    return false;
  },

  isIsvAccount: (user, auth, merchantSettings) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return !!(
      (
        selectedMerchantAccount?.role === 'owner'
        || selectedMerchantAccount?.role === 'admin'
        || UserUtil.isUserCS(user, auth)
        || UserUtil.isUserCSWrite(user)
      )
      && merchantSettings.merchantSettings?.is_isv
    );
  },

  isLavaEnabled: (auth, merchantSettings) => {
    const settings = merchantSettings.merchantSettings;
    if (settings) {
      return (auth.isSpr && settings?.lava_enabled);
    }
    return false;
  },

  isLoyaltyEnabled: (user, merchantSettings) => {
    const selectedMerchantAccount = UserUtil.getActiveAccount(user);
    return selectedMerchantAccount?.loyalty_vpc || merchantSettings?.merchantSettings?.loyalty_status === 'enabled';
  },

  hasLoyaltyNeverBeenEnabled: (merchantSettings) => {
    const loyaltyNeverEnabledShape = {enabled: false};
    return _.isEqual(merchantSettings?.loyaltyVpc, loyaltyNeverEnabledShape);
  },

  isLoyaltyInterested: (merchantSettings) => {
    return merchantSettings?.merchantSettings?.loyalty_status === 'interested';
  },

  hasDemoAccountAnApplicationInProgress: (user) => {
    const currentAccount = UserUtil.getActiveAccount(user);
    return currentAccount?.app_status === 'external';
  },

  isNorthDemoAccount: (user) => {
    const currentAccount = UserUtil.getActiveAccount(user);
    return currentAccount?.mea_code === '-1';
  },

  isDemoAccount(user) {
    if (!user || !user.data || !user.data.merchantAccounts || !user.selectedMerchantAccount) {
      return false;
    }

    const selectedAccount = user.data.merchantAccounts.find(account =>
      account.mea_id.toString() === user.selectedMerchantAccount.toString()
    );

    return selectedAccount ? selectedAccount.is_demo : false;
  }

};

export default UserUtil;
