/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import numeral from 'numeral';
import actionTypes from '../constants/actionTypes';
import activationConstants from '../constants/activationConstants';
import LocalStorageUtil from '../components/util/LocalStorageUtil';
import ActivationUtil from '../components/util/ActivationUtil';
import activationStatuses from '../constants/activationStatuses';

const initialState = {
  steps: 5,
  activeStep: 0,
  status: undefined,
  loading: false,
  updating: false,
  isHighRisk: false,
  isExternal: false,
  error: null,
  loadedSection: null,
  ownersForm: {
    primaryOwner: 'owner_0',
    owners: [
      {
        fullName: null,
        dateOfBirth: null,
        email: null,
        socialSecurityNumber: null,
        phoneNumber: null,
        equityOwnershipPercentage: null,
        street: null,
        street2: null,
        city: null,
        state: -1,
        zipCode: null
      }
    ]
  },
  businessForm: {
    corporateName: null,
    dbaName: null,
    businessType: -1,
    federalTaxIdNumber: null,
    mcc: null,
    industryType: -1,
    cardPresent: 0,
    keyed: 0,
    moto: 0,
    internet: 0,
    street: null,
    street2: null,
    city: null,
    state: -1,
    zipCode: null,
    accountType: -1,
    accountNumber: null,
    routingNumber: null,
    phone: null,
    email: null,
  },
  selectableEquipment: [],
  selectedEquipment: [],
  merchantApplicationPdf: null,
  loadingMerchantApplication: false,
  isApprovedStatusBannerHidden: localStorage.getItem('pa-approved-banner-hidden')
};

export const parseBusinessResponse = ({business = {}, bankAccount = {}}) => {
  const {
    percentOfBusinessTransactions: {
      cardSwiped: cardPresent,
      internet,
      keyedCardPresentNotImprinted: keyed,
      mailOrPhoneOrder: moto,
    } = {},
    businessType
  } = business;

  const { businessAddress } = business;
  const corporate = businessAddress?.corporate || {};
  const websites  = business?.websites || [];

  const {
    street,
    street2,
    city,
    state: stateAbbrv,
    zipCode
  } = corporate;

  const fields = [
    'corporateName', 'dbaName',
    'federalTaxIdNumber', 'mcc', 'industryType',
    'accountType', 'accountNumber', 'routingNumber',
    'phone', 'email'
  ].reduce((obj, key) => {
    obj[key] = business[key];
    return obj;
  }, {});

  const [website] =  websites;
  const businessWebsite = website?.url;

  return {
    ...fields,
    cardPresent,
    internet,
    moto,
    businessType: businessType ? businessType : -1,
    keyed,
    street,
    street2,
    city,
    state: stateAbbrv,
    zipCode,
    businessWebsite,
    accountNumber: bankAccount?.demandDepositAccount,
    accountType: bankAccount?.accountType ? bankAccount.accountType : -1,
    routingNumber: bankAccount?.abaRouting,
  }
}

const activationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.prevActivationStep:
      return {...state, activeStep: Math.max(state.activeStep - 1, 0) };
    case actionTypes.nextActivationStep:
      return {...state, activeStep: Math.min(state.activeStep + 1, state.steps - 1) };
    case actionTypes.getBoardingApplicationRequest:
      return {
        ...state,
        activeStep: action.metadata?.initStep ? 0 : state.activeStep,
        loadedSection: action.metadata?.initStep ? null : state.loadedSection,
        loading: true,
        error: null
      };
    case actionTypes.updateBoardingApplicationRequest:
      return {...state, updating: true, error: null};
    case actionTypes.getBoardingApplicationSuccess:
    case actionTypes.updateBoardingApplicationSuccess:
      let { ownersForm, businessForm } = state;

      if (Boolean(action?.response?.application)) {
        businessForm = action.metadata?.section === activationStatuses.BUSINESS ?
          parseBusinessResponse(action.response.application) : businessForm;

        ownersForm = action.metadata?.section === activationStatuses.OWNER ?
          { owners: action.response.application.principals } : ownersForm;
      }

      return {
        ...state,
        status: action.response?.status || null,
        activeStep: action.metadata?.initStep ? getActiveStep(action.response?.status?.stageCompleted) : state.activeStep,
        isHighRisk: action?.response?.application?.business?.isHighRisk || state.isHighRisk,
        loadedSection: action.metadata?.section || null,
        loading: false,
        updating: false,
        ownersForm,
        businessForm,
        isExternal: ActivationUtil.isExternal(action.response),
        selectedEquipment: action.response?.application?.plan?.equipment || [],
      };
    case actionTypes.getBoardingApplicationFailure:
      return {...state, loading: false, updating: false, error: action.error, status: null };
    case actionTypes.updateBoardingApplicationFailure:
      return {...state, loading: false, updating: false, error: action.error};
    case actionTypes.getBoardingEquipmentRequest:
      return { ...state,  loading: true, error: null };
    case actionTypes.getBoardingEquipmentFailure:
      return { ...state,  loading: false, error: action.error };
    case actionTypes.getBoardingEquipmentSuccess:
      return {
        ...state,
        loading: false,
        selectableEquipment: action.response?.filter(device => ActivationUtil.shouldDisplayDevice(device.equipment_id)).map(device => {
          let cost = numeral(device.outside_cost).value();
          let costType = activationConstants.costType.oneTime;

          device.equipment_fees?.forEach(fee => {
            if (fee.fee_code.toLowerCase().includes('monthly')) {
              cost = numeral(fee.max_value).value();
              costType = activationConstants.costType.monthly;
            }
          });

          const equipment = {
            id: device.equipment_id,
            quantity: _.get(_.find(state.selectedEquipment, { equipmentId: device.equipment_id }), 'quantity', 0),
            cost,
            costType,
            setupFee: numeral(device.setup_fee).value(),
            ...(activationConstants.hardware[device.equipment_id] || {
              title: device.model_name,
              image: device.image_url,
              details: [],
              order: 10
            })
          };

          if (!equipment.image) {
            equipment.image = `/resources/activation-equipment-${equipment.id}.png`;
          }

          return equipment;
        }).sort((device1, device2) => {
          if (device1.order === device2.order) {
            return device1.title.localeCompare(device2.title);
          }
          return device1.order - device2.order;
        }) || []
      };
    case actionTypes.updateBoardingEquipmentCount:
      return {
        ...state,
        selectableEquipment: state.selectableEquipment.map(device => {
          return (device.id === action.id) ? {
            ...device,
            quantity: action.count
          } : device;
        })
      };
    case actionTypes.publishBoardingApplicationRequest:
      return {...state, updating: true, error: null};
    case actionTypes.publishBoardingApplicationSuccess:
      return {
        ...state,
        status: action.response,
        updating: false,
        error: null
      };
    case actionTypes.publishBoardingApplicationFailure:
      return {
        ...state,
        updating: false,
        error: action.error
      };
    case actionTypes.getBoardingPlacementFormRequest:
      return {
        ...state,
        updating: true
      };
    case actionTypes.getBoardingPlacementFormSuccess:
    case actionTypes.getBoardingPlacementFormFailure:
      return {
        ...state,
        updating: false
      };
    case actionTypes.getBoardingApplicationFormRequest:
      return {
        ...state,
        merchantApplicationPdf: null,
        loadingMerchantApplication: true
      };
    case actionTypes.getBoardingApplicationFormSuccess:
      return {
        ...state,
        merchantApplicationPdf: action.response.data,
        loadingMerchantApplication: false
      };
    case actionTypes.getBoardingApplicationFormFailure:
      return {
        ...state,
        merchantApplicationPdf: null,
        loadingMerchantApplication: false
      };
    case actionTypes.logoutSuccess:
      return {
        ...initialState
      };
    case actionTypes.hideApprovedStatusBanner:
      return {
        ...state,
        isApprovedStatusBannerHidden: true
      }
    default:
      return state;
  }
};

export const getActiveStep = (status) => {
  switch (status) {
    case activationStatuses.OWNER:
    case activationStatuses.BUSINESS:
      return 1;
    case activationStatuses.BANK_VALIDATED:
      return LocalStorageUtil.getDemoActivationTrialAccepted() ? 3 : 2;
    case activationStatuses.HARDWARE:
      return 4;
    default:
      return 0;
  }
};

export default activationReducer;
