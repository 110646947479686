/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import { Trans } from 'react-i18next';
import {
  Box, Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link
} from '@mui/material'
import { FaqStyles } from '../../jss/inlineStyles';
import { CustomExpandIcon } from '../../components/shared/accordion/CustomExpandIcon';

const i18TranslationsKeys = {
  SAQList: [
    'installAndMaintain',
    'vendorSupplied',
    'cardHolder',
    'encryptTransmission',
    'antiVirus',
    'secureSystemAndApps',
    'restrictAccess',
    'authenticateAccess',
    'restrictPhysicalAccess',
    'trackAndMonitor',
    'testSecuritySystem',
    'maintainPolicy'
  ],
  howToAccessOptions: [
    {
      title: 'reviewPCIRequirementsTitle',
      description: 'reviewPCIRequirementsDescription'
    },
    {
      title: 'inventoryITTitle',
      description: 'inventoryITDescription'
    },
    {
      title: 'findVulnerabilitiesTitle',
      description: 'findVulnerabilitiesDescription'
    },
    {
      title: 'thirdPartyTitle',
      description: 'thirdPartyDescription'
    }
  ],
  securityListOptions: [
    {
      title: 'accessingTitle',
      description: 'accessingDescription'
    },
    {
      title: 'reportingTitle',
      description: 'reportingDescription'
    },
    {
      title: 'monitoringAlertingTitle',
      description: 'monitoringAlertingDescription'
    }
  ],
  exclusionsListOptions: [
    'incidentOcurred',
    'incidentKnow',
    'incidentDiscovered',
    'incidentFailed',
    'costsOrExpenses',
    'incidentCertified',
    'softwareWithinYourControl',
    'softwareIncident'
  ]
}

const DisputesFaq = ({ contactEmail, contactText, contactTextTwo, t }) => {

  return (
    <Box sx={{ ...FaqStyles }} className='pciFaq'>
      <Accordion>
        <AccordionSummary
            aria-controls='panel1a-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel1a-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.whatsPCIDSS.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>
            {t('PCICompliance.FAQS.whatsPCIDSS.PCDStandard')}
          </Typography>
          <Typography fontSize={13}>
            {t('PCICompliance.FAQS.whatsPCIDSS.PCDGoal')}
          </Typography>
          <Typography fontSize={13}>
            {t('PCICompliance.FAQS.whatsPCIDSS.PCDCompliance')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel2-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel2-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.whatIsSAQ.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>
            {t('PCICompliance.FAQS.whatIsSAQ.selfAssessmentQuestionnaire')}
          </Typography>
          <List sx={{ listStyle: 'decimal', paddingLeft: 4, fontSize: 13 }}>
            {i18TranslationsKeys.SAQList.map((item, index) => (
              <ListItem
                  key={index}
                  sx={{ display: 'list-item', paddingY: 0 }}
              >
                <ListItemText
                    primary={t(`PCICompliance.FAQS.whatIsSAQ.requirementsList.${item}`)}
                    primaryTypographyProps={{ fontSize: 13 }}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel3-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel3-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.howToAccessPCI.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          {i18TranslationsKeys.howToAccessOptions.map((item, index) => (
            <p key={`${index}-${item.title}`}>
              <b><i>{t(`PCICompliance.FAQS.howToAccessPCI.howToAccessOptions.${item.title}`)} </i></b>
              {t(`PCICompliance.FAQS.howToAccessPCI.howToAccessOptions.${item.description}`)}
            </p>
          ))}
          <p><i><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/qualified_security_assessors'
              target='_blank'
              className='linkLike'
                >{t('PCICompliance.FAQS.howToAccessPCI.QSAsLink')}</a></i></p>
          <p><i><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/approved_scanning_vendors'
              target='_blank'
              className='linkLike'
                >{t('PCICompliance.FAQS.howToAccessPCI.ASVsLink')}</a></i></p>
          <p>{t('PCICompliance.FAQS.howToAccessPCI.securityListTitle')}</p>
          <ul>
          {i18TranslationsKeys.securityListOptions.map((item, index) => (
            <li key={`${index}-${item.title}`}>
              <b>{t(`PCICompliance.FAQS.howToAccessPCI.securityList.${item.title}`)} </b>
              {t(`PCICompliance.FAQS.howToAccessPCI.securityList.${item.description}`)}
            </li>
          ))}
          </ul>
          <p>{t('PCICompliance.FAQS.howToAccessPCI.ensureSecurityControl')}</p>
          <table>
            <tbody>
            <tr>
              <th>{t('PCICompliance.global.level')}</th>
              <th>{t('PCICompliance.global.criteria')}</th>
              <th>{t('PCICompliance.global.requirements')}</th>
            </tr>
            <tr>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowOne.levelOne')}</td>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowOne.criteria')}</td>
              <td>
                <p>{t('PCICompliance.global.annual')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowOne.requirements.annualOne')}</li>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowOne.requirements.annualTwo')}</li>
                </ul>
                <p>{t('PCICompliance.global.quarterly')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowOne.requirements.quarterlyOne')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowTwo.levelTwo')}</td>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowTwo.criteria')}</td>
              <td>
                <p>{t('PCICompliance.global.annual')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowTwo.requirements.annualOne')}
                  </li>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowTwo.requirements.annualTwo')}</li>
                </ul>
                <p>{t('PCICompliance.global.quarterly')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowTwo.requirements.quarterlyOne')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowThree.levelThree')}</td>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowThree.criteria')}</td>
              <td>
                <p>{t('PCICompliance.global.annual')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowThree.requirements.annualOne')}</li>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowThree.requirements.annualOne')}</li>
                </ul>
                <p>{t('PCICompliance.global.quarterly')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowThree.requirements.quarterlyOne')}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowFour.levelFour')}</td>
              <td>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowFour.criteria')}
              </td>
              <td>
                <p>{t('PCICompliance.global.annual')}</p>
                <ul>
                <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowFour.requirements.annualOne')}</li>
                <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowFour.requirements.annualOne')}</li>
                </ul>
                <p>{t('PCICompliance.global.quarterly')}</p>
                <ul>
                  <li>{t('PCICompliance.FAQS.howToAccessPCI.table.body.rowFour.requirements.quarterlyOne')}
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel4-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel4-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.consequencesOfNonCompliance.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <List sx={{ listStyle: 'disc', paddingLeft: 4, fontSize: 13 }}>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.consequencesOfNonCompliance.list.accountClosure')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.consequencesOfNonCompliance.list.branMarket')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.consequencesOfNonCompliance.list.cardBrand')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.consequencesOfNonCompliance.list.dataBreach')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel5-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel5-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.whatToDoWhenBreached.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>{t('PCICompliance.FAQS.whatToDoWhenBreached.descriptionOne')}</Typography>
          <Typography fontSize={13}>
            <Trans i18nKey='PCICompliance.FAQS.whatToDoWhenBreached.descriptionTwo'>
              {t('PCICompliance.FAQS.whatToDoWhenBreached.descriptionTwo')}
            </Trans>
          </Typography>
          <Typography fontSize={13}>{t('PCICompliance.FAQS.whatToDoWhenBreached.largeCompanyBreaches')}</Typography>
          <Typography fontSize={13}>{t('PCICompliance.FAQS.whatToDoWhenBreached.contact')}</Typography>
          <List sx={{ listStyle: 'disc', paddingLeft: 4, fontSize: 13 }}>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText>
                <Typography>
                  <Typography
                      component='span'
                      fontSize={13}
                      id='contactText'
                  >{contactText}</Typography>
                  <Link
                      fontSize={13}
                      href={`mailto:${contactEmail}`}
                      id='contactEmail'
                  >{` ${contactEmail}`}</Link>
                  <Typography
                      component='span'
                      fontSize={13}
                  > {contactTextTwo}</Typography>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.whatToDoWhenBreached.list.mids')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.whatToDoWhenBreached.list.incidentOcurred')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary={t('PCICompliance.FAQS.whatToDoWhenBreached.list.contactInformation')}
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
          </List>
          <p><i>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.title')}</i></p>
          <p>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.description')}</p>
          <ul>
            <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.list.reporting')}</li>
            <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.list.engagement')}</li>
            <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.list.reCertification')}</li>
          </ul>
          <p>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.costsOfTheForensics')}</p>
          <p><i>{t('PCICompliance.global.criteria')}</i></p>
          <p>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.breachForgiveness')}</p>
          <ul>
            <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.mustHaveLevels')}</li>
            <li><b>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.noFineOrPenalty')}</b> {t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.SEQ')}</li>
            <li>
            <Trans
              i18nKey='PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.notSuffered'
              parent='p'
            >
              {t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.notSuffered')}
            </Trans>
            </li>
            <li><b>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.incidentsTitle')}</b> {t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.incidentsDescription')}
            </li>
          </ul>
          <p><i>{t('PCICompliance.global.exclusions')}</i></p>
          <ul>
            {i18TranslationsKeys.exclusionsListOptions.map((item, index) => (
              <li key={`${index}-${item}`}>
                {t(`PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.exclusionList.${item}`)}
              </li>
            ))}
            <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.exclusionList.incidentWithout')} <br/>
              <ol>
                <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.exclusionList.incidentWithoutWrittenNotification')}</li>
                <li>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.exclusionList.incidentWithoutContractually')}</li>
              </ol>
            </li>
          </ul>
          <p><i>{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.additionalInformation')}</i></p>
          <p><a
              href='https://usa.visa.com/dam/VCOM/download/merchants/cisp-what-to-do-if-compromised.pdf'
              target='_blank'
              className='linkLike'
             >{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.additionalInformationVisaLink')}</a></p>
          <p><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/pci_forensic_investigators'
              target='_blank'
              className='linkLike'
             >{t('PCICompliance.FAQS.whatToDoWhenBreached.whatHappensNext.additionalInformationPFIsLink')}</a></p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel6-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel6-header'
        >
          <Typography marginY={0}>
            {t('PCICompliance.FAQS.helpMerchants.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
        <Typography fontSize={13}>
          {t('PCICompliance.FAQS.helpMerchants.description')}
        </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DisputesFaq;

