/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import {getReports} from '../../../actions/reportsActions';
import Menu from '@mui/material/Menu';
import MenuItem from '../../shared/MenuItem';
import PropTypes from 'prop-types';
import CsvUtil from '../../util/CsvUtil';
import IconUtil from '../../util/IconUtil';
import ReportUtil from '../../util/ReportUtil';
import SearchBarField from '../../SearchBarField';
import Loading from '../../Loading';
import DateUtils from '../../util/DateUtil';
import EmployeeFlashReportTable from '../EmployeeFlashReportTable';

export default class EmployeeFlashReport extends Component {

  constructor(props) {
    super(props);

    this.saveCsv = this.saveCsv.bind(this);
    this.processEmployeeFlash = this.processEmployeeFlash.bind(this);
    this.handlePrintViewClick = this.handlePrintViewClick.bind(this);
    this.showReportPopover = this.showReportPopover.bind(this);
    this.dismissReportPopover = this.dismissReportPopover.bind(this);
    this.state = {
      open: false,
      anchorElement: null,
      selectedRow: null
    }
  };

  showReportPopover({ rowIndex, target }) {
    this.setState({
      anchorElement: target,
      selectedRow: rowIndex
    });
  };

  dismissReportPopover() {
    this.setState({
      anchorElement: null,
      selectedRow: null
    });
  };

  processEmployeeFlash() {
    const employee = this.props.employees.filteredModally.length ?
      this.props.employees.filteredModally[this.state.selectedRow] :
      this.props.employees.filteredData[this.state.selectedRow];
    const that = this;

    const dateRange = this.props.userExperience.selectedDate.dateRange ? this.props.userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange('Today', this.props.merchantSettings.customReportStartTime);

    return this.props.dispatch(getReports(this.props.user, dateRange, null, this.props.userExperience.allAccounts, employee?.id))
      .then((resp) => {
        that.saveCsv(employee, resp.response);
      });
  }

  getOrderedEmployees() {
    const { employees } = this.props;
    const employeesList = employees?.filteredModally?.length ? employees.filteredModally : employees?.filteredData;

    return employeesList?.sort((a, b) => b.is_active - a.is_active) || [];
  }

  handlePrintViewClick() {
    const  reportWindow = window.open('about:blank');
    const employees = this.getOrderedEmployees();
    const employee = employees[this.state.selectedRow];
    let that = this;
    const achEnabled = this.props.merchantSettings?.merchantSettings?.ach_enabled;

    const dateRange = this.props.userExperience.selectedDate.dateRange ? this.props.userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange('Today', this.props.merchantSettings.customReportStartTime);

    return this.props.dispatch(getReports(this.props.user, dateRange, null, this.props.userExperience.allAccounts, employee?.id))
      .then((payload) => {
        const flashReportData = { ...payload.response, employee  }
        const csvData = CsvUtil.createFlashReportCsv(flashReportData, that.props.user, achEnabled);
        const html = ReportUtil.createHtmlFromCsvReport('Employee Flash Report', that.props, csvData);
        if (!!reportWindow?.document) {
          reportWindow.document.write(html);
          reportWindow.document.title = 'Employee Flash Report';
        }
      });
  }

  saveCsv(employee, reportsData) {
    this.props.exportReport('EmployeeFlashReport', { ...this.props, fromReportView: true, employee, reportsData, merchantSettings: this.props.merchantSettings });
  }

  render() {
    const {isFetching, employees, t} = this.props;
    const printIcon = IconUtil.getIcon('PrintIcon');
    const exportIcon = IconUtil.getIcon('LaunchIcon');

    let reportPopover = (
      <Menu
        open={Boolean(this.state.anchorElement)}
        anchorEl={this.state.anchorElement}
        anchorOrigin={{horizontal: 'left', vertical: 'center'}}
        transformOrigin={{horizontal: 'center', vertical: 'top'}}
        className='employeeReportMenu'
        onClose={this.dismissReportPopover}
      >
        <MenuItem className='employeeReportMenuItem' leftIcon={printIcon} primaryText={t('Print')} onClick={this.handlePrintViewClick}/>
        <MenuItem className='employeeReportMenuItem' leftIcon={exportIcon} primaryText={t('Export')} onClick={this.processEmployeeFlash}/>
      </Menu>
    );

    const isEmpty = employees.data === null;

    if (isEmpty && isFetching) {
      return (
        <Loading/>
      )
    } else {
      return (
        <div className='employeeFlashReport'>
          <div className='selectEmployee'>{t('SelectEmployee')}</div>
          <br/>
          <SearchBarField {...this.props} filterType='modalSearch' searchPlaceholder={t('SearchEmployees')} >
            {this.props.searchAccessory}</SearchBarField>
          <EmployeeFlashReportTable t={t} employees={employees} onRowClick={this.showReportPopover} />
          {reportPopover}
        </div>
      );
    }
  }
}

EmployeeFlashReport.propTypes = {
  reports: PropTypes.object.isRequired,
};

EmployeeFlashReport.defaultProps = {
  isFetching: true,
};
