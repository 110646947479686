/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Box } from '@mui/material';
import IconUtil from '../../util/IconUtil';
import NoData from '../../NoData';
import CsvUtil from '../../util/CsvUtil';
import ReportUtil from '../../util/ReportUtil';
import {
  clearCustomReportSelected,
  setCustomReportSelected,
  setReportFailure,
  toggleDeleteReportModal,
  toggleNoDataReportModal
} from '../../../actions/reportsActions';
import { deleteCustomReportSelected } from '../../../actions/merchantSettingsActions';
import UserUtil from '../../util/UserUtil';
import moment from 'moment';
import {getTransactionReceipts} from '../../../actions/transactionsActions';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';
import { customReportStyles } from '../../../jss/table';

export default class CustomReports extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handlePrintViewClick = this.handlePrintViewClick.bind(this);
    this.closeNoDataReportModal = this.closeNoDataReportModal.bind(this);
    this.closeDeleteReportModal = this.closeDeleteReportModal.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
  }

  handlePrintViewClick(customReport) {
    const { dispatch, user, userExperience, auth, reportDate, customFields } = this.props;
    const isLargeProcessor = UserUtil.isLargeProcessor(user);
    dispatch(getTransactionReceipts(user, reportDate, userExperience.allAccounts, 'verbose', userExperience.historicHardwareMode, null, isLargeProcessor ? null : 'all'))
      .then((transactions) => {
        if (transactions?.response?.length) {
          let reportWindow = window.open('about:blank');

          const initialTransactionsList = isLargeProcessor ? transactions?.verbose?.rows : transactions?.response;
          const csvData = CsvUtil.createCustomReportCsv({
            transactions: initialTransactionsList,
            user,
            processingLevel: auth.processingLevel,
            columns: customReport.columns,
            customFields
          });
          const html = ReportUtil.createHtmlFromCsvReport(customReport.name, this.props, csvData);
          const startDate = reportDate && reportDate.startDate ? moment(reportDate.startDate).format('MMDDYYYY') : ' N/A ';
          const endDate = reportDate && reportDate.endDate ? moment(reportDate.endDate).format('MMDDYYYY') : ' N/A ';
          if (!!reportWindow?.document) {
            reportWindow.document.write(html);
            reportWindow.document.title = `${customReport.name}_${user.data.merchantAccounts[0].mid}_from_${startDate}_to_${endDate}.csv`;
          }
          dispatch(clearCustomReportSelected());
        } else {
          dispatch(toggleNoDataReportModal());
        }
      })
      .catch(error => dispatch(setReportFailure(error)));
  }

  async handleClick(customReport) {
    const { dispatch, exportReport } = this.props;
    await dispatch(setCustomReportSelected(customReport));
    exportReport('CustomReports', this.props);
  }

  handleDeleteClick(customReport) {
    const { dispatch } = this.props;
    dispatch(setCustomReportSelected(customReport));
    dispatch(toggleDeleteReportModal());
  }

  async handleOnDeleteClick() {
    const { dispatch, user } = this.props;
    dispatch(deleteCustomReportSelected(user));
    this.closeDeleteReportModal();
  }

  closeNoDataReportModal() {
    this.props.dispatch(toggleNoDataReportModal());
  }

  closeDeleteReportModal() {
    this.props.dispatch(toggleDeleteReportModal());
  }

  render() {

    const {merchantSettings, t, reports} = this.props;
    const customReports = merchantSettings?.merchantSettings?.custom_fields?.custom_report;
    const printIcon = IconUtil.getIcon('PrintIcon');
    const exportIcon = IconUtil.getIcon('LaunchIcon');
    const deleteIcon = IconUtil.getIcon('DeleteIcon');

    if (customReports?.length > 0) {

      return (
        <Box
          sx={customReportStyles}
          data-test-id={'customReport'}
        >
          <div className='flexRowHeader'>
            <div>{'REPORT NAME'}</div>
            <div>{'FIELDS'}</div>
            <div>{'ACTIONS'}</div>
          </div>

          {customReports.map((customReport, index) => {
            return (
              <div key={index} className='flexRowItem'>
                <div>{customReport.name}</div>
                <div className='customReportFields'>
                  {
                    customReport.columns.map(column => <div key={column}>{column}</div>)
                  }
                </div>
                <div className='actions'>
                  <Button variant='link' className='printAction' onClick={() => this.handlePrintViewClick(customReport)} startIcon={printIcon} label={t('Print')} />
                  <Button variant='link' className='exportAction' onClick={() => this.handleClick(customReport)} startIcon={exportIcon} label={t('Export')} />
                  <Button variant='link' className='deleteAction' onClick={() => this.handleDeleteClick(customReport)} startIcon={deleteIcon} label={<span className='hideMobileOnly hideTablet'>{t('Delete')}</span>} />
                </div>
              </div>
            );
          })}
          <Modal
            externalClassName='reportsInfoDialog'
            title={t('NoDataDialog.Title')}
            open={reports.isNoDataReportModalOpen}
            confirmText={t('Close')}
            onConfirm={this.closeNoDataReportModal}
            hideCancelButton
          >
            <p>{t('NoDataDialog.Message')}</p>
          </Modal>

          <Modal
            externalClassName='deleteReportDialog'
            title={t('DeleteReportDialog.Title')}
            open={reports.isDeleteReportModalOpen}
            confirmText={t('DeleteReportDialog.Confirm')}
            cancelText={t('No')}
            onConfirm={this.handleOnDeleteClick}
            onClose={this.closeDeleteReportModal}
          >
            <p>{t('DeleteReportDialog.Message')}</p>
          </Modal>
        </Box>
      )
    } else {
      return (<NoData text={t('NoCustomReports')} />)
    }
  }
}

CustomReports.defaultProps = {
  isFetching: true,
};
