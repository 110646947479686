/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import Modal from '../../shared/Modal';
import PropTypes from 'prop-types';
import messages from '../../../constants/messages';
import Terms2FAForm from '../../legal/Terms2FAForm';
import Button from '../../shared/Button';
import {
  getPhoneVerificationCode,
  receiveLogout,
  resetTwoFactorAuthCodeStatus,
  resetTwoFactorAuthPhoneStatus,
  validatePhoneVerificationCode
} from '../../../actions/authActions';
import { setVTAccessProcessValues, removeVTAccessProcessValues } from '../../../actions/userExperienceActions';
import IconUtil from '../../util/IconUtil';
import labels from '../../../constants/labels';
import applicationConstants from '../../../constants/applicationConstants';
import { toggleTerms2FADialog } from '../../../actions/userExperienceActions';
import { stopAsyncValidation } from 'redux-form';

export default class Terms2FADialog
  extends Component {

  constructor(props) {
    super(props);
    this.callAction = this.callAction.bind(this);
    this.logOut = this.logOut.bind(this);
    this.getMainActionLabel = this.getMainActionLabel.bind(this);
    this.validateToken = this.validateToken.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.phoneTokenValidationFails = this.phoneTokenValidationFails.bind(this);
    this.validationAttempts = this.validationAttempts.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(resetTwoFactorAuthCodeStatus());
    this.props.dispatch(resetTwoFactorAuthPhoneStatus());
  }

  callAction() {
    const {twoFactorPhoneStatus, closeAction} = this.props;

    if (twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.default || twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.error) {
      this.validatePhone().then(response => {
        if (!response?.authenticated) {
          this.phoneTokenValidationFails();
        }
      }).catch(() => closeAction());
    }

    if (twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.verified) {
      this.validateToken();
    }

  }

  validatePhone() {
    const {dispatch, user, formReducer} = this.props;
    return dispatch(getPhoneVerificationCode(user, formReducer.terms2FAForm.values.cellPhone, 'vt'));
  }

  phoneTokenValidationFails(isTokenFail) {
    const { uxStorage, dispatch } = this.props;
    const option = isTokenFail ? 'vt_tokenValidationFail' : 'vt_phoneValidationFail';
    uxStorage.vtaccess[option] = uxStorage.vtaccess[option] + 1;

    if (uxStorage.vtaccess[option] >= 3) {
      uxStorage.vtaccess.vt_processEnd = true ;
    }

    const error = isTokenFail ?
      {token: applicationConstants.codeStatus.error} :
      {cellPhone: applicationConstants.phoneVerificationStatus.error};

    dispatch(stopAsyncValidation('terms2FAForm', error))
    dispatch(setVTAccessProcessValues({ ...uxStorage.vtaccess}));
  }

  validateToken() {
    const { dispatch, user, formReducer, uxStorage } = this.props;
    const status = this.props.twoFactorCodeStatus;

    if (status === applicationConstants.codeStatus.sent || (status === applicationConstants.codeStatus.error)) {
      dispatch(validatePhoneVerificationCode(user, formReducer.terms2FAForm.values.cellPhone, formReducer.terms2FAForm.values.token, 'vt')).then(res => {
        if (res?.type && res.type === messages.twoFactorTokenValidated) {

          dispatch(setVTAccessProcessValues({
            ...uxStorage.vtaccess,
            vt_processEnd: true,
            vt_processPassed: true
          }));
          return;
        }
        this.phoneTokenValidationFails(true);
      }).catch(() => this.props.closeAction());
    }

  }

  logOut() {
    const { acceptAction, dispatch, user, uxStorage } = this.props;
    acceptAction()
      .then(() => {
        dispatch(toggleTerms2FADialog(false));
        if (user.data.mobile_number && uxStorage?.vtaccess?.vt_processPassed) {
          dispatch(receiveLogout());
        } else {
          dispatch(removeVTAccessProcessValues());
        }
      })
      .catch(err => dispatch(receiveLogout()));
  }

  getMainActionLabel() {
    const {twoFactorPhoneStatus, twoFactorCodeStatus, t} = this.props;
    const codeStatus = applicationConstants.codeStatus;
    const phoneStatus = applicationConstants.phoneVerificationStatus;

    if (twoFactorPhoneStatus !== phoneStatus.verified) {
      return (twoFactorPhoneStatus === phoneStatus.default || twoFactorPhoneStatus === phoneStatus.error) ? t(phoneStatus.default) : t(phoneStatus.verifying);
    } else {
      return (twoFactorCodeStatus === codeStatus.default || twoFactorCodeStatus === codeStatus.error || twoFactorCodeStatus === codeStatus.sent) ? t(codeStatus.sent) : t(codeStatus.validating);
    }
  }

  validationAttempts() {
    const { twoFactorPhoneStatus, uxStorage } = this.props;

    return twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.verified ? uxStorage.vtaccess.vt_tokenValidationFail : uxStorage.vtaccess.vt_phoneValidationFail;
  }

  render() {
    const {
      user,
      closeAction,
      twoFactorPhoneStatus,
      twoFactorCodeStatus,
      uxStorage,
      t,
    } = this.props;

    const vtaccess = uxStorage?.vtaccess || {};

    const lastDigits = user?.data?.mobile_number;
    const processPassed = vtaccess?.vt_processPassed;
    const processFinished = vtaccess?.vt_processEnd;

    const processFinishedSuccess = (
      <div className={'processFinishedSuccess'}>
        {IconUtil.getIcon('DoneIcon')}
        <p className={'headerBody'}>{t(messages.terms2FADialog.processFinishedSuccess)}</p>
      </div>
    );
    const processFinishedFailure = (
      <div className={'processFinishedFailure'}>
        {IconUtil.getIcon('AddIcon')}
        <p className={'headerBody'}>
          {
            `${vtaccess.vt_tokenValidationFail && vtaccess.vt_tokenValidationFail >= 3 ? t(messages.terms2FADialog.processFinishedFailureByToken) : t(messages.terms2FADialog.processFinishedFailureByPhone)} `
          }
          <a className={'linkLike'} href={`tel:${labels.ph.phoneNumber}`}>{labels.ph.phoneNumber}</a>
          {' or '}
          <a className={'linkLike'} href={`mailto:${labels.ph.emailAddress}`}>{labels.ph.emailAddress}</a>
          {
            ` ${vtaccess.vt_tokenValidationFail && vtaccess.vt_tokenValidationFail >= 3 ? t(messages.terms2FADialog.processFinishedFailureByTokenAdd) : t(messages.terms2FADialog.processFinishedFailureByPhoneAdd)}`
          }
        </p>
      </div>
    );
    const continueButton = ((processFinished || !lastDigits) &&
      <Button
        label={lastDigits && processPassed ? t('LogOut') : t('Continue')}
        onClick={this.logOut}
      />
    );
    const cancelActionButton = (!processFinished &&
      <Button
        variant='text'
        label={t('Cancel')}
        onClick={this.props.closeAction}
      />
    );
    const mainActionButton = (!processFinished &&
      <Button
        disabled={
          !this.props.formReducer?.terms2FAForm?.values?.cellPhone || this.props.formReducer?.terms2FAForm?.values?.cellPhone.length < 10 ||
          twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.verifying ||
          twoFactorCodeStatus === applicationConstants.codeStatus.validating
        }
        label={this.getMainActionLabel()}
        onClick={this.callAction}
      />
    );
    const resendCodeButton = (!processFinished &&
      this.props.twoFactorCodeStatus !==
      applicationConstants.codeStatus.default &&
      this.props.twoFactorPhoneStatus === applicationConstants.phoneVerificationStatus.verified &&
      <Button
        className={`resendCodeButton ${this.props.twoFactorCodeStatus ===
        applicationConstants.codeStatus.error ?
          'shrink' :
          ''}`}
        disabled={!this.props.formReducer?.terms2FAForm?.values?.cellPhone}
        label={t('SendNewCode')}
        onClick={this.validatePhone}
        variant='text'
      />
    );
    const noUserPhone = (
      <div className={'processFinishedFailure'}>
        {IconUtil.getIcon('AddIcon')}
        <p className={'headerBody'}>
          {t(messages.terms2FADialog.merchantHaveNoPhoneNumber)}
          <a className={'linkLike'} href={`tel:${labels.ph.phoneNumber}`}>{labels.ph.phoneNumber}</a>
          {t('Legal.Or')}
          <a className={'linkLike'} href={`mailto:${labels.ph.emailAddress}`}>{labels.ph.emailAddress}</a>
          {t(messages.terms2FADialog.processFinishedFailureByPhoneAdd)}
        </p>
      </div>);

    const terms2FAForm = (
      !processFinished ?
        <span>
          <Terms2FAForm t={this.props.t} lastDigits={lastDigits} twoFactorPhoneStatus={this.props.twoFactorPhoneStatus}/>
          {(vtaccess.vt_phoneValidationFail > 0 || vtaccess.vt_tokenValidationFail > 0) &&
            t('Legal.VerificationAttempts', {attempts: this.validationAttempts()})
          }
        </span> :
        !!processPassed ?
          processFinishedSuccess : processFinishedFailure
    );
    const dialogActions = lastDigits ? [continueButton, resendCodeButton, cancelActionButton, mainActionButton] : [cancelActionButton, continueButton];

    return (
      <Modal
        actions={dialogActions}
        actionsContainerClassName={'dialogActions'}
        contentClassName='messageDialog twoFATermsDialog'
        id='TwoFactorVerificationDialog'
        modal={false}
        onClose={closeAction}
        open
        title={t(messages.terms2FADialog.header)}
        titleClassName='dialogTitle'
      >
        {lastDigits ? terms2FAForm : noUserPhone}
      </Modal>
    )
  }
}

Terms2FADialog.propTypes =
  {
    closeAction: PropTypes.func.isRequired,
    acceptAction: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    uxStorage: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    twoFactorCodeStatus: PropTypes.string.isRequired,
    twoFactorPhoneStatus: PropTypes.string.isRequired,
    formReducer: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
