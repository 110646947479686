/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import { submit } from 'redux-form';
import {Link} from 'react-router-dom';
import {loginCsUser} from '../actions/authActions';
import Loading from './Loading';
import ClientServiceForm from './ClientServiceForm';
import PropTypes from 'prop-types';
import routes from './../constants/routes';
import RightArrowIcon from './icons/RightArrowIcon';
import Button from './shared/Button';
import LanguageSelector from './shared/LanguageSelector';
import withTranslate from '../hoc/withTranslate';
import ReactRecaptcha3 from 'react-google-recaptcha3';

export class ClientServiceComponent extends Component {

  constructor(props) {
    super(props);

    this.loginCsUser = this.loginCsUser.bind(this);
    this.handleEnterPressed = this.handleEnterPressed.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.getCaptchaToken = this.getCaptchaToken.bind(this);
  }

  async getCaptchaToken() {
    const token = await ReactRecaptcha3.getToken({ action: 'client_service_signin' });
    return token;
  }

  async componentDidMount() {
    window.addEventListener('keydown', this.handleEnterPressed);
    await ReactRecaptcha3.init(googleRecaptchaClientId);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnterPressed);
    ReactRecaptcha3.destroy();
  }

  onSubmitClick() {
    this.props.dispatch(submit('clientServiceForm'));
  }

  handleEnterPressed(event) {

    if (event.key == 'Enter' || event.keyCode == 13) {
      this.onSubmitClick()
    }
  }

  async loginCsUser(values) {

    if (!this.props.auth.isFetching) {
      const credentials = {
        username: values.username.trim(),
        password: values.password.trim(),
        mid: values.mid.trim(),
        recaptchaToken: await this.getCaptchaToken()
      };
      this.props.dispatch(loginCsUser(credentials));
    }

  }

  render() {

    const {errorMessage, user, auth, userExperience, t} = this.props;

    const appRoutePrefix = globalApplicationLabel.path;
    const submitButtonDisabled = !userExperience.enableFormSubmitButton;

    const loadingIndicator = auth.isFetching === true ? (<Loading />) : null;

    const csLoginContainer = (

      <div className='loginContainer'>
        {loadingIndicator}
        <ClientServiceForm onSubmit={this.loginCsUser} {...this.props}/>
        {errorMessage &&
        <p className='loginErrorMessage' style={{color: 'red'}}>{t(errorMessage)}</p>
        }
        <br />
        <Button
          className='loginButton'
          tabIndex={3}
          onClick={this.onSubmitClick}
          disabled={submitButtonDisabled}
          label={t('Submit')}
        />

        <div className='forgotPasswordContainer'>
          <Link to={appRoutePrefix + routes.clientService.root + routes.clientService.forgotPassword}>
            <span>{t('ForgotPassword')}</span>
            <span className='arrowIcon'><RightArrowIcon color='labelColor' /></span>
          </Link>
        </div>

        <div className='grecaptcha-protection'>
          This site is protected by reCAPTCHA and the Google
          <a href='https://policies.google.com/privacy' target='_blank'>Privacy&nbsp;Policy</a> and
          <a href='https://policies.google.com/terms' target='_blank'>Terms&nbsp;of&nbsp;Service</a> apply.
        </div>

        <br />
        <br />
        <br />
        <LanguageSelector  {...this.props} />
      </div>
    );

    const knowWhoYouAre =  user.data && user.data.merchantAccounts && user.data.merchantAccounts[0] ?
      user.data.merchantAccounts[0].mid : 'not found';

    const successContainer = (
      <div className='isSprContainer niceContainer'>
        <div className='niceHeader'>
          {`${t('Success')}.`}
        </div>
        <br />
        <br />
        <br />
        <div className='instructionText'>
          {`${t('ClientServiceForm.KnowYouAreNow')} ${knowWhoYouAre}`}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='niceLink'>
          <Link to={appRoutePrefix}>{t('ClientServiceForm.Back')}</Link>
        </div>
      </div>
    );

    const nopeContainer = (
      <div className='notSprContainer niceContainer'>
        <div className='niceHeader'>
          {t('ClientServiceForm.NoSprContainerError')}
        </div>
        <br />
        <br />
        <br />
        <div className='instructionText'>
          {t('ClientServiceForm.NoSprContainerInstruction')}
        </div>
        <br />
        <br />
        <div className='instructionText notSprErrorMessage'>
          {errorMessage ? 'ERROR: ' + t(errorMessage) : null}
        </div>
      </div>
    );

    let containerToDisplay = csLoginContainer;

    if (auth.isSpr === true) {
      containerToDisplay = successContainer;
    } else if (auth.isSpr === false) {
      containerToDisplay = nopeContainer;
    }

    return (
      <div className='clientService pageWrap'>
        {containerToDisplay}
      </div>

    )
  }

}

ClientServiceComponent.propTypes = {
  errorMessage: PropTypes.string,
};

export default withTranslate(ClientServiceComponent);
