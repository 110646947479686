import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment/moment';
import CommonUtil from './CommonUtil';
import DateUtil from './DateUtil';
import i18n from '../../locales/i18n';
import {PriceName} from './InvoiceUtil';

const { t } = i18n;
const diacriticsMap = {};
const createDiacriticsMap = () => {
  if (!Object.keys(diacriticsMap).length) {
    const defaultDiacriticsRemovalap = [
      {'base':'A', 'letters':'\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'},
      {'base':'AA','letters':'\uA732'},
      {'base':'AE','letters':'\u00C6\u01FC\u01E2'},
      {'base':'AO','letters':'\uA734'},
      {'base':'AU','letters':'\uA736'},
      {'base':'AV','letters':'\uA738\uA73A'},
      {'base':'AY','letters':'\uA73C'},
      {'base':'B', 'letters':'\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'},
      {'base':'C', 'letters':'\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E'},
      {'base':'D', 'letters':'\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779'},
      {'base':'DZ','letters':'\u01F1\u01C4'},
      {'base':'Dz','letters':'\u01F2\u01C5'},
      {'base':'E', 'letters':'\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E'},
      {'base':'F', 'letters':'\u0046\u24BB\uFF26\u1E1E\u0191\uA77B'},
      {'base':'G', 'letters':'\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E'},
      {'base':'H', 'letters':'\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D'},
      {'base':'I', 'letters':'\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197'},
      {'base':'J', 'letters':'\u004A\u24BF\uFF2A\u0134\u0248'},
      {'base':'K', 'letters':'\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2'},
      {'base':'L', 'letters':'\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780'},
      {'base':'LJ','letters':'\u01C7'},
      {'base':'Lj','letters':'\u01C8'},
      {'base':'M', 'letters':'\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C'},
      {'base':'N', 'letters':'\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4'},
      {'base':'NJ','letters':'\u01CA'},
      {'base':'Nj','letters':'\u01CB'},
      {'base':'O', 'letters':'\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C'},
      {'base':'OI','letters':'\u01A2'},
      {'base':'OO','letters':'\uA74E'},
      {'base':'OU','letters':'\u0222'},
      {'base':'P', 'letters':'\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754'},
      {'base':'Q', 'letters':'\u0051\u24C6\uFF31\uA756\uA758\u024A'},
      {'base':'R', 'letters':'\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782'},
      {'base':'S', 'letters':'\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784'},
      {'base':'T', 'letters':'\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786'},
      {'base':'TZ','letters':'\uA728'},
      {'base':'U', 'letters':'\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244'},
      {'base':'V', 'letters':'\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245'},
      {'base':'VY','letters':'\uA760'},
      {'base':'W', 'letters':'\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72'},
      {'base':'X', 'letters':'\u0058\u24CD\uFF38\u1E8A\u1E8C'},
      {'base':'Y', 'letters':'\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE'},
      {'base':'Z', 'letters':'\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762'},
      {'base':'a', 'letters':'\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'},
      {'base':'aa','letters':'\uA733'},
      {'base':'ae','letters':'\u00E6\u01FD\u01E3'},
      {'base':'ao','letters':'\uA735'},
      {'base':'au','letters':'\uA737'},
      {'base':'av','letters':'\uA739\uA73B'},
      {'base':'ay','letters':'\uA73D'},
      {'base':'b', 'letters':'\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'},
      {'base':'c', 'letters':'\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184'},
      {'base':'d', 'letters':'\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'},
      {'base':'dz','letters':'\u01F3\u01C6'},
      {'base':'e', 'letters':'\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'},
      {'base':'f', 'letters':'\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'},
      {'base':'g', 'letters':'\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'},
      {'base':'h', 'letters':'\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'},
      {'base':'hv','letters':'\u0195'},
      {'base':'i', 'letters':'\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'},
      {'base':'j', 'letters':'\u006A\u24D9\uFF4A\u0135\u01F0\u0249'},
      {'base':'k', 'letters':'\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'},
      {'base':'l', 'letters':'\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'},
      {'base':'lj','letters':'\u01C9'},
      {'base':'m', 'letters':'\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'},
      {'base':'n', 'letters':'\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'},
      {'base':'nj','letters':'\u01CC'},
      {'base':'o', 'letters':'\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275'},
      {'base':'oi','letters':'\u01A3'},
      {'base':'ou','letters':'\u0223'},
      {'base':'oo','letters':'\uA74F'},
      {'base':'p','letters':'\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755'},
      {'base':'q','letters':'\u0071\u24E0\uFF51\u024B\uA757\uA759'},
      {'base':'r','letters':'\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'},
      {'base':'s','letters':'\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'},
      {'base':'t','letters':'\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'},
      {'base':'tz','letters':'\uA729'},
      {'base':'u','letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'},
      {'base':'v','letters':'\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'},
      {'base':'vy','letters':'\uA761'},
      {'base':'w','letters':'\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73'},
      {'base':'x','letters':'\u0078\u24E7\uFF58\u1E8B\u1E8D'},
      {'base':'y','letters':'\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'},
      {'base':'z','letters':'\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'}
    ];
    for (let i=0; i < defaultDiacriticsRemovalap.length; i++) {
      const letters = defaultDiacriticsRemovalap[i].letters.split('');
      for (let j=0; j < letters.length ; j++) {
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalap[i].base;
      }
    }
  }

  return diacriticsMap;
}

const onlyNumbersRegex = value => value.replace(/[^\d]/g, '');

const FormatTextUtils = {

  formatDOB: (current, previous) => {
    const onlyNumbers = onlyNumbersRegex(current);

    if (previous && (previous.length >= current.length)) {
      return current;
    }

    if (onlyNumbers.length > 8) {
      return previous;
    }

    return onlyNumbers.split('').reduce((acc, curr, i) => `${acc}${(i === 1 || i === 3) ? `${curr}/` : curr}`, '')
  },

  parseCardType: function(cardNumber, network) {

    let cardType = null;
    cardNumber = cardNumber ? cardNumber.toString() : null;

    if (network && network?.length){
      if (network == 'American Express'){
        cardType = 'amex';
      } else if (network.includes('Diners Club')) {
        cardType = 'dinersclub';
      } else {
        cardType = network?.toLowerCase().replace(/\s+/g, '');
      }
    }

    if (cardNumber && cardNumber.length > 4 && cardNumber.length < 20) {
      if (cardNumber[0] == 2 || cardNumber[0] == 5) cardType = 'mastercard';
      if (cardNumber[0] == 4) cardType = 'visa';
      if (cardNumber[0] == 6) cardType = 'discover';

      const amexRegex = new RegExp('^3[47][0-9\\*XF]{13}$');
      const dinersClubRegex =  new RegExp('^3(?:0[0-5]|[68][0-9])[0-9\\\\*XF]{11}$');
      const parsedCardNumber = this.parseCardNumberDigits(cardNumber);

      if (dinersClubRegex.test(parsedCardNumber)) cardType = 'dinersclub';
      if (amexRegex.test(parsedCardNumber)) cardType = 'amex';
    }

    return cardType;
  },

  formatJoin: function (first, second) {

    let formattedJoin = first ? first + ' ' : '';
    formattedJoin = second ? formattedJoin + second : formattedJoin + '';

    return formattedJoin;
  },

  formatName: function (first, last, noNameText) {

    let firstName = first ? first + ' ' : '';
    let lastName = last ? last : '';
    let customerFullName = firstName + lastName;

    return customerFullName ? customerFullName.trim() : noNameText;
  },

  formatNumberWithTrailingText: function (number, trailingText) {

    return number ? number + ' ' + trailingText : 'No ' + trailingText;
  },

  upperCaseFirstLowerCaseRest: function (textToFormat) {

    return _.upperFirst(_.lowerCase(textToFormat));

  },

  removeDiacritics: (str) => {
    const diacriticsMapL = createDiacriticsMap();

    return str ? str.replace(/[^A-Za-z0-9\s]/g, (specialLetter) => {
      return diacriticsMapL[specialLetter] || specialLetter;
    }) : '';
  },

  formatReverseDebitExpiration: function (value) {

    if (!value) {
      return value
    }

    if (value.length === 4) {
      const yearValue = value.slice(0, 2);
      const monthValue = value.slice(2);
      const formattedExpirationDate = `${monthValue}/${yearValue}`;
      return formattedExpirationDate;
    } else {
      return value
    }
  },

  formatCreditCardExpiration: function (value, previousValue) {

    if (!value) {
      return value
    }

    previousValue = !previousValue ? '' : previousValue;

    let passes = true;

    if (value.length === 1) {

      const re = new RegExp('^([0-1])$');
      passes = re.test(value);

    } else if (value.length > previousValue.length) {

      if (value.length === 2) {

        const firstSeg = new RegExp('^(0[1-9]|1[0-2])$');
        passes = firstSeg.test(value);

        if (passes) {
          value += '/';
        }
      } else {
        const secondSeg = new RegExp('^\\d+$');
        const twoDigitYear = value.split('/')[1];
        passes = secondSeg.test(twoDigitYear);
      }

    } else if (previousValue.length === 3) {
      value = value.slice(0, -1); // delete forward slash and second digit
    }

    if (!passes) {
      value = value.slice(0, -1)
    }

    return value;
  },

  formatPhoneNumber: function (value, previousValue) {

    if (!value) {
      return value
    }

    const onlyNumbers = value.replace(/[^\d]/g, '');

    if (!previousValue || value.length > previousValue.length) {

      if (onlyNumbers.length === 3) {
        return '(' + onlyNumbers + ') ';
      }
      if (onlyNumbers.length === 6) {
        return '(' + onlyNumbers.slice(0, 3) + ') ' + onlyNumbers.slice(3) + '-';
      }
    }

    if (onlyNumbers.length <= 3) {
      return onlyNumbers;
    }

    if (onlyNumbers.length <= 6) {
      return '(' + onlyNumbers.slice(0, 3) + ') ' + onlyNumbers.slice(3);
    }

    return '(' + onlyNumbers.slice(0, 3) + ') ' + onlyNumbers.slice(3, 6) + '-' + onlyNumbers.slice(6, 10);

  },

  formatPercentOff: function (value, previousValue = 0) {

    if (!value) {
      return value;
    }

    const regex = new RegExp('[^\\d]', 'g');

    let onlyNumbers = value.toString().replace(regex, '');
    if (previousValue.toString().replace(regex, '').length && value.length < previousValue.length) {
      onlyNumbers = onlyNumbers.toString().substring(0, onlyNumbers.length - 1); // drop the last digit
    }

    return Math.min(onlyNumbers, 100) + `% ${t('Off')}`;
  },

  formatCurrencyOff: function (value, previousValue = 0) {
    if (!value) {
      return value;
    }

    const regex = new RegExp('[^\\d]', 'g');

    const onlyNumbers = value.toString().replace(regex, '');
    let unformattedCurrency = numeral(onlyNumbers).value();

    if (value.toString().indexOf('.') === -1) {
      unformattedCurrency *= 100; // whole dollar amount response (without decimal)
    } else if (value.toString().split('.')[1].length === 1) {
      unformattedCurrency *= 10; // always have two decimal places for change
    }

    if (previousValue.toString().replace(regex, '').length && value.length < previousValue.length) {
      let currencyString = unformattedCurrency.toString();
      unformattedCurrency = currencyString.substring(0, currencyString.length - 1); // drop the last digit
    }

    unformattedCurrency /= 100;
    return numeral(unformattedCurrency).format('$0,0.00') + ` ${t('Off')}`;
  },

  formatCurrencyWithMaxDigit: function (initialValue, maxDigit) {
    if (initialValue === '$0.0') {
      return null;
    }

    if (!initialValue && initialValue !== 0 || initialValue === '$0.0') {
      return initialValue;
    }

    const firstEntry = _.isString(initialValue) && initialValue.length === 1 && initialValue !== '0';
    let value = firstEntry ? (+initialValue)/100 : initialValue;
    const numericInput = Number(value);

    if(_.isNumber(value) || (_.isNumber(numericInput) && !!numericInput)) {
      value = numericInput.toFixed(2);
    }

    const isInputIntegerNumber = Number.isInteger(numericInput);
    const onlyNumbers = isInputIntegerNumber ? numericInput.toString() : value.toString().replace(/[^\d]/g, '');
    let unformattedCurrency = numeral(onlyNumbers).value();

    if (onlyNumbers.length > maxDigit) {
      let currencyString = unformattedCurrency.toString();
      unformattedCurrency = currencyString.substring(0, currencyString.length - 1); // drop the last digit
    }

    if(!isInputIntegerNumber) unformattedCurrency /= 100;
    return numeral(unformattedCurrency).format('$0,0.00');
  },

  formatAlphaNumeric: function (value) {

    if (!value) {
      return value;
    }

    return value.toString().replace(/[^a-z0-9]/g, '');
  },

  formatAlpha: function (value) {

    if (!value) {
      return value;
    }

    return value.toString().replace(/[^a-z A-Z ]/g, '').replace(/\s+/g, ' ');
  },

  formatWholeNumberWithComas: function (value) {

    if (!value) {
      return value;
    }

    const onlyNumbers = value.toString().replace(/[^\d]/g, '');
    return numeral(onlyNumbers).format('0,0');
  },
  formatWholeNumberWithDelimiter: function (value, limit ) {
    const formattedNumber = FormatTextUtils.formatWholeNumber(value);
    return formattedNumber && formattedNumber?.substring(0, limit);
  },
  formatWholeNumber: function (value) {

    if (!value) {
      return value;
    }

    return value.toString().replace(/[^\d]/g, '');
  },

  formatPositiveWholeNumber: function (value) {

    if(/^\+?[1-9]\d*$/.test(value) || !value) {
      return value;
    }

  },

  formatStreetNumber: function (value) {

    if (!value) {
      return value;
    }

    return value.toString().replace(/[^\d|\/ ]/g, '');
  },

  formatCurrency: function (value) {

    if (!value) {
      return value;
    }

    return numeral(value).format('$0,0');
  },

  formatPercentage: function (value) {
    if (!value || typeof value !== 'string') return value
    return `${value?.replace(',', '.')?.replace(/[^\d\.]/g, '')?.replace(/\./, 'x')?.replace(/\./g, '')?.replace(/x/, '.')}%`;

  },

  formatDecimals: function (value) {

    return value ? `${value?.replace(',', '.').replace(/[^\d\.]/g, '').replace(/\./, 'x').replace(/\./g, '').replace(/x/, '.')}` : '';

  },

  formatNumberDoubleDecimals: function (value) {
    if (!value) return value;

    const decimals = this.formatDecimals(value);
    const first = decimals.split('.')[0];
    const second = decimals.split('.')[1] ? decimals.split('.')[1].substring(0, 2) : '';
    const isDecimal = decimals.indexOf('.') > -1;

    return isDecimal ? `${first}.${second}` : first;
  },

  formatCurrencyDoubleDecimals: function (value) {

    if (!value) {
      return value;
    }

    return numeral(value).format('$0.00');
  },

  formatDays: function (value) {

    if (!value) {
      return value;
    }

    let onlyNumbers = value.toString().replace(/[^\d]/g, '');
    let trailingText = onlyNumbers > 1 ? ` ${t('Days')}` : ` ${t('Day')}`;

    return onlyNumbers + trailingText;
  },

  formatFrequency: function (value) {

    if (!value) {
      return value;
    }

    let [length, period = ''] = value.split(' ');

    if (length === '1') {
      period = period.slice(0, -1);
    }

    return `${length} ${period}`.trimRight();
  },

  formattedFrequencyToArray: function (value) {

    if (!value) {
      return [];
    }

    let [length, period = ''] = value.split(' ');
    const lengthIsNumber = length.match(/\d+/);

    if (period === '' && !lengthIsNumber) {
      if (value.toLowerCase() === 'daily') {
        period = t('day');
      } else {
        const splitValue = value.toLowerCase().split('ly');
        if (splitValue.length > 1) {
          period = t(splitValue[0]);
        }
      }
      length = '1';
    }

    return [length, period];

  },

  formatItemQuantity: function (value) {

    if (!value) {
      return value;
    }
    const onlyNumbers = value.toString().replace(/[^\d]/g, '');
    return numeral(onlyNumbers.substring(0, 4)).format('0,0');

  },

  formatItemStockQuantity: function (value) {

    if (!value) {
      return value;
    }
    const onlyNumbers = value.toString().replace(/[^\d]/g, '');
    return numeral(onlyNumbers.substring(0, 3)).format('0,0');

  },

  formatDebit: (type, isDebit) => {

    if ((type === 'Credit Sale' || type === 'Credit Sale - Split Payment') && isDebit) {
      if (type === 'Credit Sale') {
        return 'Debit Sale'
      }
      else {
        return 'Debit Sale - Split Payment'
      }
    }
    else {
      return type;
    }

  },

  formatTransactionType: function (incompleteText, transactionType, selectedReceipt) {

    let transactionTypeString = '';

    if ((selectedReceipt.inv_paid_date && selectedReceipt.inv_paid_date !== null) && selectedReceipt.type !== 'Credit Refund') {
      transactionTypeString = 'Paid Invoice';
    } else {
      let subTransactionText = selectedReceipt.type === 'Void' ||
      selectedReceipt.type === 'Credit Refund' ||
      selectedReceipt.type === 'Cash Refund' ? selectedReceipt.type + ':' : '';

      let parentTransactionText = this.formatJoin(incompleteText, transactionType);

      transactionTypeString = this.formatJoin(subTransactionText, parentTransactionText);
    }

    return transactionTypeString
  },

  formatTransactionId: function (id) {
    return id ? id.replace(/\D/g, '') : id;
  },

  formatMaskedCardNumber: function (first6, last4, network) {
    if (!first6) {
      return last4;
    }

    const formattedFirst6 = `${first6.substring(0, 4)}-${first6.substring(4)}`;

    switch (network) {
      case 'American Express':
        return `${formattedFirst6}XXXX-X${last4}`;
      case 'Diners Club/Carte Blanche':
        return `${formattedFirst6}XXXX-${last4}`;
      default:
        return `${formattedFirst6}XX-XXXX-${last4}`;
    }
  },

  formatDisputeMaskedCardNumber: function (cardNumber, network) {
    const cardNumbersOnly = cardNumber?.replace(/\*/g, '');
    let first6 = 'XXXXXX';
    let last4 = cardNumbersOnly;

    if (cardNumbersOnly?.length > 4) {
      first6 = cardNumbersOnly.substring(0, cardNumbersOnly.length - 4);
      last4 = cardNumbersOnly.substring(cardNumbersOnly.length - 4);
    }

    return FormatTextUtils.formatMaskedCardNumber(first6, last4, network);
  },

  formatCardNumber: function (value) {

    let regex = new RegExp('[^\\d]', 'g');
    let onlyNumbers = value.toString().replace(regex, '');
    let cardType = FormatTextUtils.parseCardType(value);

    let number = onlyNumbers.split('-').join('');

    if (number.length > 0) {
      if (cardType !== 'amex' && cardType !== 'dinersclub' && number.length === 16) {
        number = number.match(new RegExp('.{1,4}', 'g')).join('-');
      } else if (cardType === 'amex' && number.length === 15) {
        number = number.replace(/\b(\d{4})/, '$1-');
        number = number.replace(/-(\d{6})/, '-$1-');
        number = number.substring(0, 17);
      } else if (cardType === 'dinersclub' && number.length === 14) {
        number = number.replace(/\b(\d{4})/, '$1-');
        number = number.replace(/-(\d{6})/, '-$1-');
        number = number.substring(0, 16);
      }
    }

    return number;
  },

  parseCardNumberDigits: function (value) {
    if (!value) return value;
    return value.split('-').join('');
  },

  formatDueInDays: function (selectedDate) {
    if (!selectedDate) return null;
    let days = moment(selectedDate).diff(moment().startOf('day'), 'days');
    return days > 0 ? t('DueInDays', { days: FormatTextUtils.formatDays(days) }) : days === 0 ? t('DueToday') : t('Overdue');

  },

  getQuantityInvoices: function (startDate, endDateString, frequency, payments) {
    if (payments) {
      return `${payments} ${payments > 1 ? t('Invoices').toLowerCase() : t('Invoice').toLowerCase()}`;
    } else {
      const [length, period] = FormatTextUtils.formattedFrequencyToArray(frequency);
      const endDate = endDateString || DateUtil.getTodayWithoutTime().format();
      if (length > 0) {
        const difference = DateUtil.getMomentDate(endDate).diff(DateUtil.getMomentDate(startDate), period, true);
        const quantity = Math.trunc(difference / length) + 1;
        return `${quantity} ${quantity > 1 ? t('Invoices').toLowerCase() : t('Invoice').toLowerCase()}`;
      }
    }
  },

  getCurrentInvoiceFromSeries: function (startDateString, frequency, createdDate) {
    const [length, period] = FormatTextUtils.formattedFrequencyToArray(frequency);

    const endDate = DateUtil.getMomentDate(createdDate);
    const startDate = DateUtil.getMomentDate(startDateString);

    if (startDate.isAfter(endDate)) { return 1; }

    if (length > 0) {
      const difference = endDate.diff(startDate, period, true) || 0;
      const quantity = Math.trunc(difference / length);
      return quantity + 1;
    }
  },

  getInvoiceRepeat: (frequency, end_date, format) => {
    const [length, period] = FormatTextUtils.formattedFrequencyToArray(frequency) || [null, null];
    const dueDate = end_date ? t('InvoiceRepeatUntil', { date: moment(end_date).tz('America/New_York').format(format)}) : t('NoEndDate');
    return `${t('InvoiceRepeat', { length, period: t(period), dueDate})}`
  },

  formatFrequencyInvoice: function (date, invoiceFrequency) {
    const [length, period] = FormatTextUtils.formattedFrequencyToArray(invoiceFrequency);
    const consecutive = length === '1';
    let consecutiveText, preposition, duration;

    if (period?.includes('month')) {
      consecutiveText = consecutive ? FormatTextUtils.upperCaseFirstLowerCaseRest(t('Monthly')) : FormatTextUtils.upperCaseFirstLowerCaseRest(t('Every'));
      preposition = 'OnThe';
      duration = DateUtil.formatDate(date, 'Do');
    } else if (period?.includes('week')) {
      const day = DateUtil.formatDate(date, 'dddd');
      consecutiveText = consecutive ? FormatTextUtils.upperCaseFirstLowerCaseRest(t('Weekly')) : FormatTextUtils.upperCaseFirstLowerCaseRest(t('Every'));
      preposition = 'On';
      duration = day.charAt(day.length - 1) === 's' ? day : day + 's';
    } else if (period?.includes('day')) {
      consecutiveText = consecutive ? FormatTextUtils.upperCaseFirstLowerCaseRest(t('Daily')) : FormatTextUtils.upperCaseFirstLowerCaseRest(t('Every'));
      preposition = 'At';
      duration = DateUtil.formatDate(date, 'h:mm a');
    } else if (period?.includes('year')) {
      consecutiveText = consecutive ? FormatTextUtils.upperCaseFirstLowerCaseRest(t('Yearly')) : FormatTextUtils.upperCaseFirstLowerCaseRest(t('Every'));
      preposition = 'On';
      duration = DateUtil.formatDate(date, 'MM/DD');
    }

    return {
      repeats: 'RepeatsText',
      consecutiveText,
      length: !consecutive && length,
      period: !consecutive && period,
      preposition,
      duration
    };
  },

  getOverdueDays: function (due_date) {
    const date = moment(due_date);
    const now = moment();

    const days = moment(date).diff(now.startOf('day'), 'days');

    if (days === 0) {
      return t('OverdueToday');
    } else {
      return t('OverdueDays', { days: FormatTextUtils.formatDays(days) });
    }
  },

  formatSSN: function (ssn) {
    // this needs to be masked
    if (!(ssn || null)) {
      return '••• •• ••••';
    }

    const r = ssn.trim().replace(/\D/g, ''), length = (r) ? r.length : 0;

    if (length === 4) {
      return r
    } else if (length === 9) {
      return r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, '$1 $2 $3');
    } else if (length > 9) {
      return r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, '$1 $2 $3').slice(0, 11);
    } else {
      return ssn;
    }
  },

  formatFullSSNWithDelimiter: (value, delimiter = ' ') => {
    if (value) {
      let val = value.replace(/\D/g, '').substring(0, 9);
      let newVal = '';

      if ((val.length > 3) && (val.length < 6)) {
        newVal += `${val.substr(0, 3)}${delimiter}`;
        val = val.substr(3);
      }

      if (val.length > 5) {
        newVal += `${val.substr(0, 3)}${delimiter}`;
        newVal += `${val.substr(3, 2)}${delimiter}`;
        val = val.substr(5);
      }

      newVal += val;
      value = newVal.substring(0, 11);
      return value;
    }

    return '';
  },

  formatOrMaskSSN: function(mask) {
    mask = mask === undefined || mask === null ? true : !!(mask);

    return (ssn) => this.formatSSN(mask ? null : ssn);
  },

  formatZip: function (zip) {
    const regex = new RegExp('[^\\d]', 'g');
    const onlyNumbers = zip.toString().replace(regex, '');
    return onlyNumbers.slice(0, 5);
  },

  formatRoutingNumber: function (routing) {
    return routing?.replace(/[^0-9]/g, '')?.slice(0, 9);
  },

  formatAccountNumber: function (account) {
    return account?.replace(/[^0-9]/g, '')?.slice(0, 17);
  },

  formatTaxRate: function (taxRate) {

    let taxRateString = '';

    if (taxRate && taxRate !== '%') {
      const regex = new RegExp('[^\\d\\.?\\d]');
      taxRateString = String(taxRate).replace(/%/g, '').replace(regex, '') + '%';
    }

    return taxRateString;
  },

  formatNumberToThreeDecimals(value) {
    return numeral(value ?? 0).format('0.000');
  },

  formatPercentageWithoutSymbol: function(percentageValue) {
    let percentageValueString = '';
    if (percentageValue)
      percentageValueString = `${percentageValue.toString().replace(',', '.').replace(/[^\d\.]/g, '').replace(/\./, 'x').replace(/\./g, '').replace(/x/, '.')}`;
    return percentageValueString;
  },

  formatDollarsOrReturnBlank: function (value, format) {

    if (!value) {
      return '';
    }

    return numeral(value).format(format);

  },

  formatDateOrReturnBlank: function (value, format) {

    if (!value) {
      return '';
    }

    return moment(value).format(format);

  },

  valeOrReturnBlank: function (value) {

    if (value !== 0 && !value) {
      return '';
    }

    return value;

  },

  paymentTypeFormatTable: function (value) {
    switch (value) {
      case 'Visa':
      case 'visa':
        return value;
      case 'Mastercard':
      case 'mastercard':
        return 'MC';
      case 'American Express':
        return 'Amex';
      case 'Discover':
        return 'Disc';
      default:
        return value;
    }
  },

  formatTaxRateLevelThree: function (taxRate) {

    let taxRateString = '';

    taxRate = taxRate ? taxRate.replace('%', '') : taxRate;

    if (taxRate && taxRate !== '%') {
      taxRateString = numeral(taxRate).format('0.00');
    }

    taxRateString = taxRateString + '%';

    return taxRateString;
  },

  formatTaxId: function(value) {
    if (value) {
      let val = value.replace(/\D/g, '');
      let newVal = '';

      if (val.length > 2) {
        newVal += `${val.substr(0, 2)}-`;
        val = val.substr(2);
      }

      newVal += val;
      value = newVal.substring(0, 10);
      return value;
    }

    return ''
  },

  formatCashDiscount: function (fixedAmount, percent, totalAmount) {

    totalAmount = numeral(totalAmount).value();

    if (fixedAmount && !percent) {
      return numeral(fixedAmount).format('$0,0.00');
    } else if (!fixedAmount && percent) {
      return this.formatTaxRate(parseFloat(percent).toFixed(3));
    } else if (fixedAmount && percent && totalAmount) {
      const percentCashDiscount = ((numeral(percent).value() / 100) * totalAmount);
      return numeral(fixedAmount).value() >= percentCashDiscount ? numeral(fixedAmount).format('$0,0.00') : this.formatTaxRate(parseFloat(percent).toFixed(3)); //tax rate format also works here
    } else {
      return 0;
    }

  },

  formatCashDiscountPerItem: function (items, quantity, index, cashDiscountAmount) {

    cashDiscountAmount = !cashDiscountAmount ? 0 : cashDiscountAmount;
    const remainder = index === 0 ? parseFloat(parseFloat(parseFloat(cashDiscountAmount * 100) % items) / 100) : 0; // only add remainder to the first item on the list like the apps do it

    return (numeral(parseFloat(cashDiscountAmount)).value() / items) / numeral(quantity).value() + numeral(remainder).value();
  },

  formatYesNo: (value) => {

    switch (value) {
      case 'N':
      case 'n':
      case 'null':
        return 'No';
      case 'Y':
      case 'y':
        return 'Yes';
      default:
        return value;
    }

  },

  formatCoordinates: coordinates => {
    const cardinalDirections = {
      north: t('CardinalDirections.North'),
      east: t('CardinalDirections.East'),
      west: t('CardinalDirections.West'),
      south: t('CardinalDirections.South')
    };
    let lat = coordinates.latitude, lng = coordinates.longitude;
    if (lat === '0.000000' && lng === '0.000000') {
      return 'N/A';
    } else {
      let latdeg, latmin, latsec, lngdeg, lngmin, lngsec, latIndicator,
        longIndicator;
      if (lat >= 0)
        latIndicator = cardinalDirections.north;
      if (lat < 0)
        latIndicator = cardinalDirections.south;
      if (lng >= 0)
        longIndicator = cardinalDirections.east;
      if (lng < 0)
        longIndicator = cardinalDirections.west;
      lat = Math.abs(lat);
      lng = Math.abs(lng);
      latdeg = Math.floor(lat);
      latmin = Math.floor((lat - latdeg) * 60);
      latsec = Math.round((lat - latdeg - latmin / 60) * 1e3 * 3600) / 1e3;
      lngdeg = Math.floor(lng);
      lngmin = Math.floor((lng - lngdeg) * 60);
      lngsec = Math.floor((lng - lngdeg - lngmin / 60) * 1e3 * 3600) / 1e3;

      return (
        `${latdeg}° ${latmin}' ${latsec}'' ${latIndicator},\n
        ${lngdeg}° ${lngmin}' ${lngsec}'' ${longIndicator}`
      );
    }
  },
  checkTipType: (tipType) => {
    if (tipType === null) {
      return false;
    }
    return (!!tipType || tipType === 0);
  },
  formatInvoiceCurrency: (value = 0, amountToSubtract = 0) => {
    return numeral(CommonUtil.roundToTwoDecimals(value - amountToSubtract)).format('$0,0.00')
  },
  formatTextToKey: (text) => {
    return text.replace(/\s/gm, '').replace('-', '');
  },

  formatItemName: (itemName, priceName) => {
    const choppedItem = itemName?.split(' - ');
    const hasBasePriceString = choppedItem?.[1]?.includes(PriceName.BASE_PRICE);
    return (priceName && priceName !== PriceName.BASE_PRICE) ? `${choppedItem[0]} - ${priceName}` : hasBasePriceString ? choppedItem[0] : itemName;
  },

  formatParseFloatCurrencyValues: (value) => {
    if (_.isNil(value)) return '$0.00';
    value = Math.round(value * 100) / 100;
    return numeral(CommonUtil.roundToTwoDecimals(value)).format('$0,0.00');

  },

  formatBusinessPercentage: value => {
    if (!value) {
      return value;
    }

    if (isNaN(value) || value === ' ') {
      return 0;
    }

    return parseInt(value.toString().replace(/[^\d]/g, '').slice(0, 3));
  }
};

export default FormatTextUtils;

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
