/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import { createPaymentLink, getInternalPaymentLinks } from '../../../actions/paymentLinksActions';

import CreatePaymentLinkForm, {PAYMENT_LINK_FORM_ID} from './createPaymentLinkForm';
import BottomBar from '../BottomBar';
import IconUtil from '../../util/IconUtil';
import LabelUtil from '../../util/LabelUtil';
import MessageDialog from '../../shared/MessageDialog';
import UpdateSpinner from '../../UpdateSpinner';
import routes from '../../../constants/routes';
import actionTypes from '../../../constants/actionTypes';
import PreviewPaymentLink from './PreviewPaymentLink';
import HighAmountModal from '../../business/HighAmountModal';
import numeral from 'numeral';
import { highTransactionLimitAmount } from '../../../constants/applicationConstants';
import { setModalVisibility, toggleNorthGatekeeper } from '../../../actions/userExperienceActions';
import IconUtils from '../../util/IconUtil';
import Button from '../Button';
import {Box, Stack} from '@mui/material';
import { createPaymentLinkControlStatusDialogStyles } from '../../../jss/createPaymentLinkControlStyles';
import UserUtil from '../../util/UserUtil';

export default class CreatePaymentLinkControl extends Component {

  constructor(props) {
    super(props);
    this.onClosePreviewDialog = this.onClosePreviewDialog.bind(this);
    this.onCloseStatusDialog = this.onCloseStatusDialog.bind(this);
    this.onCopyUrl = this.onCopyUrl.bind(this);
    this.onCreatePaymentLinkClick = this.onCreatePaymentLinkClick.bind(this);
    this.onPreviewPaymentLinkClick = this.onPreviewPaymentLinkClick.bind(this);
    this.onPreviewPaymentLinkClick = this.onPreviewPaymentLinkClick.bind(this);
    this.submitCreatePaymentLink = this.submitCreatePaymentLink.bind(this);
    this.openHighAmountDialog = this.openHighAmountDialog.bind(this);
    this.closeHighAmountDialog = this.closeHighAmountDialog.bind(this);

    this.state = {
      showPreviewDialog: false,
      showStatusDialog: false,
      createdPaymentLink: null
    }
  }

  onPreviewPaymentLinkClick() {
    this.setState({showPreviewDialog: true});
  }

  onClosePreviewDialog() {
    this.setState({showPreviewDialog: false});
  }

  onCopyUrl() {

    const { t } = this.props;
    const { createdPaymentLink } = this.state;

    const rawUrl = createdPaymentLink?.settings?.url;
    navigator.clipboard.writeText(rawUrl);
    toastr.success('Success', t('PaymentLinks.CopyURLSuccess'));

  }

  onCreatePaymentLinkClick() {
    this.props.dispatch(submit(PAYMENT_LINK_FORM_ID));
  }

  onCloseStatusDialog() {
    const { dispatch, user, onCancelButtonClick } = this.props;
    this.setState({ showStatusDialog: false, createdPaymentLink: null });
    dispatch(reset(PAYMENT_LINK_FORM_ID));

    if(typeof onCancelButtonClick === 'function') {
      dispatch(getInternalPaymentLinks(user));
      onCancelButtonClick();
    }
  }

  getAmountWithTaxCD = (paymentAmount, enableTax, cashDiscounting) => {
    const {cash_discount_enabled, cash_discount_fixed_amount, cash_discount_amount_percent, tax_rate, is_geotax_enabled} = this.props.merchantSettings?.merchantSettings || {};
    let amount = numeral(paymentAmount).value();

    if (enableTax && !is_geotax_enabled) {
      amount = amount + amount * tax_rate / 100;
    }

    if (cash_discount_enabled && cashDiscounting) {
      const cdFlatAmount = numeral(cash_discount_fixed_amount).value();
      const cdPercentAmount = numeral(cash_discount_amount_percent).value() / 100;
      const percentCdApplied = amount * cdPercentAmount;
      const cashDiscount = cdFlatAmount >= percentCdApplied ? cdFlatAmount : percentCdApplied;

      return amount + cashDiscount;
    }

    return amount;
  }

  submitCreatePaymentLink(values) {
    const {user} = this.props;
    if (UserUtil.isNorthDemoAccount(user)) {
      this.props.dispatch(toggleNorthGatekeeper(true));
      return;
    }

    if (this.getAmountWithTaxCD(values?.paymentAmount1, values?.enableTax, values?.cashDiscounting) > highTransactionLimitAmount ||
      (values?.paymentLinkAmountType === 'multiple' &&
        this.getAmountWithTaxCD(values?.paymentAmount2, values?.enableTax, values?.cashDiscounting) > highTransactionLimitAmount ||
        this.getAmountWithTaxCD(values?.paymentAmount3, values?.enableTax, values?.cashDiscounting) > highTransactionLimitAmount
      ) ||
      ((values?.allowCustomAmount || values?.paymentLinkAmountType === 'open') &&
        this.getAmountWithTaxCD(values?.minimumAmount, values?.enableTax, values?.cashDiscounting) > highTransactionLimitAmount ||
        this.getAmountWithTaxCD(values?.maximumAmount, values?.enableTax, values?.cashDiscounting) > highTransactionLimitAmount)
    ) {
      this.openHighAmountDialog();
    } else {
      const {dispatch, user} = this.props;

      dispatch(createPaymentLink(values, user)).then(result => {

        if (result.type === actionTypes.createPaymentLinkSuccess) {
          this.setState({
            showStatusDialog: true,
            createdPaymentLink: result?.response
          });
        }

      });
    }
  }

  closeHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  openHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  render() {
    const { t, showCancelButton, onCancelButtonClick, paymentLinks: { isProcessing }, userExperience, user, auth } = this.props;
    const { showStatusDialog, createdPaymentLink } = this.state;
    const readOnly = !UserUtil.isActive(user) || auth.isSpr;

    const paymentLinksPath = `${globalApplicationLabel.path}${routes.business.root}${routes.business.paymentLinks}`;

    let leftActions = [];

    if (showCancelButton) {
      leftActions.push({
        key: 'cancelCreatePaymentLink',
        label: t('Cancel'),
        onClick: onCancelButtonClick,
        variant: 'text'
      });
    }


    const dialogTitleIcon = IconUtil.getIcon('CheckCircle', LabelUtil.getLabelColor());
    const label = LabelUtil.getLabel();

    const statusDialogContent = (
      <Box sx={createPaymentLinkControlStatusDialogStyles}>

        {dialogTitleIcon}
        <h3 className='contentTitle'>{`${t('PaymentLinks.CreateForm.Success')}`}</h3>

        <Stack direction='column' alignItems='center' justifyContent='space-around'>
          <Button
            variant='outlined'
            href={`${paymentLinksPath}?paymentLinkId=${createdPaymentLink?.id}`}
            className='viewPaymentLink'
            target={'_blank'}
            rel='noopener noreferrer'
            endIcon={IconUtil.getIcon('ActivePaymentLinksIcon', label.primaryColor)}
            label={t('PaymentLinks.CreateForm.ViewDetails')}
            fullWidth
          />

          <Button
            variant='outlined'
            href={createdPaymentLink?.settings?.qrCode}
            target={'_blank'}
            rel='noopener noreferrer'
            endIcon={IconUtils.getIcon('QRCodeIcon', label.primaryColor)}
            label={t('PaymentLinks.Detail.DownloadQR')}
            fullWidth
          />

          <Button
            variant='outlined'
            onClick={this.onCopyUrl}
            endIcon={IconUtil.getIcon('CopyIcon', label.primaryColor)}
            label={t('PaymentLinks.Detail.CopyURL')}
            fullWidth
          />

        </Stack>

      </Box>
    );

    const isHighAmountTransactionDialogOpen = userExperience?.modalVisibility?.highTransactionAmountDialog;

    return (
      <div className='createPaymentLinkControlContainer'>

        <CreatePaymentLinkForm
          {...this.props}
          onSubmit={this.submitCreatePaymentLink}
          className='createPaymentLinkForm'
        />

        <BottomBar
          className='paymentLinksBottomBar'
          leftActions={leftActions}
          rightActions={[
            {
              key: 'previewPaymentLink',
              label: t('PaymentLinks.CreateForm.Preview'),
              icon: IconUtil.getIcon('Visibility'),
              onClick: this.onPreviewPaymentLinkClick,
              variant: 'text'
            },
            {
              key: 'createPaymentLink',
              variant: 'contained',
              label: t('PaymentLinks.CreateForm.CreateLink'),
              icon: IconUtil.getIcon('ActivePaymentLinksIcon'),
              iconPosition: 'right',
              onClick: this.onCreatePaymentLinkClick,
              disabled: readOnly
            }
          ]}
        />

        <MessageDialog
          externalClassName={'createPaymentLinkStatusDialog'}
          titleText={''}
          bodyText={statusDialogContent}
          confirmText={t('Done')}
          isChoiceRequired={false}
          open={showStatusDialog}
          onConfirm={this.onCloseStatusDialog}
          onRequestClose={this.onCloseStatusDialog}
          hideCloseIcon={true}
        />

        { isProcessing && <UpdateSpinner /> }

        <HighAmountModal
          onClose={this.closeHighAmountDialog}
          open={isHighAmountTransactionDialogOpen}
          t={t}
        />

        <PreviewPaymentLink open={this.state.showPreviewDialog} handleClose={this.onClosePreviewDialog} {...this.props}/>
      </div>
    );
  }

}
