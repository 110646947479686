/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';

import { submit } from 'redux-form';

import {
  addOwnerContactApi,
  deleteOwnerByContactId,
  getAccountProfile,
  updateOwnerContactApi
} from '../../actions/accountProfileActions';
import { getMerchantStatements } from '../../actions/statementsActions';
import {
  setModalVisibility,
  setSubmitButtonEnabled,
  suppressAppError
} from '../../actions/userExperienceActions';
import { saveBusinessProfileNoBanking } from '../../actions/userActions';
import { profileChange } from '../../actions/verificationActions';
import { getMerchantSettings } from '../../actions/merchantSettingsActions';

import actionTypes from '../../constants/actionTypes';

import LabelUtil from '../util/LabelUtil';
import UserUtil from '../util/UserUtil';
import IconUtils from '../util/IconUtil';

import Spinner from '../UpdateSpinner';

import BusinessForm from './BusinessForm';
import OwnerForm from './owners/OwnerForm';
import FilterPanel from '../shared/FilterPanel';

import Button from '../shared/Button';
import Modal from '../shared/Modal';
import MessageDialog from '../shared/MessageDialog';
import TwoFactorTools from '../dialogs/account/TwoFactorTools';

import messages from '../../constants/messages';
import twoFactorTypes from '../../constants/twoFactorTypes';
import Page from '../shared/Page';

const labelColor = LabelUtil.getLabelColor();

export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleteOwnerDialog: false,
      verifiedDialog: false,
      loading: false,
      openUploadError: false,
      contactId: '',
      ownerDialog: false,
      selectedOwner: {},
      ownerErrorDialog: false
    };

    this.loadData = this.loadData.bind(this);
    this.handleSaveBusinessProfile = this.handleSaveBusinessProfile.bind(this);
    this.onSaveBusinessClick = this.onSaveBusinessClick.bind(this);
    this.displayDeleteOwnerDialog = this.displayDeleteOwnerDialog.bind(this);
    this.handleDeleteOwnerDialogClose = this.handleDeleteOwnerDialogClose.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.displayOwnerDialog = this.displayOwnerDialog.bind(this);
    this.handleAddOwnerDialogClose = this.handleAddOwnerDialogClose.bind(this);
    this.handleAddOwner = this.handleAddOwner.bind(this);
    this.onAddOwner = this.onAddOwner.bind(this);
    this.handleErrorOwnerDialogClose = this.handleErrorOwnerDialogClose.bind(this);
    this.closeVerifiedDialog = this.closeVerifiedDialog.bind(this);

    this.openTwoFactorDialog = this.openTwoFactorDialog.bind(this);
    this.on2faProcessing = this.on2faProcessing.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  componentDidMount() {
    const { user, dispatch, merchantSettings } = this.props;
    if (!merchantSettings.merchantSettings) {
      dispatch(getMerchantSettings(user));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(suppressAppError(false));
  }

  loadData(props) {
    props.dispatch(setSubmitButtonEnabled(false));
    props.dispatch(getAccountProfile(props.user));
    props.dispatch(getMerchantStatements(props.user));
  }

  handleSaveBusinessProfile(values) {

    const that = this;

    this.setState({loading: true});
    this.props.dispatch(saveBusinessProfileNoBanking(this.props.user, values)).then((res) => {
      that.props.dispatch(profileChange());
      that.setState({loading: false, verifiedDialog: true });
    });
  }

  onSaveBusinessClick() {
    this.props.dispatch(submit('businessForm'));
  }

  displayDeleteOwnerDialog(contactId) {
    this.setState({deleteOwnerDialog: true, contactId});
  }

  handleDeleteOwnerDialogClose() {
    this.setState({deleteOwnerDialog: false});
  }

  handleErrorOwnerDialogClose() {
    this.setState({ownerErrorDialog: false});
  }

  deleteOwner() {
    let that = this;
    this.setState({loading: true});
    this.props.dispatch(deleteOwnerByContactId(this.props.user, this.state.contactId)).then(res => {
      if (res.type === actionTypes.deleteOwnerByContactIdSuccess) {
        that.setState({loading: false, deleteOwnerDialog: false});
        that.loadData(that.props);
      }
    });
  }

  displayOwnerDialog(selectedOwner) {
    this.setState({ownerDialog: true, selectedOwner});
  }

  closeVerifiedDialog() {
    this.setState({verifiedDialog: false});
    this.loadData(this.props);
  }

  handleAddOwnerDialogClose() {
    this.setState({ownerDialog: false, selectedOwner: {}});
  }

  handleAddOwner(values) {
    let that = this;
    this.setState({loading: true});
    this.props.dispatch(suppressAppError(true));

    if (this.state.selectedOwner.contact_id) {
      values.contactId = this.state.selectedOwner.contact_id;
      this.props.dispatch(updateOwnerContactApi(this.props.user, values)).then((res) => {
        if (res.type === actionTypes.updateOwnerContactApiSuccess) {
          that.setState({
            loading: false,
            ownerDialog: false,
            selectedOwner: {}
          });
          that.loadData(that.props);
        } else if (res.type === actionTypes.updateOwnerContactApiFailure) {
          that.setState({
            loading: false,
            ownerDialog: false,
            selectedOwner: {},
            ownerErrorDialog: true
          });
        }
      });
    } else {
      this.props.dispatch(addOwnerContactApi(this.props.user, values)).then((res) => {
        if (res.type === actionTypes.addOwnerContactApiSuccess) {
          that.setState({
            loading: false,
            ownerDialog: false,
            selectedOwner: {}
          });
          that.loadData(that.props);
        } else if (res.type === actionTypes.addOwnerContactApiFailure) {
          that.props.dispatch(suppressAppError(true));
          that.setState({
            loading: false,
            ownerDialog: false,
            selectedOwner: {},
            ownerErrorDialog: true
          });
        }
      });
    }
  }

  onAddOwner() {
    this.props.dispatch(submit('ownerForm'));
  }

  openTwoFactorDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('twoFactorDialog'));
  }

  on2faProcessing(state) {
    this.setState({ loading: state });
  }

  render() {

    const {accountProfile, statements, verification, userExperience, auth, t, user}  = this.props;

    const isEmpty = accountProfile?.data === null && statements.data === null;
    const isActiveMerchantAccount = UserUtil.isActive(user);

    const label = LabelUtil.getLabel();

    const submitButtonDisabled = !userExperience.enableFormSubmitButton;

    const isHumboltUser = UserUtil.isHumbolt(user);
    const isDemoAccount = UserUtil.isDemoAccount(user);

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
      />
    );

    const profileEditSaveButton = !verification.twoFactorAuthenticationVerified[twoFactorTypes.businessProfile] && auth.isOwner &&
      <span onClick={this.openTwoFactorDialog}>
        {IconUtils.getIcon('EditIcon', labelColor, 15)}
        {t('EditBusinessProfile')}
      </span>;

    const deleteOwnerDialog =
      <Modal
        title={t('DeleteOwnerDialog.Title')}
        confirmText={t('DeleteOwnerDialog.ConfirmText')}
        cancelText={t('Close').toUpperCase()}
        open={this.state.deleteOwnerDialog}
        onClose={this.handleDeleteOwnerDialogClose}
        onConfirm={this.deleteOwner}
      >
        <p>{t('DeleteOwnerDialog.DeleteOwnerMessage')}</p>
      </Modal>;

    const addOwnerDialog = (
      <Modal
        title={t('AddOwnerDialog.Title')}
        confirmText={t('Continue').toUpperCase()}
        cancelText={t('Close').toUpperCase()}
        open={this.state.ownerDialog}
        onClose={this.handleAddOwnerDialogClose}
        onConfirm={this.onAddOwner}
      >
        <OwnerForm
          onSubmit={this.handleAddOwner}
          selectedOwner={this.state.selectedOwner}
          t={t}
        />
      </Modal>
    );

    const verifiedDialog = (
      <MessageDialog
        cancelText={t('Close')}
        hideCloseIcon={true}
        hideConfirmButton={true}
        isChoiceRequired={false}
        confirmText={''}
        onRequestClose={this.closeVerifiedDialog}
        open={this.state.verifiedDialog}
        bodyText={
          <p className='membershipDialogContent'>
            {t('VerifiedDialog.Text')}
          </p>
        } />
    );

    const ownerDialogError = (
      <Modal
        title={t('OwnerDialogError.Title')}
        open={this.state.ownerErrorDialog}
        onClose={this.handleErrorOwnerDialogClose}
        hideConfirmButton
      >
        <div>
          <span>{t('OwnerDialogError.OwnerDialogErrorMessage', { phoneNumber: label.phoneNumber })}</span>
        </div>
      </Modal>);


    return (
      <div className='businessProfile pageWrap'>
        <Page
          loading={isEmpty}
          title={t('Business Settings')}
          initialSubpage={t('Business Profile')}
        >
          <>
            <div className='flexContainer flexContainerResponsiveLayout'>
              {filterPanel}
              <div
                className='businessSettingsSubsection businessProfileContainer businessFormHolder'>
                <div className='businessForm'>
                  <div className='title'>
                    <span className='heading'>
                      {t(messages.bizSettings.formTitle)}
                    </span>
                    <span className='subText'>
                      {t(messages.bizSettings.formSubtitle)}
                    </span>
                  </div>
                  {(isActiveMerchantAccount && !isHumboltUser && !isDemoAccount) &&
                    <span className='editButton' id='editProfileButton'>
                    {profileEditSaveButton}
                    </span>
                  }
                  <BusinessForm
                    {...this.props}
                    canEdit={!verification.twoFactorAuthenticationVerified[twoFactorTypes.businessProfile] || !isDemoAccount}
                    onSubmit={this.handleSaveBusinessProfile}
                    displayDeleteOwnerDialog={this.displayDeleteOwnerDialog}
                    displayOwnerDialog={this.displayOwnerDialog}
                    submitButtonDisabled={submitButtonDisabled}
                  />
                  {verification.twoFactorAuthenticationVerified[twoFactorTypes.businessProfile] &&
                    <div className='detailPanelHolderButtons saveContainer'>
                      <Button
                        label={t('SaveSettingsBtn')}
                        disabled={!verification.twoFactorAuthenticationVerified[twoFactorTypes.businessProfile] || submitButtonDisabled}
                        onClick={this.onSaveBusinessClick}
                      />
                    </div>}
                </div>
              </div>
            </div>
            {deleteOwnerDialog}
            {addOwnerDialog}
            {verifiedDialog}
            {ownerDialogError}
            <TwoFactorTools
              type={twoFactorTypes.businessProfile}
              onProcessing={this.on2faProcessing}
              {...this.props}
            />
            {this.state.loading && <Spinner/>}
          </>
        </Page>
      </div>
    );
  }
}
