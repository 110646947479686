/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import {
  getTwoFactorToken,
  loginFormError,
  loginUser,
  logoutUser,
  loginUserWithToken,
  loginUserWithExpiredPassword,
  expireActionRequiredToken,
  loginSetup2FA,
  sendNotification
} from '../actions/authActions';
import TextField from '@mui/material/TextField';
import Button from './shared/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Loading from './Loading';
import routes from './../constants/routes';
import PropTypes from 'prop-types';
import ContactFooter from './ContactFooter';
import PhoneSwipeEndOfLife from './PhoneSwipeEndOfLife';
import LabelUtil from './util/LabelUtil';
import UserUtil from './util/UserUtil';
import RightArrowIcon from './icons/RightArrowIcon';
import twoFactorCodeUtil from './util/twoFactorCodeStatusUtil';
import IconUtils from './util/IconUtil';
import { Trans } from 'react-i18next';
import LanguageSelector from './shared/LanguageSelector';
import ForceResetPasswordForm from './ForceResetPasswordForm';
import actionTypes from '../constants/actionTypes';
import MFASetupForm from './MFASetupForm';
import Validator from './util/Validator';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {isNorth as isNorthLabel} from './util/WhiteLabelUtil';
import North from './whitelabel/North';
import PaymentsHub from './whitelabel/PaymentsHub';
import ArrowRight from './icons/RightArrowIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  forgotPasswordContainerStyles,
  loginAsideStyles,
  loginButtonStyles,
  loginContainerStyles,
  loginInputLabelStyles,
  loginStyles,
  northDeveloperButtonStyles,
  northLoginButtonStyles,
  northTextFieldStyles,
  phForgorPasswordContainerStyles,
  textFieldStyles,
  welcomeStyles,
  requestTokenStyles,
} from '../jss/loginStyles';
import { IconButton } from '@mui/material';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEnterPressed = this.handleEnterPressed.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleVerifyClick = this.handleVerifyClick.bind(this);
    this.MFASetupRequest = this.MFASetupRequest.bind(this);
    this.MFASetupVerify = this.MFASetupVerify.bind(this);
    this.callLogout = this.callLogout.bind(this);
    this.requestToken = this.requestToken.bind(this);
    this.detectIE = this.detectIE.bind(this);
    this.detectFF = this.detectFF.bind(this);
    this.verifyTokenButtonAction = this.verifyTokenButtonAction.bind(this);
    this.getVisibleSections = this.getVisibleSections.bind(this);
    this.usernameInput = createRef();
    this.passwordInput = createRef();
    this.twoFactorTokenInput = createRef();
    this.rememberThisDeviceInput = createRef();
    this.loginValidation = this.loginValidation.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.state = {
      showPassword: false,
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('keydown', this.handleEnterPressed);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnterPressed);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.twoFactorEnabled) {
      this.setState({twofactortoken: ''});
    }
    if (this.props.auth.twoFactorEnabled && !nextProps.auth.twoFactorEnabled && this.twoFactorTokenInput?.current?.value) {
      this.twoFactorTokenInput.current.value = '';
    }
  }

  requestToken() {
    const { user, dispatch, t } = this.props;

    if (user.data && user.data.user_id) {
      return dispatch(getTwoFactorToken(user, true)).then(result => {
        if (result.type === actionTypes.twoFactorSendTokenFailure && result.error === t('Errors.TwoFactor.RateLimit')) {
          dispatch(logoutUser());
        }
        return result;
      });
    }
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleEnterPressed(event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      const { showTwoFactorForm, showMainLogin } = this.getVisibleSections();

      if (showTwoFactorForm) {
        this.handleVerifyClick();
      }  else if (showMainLogin) {
        this.handleClick();
      }

      event.preventDefault();
    }
  }

  onSubmit(event) {
    event.preventDefault();
  }

  handleClick() {
    // This is required to prompt to save password in IE/Edge & (Firefox when loggin in through Enter key)
    if (this.detectIE() || this.detectFF()) {
      document.getElementById('hiddenSubmit').click();
    }

    if (!this.props.auth.isFetching) {

      const username = this.usernameInput.current?.value;
      const password = this.passwordInput.current?.value;

      const credentials = {
        username: username?.trim(),
        password
      };

      const { isValidInput, errorMessage } = this.loginValidation(credentials);

      if (isValidInput) {
        this.props.dispatch(loginUser(credentials, this.props.entryType, this.props.qr));
      } else {
        this.props.dispatch(loginFormError(errorMessage));
      }
    }
  }

  loginValidation(credentials) {
    let errorMessage = '';
    const {username, password} = credentials;
    const isValidUsernameInput = Validator.validateLoginEmail(username);

    if (!isValidUsernameInput) {
      errorMessage = 'Server.Error.InvalidCharacters';
    }

    if (!username) {
      errorMessage = 'Server.Error.NoUsername'
    }

    if (!password) {
      errorMessage = 'Server.Error.NoCredentials';
    }

    return {
      isValidInput: !errorMessage,
      errorMessage
    };
  };

  handleVerifyClick() {
    if (!this.props.auth.isFetching) {
      const username = this.usernameInput.current.value;
      const password = this.passwordInput.current.value;
      const token = this.twoFactorTokenInput.current.value;
      const rememberThisDevice = this.rememberThisDeviceInput.current.checked;
      const credentials = {
        username: username.trim(),
        password: password.trim(),
        userId: this.props.user.data.user_id,
        merchantId: this.props.user.selectedMerchantAccount,
        token: token.trim(),
        rememberThisDevice
      };
      if (credentials.token) {
        this.props.dispatch(loginUserWithToken(credentials, this.props.entryType, this.props.qr));
      } else {
        this.props.dispatch(loginFormError('Please enter a token'));
      }
    }
  }

  detectFF() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  verifyTokenButtonAction() {
    const { auth } = this.props;
    return !auth.isFetching || !twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus) ? this.handleVerifyClick() : null;
  }

  forceResetPasswordLogin = async (values) => {
    if (!this.props.auth.isFetching) {
      const password = values.currentPassword;
      const newPassword = values.newPassword;
      const token = this.twoFactorTokenInput?.current?.value || '';
      const credentials = {
        password: password.trim(),
        newPassword: newPassword.trim(),
        token: token.trim()
      };

      const response = await this.props.dispatch(loginUserWithExpiredPassword(credentials, this.props.entryType, this.props.qr));

      if (response?.type === actionTypes.forceResetPasswordLoginSuccess && !response.entryType) {
        await this.callLogout(true);
      } else if (response?.type === actionTypes.forceResetPasswordLoginError && response.payload.message === 'Server.Error.Unauthorized') {
        await this.callLogout(false);
      } else if (response?.type === actionTypes.forceResetPasswordLoginError && response.payload.message === 'Cannot reuse the last 4 passwords.') {
        this.props.dispatch(loginFormError(response.payload.message));
      }
    }

  }

  onGoBackClick = () => {
    this.props.dispatch(logoutUser());
  };

   MFASetupRequest(values) {
    const { auth, dispatch } = this.props;

    if (!auth.isFetching) {

      const setup2FAValues = {
        ...values,
        token: '',
        willRequestCode: true
      };

      return dispatch(loginSetup2FA(setup2FAValues)).then((response) => {
        if (response.type === actionTypes.setup2faLoginError) {
          dispatch(loginFormError(response?.payload?.message));
        }
      });
    }
  };

  MFASetupVerify(values) {
    const { auth, dispatch, t } = this.props;

    if (!auth.isFetching) {

      const setup2FAValues = {
        ...values,
        willRequestCode: false
      };

      return dispatch(loginSetup2FA(setup2FAValues)).then(async (response) => {
        const payload = response?.payload;

        if (response.type === actionTypes.setup2faLoginError) {
          dispatch(loginFormError(payload?.message));
        } else if (response.type === actionTypes.setup2faLoginSuccess && payload?.status === 'approved'){
          dispatch(sendNotification('', 'Success', t('success2FaSetupMessage')));
          await this.callLogout(true);
        }

      });
    }
  };

  async callLogout(expireToken) {
    if (expireToken) {
      await this.props.dispatch(expireActionRequiredToken());
    }

    this.props.dispatch(logoutUser());
  };

  getVisibleSections() {
    const { auth, user } = this.props;

    const isOwnerOrAdmin = UserUtil.isOwnerOrAdminOfAny(user);

    const showTwoFactorSection = (auth.twoFactorEnabled || twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus)) &&
      !_.isEmpty(user?.data) && !auth.twoFactorSetupEnabled;
    const isMFASetupRequired = !showTwoFactorSection && !_.isEmpty(user?.data) && !user.data.is_ecp && user.data.force_two_factor;
    const showTwoFactorBlock = !showTwoFactorSection && !isOwnerOrAdmin && isMFASetupRequired;
    const showTwoFactorForm = showTwoFactorSection && !showTwoFactorBlock && !isMFASetupRequired;
    const showTwoFactorSetup = !showTwoFactorSection && !showTwoFactorBlock && isOwnerOrAdmin && isMFASetupRequired;

    const showForceResetPassword = !!auth.forceResetPassword;
    const showTwoFactor = showTwoFactorSection && !showForceResetPassword;
    const showMainLogin = !showTwoFactorSection && !showForceResetPassword && !showTwoFactor && !showTwoFactorSetup;

    return {
      showTwoFactorBlock,
      showTwoFactorForm,
      showTwoFactorSetup,
      showForceResetPassword,
      showMainLogin
    }
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }


  render() {

    let {errorMessage, auth, t, user} = this.props;
    errorMessage = errorMessage && errorMessage.includes('html') ? t('LoginError') : t(errorMessage);
    const isNhLabel = isNorthLabel();
    const appRoutePrefix = globalApplicationLabel.path;

    const label = LabelUtil.getLabel();
    const verifyTwoFactorButtonDisabled = twoFactorCodeUtil.isSendingCode(auth.twoFactorCodeStatus) || twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus) || twoFactorCodeUtil.codeError(auth.twoFactorCodeStatus);

    if (label.abbreviation === 'ps') {

      return (
        <PhoneSwipeEndOfLife t={t} />
      );

    } else {
      const isPaLabel = label.abbreviation === 'pa';
      const isPhLabel = label.abbreviation === 'ph';
      const loadingIndicator = (auth.isFetching === true || user.isUpdating) && (
        <Loading/>
      );

      const {
        showTwoFactorBlock,
        showTwoFactorForm,
        showTwoFactorSetup,
        showForceResetPassword,
        showMainLogin
      } = this.getVisibleSections();

      const showUsernamePasswordClass = !showMainLogin ? 'hideMe' : 'detailsHeaderInputField';

      const paymentsHubPartnerButton = (
        agentPortal ? <a className='paymentsHubDevelopersButton partnerHub' href={agentPortal} target={'_blank'}>
          <div className='iconText'>
            {isPhLabel ? <img
              style={{ width: '35px', height: '39px' }}
              src='/images/paymenthubs/login/partnerHub.svg' /> : null}
            <p>{t('GoToPartnerHub')}</p>
          </div>
          <ChevronRightRoundedIcon />
        </a> : null
      );

      const northPartnersButton = (
        agentPortal ? (
          <Button
            variant='outlined'
            className='partnerHub'
            href={agentPortal}
            target={'_blank'}
            sx={northDeveloperButtonStyles}
            endIcon={<ChevronRightIcon color={label.primaryColor} />}
            label={t('NorthPartners')}
            fullWidth
            isSpaceBetween
          />
        ) : null
      );

      const paymentsHubDevelopersButton = (
        <a className='paymentsHubDevelopersButton developersHub' href={phDevelopersUrl} target={'_blank'}>
          <div className='iconText'>
            {isPhLabel ? <img
              style={{ width: '37px', height: '30px' }}
              src='/images/paymenthubs/login/developerHub.svg' /> : null}
            <p>{t('GoToDeveloperHub')}</p>
          </div>
          <ChevronRightRoundedIcon />
        </a>
      );

      const northDevelopersButton = (
        <Button
          variant='outlined'
          className='northDevelopersHub'
          href={phDevelopersUrl}
          target={'_blank'}
          sx={northDeveloperButtonStyles}
          endIcon={<ChevronRightIcon color={label.primaryColor} />}
          label={t('NorthDeveloper')}
          fullWidth
          isSpaceBetween
        />
      );

      const payAnywhereLoginButton = (
        <Button
          className='loginButton enableHover'
          fullWidth
          id='loginButton'
          onClick={this.handleClick}
          onKeyDown={this.handleEnterPressed}
          endIcon={IconUtils.getIcon('ArrowRight')}
          label={t('LogIn')}
        />);

      const paymentHubsLoginButton = (
        <Button
          className='paymentsHubLoginButton enableHover'
          id='loginButton'
          onClick={this.handleClick}
          onKeyDown={this.handleEnterPressed}
          label={t('LogIn')}
          endIcon={isNhLabel ? <ChevronRightIcon color='white' /> : <SvgIcon><ArrowRight viewBox='-5 -6 44 44' /></SvgIcon>}
          sx={isNhLabel ? northLoginButtonStyles : loginButtonStyles}
          fullWidth={isNhLabel}
          isSpaceBetween={isNhLabel}
        />
      );

      const errorMessageComponent = errorMessage && (
        <p className='loginErrorMessage' style={{color: 'red'}}>{t(errorMessage)}</p>
      );

      const MFAForm = (
        <>
          <div className='disclaimerBox'>
                <span className='icon'>
                  <img src='/images/shieldProtect.svg' alt='protect'/>
                </span>
            <div className='content'>
              <h4>{t('MFAFormDisclaimer.Title')}</h4>
              <p>{t('MFAFormDisclaimer.Description')}</p>
            </div>
          </div>

          <p className='twoFactorMessage'>
            <span>{IconUtils.getIcon('MobileFriendlyIcon')}</span>
            {auth?.twoFactorMessage ? `${t('TwoFactorMessageWithNumber')} ${auth?.twoFactorMessage}.` : `${t('TwoFactorMessage')}`}
          </p>

          {errorMessageComponent}

          <h4>{t('VerificationCodeLogin')}</h4>

          <TextField
            placeholder={t('EnterCode')}
            inputRef={this.twoFactorTokenInput}
            fullWidth
            className='tokenField'
            disabled={auth.isFetching || twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus)}
            id='twofactortoken'
            name='twofactortoken'
            inputProps={{ tabIndex: 1 }}
            type='text'
            variant={isPaLabel ? 'standard' : 'outlined'}
            autoComplete='off'
          />

          {auth.twoFactorEnabled && !auth.twoFactorSetupEnabled && (
            <Typography sx={requestTokenStyles} variant='span' onClick={this.requestToken}>
              {t('ResendCode')}
            </Typography>
          )}

          <div className='rememberDeviceField'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id='rememberThisDevice'
                    disabled={auth.isFetching || twoFactorCodeUtil.isValidatingCode(auth.twoFactorCodeStatus)}
                    inputProps={{ tabIndex: 2 }}
                    inputRef={this.rememberThisDeviceInput}
                    name='rememberThisDevice'
                    sx={{ alignSelf: 'self-start' }}
                  />
                }
                label={
                  <div className='content'>
                    <span>{t('RememberDevice')}</span><br/>
                    <p>{t('RememberDeviceDescription')}</p>
                  </div>
                } />
            </FormGroup>

          </div>

          <Button
            className={`${!isPaLabel ? 'paymentsHub2FAButton' : ''} enableHover`}
            disabled={verifyTwoFactorButtonDisabled}
            fullWidth
            onClick={this.verifyTokenButtonAction}
            {...(isPaLabel ? {
              label: auth.twoFactorCodeStatus
            } : {
              label: t('VerifyCode'),
              endIcon: isNhLabel ? IconUtils.getIcon('ChevronRightIcon') : IconUtils.getIcon('ArrowRight')
            })}
          />
        </>
      );

      const twoFactorBlockComponent = (
        <div className='MFABlock'>
          <div className='disclaimerBox'>
            <span className='icon'>
              <img src='/images/shieldProtect.svg' alt='protect'/>
            </span>
            <div className='content'>
              <h4>{t('MFABlockDisclaimer.Title')}</h4>
              <p>{t('MFABlockDisclaimer.Description')}</p>
            </div>
          </div>

          <Button
            onClick={this.onGoBackClick}
            label={t('GoBackAction')}
            variant='text'
          />
        </div>
      );

      const twoFactorSetupComponent = (
        <MFASetupForm
          onSubmit={this.MFASetupVerify}
          onRequestToken={this.MFASetupRequest}
          errorMessageComponent={errorMessageComponent}
          {...this.props}
        />
      );

      const ForceResetPasswordFormComponent = (
        <ForceResetPasswordForm
          onSubmit={this.forceResetPasswordLogin}
          errorMessageComponent={errorMessageComponent}
          {...this.props}
        />
      );

      let loginComponent =
        showTwoFactorBlock ? twoFactorBlockComponent :
          showTwoFactorSetup ? twoFactorSetupComponent :
            showForceResetPassword ? ForceResetPasswordFormComponent : (
        <form className={`${isPaLabel ? 'payanywhereForm' : ''} loginForm`} onSubmit={this.onSubmit}>
          {showTwoFactorForm && MFAForm}
          {!isPaLabel && !isNhLabel && showMainLogin && <h4>{t('MerchantLogin')}</h4>}
          { showMainLogin ? <North><Box sx={loginInputLabelStyles}><label>{t('EmailAddress')}</label></Box></North> : null }
          <TextField
            placeholder={isNhLabel ? t('EmailAddressPlaceholder') : t('EmailAddress')}
            inputRef={this.usernameInput}
            fullWidth
            className={`usernameField ${showUsernamePasswordClass}`}
            disabled={auth.isFetching || auth.twoFactorEnabled}
            id='emailAddress'
            inputProps={{ tabIndex: 1 }}
            type='text'
            name='username'
            autoComplete='username'
            variant={isPaLabel ? 'standard' : 'outlined'}
            sx={isNhLabel ? northTextFieldStyles : textFieldStyles}
          />
          { showMainLogin ? <North><Box sx={loginInputLabelStyles}><label>{t('Password')}</label></Box></North> : null }
          <TextField
            placeholder={isNhLabel ? t('PasswordPlaceholder') : t('Password')}
            inputRef={this.passwordInput}
            fullWidth
            className={showUsernamePasswordClass}
            id='password'
            type={this.state.showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='current-password'
            inputProps={{ tabIndex: 2 }}
            InputProps={!isPaLabel ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password'
                    onClick={this.toggleShowPassword}
                    edge='end'
                  >
                    { this.state.showPassword ? IconUtils.getIcon('Visibility') : IconUtils.getIcon('VisibilityOff')}
                  </IconButton>
                </InputAdornment>
              )
            }: null}
            disabled={auth.isFetching || auth.twoFactorEnabled}
            variant={isPaLabel ? 'standard' : 'outlined'}
            sx={isNhLabel ? northTextFieldStyles : textFieldStyles}
          />

          <input id='hiddenSubmit' className='hiddenSubmit' type='submit'/>

          {showMainLogin && !showTwoFactorForm && errorMessageComponent}

          {(showMainLogin && !isPaLabel) ? (
            <>
              {paymentHubsLoginButton}
              <Box
                className='forgotPasswordContainer'
                sx={isNhLabel ? forgotPasswordContainerStyles : phForgorPasswordContainerStyles}
              >
                <PaymentsHub><p>{t('NoPassword')}</p></PaymentsHub>
                <Link to={appRoutePrefix + routes.forgotPassword} className={isNhLabel ? 'createResetPassword' : ''}>
                  {t('CreateResetPassword')}
                </Link>
              </Box>
              <North>
                {northDevelopersButton}
                {northPartnersButton}
              </North>
              <PaymentsHub>
                <div className='separator' />
                {paymentsHubPartnerButton}
                {paymentsHubDevelopersButton}
              </PaymentsHub>
            </>
          ) : null}


          {(showMainLogin && isPaLabel) ? (
            <>
              {payAnywhereLoginButton}
              <div className='forgotPasswordContainer'>
                <Link to={appRoutePrefix + routes.forgotPassword}>
                  <span>{t('ForgotPassword')}</span>
                  <span className='arrowIcon'><RightArrowIcon color='labelColor'/></span>
                </Link>
              </div>
            </>
          ) : null}
        </form>
      );

      const brandLogo =  (<img src={label.brandLogo} className={isPaLabel ? 'logo' : ''} alt={label.brand}/>)

      const contactFooter = (<ContactFooter {...this.props} />);

      const payAnywhereLogin = (
        <div className='login payAnywhereLogin'>
          <div className='formContainer'>
            {brandLogo}
            <div className='loginContainer'>
              {loadingIndicator}
              {loginComponent}
              {contactFooter}
            </div>
          </div>
          <div className='imgContainer'>
            <h2 className='heading'>
              <Trans i18nKey='ImageText' >
                {t('ImageText')}
              </Trans>
            </h2>
          </div>
        </div>
      );

      const paymentHubsLogin = (
        <Box className='login' sx={loginStyles}>
          <div className='paymentHubsLogin'>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={isNhLabel ? 6 : 12}>
                <Box className='loginContainer' sx={isNhLabel ? loginContainerStyles : {}}>
                  {brandLogo}
                  <North><Typography variant='h4' sx={welcomeStyles}>{t('WelcomeBack')}</Typography></North>
                  {loadingIndicator}
                  {loginComponent}
                  {contactFooter}
                </Box>
              </Grid>
              <North>
                <Grid item lg={6} className='loginAside'>
                  <Box sx={loginAsideStyles} />
                </Grid>
              </North>
            </Grid>

          </div>
          <LanguageSelector  {...this.props} />
        </Box>
      );

      return (isPaLabel  ? payAnywhereLogin : paymentHubsLogin)
    }
  }
}

Login.propTypes = {
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};
