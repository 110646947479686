/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {
  getDeposits,
  getDepositTransactionData,
  setSelectedDepositDetail
} from '../../actions/depositsActions';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import numeral from 'numeral';
import moment from 'moment';
import MasterDetailLayout from '../MasterDetailLayout';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import DateUtils from './../util/DateUtil';
import IconUtil from '../util/IconUtil';
import NoData from '../NoData';
import CsvExporter from './../util/CsvExporter';
import LabelUtil from '../util/LabelUtil';
import CsvUtil from './../util/CsvUtil';
import UserUtil from '../util/UserUtil';
import ReportUtil from './../util/ReportUtil';
import SettingsUtil from '../util/SettingsUtil';

import DepositDetail from './DepositDetail';
import _ from 'lodash';
import FilterPanel from '../shared/FilterPanel';
import {
  generateTransactionCsv,
  setLoaderPercentage,
  setVisibilityFilter
} from '../../actions/userExperienceActions';
import TableHeader from '../shared/TableHeader';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import DepositsSummaryHeader from './DepositsSummaryHeader';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import SecondaryLoader from '../SecondaryLoader';
import Page from '../shared/Page';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';

const defaultDateSelectionPeriod = 'Today';

export default class Deposits extends Component {

  constructor(props) {
    super(props);

    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.setSelectedDeposit = this.setSelectedDeposit.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleDetailExport = this.handleDetailExport.bind(this);
    this.handleDetailPrint = this.handleDetailPrint.bind(this);
    this.handleExportDepositsClick = this.handleExportDepositsClick.bind(this);
    this.handleExportDepositTransactions = this.handleExportDepositTransactions.bind(this);

    const dateRange = props.userExperience.selectedDate.dateRange ? props.userExperience.selectedDate.dateRange :
      DateUtils.getDateRange(defaultDateSelectionPeriod);

    this.state = {
      selectedIndex: 0,
      constructingCsv: false,
      dateRange: dateRange,
      forceOpenDetail: false
    };

  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  componentDidUpdate() {

    const { visibilityFilter, deposits, dispatch } = this.props;

    if((visibilityFilter.filter === null && deposits.data) ||
      (deposits.data && visibilityFilter.filter !== null && visibilityFilter.filter.value?.includes('All ('))) {
      dispatch(setVisibilityFilter({
        property: 'type',
        value: `All (${deposits.data.length})`
      }, 'filter'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    /* istanbul ignore else */
    if (this.props.merchantSettings.customReportStartTime !== nextProps.merchantSettings.customReportStartTime) {
      const dateRange = this.props.userExperience.selectedDate.dateRange ? this.props.userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, nextProps.merchantSettings.customReportStartTime);
      this.setState({dateRange: dateRange});
    }

  }

  loadData(props) {
    const { dispatch, user, userExperience, merchantSettings } = props;

    SettingsUtil.getMerchantSettings(props).then(() => {
      const dateRange = userExperience.selectedDate.dateRange
        ? userExperience.selectedDate.dateRange
        : DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, merchantSettings.customReportStartTime);
      dispatch(getDeposits(user, dateRange)).then(({response}) => {
        if (props.location.state?.shouldDownloadMostRecentDeposit && response?.length > 0) {
          this.showDetailForRow(1);
          this.setState({forceOpenDetail: true});
          this.handleDetailExport();
        }
      });
    });

  }

  setSelectedDeposit(depositDetail) {
    this.props.dispatch(setSelectedDepositDetail(depositDetail))
  }

  showDetailForRow(row) {
    if (this.props.deposits?.filteredData?.length) {
      let selectedIndex = row === 0 ? 0 : row - 1;
      this.setState({selectedIndex: selectedIndex});
      this.setSelectedDeposit(this.props.deposits.filteredData[selectedIndex]);
    }
  }

  handleDateSelection(value, dateRange) {

    this.setState({dateRange: dateRange});
    this.props.dispatch(getDeposits(this.props.user, dateRange))
  }

  async handleDetailExport() {
    const labelName = LabelUtil.getLabel().text;
    const { dispatch, user, userExperience } = this.props;

    let { deposits } = this.props;

    /* istanbul ignore else */
    if (!deposits?.selectedDeposit?.uniq_id) return;

    await dispatch(getDepositTransactionData(user, deposits.selectedDeposit.uniq_id, userExperience?.allAccounts));

    deposits = this.props.deposits;

    const selectedDepositTransaction = deposits.selectedDeposit && deposits.selectedDeposit.transactions ?
      deposits.selectedDeposit.transactions : [];
    const selectedFees = deposits.selectedDeposit && deposits.selectedDeposit.fees ?
      deposits.selectedDeposit.fees : [];
    const selectedChargebacks = deposits.selectedDeposit && deposits.selectedDeposit.chargebacks ?
      deposits.selectedDeposit.chargebacks : [];
    const selectedDepositRepresentments = deposits.selectedDeposit && deposits.selectedDeposit.representments ?
      deposits.selectedDeposit.representments : [];
    const selectedDepositAllTransactionTypes = ReportUtil.mergeDepositTransactionArrays(selectedDepositTransaction, selectedChargebacks, selectedFees, selectedDepositRepresentments);
    const selectedDepositTransactionsJson = CsvUtil.createDepositTransactionsCsv(selectedDepositAllTransactionTypes, user);
    CsvExporter.download(selectedDepositTransactionsJson, labelName + '_Deposit_Details_' + moment().format('MMDDYYYY') + '.csv');
  }

  async handleDetailPrint() {
    const labelName = LabelUtil.getLabel().text;

    const { dispatch, user, userExperience } = this.props;

    let { deposits } = this.props;

    await dispatch(getDepositTransactionData(user, deposits.selectedDeposit.uniq_id, userExperience?.allAccounts));

    deposits = this.props.deposits;
    const selectedDepositTransaction = deposits.selectedDeposit && deposits.selectedDeposit.transactions ?
      deposits.selectedDeposit.transactions : [];
    const selectedFees = deposits.selectedDeposit && deposits.selectedDeposit.fees ?
      deposits.selectedDeposit.fees : [];
    const selectedChargebacks = deposits.selectedDeposit && deposits.selectedDeposit.chargebacks ?
      deposits.selectedDeposit.chargebacks : [];
    const selectedDepositRepresentments = deposits.selectedDeposit && deposits.selectedDeposit.representments ?
      deposits.selectedDeposit.representments : [];
    const selectedDepositAllTransactionTypes = ReportUtil.mergeDepositTransactionArrays(selectedDepositTransaction, selectedChargebacks, selectedFees, selectedDepositRepresentments);
    const account = 'AccountNumber: ' + _.find(user.data.merchantAccounts, ['mea_id', Number(user.selectedMerchantAccount)]).mid;
    const filename = labelName + ' Deposit Detail - ' + account;
    const printWindow = window.open('Deposit Detail', '_blank');
    const selectedDepositTransactionsHtml = deposits.selectedDeposit && ReportUtil.buildDepositTransactionsHtml(selectedDepositAllTransactionTypes, deposits.selectedDeposit, filename);
    if(selectedDepositTransactionsHtml) !_.isNull(printWindow) && printWindow?.document?.write(selectedDepositTransactionsHtml);
  }

  handleExportDepositsClick() {

    const {deposits, user} = this.props;

    const labelName = LabelUtil.getLabel().text;
    let depositsJson = CsvUtil.createDepositsCsv(deposits.filteredData, user);
    CsvExporter.download(depositsJson, labelName + '_Deposits_' + moment().format('MMDDYYYY') + '.csv');

  }

  handleFilterSelection(filter) {
    this.props.dispatch(setVisibilityFilter({property: 'type', value: filter.name}, 'filter'));
  }

  constructCsv(transactionsArray, user, auth, dispatch, action) {
    const chunkSize = 10000;

    const chunks = _.chunk(transactionsArray, chunkSize);

    const chain = chunks.map( (chunk, index) => () => {
      dispatch(setLoaderPercentage(Math.round((index + 1) / chunks.length * 100)));
      return dispatch(generateTransactionCsv({
        transactions: chunk,
        user,
        debtRepayment: auth.debtRepayment
      }));
    });

    return chain.reduce(
      (accumulator, chunk) => accumulator.then(
        acc => chunk().then(result => acc.concat(result?.response))),
      Promise.resolve([])
    );
  }

  async handleExportDepositTransactions() {

    const { dispatch, user, auth, userExperience } = this.props;
    let { deposits } = this.props;

    try {
      dispatch(setLoaderPercentage(0));

      await dispatch(getDepositTransactionData(user, deposits.selectedDeposit.uniq_id, userExperience?.allAccounts));

      deposits = this.props.deposits;

      const selectedDepositTransaction = deposits.selectedDeposit && deposits.selectedDeposit.transactions ?
        deposits.selectedDeposit.transactions : [];
      const selectedFees = deposits.selectedDeposit && deposits.selectedDeposit.fees ?
        deposits.selectedDeposit.fees : [];
      const selectedChargebacks = deposits.selectedDeposit && deposits.selectedDeposit.chargebacks ?
        deposits.selectedDeposit.chargebacks : [];
      const selectedDepositRepresentments = deposits.selectedDeposit && deposits.selectedDeposit.representments ?
        deposits.selectedDeposit.representments : [];

      const selectedDepositAllTransactionTypes = ReportUtil.mergeDepositTransactionArrays(selectedDepositTransaction, selectedChargebacks, selectedFees, selectedDepositRepresentments);

      /* istanbul ignore else */
      if (!selectedDepositAllTransactionTypes.length) return;

      let transactionsJson;

      this.setState({constructingCsv: true});

      if (selectedDepositAllTransactionTypes.length > 10000) {
        transactionsJson = await this.constructCsv(selectedDepositAllTransactionTypes, user, auth, dispatch);
      } else {
        transactionsJson = CsvUtil.createDepositTransactionsCsv(selectedDepositAllTransactionTypes, user, auth.debtRepayment);
        dispatch(setLoaderPercentage(100));
      }

      transactionsJson.length > 0 && CsvExporter.download(transactionsJson, 'Transaction_' + moment().format('MMDDYYYY') + '.csv');
      this.setState({constructingCsv: false});

    } catch (err) {
      this.setState({constructingCsv: false});

    }

  }

  render() {

    const {isFetching, deposits, merchantSettings, user, t} = this.props;

    const isEmpty = deposits.data === null || merchantSettings.customReportStartTime === null;

    const pageLoading = isEmpty && isFetching;

    if (pageLoading) {
      return (
        <Page title={t('Deposits')} loading/>
      )
    }

    const printIcon = IconUtil.getIcon('BatchesPrintIcon', LabelUtil.getLabelColor());
    const exportIcon = IconUtil.getIcon('ExportIconBatch', LabelUtil.getLabelColor());

    const filteredDepositsLength = deposits.filteredData.length;

    const filterData =  [
      {name: 'All', count: deposits.data.length},
      {name: 'Deposits'},
      {name: 'Withdrawals'},
      {name: 'Resolved transactions'},
      {name: 'Held funds'}
    ];

    const listHeaders = ((deposits.filteredData && deposits.filteredData.length > 0) &&
      <TableRow className={'tableRow'}>
        <TableHeader
          type='table'
          clickable
          headerId={'depositsHeaderOne'}
          attribute={'type'}
          name={t('Type')}
          {...this.props}
        />
        <TableHeader
          type='table'
          clickable
          attribute={'deposit_date'}
          headerId={'depositsHeaderTwo'}
          name={t('DepositInitiated')}
          className='hideMobile'
          {...this.props}
        />
        <TableHeader
          type='table'
          clickable
          attribute={'effective_date'}
          headerId={'depositsHeaderTwo'}
          name={t('EstimatedDepositDate')}
          className='hideMobile'
          {...this.props}
        />
        <TableHeader
          type='table'
          headerId={'depositsHeaderThree'}
          clickable
          attribute={'amount_to_clear'}
          name={t('Amount')}
          {...this.props}
        />
      </TableRow>
    ) || null;

    const noDataText = UserUtil.isEPX(user) && UserUtil.userType(user) !== 'PA' ?
      t('NoData.BatchDeposits') : t('NoData.NoDeposits');

    const depositRows = deposits.filteredData.length > 0 ?
      deposits.filteredData.map((deposit, i) => {
          const depositInitiated = moment(deposit.deposit_date).format('MM/DD/YYYY');
          const estimatedDepositDate = moment(deposit.effective_date).format('MM/DD/YYYY');
          const tableRowClassName = this.state.selectedIndex === i ? 'tableRow selectedListRow' : 'tableRow';
          return (
            <TableRow key={i} className={tableRowClassName}>
              <TableCell>{deposit.type}</TableCell>
              <TableCell className='hideMobile'>{depositInitiated}</TableCell>
              <TableCell className='hideMobile'>{estimatedDepositDate}</TableCell>
              <TableCell>{numeral(deposit.amount_to_clear).format('$0,0.00')}</TableCell>
            </TableRow>
          )
        }
      ) : (<NoData text={noDataText} type='table'/>);

    const searchBar = (
      <SearchBarAccessory {...this.props} searchPlaceholder='Search Deposits'/>
    );

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
      />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'dateFilter',
        accessory: (
          <DateFilterAccessory
            {...this.props}
            defaultValue={this.state.dateRange.text}
            handleSelection={this.handleDateSelection}
            showAllTime={false}
            showPreviousCustomSelection={false}
            disableCompactVersion
            dataName={'Deposits data'}
          />
        ),
        showInMobile: true,
        hide: false
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            exportTitle={'Export Deposits'}
            handleSelection={this.handleExportDepositsClick}
          />
        ),
        showInMobile: true,
        hide: !filteredDepositsLength
      },
    ];


    let depositList = (
      <TableBody className='depositList'>
        {listHeaders}
        {depositRows}
      </TableBody>
    );

    const selectedDeposit = deposits.filteredData.length > 0 ? deposits.filteredData[this.state.selectedIndex] : null;

    const detailTitle = selectedDeposit && `${numeral(selectedDeposit.amount_to_clear).format('$0,0.00')} ${selectedDeposit.type}`;

    const isHumboltMpbOrBigData = UserUtil.isHumboltMbpOrLargeProcessor(user);

    const isHbt = UserUtil.isHumbolt(user);
    const islargerPanel = !isHumboltMpbOrBigData && (selectedDeposit?.ndf === 1 || isHbt);

    const detailOptions = selectedDeposit && selectedDeposit.ndf === 1
      ?
      isHumboltMpbOrBigData
        ? [
          {
            onClick: this.handleExportDepositTransactions,
            text: 'Export transaction detail',
            icon: exportIcon
          }
        ]
        : [
          {
            onClick: this.handleDetailExport,
            text: 'Export deposit detail',
            icon: exportIcon
          },
          {
            onClick: this.handleDetailPrint,
            text: 'Print deposit detail',
            icon: printIcon
          }
        ]
      : [];

    const newDetail = selectedDeposit ? (
      <DetailPanel heading={detailTitle} largerPanel={islargerPanel}>
        {deposits.isFetchingDepositTransactions ||
        this.state.constructingCsv ? (
          <SecondaryLoader {...this.props} />
        ) : (
          <DetailPanelOptions options={detailOptions}/>
        )}
        <DepositDetail {...this.props}/>
      </DetailPanel>
    ) : null;

    const titlePanel = (
      <DepositsSummaryHeader {...this.props}/>
    );

    return (
      <section className='deposits'>
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('Deposits')}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          header={titlePanel}
          filterPanel={filterPanel}
          tableData={this.props.deposits.filteredData}
          tableRowLayout={depositList}
          detailDisplayCallback={this.showDetailForRow}
          forceOpenDetail={this.state.forceOpenDetail}
        >
          {newDetail}
        </MasterDetailLayout>
      </section>
    )
  }
}

Deposits.defaultProps = {
  isFetching: true,
};
