import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import UserUtil from '../components/util/UserUtil';
import LabelUtil from '../components/util/LabelUtil';
import {isValidAtob} from '../components/util/CommonUtil';

export function clearNotification(index) {
  return {
    type: actionTypes.clearNotification,
    notificationIndex: index
  };
}

export function updateUserEmail(user, values) {

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/email';

  let body = {};
  body.username = user.data.username;
  body.newUsername = values.newUsername;
  body.currentPassword = values.currentPassword;
  body.captchaResponse = values.captcharesponse;
  body.brand = (UserUtil.userType(user) === 'MBP') ? 'mbp' : LabelUtil.getLabel().abbreviation;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.userUpdateLoginInfoRequest, actionTypes.userUpdateLoginInfoSuccess, actionTypes.userUpdateLoginInfoFailure],
      body
    }
  };
}

export function setModalVisibility(type, state) {

  const modalType = {
    avs: actionTypes.setModalVisibilityTypePostPayment,
    partial: actionTypes.setModalVisibilityTypePostPayment,
    hidden: actionTypes.setModalVisibilityTypeHidden,
    transaction: actionTypes.setModalVisibilityTypeTransaction,
    vt: actionTypes.setModalVisibilityTypeVT,
    partialPayment: actionTypes.setModalVisibilityTypePartialPayment,
    vtInvoice: actionTypes.setModalVisibilityTypeVTInvoice,
    cardError: actionTypes.setModalVisibilityTypeCardError,
    cashPaidInvoice: actionTypes.setModalVisibilityTypeCashPaidInvoice,
    manageIntegrations: actionTypes.setModalVisibilityManageIntegrations,
    duplicate: actionTypes.setModalVisibilityTypeDuplicate,
    apiPasswordDialog: actionTypes.setModalVisibilityTypeApiPasswordDialog,
    apiPasswordResetConfirm: actionTypes.setModalVisibilityTypeApiPasswordResetConfirmDialog,
    switchMIDConfirm: actionTypes.setModalVisibilityTypeSwitchMIDConfirmDialog,
    webHookFormDialog: actionTypes.setModalVisibilityTypeWebHookFormDialog,
    jsSdkFormDialog: actionTypes.setModalVisibilityTypeJsSdkFormDialog,
    twoFactorFailedVerificationDialog: actionTypes.setModalVisibilityTypeTwoFactorFailedVerificationDialog,
    failedIdentityVerificationDialog: actionTypes.setModalVisibilityTypeFailedIdentityVerificationDialog,
    twoFactorDialog: actionTypes.setModalVisibilityTypeTwoFactorDialog,
    twoFactorSetupDialog: actionTypes.setModalVisibilityTypeTwoFactorSetupDialog,
    manageRememberedDevices: actionTypes.setModalVisibilityTypeManageRememberedDevicesDialog,
    invoiceDialog: actionTypes.setModalVisibilityTypeInvoicePostPayment,
    planDetailsFreeDialog: actionTypes.setModalVisibilityTypePlanDetailsFree,
    planDetailsPremiumDialog: actionTypes.setModalVisibilityTypePlanDetailsPremium,
    planDetailsPremiumPlusDialog: actionTypes.setModalVisibilityTypePlanDetailsPremiumPlus,
    planComparisonDialog: actionTypes.setModalVisibilityTypePlanComparison,
    threeAvsErrorDialog: actionTypes.setModalVisibilityTypeThreeAvsError,
    loyaltyAddOnActivateDialog: actionTypes.setModalVisibilityTypeLoyaltyAddOnActivate,
    loyaltyAddOnDeactivateDialog: actionTypes.setModalVisibilityTypeLoyaltyAddOnDeactivate,
    unableToAddPaymentMethodDialog: actionTypes.setModalVisibilityUnableToAddPaymentMethod,
    highTransactionAmountDialog: actionTypes.setModalVisibilityHighTransactionAmount,
    chargezoomDisconnectDialog: actionTypes.setModalVisibilityChargezoomDisconnect
  }[type];

  // Defaults
  const modalState = Object.assign({}, {
    paymentDialogOpen: false,
    vtDialogOpen: false,
    partialPaymentDialogOpen: false,
    vtInvoiceDialogOpen: false,
    cardErrorDialogOpen: false,
    vtDuplicateDialogOpen: false,
    invoiceDialogOpen: false,
    manageIntegrations: false,
    ccInvoiceDialogOpen: false,
    operationType: type,
    postPaymentError: null,
    showError: false,
    showConfirmation: false,
    showRefundTransaction: false,
    refundAsVoid: false,
    errorMessage: null,
    apiPasswordDialog: false,
    apiPasswordResetConfirm: false,
    switchMIDConfirm: false,
    webHookFormDialog: false,
    jsSdkFormDialog: false,
    twoFactorFailedVerificationDialog: false,
    failedIdentityVerificationDialog: false,
    twoFactorDialog: false,
    twoFactorSetupDialog: false,
    planDetailsFreeDialog: false,
    planDetailsPremiumDialog: false,
    planDetailsPremiumPlusDialog: false,
    planComparisonDialog: false,
    threeAvsErrorDialog: false,
    loyaltyAddOnActivateDialog: false,
    loyaltyAddOnDeactivateDialog: false,
    unableToAddPaymentMethodDialog: false,
    manageRememberedDevices: false,
    highTransactionAmountDialog: false,
    chargezoomDisconnectDialog: false
  }, state);

  return {
    type: modalType,
    modalVisibility: modalState
  };
}

export function setVisibilityFilter(filter, filterType, searchElement = null) {

  let action = actionTypes.setVisibilitySearchFilter;
  const filterValue = filter;

  if (filterType === 'filter') {
    action = actionTypes.setVisibilityFilter;
  }

  if (filterType === 'modalSearch') {
    action = actionTypes.setModalVisibilitySearchFilter;
  }

  if (filterType === 'secondaryFilter') {
    action = actionTypes.setSecondaryVisibilityFilter;
  }

  if (filterType === 'advancedSearch') {
    action = actionTypes.setAdvancedSearchVisibilityFilter;
  }

  if (filterType === 'order') {
    action = actionTypes.setOrderVisibilityFilter;
  }

  if (filterType === 'pagination') {
    action = actionTypes.setPaginationVisibilityFilter;
  }

  return {
    type: action,
    filterValue,
    searchElement,
  };
}

export function addMidForUI(value) {
  return {
    type: actionTypes.addMidForUI,
    value
  };
}

export function removeMidForUI(value) {
  return {
    type: actionTypes.removeMidForUI,
    value
  };
}

export function addMidErrorForUI(value) {
  return {
    type: actionTypes.addMidErrorForUI,
    value
  };
}

export function clearMidMessagesForUI() {
  return {
    type: actionTypes.clearMidMessagesForUI,
    value: null
  };
}

export function setHeaderHeight(value = 0) {
  return {
    type: actionTypes.setHeaderHeight,
    value
  };
}

export function setOpenAccountSwitchDialog(value) {
  return {
    type: actionTypes.setOpenAccountSwitchDialog,
    value
  };
}

export function setOpenAddAccountDialog(value) {
  return {
    type: actionTypes.setOpenAddAccountDialog,
    value
  };
}

export function setOpenReportsDialog(value) {
  return {
    type: actionTypes.setOpenReportsDialog,
    value
  };
}

export function setOpenMessageDialog(value) {

  return {
    type: actionTypes.setOpenMessageDialog,
    value
  };
}

export function setMessageDialogContent(value) {
  return {
    type: actionTypes.setMessageDialogContent,
    value
  };
}

export function setOpenDynamicMessageDialog(value) {

  return {
    type: actionTypes.setOpenDynamicMessageDialog,
    value
  };
}

export function setOpenChangePasswordDialog() {

  return {
    type: actionTypes.setOpenChangePasswordDialog,
  };
}

export function setDynamicMessageDialogContent(value) {
  return {
    type: actionTypes.setDynamicMessageDialogContent,
    value
  };
}

export function toggleRefreshTokenDialog(value) {
  return {
    type: actionTypes.toggleRefreshTokenDialog,
    value
  };
}

export function toggleAllAccounts(value) {
  return {
    type: actionTypes.toggleAllAccounts,
    value
  };
}

export function toggleHistoricHardwareMode() {
  return {
    type: actionTypes.toggleHistoricHardwareMode
  };
}

export function closeHistoricHardwareModeNotice() {
  return {
    type: actionTypes.closeHistoricHardwareModeNotice
  };
}

export function toggleOpenBatchTransactions(value) {
  return {
    type: actionTypes.toggleOpenBatchTransactions,
    value
  };
}

export function toggleShowGiftCardProgIntegration(value) {
  return {
    type: actionTypes.toggleShowGiftCardProgIntegration,
    value
  };
}

export function toggleShowIntegratedAccessKeysIntegration(value) {
  return {
    type: actionTypes.toggleShowIntegratedAccessKeysIntegration,
    value
  };
}

export function toggleShowTransactionsWebhookIntegration(value) {
  return {
    type: actionTypes.toggleShowTransactionsWebhookIntegration,
    value
  };
}

export function setLinkEnabled(value) {
  return {
    type: actionTypes.setLinkEnabled,
    value
  };
}

export function setSubmitButtonEnabled(value) {
  return {
    type: actionTypes.setSubmitButtonEnabled,
    value
  };
}

export function setEditButtonEnabled(value) {
  return {
    type: actionTypes.setEditButtonEnabled,
    value
  };
}

export function setSelectedItemTab(value) {
  return {
    type: actionTypes.setSelectedItemTab,
    value
  };
}

export function setSelectedDateRange(value, dateRange) {
  if (localStorage && dateRange) localStorage.setItem('pa-dateRange', JSON.stringify({ dateRange }));
  return {
    type: actionTypes.setSelectedDateRange,
    value: value,
    dateRange: dateRange,
    customStart: (dateRange && dateRange.startDate) ? dateRange.startDate : '',
    customEnd: (dateRange && dateRange.endDate) ? dateRange.endDate : ''
  };
}

export function setStopMIDConfirmation() {
  localStorage.setItem('stopMIDConfirmation', true);
  return {
    type: actionTypes.setStopMIDConfirmation,
  };
}

export function setSelectedEmployeeFilter(value) {
  return {
    type: actionTypes.setSelectedEmployeeFilter,
    value: value
  };
}

export function setSelectedChartItems(selectedItems, selectedRows) {
  return {
    type: actionTypes.setSelectedChartItems,
    selectedItems: selectedItems,
    selectedRows: selectedRows
  };
}

export function setViewOrderSummary(value) {
  return {
    type: actionTypes.viewOrderSummary,
    value
  };
}

export function setViewRecurringFields(value) {
  return {
    type: actionTypes.viewRecurringFields,
    value
  };
}

export function setUserExperienceFetching(value) {
  return {
    type: actionTypes.userExperienceFetching,
    value
  };
}

export function setGeoTaxFetching(value) {
  return {
    type: actionTypes.geoTaxFetching,
    value
  };
}

export function setGraphSelector(value) {
  return {
    type: actionTypes.setGraphSelector,
    value
  };
}

export function toggleShowAdvancedSearchMoreFields(value) {
  return {
    type: actionTypes.toggleShowAdvancedSearchMoreFields,
    value
  };
}

export function generatePdf(filename, docDefinition) {

  let endpoint = 'generate-pdf';

  let body = {};
  body.filename = filename;
  body.docDefinition = JSON.stringify(docDefinition);

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.generatePdfRequest, actionTypes.generatePdfSuccess, actionTypes.generatePdfFailure],
      body
    }
  };
}

export function setLocalCommunication(values) {

  let ux = JSON.parse(localStorage.getItem('pa-ux'));

  const communicationObj = {
    comm_dailySnapshotEmail: values.comm_dailySnapshotEmail,
    comm_compliance: values.comm_compliance,
    comm_disputes: values.comm_disputes,
    comm_statements: values.comm_statements
  };

  if (ux) {
    ux.communication = communicationObj;
    localStorage.setItem('pa-ux', JSON.stringify(ux));

  } else {
    ux = {};
    ux.communication = communicationObj;
    localStorage.setItem('pa-ux', JSON.stringify(ux));
  }

  return {
    type: actionTypes.localUserExperienceSet,
  };
}

export function setVTAccessProcessValues(values) {
  let ux = localStorage.getItem('pa-ux') ? JSON.parse(localStorage.getItem('pa-ux')) : null;

  const defaultValues = {
    vt_tokenValidationFail: 0,
    vt_phoneValidationFail: 0,
    vt_processEnd: false,
    vt_processPassed: false
  };
  let vtAccessProcessObj = {};
  // If no values and nothing on local storage set to default
  if (!values && (!ux || !ux.vtaccess)) {
    vtAccessProcessObj = defaultValues;
  // If no values but already data on LS, set the data on local storage (in  case of page refresh)
  } else if (!values && (ux && ux.vtaccess)) {
    vtAccessProcessObj = isValidAtob(ux.vtaccess) ? JSON.parse(decodeURIComponent(atob(ux.vtaccess))) : defaultValues;
  } else { vtAccessProcessObj = { ...values } }

  if (ux) {
    ux.vtaccess = encodeURIComponent(btoa(JSON.stringify(vtAccessProcessObj)));
    localStorage.setItem('pa-ux', JSON.stringify(ux).replace(/%3D/g, ''));

  } else {
    ux = {};
    ux.vtaccess = encodeURIComponent(btoa(JSON.stringify(vtAccessProcessObj)));
    localStorage.setItem('pa-ux', JSON.stringify(ux));
  }


  return {
    type: actionTypes.vtAccessProcessValuesSet,
  };
}

export function removeVTAccessProcessValues() {
  return {
    type: actionTypes.vtAccessProcessValuesRemove
  };
}

export function emailValidatedFlag(string) {

  /////////////////////////////////////////////////////////////////////
  // String values: valid - invalid - progress
  /////////////////////////////////////////////////////////////////////

  let ux = JSON.parse(localStorage.getItem('pa-ux'));

  if (ux) {
    ux.emailValidated = string;
    localStorage.setItem('pa-ux', JSON.stringify(ux));
  } else {
    ux = {};
    ux.emailValidated = string;
    localStorage.setItem('pa-ux', JSON.stringify(ux));
  }

  return {
    type: actionTypes.localUserExperienceSet
  };
}

export function setLoaderPercentage(value) {
  return {
    type: actionTypes.setLoaderPercentage,
    value
  };
}

export function showLoader() {
  return {
    type: actionTypes.showReportLoader
  };
}

export function hideLoader() {
  return {
    type: actionTypes.hideReportLoader
  };
}

export function generateTransactionCsv({ transactions, user, debtRepayment }) {

  const endpoint = 'generateTransactionCsv';

  const body = {
    transactions,
    user,
    debtRepayment
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.generateTransactionCsvRequest, actionTypes.generateTransactionCsvSuccess, actionTypes.generateTransactionCsvFailure],
      body
    }
  };
}

export function generateExpandedTransactionCsv({ transactions, user, debtRepayment, processingLevel }) {

  const endpoint = 'generateExpandedTransactionCsv';

  const body = {
    transactions,
    user,
    debtRepayment,
    processingLevel
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.generateTransactionCsvRequest, actionTypes.generateTransactionCsvSuccess, actionTypes.generateTransactionCsvFailure],
      body
    }
  };
}

export function generateCustomReportCsv({
                                          transactions,
                                          user,
                                          debtRepayment,
                                          processingLevel,
                                          customReport,
                                          customFields = [],
                                        }) {

  const endpoint = 'generateCustomReportCsv';

  const body = {
    transactions,
    user,
    debtRepayment,
    processingLevel,
    customReport,
    customFields
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.generateTransactionCsvRequest, actionTypes.generateTransactionCsvSuccess, actionTypes.generateTransactionCsvFailure],
      body
    }
  };
}

export function generateDepositTransactionCsv(transactions, user) {

  const endpoint = 'generateDepositTransactionCsv';

  const body = {
    transactions,
    user
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.generateTransactionCsvRequest, actionTypes.generateTransactionCsvSuccess, actionTypes.generateTransactionCsvFailure],
      body
    }
  };
}

export function suppressAppError(value) {
  return {
    type: actionTypes.suppressAppError,
    value
  };
}

export function midSelectedFlag() {
  localStorage.setItem('pa-mid', true);
  return {
    type: actionTypes.midSelected
  };
}

export function setOpenRelatedMidsDialog(value) {
  return {
    type: actionTypes.setOpenRelatedMidsDialog,
    value
  };
}

export function setActiveFilterHeader(headerId, orderDirection) {
  return {
    type: actionTypes.setActiveHeader,
    headerId,
    orderDirection
  };
}

export function updateItemsChart(value) {
  return {
    type: actionTypes.updateItemsChart,
    value
  };
}

export function toggleMainMenu() {
  return {
    type: actionTypes.toggleMainMenu
  };
}

export function closeMainMenu() {
  return {
    type: actionTypes.closeMainMenu
  };
}

export function toggleInventoryPopover(value) {
  return {
    type: actionTypes.toggleInventoryPopover,
    value
  };
}

export function setEdgeProgramTerms(bool) {

  let ux = JSON.parse(localStorage.getItem('pa-ux'));

  const edgeTermsAccepted = bool;

  if (ux) {
    ux.edgeTermsAccepted = edgeTermsAccepted;
    localStorage.setItem('pa-ux', JSON.stringify(ux));

  } else {
    ux = {};
    ux.edgeTermsAccepted = edgeTermsAccepted;
    localStorage.setItem('pa-ux', JSON.stringify(ux));
  }

  return {
    type: actionTypes.localUserExperienceSet,
  };
}

export function toggleAgentPortalMsg(value) {
  return {
    type: actionTypes.toggleAgentPortalMsg,
    value
  };
}

export function toggleSalesPartnerNotificationAccepted(value) {
  return {
    type: actionTypes.toggleSalesPartnerNotificationAccepted,
    value
  };
}

export function toggleMerchantVerifyByCSDialog(value) {
  return {
    type: actionTypes.toggleMerchanConfirmByCSDialog,
    value
  };
}

export function setComparedToPeriod(value) {
  return {
    type: actionTypes.compareToPeriod,
    value
  };
}

export function toggleTerms2FADialog(value) {

  return {
    type: actionTypes.toggleTerms2FADialog,
    value
  };
}

export function togglePaymentMethodsDeleteDialog(value) {

  return {
    type: actionTypes.paymentMethodsToggleDeleteDialog,
    value
  };
}

export function togglePaymentMethodsEditDialog(value) {

  return {
    type: actionTypes.paymentMethodsToggleEditDialog,
    value
  };
}

export function togglePaymentMethodsAddDialog(value) {

  return {
    type: actionTypes.paymentMethodsToggleAddDialog,
    value
  };
}

export function acknowledgeIssueCreditDialog() {
  return {
    type: actionTypes.issueCreditDialogAcknowledged
  };
}

export function setReputationCardNextValue() {
  let ux = JSON.parse(localStorage.getItem('pa-pux'));

  if (ux) {
    const reputationCard = ux.currentReputationCard;
    if(!reputationCard || reputationCard === '3' || isNaN(reputationCard)) {
      ux.currentReputationCard = '1';
    } else {
      ux.currentReputationCard = (+reputationCard + 1).toString();
    }
    localStorage.setItem('pa-pux', JSON.stringify(ux));
  } else {
    ux = {};
    ux.currentReputationCard = '1';
    localStorage.setItem('pa-pux', JSON.stringify(ux));
  }

  return {
    type: actionTypes.persistentUserExperienceSet,
  };
}

export function togglePlanListDialog(value) {

  return {
    type: actionTypes.planListToggleDialog,
    value
  };
}

export function toggleUnableToActivateAccountDialog(value) {

  return {
    type: actionTypes.unableToActivateAccountToggleDialog,
    value
  };
}

export function toggleNorthGatekeeper(value) {

  return {
    type: actionTypes.northGatekeeperToggleDialog,
    value
  };
}

export function toggleAchTermsAndConditions(value) {
  
  return {
    type: actionTypes.toggleAchTermsAndConditions,
    value
  };
}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
