/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import numeral from 'numeral';
import moment from 'moment';
import { roundToTwoDecimals } from '../util/CommonUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import FormUtil from './../util/FormUtil';
import ApplePayButton from '../ApplePayButton';
import BrowserUtil from './../util/BrowserUtil';
import {
  setSubmitButtonEnabled,
  setViewOrderSummary
} from '../../actions/userExperienceActions';
import { setInvoiceForPaymentTip } from '../../actions/invoicesActions';
import VisibilityToggle from '../visibilityToggle';
import _ from 'lodash';
import Button from '../shared/Button';
import TextField from '../shared/TextField';
import { checkIsSpanish } from '../../locales/i18n';
import {
  highTransactionLimitAmount
} from '../../constants/applicationConstants';
import paymentTypes from '../../constants/paymentTypes';
import InvoiceUtil from '../util/InvoiceUtil';

const validate = (values) => {
  return Validator.validateOnlinePayments(values);
};

export class PayInvoiceFormComponent extends Component {

  constructor(props) {
    super(props);

    this.viewOrderSummary = this.viewOrderSummary.bind(this);
    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.validateApplePay = this.validateApplePay.bind(this);
    this.state = {
      cardVisibility: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const enableSubmit = FormUtil.shouldEnableSubmit(nextProps);
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
    if (this.props.currentValues?.tip_amount !== nextProps.currentValues?.tip_amount) {
      this.props.dispatch(setInvoiceForPaymentTip(nextProps.currentValues?.tip_amount));
    }
  }

  viewOrderSummary() {
    this.props.dispatch(setViewOrderSummary(!this.props.userExperience.viewOrderSummary));
  }

  /**
   * Toggles the state property in charge of hiding the contents
   * of the credit card number input.
   */
  toggleCardNumberVisibility() {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  validateApplePay() {
    const { currentValues, initialValues } = this.props;
    const receiptDiscountsTotalAmount = initialValues.receipt_discount_amt && initialValues.receipt_discount_amt.length > 0
      ? initialValues.receipt_discount_amt.reduce((total, currentDiscount) => (total + roundToTwoDecimals(currentDiscount)), 0)
      : null;
    const discountAmountToSubtract = !initialValues.paid_date && !initialValues.receipt_updated_date && receiptDiscountsTotalAmount ? receiptDiscountsTotalAmount : 0;
    const totalAmount = roundToTwoDecimals(numeral(currentValues.total_amt).value() - discountAmountToSubtract);
    const tipAmount = numeral(currentValues?.tip_amount).value();
    let isValid = totalAmount <= highTransactionLimitAmount;
    if(tipAmount > 0 && (totalAmount + tipAmount) > highTransactionLimitAmount){
      isValid = false;
    }
    return isValid;
  }

  render() {

    const {handleSubmit, submitting, handleSubmitApplePay, invoices, initialValues, virtualTerminal, currentValues, t} = this.props;

    const receiptEmailText = initialValues.email_addresses?.length > 0 && initialValues.email_addresses[0] !== null ?  ` ${t('PayInvoiceForm.ReceiptSent')} ${initialValues.email_addresses[0]}` : '';

    let thankYouText = `${t('PayInvoiceForm.PaymentReceived')}. ${receiptEmailText}` ;

    if (!initialValues.paid_date ) {
      thankYouText = t('PayInvoiceForm.PaymentReceived');
    }

    const isSafari = BrowserUtil.isSafari();
    
    const { taxAmount, tipAmount, subTotalAmount, totalAmount } = InvoiceUtil.formatPayNowInvoiceTotal(initialValues);

    const applePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      shippingMethods: [],
      lineItems: [
        {
          label: `Invoice ${this.props.initialValues.invoice} Subtotal`,
          amount: subTotalAmount.toString(),
          type: 'final'
        },
        {
          label: 'Tax',
          amount: taxAmount.toString(),
          type: 'final'
        }
      ],
      total: {
        label: 'Total',
        amount: totalAmount.toString(),
        type: 'final'
      },
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      requiredBillingContactFields: ['postalAddress']
    };

    if (tipAmount > 0) {
      applePayPaymentRequest.lineItems.push({
        label: 'Tip',
        amount: tipAmount.toString(),
        type: 'final'
      });
    }

    const applePayEnabled = !_.isUndefined(initialValues.apple_pay_enabled) ? initialValues.apple_pay_enabled : true;

    const applePayButton = isSafari && applePayEnabled ?
      (<div className='applePayContainer'>
        <ApplePayButton
          {...this.props}
          paymentRequest={applePayPaymentRequest}
          paymentData={invoices.invoiceForPayment}
          paymentType={paymentTypes.invoice}
          tipAmount={tipAmount}
          handlePaymentProcessing={handleSubmitApplePay}
          validate={this.validateApplePay}
        />
      </div>) : null;

    const formHasCdigits = virtualTerminal.cardType && currentValues && currentValues.cdigits &&
                           currentValues.cdigits.length > 0;

    const isSpanishLanguage = checkIsSpanish() ? 'spanishTranslation' : '';

    return (
      <form onSubmit={handleSubmit}>
        <div className='invoiceFormContainer virtualTerminalForm'>
          {this.props.paymentCompleted ? (
              <div className='thankYou'>
                <div className='invoiceTitle'>{`${t('ThankYou')}.`}</div>
                <div className='invoiceSubtitle'>{thankYouText}</div>
                { !!initialValues.paid_date && (
                  <div className='invoiceSubtitle'>{`${t('PayInvoiceForm.Paid')}: ${moment(initialValues.paid_date).format('MM/DD/YYYY')}`}</div>
                )}
              </div>
            ) :
            (
              <div className='invoiceDue'>
                <div className='invoiceTitle'>{`${numeral(totalAmount).format('$0,0.00')} ${t('PayInvoiceForm.Due')}`}</div>
                <div className='invoiceSubtitle'>{`${t('PayInvoiceForm.PayBy')} ${moment.utc(initialValues.due_date).format('MMMM DD, YYYY')}`}</div>
              </div>
            )
          }
          <Button
            label='View order summary'
            className='viewOrderSummaryButton'
            onClick={this.viewOrderSummary}
          />
          <br/> <br/>

          {!this.props.paymentCompleted ? (
            <div className='paymentInformation'>

              {applePayButton}

              <div className='formItems'>
                {initialValues?.is_tip_enabled && initialValues?.inv_allow_tip && (
                  <div className='flex'>
                    <Field
                      component={TextField}
                      name='tip_amount'
                      label={t('AddTip')}
                      maxLength='20'
                      hintText='$0.00'
                      normalize={FormatTextUtil.formatCurrencyWithMaxDigit}
                      className='halfScreen textField tip_amount'
                    />
                  </div>
                )}

                <div>
                  <div
                    className='cardContainerWithVisibilityToggle halfToFullScreen'>
                    {/*The label text is in business.css to stop safari from saving the card number*/}
                    <Field
                      className={`cardNumber textField ${isSpanishLanguage} ${this.state.cardVisibility ? 'visible' : ''}`}
                      component={TextField}
                      disabled={submitting}
                      label=' '
                      maxLength='25'
                      name='cdigits'
                      normalize={value => FormatTextUtil.formatCardNumber(value)}
                    />
                    <span className='cardImage'>
                      {formHasCdigits ?
                        <img width='50'
                             src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                        : <img width='50'
                               src={`${serverDomainUrl}images/cards/unknown_card.png`}/>}
                    </span>
                    <VisibilityToggle
                      visibility={this.state.cardVisibility}
                      onClick={this.toggleCardNumberVisibility}/>
                  </div>

                  <Field
                    component={TextField}
                    name='edate'
                    label={t('PayInvoiceForm.Expiration')}
                    hintText='MM/YY'
                    normalize={FormatTextUtil.formatCreditCardExpiration}
                    className='quarterToHalfScreen textField cardDate'
                    disabled={submitting}
                    maxLength='5'
                  />
                  <Field
                    name='cvv'
                    component={TextField}
                    label={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                    hintText={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                    maxLength='10'
                    disabled={submitting}
                    normalize={FormatTextUtil.formatWholeNumber}
                    className='quarterToHalfScreen textField cardCvv'
                  />

                </div>

                <div className='flex'>
                  <Field
                    component={TextField}
                    name='street_number'
                    label={t('PayInvoiceForm.BillingStreet')}
                    maxLength='50'
                    normalize={FormatTextUtil.formatStreetNumber}
                    className='halfScreen textField cardBillingStreet'
                  />
                  <Field
                    component={TextField}
                    name='zip'
                    label='Zip'
                    maxLength='5'
                    disabled={submitting}
                    normalize={FormatTextUtil.formatWholeNumber}
                    className='quarterScreen textField cardZip'
                  />
                  <Field
                    component={TextField}
                    name='zipPlus4'
                    label='Zip + 4'
                    hintText={t('Optional')}
                    maxLength='4'
                    disabled={submitting}
                    normalize={FormatTextUtil.formatWholeNumber}
                    className='quarterScreen textField cardZipPlus4'
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    );
  }
}

let PayInvoiceForm = reduxForm({
  form: 'payInvoiceForm',
  fields: ['cdigits', 'edate', 'cvv', 'street_number', 'zip', 'zipPlus4'],
  validate,
  enableReinitialize: false
})(PayInvoiceFormComponent);

function mapStateToProps(state, ownProps) {

  const currentValues = getFormValues('payInvoiceForm')(state);
  const invoice = ownProps?.invoices?.invoiceForPayment;

  return {
    initialValues: {...invoice, creditCardPaymentFlag: true},
    paymentCompleted: ownProps.paymentCompleted,
    currentValues
  };
}

PayInvoiceForm = connect(mapStateToProps)(PayInvoiceForm);

export default PayInvoiceForm;
