/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import FormatTextUtil from '../../util/FormatTextUtil';
import Validator from '../../util/Validator';
import FormUtil from '../../util/FormUtil';
import { setSubmitButtonEnabled } from '../../../actions/userExperienceActions';
import TextField from '../../shared/TextField';
import VisibilityToggle from '../../visibilityToggle'
import {checkIsSpanish} from '../../../locales/i18n';
import {Box} from '@mui/material';
import {
  PayOrderFormStyles
} from '../../../jss/payOrderFormStyles';

export const validate = (values) => {
  return Validator.validatePayOrderForm(values);
};

export class PayOrderFormContainer extends Component {

  constructor(props) {
    super(props);

    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);

    this.state = {
      cardVisibility: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let enableSubmit = FormUtil.shouldEnableSubmit(nextProps);
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
  }

  /**
   * Toggles the state property in charge of hiding the contents
   * of the credit card number input.
   */
  toggleCardNumberVisibility() {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  render() {

    const {handleSubmit, submitting, currentValues, virtualTerminal, t} = this.props;

    const formHasCdigits = virtualTerminal.cardType && currentValues && currentValues.cdigits &&
                           currentValues.cdigits.length > 0;

    const isSpanishLanguage = checkIsSpanish() ? 'spanishTranslation' : '';

    return (
      <form onSubmit={handleSubmit}>
        <div className='orderFormContainer virtualTerminalForm'>
          <div className='paymentInformation'>
            <div className='formItems'>
              <Box sx={PayOrderFormStyles}>
                <div className='cardContainerWithVisibilityToggle'>
                  <Field
                    className={`cardNumber textField fullScreen ${this.state.cardVisibility ? 'visible' : ''} ${isSpanishLanguage}`}
                    component={TextField}
                    disabled={submitting}
                    label=' '
                    maxLength='25'
                    name='cdigits'
                    normalize={value => FormatTextUtil.formatCardNumber(value)}
                    style={{width: '35%'}}
                  />
                  <div className='cardImage'>
                  {formHasCdigits ?
                    <img width='50' src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                    : <img width='50' className='blankCard' src={`${serverDomainUrl}images/cards/unknown_card.png`}/>}
                  </div>
                  <VisibilityToggle
                      visibility={this.state.cardVisibility}
                      onClick={this.toggleCardNumberVisibility} />
                </div>
                <Field
                  component={TextField}
                  name='edate'
                  label={t('PayOrderForm.Expiration')}
                  hintText='MM/YY'
                  normalize={FormatTextUtil.formatCreditCardExpiration}
                  className='quarterScreen textField'
                  disabled={submitting}
                  maxLength='5'
                />
                <Field
                  name='cvv'
                  component={TextField}
                  label={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                  hintText={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                  maxLength='10'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField'
                />
              </Box>
              <div className='flex'>
                <Field
                  component={TextField}
                  name='street_number'
                  label={t('PayOrderForm.BillingStreetNumber')}
                  maxLength='50'
                  normalize={FormatTextUtil.formatStreetNumber}
                  className='halfScreen textField'
                />
                <Field
                  component={TextField}
                  name='zip'
                  label={t('PayOrderForm.Zip')}
                  maxLength='5'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField '
                />
                <Field
                  component={TextField}
                  name='zipPlus4'
                  label={t('PayOrderForm.ZipPlus')}
                  hintText='Optional'
                  maxLength='4'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField'
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

let PayOrderForm = reduxForm({
  form: 'payOrderForm',
  fields: ['cdigits', 'edate', 'cvv', 'street_number', 'zip', 'zipPlus4'],
  validate,
  enableReinitialize: false
})(PayOrderFormContainer);

export function mapStateToProps(state, ownProps) {

  const currentValues = getFormValues('payOrderForm')(state) || {};

  return {
    initialValues: {
      creditCardPaymentFlag: true
    },
    totalOrder: ownProps.totalOrder,
    currentValues
  }
}

PayOrderForm = connect(mapStateToProps)(PayOrderForm);

export default PayOrderForm;
