/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Button from '../shared/Button';
import LabelUtil from '../util/LabelUtil';
import ThemeUtil from '../util/ThemeUtil';
import MerchantLogo from '../MerchantLogo';
import Select from '../shared/Select';
import TextField from '../shared/TextField';
import Switch from '../shared/Switch';
import { MenuItem } from '@mui/material';
import Facebook from '../icons/FacebookBlackIcon';
import Twitter from '../icons/TwitterBlackIcon';
import Instagram from '../icons/InstagramBlackIcon';
import YouTube from '../icons/YouTubeBlackIcon';

const PaymentPortalPreview = ({ logo, formReducer, ...props }) => {

  const values = formReducer?.receiptSettingsForm?.values;
  const isAddressComplete = values?.address && values?.city;
  const brandColor = values?.brand_color;
  const { t } = props;

  return (
    <ThemeProvider theme={ThemeUtil.getTheme(ThemeUtil.themeConfig({}, brandColor))}>
      <section className='paymentPortal paymentPortalPreview'>
        <aside className='sideContainer'>
          <div className='panel'>
            <MerchantLogo
              logoBase64={logo}
              size={50}
              additionalClass='logo'
              altText='logo'
            />
            <ul className='list'>
              <Box
                className='item'
                component='li'
                sx={{
                  color: 'labelColor',
                }}
              >
                {t('PaymentPortalPreview.OpenInvoices')}
              </Box>
              <Box
                className='item'
                component='li'
              >
                {t('PaymentPortalPreview.PaymentMethods')}
              </Box>
              <Box
                className='item'
                component='li'
              >
                {t('PaymentPortalPreview.PaymentHistory')}
              </Box>
            </ul>
            <div className='footer'>
              <p>
                <b>{values.merchant_name}</b>
                <br />
                <span>
                  {isAddressComplete && `${values.address}`}
                  <br />
                  {isAddressComplete && `${values.city}, ${values.state} ${values.zip}`}
                  <br />
                  {values.website && values.website}
                </span>
              </p>
              <ul className='social'>
                <li className='fb'>
                  <Facebook />
                </li>
                <li className='twitter'>
                  <Twitter />
                </li>
                <li className='insta'>
                  <Instagram />
                </li>
                <li className='youtube'>
                  <YouTube />
                </li>
              </ul>
              <div className='branding'>
                <span className='img' />
                <p>{t('PoweredBy', { company: LabelUtil.getLabel().text })}</p>
              </div>
            </div>
          </div>
        </aside>
        <div className='main'>
          <div className='form'>
            <div>
              <Button
                className='backButton'
                label={t('PaymentPortalPreview.BackToInvoices')}
                variant='text'
              />
            </div>
            <h3>{t('PaymentPortalPreview.InvoiceAmount')}</h3>
            <h4>{t('PaymentPortalPreview.PayBy')}</h4>
            <div>
              <Select
                label={t('PaymentPortalPreview.PaymentMethod')}
                disabled={true}
                value={1}
              >
                <MenuItem
                  value={1}>
                  {t('PaymentPortalPreview.PayWithCreditCard')}
                </MenuItem>
              </Select>
            </div>
            <div>
              <TextField
                label={t('PaymentPortalPreview.CardHolderName')}
                disabled={true}
                value={'Michael Benedict'}
              />
            </div>
            <div className='multiField'>
              <TextField
                label={t('PaymentPortalPreview.CardNumber')}
                disabled={true}
                value={'•••• •••• •••• 1807'}
                className='half'
              />
              <TextField
                label={t('PaymentPortalPreview.Expiration')}
                disabled={true}
                value={'04/24'}
                className='quarter'
              />
              <TextField
                label='CVV'
                disabled={true}
                value={'740'}
                className='quarter'
              />
            </div>
            <div className='multiField'>
              <TextField
                label={t('BillingStreetNumber')}
                disabled={true}
                value={'37750'}
                className='half'
              />
              <TextField
                label={t('Zip')}
                disabled={true}
                value={'38938'}
                className='quarter'
              />
              <TextField
                label={t('Zip4')}
                disabled={true}
                value={t('Optional')}
                className='quarter'
              />
            </div>
            <div className='switchContainer'>
              <Switch input={{value: true}} />
              <p>{t('PaymentPortalPreview.SavePaymentMethodDesc')}</p>
            </div>
            <div className='tipContainer'>
              <p className='tipLabel'>{t('AddTip')}</p>
              <div className='tipBar'>
                <div className='tipHolder tipActive'>{t('PaymentPortalPreview.NoTip')}</div>
                <div className='tipHolder'>18%</div>
                <div className='tipHolder'>20%</div>
                <div className='tipHolder'>{t('PaymentPortalPreview.Other')}</div>
              </div>
            </div>
            <div className='buttonContainer'>
              <Button label={t('PaymentPortalPreview.PayAmount')} />
            </div>
          </div>
          <div className='detail'>
            <div className='heading'>
              <h3>{values.merchant_name}</h3>
              <p>{t('PaymentPortalPreview.TravelPackage')}</p>
              <p>{t('PaymentPortalPreview.BillTo')}</p>
              <p>{t('PaymentPortalPreview.InvoiceNumber')}</p>
              <p>{t('PaymentPortalPreview.Date')}</p>
              <p>{t('PaymentPortalPreview.RepeatsMonthly')}</p>
            </div>
            <ul className='detailList'>
              <li className='detailItem'>
                <div className='text'>
                  <p className='desc'>{t('PaymentPortalPreview.MonthlyTravelKit')}</p>
                </div>
                <span className='price'>$29.00</span>
              </li>
              <li className='detailItem'>
                <div className='text'>
                  <p className='desc'>{t('PaymentPortalPreview.VacationPackagePayment')}</p>
                  <p className='sub'>{t('PaymentPortalPreview.VacationPlace')}</p>
                </div>
                <span className='price'>$199.00</span>
              </li>
              <li className='detailItem sub'>
                <div className='text'>
                  <p className='desc'>{t('Subtotal')}</p>
                  <p className='desc'>{t('Tax')}</p>
                  <p className='desc'>{t('Tip')}</p>
                </div>
                <div className='pullLeft'>
                  <p className='price'>$228.00</p>
                  <p className='price'>$13.68</p>
                  <p className='price'>$0.00</p>
                </div>
              </li>
              <li className='detailItem'>
                <div className='text'>
                  <p className='desc'>{t('Total')}</p>
                </div>
                <span className='price'>$241.68</span>
              </li>
            </ul>
            <div className='applePay'>
              <span />
            </div>
          </div>
        </div>
      </section>
    </ThemeProvider>
  );

};

export default PaymentPortalPreview;
