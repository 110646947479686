import actionTypes from '../constants/actionTypes';
import messages from '../constants/messages';
import graphSelectors from '../constants/graphSelectors';
import _ from 'lodash';
import LocalStorageUtil from '../components/util/LocalStorageUtil';
import { setLanguageKey } from '../locales/i18n';

const quickbooksSubscribed = 'SUBSCRIBED';

const userExperienceReducer = (
  state = {
    isProcessing: false,
    geoTaxFetching: false,
    openAccountSwitchDialog: false,
    openRelatedMidsDialog: false,
    openAddAccountDialog: false,
    openRefreshTokenDialog: false,
    openReportDialog: false,
    openDepositTransactionsDialog: false,
    openMessageDialog: false,
    openDynamicMessageDialog: false,
    openInventoryPopover: false,
    openChangePasswordDialog: false,
    toggleMerchantVerifyByCSDialog: false,
    suppressMessageDialog: false,
    messageDialogContent: null,
    dynamicMessageDialogContent: [],
    addAccountDialog: {
      mids: [],
      saved: {},
      errors: {}
    },
    allAccounts: false,
    historicHardwareMode: false,
    showHistoricHardwareModeNotice: false,
    openBatchTransactions: false,
    notifications: [],
    selectedItemTab: 'details',
    enableFormSubmitButton: null,
    enableEditButton: null,
    selectedDate: localStorage.getItem('pa-dateRange') ? JSON.parse(localStorage.getItem('pa-dateRange')) : {},
    stopMIDConfirmation: localStorage.getItem('stopMIDConfirmation'),
    selectedEmployees: [],
    selectedReport: null,
    showGiftCardProgIntegration: false,
    showIntegratedAccessKeysIntegration: false,
    showTransactionsWebhookIntegration: false,
    requestNewToken: false,
    enableLink: null,
    modalVisibility: {
      paymentDialogOpen: false,
      manageIntegrations: false,
      partialPaymentDialogOpen: false,
      vtDialogOpen: false,
      vtInvoiceDialogOpen: false,
      invoiceDialogOpen: false,
      ccInvoiceDialogOpen: false,
      apiPasswordDialog: false,
      webHookFormDialog: false,
      jsSdkFormDialog: false,
      twoFactorFailedVerificationDialog: false,
      failedIdentityVerificationDialog: false,
      twoFactorDialog: false,
      twoFactorSetupDialog: false,
      manageRememberedDevices: false,
      apiPasswordResetConfirm: false,
      switchMIDConfirm: false,
      planDetailsFreeDialog: false,
      planDetailsPremiumDialog: false,
      planDetailsPremiumPlusDialog: false,
      planComparisonDialog: false,
      loyaltyAddOnActivateDialog: false,
      loyaltyAddOnDeactivateDialog: false,
      threeAvsErrorDialog: false,
      unableToAddPaymentMethodDialog: false,
      highTransactionAmountDialog: false,
      chargezoomDisconnectDialog: false
    },
    selectedItems: [],
    selectedRows: [],
    showMaintenanceMessage: false,
    viewOrderSummary: false,
    viewRecurringFields: false,
    loaderPercentage: 0,
    uxStorage: localStorage.getItem('pa-ux') ? JSON.parse(localStorage.getItem('pa-ux')) : {},
    persistentUxStorage: localStorage.getItem('pa-pux') ? JSON.parse(localStorage.getItem('pa-pux')) : {},
    midSelected: localStorage.getItem('pa-mid') ? JSON.parse(localStorage.getItem('pa-mid')) : false,
    debtRepaymentError: false,
    headerId: null,
    orderDirection: null,
    charts: {
      itemsTableCheckBox: false,
      itemsyAxesType: '$',
    },
    mainMenuOpen: false,
    agentPortalMsgShown: false,
    salesPartnerNotificationAccepted: LocalStorageUtil.getSalesPartnerNotificationAccepted(),
    compareToPeriod: null,
    terms2FADialog: false,
    deletePaymentMethodDialog: false,
    editPaymentMethodDialog: false,
    addPaymentMethodDialog: false,
    issueCreditDialogAcknowledged: false,
    i18nLanguage: setLanguageKey(localStorage.getItem('i18nLanguage')),
    openPlanListDialog: false,
    openUnableToActivateAccountDialog: false,
    openNorthGatekeeper: false,
    hasDemoAccountTriedPayment: LocalStorageUtil.hasDemoAccountTriedPayment(),
    graphSelector: graphSelectors.total.value,
    showAdvancedSearchMoreFields: false,
    headerHeight: 0,
    achTermsAccepted: false
  },
  action) => {
  const {type, error} = action;

  if (error && type !== '@@redux-form/SET_SUBMIT_FAILED' &&
    type !== actionTypes.addAccountsFailure &&
    type !== actionTypes.twoFactorPhoneCodeFailure &&
    type !== actionTypes.expireTokenFailure &&
    type !== actionTypes.onDropFileFailed &&
    type !== actionTypes.updateBoardingApplicationFailure &&
    error.length > 0 &&
    !error.includes('Expired Card')) {
      const transactionRequestDto = 'TransactionRequestDto.amount: ';
      let highAmountError = null;
      if (error.includes(transactionRequestDto)) {
        highAmountError = error.split(transactionRequestDto)[1].replace('"}', '');
      }
    return Object.assign({}, state, {
      notifications: [...state.notifications, {type: 'Error', message: highAmountError || error}]
    });

  } else if (type === actionTypes.logoutSuccess) {
    return Object.assign({}, state, {
      modalVisibility: {
        paymentDialogOpen: false,
        manageIntegrations: false,
        vtDialogOpen: false,
        invoiceDialogOpen: false,
        ccInvoiceDialogOpen: false,
        partialPaymentDialogOpen: false,
        apiPasswordDialog: false,
        webHookFormDialog: false,
        jsSdkFormDialog: false,
        twoFactorFailedVerificationDialog: false,
        failedIdentityVerificationDialog: false,
        twoFactorDialog: false,
        twoFactorSetupDialog: false,
        manageRememberedDevices: false,
        apiPasswordResetConfirm: false,
        switchMIDConfirm: false,
        loyaltyAddOnActivateDialog: false,
        loyaltyAddOnDeactivateDialog: false,
        threeAvsErrorDialog: false,
        unableToAddPaymentMethodDialog: false,
        highTransactionAmountDialog: false
      },
      selectedReport: null,
      openRefreshTokenDialog: false,
      openAccountSwitchDialog: false,
      openAddAccountDialog: false,
      openReportDialog: false,
      openDepositTransactionsDialog: false,
      openMessageDialog: false,
      openDynamicMessageDialog: false,
      suppressMessageDialog: false,
      messageDialogContent: null,
      dynamicMessageDialogContent: [],
      showMaintenanceMessage: false,
      addAccountDialog: {
        mids: [],
        saved: {},
        errors: {}
      },
      allAccounts: false,
      historicHardwareMode: false,
      showHistoricHardwareModeNotice: false,
      openBatchTransactions: false,
      notifications: [],
      selectedDate: {},
      selectedEmployees: [],
      requestNewToken: false,
      midSelected: false,
      debtRepaymentError: false,
      openInventoryPopover: false,
      issueCreditDialogAcknowledged: false,
      graphSelector: graphSelectors.total.value,
    });
  } else if (type === actionTypes.loginSuccess) {
    return Object.assign({}, state, {
      modalVisibility: {
        paymentDialogOpen: false,
        manageIntegrations: false,
        vtDialogOpen: false,
        invoiceDialogOpen: false,
        ccInvoiceDialogOpen: false,
        partialPaymentDialogOpen: false,
        apiPasswordDialog: false,
        webHookFormDialog: false,
        jsSdkFormDialog: false,
        twoFactorFailedVerificationDialog: false,
        failedIdentityVerificationDialog: false,
        twoFactorDialog: false,
        twoFactorSetupDialog: false,
        manageRememberedDevices: false,
        apiPasswordResetConfirm: false,
        switchMIDConfirm: false,
        highTransactionAmountDialog: false
      },
      selectedReport: null,
      openRefreshTokenDialog: false,
      openAccountSwitchDialog: false,
      openAddAccountDialog: false,
      openReportDialog: false,
      openDepositTransactionsDialog: false,
      openMessageDialog: false,
      openDynamicMessageDialog: false,
      suppressMessageDialog: false,
      messageDialogContent: null,
      addAccountDialog: {
        mids: [],
        saved: {},
        errors: {}
      },
      allAccounts: false,
      historicHardwareMode: false,
      showHistoricHardwareModeNotice: false,
      openBatchTransactions: false,
      notifications: [],
      selectedDate: {},
      selectedEmployees: [],
      requestNewToken: false,
      openInventoryPopover: false,
      graphSelector: graphSelectors.total.value,
    });
  } else {
    switch (action.type) {
      case actionTypes.languageSelected:
        return Object.assign({}, state, {
          i18nLanguage: action.i18nLanguage
        });
      case actionTypes.clearNotification:
        return Object.assign({}, state, {
          notifications: [
            ...state.notifications.slice(0, action.notificationIndex),
            ...state.notifications.slice(action.notificationIndex + 1)]
        });
      case actionTypes.userUpdateLoginInfoSuccess:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            type: 'Success',
            message: 'User Login Credentials Updated'
          }]
        });
      case actionTypes.userExperienceFetching:
        return Object.assign({}, state, {isFetching: action.value});
      case actionTypes.geoTaxFetching:
        return Object.assign({}, state, {geoTaxFetching: action.value});
      case actionTypes.sendInvoiceSuccess:
      case actionTypes.sendReceiptSuccess:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            type: 'Success',
            message: action.response.message ? action.response.message : 'Success'
          }]
        });
      case actionTypes.employeeSendInviteSuccess:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            type: 'Success',
            message: 'Employee Invite Sent'
          }]
        });
      case actionTypes.twoFactorPhoneCodeFailure:
        let errorMessage = null;
        try {
          errorMessage = JSON.parse(action.error);
        } catch {
          if (action.error && _.isString(action.error)) {
            errorMessage = {
              reason: action.error
            };
          } else {
            errorMessage = {};
          }
        }

        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            type: 'Error',
            message: errorMessage.reason ?? 'Can not send SMS to landline phone numbers',
          }]
        });
      case actionTypes.postIdentityVerificationFailure:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            type: 'Error',
            message: action.error || {},
          }]
        });
      case actionTypes.sendNotification:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            title: action.notificationTitle,
            type: action.notificationType,
            message: action.notificationMessage
          }]
        });
      case actionTypes.equipmentOrderSuccess:
        return Object.assign({}, state, {
          enableLink: true,
          notifications: [...state.notifications, {
            type: 'Success',
            message: action.response.message
          }]
        });
      case actionTypes.setHeaderHeight:
        return Object.assign({}, state, {
          headerHeight: action.value
        });
      case actionTypes.setModalVisibilityTypePostPayment:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            vtDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityManageIntegrations:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            manageIntegrations: true
          })
        });
      case actionTypes.setModalVisibilityTypePartialPayment:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            partialPaymentDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeDuplicate:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            vtDuplicateDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeHidden:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility
          })
        });
      case actionTypes.setModalVisibilityTypeVT:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            vtDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeVTInvoice:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            vtInvoiceDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeCardError:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            cardErrorDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeTransaction:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            paymentDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeCashPaidInvoice:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            invoiceDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypeApiPasswordDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            apiPasswordDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeApiPasswordResetConfirmDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            apiPasswordResetConfirm: true,
          })
        });
      case actionTypes.setModalVisibilityTypeSwitchMIDConfirmDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
          })
        });
      case actionTypes.setModalVisibilityTypeWebHookFormDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            webHookFormDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeJsSdkFormDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            jsSdkFormDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeTwoFactorFailedVerificationDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            twoFactorFailedVerificationDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeFailedIdentityVerificationDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            failedIdentityVerificationDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeTwoFactorDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            twoFactorDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeTwoFactorSetupDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            twoFactorSetupDialog: true
          })
        });
        case actionTypes.setModalVisibilityTypeManageRememberedDevicesDialog:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            manageRememberedDevices: true
          })
        });
      case actionTypes.setModalVisibilityTypeInvoicePostPayment:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
              ccInvoiceDialogOpen: true
          })
        });
      case actionTypes.setModalVisibilityTypePlanDetailsFree:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            planDetailsFreeDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypePlanDetailsPremium:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            planDetailsPremiumDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypePlanDetailsPremiumPlus:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            planDetailsPremiumPlusDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypePlanComparison:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            planComparisonDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeThreeAvsError:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            threeAvsErrorDialog: true
          })
        });
      case actionTypes.setModalVisibilityUnableToAddPaymentMethod:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            unableToAddPaymentMethodDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeLoyaltyAddOnActivate:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            loyaltyAddOnActivateDialog: true
          })
        });
      case actionTypes.setModalVisibilityTypeLoyaltyAddOnDeactivate:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            loyaltyAddOnDeactivateDialog: true
          })
        });
      case actionTypes.setModalVisibilityHighTransactionAmount:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            highTransactionAmountDialog: true
          })
        });
      case actionTypes.setModalVisibilityChargezoomDisconnect:
        return Object.assign({}, state, {
          modalVisibility: Object.assign({}, state.modalVisibility, {
            ...action.modalVisibility,
            chargezoomDisconnectDialog: true
          })
        });
      case actionTypes.addMidForUI:
        let addMids = _.clone(_.uniq(state.addAccountDialog.mids));
        addMids.push(action.value);
        return Object.assign({}, state, {
          addAccountDialog: Object.assign({}, state.addAccountDialog, {
            mids: addMids,
            errors: {}
          })
        });
      case actionTypes.removeMidForUI:
        let removeMids = _.clone(_.uniq(state.addAccountDialog.mids));
        if (removeMids.length > action.value) {
          _.pullAt(removeMids, action.value);
        }
        return Object.assign({}, state, {
          addAccountDialog: Object.assign({}, state.addAccountDialog, {
            mids: removeMids,
            errors: {}
          })
        });
      case actionTypes.addMidErrorForUI:
        action.value = messages.addAccountDialog.errorMap[action.value] || messages.addAccountDialog.defaultError;
        return Object.assign({}, state, {
          addAccountDialog: Object.assign({}, state.addAccountDialog, {
            errors: {
              global: action.value
            }
          })
        });
      case actionTypes.addAccountsSuccess:
        // we want to keep the errored MIDs around
        let mids = ((action.response || {}).errors || []).map(error => error.mid);
        return Object.assign({}, state, {
          addAccountDialog: Object.assign({}, state.addAccountDialog, {
            mids: mids,
            errors: {}
          })
        });
      case actionTypes.clearMidMessagesForUI:
        return Object.assign({}, state, {
          addAccountDialog: Object.assign({}, state.addAccountDialog, {
            mids: [],
            errors: {
              global: null
            }
          })
        });
      case actionTypes.setSelectedReport:
        return Object.assign({}, state, {selectedReport: action.value});
      case actionTypes.setOpenAccountSwitchDialog:
        return Object.assign({}, state, {openAccountSwitchDialog: action.value});
      case actionTypes.setOpenRelatedMidsDialog:
        return Object.assign({}, state, {openRelatedMidsDialog: action.value});
      case actionTypes.setOpenAddAccountDialog:
        return Object.assign({}, state, {openAddAccountDialog: action.value});
      case actionTypes.toggleRefreshTokenDialog:
        return Object.assign({}, state, {openRefreshTokenDialog: action.value});
      case actionTypes.setOpenReportsDialog:
        return Object.assign({}, state, {openReportDialog: action.value});
      case actionTypes.setOpenDepositTransactionsDialog:
        return Object.assign({}, state, {openDepositTransactionsDialog: action.value});
      case actionTypes.setOpenMessageDialog:
        return Object.assign({}, state, {openMessageDialog: action.value});
      case actionTypes.suppressAppError:
        return Object.assign({}, state, {suppressMessageDialog: action.value});
      case actionTypes.setMessageDialogContent:
        return Object.assign({}, state, {messageDialogContent: action.value});
      case actionTypes.setOpenDynamicMessageDialog:
        return Object.assign({}, state, {openDynamicMessageDialog: action.value});
      case actionTypes.setDynamicMessageDialogContent:
        return Object.assign({}, state, {dynamicMessageDialogContent: action.value});
      case actionTypes.toggleAllAccounts:
        return Object.assign({}, state, {allAccounts: action.value});
      case actionTypes.toggleHistoricHardwareMode:
        return Object.assign({}, state, {
          historicHardwareMode: !state.historicHardwareMode,
          showHistoricHardwareModeNotice: !state.historicHardwareMode
        });
      case actionTypes.closeHistoricHardwareModeNotice:
        return Object.assign({}, state, {showHistoricHardwareModeNotice: false});
      case actionTypes.changeSelectedMerchantAccount:
        return Object.assign({}, state, {historicHardwareMode: false, showHistoricHardwareModeNotice: false});
      case actionTypes.toggleOpenBatchTransactions:
        return Object.assign({}, state, {openBatchTransactions: action.value});
      case actionTypes.toggleInventoryPopover:
        return Object.assign({}, state, {openInventoryPopover: action.value});
      case actionTypes.setLinkEnabled:
        return Object.assign({}, state, {enableLink: action.value});
      case actionTypes.setSubmitButtonEnabled:
        return Object.assign({}, state, {enableFormSubmitButton: action.value});
      case actionTypes.setEditButtonEnabled:
        return Object.assign({}, state, {enableEditButton: action.value});
      case actionTypes.setSelectedItemTab:
        return Object.assign({}, state, {selectedItemTab: action.value});
      case actionTypes.setSelectedDateRange:
        return Object.assign({}, state, {
          selectedDate: {
            value: action.value,
            dateRange: action.dateRange,
            customStart: action.customStart,
            customEnd: action.customEnd,
          }
        });
      case actionTypes.setStopMIDConfirmation:
        return Object.assign({}, state, {stopMIDConfirmation: true});
      case actionTypes.setSelectedEmployeeFilter:
        return Object.assign({}, state, {selectedEmployees: action.value});
      case actionTypes.refreshTokenRequest:
        return Object.assign({}, state, {requestNewToken: false});
      case actionTypes.tokenValid:
        return Object.assign({}, state, {requestNewToken: false});
      case actionTypes.tokenExpiring:
        return Object.assign({}, state, {requestNewToken: true});
      case actionTypes.viewOrderSummary:
        return Object.assign({}, state, {viewOrderSummary: action.value});
      case actionTypes.toggleShowGiftCardProgIntegration:
        return { ...state, showGiftCardProgIntegration: action.value };
      case actionTypes.toggleShowIntegratedAccessKeysIntegration:
        return Object.assign({}, state, {showIntegratedAccessKeysIntegration: action.value});
      case actionTypes.toggleShowTransactionsWebhookIntegration:
        return Object.assign({}, state, {showTransactionsWebhookIntegration: action.value});
      case actionTypes.viewRecurringFields:
        return Object.assign({}, state, {viewRecurringFields: action.value});
      case actionTypes.sendMaintenanceMessage:
        return Object.assign({}, state, {showMaintenanceMessage: true});
      case actionTypes.setSelectedChartItems:
        return Object.assign({}, state, {
          selectedItems: action.selectedItems,
          selectedRows: action.selectedRows
        });
      case actionTypes.localUserExperienceSet:
        return Object.assign({}, state, {
          uxStorage: localStorage.getItem('pa-ux') ? JSON.parse(localStorage.getItem('pa-ux')) : {}
        });
      case actionTypes.persistentUserExperienceSet:
        return Object.assign({}, state, {
          persistentUxStorage: localStorage.getItem('pa-pux') ? JSON.parse(localStorage.getItem('pa-pux')) : {}
        });
      case actionTypes.vtAccessProcessValuesSet:
        const localStorageData = localStorage.getItem('pa-ux') ? JSON.parse(localStorage.getItem('pa-ux')) : null;

        const decodedData = localStorageData !== null ?
          { vtaccess: JSON.parse(decodeURIComponent(atob(localStorageData.vtaccess))) }
          : {};
        return {
          ...state,
          uxStorage: {...decodedData}
        }
      case actionTypes.vtAccessProcessValuesRemove:
        localStorage.removeItem('pa-ux');
        return {
          ...state,
          uxStorage: {}
        }
      case actionTypes.setLoaderPercentage:
        return Object.assign({}, state, {
          loaderPercentage: action.value
        });
      case actionTypes.setActiveHeader:
        return Object.assign({}, state, {
          headerId: action.headerId,
          orderDirection: action.orderDirection
        });
      case actionTypes.generateTransactionCsvRequest:
        return Object.assign({}, state, {
          isProcessing: true
        });
      case actionTypes.generateTransactionCsvSuccess:
        return Object.assign({}, state, {
          isProcessing: false
        });
      case actionTypes.generateTransactionCsvFailure:
        return Object.assign({}, state, {
          isProcessing: false
        });
      case actionTypes.userProfileRequest:
        return Object.assign({}, state, {
          isFetching: true
        });
      case actionTypes.userProfileSuccess:
        return Object.assign({}, state, {
          isFetching: false
        });
      case actionTypes.userProfileFailure:
        return Object.assign({}, state, {
          isFetching: false
        });
      case actionTypes.onDropFileFailed:
        return {
          ...state,
          notifications: [...state.notifications, {
            type: 'Error',
            message: error
          }],
          enableFormSubmitButton: false
        };
      case actionTypes.updateBoardingApplicationFailure:
        return Object.assign({}, state, {
          notifications: [...state.notifications, {
            title: 'Errors.Activation.SubmitFailed.Title',
            message: 'Errors.Activation.SubmitFailed.Message',
            timeout: 5000,
            type: 'Error'
          }]
        });
      case actionTypes.midSelected:
        return Object.assign({}, state, {
          midSelected: localStorage.getItem('pa-mid') ? JSON.parse(localStorage.getItem('pa-mid')) : false
        });
      case actionTypes.itemDeleteSuccess:
        /* istanbul ignore else */
        if (action.response.message) {
          return Object.assign({}, state, {
            notifications: [...state.notifications, {
              type: 'Error',
              message: action.response.message
            }]
          });
        }
      case actionTypes.creditCardPaymentRequest:
        return Object.assign({}, state, {
          debtRepaymentError: false
        });
      case actionTypes.creditCardPaymentSuccess:
        if (action.response && action.response && action.response.debt_repayment_error) {
          return {...state, debtRepaymentError: true};
        } else {
          return state;
        }
      case actionTypes.toggleAgentPortalMsg:
        return {...state, agentPortalMsgShown: action.value};
      case actionTypes.toggleSalesPartnerNotificationAccepted:
          return {...state, salesPartnerNotificationAccepted: action.value};
      case actionTypes.updateItemsChart:
        return {
          ...state,
          charts: {
            itemsyAxesType: action.value === 'sold' ? '#' : '$',
            itemsTableCheckBox: action.value === 'custom'
          }
        };
      case actionTypes.toggleMainMenu:
        return {...state, mainMenuOpen: !state.mainMenuOpen};
      case actionTypes.closeMainMenu:
      case actionTypes.locationChange:
        return {...state, mainMenuOpen: false};
      case actionTypes.toggleTerms2FADialog:
        return { ...state, terms2FADialog: action.value};
      case actionTypes.setOpenChangePasswordDialog:
        return { ...state, openChangePasswordDialog: true }
      case actionTypes.toggleMerchanConfirmByCSDialog:
        return { ...state, toggleMerchantVerifyByCSDialog: action.value };
      case actionTypes.compareToPeriod:
        return {...state, compareToPeriod: action.value};
      case actionTypes.paymentMethodsToggleDeleteDialog:
        return { ...state, deletePaymentMethodDialog: action.value };
      case actionTypes.paymentMethodsToggleEditDialog:
        return { ...state, editPaymentMethodDialog: action.value };
      case actionTypes.paymentMethodsToggleAddDialog:
        return { ...state, addPaymentMethodDialog: action.value };
      case actionTypes.getQuickBooksStatusSuccess:
        const status = action.response.status;
        /* istanbul ignore else */
        if (status && status.toUpperCase() !== quickbooksSubscribed) {
          return Object.assign({}, state, {
            notifications: [...state.notifications, {
              type: 'Error',
              message: messages.quickbooksAccount.text
            }]
          });
        }
        return state;
      case actionTypes.issueCreditDialogAcknowledged:
        return { ...state, issueCreditDialogAcknowledged: true };
      case actionTypes.planListToggleDialog:
        return Object.assign({}, state, {openPlanListDialog: action.value});
      case actionTypes.unableToActivateAccountToggleDialog:
        return Object.assign({}, state, {openUnableToActivateAccountDialog: action.value});
      case actionTypes.northGatekeeperToggleDialog:
        return Object.assign({}, state, {openNorthGatekeeper: action.value, hasDemoAccountTriedPayment: LocalStorageUtil.hasDemoAccountTriedPayment()});
      case actionTypes.setGraphSelector:
        return Object.assign({}, state,
          {graphSelector: action.value});
      case actionTypes.toggleShowAdvancedSearchMoreFields:
        return Object.assign({}, state,
          {showAdvancedSearchMoreFields: action.value});
      case actionTypes.toggleAchTermsAndConditions:
        return Object.assign({}, state, {
          achTermsAccepted: action.value
        });
      default:
        return state;
    }
  }
};

export default userExperienceReducer;

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
