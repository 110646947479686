/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Box } from '@mui/material';

import { UserAgreementNorthStyles } from '../../jss/UserAgreementNorthStyles'; 

export default class FreeEquipmentPlacementAgreement extends Component {
  render() {
    return (
      <Box className='freeEquipmentPlacementAgreement' sx={UserAgreementNorthStyles}>
        <hr />
        <h2>Free Equipment Placement Agreement</h2>
        <hr />
        <p>This Agreement is a contract between the Merchant and Payanywhere,
          LLC ("Payanywhere").</p>
        <p>NOW THEREFORE, Payanywhere and Merchant agree as follows:</p>
        <p>Upon the approval of Merchant's card processing application,
          Payanywhere shall ship the Payanywhere Equipment selected below to
          Merchant.</p>
        <p>Merchant agrees (i) to use the Equipment only to process
          transactions permitted under its card processing agreement with
          Payanywhere, (ii) to
          be responsible for any damage to the Equipment as a result of its
          misuse or negligence, and (iii) not to reverse engineer, disassemble
          or modify
          the Equipment in any way.</p>
        <p>Merchant agrees that the Equipment is the property of Payanywhere,
          is being licensed to Merchant, and must be returned in good and
          working
          condition within ten (10) days of the termination of Merchant's card
          processing account with Payanywhere. If the Equipment is not
          returned in
          good and working condition within ten (10) days of the termination
          of Merchant's card processing account, then the Merchant will be
          charged the
          Equipment Value indicated below.</p>
        <p>If the Equipment is defective for any reason other than Merchant's
          misuse or negligence, Payanywhere shall replace the defective
          Equipment
          with comparable Equipment, which may be new or refurbished. However,
          Merchant shall first return the defective Equipment to Payanywhere,
          and shall be responsible for all shipping and handling fees for the
          replacement Equipment. Notwithstanding this, Payanywhere shall have
          no
          obligation to replace Equipment free of charge more than one time
          within the first twelve (12) month period. Payanywhere shall have no
          other
          responsibility, obligation or liability with regard to the
          Equipment.</p>
        <p>Payanywhere is not the manufacturer of the Equipment. Accordingly,
          Payanywhere makes no warranties, expressed or implied, and disclaims
          any warranties as to the suitability or merchantability or fitness
          of the Equipment for any particular purpose, for any negligent
          design or
          manufacture of the Equipment, or any software or other computer
          program provided with or to be used in conjunction with the
          Equipment. In
          addition, Payanywhere is not responsible for any liabilities,
          losses, claims, or damages of any kind in any way related to the use
          of the
          Equipment, including but not limited to any damage to the device(s)
          on which the Equipment is used, or to the software, applications or
          data
          downloaded or maintained on such device(s). Merchant may not nor may
          Merchant permit any third party to resell, lease or distribute in
          any way
          the Equipment.</p>
        <p>Merchant agrees to indemnify and hold Payanywhere and its
          subsidiaries harmless from and against any and all liabilities,
          losses, claims,
          damages, disputes, offsets, claims or counterclaims of any kind in
          any way related to the use (or misuse) of the Equipment.</p>
        <p>Payanywhere ships the Equipment with an activated SIM card which
          provides 4G cellular data access. Upon acceptance of the Equipment,
          Merchant agrees to a monthly cellular data access fee of $9.95 after
          10 MB of data has been used each month. $9.95 covers up to 250 MB of
          data usage per month; each additional 250 MB of data usage in a
          month will be assessed an additional fee of $9.95 per month. Your
          first 25
          Transactions processed each month using cellular data are free.
          After 25 free Transactions are processed, each additional
          Transaction
          processed that month using cellular data will incur a $0.10 fee.
          "Transactions" means all credit/debit and cash sales, refunds,
          voids, or preauthorizations processed on the Equipment. If you would
          like to avoid this charge, please contact us at
          custservice@payanywhere.com or
          877.387.5640.</p>
        <p><strong>Merchant has accepted one or more of the following
          Payanywhere Equipment</strong></p>
        <ul>
          <li className='equipmentListItem'>Payanywhere Smart Terminal Mini
            (A60) <br />
            Equipment Value of $795.00 per device <br />
            Monthly Software Fee (per device) $8.95
          </li>
          <li className='equipmentListItem'>Payanywhere Smart Terminal
            (A920) <br />
            Equipment Value of $895.00 per device <br />
            Monthly Software Fee (per device) $9.95
          </li>
          <li className='equipmentListItem'>Payanywhere Smart Flex
            (E600) <br />
            Equipment Value of $1,195.00 per device <br />
            Setup Fee (per device) $49.95 <br />
            Monthly Software Fee (per device) $19.95
          </li>
          <li className='equipmentListItem'>Payanywhere Smart POS (E500) <br />
            Equipment Value of $1,495.00 per device <br />
            Setup Fee (per device) $99.95 <br />
            Monthly Software Fee (per device) $29.95
          </li>
          <li>Payanywhere Smart POS+ (E700) <br />
            Equipment Value of $1,595.00 per device <br />
            Setup Fee (per device) $99.95 <br />
            Monthly Software Fee (per device) $39.95
          </li>
        </ul>
      </Box>
    )
  }
}
