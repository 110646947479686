/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MidSelectWidget from '../MainMenu/MidSelectWidget';
import AccountMenu from '../MainMenu/AccountMenu';
import appBarStyles from '../../jss/appBarStyles';
import MainMenuIconButton from '../shared/MainMenuIconButton';
import withAppData from '../../hoc/withAppData';
import Breadcrumbs from '../shared/Breadcrumbs';
import HeaderLabelLogo from '../shared/HeaderLabelLogo';
import CustomLinearProgress from '../Loading';
import withLayoutBreakPoints from '../util/WithLayoutBreakPoints';
import TopAccessories from './TopAccessories';
import MobileAccessories from './MobileAccessories';
import CsIndicatorBar from './CsIndicatorBar';
import {setHeaderHeight} from '../../actions/userExperienceActions';
import Notice from './Notice';
import _ from 'lodash';


export class AppBarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchAccessory: !!props.visibilityFilter.search || false,
    };

    this.appBarRef = React.createRef();
    this.throttledCheckHeight = _.throttle(this.checkHeight.bind(this), 400);
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledCheckHeight);
    document.body.classList.add('noScroll');
    this.checkHeight();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledCheckHeight);
    document.body.classList.remove('noScroll');
  }


  checkHeight = () => {
    const {dispatch, userExperience } = this.props;

    const currentAppBarHeight = this.appBarRef?.current?.offsetHeight;
    const needToSetHeaderHeight = currentAppBarHeight && currentAppBarHeight !== userExperience.headerHeight;

    if (needToSetHeaderHeight) {
      dispatch(setHeaderHeight(currentAppBarHeight));
    }
  }

  setShowSearchAccessory = () => {
    this.setState({showSearchAccessory: !this.state.showSearchAccessory});
  }

  render() {

    const {
      accountProfile,
      auth,
      dispatch,
      employees,
      history,
      t,
      user,
      userExperience,
      hideMidSwitcher,
      hideUserMenu,
      hideMainMenu,
      hideLabelLogo,
      hideBreadcrumbs,
      hideAccessories,
      title,
      initialSubpage,
      visibilityFilter,
      accessories,
      loading,
      LayoutBreakPoints,
      merchantSettings,
      forceInitialSubpage,
    } = this.props;


    const appBarHeight = this.appBarRef?.current?.offsetHeight || 0;

    const isMobileOrTabletLayout = LayoutBreakPoints && LayoutBreakPoints.isMaxTablet;

    const appBarAccessories = accessories || [];
    const filteredMobileAccessories = appBarAccessories.filter(({hide, showInMobile}) => !hide && Boolean(showInMobile));
    const hasMobileAccessories = Boolean(filteredMobileAccessories?.length);

    const mutiAccessoriesClassName = appBarAccessories.length > 2 ? 'multiAccessories' : '';

    const renderLeftSide= (
      <Box className={`appBarLeftSide ${mutiAccessoriesClassName}`}>
        <Stack alignItems='center'
            direction='row'
            justifyContent='start'
        >

          {!hideMainMenu && (
            <Box className='appBarItem appBarItemMainMenu'>
              <MainMenuIconButton/>
            </Box>
          )}

          {!hideLabelLogo && (
            <Box className='appBarItem appBarItemLabelLogo'>
              <HeaderLabelLogo/>
            </Box>
          )}

          {!hideBreadcrumbs && (
            <Box className='appBarItem appBarItemBreadcrumbs'>
              <Breadcrumbs
                  forceInitialSubpage={forceInitialSubpage}
                  initialSubpage={initialSubpage}
                  t={t}
                  title={title}
                  visibilityFilter={visibilityFilter}
              />
            </Box>
          )}

        </Stack>
      </Box>
    );

    const renderRightSide= (
      <Box className={`appBarRightSide ${mutiAccessoriesClassName}`}>
        <Stack alignItems='center'
            direction='row'
            justifyContent='space-between'
        >

          {!hideAccessories && (
            <Stack alignItems='center'
                className='appBarCustomAccessories'
                direction='row'
                justifyContent='end'
            >
              <TopAccessories
                  appBarAccessories={appBarAccessories}
                  isMobileOrTabletLayout={isMobileOrTabletLayout}
              />
            </Stack>
          )}

          {!hideMidSwitcher && (
            <Box className='appBarItem appBarItemMidSwitcher'>
              <MidSelectWidget
                  auth={auth}
                  dispatch={dispatch}
                  history={history}
                  isTopBar
                  t={t}
                  user={user}
                  userExperience={userExperience}
                  visible
              />
            </Box>
          )}

          {!hideUserMenu && (
            <Box className='appBarItem appBarItemUserMenu'>
              <AccountMenu
                  accountProfile={accountProfile}
                  auth={auth}
                  dispatch={dispatch}
                  employees={employees}
                  history={history}
                  t={t}
                  user={user}
                  userExperience={userExperience}
              />
            </Box>
          )}

        </Stack>
      </Box>
    );

    const loadingComponent = Boolean(loading) && (
      <CustomLinearProgress variant='indeterminate' />
    );

    const historicHardwareModeNoticeBar = userExperience.historicHardwareMode ? (
      <Notice key='historyHardware'
          {...this.props}
      />
    ) : null;

    const isSprBar = auth.isSpr ? (
      <CsIndicatorBar auth={auth}
          key='csIndicator'
          merchantSettings={merchantSettings}
          t={t}
          user={user}
      />
    ) : null

    const barAccessories =[
      historicHardwareModeNoticeBar,
      isSprBar,
    ].filter((accessory) => Boolean(accessory));

    return auth.isAuthenticated ? (
      <>
        <MuiAppBar
            className='appBarCustom'
            color='inherit'
            elevation={0}
            position='fixed'
            ref={this.appBarRef}
            sx={appBarStyles}
        >
          <Stack direction='column'
              position='relative'
          >

            {Boolean(barAccessories.length) && barAccessories}

            <Stack alignItems='center'
                className='appBarCustomContent'
                direction='row'
                justifyContent='space-between'
            >

              {renderLeftSide}

              {renderRightSide}

            </Stack>

            {isMobileOrTabletLayout && (
              <MobileAccessories
                  filteredMobileAccessories={filteredMobileAccessories}
                  hasMobileAccessories={hasMobileAccessories}
                  setShowSearchAccessory={this.setShowSearchAccessory}
                  showSearchAccessory={this.state.showSearchAccessory}
              />
            )}

            {loadingComponent}

          </Stack>
        </MuiAppBar>

        <Box
            className='appBarCustomSpacer'
            marginTop={`${appBarHeight}px`}
        />

      </>
    ) : null
  }
}

const AppBar = withAppData(withLayoutBreakPoints(AppBarComponent));

AppBar.displayName = 'AppBar';

export default AppBar;
