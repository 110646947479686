import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import {lastLoginActivityStyles} from '../../jss/lastLoginActivityStyles';

const LastLoginActivity = ({ lastLogin, t }) => {

  const lastActivity = lastLogin
    ? moment(lastLogin).local().format('MM/DD/YYYY h:mm a')
    : 'N/A';

  return (
    <Box className='lastLoginActivity' sx={lastLoginActivityStyles}>
      <div className='formHeading'>{t('LastLoginActivityHeader')}</div>
      <div className='lastLoginActivityContainer'>
        {lastLogin && <span className='lastLoginStatus'/>}
        {lastActivity}
      </div>
    </Box>
  )
}

export default LastLoginActivity;
