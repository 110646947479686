/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';

import { submit, reset } from 'redux-form';

import Avatar from '@mui/material/Avatar';
import { Trans } from 'react-i18next';

import MarkAsPaidForm from './MarkAsPaidForm';
import AuditActivityList from './AuditActivityList';

import DateUtil from '../util/DateUtil';
import IconUtil from '../util/IconUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import IconUtils from '../../components/util/IconUtil';
import PaymentUtil from '../../components/util/PaymentUtil';
import InvoiceUtil, {
  InvoicesStatus,
  InvoiceFilterTypes,
  InvoiceTypeIcons,
  CreditCardProcessors, EditPaymentDuration, invoicesColorHashes
} from '../util/InvoiceUtil';

import Modal from '../shared/Modal';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';

import {patchInvoice, setInvoice} from '../../actions/invoicesActions';
import { setModalVisibility } from '../../actions/userExperienceActions';
import { setTransactionReceipt } from '../../actions/transactionsActions';

import messages from '../../constants/messages';
import routes from '../../constants/routes';
import Select from '../shared/Select';
import {MenuItem} from '@mui/material';
import PaymentMethodSummary from '../shared/enhancedInvoices/PaymentMethodSummary';
import CardOnFileForm from '../shared/enhancedInvoices/CardOnFileForm';
import EditPaymentAmountForm from './EditPaymentAmountForm';
import numeral from 'numeral';
import HighAmountModal from './HighAmountModal';
import LowAmountModal from './LowAmountModal';
import {roundToTwoDecimals} from '../util/CommonUtil';
import {setCustomer} from '../../actions/customerActions';
import UserUtil from '../util/UserUtil';
import withTranslate from '../../hoc/withTranslate';
import LabelUtil from '../util/LabelUtil';
import applicationConstants from '../../constants/applicationConstants';

export const ModalTypes = {
  SEND_INVOICE: 'sendInvoice',
  RESEND_INVOICE: 'resendInvoice',
  MARK_AS_PAID: 'markAsPaid',
  EDIT_PAYMENT_AMOUNT: 'editPaymentAmount',
  CANCEL_INVOICE: 'cancelInvoice',
  CANCEL_SERIES: 'cancelSeries',
  VIEW_ALL_ACTIVITIES: 'viewAllActivities'
}

const { invoiceDetailModal } = messages;
const { formatDate: formatStatusDate, getMomentDateFromUnix } = DateUtil;
const { formatInvoiceCurrency: formatCurrency, formatFrequencyInvoice, getQuantityInvoices, getOverdueDays } = FormatTextUtil;

export class InvoiceTypeDetailComponent extends Component {

  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      openModal: false,
      modalType: null,
      openEditPayment: false,
      openAddPayment: false,
      openWithoutPayment: false,
      selectedPaymentMethodToShow: null,
      selectedPaymentMethod: 'save',
      showLowAmountWarningModal: false,
      editPaymentAmountValues: {}
    }

    this.modalAction = {
      [ModalTypes.SEND_INVOICE]: props.sendInvoice,
      [ModalTypes.RESEND_INVOICE]: this.resendInvoice,
      [ModalTypes.MARK_AS_PAID]: this.submitMarkAsPaidForm,
      [ModalTypes.EDIT_PAYMENT_AMOUNT]: this.submitEditPaymentAmount,
      [ModalTypes.CANCEL_SERIES]: this.cancelSeries,
      [ModalTypes.CANCEL_INVOICE]: this.cancelInvoice,
      [ModalTypes.VIEW_ALL_ACTIVITIES]: this.handleModalClose
    }

    this.modalContent = {
      [ModalTypes.MARK_AS_PAID]: (
        <MarkAsPaidForm
            merchantSettings={props.merchantSettings}
            onSubmit={this.markAsPaid}
            selectedInvoice={props.invoice}
            cashPrice={this.calculateCashPriceCart().total_amt}
            t={t}
        />
      ),
      [ModalTypes.EDIT_PAYMENT_AMOUNT]: (
        <EditPaymentAmountForm
          onSubmit={this.handleEditPaymentAmount}
          selectedInvoice={props.invoice}
          t={t}
        />
      ),
      [ModalTypes.VIEW_ALL_ACTIVITIES]: (
        <AuditActivityList
          invoice={props.invoice}
          t={t}
        />)
    }
  }

  componentWillUnmount() {
    const { customers, dispatch } = this.props;

    dispatch(reset('cardOnFileForm'));

    if (customers.selectedCustomer){
      dispatch(setCustomer({}));
    }
  }

  getActions = (invoice) => {
    const { statusList, failure_array: failureArray } = invoice;
    const labelColor = LabelUtil.getLabelColor();

    const { t } = this.props;
    const actions = [];
    const hasBeenPaid = InvoiceUtil.checkPaidActivity(invoice);

    if (statusList.isSeries) {
      if (failureArray?.length > 0) {
        actions.push(
          {
            icon: IconUtils.getIcon('Warning', '#EB3223'),
            customComponent: (
              <div className='failedInvoices'>
                {t('FailedPayments', {number: failureArray.length})}
                <div className='warn'>{t('ContactCustomerEditPaymentMethod')}</div>
              </div>),
            text: t('ViewFailedInvoices'),
            onClick: this.viewFailedInvoicesInSeries
          });
      }
      actions.push(
        {
          icon: IconUtil.getIcon('MainMenu_InvoicesIcon', labelColor),
          text: t('ViewInvoicesSeries'),
          onClick: this.viewInvoicesInSeries
        }
      );
      if (!statusList.isCanceled) {
        if (!hasBeenPaid) {
          if (!statusList.isZeroAmountInvoice) {
            actions.push(
              {
                icon: IconUtil.getIcon('EditIcon', labelColor),
                text: t('EditPaymentAmount'),
                onClick: this.handleModalChange.bind(this, ModalTypes.EDIT_PAYMENT_AMOUNT)
              });
          }
        }
        actions.push(
          {
            icon: IconUtil.getIcon('CancelIcon', labelColor),
            text: t('InvoiceDetailModal.CancelSeries.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.CANCEL_SERIES)
          }
        );
      }
    } else {
      if (statusList.isInvoiceFromSeries) {
        actions.push({
          icon: IconUtil.getIcon('RecurringIcon', labelColor),
          text: t('ManageSeries'),
          onClick: this.manageSeries
        });
      }

      if (statusList.isPaid && !statusList.isZeroAmountInvoice) {
        actions.push({
          icon: IconUtil.getIcon('TransactionIcon', labelColor),
          text: t('ViewTransactionDetails'),
          onClick: this.viewTransactionDetail
        })
      }

      if (!statusList.isScheduled && !statusList.isZeroAmountInvoice && (statusList.isOverdue || statusList.isInvoiceFromSeries || statusList.isUnpaid || statusList.isFailed) ) {
        actions.push(
          { icon: IconUtil.getIcon('SendIcon', labelColor),
            text: t('InvoiceDetailModal.ResendInvoice.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.RESEND_INVOICE)
          })
      }

      if (statusList.isDraft) {
        actions.push(
          {
            icon: IconUtil.getIcon('SendIcon', labelColor),
            text: t('InvoiceDetailModal.SendInvoice.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.SEND_INVOICE)
          },
          {
            icon: IconUtil.getIcon('DeleteIcon', labelColor),
            text: t('InvoiceDetailModal.CancelInvoice.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.CANCEL_INVOICE)
          },
        )
      }

      if (statusList.isOverdue || statusList.isUnpaid || statusList.isFailed) {
        actions.push(
          { icon: IconUtil.getIcon('MarkPaidIcon', labelColor),
            text: t('InvoiceDetailModal.MarkAsPaid.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.MARK_AS_PAID)
          })
      }

      if (!statusList.isCanceled && (statusList.isUnpaid || statusList.isOverdue || statusList.isFailed || statusList.isScheduled)) {
        if (!hasBeenPaid) {
          if (!statusList.isZeroAmountInvoice) {
            actions.push(
              {
                icon: IconUtil.getIcon('EditIcon', labelColor),
                text: t('EditPaymentAmount'),
                onClick: this.handleModalChange.bind(this, ModalTypes.EDIT_PAYMENT_AMOUNT)
              }
            );
          }
        }

        actions.push(
          {
            icon: IconUtil.getIcon('CancelIcon', labelColor),
            text: t('InvoiceDetailModal.CancelInvoice.Title'),
            onClick: this.handleModalChange.bind(this, ModalTypes.CANCEL_INVOICE)
          }
        );
      }
    }

    return actions;
  }

  getPurchases = (invoice) => {
    return invoice?.item_names?.map((name, index) => {
      const price = invoice.price_total_amount?.[index] > 0 ? invoice.price_total_amount[index] : invoice.item_unit_price[index] * invoice.item_quantity[index];
      const priceName = invoice.item_price_names?.[index];
      let totalModifiersAmt = 0;
      let mappedModifiers = [];
      if (invoice.item_modifiers?.[index]?.length > 0) {
        mappedModifiers = invoice.item_modifiers.map(InvoiceUtil.mapInvoiceModifiers);
        totalModifiersAmt = mappedModifiers[index].reduce((partialSum, amount) => {

          const modifierTotal = amount.total_amt > 0 ? amount.total_amt : Number(amount.price) * invoice.item_quantity[index];

          return partialSum + Number(modifierTotal);
        }, 0)
      }

      const discountAmt = invoice.item_discount_total_amt?.[index] > 0
        ? invoice.item_discount_total_amt[index]
        : invoice.item_discount_rate[index] > 0
          ? (price + totalModifiersAmt) * (invoice.item_discount_rate[index] / 100)
          : invoice.item_discount_amt[index];

      return {
        name,
        letters: name.charAt()?.toUpperCase(),
        price,
        priceName,
        amount: invoice.item_quantity[index],
        modifiers: mappedModifiers[index],
        discountRate: invoice.item_discount_rate[index] || 0,
        discountAmt: discountAmt,
        totalModifiersAmt: totalModifiersAmt
      };
    }) || [];
  }

  getStatusInfo = (invoice) => {
    const { status, statusList, series  } = invoice;

    let description = '';

    let label = invoice.statusList.isSeries ? InvoicesStatus.SERIES : status;
    label = FormatTextUtil.upperCaseFirstLowerCaseRest(label);

    let icon =  IconUtil.getIcon(
      invoice.statusList.isSeries
        ? InvoiceTypeIcons[InvoicesStatus.SERIES]
        : InvoiceTypeIcons[status]
    );

    const isAutoPay = Boolean(invoice.statusList.isInvoiceFromSeries && series && series.auto_pay);
    const date = formatStatusDate(invoice.due_date);

    if (statusList.isUnpaid && !isAutoPay) {
      description = (
        <Trans i18nKey='DueDate'>
          <span>Due</span> {{date}}
        </Trans>);
    }

    if (statusList.isUnpaid && isAutoPay) {
      description = (
        <Trans i18nKey='ScheduleDate'>
          <span>Scheduled</span> to autopay on {{date}}
        </Trans>
      );
    }

    if (statusList.isScheduled) {
      const sendDate = formatStatusDate(getMomentDateFromUnix(invoice.send_date));
      description = (
        <Trans i18nKey={'WillSendDate'}>
          <span>Will</span> send on {{date: sendDate}}
        </Trans>
      );
    }

    if (statusList.isPaid && !statusList.isZeroAmountInvoice) {
      description = formatStatusDate(invoice.paid_date);
    }

    if (statusList.isOverdue) {
      label = `${getOverdueDays(invoice.due_date)}`;
      description = (
        <Trans i18nKey={'OverDueDate'}>
          <span>Contact</span> customer or edit payment method.
        </Trans>
      );
    }

    if (statusList.isFailed) {
      icon = IconUtil.getIcon(InvoiceTypeIcons[InvoicesStatus.FAILED], invoicesColorHashes[InvoicesStatus.FAILED]);
      let failureDate = invoice && invoice.failure_array?.[0]?.date;
      failureDate = formatStatusDate(failureDate);
      description = (
        <Trans i18nKey='PaymentFailedDescription'>
          <span>Payment</span> attempted {{date: failureDate}}<br/><span>Contact</span> customer or edit payment method.
        </Trans>
      );
    }

    return { icon, label, description };
  }

  getCustomerInfo = (invoice) => {

    const hasPACustomer = (invoice?.pa_customer && Object.keys(invoice.pa_customer).length > 0);

    const customer = {
      first_name: invoice?.pa_customer?.first_name || invoice.first_name,
      last_name: invoice?.pa_customer?.last_name || invoice.last_name,
      email: invoice?.pa_customer?.email || invoice.email_addresses || '',
      phone: invoice?.pa_customer?.phone || invoice.phone_number
    };

    const isEmailNotEmpty =  customer.email && customer.email.length && typeof customer.email[0] === 'string';

    return customer.first_name && customer.last_name ? {
      fullName: `${customer.first_name} ${customer.last_name}`,
      email: hasPACustomer ? customer.email : (isEmailNotEmpty && customer.email[0] || ''),
      phone: (customer.phone && FormatTextUtil.formatPhoneNumber(customer.phone) ) || ''
    } : null;

  }

  getCreditCardInfo = (invoice) => {

    let series = {};

    if (invoice.statusList.isSeries) {
      series = invoice;
    } else if (invoice.statusList.isInvoiceFromSeries) {
      series = invoice.series;
    }

    const {
      payment_method_is_active,
      payment_method_network,
      payment_method_last4,
      payment_method_exp_month,
      payment_method_exp_year
    } = series;

    const processor = payment_method_network;
    const processorCode = processor && CreditCardProcessors[processor];

    return payment_method_is_active && processor && processorCode ? {
      processorCode,
      expireAt: `${payment_method_exp_month}/${payment_method_exp_year}`,
      lastDigits: payment_method_last4,
      processor,
    } : null;

  }

  handleModalChange = (modalType) => {
    this.setState({ modalType, openModal: true });
  }

  handleModalClose = () => {
    this.setState({ openModal: false });
  }

  getModalProps = (modalType) => {
    const { t, selectedFilter } = this.props;
    let subtitle;

    if (!modalType) return {
      title: t('Not supported yet!'),
      onConfirm: this.handleModalClose
    };

    const isSeriesSection = selectedFilter === InvoiceFilterTypes.SERIES;
    const content = this.modalContent[modalType];
    let extraModalProps = content ? { children: content } : {};

    if (invoiceDetailModal[modalType]?.children && extraModalProps.children) {
      extraModalProps.children = <>{extraModalProps.children}{modalType !== 'viewAllActivities' ? t(invoiceDetailModal[modalType].children) : null}</>;
    }

    const cancelText = modalType !== 'viewAllActivities' && t(invoiceDetailModal[modalType].cancelText);

    if (modalType === 'editPaymentAmount') {
      subtitle = t(invoiceDetailModal[modalType].subtitle);
    }

    const invoiceDetails = {
      ...invoiceDetailModal[modalType],
      title: t(invoiceDetailModal[modalType].title, {section: isSeriesSection ? t('Series') : t('Invoice')}),
      subtitle,
      cancelText,
      confirmText: t(invoiceDetailModal[modalType].confirmText),
      children: t(invoiceDetailModal[modalType].children),
      ...(!cancelText ? { hideCancelButton: true } : {})
    };

    return {
      ...invoiceDetails,
      ...extraModalProps,
      onConfirm: this.modalAction[modalType]
    };

  }

  resendInvoice = async () => {

    const { user, invoice, dispatch, handleCloseDetail, handleLoadData } = this.props;

    this.handleModalClose();

    await InvoiceUtil.sendInvoice({ user, dispatch }, invoice);

    handleCloseDetail();
    await handleLoadData();

  }

  submitMarkAsPaidForm = () => {
    this.props.dispatch(submit('markAsPaidForm'));
  }

  submitEditPaymentAmount = () => {
    this.props.dispatch(submit('editPaymentAmountForm'));
  }

  getRewardFromInvoice = () => {
    const { reward_code, reward_type, reward_amount } = this.props?.invoice?.loyalty_info || {};

    return reward_code && reward_type && reward_amount ? {
      name: 'Loyalty Reward',
      code: reward_code,
      type: reward_type,
      amount: reward_amount
    } : undefined;
  }

  calculateCashPriceCart = () => {
    const { invoice, items } = this.props;

    const rewardCode = this.getRewardFromInvoice();
    const itemizedCart = InvoiceUtil.initialItemizedCartObjectFromInvoice(invoice, items);

    return InvoiceUtil.recalculateCart(itemizedCart, undefined, rewardCode);
  }

  markAsPaid = async ({ amountSelected, comment }) => {
    const { user, invoice, dispatch, handleCloseDetail } = this.props;

    invoice['comment'] = comment;
    invoice['cashAmountSelected'] = amountSelected;

    if (amountSelected) {
      const itemizedCart = this.calculateCashPriceCart();
      itemizedCart['is_one_time_use'] = invoice.is_one_time_use;
      invoice['itemizedCart'] = itemizedCart;
      invoice['tax_amt'] = itemizedCart.tax_amt;
      invoice['total_amt'] = itemizedCart.total_amt;
      invoice['rewardCode'] = this.getRewardFromInvoice();

      if (invoice['rewardCode']) {
        invoice['rewardCode']['amount'] = itemizedCart.loyalty_discount_amt;
        invoice['rewardCode']['type'] = 'loyalty';
      }
    }

    try {

      this.handleModalClose();

      const resp = await PaymentUtil.processInvoiceMarkAsPaid(invoice, { user, dispatch });

      handleCloseDetail();

      dispatch(setModalVisibility('cashPaidInvoice', { ...resp }));

      dispatch(setTransactionReceipt({
        id: resp?.response?.transaction_id?.split('_')?.[1],
        unique_id: resp?.response?.transaction_id,
        type: 'Cash Sale',
        subTransactions: []
      }, null));

    } catch(error) {

      handleCloseDetail();

      throw error;
    }

  }

  sendEditInvoice = async (user, invoice, payload, ofType) => {
    const { dispatch, handleCloseDetail, handleLoadData } = this.props;

    this.handleModalClose();

    await dispatch(patchInvoice(user, invoice, payload));

    handleCloseDetail();

    await handleLoadData({ ...( ofType ? { ofType } : {} ) });
  }

  cancelSeries = async () => {
    const { user, invoice } = this.props;

    const payload = { type: 'series', active: false };

    await this.sendEditInvoice(user, invoice, payload, InvoiceFilterTypes.SERIES);
  }

  cancelInvoice = async () => {
    const { user, invoice } = this.props;

    const payload = { type: 'invoice', invoice: { is_active: false } };

    await this.sendEditInvoice(user, invoice, payload);
  }

  manageSeries = async () => {

    const { invoice, handleCloseDetail, handleLoadData } = this.props;

    const {
      series_details_id: seriesId,
    } = invoice;

    const ofType = invoice.series.active ? InvoiceFilterTypes.SERIES : InvoiceFilterTypes.CANCELED;

    this.handleModalClose();
    handleCloseDetail(false);

    await handleLoadData({ ofType, seriesId, isSeries: true });

  }

  viewCustomerProfile = () => {
    const { history, invoice } = this.props;

    const customerKey = invoice.pa_customer_id ? 'pa_customer_id' : 'customer_id';
    history.push({
      pathname: routes.business.customers.replace('/', ''),
      state: {
        openCustomerDetail: true,
        [customerKey]: invoice.pa_customer_id || invoice.customer_id
      }
    });
  }

  viewInvoicesInSeries = async () => {

    const { invoice, handleLoadData, history, handleCloseDetail } = this.props;

    this.handleModalClose();
    handleCloseDetail(false);

    history.replace({ state: {} });

    await handleLoadData({ seriesId: invoice.id, ofType: InvoiceFilterTypes.INVOICES, isSeries: true, shouldOpenDetails: false });

  }

  viewFailedInvoicesInSeries = async () => {

    const { invoice, handleCloseDetail, handleLoadData } = this.props;

    this.handleModalClose();
    handleCloseDetail();

    await handleLoadData({ seriesId: invoice.id, ofType: InvoiceFilterTypes.FAILED, isSeries: true });

  }

  viewTransactionDetail = () => {
    const { invoice, history } = this.props;
    const appRoutePrefix = globalApplicationLabel.path;
    const historyParam = invoice.statusList.isSeries ? '?seriesId=' : '?invoiceId=';
    const transactionHistoryLink = `${appRoutePrefix}${routes.activity.root}${routes.activity.transactions}${historyParam}${this.props.invoice.id}`;

    history.push(transactionHistoryLink);
  }

  viewAllActivities = () => {
    this.handleModalChange(ModalTypes.VIEW_ALL_ACTIVITIES);
  }

  closeHighAmountDialog = () => {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  };

  openHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  closeLowAmountDialog = () => {
    this.setState({ showLowAmountWarningModal: false })
  };

  handleEditPaymentAmount = async (values) => {
    const amount = numeral(values.amount).value();
    if (amount > applicationConstants.highTransactionLimitAmount) {
      this.openHighAmountDialog();
      this.setState({ editPaymentAmountValues: values });
    } else if (amount === 0) {
      this.setState({ showLowAmountWarningModal: true, editPaymentAmountValues: values });
    } else {
      await this.editPaymentAmount(values);
    }
  }

  editPaymentAmount = async (values = this.state.editPaymentAmountValues) => {
    const { user, invoice, dispatch, handleCloseDetail, handleLoadData } = this.props;

    const amount = numeral(values.amount).value();

    const payload = {
      type: 'invoice',
      invoice: {
        sub_total_amount: amount,
        tax_rate: 0,
        tax_amount: 0,
        amount: amount
      },
      activity_log_comments: { 'PAYMENT_AMOUNT_CHANGED': values.comment }
    };

    const { isSeries } = invoice.statusList;
    const nextInvoiceOnly = isSeries && values.duration === EditPaymentDuration.NEXT_ONLY;

    if (isSeries) { payload.type = 'series'; }
    if (nextInvoiceOnly) { payload.next_only = true; }

    this.closeHighAmountDialog();
    this.closeLowAmountDialog();
    this.handleModalClose();

    await dispatch(patchInvoice(user, invoice, payload));

    handleCloseDetail();

    await handleLoadData({ ofType: invoice.isSeries ? InvoiceFilterTypes.SERIES : InvoiceFilterTypes.INVOICES });
  }

  editCreditCardPayment = () => {
    this.setState({
      openAddPayment: false, openWithoutPayment: false,
      selectedPaymentMethodToShow: null, selectedPaymentMethod: 'save',
      openEditPayment: !this.state.openEditPayment
    });
  }

  saveCreditCardPayment = async () => {
    const {selectedPaymentMethod, selectedPaymentMethodToShow} = this.state;

    if (selectedPaymentMethod === 'new') {
      this.props.dispatch(submit('cardOnFileForm'));
      return;
    }

    const { user, invoice } = this.props;
    const { isInvoiceFromSeries } = invoice?.statusList;
    const defaultPaymentMethod = this.getDefaultPaymentMethod();
    const paymentMethodToSave = selectedPaymentMethodToShow || defaultPaymentMethod || {};

    const payload = { type: 'series' };

    if (paymentMethodToSave?.id && selectedPaymentMethod === 'save') {
      payload.payment_method = paymentMethodToSave?.id;
    } else if (selectedPaymentMethod === 'noPayment') {
      payload.payment_method = null;
      payload.auto_pay = false;
    }

    const invoiceToChange = isInvoiceFromSeries ? invoice.series : invoice;
    await this.sendEditInvoice(user, invoiceToChange, payload);

    this.editCreditCardPayment();
  }

  submitCardOnFileForm = async (values) => {
    const { user, invoice, dispatch } = this.props;
    const { isInvoiceFromSeries } = invoice?.statusList;

    const payload = { type: 'series' };

    const [month, year] = DateUtil.getCreditCardExpirationDates(values.edate);
    const number = FormatTextUtil.parseCardNumberDigits(values.cdigits);

    payload.save_payment_method = true;
    payload.card = {
      name_on_card: values.cardHolderName,
      number,
      cvv: values.cvv,
      exp_month: month,
      exp_year: year,
      input_type: 'KEYED',
      address: values.streetNumber,
      zip: values.cardZip || values.zipPlus4
    };

    const invoiceToChange = isInvoiceFromSeries ? invoice.series : invoice;
    await this.sendEditInvoice(user, invoiceToChange, payload);

    dispatch(reset('cardOnFileForm'));

    this.editCreditCardPayment();
  };

  handleEditPaymentChange = (event) => {
    const { paymentMethods } = this.props;

    const value = event.target.value;

    let stateObj = {
      openWithoutPayment: false,
      openAddPayment: false,
      selectedPaymentMethodToShow: null,
      selectedPaymentMethod: value
    }

    switch (value) {
      case 'new':
        stateObj.openAddPayment = true;
        break;
      case 'noPayment':
        stateObj.openWithoutPayment = true;
        break;
      case 'save':
      default:
        const selectedPaymentMethod = paymentMethods
          ?.find((paymentMethod) => paymentMethod.id === value);

        stateObj.selectedPaymentMethod = 'save';
        stateObj.selectedPaymentMethodToShow = selectedPaymentMethod;
        break;
    }

    this.setState(stateObj);
  }

  getDefaultPaymentMethod() {
    const { paymentMethods } = this.props;

    return paymentMethods?.find((p) => p.is_default) || paymentMethods?.[0] || null;
  };

  render() {
    const {
      invoice, merchantSettings, user, t, paymentMethods, isCardOnFileValid
    } = this.props;

    const {
      openModal, modalType, openEditPayment, openAddPayment,
      openWithoutPayment, selectedPaymentMethod, selectedPaymentMethodToShow
    } = this.state;

    const modalProps = this.getModalProps(modalType);

    const { statusList } = invoice;

    const isAVSError = !merchantSettings?.merchantSettings?.ignore_avs_failure && (invoice?.failure_array?.length > 2);

    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;

    const
      taxes = formatCurrency(invoice.tax_amt),
      tip = formatCurrency(invoice.tip_amount),
      total = formatCurrency(invoice.total_amt),
      subtotal = formatCurrency(invoice.sub_total_amt);

    const
      itemHasDiscounts = invoice?.item_discount_amt?.length || invoice?.item_discount_rate?.length,
      hasItemizedDiscounts = !!invoice?.receipt_discount_name?.length;

    const purchases = this.getPurchases(invoice);
    const receiptDiscounts = InvoiceUtil.getReceiptDiscounts(invoice);
    const customer = this.getCustomerInfo(invoice);
    const creditCard = this.getCreditCardInfo(invoice);
    const actions = this.getActions(invoice);
    const statusInfo = this.getStatusInfo(invoice);
    const defaultPaymentMethod = this.getDefaultPaymentMethod();
    const currentPaymentMethod = selectedPaymentMethodToShow || defaultPaymentMethod;

    let quantity, frequency, frequencyMessage;

    if (statusList.isInvoiceFromSeries && invoice.series) {
      frequencyMessage = formatFrequencyInvoice(invoice.series.start_date, invoice.series.frequency);
      quantity = getQuantityInvoices(invoice.series.start_date, invoice.series.end_date, invoice.series.frequency, invoice.series.end_amount_payments)
    }

    if (statusList.isSeries) {
      frequencyMessage = formatFrequencyInvoice(invoice.start_date, invoice.frequency);
      quantity = getQuantityInvoices(invoice.start_date, invoice.end_date, invoice.frequency)
    }

    if (frequencyMessage) {
      const { repeats, consecutiveText, length, period, preposition, duration } = frequencyMessage;
      const consecutiveLabel = !length ? t(consecutiveText) : `${t(consecutiveText)} ${length} ${t(period)}`;

      frequency = `${t(repeats)} ${consecutiveLabel} ${t(preposition)} ${duration}`;
    }

    const isHighAmountTransactionDialogOpen = this.props.userExperience?.modalVisibility?.highTransactionAmountDialog;

    const highAmountModal = (
      <HighAmountModal
        onClose={this.closeHighAmountDialog}
        open={isHighAmountTransactionDialogOpen}
        t={t}
      />
    );

    const lowAmountModal = (
      <LowAmountModal
        onClose={this.closeLowAmountDialog}
        onConfirm={() => this.editPaymentAmount()}
        open={this.state.showLowAmountWarningModal}
        t={t}
      />
    );

    const editPaymentModalConfirmDisabled =
      (openAddPayment && !isCardOnFileValid) ||
      (!openWithoutPayment && !currentPaymentMethod) ||
      (!openWithoutPayment && !openAddPayment && currentPaymentMethod?.last4 === creditCard?.lastDigits);

    const editPaymentModal = (
      <Modal
        cancelText={t('Cancel')}
        confirmText={t('Confirm')}
        contentClassName='invoiceDetail'
        maxWidth='md'
        onClose={this.editCreditCardPayment}
        onConfirm={this.saveCreditCardPayment}
        isConfirmDisabled={editPaymentModalConfirmDisabled}
        open={openEditPayment}
        title={t('EditPaymentMethod')}
      >
        <div className='editPayment'>
          <p>{t('ReplaceExistingPaymentMethod')}</p>
          <div>
            <Select
              value={selectedPaymentMethod}
              label={t('PaymentMethod')}
              onChange={this.handleEditPaymentChange}
            >
              <MenuItem value='save'>{t('UseSavedPaymentMethod')}</MenuItem>

              {paymentMethods?.map((creditCard) =>
                <MenuItem value={creditCard.id} key={`paymentMethod${creditCard.last4}`}>
                  <PaymentMethodSummary paymentMethod={creditCard} showEditButton={false} t={t} />
                </MenuItem>
              )}

              <MenuItem value='new'>{t('AddPaymentMethod')}</MenuItem>

              <MenuItem value='noPayment'>
                {t('SendWithoutPaymentMethod')}
              </MenuItem>
            </Select>
          </div>

          { !openAddPayment && !openWithoutPayment && currentPaymentMethod && (
            <PaymentMethodSummary paymentMethod={currentPaymentMethod} showEditButton={false} t={t} />
          )}

          { openAddPayment && <CardOnFileForm onSubmit={this.submitCardOnFileForm} achEnabled={achEnabled}/>}

          {(openAddPayment || openWithoutPayment) && (
            <div className='notice'
              style={{borderRadius: '20px', backgroundColor: '#F6F8FA'}}
            >
              <div className='icon'>
                {IconUtil.getIcon('LightBulbIcon', 'gray')}
              </div>
              <div className='text'>
                {openWithoutPayment ? t('Autopay') : t('WrittenPermission')}
              </div>
              </div>
          )}
        </div>
      </Modal>
    );

    const hasEndDate = invoice.series ? Boolean(invoice.series.end_date) : false;
    const invoiceQuantity = hasEndDate ? quantity : t('NoEndDate');
    const currentInvoice = statusList.isInvoiceFromSeries && invoice.series &&
      FormatTextUtil.getCurrentInvoiceFromSeries(invoice.series.start_date, invoice.series.frequency, invoice.due_date);

    const dueDate = invoice?.due_date && DateUtil.extractUTCDate(invoice.due_date);

    const isMbp = UserUtil.userType(user) === 'MBP';

    return (
      <div className='invoiceDetail'>
        {statusList.isSeries && (
          <>
            <div className='invoiceSeries'>
              {IconUtil.getIcon(InvoiceTypeIcons[InvoicesStatus.SERIES])}
              <div className='title'>{t('SeriesDetails')}</div>
            </div>
            <div className='summary'>
            <div className='invoiceInfo'>
              <h2 className='marginBottom'>{t('InvoiceTitle', {name: invoice.name, total})}</h2>
              <div>
                {quantity && <div className='text'>{quantity}</div>}
                {frequency && <div className='text frequency'>{frequency}</div>}
                {invoice.grace_period && <div className='text'>{`${t('DueIn', {date: invoice.grace_period.replace('days', t('Days'))})}`}</div>}
                {invoice.start_date && <div className='text'>{t('StartDate', {date: DateUtil.extractDate(invoice.start_date)})}</div>}

                <div className={`text ${creditCard ? 'marginBottom' : ''}`}>
                  {t('EndDate', { date: invoice.end_date ? DateUtil.extractDate(invoice.end_date) : t('Never')})}
                </div>
                {creditCard && (
                  <div className='paymentMethod'>
                    <h4>{t('SavedPaymentMethod')}</h4>
                    <div className='paymentMethodItem'>
                      {creditCard?.processorCode && IconUtil.creditCardIcon(creditCard.processorCode)}
                      <div className='content'>
                        <div>
                          <div className='creditCardNumber'>{`•••• •••• •••• ${creditCard.lastDigits}`}</div>
                          <div className='expireAt'>{t('ExpiresCard', {card: creditCard.expireAt})}</div>
                        </div>
                        <a onClick={this.editCreditCardPayment}>{t('Edit')}</a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          </>
        )}
        {!statusList.isSeries &&
          <>
            <div className='invoiceInfo'>
              <h2>{t('InvoiceTitle', {name: invoice.name, total})}</h2>
              <div>
                {statusList.isInvoiceFromSeries ? (
                  <div className='quantityFromSeries'>{t('InvoiceIndex', { index: currentInvoice, size: invoiceQuantity })}</div>
                ) : (
                  <div className='noQuantityInvoice'>{t('InvoiceIndex', { index: 1, size: 1 })}</div>
                )
                }
                {frequency && <div className='text frequencyLabel'>{frequency}</div>}
              </div>
            </div>

            <div className='invoiceStatus'>
              {statusInfo.icon}
              <div>
                <div className={'statusLabel'}>{t(statusInfo.label)}</div>
                <p>{statusInfo.description}</p>
              </div>
            </div>
          </>
        }

        <div className='invoiceActions'>
          <DetailPanelOptions options={actions}/>
        </div>

        {
          isAVSError && (
            <div className='failedPaymentInfo'>
              {IconUtils.getIcon('Warning', '#F00')}
              <div className='failedPaymentDetails'>
                <h4 className='failureReasonTitle'>{t('PaymentFailed')}</h4>
                <span className='failureReason'>{invoice.failure_array[0].reason}</span>
                <br/>
                <div className='failureButtonContainer'>
                  <a
                      className='failureResendButton'
                      onClick={this.handleModalChange.bind(this, ModalTypes.RESEND_INVOICE)}
                  >{t('Resend')}</a>
                </div>
              </div>
            </div>
          )
        }

        {!statusList.isSeries && (
          <div className='invoiceDetail'>
            <h4>{t('Details')}</h4>
            <div>{t('InvoiceNumberID', {number: invoice.invoice})}</div>
            {invoice.sent_date && <div><Trans i18nKey='SentDate'><span>Sent</span> {{date: DateUtil.extractDate(invoice.sent_date) }}</Trans></div>}
            {dueDate && <div><Trans i18nKey='DueDate'><span>Due</span> {{date: dueDate}}</Trans></div>}
            {invoice.paid_date && <div className='paidDateLabel'>{t('PaidDate') + DateUtil.extractDate(invoice.paid_date)}</div>}
            {creditCard?.processor && creditCard?.lastDigits && (
              <div>{t('AutopayCard', {processor: creditCard.processor, lastDigits: creditCard.lastDigits})}</div>
            )}
          </div>
        )}
        {customer && (
          <div className='customerDetail'>
            <h4>{t('Customer')}</h4>
            <div>{customer.fullName}</div>
            <div>{customer.email}</div>
            <div>{customer.phone}</div>
            { !isMbp && (<a onClick={this.viewCustomerProfile}>{t('ViewCustomerProfile')}</a>) }
          </div>
        )}

        {!statusList.isSeries && creditCard && (
          <div className='paymentMethod'>
            <h4>{t('SavedPaymentMethod')}</h4>
            <div className='paymentMethodItem'>
              {creditCard?.processorCode && IconUtil.creditCardIcon(creditCard.processorCode)}
              <div className='content'>
                <div>
                  <div className='creditCardNumber'>{`•••• •••• •••• ${creditCard.lastDigits}`}</div>
                  <div className='expireAt'>{t('ExpiresCard', {card: creditCard.expireAt})}</div>
                </div>
                <a onClick={this.editCreditCardPayment}>{t('Edit')}</a>
              </div>
            </div>
          </div>
        )}

        <div className='invoiceDescription'>
          <h4>{t('InvoiceDescription')}</h4>
          <p>{invoice.description || t('None')}</p>
        </div>

        <div className='invoiceActivity'>
          <h4>{t('InvoiceActivity', {invoice: statusList.isSeries ? t('Series') : t('Invoice')})}</h4>
          <AuditActivityList
            invoice={invoice}
            viewAllActivities={this.viewAllActivities}
            showLatestOnly
            t={t}
          />
        </div>

        <div className='purchases'>
          <h4>{t('Purchases')}</h4>

          {purchases.map(({ letters, name, price, priceName, amount, modifiers, discountRate, discountAmt, totalModifiersAmt }, index) => (
            <div className='purchaseItem' key={`purchase-item-${index}`}>
              <Avatar className='avatar'>{letters}</Avatar>
              <div className='contentHolder'>
                <div className='content'>
                  <div>
                    <div>{InvoiceUtil.formatNamePriceQuantity(name, priceName, amount)}</div>
                  </div>
                  <div className='price'>{formatCurrency(price)}</div>
                </div>
                {modifiers?.length && modifiers?.map((mod, modIndex) =>
                  <div className='content' key={`modifier-${modIndex}`}>
                    <div>
                      <div>{mod?.name}</div>
                    </div>
                    <div className='price'>{formatCurrency(mod?.total_amt)}</div>
                  </div>)}
                {itemHasDiscounts && (discountRate || discountAmt) &&
                  <div className='content'>
                    <div>
                      <div className='discount-title'>{`Discount${discountRate ? ` (${discountRate}%)` : ''}`}</div>
                    </div>
                    <div className='price'>{`-${formatCurrency(discountAmt)}`}</div>
                  </div>}
              </div>
            </div>
          ))}

          {hasItemizedDiscounts && receiptDiscounts.map(({ name, letters, receiptDiscountInfo, receiptDiscountAmt }, index ) => (
            <div className='purchaseItem' key={`purchase-item-${index}`}>
              <Avatar className='avatar'>{letters}</Avatar>
              <div className='contentHolder'>
                <div className='content'>
                  <div>
                    <div className='receiptDiscountInfo'>{`${name} ${receiptDiscountInfo.includes('percentage') ? `(${JSON.parse(receiptDiscountInfo).percentage}%)` : ''}`}</div>
                  </div>
                  <div className='price'>{`-${formatCurrency(receiptDiscountAmt)}`}</div>
                </div>
              </div>
            </div>
            ))}
        </div>

        <div className='total'>
          <div>
            <span>{t('Subtotal')}</span>
            <span>{subtotal}</span>
          </div>
          <div>
            <span>{t('Tax')}</span>
            <span>{taxes}</span>
          </div>
          <div>
            <span>{t('Tip')}</span>
            <span>{tip}</span>
          </div>
          <div>
            <span>{t('Total')}</span>
            <span>{total}</span>
          </div>
        </div>

        <Modal
            {...modalProps}
            onClose={this.handleModalClose}
            open={openModal}
        />
        {editPaymentModal}
        {highAmountModal}
        {lowAmountModal}
      </div>
    )
  }
}

export default withTranslate(InvoiceTypeDetailComponent);
