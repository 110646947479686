/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { Grid, MenuItem } from '@mui/material';
import numeral from 'numeral';

import CustomToggle from '../Toggle';
import Select from '../Select';
import TextField from '../TextField';
import Tooltip from '../Tooltip';

import FileUtil from '../../util/FileUtil';
import FormatTextUtil from '../../util/FormatTextUtil';
import IconUtil from '../../util/IconUtil';
import LabelUtil from '../../util/LabelUtil';
import Validator from '../../util/Validator';

import AttachFilesForm, { AttachFilesFormType} from '../../AttachFilesForm';

import attachFileOptions from '../../../constants/attachFileOptions';
import messages from '../../../constants/messages';
import routes from '../../../constants/routes';
import paymentLinks from '../../../constants/paymentLinks';
import { highTransactionLimitAmount } from '../../../constants/applicationConstants';
import ResizableTextArea from '../ResizableTextArea';

export const PAYMENT_LINK_FORM_ID = 'createPaymentLinksForm';

export const validate = (values) => {
  return (!_.isEmpty(values)) ? Validator.validateCreatePaymentLinksForm(values) : {};
};


export class CreatePaymentLinkForm extends Component {

  constructor(props) {
    super(props);

    this.normalizeCurrency = this.normalizeCurrency.bind(this);
    this.handleAddImage = this.handleAddImage.bind(this);
    this.handleResetImage = this.handleResetImage.bind(this);

  }

  normalizeCurrency(value) {
    return FormatTextUtil.formatCurrencyWithMaxDigit(value, 20);
  }

  handleAddImage(file) {

    return FileUtil.getBase64(file[0]).then((result) => {

      const [extension, imageString] = (result?.base64?.match(/data:image\/(\w+);base64,(.+)/) || []).slice(1);

      this.props.dispatch(change(PAYMENT_LINK_FORM_ID, 'image', imageString));
      this.props.dispatch(change(PAYMENT_LINK_FORM_ID, 'imageExtension', extension));

    });

  }

  handleResetImage() {

    this.props.dispatch(change(PAYMENT_LINK_FORM_ID, 'image', null));
    this.props.dispatch(change(PAYMENT_LINK_FORM_ID, 'imageExtension', ''));

  }

  render() {

    const { t, currentValues, isProcessing = false, merchantSettings } = this.props;

    const paymentTypes = new Map([
      [paymentLinks.amountTypes.fixedAmount, {
        text: t('PaymentLinks.CreateForm.PaymentTypes.FixedAmount'),
        description: t('PaymentLinks.CreateForm.PaymentTypes.FixedAmountDescription')
      }],
      [paymentLinks.amountTypes.multipleAmount, {
        text: t('PaymentLinks.CreateForm.PaymentTypes.MultipleAmount'),
        description: t('PaymentLinks.CreateForm.PaymentTypes.MultipleAmountDescription')
      }],
      [paymentLinks.amountTypes.openEnded, {
        text: t('PaymentLinks.CreateForm.PaymentTypes.OpenEnded'),
        description: t('PaymentLinks.CreateForm.PaymentTypes.OpenEndedDescription')
      }],
    ]);

    const paymentMenuItems = Array.from(paymentTypes.entries()).map(([paymentType, value]) => (
      <MenuItem value={paymentType} key={paymentType}>{value.text}</MenuItem>
    ));

    const selectedPaymentTypeDescription = paymentTypes.get(currentValues?.paymentLinkAmountType)?.description;

    const paymentLinksRoute = globalApplicationLabel.path + routes.business.root + routes.business.paymentLinks;
    const businessSettingsLinksRoute = globalApplicationLabel.path + routes.account.root + routes.account.settings;

    const infoIcon = IconUtil.getIcon('InfoIcon', LabelUtil.getLabel().primaryColor);
    const removeIcon = IconUtil.getIcon('Close', '#ffffff');

    const cashDiscountEnabled = merchantSettings?.merchantSettings?.cash_discount_enabled;
    const cashDiscountPerTransactionEnabled = merchantSettings?.merchantSettings?.cash_discount_per_transaction_enabled;
    let cashDiscountingAmount;

    if (cashDiscountEnabled) {

      const cdFixedAmount = parseFloat(merchantSettings?.merchantSettings?.cash_discount_fixed_amount);
      const cdPercent = parseFloat(merchantSettings?.merchantSettings?.cash_discount_amount_percent);
      const formattedCdFixedAmount = numeral(cdFixedAmount).format('$0,0.00');
      const formattedCdPercent = FormatTextUtil.formatTaxRate(cdPercent.toFixed(3));
      let merchantCDValues = '';

      if (cdFixedAmount && cdPercent) {
        merchantCDValues = `${formattedCdFixedAmount} / ${formattedCdPercent}`;
      } else if (cdFixedAmount && !cdPercent) {
        merchantCDValues = formattedCdFixedAmount;
      } else if (!cdFixedAmount && cdPercent) {
        merchantCDValues = formattedCdPercent;
      }

      cashDiscountingAmount = (
        <div className='cdAmountContainer'>
          <span>{`(${merchantCDValues})`}</span>
          <Tooltip
            component='span'
            placement='right'
            title={t(messages.virtualTerminal.cashDiscountInfo)}
          >
            {infoIcon}
          </Tooltip>
        </div>
      );

    }

    const imageContainer = (
      <div className='imageContainer'>

        {currentValues?.image ?
          <div className='uploadedImage'>
            <p className='imageLabel'>{t('PaymentLinks.CreateForm.ImageLabel')}</p>
            <img
              src={`data:image/${currentValues?.imageExtension};base64,${currentValues?.image}`}
              alt='Company Logo'/>
            <span className='removeLogo' onClick={this.handleResetImage}>{removeIcon}</span>
          </div>
        :
        <AttachFilesForm
          insideForm
          label={t('PaymentLinks.CreateForm.ImageLabel')}
          displayType={AttachFilesFormType.LOGO}
          logoSetState={this.handleAddImage}
          filesCopy={t('PaymentLinks.CreateForm.ImageUpload')}
          secondaryText={`170x170, JPEG ${t('or')} PNG`}
          dropzoneOptions={attachFileOptions.imageSinglePngJpgDropzoneOptions}
          onSubmit={null}
        />}
      </div>
    );

    return (

      <Grid container spacing={3} className='createPaymentLinkForm'>
        <Grid item md={8} xs={12}>

          <div className='paymentLinkFormContainer'>

            <h2 className='formTitle'>
              {`${t('Create Payment Link')}.`}
            </h2>

            <div className='mainInfo formSubsection'>

              <div className='titleDescriptionContainer'>

                <Field
                  component={TextField}
                  name='paymentLinkTitle'
                  className='paymentLinkTitle'
                  label={t('PaymentLinks.CreateForm.TitleLabel')}
                  hintText={t('PaymentLinks.CreateForm.TitleHint')}
                  disabled={isProcessing}
                  maxLength='50'
                />

                <Field
                  component={ResizableTextArea}
                  name='paymentLinkDescription'
                  label={t('PaymentLinks.CreateForm.DescriptionLabel')}
                  hintText={t('PaymentLinks.CreateForm.DescriptionHint')}
                  disabled={isProcessing}
                />

              </div>

              {imageContainer}

            </div>

            <div className='paymentInfo formSubsection'>

              <Field
                fullWidth
                label={t('PaymentLinks.CreateForm.PaymentTypeLabel')}
                component={Select}
                t={t}
                name='paymentLinkAmountType'
                className='paymentLinkAmountType'
                disabled={isProcessing}
              >
                {paymentMenuItems}
              </Field>
              <p className='paymentTypeDescription'>{selectedPaymentTypeDescription}</p>


              {currentValues?.paymentLinkAmountType !== paymentLinks.amountTypes.openEnded && (
                <div className='paymentAmounts'>

                  <Field
                    component={TextField}
                    name='paymentAmount1'
                    className='paymentAmount1'
                    label={t('PaymentLinks.CreateForm.PaymentAmountLabel')}
                    hintText={'$0.00'}
                    disabled={isProcessing}
                    maxLength='100'
                    normalize={this.normalizeCurrency}
                  />

                  {currentValues?.paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount && (
                    <div className='secondaryPaymentAmounts'>
                      <Field
                        component={TextField}
                        name='paymentAmount2'
                        className='paymentAmount2'
                        label={t('PaymentLinks.CreateForm.PaymentAmountLabelOptional')}
                        hintText={'$0.00'}
                        disabled={isProcessing}
                        maxLength='100'
                        normalize={this.normalizeCurrency}
                      />

                      <Field
                        component={TextField}
                        name='paymentAmount3'
                        className='paymentAmount3'
                        label={t('PaymentLinks.CreateForm.PaymentAmountLabelOptional')}
                        hintText={'$0.00'}
                        disabled={isProcessing}
                        maxLength='100'
                        normalize={this.normalizeCurrency}
                      />

                      <Field
                        component={CustomToggle}
                        name='allowCustomAmount'
                        customClass='allowCustomAmount paymentOptionsToggle'
                        label={t('PaymentLinks.CreateForm.CustomAmountLabel')}
                        checked={!!this.value}
                        disabled={isProcessing}
                      />
                    </div>
                  )}

                </div>
              )}

              {(currentValues?.paymentLinkAmountType === paymentLinks.amountTypes.openEnded
                || (currentValues?.paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount && currentValues?.allowCustomAmount)
                ) && (
                <div className='openEndedFields'>

                      <Field
                        component={TextField}
                        name='minimumAmount'
                        className='minimumAmount'
                        label={t('PaymentLinks.CreateForm.MinimumAmount')}
                        hintText={'$0.00'}
                        disabled={isProcessing}
                        maxLength='100'
                        normalize={this.normalizeCurrency}
                      />

                      <Field
                        component={TextField}
                        name='maximumAmount'
                        className='maximumAmount'
                        label={t('PaymentLinks.CreateForm.MaximumAmount')}
                        hintText={'$0.00'}
                        disabled={isProcessing}
                        maxLength='100'
                        normalize={this.normalizeCurrency}
                      />

                </div>
              )}
              { (Number(merchantSettings?.merchantSettings?.tax_rate) > 0 || merchantSettings?.merchantSettings?.is_geotax_enabled) && (
                <>
                  <Field
                    component={CustomToggle}
                    name='enableTax'
                    customClass='enableTax paymentOptionsToggle'
                    label={t('PaymentLinks.CreateForm.EnableTaxLabel')}
                    checked={!!this.value}
                    disabled={isProcessing}
                  />
                  <div className='toggleTaxDescription'>
                    <Trans i18nKey={'PaymentLinks.CreateForm.ToggleTaxDescription'}>
                      You can manually set a tax rate or auto-detect by location of transaction. Manage tax options in <Link className='businessSettingsLinksLink linkLike' to={businessSettingsLinksRoute}>Business Settings</Link>.
                    </Trans>
                  </div>
                </>
              ) }
              { merchantSettings?.merchantSettings?.is_tip_enabled && (
                <Field
                  component={CustomToggle}
                  name='acceptTips'
                  customClass='acceptTips paymentOptionsToggle'
                  label={t('PaymentLinks.CreateForm.AcceptTipsLabel')}
                  checked={!!this.value}
                  disabled={isProcessing}
                />
              )}
              {cashDiscountEnabled &&
                <div className='cdContainer'>
                  <Field
                    component={CustomToggle}
                    name='cashDiscounting'
                    customClass='cashDiscounting paymentOptionsToggle'
                    label={t('PaymentLinks.CreateForm.CashDiscountingLabel')}
                    checked={!!this.value}
                    disabled={isProcessing || !cashDiscountPerTransactionEnabled}
                  />
                  {cashDiscountingAmount}
                </div>
              }

            </div>

            <div className='customInfo formSubsection'>

              <Field
                component={TextField}
                name='addCustomField'
                className='addCustomField'
                label={t('PaymentLinks.CreateForm.CustomFieldLabel')}
                hintText={t('PaymentLinks.CreateForm.CustomFieldHint')}
                disabled={isProcessing}
                maxLength='100'
              />

              <Field
                component={CustomToggle}
                name='customRequiredField'
                customClass='customRequiredField'
                label={t('PaymentLinks.CreateForm.CustomFieldRequired')}
                checked={!!this.value}
                disabled={isProcessing}
              />

              <Field
                component={TextField}
                name='redirectUrl'
                className='redirectUrl'
                label={t('PaymentLinks.CreateForm.RedirectUrlLabel')}
                hintText={t('PaymentLinks.CreateForm.RedirectUrlHint')}
                disabled={isProcessing}
                maxLength='100'
              />

              <p className='redirectUrlDescription'>{t('PaymentLinks.CreateForm.RedirectUrlDescription')}</p>

            </div>

          </div>

        </Grid>

        <Grid item md={4} xs={12}>
          <div className='paymentLinkSharingInfoContainer'>

            <p className='sharingTitle'>{t('PaymentLinks.Sharing.Title')}</p>

            <div className='sharingFeature'>
              <img className='featureImage' src={'/images/paymentLinks/paymentLinksShareUrl.svg'} />
              <p className='featureText'>{t('PaymentLinks.Sharing.CopyUrl')}</p>
            </div>

            <div className='sharingFeature'>
              <img className='featureImage' src={'/images/paymentLinks/paymentLinksShareEmbed.svg'} />
              <p className='featureText'>{t('PaymentLinks.Sharing.Embed')}</p>
            </div>

            <div className='sharingFeature'>
              <img className='featureImage' src={'/images/paymentLinks/paymentLinksShareQR.svg'} />
              <p className='featureText'>{t('PaymentLinks.Sharing.QRCode')}</p>
            </div>

            <p className='manageInfo'>
              <Trans i18nKey={'PaymentLinks.Sharing.Manage'}>
                Manage the payment links you have created on the <Link className='paymentLinksLink linkLike' to={paymentLinksRoute}>Payment Links</Link> page.
              </Trans>
            </p>

          </div>
        </Grid>

      </Grid>

    );

  }
}

let CreatePaymentLinkFormContainer = CreatePaymentLinkForm;

CreatePaymentLinkFormContainer = reduxForm({
  form: PAYMENT_LINK_FORM_ID,
  validate,
  fields: [],
  enableReinitialize: true,
})(CreatePaymentLinkFormContainer);

function mapStateToProps(state, ownProps) {

  const { merchantSettings } = ownProps;
  const currentValues = getFormValues(PAYMENT_LINK_FORM_ID)(state) || {};

  const cashDiscountEnabled = merchantSettings?.merchantSettings?.cash_discount_enabled;

  return {
    currentValues,
    initialValues: {
      paymentLinkAmountType: paymentLinks.amountTypes.fixedAmount,
      cashDiscounting: cashDiscountEnabled,
      image: null,
      imageExtension: ''
    }
  };
}

export default connect(mapStateToProps)(CreatePaymentLinkFormContainer);
