/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit } from 'redux-form';
import {
  setActiveFilterHeader,
  setOpenMessageDialog,
  setSubmitButtonEnabled,
  setVisibilityFilter
} from '../../actions/userExperienceActions';
import {
  getEmployees,
  modifyEmployeeStatus,
  saveEmployee,
  setEmployee,
  unlockRateLimitForEmployee
} from '../../actions/employeesActions';
import { updateMerchantSettings } from '../../actions/merchantSettingsActions';
import MasterDetailLayout from '../MasterDetailLayout';
import FilterPanel from '../shared/FilterPanel';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconUtils from './../util/IconUtil';
import AlertErrorIcon from '@mui/icons-material/Error';
import TextUtil from '../util/FormatTextUtil';
import LabelUtil from '../util/LabelUtil';
import LockIcon from '@mui/icons-material/Lock';
import NoData from '../NoData';
import moment from 'moment';
import EmployeeDetailForm from './EmployeeDetail';
import RoleSettingsForm from './RoleSettingsForm';
import UpdateSpinner from '../UpdateSpinner';
import UserUtil from '../util/UserUtil';
import GeoLocationUtil from '../util/GeoLocationUtil';
import MessageDialog from '../shared/MessageDialog';
import ErrorMessageDialog from '../shared/ErrorMessageDialog';
import messages from '../../constants/messages';
import TableHeader from '../shared/TableHeader';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import Button from '../shared/Button';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import Page from '../shared/Page';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButtonAccessory from '../appBar/accessories/IconButtonAccesory';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';

export default class Employees extends Component {

  constructor(props) {
    super(props);

    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.showCreateNew = this.showCreateNew.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.reactivate = this.reactivate.bind(this);
    this.handleRoleWindow = this.handleRoleWindow.bind(this);
    this.onSaveRoleClick = this.onSaveRoleClick.bind(this);
    this.saveRoleSettings = this.saveRoleSettings.bind(this);
    this.displayArchiveDialog = this.displayArchiveDialog.bind(this);
    this.closeArchiveDialog = this.closeArchiveDialog.bind(this);
    this.unlockRateLimitForEmployee = this.unlockRateLimitForEmployee.bind(this);

    this.state = {
      selectedIndex: 'none',
      updatingSettings: false,
      roleSettings: false,
      subUserErrorType: null,
      selectedEmployee: null,
      openArchiveDialog: false,
      employeeLocation: null
    };

  }

  componentDidMount() {
    this.loadData();
    this.props.dispatch(setVisibilityFilter({
      property: 'role',
      value: 'All Employees'
    }, 'filter'));

    if (this.props.auth.needsInfo) {
      this.props.dispatch(setOpenMessageDialog('restrictedMbp'));
    }

  }

  componentDidUpdate(prevProps) {
    const {employees} = this.props;
    if ((employees.shouldFetch !== prevProps.employees.shouldFetch) && employees.shouldFetch) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
    this.props.dispatch(setActiveFilterHeader(null));
  }

  displayArchiveDialog() {
    this.setState({openArchiveDialog: true});
  }

  closeArchiveDialog() {
    this.setState({openArchiveDialog: false});
  }

  loadData() {
    const {user, dispatch} = this.props;
    dispatch(getEmployees(user));
  }

  async showDetailForRow(row) {
    this.props.dispatch(setSubmitButtonEnabled(true));

    if (row) {
      this.setState({roleSettings: false});
      let selectedIndex = row - 1; // Title Panel is row zero
      this.setState({selectedIndex: selectedIndex});
      const selectedEmployee = this.props.employees.filteredData[selectedIndex];
      this.props.dispatch(setEmployee(selectedEmployee));
      if (selectedEmployee.last_login_ip) {
        try {
          const location = await GeoLocationUtil.getLocationFromIp(selectedEmployee.last_login_ip, this.props);
          this.setState({ employeeLocation: location });
        } catch {
          this.setState({ employeeLocation: null });
        }
      }
    } else {
      this.setState({selectedIndex: null});
    }

  }

  showCreateNew() {
    this.setState({
      roleSettings: false,
      selectedIndex: 'new'
    });
    this.props.dispatch(setEmployee({}))
  }

  handleFilterSelection(filter) {
    this.setState({roleSettings: false});
    this.setState({selectedIndex: null});
    this.props.dispatch(setVisibilityFilter({
      property: 'role',
      value: filter.name
    }, 'filter'));
  }

  async unlockRateLimitForEmployee() {
    const { dispatch, user, employees } = this.props;
    const id = employees?.selectedEmployee?.id;

    if(UserUtil.isUserCSWrite(user) && id) {
      this.setState({updatingSettings: true});
      await dispatch(unlockRateLimitForEmployee(user, id));

      this.setState({selectedIndex: null, updatingSettings: false});
      this.loadData();
    }
  }

  save(values) {

    const { dispatch, user } = this.props;

    const label = LabelUtil.getLabel();

    this.setState({updatingSettings: true});
    this.setState({selectedEmployee: values.email_address});
    this.setState({subUserErrorType: null});

    dispatch(saveEmployee(user, this.state.selectedIndex, values, label.abbreviation)).then((res) => {

      if (res?.response?.message === 'Server.Error.DuplicateEmployee') {
        this.setState({subUserErrorType: 'duplicate'});
        dispatch(setOpenMessageDialog('addEmployeeError'));
      }
      this.setState({selectedIndex: null, updatingSettings: false});
    });
  }

  delete() {

    let that = this;
    let body = {};
    body.is_active = false;
    body.email_address = this.props.employees.selectedEmployee.email_address;

    this.setState({openArchiveDialog:false, updatingSettings: true});

    this.props.dispatch(modifyEmployeeStatus(this.props.user, this.props.employees.selectedEmployee, body)).then(function () {
      that.setState({
        selectedIndex: 0,
        updatingSettings: false
      });
    });
  }

  reactivate() {

    let that = this;
    let body = {};
    body.is_active = true;
    body.email_address = this.props.employees.selectedEmployee.email_address;

    this.setState({updatingSettings: true});

    this.props.dispatch(modifyEmployeeStatus(this.props.user, this.props.employees.selectedEmployee, body)).then(function () {
      that.setState({updatingSettings: false});
    });
  }

  onSaveClick() {
    this.props.dispatch(submit('employeeDetailForm'));
  }

  onSaveRoleClick() {
    this.props.dispatch(submit('roleSettingsForm'));
  }

  saveRoleSettings(values) {

    if (this.props.visibilityFilter.filter) {
      const roleType = this.props.visibilityFilter.filter.value;
      this.setState({updatingSettings: true});
      this.props.dispatch(updateMerchantSettings(this.props.user, values, roleType))
        .then(() => {
          this.setState({updatingSettings: false});
          this.setState({selectedIndex: null});
        });
    }

  }

  handleRoleWindow() {
    this.setState({selectedIndex: false});
    this.setState({roleSettings: true});
  }

  //Determines if an employee should be read-only depending on the current user, shared with EmployeeDetail
  //currentUserRole should default to the role with least priviledges that can access this (owner,admin,manager)
  isEmployeeReadOnly(employee, currentUserRole = 'manager', currentUserId) {

    if (!employee) {
      return false;
    }
    const isCurrentUserManager = currentUserRole === 'manager';
    const isCurrentUserAdmin = currentUserRole === 'admin';
    const isCurrentUserTheEmployee = currentUserId === employee.id;

    return (isCurrentUserTheEmployee) || (employee.role === 'owner') ||
      (employee.role === 'admin' && (isCurrentUserManager || isCurrentUserAdmin)) ||
      (isCurrentUserManager);
  }

  renderEmployeeStatusIcon(employee, options = {}) {
    const {currentUserRole, currentUserId} = options;
    if (employee.is_locked) // top one
      return (
        <LockIcon className='employeeIsLocked isGrayIcon isSecondaryColor'/>);
    if (!employee.is_active || this.isEmployeeReadOnly(employee, currentUserRole, currentUserId))
      return (<AlertErrorIcon
        className='employeeIsArchived isGrayIcon isPrimaryColor'/>);
    return null;
  }

  render() {
    const {isFetching, employees, visibilityFilter, auth, userExperience, merchantSettings, user, t} = this.props;
    const isEmpty = employees.data === null;
    const spinner = this.state.updatingSettings || merchantSettings?.isProcessing ? (<UpdateSpinner/>) : null;
    const labelColor = LabelUtil.getLabelColor();

    const deleteIcon = IconUtils.getIcon('DeleteIcon', labelColor);
    const reactivateIcon = IconUtils.getIcon('RefreshIcon', labelColor);

    const pageLoading = isEmpty && isFetching;

    if (pageLoading) {
      return  (
        <Page title={t('Employees')} loading/>
      )
    }

    let filterData = [
      {name: 'All Employees', icon: 'EmployeeIcon', color: '888C8D'},
      {name: 'Administrator', icon: 'EmployeeIcon', color: labelColor},
      {name: 'Manager', icon: 'EmployeeIcon', color: '35C2FD'},
      {name: 'Cashier', icon: 'EmployeeIcon', color: '67E418'},
      {name: 'Reporter', icon: 'EmployeeIcon', color: 'E762FF'},
      {name: 'Archive', icon: 'PauseIcon', color: '888C8D'}
    ];

    if (UserUtil.userType(user) === 'MBP') {
      filterData = filterData.filter(filter => filter.name !== 'Cashier');
    }

    const selectedMerchantAccount = user.data && user.data.merchantAccounts && user.data.merchantAccounts[0] ?
      _.find(user.data.merchantAccounts, {mea_id: Number(user.selectedMerchantAccount)}) : null;

    const currentUserRole = selectedMerchantAccount ? selectedMerchantAccount.role : undefined;

    const currentUserId = user.data.user_id;

    const headerTitle = visibilityFilter.filter && visibilityFilter.filter.value ? visibilityFilter.filter.value : filterData[0].name;
    const showWindowBtn = (this.state.selectedIndex === 'none' || this.state.selectedIndex === null) && (visibilityFilter.filter && (visibilityFilter.filter.value === 'Cashier' || visibilityFilter.filter.value === 'Manager')) && (auth.isAdmin || auth.isOwner || auth.isSpr || (currentUserRole === 'manager' && visibilityFilter.filter.value === 'Cashier'));

    const searchBar = (
      <SearchBarAccessory {...this.props} searchPlaceholder='Search Employees' />
    );

    const isClosedAccount = !UserUtil.isActive(user);
    const isCsUser = UserUtil.isUserCS(user, auth);

    const createNewButton = <CreateButtonAccessory
      onClick={this.showCreateNew}
      disabled={isCsUser || isClosedAccount}
      isMobileOnly
    />

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'editRoleButton',
        accessory: (
          <IconButtonAccessory
            icon={<EditOutlinedIcon color='primary'/>}
            onClick={this.handleRoleWindow}
          />
        ),
        showInMobile: true,
        hide: !(showWindowBtn)
      },
    ];

    const filterPanel = auth.isManager ? (
      <FilterPanel
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
        disabled={isCsUser || isClosedAccount}
      />
    ) : (
      <FilterPanel
        buttonOnClick={this.showCreateNew}
        buttonText={'Create Employee'}
        buttonWithCreateIcon
        disabled={isCsUser || isClosedAccount}
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
      />
    );

    const employeeRows = employees.filteredData && employees.filteredData.length > 0 ?
      employees.filteredData.map((employee, i) => {

          const tableRowClassName = (this.state.selectedIndex === i) ? 'tableRow selectedListRow employeeRow' : 'tableRow employeeRow';

          const lastLoginText = employee.last_login ? moment(employee.last_login).local().format('MM/DD/YYYY') + ' ' + moment(employee.last_login).local().format(' h:mm a') : '';

          return (
            <TableRow key={i} className={tableRowClassName}>
              <TableCell style={{width: '0'}}>
                {this.renderEmployeeStatusIcon(employee, {
                  currentUserRole,
                  currentUserId
                })}
              </TableCell>
              <TableCell>{TextUtil.formatName(employee.first_name, employee.last_name, 'Unnamed Employee')}</TableCell>
              <TableCell>{employee.role === 'report' ? 'reporter' : employee.role}</TableCell>
              <TableCell
                className='employeeLogin'>{lastLoginText}</TableCell>
            </TableRow>
          )
        }
      ) : (<NoData text='No Employees' type='table'/>);

    const listHeaders = ((employees.filteredData && employees.filteredData.length) &&
      <TableRow className={'tableRow'}>
        <TableHeader
          type='table'
          headerId={'employeeHeaderOne'}
          name={t('EmployeeTable.Headers.Status')}
          {...this.props}/>
        <TableHeader
          type='table'
          clickable={true}
          attribute={'last_name'}
          headerId={'employeeHeaderTwo'}
          name={t('EmployeeTable.Headers.Name')}
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'employeeHeaderThree'}
          clickable={true}
          attribute={'role'}
          name={t('EmployeeTable.Headers.Role')}
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'employeeHeaderFour'}
          clickable={true}
          attribute={'last_login'}
          name={t('EmployeeTable.Headers.LastLogin')}
          {...this.props}/>
      </TableRow>
    ) || null;

    const employeeList = (
      <TableBody>
        {listHeaders}
        {employeeRows}
      </TableBody>
    );

    const selectedEmployee = this.state.selectedIndex !== null ? employees.selectedEmployee : null;
    const isReadOnly = this.isEmployeeReadOnly(selectedEmployee, currentUserRole, currentUserId);
    const isNewEmployee = this.state.selectedIndex === 'new';
    const openDetail = (isNewEmployee || (this.state.roleSettings && this.state.selectedIndex !== null));
    const isArchivedEmployee = (selectedEmployee && selectedEmployee.is_active === false);

    const detailsTitle = !selectedEmployee || isNewEmployee ? t('AddEmployee') :
      isArchivedEmployee ? t('EmployeeDetailTitle.ArchivedEmployee') :
        isReadOnly || isClosedAccount ? t('EmployeeDetailTitle.DetailsEmployee') : t('EmployeeDetailTitle.EditEmployee');

    const reactivateOption =
      {
        onClick: this.reactivate,
        text: t('EmployeeDetailOption.Reactivate'),
        icon: reactivateIcon
      };

    const archiveOption =
      {
        onClick: this.displayArchiveDialog,
        text: t('EmployeeDetailOption.Archive'),
        icon: deleteIcon
      };

    const isNew = (isNewEmployee || !isReadOnly) && !isClosedAccount;

    const employeeOptions = isReadOnly || isClosedAccount ? [] : isArchivedEmployee ? [reactivateOption] : isNewEmployee ? [] : [archiveOption];

    const newDetail = this.state.selectedIndex !== null && !this.state.roleSettings ?
      <DetailPanel
        heading={detailsTitle}
        isNew={isNew}
        onClick={this.onSaveClick}
        confirmationText={t('EmployeeDetailForm.Save')}>
        <DetailPanelOptions options={employeeOptions}/>
        <EmployeeDetailForm
          isNew={isNewEmployee}
          isArchived={isArchivedEmployee}
          onSubmit={this.save}
          isEmployeeReadOnly={this.isEmployeeReadOnly}
          location={this.state.employeeLocation}
          t={t}
          unlockRateLimitForEmployee={this.unlockRateLimitForEmployee}
        />
      </DetailPanel> : (this.state.roleSettings && this.state.selectedIndex === false) ?
        <DetailPanel
          isNew={true}
          heading={t(headerTitle === filterData[2].name ? 'RoleSettingsHeadingManager' : 'RoleSettingsHeading')}
          onClick={this.onSaveRoleClick}
          isSubmitButtonVisible={!isClosedAccount}>
            <RoleSettingsForm
              auth={this.props.auth}
              user={this.props.user}
              isManagerRole={headerTitle === filterData[2].name}
              isCashierRole={headerTitle === filterData[3].name}
              onSubmit={this.saveRoleSettings}
              readOnly={isClosedAccount}
              t={t}
            />
        </DetailPanel> : null;

    const roleWindowBtn = showWindowBtn ? (
      <Button
        className='roleWindowButton hideTablet'
        label={t('RoleSettingsHeading')}
        onClick={this.handleRoleWindow}
        variant='text'
      />
    ) : null;

    const restrictedAccessDialog = userExperience.openMessageDialog === 'restrictedMbp' && !UserUtil.isHumbolt(this.props.user) && (
      <ErrorMessageDialog
        messageTitle={t('RestrictedAccessDialog.Title')}
        messageContent={t(messages.user.restrictedMbpAccess)}
      />
    );

    const addEmployeeErrorDialog = userExperience.openMessageDialog === 'addEmployeeError' && this.state.subUserErrorType &&
      (
        <ErrorMessageDialog
          messageTitle={t('EmployeeDetailDuplicateErrorDialogTitle')}
          messageContent={t(messages.employee.duplicateEmployeeError)}
        />
      );

    const archiveConfirmationDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='archiveConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.delete}
        onRequestClose={this.closeArchiveDialog}
        open={this.state.openArchiveDialog}
        scrollable={true}
        titleText={t('EmployeeDetailArchiveConfirmationDialog.Title')}
        bodyText={
          <p className='confirmationText'>{t('EmployeeDetailArchiveConfirmationDialog.Text')}</p>
        } />
    );

    return (
      <section className='businessEmployees'>
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('Employees')}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          filterPanel={filterPanel}
          tableData={employees.filteredData}
          detailDisplayCallback={this.showDetailForRow}
          tableRowLayout={employeeList}
          forceOpenDetail={openDetail}
        >
          {newDetail}
        </MasterDetailLayout>
        {roleWindowBtn}
        {spinner}
        {restrictedAccessDialog}
        {addEmployeeErrorDialog}
        {archiveConfirmationDialog}
      </section>
    )
  }

}

Employees.defaultProps = {
  isFetching: true,
};
