/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '../shared/TextField';
import Button from '../shared/Button';
import CustomToggle from '../shared/Toggle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup'
import Validator from '../util/Validator';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import LabelUtil from './../util/LabelUtil';
import _ from 'lodash';
import FormatTextUtil from '../util/FormatTextUtil';
import CustomRadio from '../shared/Radio';

export const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    return Validator.validateFunding(values, props);
  } else {
    return {};
  }
};

export class FundingFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cardVisibility: false,
    };

  }

  render() {

    const label = LabelUtil.getLabel();

    const styles = {
      inputStyle: {color: '#393A3F'},
      underlineStyle: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'rgb(224, 224, 224)'
      },
      toggleFieldStyle: {
        thumbOff: {color: '#888C8D', backgroundColor: '#888C8D'},
        trackOff: {backgroundColor: '#A3A3A6'},
        thumbSwitched: {color: label.primaryColor},
        trackSwitched: {backgroundColor: label.primaryColor}
      }
    };

    const {
      handleSubmit,
      accountProfile,
      displaySavedDebitCard,
      instantFunding,
      invalid,
      submitting,
      pristine,
      toggleDebitCardInfo,
      submitFundingForm,
      t,
    } = this.props;

    const showSameDayFunding = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.is_sf_qualified;
    const showInstantFunding = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.is_if_qualified;
    const instantFundingEnabled = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.is_if;
    const showSameDayFundingClass = showSameDayFunding ? '' : ' hideMe';

    const fundingOptions = showSameDayFunding ?
      (<Field
          buttons={[{label: '', value: false}, {label: '', value: true}]}
          component={CustomRadio}
          externalClassName='defaultFunding'
          isBoolean
          name='is_sf'
      />) : (
        <RadioGroup
          value={false}
          name={'defaultFunding'}
          className='defaultFunding'>
          <Radio
            label={''}
            style={{marginBottom: 0}}
            value={false}
            />
        </RadioGroup>
      );

    const last4 = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.last4;
    const nameOnCard = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.name_on_card;
    const expirationDate = accountProfile.fundingData && accountProfile.fundingData.funding && accountProfile.fundingData.funding.expiration;
    const displaySavedCard = !instantFundingEnabled && !last4 ? false : displaySavedDebitCard;

    const debitCardInfo = instantFunding && displaySavedCard ? (
      <div className='flex debitCardInfo'>
        <div className='last4 field'>{`****-****-****-${last4}`}</div>
        <div className='nameOnCard field'>{nameOnCard}</div>
        <div className='expirationDate field'>{FormatTextUtil.formatReverseDebitExpiration(expirationDate)}</div>
        <Button
          label={t('Update')}
          onClick={toggleDebitCardInfo}
          className='saveButton'
        />
      </div>
    ) : null;

    const debitCardInput = instantFunding && !displaySavedCard ?
      (<div className='flex debitCardInput'>
        <Field
          component={TextField}
          name='name_on_card'
          label={t('FundingForm.NameOnCard')}
          maxLength='50'
          className='fullScreen textField'
          inputStyle={styles.inputStyle}
        />
        <br/>
        <Field
          className={'alignBottom cardNumber textField debitCard halfScreen'}
          component={TextField}
          label={t('FundingForm.DebitCardNumber')}
          maxLength={19}
          name='debit_card_number'
          normalize={value => FormatTextUtil.formatCardNumber(value)}
        />
        <Field
          component={TextField}
          name='expiration'
          label='M M / Y Y'
          normalize={FormatTextUtil.formatCreditCardExpiration}
          className='fifthScreen alignBottom textField shrinkSpacing smallerField'
          maxLength='5'
        />
        <Field
          name='cvv2'
          component={TextField}
          label={'CVV'}
          maxLength={'3'}
          normalize={FormatTextUtil.formatWholeNumber}
          className='fifthScreen alignBottom textField smallerField'
        />
        <br/>
        <Field
          component={TextField}
          name='address'
          label={t('FundingForm.StreetNumber')}
          maxLength='50'
          normalize={FormatTextUtil.formatStreetNumber}
          className='halfScreen alignBottom textField'
        />
        <Field
          component={TextField}
          name='zip_code'
          label={t('FundingForm.ZipLabel')}
          hintText={t('FundingForm.ZipHint')}
          maxLength='5'
          normalize={FormatTextUtil.formatWholeNumber}
          className='rowMain alignBottom textField zipField'
        />
      </div>)
      : null;

    const instantFundingOption = showInstantFunding ? (
      <div className='instantFunding'>
        <div className='instantFundingToggle'>
          <Field
            name='is_if'
            component={CustomToggle}
            toggleFieldStyle={styles.toggleFieldStyle}
          />
          <div className='twoLines'>
            <p className='label'>{t('FundingForm.InstantFundingLabel')}</p>
            <p
              className='subText'>{t('FundingForm.InstantFundingSubText')}</p>
          </div>
        </div>
        {debitCardInfo}
        {debitCardInput}
      </div>
    ) : null;

    const saveFundingButton = showSameDayFunding || showInstantFunding ? (
      <div className='businessButtonHolder'>
        <Button
          label={t('Save')}
          disabled={invalid || submitting || pristine}
          onClick={submitFundingForm}
          className='saveButton'
        />
      </div>
    ) : null;

    return (
      <div className='fundingOptimizationContainer'>
        <form onSubmit={handleSubmit}>
          <div className='settingsFormContainer'>
            <div className='title'>
              <span className='heading'>{t('FundingForm.Heading')}</span>
              <span className='subText'>{t('FundingForm.SubText')}</span>
            </div>
            <div className='settingsContainer businessForm'>
              <div className='secondaryTitle'>
                <span className='subText'>{t('FundingForm.SecondarySubText')}</span>
              </div>
              <div className='fundingFields'>
                <div className='columnLeft'>
                  {fundingOptions}
                </div>
                <div className='columnRight'>
                  <div className='twoLines'>
                    <p className='label'>
                      {t('FundingForm.NextDayFundingLabel')}
                      <span className='subText'>{t('FundingForm.NextDayFundingLabelSubText')}</span>
                    </p>
                    <p className='subText'>{t('FundingForm.NextDayFundingSubText')}</p>
                  </div>
                  <div className={'twoLines' + showSameDayFundingClass}>
                    <p className='label'>{t('FundingForm.SameDayFundingLabel')}</p>
                    <p className='subText'>{t('FundingForm.SameDayFundingSubText')}</p>
                  </div>
                </div>
              </div>
              {instantFundingOption}
              {saveFundingButton}
              <div className='detailsRow headerDivider'/>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

let FundingForm = reduxForm({
  form: 'fundingForm',
  validate,
  fields: [
    'is_if',
    'is_sf',
    'debit_card_number',
    'name_on_card',
    'expiration',
    'address',
    'zip_code',
    'cvv2',
    'last4',
    'instantFunding',
    'displaySavedDebitCard'
  ],
  enableReinitialize: true
})(FundingFormComponent);

const selector = formValueSelector('fundingForm');

function mapStateToProps(state, ownProps) {

  const initialValues = ownProps.accountProfile?.fundingData?.funding ?? {};
  initialValues.is_if = !!(initialValues && initialValues.is_if);
  initialValues.expiration = initialValues.expiration && FormatTextUtil.formatReverseDebitExpiration(initialValues.expiration);
  const instantFunding = selector(state, 'is_if');
  const displaySavedDebitCard = ownProps.displaySavedDebitCard;

  return {
    initialValues,
    instantFunding,
    displaySavedDebitCard
  };

}

FundingForm = connect(mapStateToProps)(FundingForm);

export default FundingForm;
