import React, {Component} from 'react';
import { Box } from '@mui/material';
import { UserAgreementNorthStyles } from '../jss/UserAgreementNorthStyles';

export default class PrivacyPolicyNorth extends Component {

  render(){
    return(
      <Box className='pageScrollableArea' sx={UserAgreementNorthStyles}>
        <Box className='privacyPolicyNorth'>
          <h1>Privacy Policy</h1>
          <p>Effective date: 07/15/2024</p>
          <p>This Online Privacy Policy applies to personal information that NorthAB, LLC, its subsidiaries and affiliated companies (“North,” “we,” “our,” “us”) may obtain when you visit www.north.com and/or the sites of subsidiaries or affiliated companies (the “Site” or “Sites”). This Privacy Policy describes how we may collect, use, or disclose information about you; your choices regarding our use of your information; how we safeguard such information; and how you may contact us regarding our privacy practices.</p>
          <strong className='sectionHeading'>Collection of personal information.</strong>
          <p>Information you provide: We may obtain information that you provide directly to us when you interact with the Site(s) (e.g., when you fill out our contact form). This information may include personal information, which is information that can be used to identify you individually, such as your name, email address, and phone number.</p>
          <p>Information collected automatically: We may collect certain information automatically using cookies and other online tracking technologies. The types of information we collect may include IP addresses, device identifiers, internet or other electronic network activity information, browser characteristics, operating system details, language preferences, referring URLs, length of visits, and pages viewed.</p>
          <p>Please note that we or third parties may collect personal information about your online activities over time and across different devices and online properties when you use the Site(s).</p>
          <p>Information collected from other parties. We may also obtain your personal information from our service providers, vendors, and other parties. For example, if you apply for a job using the career portal, you may submit personal information to one or more of our service providers that may disclose your information to us. We may combine information that we have about you with information we obtain from other parties. When you submit information to another party, you are subject to that third party’s terms of use and privacy policies, for which we are not responsible.</p>
          <strong className='sectionHeading'>Use of personal information.</strong>
          <p>We may use personal information we obtain through the Site(s) to:</p>
          <ul>
            <li>Facilitate and personalize your user experience;</li>
            <li>Monitor and analyze trends, usage, and activities of visitors and users of the Site(s);</li>
            <li>Administer and improve the Site(s), our marketing, or services we may offer;</li>
            <li>Deliver promotional content to you;</li>
            <li>Comply with applicable laws, regulations, or legal processes, as well as industry standards and our company policies;</li>
            <li>Prevent, investigate, identify, stop, or take any other action with regard to suspected or actual fraudulent or illegal activity, or any activity that violates our policies; or</li>
            <li>For any other purpose, with your consent.</li>
          </ul>
          <strong className='sectionHeading'>Disclosure of personal information.</strong>
          <p>We may disclose your personal information to:</p>
          <ul>
            <li>Our subsidiaries, affiliates, and business partners to support our business operations;</li>
            <li>Our service providers and vendors that assist us in providing services to you; or</li>
            <li>Parties involved in a business transaction, such as if we sell or transfer all or a portion of our business or assets (e.g., further to a merger, reorganization, liquidation, or any other business transaction, including negotiations of such transactions).</li>
          </ul>
          <p>Additionally, we may disclose personal information when required by subpoena, search warrant, or other legal processes, or in response to activities that may be unlawful or a violation of our rules for use of the Site(s), or to protect and defend our rights or property.</p>
          <strong className='sectionHeading'>Cookies and tracking technologies.</strong>
          <p>We, and our vendors, may use cookies and other online tracking technologies to process data about your interactions with our Site(s), including to help you access and navigate the Site(s), retain your preferences, analyze your use of the Site(s), personalize content, deliver and customize advertisements, and track advertising campaigns.</p>
          <p>Your browser may allow you to review and delete cookies stored on your device, and/or to disable or otherwise prevent cookies from being placed on your device. If you disable cookies, you may not be able to use certain features of this Site, our other Sites, or other websites, and disabling cookies may invalidate opt outs that rely on cookies to function.</p>
          <p>We may disclose personal information to our vendors, including third parties, in connection with interest-based advertising. We and our vendors, including Meta, may use, store, or access cookies and other online tracking technologies to collect or receive information from the Site(s) and elsewhere on the Internet and use that information to provide advertising services, including advertisements targeted to your interests. In this context we may process your automatically-collected personal information, including identifiers, internet or other electronic network activity information, and inferences based on other categories of personal information.</p>
          <p>We may use certain web analytics services, including Google Analytics and Hotjar, to help us understand and analyze how visitors use the Site(s) and to serve ads on our behalf across the Internet. We’ve implemented Google Analytics Advertising features such as remarketing with analytics, interest-based advertising, demographics and interests reporting, technology and device reporting, user segment analysis, and impression reporting.</p>
          <p>For more information on how Google Analytics uses data collected through the Site(s), visit: www.google.com/policies/privacy/partners/. To opt out of Google Analytics cookies, visit: http://adssettings.google.com and https://tools.google.com/dlpage/gaoptout/.</p>
          <p>We also may use Google reCAPTCHA to protect our Site(s) from fraud and abuse. Your use of reCAPTCHA is subject to Google's Privacy Policy, available at http://policies.google.com/privacy, and Terms of Use, available at http://policies.google.com/terms.</p>
          <p>In certain instances, we may contract with vendors to help us analyze activity on our websites, our chat functionality, and mobile apps as applicable, through session replay technologies to help us understand and analyze how visitors use our services, and to improve the services. These technologies may collect the personal information, such as IP address, screen size, device type (unique device identifiers), browser information, geographic location, and preferred language.</p>
          <p>Some advertising vendors may participate in the Digital Advertising Alliance (“DAA”) and/or Network Advertising Initiative (“NAI”) and their opt-out programs. For more information about the DAA opt out tool, you can visit: http://www.youradchoices.com for web browsers or http://www.aboutads.info/appchoices for mobile apps. To learn more about the NAI opt-out tool, visit http://www.networkadvertising.org/choices for web browsers or networkadvertising.org/mobile-choice for mobile apps.</p>
          <p>Your web browser may have settings that allow you to transmit a “Do Not Track” signal when you visit various websites or use online services. Like many websites, these Sites are not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, visit http://www.allaboutdnt.com/.</p>
          <p>Please note that we do not control how external parties manage their opt-out processes.</p>
          <strong className='sectionHeading'>Vendor integrations and features.</strong>
          <p>We may engage vendors to provide certain interactive features on our Site(s). Your use of these interactive features is voluntary, and we may retain the information that you submit through these features. By using these features, you understand that our vendors may process the information obtained through the feature to provide the service on our behalf. For example, we may offer an interactive chat feature on our Site(s) to answer your questions and for other customer service purposes. When you participate in the interactive chat, either with a virtual or live agent, the contents of the chat may be captured and kept as a transcript.</p>
          <p>We may provide third-party interfaces or links on the Site(s) to facilitate your sending of a communication from the Site(s). For example, we may use third parties to facilitate “likes,” content sharing, or content submissions such as product reviews. On the Site(s), we may enable access to, or display of, third-party content, that is served or published by the third party; that third party may be collecting data from you in connection with that content. These third parties may collect and retain any information used or provided in connection with these interactions, and these third parties’ practices are not subject to our Privacy Policy. If you use these features, information you post or provide access to may be publicly displayed by the third-party service.</p>
          <strong className='sectionHeading'>Security.</strong>
          <p>We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Nevertheless, transmission via the Internet and online digital storage are not completely secure and we cannot guarantee the security of your information collected through the Site(s).</p>
          <strong className='sectionHeading'>Linked websites.</strong>
          <p>The Site(s) may include links to websites that are owned and/or operated by external parties. This Privacy Policy does not apply to those websites, which may have their own privacy policies or notices that you should review to understand how they may collect, use, or disclose your personal information. North is not responsible for the content or privacy practices of any such linked websites.</p>
          <p>The Site(s) may also provide links to websites operated by North. Unless indicated otherwise, these websites are not governed by this Privacy Policy. For information on how these websites may collect, use, or disclose your personal information, we encourage you to review the privacy policies posted on the linked websites.</p>
          <strong className='sectionHeading'>State-specific privacy rights.</strong>
          <p>Certain U.S. states have enacted privacy laws applicable to the residents of those states. Depending on your state of residence and relationship with us, you may have the right to:</p>
          <ul>
            <li>Confirm that we process your personal information;</li>
            <li>Know which categories of personal information we have collected about you;</li>
            <li>Access your personal information;</li>
            <li>Request a copy of your personal information in a portable format;</li>
            <li>Request that we correct or delete your personal information;</li>
            <li>Opt out of the sale and/or sharing of your personal information for targeted or cross-context behavioral advertising;</li>
            <li>Opt out of profiling in furtherance of decisions that produce legal or similarly significant effects; and/or</li>
            <li>Limit the use and disclosure of your sensitive personal information.</li>
          </ul>
          <p>We will not discriminate against individuals who choose to exercise their state privacy law rights.</p>
          <p>For further information regarding our privacy practices and/or your privacy rights, you may email us at privacy@north.com.</p>
          <strong className='sectionHeading'>Information for users outside the United States.</strong>
          <p>If you are visiting the Site(s) from outside the United States, please be aware that information we obtain about you will be processed in the United States or in other jurisdictions. By using the Site(s), you acknowledge your personal information may be transferred to and processed in jurisdictions outside your own, as described in this Privacy Policy. Please be aware that the data protection laws and regulations that apply to your personal information transferred to the United States or other countries may be different from the laws in your country of residence.</p>
          <strong className='sectionHeading'>Dispute resolution.</strong>
          <p>If a dispute arises between you and North, you and North agree, as permitted by applicable law, to resolve any claim or controversy at law or equity arising out of, relating to, or connected in any way with the Site(s) through binding arbitration or as we and you otherwise agree in writing. Before resorting to these alternatives, we strongly encourage you to first contact us directly to seek a resolution. We will consider reasonable requests to resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.</p>
          <p>Certain portions of this section are deemed to be a “written agreement to arbitrate” pursuant to the Federal Arbitration Act. You and North agree that we intend that this section satisfies the “writing” requirement of the Federal Arbitration Act. This section can only be amended by mutual agreement.</p>
          <strong className='sectionHeading'>Binding arbitration.</strong>
          <p>If any controversy, allegation, or claim relates in any way to your use of the Site(s) (collectively, “Dispute”), then you agree the Dispute shall be submitted to confidential arbitration in Michigan except that we may seek injunctive or other appropriate relief in any state or federal court. You hereby consent to, and waive, all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the state and federal courts of Michigan. Arbitration under these Terms shall be conducted pursuant to the Commercial Arbitration Rules then prevailing at the American Arbitration Association; any dispute shall otherwise be governed by the internal laws of the State of Michigan without regard to Michigan choice of law principles, except that the provisions of this agreement concerning arbitration shall be governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. The arbitrability of disputes shall be determined by the arbitrator. The arbitrator’s award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction.</p>
          <strong className='sectionHeading'>Limited time to file claims.</strong>
          <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of the Site(s) must be filed within one year after such claim or cause of action arose or be forever banned.</p>
          <strong className='sectionHeading'>Class action waiver.</strong>
          <p>DISPUTES WILL BE ARBITRATED, LITIGATED, OR OTHERWISE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND WILL NOT BE JOINED OR CONSOLIDATED WITH ANY OTHER ARBITRATIONS, LAWSUITS, OR OTHER PROCEEDINGS THAT INVOLVE ANY CLAIM OR CONTROVERSY OF ANY OTHER PARTY. YOU AND NORTH AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF, COUNTER-PLAINTIFF, OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</p>
          <strong className='sectionHeading'>Revisions</strong>
          <p>This Privacy Policy is subject to change at our discretion. We will indicate changes, including material changes, to the Privacy Policy by updating the “Effective Date” at the top of this page. Your continued use of this Site, or Sites, after any update to this Privacy Policy will constitute your acceptance of the changes.</p>
          <strong className='sectionHeading'>Contact information.</strong>
          <p>Please direct comments or questions regarding this Privacy Policy via e-mail to privacy@north.com.</p>
        </Box>
      </Box>
    )
  }
}
