/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, submit, getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import Box from '@mui/material/Box';

import Loading from '../components/Loading';
import FlowHeader from '../components/activate/FlowHeader';

import Button from '../components/shared/Button';
import IconButton from '../components/shared/IconButton';

import FilterUtil from '../components/util/FilterUtil';
import IconUtils from '../components/util/IconUtil';

import HideNeedHelpWidget from '../components/shared/HideNeedHelpWidget';

import {
  nextActivationStep,
  prevActivationStep, uploadBankAccountVerification
} from '../actions/activationActions';
import { getBoardingApplication } from '../actions/activationActions';

import { activateFlowStyles, label } from '../jss/activationStyles';
import Spinner from '../components/UpdateSpinner';
import actionTypes from '../constants/actionTypes';
import ActivationUtil from '../components/util/ActivationUtil';
import routes from '../constants/routes';
import MessageDialog from '../components/shared/MessageDialog';
import Modal from '../components/shared/Modal';
import {setTokenAutoRenew} from '../actions/authActions';
import {
  setSubmitButtonEnabled,
  suppressAppError,
  toggleUnableToActivateAccountDialog
} from '../actions/userExperienceActions';
import VerifyBankAccountModal
  from '../components/activate/VerifyBankAccountModal';
import FileUtil from '../components/util/FileUtil';
import messages from '../constants/messages';
import {BUSINESS} from '../constants/activationStatuses';

const appRoutePrefix = globalApplicationLabel.path;
const DASHBOARD_ROUTE = `${appRoutePrefix}${routes.activity.root}${routes.activity.dashboard}`;

export class ActivateFlow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      closeDialogOpen: false,
      openVerifyBankAccountDialog: false,
      importInProgress: false,
      importDetails: {
        fileStatus: false,
        fileName: false,
        error: null
      },
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onOpenVerificationDialog = this.onOpenVerificationDialog.bind(this);
    this.onCloseVerificationDialog = this.onCloseVerificationDialog.bind(this);
    this.onUploadVerificationClick = this.onUploadVerificationClick.bind(this);
    this.processUploadedBankAccountFile = this.processUploadedBankAccountFile.bind(this);

    this.componentRef = React.createRef();
  }

  componentDidMount() {
    this.loadBoardingApplication();
  }

  componentDidUpdate(prevProps) {
    const { activation, user, dispatch } = this.props;
    if (activation.activeStep !== prevProps.activation?.activeStep) {
      if (ActivationUtil.requiresLoad(activation)) {
        const currentStep = ActivationUtil.getStep(activation.activeStep);
        if (currentStep.loadSection !== activation.loadedSection) {
          dispatch(getBoardingApplication(user, currentStep.loadSection));
        }
      }
    }
  }

  async loadBoardingApplication() {
    const { dispatch, user, history, t } = this.props;
    const result = await dispatch(getBoardingApplication(user, 'owner', true));
    if (result.type === actionTypes.getBoardingApplicationSuccess) {
      if (!ActivationUtil.canEditApplication(result.response)) {
        history.replace(DASHBOARD_ROUTE);
        toastr.error('Error', t('Errors.Activation.Submitted'), {
          progressBar: false
        });
      } else if (ActivationUtil.isExternal(result.response)) {
        dispatch(toggleUnableToActivateAccountDialog(true));
        history.replace(DASHBOARD_ROUTE);
      }
    }
  }

  cancel() {
    this.props.history.push(appRoutePrefix);
  }

  requestCancel(closeDialogOpen) {
    this.setState({ closeDialogOpen });
  }

  async next() {
    const { activation, user, dispatch, t, merchantCategories } = this.props;
    this.componentRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
    const currentStep = ActivationUtil.getStep(activation.activeStep);
    const currentForm = this.props[currentStep.form];
    const isBusinessForm = currentStep.form === 'activationBusinessForm';

    let disabled = false;

    if (!!currentStep.form) {
      dispatch(submit(currentStep.form));
      disabled = ActivationUtil.isFormInvalid((currentForm));
    }

    if (!disabled && currentStep.isValid) {
      disabled = !currentStep.isValid(activation, t);
    }

    if (!disabled) {
      if (ActivationUtil.requiresSubmission(activation, currentForm)) {
        const filteredMerchantCategories = merchantCategories?.filteredMerchantCategories || [];
        const response = await currentStep.onSubmit(
          dispatch, currentForm?.currentValues, user, activation, filteredMerchantCategories
        );

        const allowedResponses =
          [
            actionTypes.enableBoardingTrial,
            actionTypes.updateBoardingApplicationSuccess
          ];

        if (isBusinessForm && response?.response?.status?.stageCompleted === BUSINESS) {
          this.onOpenVerificationDialog();
          return;
        }

        if (response?.response?.application?.business?.isHighRisk) {
          this.cancel();
        } else if (allowedResponses.includes(response.type)){
          dispatch(nextActivationStep());
        }

      } else if (isBusinessForm && activation.status?.stageCompleted === BUSINESS) {
        this.onOpenVerificationDialog();
      } else {
        dispatch(nextActivationStep());
      }
    }
  }

  previous() {
    this.props.dispatch(prevActivationStep());
    this.componentRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
  }

  cleanBankAccountVerification() {
    this.setState({
      importInProgress: false,
      importDetails: {
        fileStatus: false,
        fileName: false,
        error: null
      }
    });
  }

  onOpenVerificationDialog() {
    this.cleanBankAccountVerification();
    this.setState({
      openVerifyBankAccountDialog: true
    });
  }

  onCloseVerificationDialog() {
    const { activation } = this.props;
    const isHighRisk = activation?.isHighRisk;

    this.cleanBankAccountVerification();
    this.setState({
      openVerifyBankAccountDialog: false
    }, () => {
      isHighRisk && this.cancel();
    });
  }

  onUploadVerificationClick() {
    this.props.dispatch(submit('importBankAccountVerificationForm'));
  }

  onFileDrop = (files, details) => {
    const { t } = this.props;

    if (files[0]?.name) {
      this.setState({
        importDetails: {
          fileStatus: t('ImportedFileUploaded', {fileName: files[0].name}),
          fileName: files[0].name,
          error: null
        }
      });

      this.props.dispatch(setSubmitButtonEnabled(true));

    } else {
      let errorMessage;
      const errorCode = details && details[0]?.errors && details[0]?.errors[0]?.code;

      if (errorCode === 'file-invalid-type') {
        errorMessage = 'OnDropFile.InvalidType';
      } else if (errorCode === 'file-too-large') {
        errorMessage = 'OnDropFile.ExceedsSize';
      } else {
        errorMessage = 'OnDropFile.ImportFailed'
      }

      this.setState(prevState => ({
        ...prevState,
        importInProgress: false,
        importDetails: {
          ...prevState.importDetails,
          error: errorMessage
        }
      }));
    }
  }

  onFileRemove = () => {
    this.setState({
      importDetails: {
        fileStatus: false,
        fileName: false,
        error: null
      },
    });
    this.props.dispatch(setSubmitButtonEnabled(false));
  }


  async processUploadedBankAccountFile(values) {
    const {dispatch, user, t, activation, history} = this.props;
    dispatch(setTokenAutoRenew(true));

    this.setState(prevState => ({
      ...prevState,
      importInProgress: true,
      importDetails: {
        ...prevState.importDetails,
        fileStatus: t('ImportInProgress', {fileName: this.state.importDetails.fileName}),
        error: null
      }
    }));

    const file = values.bank_account_verification[0];
    const fileBase64 = await FileUtil.getBase64(file);
    const payload = {
      filename: file.name,
      attachment: fileBase64?.base64?.split(',')?.pop()
    };

    dispatch(suppressAppError(true));
    const response = await dispatch(uploadBankAccountVerification(user, payload));

    if ([actionTypes.putBankAccountVerificationSuccess, actionTypes.putBankAccountVerificationFailure].includes(response?.type)) {
      dispatch(setTokenAutoRenew(false));
    }

    if (response?.type === actionTypes.putBankAccountVerificationFailure) {
      this.setState(prevState => ({
        ...prevState,
        importInProgress: false,
        importDetails: {
          ...prevState.importDetails,
          error: messages.errors.internalProcessingError
        }
      }), () => {
        activation?.isHighRisk && this.cancel();
      });
    } else if (response?.type === actionTypes.putBankAccountVerificationSuccess) {
      this.onCloseVerificationDialog();
      !activation?.isHighRisk && dispatch(nextActivationStep());
    }
  }

  render() {
    const { activation, merchantCategories, t, userExperience} = this.props;
    const { importInProgress, importDetails } = this.state;
    const steps = ActivationUtil.getSteps();
    const currentStep = steps[activation.activeStep];
    const isFinalStep = activation.activeStep === steps.length - 1;
    const previousButton = activation.activeStep > 0 && (
      <IconButton
        className='iconButton prevButton'
        onClick={this.previous}
        tooltip={t('GoBackAction')}
        size='large'>
        {IconUtils.getIcon('Back')}
      </IconButton>
    );

    let error;

    const currentForm = this.props[currentStep.form];

    if (currentForm && currentStep?.form === steps[0].form) {
      error = currentForm.errors.primaryOwner;
    }

    const nextButtonLabel = !isFinalStep && typeof currentStep.action === 'function' ?
      currentStep.action(activation) : currentStep.action;

    const nextButton = !isFinalStep && (
      <Button
        className='action'
        onClick={this.next}
        label={t(nextButtonLabel)}
        type='submit'
        endIcon={IconUtils.getIcon(currentStep.actionIcon || 'ChevronRightIcon')}
        disabled={activation.loading || activation.isHighRisk}
        isSpaceBetween
      />);

    const closeConfirmationDialog = (
      <MessageDialog
        confirmText={t('Activation.Close.Proceed')}
        cancelText={t('Cancel')}
        isChoiceRequired={false}
        onConfirm={this.cancel}
        onRequestClose={() => this.requestCancel(false)}
        open={this.state.closeDialogOpen}
        titleText={t('Activation.Close.Title')}
        bodyText={t('Activation.Close.Message')}
      />
    );

    const isHighRisk = activation?.isHighRisk;

    const submitButtonDisabled = !userExperience.enableFormSubmitButton;
    const verifyBankAccountModal = (
      <Modal
        centerTitle
        cancelText={t(isHighRisk ? 'ExitAction' : 'GoBackAction')}
        confirmText={t('Submit')}
        hideConfirmButton={submitButtonDisabled}
        isCancelDisabled={false}
        isConfirmDisabled={submitButtonDisabled}
        maxWidth='sm'
        onClose={this.onCloseVerificationDialog}
        onConfirm={this.onUploadVerificationClick}
        open={this.state.openVerifyBankAccountDialog}
        title={t('Activation.BankAccountVerificationModal.Title')}
      >
        <VerifyBankAccountModal
          onFileDrop={this.onFileDrop}
          onFileRemove={this.onFileRemove}
          onSubmit={this.processUploadedBankAccountFile}
          uploading={importInProgress}
          errorMessage={importDetails.error}
          {...this.state}
          t={t}
        />
      </Modal>
    )

    return (
      <Box sx={activateFlowStyles} ref={this.componentRef}>
        <HideNeedHelpWidget />
        <FlowHeader
          title={t('Activation.Title')}
          steps={activation.steps}
          activeStep={activation.activeStep}
          loading={!!activation.loading}
          onCancel={() => this.requestCancel(true)}
          onPrevious={this.previous}
          t={t}
        />
        {activation.loading || merchantCategories?.isFetching ? <Loading /> :
        <div className={`flowContainer step${activation.activeStep + 1}`}>
          { previousButton }
          <div className='stepContainer'>
            <div className='stepContainer__title'>{t(currentStep.title)}</div>
            {!!currentStep.subtitle && <div className='stepContainer__subtitle'>{t(currentStep.subtitle)}</div>}
            <div className='stepContainer__content'>
              {currentStep.component(this.props)}
              {error && <h3 className='error'>{t(error)}</h3>}
            </div>
          </div>
          <div className='stepActions'>
            <Box sx={currentStep.imageStyles} />
            <Box sx={label} className='nextButton'>{nextButton}</Box>
          </div>
        </div>
        }
        {closeConfirmationDialog}
        {verifyBankAccountModal}
        {activation.updating && <Spinner />}
      </Box>
    )
  }

}

export function mapStateToProps(state) {

  const { activation, user, visibilityFilter, userExperience } = state;

  const merchantCategories = FilterUtil.filterMerchantCategories(state.merchantCategories, visibilityFilter);

  return {
    ownersForm: {
      errors: getFormSyncErrors('ownersForm')(state),
      currentValues: getFormValues('ownersForm')(state),
      touched: state.form?.ownersForm?.anyTouched,
    },
    activationBusinessForm: {
      errors: getFormSyncErrors('activationBusinessForm')(state),
      currentValues: getFormValues('activationBusinessForm')(state),
      touched: state.form?.activationBusinessForm?.anyTouched,
    },
    activation,
    user,
    visibilityFilter,
    merchantCategories,
    userExperience
  }
}

export default connect(mapStateToProps)(ActivateFlow);
