/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import {setGeoLocation, getGeoTax, setGeoTaxRate, setTaxRate} from '../../actions/taxesActions';
import {getMerchantSettings} from '../../actions/merchantSettingsActions';
import LocalStorageUtil from '../util/LocalStorageUtil';
import {setGeoTaxFetching} from '../../actions/userExperienceActions';
import _ from 'lodash';

const TaxUtil = {


  getGeoLocation(componentProperties) {
    const thisProps = componentProperties.props;

    const hasGeoLocation = ('geolocation' in navigator);

    const locationPromiseWithTimeout = hasGeoLocation ? Promise.race([
      TaxUtil.getPosition(thisProps),
      new Promise((resolve) => {
        setTimeout(resolve, 8000); // continue loading if permission pending
      })
    ]) : null;

    if (_.isEmpty(thisProps.merchantSettings.merchantSettings)) {

      return new Promise((resolve, reject) => {

        if (hasGeoLocation) {

          const requestArray = [];

          requestArray.push(thisProps.dispatch(getMerchantSettings(thisProps.user)));
          requestArray.push(locationPromiseWithTimeout);

          Promise.all(requestArray).then((response) => {

            const merchantSettings = response[0].response.merchant_settings;
            const positionCoordinates = _.get(response, '[1].coords') || _.get(thisProps.taxes.geoLocation, 'coords') || {};
            TaxUtil.processMerchantTaxRate(positionCoordinates, merchantSettings, thisProps);
            resolve();

          }).catch(function (err) {
            TaxUtil.updateTaxValue(thisProps.merchantSettings.taxRate, thisProps);
            resolve();
          });
        } else {
          TaxUtil.updateTaxValue(thisProps.merchantSettings.taxRate, thisProps);
          resolve();
        }
      })
    } else {
      return new Promise((resolve, reject) => {

        if (hasGeoLocation) {

          locationPromiseWithTimeout.then((response) => {

            const positionCoordinates = _.get(response, 'coords') || _.get(thisProps.taxes.geoLocation, 'coords') || {};
            TaxUtil.processMerchantTaxRate(positionCoordinates, thisProps.merchantSettings.merchantSettings, thisProps);
            resolve();
          });
        } else {
          TaxUtil.updateTaxValue(thisProps.merchantSettings.taxRate, thisProps);
          resolve();
        }
      });
    }
  },
  processMerchantTaxRate(coordinates, merchantSettings, props) {

    return new Promise((resolve, reject) => {
      if (merchantSettings.is_geotax_enabled && coordinates && (coordinates.latitude && coordinates.longitude)) {
        TaxUtil.fetchTax(props, coordinates).then(() => {
          resolve();
        });
      } else {
        TaxUtil.updateTaxValue(merchantSettings.tax_rate, props);
        resolve();
      }
    });
  },

  fetchTax(thisProps, coords) {
    return new Promise((resolve, reject) => {
      thisProps.dispatch(getGeoTax(coords, thisProps.user)).then(function (resp) {
        const stateTaxValue = resp.response.state * 100;
        LocalStorageUtil.setGeoTax(stateTaxValue);
        thisProps.dispatch(setGeoTaxRate(stateTaxValue));
        TaxUtil.updateTaxValue(stateTaxValue, thisProps);
        resolve(stateTaxValue);
      }).catch(function () {
        TaxUtil.updateTaxValue(thisProps.merchantSettings.taxRate, thisProps);
        resolve(0);
      });
    })
  },

  getPosition(props) {
    return new Promise((resolve, reject) => {

      // if coords doesn't already exist then wait longer for the initial timeout value
      // for browser to locate user after permission was granted
      const requestTimeout = _.has(props.taxes.geoLocation, 'coords') ? 7000 : 30000;

      const options = {
        timeout: requestTimeout,
        enableHighAccuracy: false,
      };

      try {
        navigator.geolocation.getCurrentPosition((position) => {
          props.dispatch(setGeoLocation(position));
          LocalStorageUtil.setLocation(position);
          resolve(position);
        }, (error) => {
          resolve(error);
        }, options);
      } catch (error) {
        resolve(error);
      }
    });
  },

  updateTaxValue(taxValue, thisProps) {
    thisProps.dispatch(setTaxRate(taxValue));
    thisProps.dispatch(setGeoTaxFetching(false));
  },

  roundedUpDisplayValue(taxRate) {

    const validNumber = isNaN(taxRate) ? 0 : taxRate;

    return (Math.round(validNumber * 1000) / 1000).toFixed(3);
  }


};

export default TaxUtil;
