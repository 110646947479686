/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BatchDetailsTableSummaryHeader from './BatchDetailsTableSummaryHeader';
import MasterDetailLayout from '../../MasterDetailLayout';
import NoData from '../../NoData';
import CsvUtil from '../../util/CsvUtil';
import CsvExporter from '../../util/CsvExporter';
import ReportUtil from '../../util/ReportUtil';
import MobileUtil from '../../util/MobileUtil';
import FormatTextUtil from '../../util/FormatTextUtil';
import TableHeader from '../../shared/TableHeader';
import Page from '../../shared/Page';
import Pagination from '../../shared/PaginationMui';
import { getBatchDetail, getBatchDetailsSummary, setBatch } from '../../../actions/batchesActions';
import { setVisibilityFilter } from '../../../actions/userExperienceActions';
import routes from '../../../constants/routes';
import generalOptions from '../../../constants/generalOptions';

export default class BatchDetailsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isGoBackAction: false,
      batchDetailsPage: 1
    };
  }

  async componentDidMount() {
    const { batches, dispatch, match, user, userExperience } = this.props;
    const { historicHardwareMode } = userExperience;

    if(!!batches.selectedBatch?.uniq_id && batches.selectedBatch.uniq_id !== match.params.batchId) {
      dispatch(setBatch(null));
    }

    if (!batches.selectedBatch?.detail?.transactions || batches.selectedBatch?.uniq_id !== match.params.batchId) {
      await dispatch(getBatchDetailsSummary(user, match.params.batchId, historicHardwareMode));
      await this.loadPaginatedData(1);
      await this.onChangePage(null, 1);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    const { isGoBackAction } = this.state;

    if (!isGoBackAction) {
      dispatch(setBatch(null));
    }
  }

  loadPaginatedData = async (page) => {
    const { match, user, userExperience, dispatch } = this.props;
    const { historicHardwareMode } = userExperience;

    const selectedBatch = { uniq_id: match.params.batchId };

    this.setState({ batchDetailsPage: page });
    await dispatch(getBatchDetail(user, selectedBatch, historicHardwareMode, page));
  };

  onChangePage = async (ev, page) => {
    const { batches, dispatch } = this.props;
    const { batchDetailsPage } = this.state;
    const { maximumPerPage } = generalOptions;

    const pageSize = batches.selectedBatch?.detail?.transactions?.pageSize || maximumPerPage;
    const numberOfLoadedPages = Math.ceil(pageSize / maximumPerPage);
    const loadedPagesEnd = numberOfLoadedPages * batchDetailsPage;
    const loadedPagesStart = numberOfLoadedPages * (batchDetailsPage - 1) + 1;

    if (page > loadedPagesEnd || page < loadedPagesStart) {
      const rowCount = page * maximumPerPage;
      const newBatchDetailsPage = Math.ceil(rowCount / pageSize);
      await this.loadPaginatedData(newBatchDetailsPage);
      return this.onChangePage(ev, page);
    }

    dispatch(setVisibilityFilter(page, 'pagination'));
  };

  handleExport = () => {
    const { batches } = this.props;
    const batchId = batches.selectedBatch?.batch_id;

    const batchDetailJson = CsvUtil.createsBatchDetailCsv(batches.selectedBatch);
    const batchDetailFileName = batchId
      ? `Batch_Details_ID_${batchId}_${moment().format('MMDDYYYY')}.csv`
      : `Batch_Details_${moment().format('MMDDYYYY')}.csv`;

    CsvExporter.download(batchDetailJson, batchDetailFileName);
  };

  handlePrint = () => {
    const { batches } = this.props;

    const batchDetailJson = CsvUtil.createsBatchDetailCsv(batches.selectedBatch);
    const reportWindow = window.open('about:blank');
    const html = ReportUtil.createHtmlFromCsvReport('Batch Details', this.props, batchDetailJson);

    if (!!reportWindow?.document) {
      reportWindow.document.write(html);
      reportWindow.document.title = 'Batch Details Report';
    }
  };

  redirectToBatches = () => {
    const { history } = this.props;
    const batchesRoute = `${globalApplicationLabel.path}${routes.activity.root}${routes.activity.batches}`;

    this.setState({ isGoBackAction: true }, () => history.push(batchesRoute));
  };

  render() {

    const { batches, visibilityFilter, t } = this.props;
    const selectedBatchDetailTransactions = batches.selectedBatch?.detail?.transactions;
    const paginatedBatchDetailTransactions = batches.selectedBatch?.detail?.paginatedTransactions;
    const isMobile = MobileUtil.isMobileDevice();

    const pageLoading = !selectedBatchDetailTransactions || !paginatedBatchDetailTransactions || batches.isFetching || batches.isFetchingDetails;

    if (pageLoading) {
      return <Page title={t('BatchDetails')} loading />;
    }

    const title = batches.selectedBatch?.batch_id ? `${t('BatchesTable.BatchNumber')}${batches.selectedBatch.batch_id}` : `${t('BatchDetails')}`;
    const subTitle = batches.selectedBatch?.batch_date && `${moment(batches.selectedBatch.batch_date).format('MMMM D, YYYY h:mm a')}`;

    const isLargeBatchDetailsList = selectedBatchDetailTransactions?.rowCount > selectedBatchDetailTransactions?.pageSize;
    const titlePanel = (
      <BatchDetailsTableSummaryHeader
        batches={batches}
        isLargeBatchDetailsList={isLargeBatchDetailsList}
        title={title}
        subTitle={subTitle}
        goBackAction={this.redirectToBatches}
        exportAction={this.handleExport}
        printAction={this.handlePrint}
        t={t}
      />
    );

    const tableHeaders = (paginatedBatchDetailTransactions.length &&
      <TableRow className={'tableRow'}>
        <TableHeader
          attribute={'date'}
          clickable
          headerId={'date'}
          name={isMobile ? t('BatchDetailsTable.Time') : t('BatchDetailsTable.Date')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'brand'}
          clickable
          headerId={'brand'}
          name={t('BatchDetailsTable.Brand')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'last4'}
          clickable
          headerId={'last4'}
          name={t('BatchDetailsTable.Card')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'type'}
          clickable
          headerId={'type'}
          name={t('BatchDetailsTable.Type')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'process'}
          clickable
          headerId={'process'}
          name={t('BatchDetailsTable.Process')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'amount'}
          clickable
          headerId={'amount'}
          name={t('BatchDetailsTable.Total')}
          type='table'
          {...this.props}
        />
      </TableRow>
    ) || null;

    const tableRows = (
      paginatedBatchDetailTransactions.length ?
        paginatedBatchDetailTransactions.map((trans, index) => {
          return (
            <TableRow className='tableRow' key={index}>
              <TableCell>
                {isMobile
                  ? moment(trans.date).format('h:mm a')
                  : moment(trans.date).format('MM/DD/YYYY') + ' ' + moment(trans.date).format('h:mm a')
                }
              </TableCell>
              <TableCell>{FormatTextUtil.paymentTypeFormatTable(trans.brand)}</TableCell>
              <TableCell>{trans.last4}</TableCell>
              <TableCell>{trans.type}</TableCell>
              <TableCell>{trans.amount < 0 ? t('BatchDetailsTable.ProcessType.Refund') : t('BatchDetailsTable.ProcessType.Sale')}</TableCell>
              <TableCell>{numeral(trans.amount).format('$0,0.00')}</TableCell>
            </TableRow>
          );
        })
      : <NoData text={t('NoBatchDetails')} type='table' />
    );

    const tableRowLayout = (
      <TableBody>
        {tableHeaders}
        {tableRows}
      </TableBody>
    );

    const isPaginated = selectedBatchDetailTransactions?.rowCount > generalOptions.maximumPerPage;
    const paginationComponent = isPaginated && (
      <Pagination
        rowCount={selectedBatchDetailTransactions?.rowCount}
        onChangePage={this.onChangePage}
        pageSize={generalOptions.maximumPerPage}
        page={visibilityFilter.pagination}
      />
    );

    return (
      <section className='batchDetailsTable'>
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('BatchDetails')}
          pageLoading={pageLoading}
          paginationComponent={paginationComponent}
          header={titlePanel}
          showLogo
          tableData={paginatedBatchDetailTransactions}
          tableRowLayout={tableRowLayout}
        />
      </section>
    );

  }

}
