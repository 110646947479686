/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */


export const TRANSLATIONS_EN = {

  // Shared Statements
  welcome: 'Welcome',
  week: 'week',
  month: 'month',
  year: 'year',
  downward: 'downward',
  upward: 'upward',
  AccountLocked: 'Account Locked',
  Action: 'Action',
  BackToForgotPassword: 'Back to forgot password',
  BackToLogin: 'Back to login',
  ConfirmNewPassword: 'Confirm New Password',
  CreateResetPassword: 'Create or Reset Password',
  ResetPassword: 'Reset Password',
  ExistingPassword: 'Existing Password',
  Date: 'Date',
  Default: 'Default',
  Disconnect: 'Disconnect',
  EmailAddress: 'Email Address',
  EmailAddressPlaceholder: 'Enter your email address',
  FirstName: 'First Name',
  ForgotPassword: 'Forgot Password',
  InvalidToken: 'Invalid Token',
  Invoice: 'Invoice',
  Invoices: 'Invoices',
  EnhancedInvoices: 'Enhanced Invoices',
  LastFourDigitsSsn: 'Last 4 Digits of SSN',
  LearnMore: 'Learn more',
  MIDAccountNumber: 'Merchant ID/Account Number',
  Month: 'Month',
  Name: 'Name',
  NewPassword: 'New Password',
  Note: 'Note',
  Offline: 'Offline',
  Online: 'Online',
  Password: 'Password',
  PasswordPlaceholder: 'Enter password',
  PhoneNumber: 'Phone Number',
  MobileNumberLabel: 'Mobile Number',
  MobileNumberHint: 'Enter mobile number',
  MobileNumberDescription: 'To protect your account, your employee will receive unique code to this number in order to verify their identity when logging in to Payments Hub.',
  Processor: 'Processor',
  LogIn: 'Log In',
  SoldBy: 'Sold By',
  Ssn: 'SSN',
  SsnHint: 'Social Security Number',
  Subtotal: 'Subtotal',
  Success: 'Success',
  Failure: 'Failure',
  Tax: 'Tax',
  ThankYou: 'Thank You.',
  Tip: 'Tip',
  Total: 'Total',
  UserRegistration: 'User Registration.',
  AddTip: 'Add Tip',
  Optional: 'Optional',
  Zip4: 'Zip + 4',
  Zip: 'Zip',
  BillingStreetNumber: 'Billing Street Number',
  Expiration: 'Expiration',
  City: 'City',
  State: 'State',
  VerifyPassword: 'Verify Password',
  PartialAuth: 'Partial Auth {{cardInfo}}',
  BalanceRemaining: 'Balance Remaining',
  InvoiceAmount: 'Invoice Amount',
  ViewEnrollment: 'View enrollment',
  PoweredBy: 'Powered by {{company}}',
  SwitchTo: 'Switch to',
  OneTimePayment: 'One-Time Payment',
  AddEmployee: 'Add Employee',
  ExportCustomers: 'Export Customers',
  DBAName: 'DBA Name',
  BusinessAddress: 'Business Address',
  AccountNumber: 'Account Number',
  RoutingNumber: 'Routing Number',
  Checking: 'Checking',
  Savings: 'Savings',
  ClipboardPermissonError: 'Clipboard permission required, check browser settings',
  // Shared Statements

  CustomerService: 'Customer Service',
  NoAccountName: 'No Account Name',
  TermsIndicator: '* Accept Terms',
  NeedsInfoIndicator: '* Limited access',

  // Action Buttons
  Approved: 'Approved',
  Cancel: 'Cancel',
  Close: 'Close',
  Confirm: 'Confirm',
  Continue: 'Continue',
  Create: 'Create',
  Delete: 'Delete',
  GotIt: 'Got it',
  LogOut: 'Log out',
  No: 'No',
  NoVoid: 'No, void',
  Ok: 'Ok',
  Okay: 'Okay',
  OptOut: 'Opt out',
  Print: 'Print',
  Save: 'Save',
  SaveDraft: 'Save as draft',
  DraftSaved: 'Draft saved',
  SaveProfile: 'Save profile',
  Submit: 'Submit',
  Upgrade: 'Upgrade',
  Yes: 'Yes',
  Respond: 'Respond',
  Rebut: 'Rebut',
  Accept: 'Accept',
  Attach: 'Attach',
  GoBackAction: 'Go Back',
  View: 'View',
  DontShowAgain: 'Don\'t show again',
  HideProgress: 'Hide progress',
  StartOver: 'Start Over',
  'Create Report': 'Create Report',
  'Create Invoice': 'Create Invoice',
  'Create Customer': 'Create Customer',
  'Create Employee': 'Create Employee',
  Loading: 'Loading',

  //login page
  BusinessOwner: 'I\'m a business owner',
  ImageText: 'Payments made easy for business owners, <br/> employees, and customers.',
  LoginError: 'There was an unexpected error during the authentication process, please check your information and try again.',
  NoPassword: 'Forgot your password or don’t have one?',
  Register: 'Register Now',
  SalesPartner: 'I\'m a sales partner',
  ResendCode: 'Resend Code',
  VerifyCode: 'Verify Code',
  EnterCode: 'Enter Code',
  TwoFactorMessageWithNumber: 'A verification code has been sent to your mobile number ending in',
  TwoFactorMessage: 'A verification code has been sent to your mobile number.',
  VerificationCodeLogin: 'Verification Code',
  success2FaSetupMessage: 'Mobile number verified. Please login again to your account.',
  errorRequestCodeSetup2fa: 'There was an issue sending the code, please try again.',
  errorVerifyCodeSetup2fa: 'There was an issue validating the code, please try again.',
  RememberDevice: 'Remember this device',
  RememberDeviceDescription: 'Enable if you use this browser or device regularly and don’t want to enter a code each time you log in. You can manage your devices in Settings.',
  MerchantLogin: 'Merchant Login',
  WelcomeBack: 'Welcome Back',
  GoToPartnerHub : 'Go to Partner Hub',
  NorthPartners : 'North Partners',
  GoToDeveloperHub : 'Go to Developer Hub',
  NorthDeveloper : 'North Developer',
  MFASetupDisclaimer: {
    Title: 'Protect your account.',
    Description: 'We now require two-factor authentication. Enter your mobile number to receive a verification code.',
    Footnote: 'Message and data rates may apply.',
  },
  MFAFormDisclaimer: {
    Title: 'Protect your account.',
    Description: 'For your security, we now require two-factor authentication.'
  },
  MFABlockDisclaimer: {
    Title: 'Protect your account.',
    Description: 'For your security, we now require two-factor authentication. Please ask the Owner or Admin of the account to add a mobile number to your employee profile.'
  },
  ForceResetPasswordDisclaimer: {
    Title: 'Your password has expired.',
    Description: 'Please reset your password in order to continue. Once you’ve changed it, log back in to Payments Hub with your new password.'
  },

  PricingDetailsModal: {
    PricingDetails: 'Pricing Details',
    SwipedRate: 'Swiped Rate',
    KeyedRate: 'Keyed Rate',
    KeyedRateDescription: 'Manual Credit Transactions, Virtual Terminal, Invoice and Payment Links Payments',
    OtherFees: 'Other Fees (if applicable)',
    MPosPerItem: 'mPOS Per Item Fee',
    MPosPerDay: 'mPOS Per Day Per Active Device Fee',
    MPosPerDayDescription: 'These fees are specific to Tap to Pay transactions and do not apply if you do not process Tap to Pay transactions. To disable Tap to Pay functionality, go to your POS app → Settings → Payment Type.',
    RetrievalFee: 'Retrieval Fee',
    ChargebackFee: 'Chargeback Fee',
    NSFFee: 'NSF Fee',
    InactivityFee: 'Inactivity Fee',
    InactivityFeePerMonth: '$3.99/mo',
    InactivityFeeDescription: 'Applicable only to merchants who do not process a transaction for 12 months. When processing resumes, the inactivity fee will no longer be charged.',
    CellularDataProgram: 'Cellular Data Program (applies to Smart Payment Devices only)',
    MonthlyCellularData: 'Monthly Cellular Data',
    MonthlyCellularDataPerMonthPerDevice: '$9.95/mo/device',
    MonthlyCellularDataDescription: 'First 10mb free. Up to 250 mb. Each additional 250 mb is an additional $9.95/mo/device.',
    CellularDataTransaction: 'Cellular Data Transaction',
    CellularDataTransactionDescription: 'First 25 transactions free.'
  },
  DevicePricingModal: {
    DevicePricing: 'Device Pricing',
    DeviceName: 'Device Name',
    OneTimeSetupFee: 'One-Time Setup Fee',
    SoftwareFee: 'Software Fee',
    PurchasePrice: 'Purchase Price',
    Device: 'device',
    MoDevice: 'mo/device'
  },

  // Login footer
  Chat: 'Chat',
  EmailUs: 'Email Us',
  FAQ: 'FAQ',
  LiveChat: 'Live Chat',
  New: 'New',
  PaymentsHubDevelopers: 'Dev Portal: Payments Hub Developers',
  PrivacyPolicy: 'Privacy Policy',

  // Reset Password
  InstructionText: 'Your token is not valid, or expired, please try forgot password again.',
  ResetPasswordDescription: 'Enter a new password. You’ll use this password to log in to North, Payments Hub, and Payanywhere products. For your security, we ask you to enter a few additional details to verify it’s you.',
  ResetPasswordForm: {
    TwoFactorToken: 'Enter the Verification Code Sent to Your Mobile Number',
    RequestToken: 'Resend Code',
    SuccessText: 'You have reset your password. Return to the login screen and enter your new password to log in to North, Payments Hub, and Payanywhere products.',
    EnterCode: 'Enter Code'
  },

  // Forgot Password
  ForgotPasswordDescription: 'Enter your email address to get started. You’ll receive an email with further instructions on how to create or reset your password for North, Payments Hub, and Payanywhere products.',
  ForgotPasswordForm: {
    SuccessHeader: 'Check your inbox.',
    SuccessText: 'We\'ve sent instructions for creating or resetting your password to your email address.'
  },
  ForgotPasswordMPB: {
    Header: 'Request Verification Token',
    Text: 'A unique token will be sent to your email and is required to reset your password.'
  },

  // Set Password
  SetPasswordHeader: 'Set Password',
  SetPasswordInvalid: {
    Text: 'Your token is not valid, or expired, please try requesting again.'
  },
  SetPasswordSuccess: {
    Text: 'Your password has been set. Return to the login screen and sign in with your new password.'
  },
  SetMBPPasswordForm: {
    Header: 'Reset password.',
    SSNTaxDivider: ' OR ',
    TaxId: 'Last 4 Tax id',
    TaxIdHint: 'Last 4 Digits of Tax Id'
  },

  //Mid Selector
  SelectMID: 'Select the MID you wish to use',
  //Mid Selector

  // Dashboard
  DeveloperAccess: 'Developer Access',
  LoyaltyNorth: 'Loyalty by North',
  LoyaltyPayAnywhere: 'Loyalty by Payanywhere',
  LoyaltyDescription: 'Build a custom program that incentivizes customers to keep coming back.',
  ReputationManagementDescription: 'Manage customer reviews, view insights, and track competitors.',
  ExploreIntegrations: 'Explore Integrations',
  PowerUser: 'Become a Power User',
  GoFurtherPaymentsHubFeature: 'Go Further with Payments Hub',
  GoFurtherNorthFeature: 'Go Further with North',
  NorthFeature: 'Go Further with North',
  MoreFeatures: 'More Features',
  PremiumPlusDescription: 'Unlock Enhanced Invoicing, Recurring Payments, Cards on File, and Loyalty.',
  IntegrationsDescription: 'Browse a collection of powerful third-party tools to enhance your payments experience.',
  YelpIntegrationDescription: 'Be found by new customers with Yelp.',
  PowerUserText: 'Search product videos and helpful tutorials that will help you become a pro.',
  DashboardDisclaimer: '©2024 Payments Hub. All rights reserved.',
  DashboardNorthDisclaimer: 'North is a registered tradename of NorthAB, LLC, a registered Independent Sales Organization of Wells Fargo Bank, N.A., Concord, CA, BMO Harris Bank N.A., Chicago, IL, Citizens Bank N.A., Providence, RI, The Bancorp Bank, Philadelphia, PA, and FFB Bank Bank, Fresno, CA.',
  QuickReports: 'Quick Reports',
  TodayTransactions: 'Today\'s Transactions',
  ViewTransactions: 'View Transactions',
  ViewBatches: 'View Batches',
  TodayFlashReport: 'Today\'s Flash Report',
  MostRecentDeposit: 'Most Recent Deposit',
  MostRecentStatement: 'Most Recent Statement',
  NewInvoice: 'New Invoice',
  NewPaymentLink: 'New Payment Link',
  ManageDisputes: 'Manage Disputes',
  GetHelp: 'Get Help',
  NorthDevelopers: {
    HeaderNH: 'Go Further with North',
    HeaderPH: 'Go Further with Payments Hub',
    Title: 'North Developers',
    Description: 'Integrate to the North payments ecosystem with easy to use APIs and SDKs.'
  },
  Deposits: 'Deposits',
  NewDisputes: 'New Disputes',
  Snapshot: 'Snapshot for',
  Activity: 'Activity',
  TransactionVolume: 'Transaction Volume',
  PaymentMethodBreakdown: 'Payment Method Breakdown',
  // Dashboard

  //Sidebar menu
  Dashboard: 'Dashboard',
  Sales: 'Sales',
  Transactions: 'Transactions',
  Deposit: 'Deposit',
  Batches: 'Batches',
  Reports: 'Reports',
  Disputes: 'Disputes',
  Statements: 'Statements',
  'Virtual Terminal': 'Virtual Terminal',
  Customers: 'Customers',
  Inventory: 'Inventory',
  Employees: 'Employees',
  'Hardware & Supplies': 'Hardware & Supplies',
  'Business Settings': 'Business Settings',
  //Sidebar menu

  // Statements
  DownloadButton: 'Download {{statements}} Selected',
  SearchStatements: 'Search Statements',
  StatementsEmpty: 'No statements are available for this merchant currently.',

  //Terms
  VerificationCode: 'Verification code',
  EnterVerificationCode: 'Enter verification code',
  SendNewCode: 'Send new code',
  'Send code': 'Send code',
  'Sending code': 'Sending code',
  'Verify code': 'Verify code',
  'Error validating the code': 'Error validating the code',
  'Code verified': 'Code verified',
  'Validating code': 'Validating code',
  'Verify phone': 'Verify phone',
  'Verifying phone': 'Verifying phone',
  'Error verifying phone': 'Error verifying phone',
  'Phone verified': 'Phone verified',
  //Terms

  // Business Settings Page
  ClosingSettingSecondaryTitle: 'Closing Setting',
  ManualClose: 'Manual Close',
  AutoClose: 'Default Host Close',
  AutoClosePrintTime: 'Auto Close Print Time',
  IsOwnerLabel: 'Auto Print Closing Report',
  Theme: 'Theme',
  Retail: 'Retail',
  Terminal: 'Terminal',
  DefaultSaleViewLabel: 'Default Sale View',
  ItemLibrary: 'Item Library',
  ExpressSale: 'Express Sale',
  ExpressCategoryEnabledLabel: 'Assign Categories to Express Items',
  ExpressCategoryEnabledSubtext: 'Allow Express Items to be associated with a category.',
  ShowInvoiceNumberingTitle: 'Invoice Numbering',
  ShowInvoiceNumberingOpt1Label: 'Use system generated invoice numbers',
  ShowInvoiceNumberingOpt1Subtext: 'Payanywhere provides system generated invoice numbers (may not be sequential).',
  ShowInvoiceNumberingOpt2Label: 'Define automatic invoice numbering',
  ShowInvoiceNumberingOpt2Subtext: 'Define your own invoice numbering and continue in sequential order (ex. 1, 2, 3). You can still enter a custom invoice number at the time of each sale on the Receipt Share screen.',
  ResetInvoiceNumbering: 'Reset invoice numbering',
  InvoiceNumberingStartField: 'Starting Invoice Number',
  InvoiceNumberingStartFieldSubtext: 'Enter the number you\'d like your invoices to start at. Note: Editing invoice numbering may result in duplicate invoice numbers with historic transactions.',
  InvoiceNumberingResetToggleLabel: 'Reset Invoice Numbering Per Batch',
  InvoiceNumberingResetToggleSubtext: 'After each batch, invoice numbering will reset based on your defined starting number. This setting will result in duplicate invoice numbers and will require you to match the invoice number to the batch date for reconciliation purposes.',
  InvoiceNumberingApplyAccountsLabel: 'Apply to all accounts',
  InvoiceNumberingApplyAccountsSubtext: 'Use your defined invoice number sequence across all accounts (MIDs) you own. When disabled, the sequence applies only to this account ({{selectedMid}})',
  ShowTaxSettingsTitle: 'Tax Settings',
  EnableDavoTitle: 'Enable Automatic Sales Tax Filing with DAVO',
  EnableDavoSubText: 'DAVO sets aside sales tax daily and pays and files it, on time and in full. First monthly filing is free to try.',
  DavoGetFreeMonthButton: 'Get First Month Filing Free',
  DavoConnectLabel: 'Connected',
  DavoPortalLink: 'Visit DAVO Portal',
  DavoWebsiteLink: 'Visit DAVO Website',
  DavoLegalLink: 'View Terms and Conditions',
  DavoTaxLink: 'View Tax Settings',
  DavoDisconnectLink: 'Disconnect',
  DavoIntegrationDetails: {
    Title: 'Integration Details',
    ConfirmTitle: 'Confirm Integration',
    DBALabel: 'DBA Name',
    MID: 'MID',
    Owner: 'Owner',
    BusinessAddress: 'Business Address',
    PaymentsHub: 'Payments Hub',
    Davo: 'DAVO',
    BodyText: 'DAVO sets aside sales tax daily and pays and files it, on time and in full. First monthly filing is free to try.',
    BottomBody: 'Get First Month Filing Free, then $54.99/mo per location',
    ConnectedBottomText: '$54.99/mo per location',
    ConnectedDate: 'Connected since {{date}}',
    Section1: {
      Title: 'Never worry about sales tax again.',
      Body: 'With DAVO and Payanywhere, sales tax is one less thing to worry about. DAVO Sales Tax automatically sets your sales tax aside daily using sales data from Payanywhere. When it’s time to file with the State, DAVO automatically files and pays your sales tax on time and in full.'
    },
    Section2: {
      Title: 'How DAVO integrates with Payanywhere.',
      Body: 'DAVO integrates directly with Payanywhere to determine exactly how much sales tax to set aside each day. That money is held in DAVO’s secure tax holding account, then automatically filed and paid to the state each month or quarter.'
    },
    Section3: {
      Title: 'Why DAVO?',
      Item1: '<0>Time savings:</0> For just $54.99 per month per location DAVO manages sales tax from start to finish so you can save time and not worry about sales tax. Avoid the last minute sales tax scramble to file and pay.',
      Item2: '<0>Fast setup:</0> It installs in minutes — and it’s easy.',
      Item3: '<0>Easy to use:</0> Once DAVO is set up, your sales tax is on autopilot. Use the online dashboard to access past sales tax filing records and to make updates to your account as needed.'
    },
    Legal: {
      Title: 'End User License Agreement',
      SubText: 'These DAVO by Avalara Supplemental Terms (“DAVO Terms”) govern Customer’s purchase and use of DAVO by Avalara. These DAVO Terms are in addition to, and incorporate by reference, the Avalara Service Terms and Conditions available at www.avalara.com/terms (the “Terms”). Any capitalized terms used in these DAVO Terms and not defined have the meanings given in the Terms.',
      Section1: {
        Title: 'Use of the Services.',
        Item1: 'Account Setup.',
        Item1Regular: ' Customer shall provide Avalara with all information requested by Avalara to establish and set up Customer’s account for DAVO by Avalara. Such information includes: (i) a list of taxing jurisdictions for which Customer requests Avalara prepare Returns (as defined in Section 3(a) below) (each, a “Filing Jurisdiction”); (ii) the dates for filing Returns in each of the Filing Jurisdictions (the “Scheduled Returns”); (iii) tax registration numbers and login information for Customer in each Filing Jurisdiction sufficient to allow Avalara to identify and access Customer’s account in that Filing Jurisdiction (the “Account Information”); and (iv) other information necessary to properly configure Customer’s Account and prepare the Returns (all such information in (i)-(iv) collectively, the “Filing Information”). Customer authorizes Avalara to access Customer’s accounts using Customer’s Account Information. Customer is solely responsible for timely providing and maintaining accurate, complete, and current Filing Information, and Avalara has no obligation to audit, verify, correct, or maintain any Filing Information. Customer acknowledges and agrees that its failure to provide accurate, current, and complete Filing Information (for example, tax registration numbers) may limit Avalara’s ability to file Returns and remit any tax amounts due (the “Taxes”).',
        Item2: 'Changes to Filing Information.',
        Item2Regular: ' Customer shall review its Filing Information periodically and shall promptly communicate any changes to Avalara. Customer shall submit any changes to the Filing Information to Avalara by the deadline specified in the Documentation. Any changes received after the deadline specified in the Documentation will be implemented and become effective in the subsequent month.',
        Item3: 'License.',
        Item3Regular: ' Avalara grants Customer a limited, nonexclusive, nontransferable, non-assignable, worldwide license to use and retain the Content that is returned by the Services to Customer solely for its internal compliance purposes in connection with the specific Return for which it was received.'
      },
      Section2: {
        Title: 'Customer Obligations.',
        Item1: 'Tax Data.',
        Item1Regular: ' Customer is solely responsible for the accuracy and completeness of all the data necessary to properly complete Returns (the “Tax Data”) and all Filing Information. Avalara does not audit, validate, or verify Tax Data. Customer shall transmit to Avalara via the method designated by Avalara all Tax Data for Customer’s Return(s) before the deadline specified in the Documentation.',
        Item2: 'Funding.',
        Item2Regular: ' Unless otherwise agreed in writing, Customer shall make sufficient funds available to pay the Taxes (“Tax Funds”) on a daily basis. Avalara will not provide any Tax Funds. Notwithstanding any other provision of the Agreement, Customer shall defend, indemnify, and hold Avalara, its Affiliates, and their respective officers, directors, employees, and representatives harmless from any Losses arising out of or related to Customer’s failure to timely provide immediately available Tax Funds for Taxes due. In order for the Tax Funds to be remitted timely to the applicable Filing Jurisdiction, provided all Tax Data and Filing Information is accurate and complete, Customer must do the following:',
        SubItem1: 'Customer shall provide bank account information for a bank account (the “Bank Account”) from which Customer authorizes Avalara to draw by ACH to pay Customer’s Taxes and any applicable DAVO by Avalara fees and Expenses.',
        SubItem2: 'Customer shall ensure the Bank Account has sufficient Tax Funds to pay the Taxes on a daily basis and any applicable DAVO by Avalara fees and Expenses by the deadlines specified in the Documentation. If Customer fails to timely and sufficiently fund the Bank Account, Customer shall remit all remaining payment due for the Taxes plus any interest or penalties that may accrue directly to the applicable Filing Jurisdiction. If the ACH payment Avalara draws from the Bank Account is cancelled or returned other than by Avalara, that will be considered a failure to timely fund the Bank Account. Avalara may suspend or terminate Customer’s subscription to DAVO by Avalara immediately upon notice if Customer fails to timely and sufficiently fund the Bank Account.',
        SubItem3: 'Customer authorizes Avalara to (i) withdraw the Tax Funds to pay the Taxes in accordance with the Scheduled Returns, including making any required prepayments; and (ii) if applicable, withdraw Customer’s DAVO by Avalara fees and Expenses.'
      },
      Section3: {
        Title: 'Avalara Obligations.',
        Item1: 'Returns Preparation and Filing.',
        Item1Regular: ' Avalara will prepare and file Returns for the Filing Entities and in the Filing Jurisdictions agreed by Avalara and Customer. Each tax return filing or prepayment mandated by a Filing without a tax return filing, prepared for one Filing Jurisdiction for one filing period, will count as one “Return.”',
        Item2: 'Funding.',
        Item2Regular: ' Avalara will remit Taxes for the agreed-upon Filing Entities in agreed-upon Filing Jurisdictions, provided Customer has timely made Tax Funds available.',
        SubItem1: 'Each day, Avalara will draw upon the Bank Account to pay the Taxes.',
        SubItem2: 'Each month, if applicable, Avalara will draw upon the Bank Account to pay DAVO by Avalara fees and Expenses due.',
        SubItem3: 'Unless otherwise agreed in writing, Avalara shall hold the Tax Funds in a trust account prior to disbursement to the applicable Filing Jurisdiction. Avalara holds all Tax Funds in trust for the benefit of the applicable Filing Jurisdiction and shall not comingle the Tax Funds with its general funds but may comingle the Tax Funds with funds held in trust on behalf of other Avalara customers. Any interest earned on the Tax Funds accrues for the benefit of and is the sole property of Avalara.',
        SubItem4: 'Avalara shall not provide funds to pay Taxes. If Tax Funds are not timely available to Avalara for remitting in full, Avalara will file the Return(s) without payment or with partial payment from the Tax Funds available.',
        SubItem5: 'If Avalara is unable to remit Taxes for Customer for six consecutive months after Avalara has begun holding Tax Funds, Avalara will stop collecting Tax Funds from Customer and return to Customer any Tax Funds that were collected but not remitted.'
      },
      Section4: {
        Title: 'Notice Management.',
        TitleRegular: ' Customer will receive all notices relating to Customer’s Returns directly from Filing Jurisdictions. During the Subscription Term, for any (i) error notice relating to a Return filed or Taxes remitted by Avalara or (ii) notice relating to changes to the Scheduled Returns, Customer shall immediately, and not later than 10 business days after the date of such notice, deliver such notice to Avalara. Such notice must be submitted in accordance with the instructions in the Documentation. During the Subscription Term, Avalara will respond to error notices for Returns filed and Taxes remitted by Avalara. In the case of error notices received by Avalara more than 10 business days after the date of the notice, Avalara may delay response or not respond or, if Customer asks Avalara to expedite the notice response and Avalara agrees, charge an additional fee to expedite the notice response. Customer is responsible for responding to or otherwise addressing all other notices. Avalara shall have no responsibility for any notice management upon expiration or termination of the Subscription Term.',
      },
      Section5: {
        Title: 'DAVO Guarantee.',
        TitleRegular: ' Avalara provides a guarantee of the timeliness of Returns prepared and filed through DAVO by Avalara and that it will draw upon the Bank Account daily to pay the Taxes (the “DAVO Guarantee”) under the following terms:',
        Item1: 'If Customer receives a notice of late filing, failure to file, or a failure to remit Taxes that results in liability for penalties or interest due solely to Avalara’s failure to timely prepare and file a Return it was obligated to file, to timely remit Taxes it was obligated to remit, or to draw upon the Bank Account daily to pay the Taxes (an “Avalara Error”), Avalara will pay the Filing Jurisdiction the lesser of either: (i) the amount of the penalties and interest that directly result from the Avalara Error, as specified in the final assessment notice received from the applicable Filing Jurisdiction after all administrative appeals and abatement options are exhausted, or (ii) the amount of the DAVO by Avalara fees paid by Customer during the 365 days preceding the final assessment of penalties or interest for the Avalara Error (calculated as described in subsection (c) below). If there are any remaining penalties or interest due, Customer shall remit payment to the applicable Filing Jurisdiction.',
        Item2: 'The following conditions apply to the DAVO Guarantee:',
        SubItem1: 'Customer must have met all of its obligations under the Terms and these DAVO Terms, including by providing and maintaining accurate, complete, and current Filing Information and Tax Data; timely providing all Tax Funds; and timely paying DAVO by Avalara fees. To the extent the Avalara Error was caused by Customer’s failure to perform any of the obligations in these DAVO Terms or the Terms, the DAVO Guarantee will not apply.',
        SubItem2: 'Customer must promptly forward any notices and relevant information from the Filing Jurisdiction within 10 days of the date of the notice.',
        SubItem3: 'Customer must assist Avalara in challenging the Filing Jurisdiction’s findings if Avalara deems it appropriate in Avalara’s sole discretion.',
        SubItem4: 'Customer must assist Avalara’s efforts to abate or reduce the amount of penalties or interest imposed by the Filing Jurisdiction.',
        Item3: 'For purposes of calculating the amount of the DAVO by Avalara fees paid that are eligible for the DAVO Guarantee payment, the amount will be the fees actually paid by Customer to Avalara for DAVO by Avalara, and the time period will be the 365 days preceding the final assessment of penalties or interest after all appeals and abatement options have been exhausted (e.g., in the case of a final assessment issued by a taxing authority on March 31, 2020, the period used in the calculation will be from April 1, 2019, to March 31, 2020). For clarity, the parties acknowledge that fees paid for DAVO by Avalara do not include any activation fees, fees for ancillary Professional Services, or any other one-time fees.',
        Item4: 'If the audit implicates other issues in addition to the alleged Avalara Error, the amount to be paid by Avalara under this DAVO Guarantee will be the percentage of the final assessment amount equal to the percentage of the final assessment related to the alleged Avalara Error.',
        Item5: 'Avalara will make the DAVO Guarantee payment within 30 days after the date that Avalara receives the final assessment notice from the applicable Filing Jurisdiction, after all administrative appeals and abatement options are exhausted. Avalara may also, in its sole discretion, make the payment at an earlier date, in which case Customer’s obligations to continue to assist Avalara in contesting the Avalara Error will cease on the date of the payment.',
        Item6: 'Customer must have a current DAVO by Avalara subscription in good standing when the claim is submitted to Avalara to be eligible to receive payment under this DAVO Guarantee.'
      },
      Section6: {
        Title: 'Fees.',
        TitleRegular: ' Customer agrees to pay fees for DAVO by Avalara based on Customer’s subscription plan and usage of DAVO by Avalara. Unless otherwise agreed in writing, Customer shall participate in Avalara’s automatic payment option for DAVO by Avalara and authorizes Avalara to draw from the Bank Account by ACH transfer to pay DAVO by Avalara fees and Expenses.',
        Item1: 'Return Fees.',
        Item1Regular: ' Customer’s monthly subscription fee includes three Returns per Subscription Term. Avalara will charge Customer the subscription fee each time Customer exceeds the three Returns included in subscription fee (e.g., if Customer files seven Returns in a Subscription Term, Avalara will charge Customer an amount equal to three subscription fees).',
        Item2: 'Additional Fees.',
        Item2Regular: ' If Customer fails to timely and completely provide accurate Filing Information, Tax Data, or Tax Funds, as applicable, or otherwise fails to fulfill any obligations under these DAVO Terms, or if Customer requires a change to the standard process described in these Return Terms, Avalara may charge Customer additional fees. For example, Avalara may charge a fee for Avalara to file an amended Return.',
        Item3: 'Rejected Transaction Fee.',
        Item3Regular: ' If the ACH payment Avalara draws from the Bank Account is cancelled or returned other than by Avalara, Avalara will charge Customer $3.00 per rejected transaction.'
      },
      Section7: {
        Title: 'Term and Termination.',
        TitleRegular: ' Notwithstanding anything to the contrary in the Terms, the Subscription Term for Customer’s DAVO by Avalara subscription will terminate on the last calendar day of the Subscription Term month. Customer may instruct Avalara not to file Returns by contacting DAVO by Avalara customer support in accordance with the instructions provided in the Documentation.',
        Item1: 'Termination for Cause.',
        Item1Regular: ' If the financial institution providing the Customer Bank Account instructs Avalara that Avalara must terminate this Agreement (for example, because Customer is conducting its business in violation of Applicable Law), Avalara may immediately terminate this Agreement upon written notice to Customer. In the event of such termination, Avalara will not refund any amounts to Customer.',
        Item2: 'Failure to Provide Account Information.',
        Item2Regular: ' In addition to any other applicable termination rights, if Customer does not provide Avalara with accurate and complete Account Information within three months following the Effective Date, Avalara may terminate the Agreement or the DAVO by Avalara Service and return to Customer the Tax Funds it collected.',
        Item3: 'Automatic Renewal.',
        Item3Regular: ' At the end of the then-current Subscription Term, each subscription to a Service will automatically renew for an additional one-month period (a “Renewal Subscription Term”) unless (1) Customer provides written notice of non-renewal to Avalara on or before the expiration date of the then-current Subscription Term, or (2) Avalara provides written notice of non-renewal to Customer at least 30 days before such expiration date.'
      },
      Section8: {
        Title: 'Compliance with NACHA Operating Rules.',
        TitleRegular: ' The funding process described in Section 3(b) (Funding) of these Returns Terms may be subject to the Operating Rules of NACHA, the organization that regulates the ACH network in the United States.',
        Item1: 'To the extent that Customer’s funding is governed by the NACHA Operating Rules, Customer specifically agrees to the following NACHA requirements:',
        SubItem1: 'Customer will comply with all applicable requirements under the then-current version of the NACHA Operating Rules;ii. Customer authorizes Avalara to originate the funding requests described in Section 3(b) (Funding); and',
        SubItem2: 'Customer will comply with the laws of the United States in providing such funding.',
        Item2: 'In addition to any other applicable termination rights, Avalara may terminate the Agreement for Customer’s non-compliance with the NACHA Operating Rules if such breach or non-compliance is not cured within 10 days of Avalara first notifying Customer of its non-compliance.',
        Item3: 'Avalara has the right to audit Customer’s funding process, at a time and location mutually agreeable to both Customer and Avalara, to ensure compliance with the NACHA Operating Rules and the Agreement.'
      },
      Section9: {
        Title: 'Deviations from the Terms.',
        Item1: 'Status Updates.',
        Item1Regular: ' Avalara may elect not to provide status updates at status.avalara.com or an equivalent replacement site.',
        Item2: 'Planned Downtime.',
        Item2Regular: ' Avalara may elect not to provide notice of planned downtime.',
        Item3: 'Customer Affiliates.',
        Item3Regular: ' Customer may not enable its Affiliates to use DAVO by Avalara.',
        Item4: 'Privacy Policy.',
        Item4Regular: ' Avalara’s use of Personal Information relating to an employee or other authorized Representative of Customer that is collected or received by Avalara in connection with the procurement or use of, or payment for, DAVO by Avalara is governed by the DAVO by Avalara Privacy Policy available at https://www.davosalestax.com/privacy-policy-davo-technologies/.',
        Item5: 'Material Reduction.',
        Item5Regular: ' Avalara may materially reduce a Service or its features or functionality with at least 30 days’ written notice to Customer.',
        Item6: 'Account Notices.',
        Item6Regular: ' Customer should send account notices (for example, name or address changes) in accordance with the instructions in the Documentation.',
        Item7: 'Entire Agreement.',
        Item7Regular: ' For avoidance of doubt, the Agreement supersedes and replaces the DAVO by Avalara End User License Agreement previously available at https://www.davosalestax.com/terms-of-use-davo-technologies/ and https://www.davosalestax.com/terms, as well as any DAVO by Avalara End User License Agreement provided by a Distributor to Customer.'
      },
    },
    CheckboxText: 'I agree to the Terms and Pricing',
    ActionDisclaimer: 'By clicking connect, a DAVO account will be created.',
    ConfirmationDialog: {
      Title: 'Are you sure you want to disconnect?',
      Body: 'Cancelations must be made at least 24 hours before the next month to avoid being charged for the following month. If you choose to cancel, your DAVO service and subscription will continue to the end of the month and you will not be charged for fees the following month.',
      Disconnect: 'Disconnect'
    }
  },
  AutoDetectTax: 'Auto Detect Tax',
  TaxRate: 'Tax Rate',
  ExpressItemTaxableLabel: 'Express Items taxable by default',
  OfflineTransactionsLabel: 'Offline Transactions',
  OfflineTransactionsSubtext: 'Accept payments when you are unable to access an internet connection. Your saved transactions will be processed when you regain connectivity. By enabling this setting, you acknowledge you are responsible for any declined transactions or chargebacks. If you clear your data or delete the Payanywhere app, your saved transactions will be lost. To lessen the risk of declined transactions, verify your customer\'s information and validate their card.',
  OfflineTransactionsNote1: 'Applies only to EMV chip, swiped, and cash transactions.',
  OfflineTransactionsNote2: 'Default transaction limit of $100.',
  OfflineTransactionsNote3: 'Offline transactions must be processed within 72 hours. Transactions after this time will be deleted from the system.',
  EnableTicketsLabel: 'Enable Tickets',
  EnableTicketsSubtext: 'Create, hold, and edit tickets or open tabs.',
  IdentifiersTitle: 'Identifiers',
  IdentifiersSubtext: 'Identifiers are descriptors to help you quickly identify tickets and customers. Set up Identifiers in the Payanywhere app.',
  AllowPinDebitLabel: 'PIN Debit',
  AllowPinDebitSubtext: 'Accept PIN debit payments. You can choose to run a transaction as either Credit or Debit; choosing Debit will prompt your customer to enter their PIN in order to complete the transaction.',
  PinDebitEnabledTitle: 'Force PIN Debit Only',
  PinDebitEnabledSubtext: 'Accept ONLY PIN debit payments. You will be unable to run transactions as Credit.',
  EBTEnabledLabel: 'EBT/SNAP Payments',
  EBTEnabledSubtext: 'Accept EBT/SNAP payments on eligible items if you are a licensed SNAP retailer with the FNS. You cannot charge tax on items paid using Food or Cash benefits.',
  CashFieldSubText: 'Accept and track cash payments.',
  GiftCardEnabledTitle: 'Gift card payments',
  GiftCardEnabledSubtext: 'Sell gift cards and accept gift card payments with a third-party gift card provider. Enroll in a gift card program through Payments Hub.',
  GiftCardStockTitle: 'Gift Card Stock',
  GiftCardStockSubtext: 'Track the number of physical gift cards you have on hand.',
  GiftCardPreset: 'Preset Gift Card {{presetNumber}}',
  LearnMoreLinkIntegration: 'Learn more on the integration page.',
  GiftCardOnTopLabel: 'Keep Gift Card Always on Top',
  GiftCardOnTopSubtext: 'Force Gift Card as the first item on your list of items available to sell.',
  GiftCardFavoritesLabel: 'Add Gift Card to Favorites',
  GiftCardFavoritesSubtext: 'Add the Gift Card item to your list of favorites items for quick access.',
  TapToPayLabel: 'Tap to Pay on iPhone',
  TapToPaySubtext: 'Accept contactless payments with only an iPhone. This setting is at the account-level and is not specific to each iPhone you use.',
  IgnoreAvsFailureLabel: 'Show AVS Mismatch Alert',
  IgnoreAvsFailureSubtext: 'For security purposes, you are alerted when the street number and/or zip code entered does not match the billing address of the cardholder. The alert will allow you to continue to proceed or void the transaction. On invoice payments, your customer will be asked to try another payment method, otherwise the transaction will decline. By turning off this setting, you acknowledge you are responsible for any declined transactions or chargebacks.',
  BarcodeSearchEnabledLabel: 'Enable Barcode Search',
  IsSignatureRequiredLabel: 'Always Require Signature',
  IsSignatureRequiredSubtext: 'Enabling this setting will display the Signature screen. Signatures are not required for transactions at the point of sale. You may require signatures at your own discretion.',
  PaperSignatureEnabledLabel: 'Sign & Tip on Printed Receipt',
  PaperSignatureEnabledSubtext: 'Customers sign and tip on a printed receipt. This option will print out a merchant and customer copy of the receipt. Enabling this setting will turn off digital signatures and on-screen tips. To adjust transactions with added tips, toggle Enable Tips to ON in Settings*. Go to Transactions --> Adjust Tips and enter tip amounts before transactions close at 10 pm EST or your custom auto close time.',
  IsTipEnabledLabel: 'Enable Tips',
  TipEnabledPresetTip: 'Preset Tip {{presetNumber}}',
  CombineTipsLabel: 'Combine tip and signature screens',
  EdgeProgramPendingWarning: 'Your Edge program request is Pending',
  EdgeProgramLabel: 'Edge Program',
  CashDiscountingLabel: 'Cash Discounting',
  EnablePerTransactionLabel: 'Enable Per Transaction',
  EnablePerTransactionSubtext: 'Allow user to disable cash discounting during checkout.',
  DisplayCashPriceLabel: 'Display Cash Price',
  DisplayCashPriceSubText: 'Your customer will see two totals. The retail price if they pay with credit and the cash price if they pay with cash, PIN Debit, or EBT.',
  CashFixedAmountLabel: 'Fixed Amount',
  CashFixedAmountSubtext: 'Between $0.01 and $1.00',
  CashDiscountAmountLabel: 'Fixed Percentage',
  CashDiscountAmountSubtext: 'Between 1% and 4%',
  EdgeNoticeTitle: 'Edge Program',
  EdgeNoticeReverseBtn: 'Upgrade to Edge Program',
  SubmitEdgeProgramTermsTitle: 'Edge Program Terms',
  SaveSettingsBtn: 'Save settings',
  SettingsErrorBottom: 'Form is incomplete. Please check your changes.',
  settingsDisclaimer: 'Either the Fixed Amount or the Amount by Percentage will be applied per transaction, depending on the limits that have been set above.',
  EditBusinessProfile: 'Edit business profile',
  DeleteOwnerDialog: {
    Title: 'DELETE OWNER',
    ConfirmText: 'DELETE',
    DeleteOwnerMessage: 'Are you sure to permanently delete this owner?'
  },
  AddOwnerDialog: {
    Title: 'Owner'
  },
  OwnerDialogError: {
    Title: 'Error',
    OwnerDialogErrorMessage: 'There was an error processing your information please try again or contact Customer Service at {{phoneNumber}}'
  },
  PCICompliance: {
    global: {
      criteria: 'Criteria',
      exclusions: 'Exclusions',
      requirements: 'Requirements',
      level: 'Level',
      annual: 'Annual:',
      quarterly: 'Quarterly:'
    },
    title: 'PCI Compliance',
    humboldt: {
      listText: 'Download Information Security Policy',
      description: 'Our PCI program makes adhering to the Payment Card Industry Data Security Standard (PCI DSS) easy. To help ensure the security of cardholder data, compliance with PCI DSS is required for all merchants.',
      faqContactText: 'Notify PCI at',
      faqContactTextTwo: 'and include the information below:',
    },
    paymentsHub: {
      listText: 'Download PCI Plus Training and Information Security Policy',
      description: 'Our PCI Plus program makes adhering to the Payment Card Industry Data Security Standard (PCI DSS) easy. To help ensure the security of cardholder data, compliance with PCI DSS is required for all merchants.',
      faqContactText: 'Notify PCI Plus at',
      faqContactTextTwo: 'and include the information below:',
    },
    meetsComplianceWithoutDate: 'Your account is PCI Compliant ',
    meetsComplianceWithDate: 'Your account is PCI Compliant through {{complianceDate}}',
    nonCompliant: 'Your account is PCI non-compliant',
    alarm: 'Your PCI Compliance status is pending.',
    downloadPCIPlusTraining: 'PCI Plus Training',
    downloadInformationSecurityPolicy: 'Information Security Policy',
    downloadSAQ: 'Download Self Assessment Questionnaire (SAQ)',
    saqDateTitle: 'SAQ Complete Date',
    scanDateTitle: 'Scan Complete Date',
    billingDateTitle: 'Billing',
    billingDateText: 'Upfront Annual Payment + Monthly',
    informationNeeded: 'If you need to edit any of the information, have already completed offline documents to validate PCI compliance within the last 12 months, or have SAQ or scan status questions, please contact the Compliance department at',
    learnMore: 'Learn more about PCI Compliance',
    learnMoreCTA: 'here',
    FAQS: {
      title: 'PCI Compliance Merchant Resources',
      whatsPCIDSS: {
        title: 'What is PCI DSS?',
        PCDStandard: 'The Payment Card Industry Data Security Standards (PCI DSS) is a global security standard for all entities that process, store, or transmit cardholder data. These standards are applicable to all merchants regardless of size or volume and helps reduce fraud and protect the payment card ecosystem.',
        PCDGoal: 'The goal of the PCI Data Security Standard (PCI DSS) is to protect cardholder data and sensitive authentication data wherever it is processed, stored, or transmitted. The security controls and processes required by PCI DSS are vital for protecting all payment card data.',
        PCDCompliance: 'Compliance with PCI DSS is enforced by the major payment card brands: American Express, Discover Financial Services, JCB International, Mastercard Worldwide and Visa Inc.'
      },
      whatIsSAQ: {
        title: 'What is an SAQ?',
        selfAssessmentQuestionnaire: 'The Self-Assessment Questionnaire (SAQ) is a validation tool for merchants and service providers targeted for your business specific environment. It is comprised of 12 requirements, covering both technical and non-technical security controls. Here are the PCI DSS requirements at a glance:',
        requirementsList: {
          installAndMaintain: 'Install and maintain a firewall configuration to protect cardholder data',
          vendorSupplied: 'Do not use vendor-supplied defaults for system passwords and other security parameters',
          cardHolder: 'Protect stored cardholder data',
          encryptTransmission: 'Encrypt transmission of cardholder data across open, public networks',
          antiVirus: 'Protect all systems against malware and regularly update anti-virus software programs',
          secureSystemAndApps: 'Develop and maintain secure systems and applications',
          restrictAccess: 'Restrict access to cardholder data by business need to know',
          authenticateAccess: 'Identify and authenticate access to system components',
          restrictPhysicalAccess: 'Restrict physical access to cardholder data',
          trackAndMonitor: 'Track and monitor all access to network resources and cardholder data',
          testSecuritySystem: 'Regularly test security systems and processes',
          maintainPolicy: 'Maintain a policy that addresses information security for all personnel'
        }
      },
      howToAccessPCI: {
        title: 'How to Assess PCI DSS Security:',
        howToAccessOptions: {
          reviewPCIRequirementsTitle: 'Review the PCI DSS Requirements:',
          reviewPCIRequirementsDescription: 'Learn what the PCI standard requires of your business.',
          inventoryITTitle: 'Inventory IT Assets and Processes:',
          inventoryITDescription: 'Identify all systems, personnel and processes involved in the transmission, processing or storing of cardholder data.',
          findVulnerabilitiesTitle: 'Find Vulnerabilities:',
          findVulnerabilitiesDescription: 'Use the appropriate SAQ to guide the assessment, and appropriate technologies to locate insecure systems.',
          thirdPartyTitle: 'Validate with Third-Party Experts:',
          thirdPartyDescription: 'Your environment\'s complexity may require a Qualified Security Assessor and/or Approved Scanning Vendor.'
        },
        QSAsLink: 'Qualified Security Assessors (QSAs)',
        ASVsLink: 'Approved Scanning Vendors (ASVs)',
        securityListTitle: 'Achieving and maintaining a secure and robust compliance environment includes the following:',
        securityList: {
          accessingTitle: 'Assessing:',
          accessingDescription: 'Test and verify controls in place to protect cardholder data during storage, processing, and transmission. Systems and data must be available for analysis.',
          reportingTitle: 'Reporting:',
          reportingDescription: 'Validate compliance and present evidence that data protection controls are in place.',
          monitoringAlertingTitle: 'Monitoring & Alerting:',
          monitoringAlertingDescription: 'Implement systems that provide auto alerting to constantly monitor access and usage of data. Extend system controls to include collection and storage of log data.',
        },
        ensureSecurityControl: 'To ensure security controls continue to be properly implemented, PCI DSS should be implemented into business-as-usual (BAU) activities as part of an entity’s overall security strategy. This enables an entity to monitor the effectiveness of its security controls on an ongoing basis, and maintain its PCI DSS compliant environment in between PCI DSS assessments.',
        table: {
          body: {
            rowOne: {
              levelOne: 'Level 1',
              criteria: 'Merchants processing over 6 million transactions annually across all channels.',
              requirements: {
                annualOne: 'Report on Compliance (ROC) by a Qualified Security Assessor (QSA)',
                annualTwo: 'Submit an Attestation of Compliance (AOC)',
                quarterlyOne: 'Network scan by an Approved Scan Vendor (ASV)'
              }
            },
            rowTwo: {
              levelTwo: 'Level 2',
              criteria: '1 to 6 million transactions annually across all channels',
              requirements: {
                annualOne: 'Self-Assessment Questionnaire (SAQ) completed by a certified Internal Security Assessor (ISA) or engage a QSA for an Onsite Audit',
                annualTwo: 'Submit an Attestation of Compliance (AOC)',
                quarterlyOne: 'Network scan by an Approved Scan Vendor (ASV)'
              }
            },
            rowThree: {
              levelThree: 'Level 3',
              criteria: '20,000 to 1 million e-commerce transactions annually',
              requirements: {
                annualOne: 'Report on Compliance (ROC) by a Qualified Security Assessor (QSA)',
                annualTwo: 'Submit an Attestation of Compliance (AOC)',
                quarterlyOne: 'Quarterly network scan by an Approved Scan Vendor (ASV)'
              }
            },
            rowFour: {
              levelFour: 'Level 4',
              criteria: 'Merchants processing less than 20,000 ecommerce transactions annually and all other merchants processing up to 1 million transactions annually.',
              requirements: {
                annualOne: 'Report on Compliance (ROC) by a Qualified Security Assessor (QSA)',
                annualTwo: 'Submit an Attestation of Compliance (AOC)',
                quarterlyOne: 'Quarterly network scan by an Approved Scan Vendor ( ASV) (if applicable)'
              }
            }
          }
        }
      },
      consequencesOfNonCompliance: {
        title: 'Consequences of Non-Compliance',
        list: {
          accountClosure: 'Account closure and/or loss of privilege to offer payment card',
          branMarket: 'Brand/Market Reputational Damage',
          cardBrand: 'Card Brand Assessments',
          dataBreach: 'Account Data Breach'
        }
      },
      whatToDoWhenBreached: {
        title: 'What To Do When Breached',
        descriptionOne: 'Breaches happen every day, data is compromised around the clock, all over the world, so it is important to know what to do about it. You should understand that there is a potential for negative fallout in any breach where sensitive customer information is stolen. This negative fallout can be at the very least financial, reputational, or any combination of the three.',
        descriptionTwo: 'Regardless of the size of your business, the amount of data that is compromised <strong>can be small and manageable</strong> just as easily as it can be massive and catastrophic. In fact, what many business owners don’t realize is that 90% of data breaches involving payment information happen with small businesses.',
        largeCompanyBreaches: 'Large company breaches that haunt the news do not happen nearly as often as breaches of small and medium sized businesses who have fewer resources available to defend themselves with.',
        contact: 'Please contact us immediately regarding the breach forgiveness program if you believe you have experienced a data compromise and take the following actions:',
        list: {
          mids: 'All MIDs you believe could be impacted',
          incidentOcurred: 'The month and year during which you believe the incident occurred',
          contactInformation: 'Name, contact information, and related correspondence from any person or entity that informed you of the potential incident'
        },
        whatHappensNext: {
          title: 'What happens next?',
          description: 'A member of the PCI Team will contact you and review with you the program criteria and exclusions, and the incident reported to confirm eligibility for the forgiveness program. Once eligibility is confirmed, your PCI Team representative will coordinate with you responses and actions needed, including:',
          list: {
            reporting: 'Completion with you of reporting requirements such as information that needs to be reported to the card brands.',
            engagement: 'Engagement of forensics specialists to conduct forensics',
            reCertification: 'Coordination of re-certification if required',
          },
          costsOfTheForensics: 'Remember that we will cover the costs of the forensics and other expenses that are covered under the benefits of the program and will not seek reimbursement from you as long as you remain eligible.',
          breachForgiveness: 'The breach forgiveness program provides the benefits listed below for merchants based on meeting the criteria identified, as long as no exclusions apply. Enrollment in the program is renewed each month based on merchant qualifications to continue in the program.',
          mustHaveLevels: 'Must be a Level 3 or Level 4 merchant, or level 2 if certain criteria is met*',
          noFineOrPenalty: 'Cannot have a previous card brand fine or penalty',
          SEQ: '(PCI Assessment) unless you have been reinstated based on completion of PCI Certification (e.g. Self-Assessment Questionnaire)',
          notSuffered: 'Not suffered a <strong>prior breach</strong> without PCI recertification',
          incidentsTitle: 'Incident occurs, is discovered, and is reported',
          incidentsDescription: 'during the breach forgiveness program period (All expenses arising from the same incident or chain of related incidents at a merchant shall be considered a single incident). Note that the breach forgiveness program period renews each month based on continuing to meet criteria and as long as no exclusion applies.',
          exclusionList: {
            incidentOcurred: 'Any incident that occurred prior to December 1, 2019',
            incidentKnow: 'Any incident known or discovered prior to December 1, 2019',
            incidentDiscovered: 'An incident discovered before the effective date of your merchant agreement',
            incidentFailed: 'Any incident relating to a failure of security that is specifically known and that existed before the breach forgiveness program was provided to you.',
            costsOrExpenses: 'Any costs or expenses incurred or required for a merchant to become PCI compliant in the first instance prior to the occurrence of an incident.',
            incidentCertified: 'Any incident where you have experienced a prior incident unless your business was recertified to an eligible PCI Compliance Level by a Qualified Security Assessor prior to the current incident',
            softwareWithinYourControl: 'Any software not within your control',
            softwareIncident: 'Any incident that occurs in any computer system in which multiple merchants with no legal relationship to one another have hosted accounts or share a common database, operating system, or software applications',
            incidentWithout: 'Any incident without',
            incidentWithoutWrittenNotification: 'a formal written notification by the card network to either the acquiring bank, ISO, or merchant of an incident,',
            incidentWithoutContractually: 'a contractually enforceable demand by the card network for expense reimbursement due to an incident (does not apply to hardware or software upgrades required to avoid a PCI assessment)'
          },
          additionalInformation: 'Additional Reference Information:',
          additionalInformationVisaLink: 'Visa Supplement - What To Do If Compromised',
          additionalInformationPFIsLink: 'PCI Forensic Investigators (PFIs)',
        }
      },
      helpMerchants: {
        title: 'What Do We Do to Help Merchants?',
        description: 'We understand that PCI DSS can be a challenging process. Our goal is to simplify this for you by making compliance easier for any merchants who use our proprietary credit card processing solutions. We will use a combination of technology and compliance team expertise to determine PCI requirements and utilize data analytics to provide greater support in identifying, achieving, and maintaining PCI compliance. In addition, we offer exciting benefits such as no non-compliance fees, breach forgiveness for qualified merchants, and included vulnerability scans if necessary - up to 3 IP addresses!'
      }
    }
  },

  // Business Settings Page

  VerifiedDialog: {
    Text: 'For security purposes, updates you’ve made to your account will take effect once verified.'
  },

  //News & Tips Card
  'News & Tips': 'News & Tips',
  'Need help navigating Payments Hub?': 'Need help navigating Payments Hub?',
  'Watch video tutorials': 'Watch video tutorials',
  'Download the guide': 'Download the guide',
  //News & Tips Card

  //Statements News & Tips
  'Watch our statement video to understand your processing statement better.': 'Watch our statement video to understand your processing statement better.',
  'Watch video': 'Watch video',
  //Statements News & Tips

  //Sales
  Volume: 'Volume',
  Method: 'Method',
  Items: 'Items',
  Categories: 'Categories',
  'Total to Account For': 'Total to Account For',
  Quantity: 'Quantity',
  'Total Sales': 'Total Sales',
  Current: 'Current',
  Previous: 'Previous',
  Discounts: 'Discounts',
  'Gross Sales': 'Gross Sales',
  'Net Sales': 'Net Sales',
  Refunds: 'Refunds',
  Tips: 'Tips',
  Unpaid: 'Unpaid',
  Void: 'Void',
  Swiped: 'Swiped',
  'Chip (EMV)': 'Chip (EMV)',
  Contactless: 'Contactless',
  Keyed: 'Keyed',
  Cash: 'Cash',
  'Chip-Read Credit': 'Chip-Read Credit',
  'Contactless Credit': 'Contactless Credit',
  'Keyed Credit': 'Keyed Credit',
  Voids: 'Voids',
  Change: 'Change',
  Status: 'Status',
  LastVisit: 'Last Visit',
  Sold: 'Sold',
  UniqueCustomers: 'Unique Customers',
  NewCustomers: 'New Customers',
  TopByVolume: 'Top 3 by Volume',
  TopByQuantity: 'Top 3 by Quantity',
  Custom: 'Custom',
  ComparedTo: 'Compared To',
  'Compared to previous month': 'Compared to previous month',
  'Previous Month': 'Previous Month',
  'Compared to previous 3 months': 'Compared to previous 3 months',
  'Previous 3 Months': 'Previous 3 Months',
  'Compared to previous 6 months': 'Compared to previous 6 months',
  'Previous 6 Months': 'Previous 6 Months',
  None: 'None',
  'Not categorized': 'Not categorized',
  NoSalesData: {
    Volume: 'No Sales Volume Data',
    Method: 'No Payment Method Data',
    Customers: 'No Customer Sales Data',
    Employees: 'No Employees Sales Data',
    Items: 'No Items Sales Data',
    Categories: 'No Categories Sales Data'
  },
  'Bank Account Sales': 'Bank Account Sales',
  //Sales

  //Invoice
  ReplaceExistingPaymentMethodSeries: 'Replace the existing payment method for all future payments in this series.',
  ReplaceExistingPaymentMethodInvoice: 'Replace the existing payment method for this invoice.',
  ReplaceExistingPaymentMethod: 'Replace existing payment method.',
  PaymentMethod: 'Payment Method',
  UseSavedPaymentMethod: 'Use saved payment method',
  AddPaymentMethod: 'Add new payment method',
  SendWithoutPaymentMethod: 'Send without saved payment method',
  Autopay: 'Selecting this option will display autopay on this series and require the customer to manually enter a payment method per invoice. The customer can re-enable autopay at any time.',
  WrittenPermission: 'It is your responsibility to collect your customer\'s written permission to save their card. By adding a card, you acknowledge to you are responsible for any declined transactions or chargebacks',
  SeriesDetails: 'Series Details',
  InvoiceTitle: '{{name}} for {{total}}',
  StartDate: 'Start {{date}}',
  StartDateLabel: 'Start Date',
  EndDate: 'End {{date}}',
  DueIn: 'Due in {{date}}',
  DueInDays: 'Due in {{days}}',
  DueToday: 'Due Today',
  SavedPaymentMethod: 'Saved Payment method',
  ExpiresCard: 'Expires {{card}}',
  Edit: 'Edit',
  ViewCustomerProfile: 'View customer profile',
  InvoiceDescription: 'Description',
  InvoiceActivity: '{{invoice}} activity',
  EditedBy: 'Edited by {{name}}',
  ViewAllActivity: 'View all activity',
  Details: 'Details',
  Resend: 'Resend',
  PaymentFailed: 'Payment Failed',
  FailedPayments: '{{number}} failed payments',
  ContactCustomerEditPaymentMethod: 'Contact customer or edit payment method.',
  ViewFailedInvoices: 'View failed invoices',
  ViewInvoicesSeries: 'View invoices in series',
  ViewTransactionHistory: 'View transaction history',
  EditPaymentAmount: 'Edit payment amount',
  ManageSeries: 'Manage this series',
  ViewTransactionDetails: 'View transaction details',
  EditPaymentMethod: 'Edit payment method',
  AutopayCard: 'Autopay {{processor}} {{lastDigits}}',
  DueDate: '<0>Due</0> {{date}}',
  DueDateLabel: 'Due {{date}}',
  SentDate: '<0>Sent</0> {{date}}',
  ScheduleDate: '<0>Scheduled</0> to autopay on {{date}}',
  WillSendDate: '<0>Will</0> send on {{date}}',
  OverDueDate: '<0>Contact</0> customer or edit payment method.',
  PaymentFailedDescription: '<0>Payment</0> attempted {{date}}<3 /><4>Contact</4> customer or edit payment method.',
  PaidDate: 'Paid ',
  InvoiceNumberID: 'Invoice #{{number}}',
  RepeatsFrequency: 'Repeats {{frequency}}',
  InvoiceIndex: 'Invoice {{index}} of {{size}}',
  NoEndDate: 'no end date',
  Never: 'never',
  RepeatsText: 'Repeats',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Yearly: 'yearly',
  On: 'on',
  At: 'at',
  OnThe: 'on the',
  Every: 'every',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
  CreateInvoice: 'Create an invoice',
  EditDraftInvoice: 'Edit draft invoice',
  EditInvoice: 'Edit invoice',
  PreviewInvoice: 'Preview invoice',
  DeleteDraft: 'Delete draft',
  SendInvoice: 'Send invoice',
  NoInvoices: 'No Invoices',
  InvoicesData: 'Invoices data',
  AddItems: 'Add items',
  TaxNoteText: 'Default tax rate is set to',
  ConfigureTax: 'Configure tax in',
  CustomerEmailAddress: 'Customer Email Address',
  EnterEmailAddress: 'Enter an email address or search customer directory',
  CustomerPhoneNumber: 'Customer Phone Number',
  EnterPhoneNumber: 'Enter phone number',
  EnterFirstName: 'Enter first name',
  EnterLastName: 'Enter last name',
  EnterInvoiceName: 'Enter invoice name',
  CustomerStreetAddress1: 'Customer Street Address 1',
  EnterStreetAddress1: 'Enter street address 1',
  CustomerStreetAddress2: 'Customer Street Address 2',
  EnterStreetAddress2: 'Enter street address 2',
  Country: 'Country',
  SelectState: 'Select state',
  EnterCity: 'Enter city',
  EnterZipCode: 'Enter zip code',
  AddCustomerAddress: 'Add customer address',
  InvoiceName: 'Invoice Name',
  InvoiceNumberText: 'Invoice Number',
  InvoiceType: 'Invoice Type',
  PaymentAmount: 'Payment Amount',
  RewardCode: 'Discount or Reward Code',
  RewardCodeHintText: 'Enter reward code',
  RewardCodeError: 'The reward code you entered is invalid or has expired',
  EnterValidRewardCode: 'Please enter a valid reward code',
  Apply: 'Apply',
  Remove: 'Remove',
  LoyaltyReward: 'Loyalty Reward',
  LoyaltyRewardOff: 'off',
  AvailableRewards: 'Available rewards',
  OffEntirePurchase: 'off entire purchase',
  Expires: 'Expires',
  Frequency: 'Frequency',
  NumberPayments: '# of Payments',
  RepeatEvery: 'Repeat Every',
  DueDateText: 'Due Date',
  CustomDate: 'Custom Date',
  Cadence: 'Cadence',
  TipEnabled: 'Tip Enabled',
  CDEnabled: 'Cash Discounting Enabled',
  Select: 'Select',
  EnterDate: 'Enter date',
  'Itemized invoice': 'Itemized invoice',
  'One time': 'One time',
  Recurring: 'Recurring',
  'Upon Receipt': 'Upon Receipt',
  'Immediately': 'Immediately',
  '7 Days': '7 Days',
  '14 Days': '14 Days',
  '30 Days': '30 Days',
  'Select Date': 'Select Date',
  'On date': 'On date',
  'After # of payments': 'After # of payments',
  'Day(s)': 'Day(s)',
  'day(s)': 'Day(s)',
  'Week(s)': 'Week(s)',
  'week(s)': 'Week(s)',
  'Month(s)': 'Month(s)',
  'month(s)': 'Month(s)',
  'Year(s)': 'Year(s)',
  'year(s)': 'Year(s)',
  Days: 'days',
  'Send to customer': 'Send to customer',
  'Invoice created': 'Invoice created',
  'Payment amount changed': 'Payment amount changed',
  'Invoice canceled': 'Invoice canceled',
  'Series canceled': 'Series canceled',
  'Series created': 'Series created',
  By: 'by',
  'NoActivities': 'No activities',
  'Apply to next invoice only.': 'Apply to next invoice only.',
  'Apply to all future invoices.': 'Apply to all future invoices.',
  Comment: 'Comment',
  ColumnsGeneral: {
    Customer: 'Customer',
    Amount: 'Amount',
    Created: 'Created',
    EndDate: 'End',
    Frequency: 'Frequency',
    Invoice: 'Invoice',
    InvoiceName: 'Invoice Name',
    LastName: 'Customer',
    StartDate: 'Start',
    Status: '',
    PaidDate: 'Paid Date',
    SendDate: 'Send Date',
  },
  ColumnsForSeries: {
    SeriesName: 'Series Name'
  },
  ColumnsForCanceled: {
    Canceled: 'Canceled'
  },
  ColumnsForInvoices: {
    Due: 'Due'
  },
  ColumnsForFailedPayment:{
    FailedDate: 'Failed Date',
  },

  // Invoice
  InvoiceHeaderAutoPay: '{{amount}} will be automatically charged on {{date}}.',
  Partially_paid: 'Partially paid',
  UnsavedChanges: 'You have unsaved changes.',
  Discard: 'Yes, discard',
  GoBack: 'No, go back',
  DiscardInvoice: 'Are you sure you want to discard this invoice?',
  EnterEmail: 'Search customer directory or enter an email address',
  EnterItem: 'Enter item name to search inventory',
  UpgradeNow: 'Upgrade now',
  CashPaidInvoiceStatusMessage: {
    DeclinedStatus: 'Error processing invoice cash sale transaction.',
    PleaseTryAgain: 'Please try again.',
    SendReceipt: 'SEND RECEIPT',
    ViewInTransactions: 'VIEW IN TRANSACTIONS',
    PrintReceipt: 'PRINT RECEIPT',
    SuccessStatus: 'Invoice cash payment has been successfully processed.'
  },
  UpgradeDialog: {
    Title: 'Upgraded',
    BodyText: 'You have successfully upgraded your plan to Premium.'
  },
  MembershipDialog: {
    Title: 'Upgrade to Premium Plan',
    BodyText: 'Do you want to upgrade to Premium for $14.95/mo?'
  },
  CreateInvoiceError: 'Create Invoice Error {{errorMessage}}',
  SendInvoiceError: 'Send Invoice Error',
  UpdateDraftError: 'Update Draft Invoice Error {{errorMessage}}',
  UnnamedCustomer: 'Unnamed Customer',
  BasePrice: 'Base Price',
  ExpressItemDescription: 'Express Item',
  PayAmount: 'Pay {{amount}}',
  PayBy: 'Pay by {{date}}',
  InvoiceRepeat: 'Repeats every {{length}} {{period}} {{dueDate}}',
  InvoiceRepeatUntil: 'until {{date}}',
  InvoiceSeriesDetail: '({{currentInvoice}} of {{quantity}})',
  NoBalanceDue: {
    Header: 'No balance due.',
    Description: 'We sent a receipt to <1>{{email}}}</1>'
  },

  // Invoice Detail
  InvoiceDetail: {
    AppleInvoice: 'Invoice {{invoice}} Subtotal',
    AppleTax: 'Tax',
    AppleTotal: 'Total',
    AppleTip: 'Tip',
    BillTo: 'Bill to {{billTo}}',
    InvoiceNumber: 'Invoice #{{invoiceNumber}}',
    PaidDate: 'Paid {{date}}',
    SavedPaymentDetails: 'Saved Payment Details',
    AutoPayCharge: 'This payment method will automatically be charged for future payments in this series',

    // Paid Invoice
    PaidInvoice: {
      Header: 'Thanks, your invoice has been paid.',
      Description: 'We sent a receipt to <1>{{email}}</1>'
    }
  },
  NoTip: 'No tip',
  Other: 'Other',
  TipModalHeader: 'Enter tip amount.',
  AutoSaveCardMessage: 'This payment method on file will be automatically charged for each recurring invoice in this series. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge the payment method on file.',
  AutoSaveCardMessageOneTime: 'This payment method will be saved to the customer profile and charged on the due date of this invoice. Your customer will be sent a paid invoice receipt. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge this payment method.',
  AutoSaveCardMessageOneTimeDueDateToday: 'This saved payment method will be charged immediately for this invoice. Your customer will be sent a paid invoice receipt. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge this saved payment method.',
  SavedCardMessageOneTime: 'This saved payment method will be charged on the due date of this invoice. Your customer will be sent a paid invoice receipt. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge this saved payment method.',
  SavedCardMessageOneTimeDueDateToday: 'This saved payment method will be charged immediately for this invoice. Your customer will be sent a paid invoice receipt. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge this saved payment method.',
  FuturePaymentDisclaimer: 'The saved payment method will be automatically charged on the due date. Protect yourself from potential chargebacks or fraud by making sure you have your customer’s written consent to charge their saved payment method.',

  // Open Invoices
  OpenInvoices: {
    Title: 'Open Invoices'
  },
  NoOpenInvoices: {
    Title: 'You’re up to date!',
    Description: 'You have no outstanding invoices. Great job!'
  },

  // Payment Methods
  // No Payment Method
  NoPaymentMethod: {
    Header: 'Add a saved payment method.',
    Text: 'Saving a credit card on file lets you enroll in autopay. It also makes future purchases much easier.',
    Button: 'Add payment method'
  },
  // Delete Method Dialog
  DeleteMethodDialog: {
    Header: 'Delete payment method',
    Text: 'If this payment method is associated with any recurring payments, it will no longer be charged'
  },
  // Add Second Payment Method
  AddSecondPaymentMethod: {
    Button: 'Add a payment method'
  },
  // Method Options
  MakeDefault: 'Make Default',

  // Payment History
  PaymentHistory: {
    DownloadReceipt: 'Download {{count}} receipt',
    DownloadReceipts: 'Download {{count}} receipts',
    NoPaymentHistoryDescription: 'Invoices that are successfully paid will appear here for your records.',
    NoPaymentHistoryTitle: 'No payment history.',
    SearchInvoices: 'Search invoices',
    SendReceipt: 'Send {{count}} receipt',
    SendReceipts: 'Send {{count}} receipts',
    Title: 'Payment History'
  },

  // Mark as Paid
  CreditPrice: 'Credit Price',
  CashPrice: 'Cash Price',
  CommentField: {
    Label: 'Comment (only you can see this)',
    Hint: 'Enter a comment, e.g., customer paid with cash',
  },

  // Edit payment amount modal
  OriginalPaymentAmount: 'Original Payment Amount',
  NewPaymentAmountField: {
    Label: 'New Payment Amount',
    Hint: '$0.00'
  },
  EditPaymentAmountCommentField: {
    Label: 'Comment (only you can see this)',
    Hint: 'Optional',
  },
  Duration: 'Duration',

  // High amount modal
  HighAmountModal: {
    Title: 'Transaction Limit Exceeded',
    CancelText: 'Go Back',
    Body: 'You have entered an amount that exceeds the transaction limit. Please enter a new amount or contact 877.464.4218 to request an update to your transaction parameters.',
    ExternalBody: 'This amount exceeds the transaction limit. Please contact {{dba}} if you need help.'
  },

  // Low amount modal
  LowAmountModal: {
    Title: '$0.00 invoice.',
    ConfirmText: 'Yes, continue',
    CancelText: 'Cancel',
    Body: 'Your invoice has a $0.00 balance. Please confirm the amount is correct before sending.'
  },

  // Partial Payment Modal
  PartialPaymentModal: {
    Title: 'Partially authorized {{authorizedAmt}}',
    Description: 'Collect the remaining balance of {{remainingBalance}} with an additional payment method. You may also void the partial authorization and start over.',
    ConfirmText: 'Collect {{remainingBalance}} balance',
    CancelText: 'Void and start over',
  },

  // Pay And Save Form
  PayAndSaveForm: {
    AccountNumberField: 'Bank Account Number',
    AccountNumberTooltipTitle: 'Tooltip Message to show',
    AccountTypePersonalChecking: 'Personal Checking',
    AccountTypePersonalSavings: 'Personal Savings',
    AccountTypeBusinessChecking: 'Business Checking',
    AccountTypeBusinessSavings: 'Business Savings',
    AccountTypeField: 'Account type',
    AddTitle: 'Add payment method',
    EditTitle: 'Edit payment method',
    IsAutopayLabel: 'Save this payment method and automatically charge future payments in this series.',
    IsDefaultHelp: 'To change the default state, please use the menu option "Make Default" on the list.',
    IsDefaultLabel: 'Make this my default payment method',
    NameOnAccountField: 'Name on Account',
    PaymentMethodField: 'Payment Method',
    PayWithCreditCardOption: 'Pay with Credit Card',
    PayWithBankAccountOption: 'Add Bank Account',
    RoutingNumberField: 'Bank Routing Number',
    RoutingNumberTooltipTitle: 'Tooltip Message to show',
    NameOnAccount: 'Name on Account',
    EnterName: 'Enter Name',
    EnterAccountNumber: 'Enter bank account number',
    EnterRoutingNumber: 'Enter bank routing number',
    BankAccountTooltip: 'This is the number associated with the bank account. Bank account numbers are typically between 5 and 17 digits.',
    RoutingNumberTooltip: 'This is the 9-digit number that identifies your bank.',
    Agreement: 'I agree to the',
    Conditions: 'ACH Terms and Conditions',
  },

  // Payment Method Summary
  PaymentMethodSummary: {
    Expires: 'Expires {{date}}'
  },

  // Invoice Summary
  InvoiceSummary: {
    BalanceRemaining: 'Balance remaining: {{amount}}',
    Due: 'Due {{date}}'
  },
  // Transactions
  'All Transactions': 'All Transactions',
  'Open Transactions': 'Open Transactions',
  'All Sales': 'All Sales',
  'Gift Card Sales': 'Gift Card Sales',
  'Gift Card Activations': 'Gift Card Activations',
  'Credit Sales': 'Credit Sales',
  'Debit Sales': 'Debit Sales',
  'EBT Sales': 'EBT Sales',
  'Cash Sales': 'Cash Sales',
  'Pre-Auths': 'Pre-Auths',
  'Refunds & Voids': 'Refunds & Voids',
  'Total Collected': 'Total Collected',
  'Contact Info': 'Contact Info',
  'Related Transactions': 'Related Transactions',
  'Declined Transactions': 'Declined Transactions',
  'Advanced Search': 'Advanced Search',
  Comments: 'Comments',
  Location: 'Location',
  NoCustomerAssigned: 'No Customer Assigned',
  Amount: 'Amount',
  RecurringInvoice: 'Recurring Invoice',
  UnenrollFromAutopay: 'Unenroll From Autopay',
  Device: 'Device',
  Authorization: 'Authorization',
  SplitPayment: 'Split Payment',
  InstantFunded: 'Instant Funded',
  AddCustomer: 'Add customer',
  Add: 'Add',
  AddTo: 'Add to',
  SelectCustomer: 'Select Customer',
  TransactionType: 'Transaction Type',
  AuthorizedAmount: 'Authorized amount',
  TransactionDetails: 'Transaction Details',
  NoDataText: 'No Transactions',
  NoDataHint: 'Transactions will display after you have batched. Click ',
  NoDataHintHere: 'Here',
  NoDataComponent: '<1>Transactions will display after you have batched. Click <2>Open Batch</2> to view a list of live credit card authorizations that have not yet settled. Does not include refunds, cash, and virtual terminal transactions.</1>',
  Complete: 'Complete',
  CancelPreAuth: 'Cancel Pre-Auth',
  CompletePreAuth: 'Complete Pre-Auth',
  Refund: 'Refund',
  SendReceipt: 'Send Receipt',
  PrintReceipt: 'Print Receipt',
  Done: 'Done',
  Send: 'Send',
  SendDate: 'Send Date',
  LastSendDate: 'Last Send Date',
  OpenBatchButton: 'Open Batch transactions',
  RefundTransaction: 'Refund Transaction',
  RefundAchDisabled: 'Refund is not yet available for this transaction. Please try again later.',
  VoidTransaction: 'Void Transaction',
  Declined: 'Declined',
  UnableProcessPayment: 'Unable to process payment',
  DeclinedTryDifferentPayment: '(Declined{{statusCode}}) Please try a different method of payment',
  Incomplete: 'Incomplete',
  PaidInvoice: 'Paid Invoice',
  ViewInvoice: 'View Invoice',
  CashSale: 'Cash Sale',
  CashRefund: 'Cash Refund',
  ContactlessEMVCreditRefund: 'Contactless EMV Credit Refund',
  ChipReadCreditRefund: 'Chip Read Credit Refund',
  ExportingDialogTitle: 'Fetching your CSV export',
  Update: 'Update',
  CloseAll: 'Close All',
  SaveTips: 'Save tips',
  CloseAllTransactions: 'Close All Transactions',
  Search: 'Search',
  AvailableActions: 'Available Actions',
  AutopayText: 'Enrolled in Autopay',
  InvoiceFrequency_one: 'Every {{dayCount}} day',
  InvoiceFrequency_other: 'Every {{dayCount}} days',
  Ends: 'Ends',
  NeverEnds: 'Never Ends',
  KeyedCreditSale: 'Keyed Credit Sale',
  KeyedCreditSaleSplitPayment: 'Keyed Credit Sale - Split Payment',
  KeyedVoid: 'Keyed Void',
  KeyedCreditRefund: 'Keyed Credit Refund',
  IncompleteKeyedCreditSale: 'Incomplete Keyed Credit Sale',
  IncompleteKeyedVoid: 'Incomplete Keyed Void',
  IncompleteKeyedCreditRefund: 'Incomplete Keyed Credit Refund',
  IncompleteCashSale: 'Incomplete Cash Sale',
  IncompleteContactlessEMVCreditSale: 'Incomplete Contactless EMV Credit Sale',
  CheckingAccountSale: 'Checking Account Sale',
  PersonalCheckingAccountSale: 'Personal Checking Account Sale',
  BusinessCheckingAccountSale: 'Business Checking Account Sale',
  CheckingAccountVoid: 'Checking Account Void',
  PersonalCheckingAccountVoid: 'Personal Checking Account Void',
  BusinessCheckingAccountVoid: 'Business Checking Account Void',
  CheckingVoid: 'Checking Account Void',
  PersonalCheckingVoid: 'Personal Checking Account Void',
  BusinessCheckingVoid: 'BusinessChecking Account Void',
  CheckingAccountRefund: 'Checking Account Refund',
  PersonalCheckingAccountRefund: 'Personal Checking Account Refund',
  BusinessCheckingAccountRefund: 'Business Checking Account Refund',
  CheckingRefund: 'Checking Account Refund',
  PersonalCheckingRefund: 'Personal Checking Account Refund',
  BusinessCheckingRefund: 'Business Checking Account Refund',
  SavingsAccountSale: 'Savings Account Sale',
  PersonalSavingsAccountSale: 'Personal Savings Account Sale',
  BusinessSavingsAccountSale: 'Business Savings Account Sale',
  SavingsAccountVoid: 'Savings Account Void',
  PersonalSavingsAccountVoid: 'Personal Savings Account Void',
  BusinessSavingsAccountVoid: 'Business Savings Account Void',
  SavingsVoid: 'Savings Account Void',
  PersonalSavingsVoid: 'Personal Savings Account Void',
  BusinessSavingsVoid: 'BusinessSavings Account Void',
  SavingsAccountRefund: 'Personal Savings Account Sale',
  PersonalSavingsAccountRefund: 'Personal Savings Account Sale',
  BusinessSavingsAccountRefund: 'BusinessSavings Account Sale',
  SavingsRefund: 'Savings Account Sale',
  PersonalSavingsRefund: 'Savings Account Sale',
  BusinessSavingsRefund: 'Savings Account Sale',
  StartTime: 'Start Time',
  CustomTimeText: 'End time is automatically set to 24 hours later. If you need a more specific timeframe, please select the <1>Custom Range</1> option on the previous menu.',
  CreditSale: 'Credit Sale',
  CreditRefund: 'Credit Refund',
  KeyedPreAuth: 'Keyed Pre-Auth',
  SwipedPreAuth: 'Swiped Pre-Auth',
  SwipedCreditRefund: 'Swiped Credit Refund',
  SwipedCreditSale: 'Swiped Credit Sale',
  SwipedCreditSaleSplitPayment: 'Swiped Credit Sale - Split Payment',
  IncompleteSwipedCreditSale: 'Incomplete Swiped Credit Sale',
  SwipedDebitRefund: 'Swiped Debit Refund',
  SwipedDebitSale: 'Swiped Debit Sale',
  SwipedDebitSaleSplitPayment: 'Swiped Debit Sale - Split Payment',
  IncompleteSwipedDebitSale: 'Incomplete Swiped Debit Sale',
  SwipedVoid: 'Swiped Void',
  ContactlessEMVCreditSale: 'Contactless EMV Credit Sale',
  ContactlessEMVCreditSaleSplitPayment: 'Contactless EMV Credit Sale - Split Payment',
  ContactlessEMVDebitSale: 'Contactless EMV Debit Sale',
  ContactlessEMVDebitSaleSplitPayment: 'Contactless EMV Debit Sale - Split Payment',
  ContactlessEMVVoid: 'Contactless EMV Void',
  ContactlessEMVPreAuth: 'Contactless EMV Pre-Auth',
  KeyedDebitSale: 'Keyed Debit Sale',
  KeyedDebitSaleSplitPayment: 'Keyed Debit Sale - Split Payment',
  KeyedDebitRefund: 'Keyed Debit Refund',
  IncompleteKeyedDebitSale: 'Incomplete Keyed Debit Sale',
  IncompleteKeyedDebitRefund: 'Incomplete Keyed Debit Refund',
  IncompleteContactlessEMVDebitSale: 'Incomplete Contactless EMV Debit Sale',
  ChipReadCreditSaleSplitPayment: 'Chip Read Credit Sale - Split Payment',
  ChipReadCreditSale: 'Chip Read Credit Sale',
  IncompleteChipReadCreditSale: 'Incomplete Chip Read Credit Sale',
  ChipReadDebitSaleSplitPayment: 'Chip Read Debit Sale - Split Payment',
  ChipReadDebitSale: 'Chip Read Debit Sale',
  IncompleteChipReadDebitSale: 'Incomplete Chip Read Debit Sale',
  ChipReadVoid: 'Chip Read Void',
  ChipReadRefund: 'Chip Read Refund',
  ChipReadPreAuth: 'Chip Read Pre-Auth',
  CashSaleSplitPayment: 'Cash Sale - Split Payment',
  AppleTapToPayCreditSale: 'Tap to Pay on iPhone Credit Sale',
  AppleTapToPayCreditRefund: 'Tap to Pay on iPhone Credit Refund',
  AppleTapToPayDebitSale: 'Tap to Pay on iPhone Debit Sale',
  AppleTapToPayDebitRefund: 'Tap to Pay on iPhone Debit Refund',
  AppleTapToPayVoid: 'Tap to Pay on iPhone Void',
  PaymentLinkRefund: 'Payment Link Refund',
  PaymentLinkVoid: 'Payment Link Void',
  KeyedEBT: 'Keyed EBT Sale',
  SwipedEBT: 'Swiped EBT Sale',
  TransactionDateRange: 'Transaction Date Range',
  TotalAmount: 'Total Amount',
  From: 'From',
  To: 'To',
  PaymentConfirmation: 'Payment confirmation #',
  Last4: 'Last 4',
  LessFields: 'Less Fields',
  MoreFields: 'More Fields',
  ClearFields: 'Clear Fields',
  TipAmount: 'Tip Amount',
  AuthAmount: 'Auth Amount',
  SoldByEmployee: 'Sold By Employee',
  AuthNumber: 'Auth #',
  AmountHint: '$0.00',
  //Transactions

  // Transactions Details
  Employee: 'Employee',
  Customer: 'Customer',
  IncompleteTransaction: 'Incomplete',
  Transaction: 'Transaction',
  IncompleteFilterMessage: 'Incomplete transactions may include payments made with insufficient funds or split payments that were not completed.',
  OpenTransactionsDetails_one: '{{count}} Open transaction. Add tips before transactions close and settle at {{settleTime}} ET. After this time, you cannot adjust the tip amount.',
  OpenTransactionsDetails_other: '{{count}} Open transactions. Add tips before transaction close and settle at {{settleTime}} ET. After this time, you cannot adjust the tip amount.',
  OpenTransactionsDetailsManualCapture_one: '{{count}} Open transaction. Add tips before transaction close and settle.',
  OpenTransactionsDetailsManualCapture_other: '{{count}} Open transactions. Add tips before transactions close and settle.',

  // MobileScrollNote
  ScrollNoteText: 'Scroll right for more columns',

  // Date Filter
  Today: 'Today',
  Yesterday: 'Yesterday',
  ThisWeek: 'This Week',
  ThisMonth: 'This Month',
  ThisYear: 'This Year',
  LastWeek: 'Last Week',
  LastMonth: 'Last Month',
  LastYear: 'Last Year',
  CustomDateRangeTitle: 'Custom Date Range',
  CustomDateError: '{{dataName}} can only be shown for a period of 18 months or less. Please choose a date range no larger than 18 months.',
  StartDateTime: 'Start Date / Time',
  EndDateTime: 'End Date / Time',
  PreviousCustomTitle: 'Previous Custom Date Range',
  PreviousStartDateTime: 'Previous Start Date / Time',
  PreviousEndDateTime: 'Previous End Date / Time',
  CustomTimeRangeTitle: 'Custom Default Time Range',
  CustomRange: 'Custom Range',
  FilterByDate: 'FILTER BY DATE',

  // Employee Filter
  AllEmployees: 'All Employees',
  ByEmployee: 'Filter by Employee',

  // Levels
  LevelsToggleLabel: 'Level 2 & 3 data',
  Show: 'Show',
  TaxExempt: 'Tax Exempt:',
  CustomerCode: 'Customer Code:',
  TaxAmount: 'Tax Amount:',
  TaxId: 'Tax Id:',
  ItemDescription: 'Item Description:',
  ItemQuantity: 'Item Quantity:',
  MeasureUnit: 'Measure Unit:',
  UnitPrice: 'Unit Price:',
  ProductCode: 'Product Code:',
  OrderDate: 'Order Date:',
  CommodityCode: 'Commodity Code:',
  RequesterName: 'Requester Name:',
  CompanyName: 'Company Name:',
  ShipmentId: 'Shipment Id:',
  ShipmentCountry: 'Shipment Country:',
  ShipmentState: 'Shipment State:',
  ShipmentZip: 'Shipment Zip:',
  DestinationCountry: 'Destination Country:',
  DestinationState: 'Destination State:',
  DestinationZip: 'Destination Zip:',
  DiscountAmount: 'Discount Amount:',
  DutyAmount: 'Duty Amount:',
  FreightAmount: 'Freight Amount:',
  DiscountName: 'Discount Name',
  AddDiscount: 'Add Discount',
  Percentage: 'Percentage',
  DiscountColor: 'Discount Color',
  NewDiscount: 'New Discount',
  DiscountValue: 'Discount Value',

  // Payment Dialog Modal
  CompleteTransaction: 'Complete Transaction',
  CancelTransaction: 'Cancel Transaction',
  TransactionVoided: 'Transaction Voided',
  TransactionCompleted: 'Transaction Completed',
  TransactionCancelled: 'Transaction Cancelled',
  TransactionRefunded: 'Transaction Refunded',
  RefundAsVoidHeading: 'Transaction Complete',
  RefundAsVoidInfo: 'This transaction has been processed as a void.',
  TransactionVoidDeclined: 'Transaction Void Declined',
  TransactionCompleteDeclined: 'Transaction Complete Declined',
  TransactionRefundDeclined: 'Transaction Refund Declined',
  RefundNoteHint: 'e.g. accidental charge',
  PartialRefundsLabel: 'Existing Partial Refunds:',
  PartialRefundAmount: 'Refunded amount:',
  Off: 'off',
  Discount: 'Discount',
  RefundAll: 'Refund All',
  RefundAmount: 'Refund amount',
  TipAmountLimit: 'Tip (Limit {{currentTipLimit}})',
  TotalRefund: 'Total Refund',
  RefundLimit: '(Limit {{currentTotalLimit}})',
  CustomRefundTitle: 'Custom Refund Amount <1>(Limit {{currentTotalLimit}})</1>',
  OptionalRestock: 'Re-stock Options (Optional)',
  Itemized: 'Itemized',
  ItemizedCustom: 'Itemized or Custom',
  NoCustomers: 'No Customers',
  SearchBarPlaceholder: 'Search Customers',

  // Manage Integrations
  ManageIntegrations: 'Manage Integrations',
  RevokeAccess: 'Revoke Access',

  // Gift Card Dialog
  GiftCardRefundDialogInfo: 'Please reload the gift card with the appropriate refund amount or provide a refund with cash.',
  GiftCardRefundDialogTitle: 'Gift Card Refund',

  // Line Item List
  Taxed: 'T',
  ReStock: 'Re-stock',

  // Export Selector
  ExportBtn: 'Export',
  CSVFile: 'CSV File',
  ExportTransactions: 'Export Transactions',
  ExportDisputes: 'Export Disputes',

  // Send Receipt Modal
  AddEmailAddressBtn: 'Add email address',
  AddPhoneNumberBtn: 'Add phone number',

  // Too Many Transactions Modal
  TooManyTransactionDialogTitle: 'Unable to export.',
  TooManyTransactionDialogTransactionInfo: '<1>The amount of data returned in your selection exceeds the {{maxTransactions}} row limit. Please select a smaller date range.</1>',

  // Pending Changes Modal
  PendingChangesTitle: 'Unsaved Tips',
  PendingChangesInfo_one: '<1>You have {{openTransactionsLength}} unsaved change.</1>',
  PendingChangesInfo_other: '<1>You have {{openTransactionsLength}} unsaved changes.</1>',

  CloseTransactions: 'Close Transactions',

  // Success Modal
  SuccessModalInfo: 'All transactions have been sent for settlement.',

  // Error Modal
  ErrorModalInfo: 'We were unable to close your transactions. Please try again.',

  // Error Save Tip Modal
  ErrorSaveTipModalTitle: 'Save Tips',
  ErrorSaveTipModalInfo: 'There was an error saving tips. Please try again.',

  // Close All Transactions Tip Modal
  CloseAllTransactionsTitle: 'Close All Transactions?',
  CloseAllTransactionsInfo: 'Closing your transactions means you are sending your transactions off for settlement. Make sure you have entered in tips, if applicable.',

  //Deposits
  All: 'All',
  Withdrawals: 'Withdrawals',
  'Resolved transactions': 'Resolved transactions',
  'Held funds': 'Held funds',

  //Batches
  BatchDetails: 'Batch Details',
  NoBatches: 'No Batches',
  NoBatchDetails: 'No Batch Details',
  ViewOpenBatch: 'View Open Batch',

  //Reports
  'All Reports': 'All Reports',
  'Sales Reports': 'Sales Reports',
  'Customer Reports': 'Customer Reports',
  'Employee Reports': 'Employee Reports',
  'Inventory Reports': 'Inventory Reports',
  'Custom Reports': 'Custom Reports',
  'What does Total Collected mean?': 'What does Total Collected mean?',

  SearchReports: 'Search Reports',
  AccountSummary: 'Account Summary',
  'A breakdown of gross sales and total collected by account.': 'A breakdown of gross sales and total collected by account.',
  BatchDetail: 'Batch Detail',
  'The sum of all your authorized credit card transactions for the day at close of business, or by the designated batch closing time.': 'The sum of all your authorized credit card transactions for the day at close of business, or by the designated batch closing time.',
  BatchSummary: 'Batch Summary',
  'An overview of your batches with totals.': 'An overview of your batches with totals.',
  BrandSummary: 'Brand Summary',
  'Brand Summary': 'Brand Summary',
  'View sales totals for all transactions by brand.': 'View sales totals for all transactions by brand.',
  FraudMonitoring: 'Fraud Monitoring',
  'The TC40 report from Visa and SAFE report from MasterCard with information about customer fraud claims.': 'The TC40 report from Visa and SAFE report from MasterCard with information about customer fraud claims.',
  CardBrandSummary: 'Card Brand Summary',
  'View sales totals for credit transactions by card brand.': 'View sales totals for credit transactions by card brand.',
  CustomReports: 'Custom Reports',
  CustomerExport: 'Customer Export',
  'Export a listing of your customers and their contact information.': 'Export a listing of your customers and their contact information.',
  CustomerExperience: 'Customer Experience',
  'A summary of customer feedback by name, date, invoice number, rating, and employee.': 'A summary of customer feedback by name, date, invoice number, rating, and employee.',
  CustomerPerformance: 'Customer Performance',
  'Track top customers and see their spending habits.': 'Track top customers and see their spending habits.',
  DepositDetail: 'Deposit Detail',
  'The transactions and related fees within each deposit and withdrawal posted to your bank account.': 'The transactions and related fees within each deposit and withdrawal posted to your bank account.',
  DepositSummary: 'Deposit Summary',
  'An overview of your deposits with net totals': 'An overview of your deposits with net totals',
  EstimatedDepositDate: 'Estimated Deposit Date',
  DepositInitiated: 'Deposit Initiated',
  DisputesReport: 'Disputes Report',
  'View the status of your disputes.': 'View the status of your disputes.',
  EmployeeFlashReport: 'Employee Flash Report',
  'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods by single employee.': 'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods by single employee.',
  SearchEmployees: 'Search Employees',
  SelectEmployee: 'Select Employee',
  EmployeePerformance: 'Employee Performance',
  'Track employees by number of transactions and total collected.': 'Track employees by number of transactions and total collected.',
  ExpandedTransactionReport: 'Expanded Transaction Report',
  CreateReportTitle: 'Create Custom Report',
  ReportName: 'Report Name',
  SelectReportFields: 'Create a custom report by giving it a name and selecting the data you would like in your report.',
  'Export an expanded listing of all transactions in a period. Includes deposit dates, deposit amounts, and applicable fees.': 'Export an expanded listing of all transactions in a period. Includes deposit dates, deposit amounts, and applicable fees.',
  FlashReport: 'Flash Report',
  GiftCardSummary: 'Gift Card Summary',
  'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods. Ideal for closing your day.': 'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods. Ideal for closing your day.',
  InventoryExport: 'Inventory Export',
  'Export your inventory with stock counts.': 'Export your inventory with stock counts.',
  ItemPerformance: 'Item Performance',
  'Track items by number sold, refunded, voided, and total collected.': 'Track items by number sold, refunded, voided, and total collected.',
  LoyaltyRewardsReport: 'Loyalty Rewards Report',
  LoyaltyRewardsReportDisabled: 'Loyalty Rewards Report (Deactivated)',
  'View a listing of your customers who have earned rewards.': 'View a listing of your customers who have earned rewards.',
  ModifierPerformance: 'Modifier Performance',
  'Track modifiers by modifier set and individual modifier used.': 'Track modifiers by modifier set and individual modifier used.',
  PaymentTypeSummary: 'Payment Type Summary',
  'View sales totals by type of transaction.': 'View sales totals by type of transaction.',
  ProductSales: 'Product Sales',
  'Track category performance and items within category by items sold and total sales.': 'Track category performance and items within category by items sold and total sales.',
  'View tax collected.': 'View tax collected.',
  'Time Clock and Schedules': 'Time Clock and Schedules',
  'View clock in/out, break times, and employee schedules via Homebase.': 'View clock in/out, break times, and employee schedules via Homebase.',
  'Export a listing of all transactions in a period.': 'Export a listing of all transactions in a period.',
  TransactionsByCategory: 'Transactions by Category',
  'View transactions by category.': 'View transactions by category.',
  TransactionsByItem: 'Transactions by Item',
  'View transactions by item.': 'View transactions by item.',
  DeclinedTransactions: 'Declined Transactions',
  'Export a listing of all declined transactions in a period.': 'Export a listing of all declined transactions in a period.',
  RefundSummary: 'Refund Summary',
  'View refunded transactions in a period.': 'View refunded transactions in a period.',
  Export: 'Export',
  'This is the amount that you sold. Includes your transactions.': 'This is the amount that you sold. Includes your transactions.',
  'This is the amount that you earn as a business. Includes your transactions with discounts, refunds, and voids.': 'This is the amount that you earn as a business. Includes your transactions with discounts, refunds, and voids.',
  'This is the amount that you collect. Includes your transactions with discounts, refunds, voids, taxes, and tips.': 'This is the amount that you collect. Includes your transactions with discounts, refunds, voids, taxes, and tips.',
  NoGiftCardSummary: 'No Gift Card Summary Report Data',
  'An overview of gift cards activated/sold, redeemed, and refunds to gift cards. Details about payments accepted with gift cards are located in your Flash or Transactions reports.': 'An overview of gift cards activated/sold, redeemed, and refunds to gift cards. Details about payments accepted with gift cards are located in your Flash or Transactions reports.',
  ReportComingSoon: 'Report Coming Soon!',
  NoCustomReports: 'No Custom Reports created',
  AllAccounts: 'All Accounts',
  UnauthorizedErrorModal: {
    Title: 'Error',
    Message: 'User does not have access to generate this report'
  },
  DeleteReportDialog: {
    Title: 'Delete Report',
    Message: 'Are you sure you want to delete your custom report?',
    Confirm: 'Yes, delete'
  },

  // Settings menu
  'Payment Settings': 'Payment Settings',
  'Receipt & invoice settings': 'Receipt & invoice settings',
  'Business Profile': 'Business profile',
  'PCI Compliance': 'PCI Compliance',
  'Online store': 'Online store',
  '1099K & tax': '1099K & tax',
  'Funding': 'Funding',
  'Communication Settings': 'Communication settings',
  'Integrations': 'Integrations',
  'Devices': 'Devices',
  'API Access': 'API Access',

  // Hardware & Supplies menu
  'Shop Supplies': 'Shop Supplies',
  'Shop Hardware': 'Shop Hardware',
  'Order History': 'Order History',
  'Allotments': 'Allotments',
  'Electronic Return Labels': 'Electronic Return Labels',

  // Employees menu
  'All Employees': 'All Employees',
  'Administrator': 'Administrator',
  'Manager': 'Manager',
  'Cashier': 'Cashier',
  'Reporter': 'Reporter',
  'Archive': 'Archive',

  // Reputation menu
  'Reputation': 'Reputation',
  'Trends': 'Trends',
  'Customer Reviews': 'Customer Reviews',
  'Competitor Performance': 'Competitor Performance',
  'Settings': 'Settings',

  // Inventory
  ModifierSets: 'Modifier Sets',
  'Modifier sets': 'Modifier sets',
  'Archived items': 'Archived items',

  // Customers menu
  'Add Customer': 'Add Customer',
  'All Customers': 'All Customers',
  'With Active Reward': 'With Active Reward',
  'Loyalty Program': 'Loyalty Program',
  'Activity & Reports': 'Activity & Reports',
  'Loyalty Program Settings': 'Loyalty Program Settings',

  // Invoices menu
  'All Invoices': 'All Invoices',
  Paid: 'Paid',
  Overdue: 'Overdue',
  OverdueToday: 'Overdue Today',
  OverdueDays: 'Overdue {{days}}',
  Failed: 'Failed',
  'Failed payments': 'Failed payments',
  Scheduled: 'Scheduled',
  Series: 'Series',
  Drafts: 'Drafts',
  Draft: 'Draft',
  Canceled: 'Canceled',
  'Bulk Invoicing': 'Bulk Invoicing',
  Active: 'Active',

  // Virtual terminal menu
  'Virtual terminal': 'Virtual terminal',
  'Custom amount': 'Custom amount',
  'Create an invoice': 'Create an invoice',
  'Itemized sale': 'Itemized sale',
  'Issue a credit': 'Issue a credit',

  // Disputes menu
  'All Disputes': 'All Disputes',
  'Action Required': 'Action Required',
  'Under Review': 'Under Review',
  Closed: 'Closed',
  'See how it works': 'See how it works',
  'Refund & Chargeback Policies': 'Refund & Chargeback Policies',
  'Reason Code Glossary': 'Reason Code Glossary',

  //

  // Disputes FAQ
  DisputesFAQ: 'Disputes FAQ',
  Definitions: 'Definitions',
  Dispute: 'Dispute',
  Chargeback: 'Chargeback',
  ChargebackReversa: 'Chargeback Reversa',
  Representment: 'Representment',
  RepresentmentRevAdj: 'Representment Rev/Adjustment',
  DisputeReversal: 'Dispute Reversal',
  DisputeResponseReversal: 'Dispute Response Reversal',
  Acquirer: 'Acquirer',
  AcquirerDesc: 'Your merchant services provider',
  Issuer: 'Issuer',
  IssuerDesc: 'The cardholder\'s bank that issued the card',
  Rebuttal: 'Rebuttal',
  Required: 'Required',
  ReasonCode: 'Reason Code',
  Example: 'Example',
  RebuttalDesc: 'A merchant\'s response to Issuer, disputing the cardholder\'s chargeback / dispute',
  CompellingEvidence: 'Compelling Evidence',
  CompellingEvidenceDesc: 'A merchant\'s response to Issuer, disputing the cardholder\'s chargeback / dispute',
  PreArbitration: 'Pre-Arbitration',
  PreArbitrationDesc: 'The phase of Visa\'s dispute process immediately preceding Arbitration.',
  VCRTitle: 'What is Visa Claims Resolution (VCR)?',
  VCRAnswer: 'VCR is Visa\'s new dispute program, formally called chargebacks. The new dispute will be effective April 13, 2018.',
  VisaTitle: 'Why is Visa implementing this new dispute process?',
  VisaAnswer: 'The current chargeback process is not flexible enough to handle the volume and complexity of the modern payments industry. The new dispute process is a simplified process that speeds up the end to end dispute cycle time.',
  VCRBenefitsTitle: 'What are the benefits of VCR?',
  VCRBenefitsAnswer: 'Visa will evaluate and determine the validity of the dispute created by the issuer. Visa will block invalid disputes and the merchant/acquirer are not required to take any action. This process leads to fewer disputes',
  ChangingTitle: 'What is changing?',
  ChangingAnswer: 'As part of the VCR initiative, some of the terminologies related to the dispute are changing. Please note, these are for Visa only at this time',
  CTerminologyTableTitle: 'Current Terminology',
  NTerminologyTableTitle: 'New Terminology',
  ChangingListItem1: 'New reason codes will be used instead of the current reason codes',
  ChangingListItem2: 'Dispute will fall under one of the two categories that Visa created, <strong>Allocation</strong> and <strong>Collaboration</strong> workflows (more on that below)',
  ChangingListItem3: 'For the <strong>Allocation</strong> workflow, no provisional credit will be issued if the disputed case is being challenged.',
  ChangingListItem4: 'Visa has clearly defined what is acceptable as compelling evidence for each reason code under Allocation. Merchants will only be allowed to challenge the dispute if they are able to submit compelling evidence',
  ChangingListItem5: 'All merchants, acquirers and issuers are impacted by the new dispute program',
  ChangingListItem6: 'Only net new disputes that occur after April 13, 2018 will go through the new dispute process (VCR)',
  WorkflowTitle: 'What is Allocation and Collaboration workflow?',
  WorkflowAnswer1: 'Disputes categorized within the <strong>Allocation</strong> workflow are related to fraud and authorization issues. Visa will perform a series of new checks in their system to help eliminate invalid disputes (i.e. in cases where merchants have already credited cardholders). After these checks, Visa will then "allocate" the financial liability of these disputes to the acquirer and the merchant. It is anticipated that a majority of the disputes will fall under Allocation workflow.',
  WorkflowAnswer2: 'Disputes for cases tagged as "Customer Disputes" (i.e. goods and services disputes) or as "Processing Errors" will follow the <strong>Collaboration</strong> workflow, which is similar to Visa\'s existing chargeback process.',
  DisputeProcessTitle: 'How does the new dispute process impact the merchant?',
  DisputeProcessListItem1: 'For Allocation disputes, merchant must respond within 14 days. The acquirer will accept the dispute on behalf of the merchant if a response is not received. If the dispute is challenged then pre-arbitration instead of representment must be initiated by the merchant, compelling evidence must be submitted. Provisional credit will not be issued to the merchant upon challenging the dispute. Credit will be issued only if the final ruling is made on behalf of the merchant. If the issuer declines pre-arbitration, then the acquirer may file for arbitration. Merchant will be liable for a fee of at least $550 in case the final ruling is made on behalf of the merchant.',
  DisputeProcessListItem2: 'For Collaboration disputes, merchant must respond within 14 days. The acquirer will accept the dispute on behalf of the merchant if a response is not received. The dispute process will remain the same as the current chargeback process, and the merchant will continue to receive provisional credit upon challenging the dispute.',
  ArbitrationTitle: 'What is Pre-Arbitration and Arbitration',
  ArbitrationAnswer: '"Arbitration" is a binding dispute resolution process where the card brand association (in this case Visa) will evaluate all the evidence and issue a final ruling. It is the last resort for a merchant and their Acquirer to fight liability for the cardholder\'s dispute, and both sides are assessed fees in the process. The losing party in an arbitration case can be liable for as much as $550 in fees, so only cases with the strongest evidence should ever proceed to arbitration. "Pre-Arbitration" is the phase directly preceding arbitration, and is the only phase for a merchant to rebut an <strong>Allocation</strong> case. If the merchant has <strong>compelling evidence</strong> to fight an allocation case, they must submit it within 14 days of the dispute notice and agree to pay the full cost of arbitration if the issue rejects their rebuttal.',
  ReasonCodeTitle: 'What are the reason codes?',
  ReasonCodeAnswer: 'To simplify the complex current system, the VCR initiative consolidated reason codes into four fundamental dispute categories, as shown in this chart:',
  ReasonCode10: '10. Fraud',
  'ReasonCode10.1': '10.1 – EMV Liability Shift Counterfeit Fraud',
  'ReasonCode10.2': '10.2 – EMV Liability Shift Non-Counterfeit Fraud',
  'ReasonCode10.3': '10.3 – Other Fraud-Card Present Environment',
  'ReasonCode10.4': '10.4 – Other Fraud-Card Absent Environment',
  'ReasonCode10.5': '10.5 – Visa Fraud Monitoring Program',
  ReasonCode11: '11. Authorization',
  'ReasonCode11.1': '11.1 – Card Recovery Bulletin',
  'ReasonCode11.2': '11.2 – Declined Authorization',
  'ReasonCode11.3': '11.3 – No Authorization',
  ReasonCode12: '12. Processing Errors',
  'ReasonCode12.1': '12.1 – Late Presentment',
  'ReasonCode12.1a': '12.1: Late Presentment',
  'ReasonCode12.2': '12.2 – Incorrect Transaction Code',
  'ReasonCode12.2a': '12.2: Incorrect Transaction Code',
  'ReasonCode12.3': '12.3 – Incorrect Currency',
  'ReasonCode12.3a': '12.3: Incorrect Currency',
  'ReasonCode12.4': '12.4 – Incorrect Account Number',
  'ReasonCode12.4a': '12.4: Incorrect Account Number',
  'ReasonCode12.5': '12.5 – Incorrect Amount',
  'ReasonCode12.5a': '12.5: Incorrect Amount',
  'ReasonCode12.6': '12.6: Duplicate processing / paid by other means',
  'ReasonCode12.6.1': '12.6.1 – Duplicate Processing',
  'ReasonCode12.6.2': '12.6.2 – Paid by Other Means',
  'ReasonCode12.7': '12.7 – Invalid Data',
  ReasonCode13: '13. Consumer Disputes',
  'ReasonCode13.1': '13.1 – Merchandise/Services Not Received',
  'ReasonCode13.1a': '13.1: Not as described or defective merchandise / services',
  'ReasonCode13.2': '13.2 – Cancelled Recurring',
  'ReasonCode13.3': '13.3 – Not as Described or Defective Merchandise/ Services',
  'ReasonCode13.4': '13.4 – Counterfeit Merchandise',
  'ReasonCode13.4a': '13.4: Counterfeit Merchandise',
  'ReasonCode13.5': '13.5 – Misrepresentation',
  'ReasonCode13.5a': '13.5: Misrepresentation',
  'ReasonCode13.6': '13.6 – Credit Not Processed',
  'ReasonCode13.6a': '13.6: Credit Not Processed',
  'ReasonCode13.7': '13.7 – Cancelled Merchandise/Services',
  'ReasonCode13.7a': '13.7: Canceled merchandise / service',
  'ReasonCode13.8': '13.8 – Original Credit Transaction Not Accepted',
  'ReasonCode13.9': '13.9 – Non-Receipt of Cash or Load Transaction Value',
  ReasonCodeNote: '**Visa chargeback reason code 75 ("Unrecognized Transaction") is no longer available. ',
  CompellingEvidenceTitle: 'What compelling evidence should the merchant submit in order to initiate pre-arbitration?',
  CompellingEvidenceAnswer1: 'With the new workflows in place, merchants will be required to provide "compelling evidence," as outlined in the Visa Rules. If a merchant does not submit the required pieces of compelling evidence, it is unlikely that Visa will rule in the merchant\'s favor.',
  CompellingEvidenceAnswer2: 'Merchants have the option to provide more than one piece of evidence when combating disputes. It is recommended that merchants collect as much compelling evidence as possible in these cases.',
  CompellingEvidenceList1Text: 'The following two reason codes require compelling evidence:',
  CompellingEvidenceList1Item1: 'Fraud/card absent environment (1040)',
  CompellingEvidenceList1Item2: 'Merchandise/services not received (1310)',
  CompellingEvidenceList2Text: 'You must submit at least one of the following for these types of disputes:',
  CompellingEvidenceList2Item1: 'Documentation to prove the cardholder is in possession of and/or using the merchandise',
  CompellingEvidenceList2Item2: 'Proof of a signed delivery form, or copy of the customer ID as proof goods were picked up at your business location',
  CompellingEvidenceList2Item3: 'Proof of delivery to the address associated with an AVS matched response',
  CompellingEvidenceList2Item4: 'Proof of delivery and customer proof of employment at the delivery location',
  CompellingEvidenceList2Item5: 'Proof that ticket was received or used',
  CompellingEvidenceList2Item6: 'Proof of an undisputed transaction for the same merchant or service',
  CompellingEvidenceList2Item7: 'Proof that the transaction was completed by an authorized signer associated with the cardholder',
  CompellingEvidenceList2Item8: 'Proof of a signed order form for a mail or phone transaction',
  CompellingEvidenceList2Item9: 'Proof of digital goods downloaded from a merchant\'s website or application',
  CompellingEvidenceList2Item10: 'Proof for the previous transaction for same merchandise or service',
  CompellingEvidenceList2Item11: 'Proof of recurring transaction history',
  CompellingEvidenceTableText: 'If you choose to provide any of the three types of compelling evidence highlighted above in bold, each will require explicit evidence be submitted. Here\'s a breakdown of these evidence requirements:',
  CompellingEvidenceTableHeader1: 'Compelling evidence description',
  CompellingEvidenceTableHeader2: 'Evidence Requirement',
  CompellingEvidenceTableDescription1: 'Proof of digital goods downloaded from a merchant\'s website or application',
  CompellingEvidenceTableDescription2: 'Proof for the previous transaction for same merchandise or service',
  CompellingEvidenceTableDescription3: 'Proof of recurring transaction history',
  CompellingEvidenceTableEvidenceList1Text: 'Both bulleted items below are required:',
  CompellingEvidenceTableEvidenceList2Text: '<strong>Plus</strong> two or more of the following bulleted items are required:',
  CompellingEvidenceTableEvidenceList3Text: 'Other evidence examples for other common dispute reason codes: *',
  CompellingEvidenceTableEvidenceListItem1: 'Description of the product successfully download',
  CompellingEvidenceTableEvidenceListItem2: 'Date and time services were downloaded',
  CompellingEvidenceTableEvidenceListItem3: 'Customer IP address and device location',
  CompellingEvidenceTableEvidenceListItem4: 'Device ID and device name',
  CompellingEvidenceTableEvidenceListItem5: 'Customer name and Customer email',
  CompellingEvidenceTableEvidenceListItem6: 'Customer profile',
  CompellingEvidenceTableEvidenceListItem7: 'Customer profile usage history',
  CompellingEvidenceTableEvidenceListItem8: 'Undisputed previous transaction ID',
  CompellingEvidenceTableEvidenceListItem9: 'Transaction date',
  CompellingEvidenceTableEvidenceListItem10: 'Proof of delivery',
  CompellingEvidenceTableEvidenceListItem11: 'Customer IP address',
  CompellingEvidenceTableEvidenceListItem12: 'Customer email address',
  CompellingEvidenceTableEvidenceListItem13: 'Customer address',
  CompellingEvidenceTableEvidenceListItem14: 'Customer phone number',
  ReasonCodeTableExampleListItem1: 'Photographs, emails, or additional evidence that proves the merchandise or service matched description',
  ReasonCodeTableExampleListItem2: 'Proof customer didn\'t attempt a return',
  ReasonCodeTableExampleListItem3: 'Proof customer didn\'t return goods',
  ReasonCodeTableExampleListItem4: 'Proof merchant did due diligence to repair or replace merchandise/services',
  ReasonCodeTableExampleListItem5: 'Evidence showing merchandise isn\'t counterfeit',
  ReasonCodeTableExampleListItem6: 'Third-party consultation that proves legitimacy',
  ReasonCodeTableExampleListItem7: 'Proof of refund',
  ReasonCodeTableExampleListItem8: 'Evidence showing terms and conditions were clearly communicated to cardholder before the transaction was completed',
  ReasonCodeTableExampleListItem9: 'Proof of refund or store credit',
  ReasonCodeTableExampleListItem10: 'Evidence showing merchant terms of service (refund and cancellation policy)',
  ReasonCodeTableExampleListItem11: 'Evidence proving customer acknowledged terms of service',
  ReasonCodeTableExampleListItem12: 'Example: They clicked checkbox confirming they understand the terms of service',
  ReasonCodeTableExampleListItem13: 'Proof of transaction is too old to be disputed (transaction receipt showing the transaction is older than 180 days)',
  ReasonCodeTableExampleListItem14: 'Copy of transaction receipt showing the currency was correct',
  ReasonCodeTableExampleListItem15: 'Copy of invoice showing transaction was applied to correct account',
  ReasonCodeTableExampleListItem16: 'Copy of transaction receipt or invoice showing the amount was correct',
  ReasonCodeTableExampleListItem17: 'Evidence proving merchant processed two separate transactions for the different orders',
  ReasonCodeTableExampleListItem18: 'Along with changes to the dispute flow, there will also be more stringent timelines. Please keep in mind the due date of your disputes, as no late or additional submissions will be accepted by Visa.',

  // User menu
  'Premium Member': 'Premium Member',
  'Free Member': 'Free Member',
  'Log Out': 'Log Out',
  'Add an Account': 'Add an Account',
  'No Account name': 'No Account name',
  'User Settings': 'User Settings',
  'Related Mids': 'Related Mids',

  // Messages

  // Toastr Dialogs
  Toastr: {
    PasswordExpiration: {
      Title: 'Password expiration',
      Message: 'Your password is over 30 days old. Keep your accounts safe and set a new password before it expires here.',
      ReferenceWord: 'here'
    },
    Error: {
      Title: 'Error'
    }
  },
  // What's New
  Notice: 'Notice',
  // Maintenance
  SiteMessage1: 'As part of our constant focus on providing the highest level of security and service for you, {{labelText}} has a scheduled system maintenance June 28, beginning at 1:00 am EDT and ending at approximately 5:00 am EDT.',
  SiteMessage2: 'During this time you will not be able to login or process any transactions.',
  MaintenanceTitle: 'System Maintenance',
  // Feedback
  FeedbackMessage: 'Confirm or revise your rating and press Submit.',
  FeedbackThanks: 'Your feedback has been received. Feel free to contact us with questions or concerns.',
  // Too Large
  TooLargeMessage: 'Upload too large: please try a smaller size (under 5MB)',
  // Too Large Transactions
  TooLargeTransactionsMessage: 'Too many transactions: please try selecting a smaller time frame.',
  // Employee
  EmployeeFullAccess: 'Full Access',
  EmployeeMidLevelAccess: 'Mid-Level Access',
  EmployeeLimitedAccess: 'Limited Access',
  EmployeeActivityAccess: 'Access to Activity',
  EmployeeActivityOnlyAccess: 'Activity Only',
  EmployeeVirtualTerminalAccess: 'Access to Virtual Terminal',
  EmployeeInvoicesAccess: 'Access to Invoices',
  EmployeePaymentLinksAccess: 'Access to Payment Links',
  EmployeeReputationManagementAccess: 'Access to Reputation Management',
  EmployeeMBPOwnerDescription: 'Full access to Payments Hub. The Owner role cannot be archived or changed.',
  EmployeeMBPAdminDescription: 'Full access to Payments Hub. This role allows the same permissions as the Owner, but does not allow access to Owner information. The Admin role should only be assigned to your most trusted and senior employees.',
  EmployeeMBPManagerDescription: 'Limited access to Payments Hub. As an Owner or Admin, you must grant the Manager role access to additional permissions, such as Activity, Virtual Terminal, and Invoices.',
  EmployeeMBPManagerOptionActivityDescription: 'Allow Managers to access Sales, Deposits, Reports, and Statements.',
  EmployeeMBPManagerOptionOnlinePaymentsDescription: 'Allow Managers to run credit card transactions using the virtual terminal in {{labelText}}.',
  EmployeeMBPManagerOptionInvoiceActionsDescription: 'Allow Managers to create and manage Invoices in {{labelText}}.',
  EmployeeMBPReportDescription: 'Limited access to Payments Hub to view and run reports.',
  EmployeeMBPStaffDescription: 'No access to Payments Hub. The Staff role is intended for use with the Homebase employee management integration.',
  EmployeeOwnerDescription: 'Full access to Payments Hub and the Payanywhere app. The Owner role cannot be archived or changed.',
  EmployeeAdminDescription: 'Full access to Payments Hub and the Payanywhere app. This role allows the same permissions as the Owner, but does not allow access to Owner information. The Admin role should only be assigned to your most trusted and senior employees.',
  EmployeeManagerDescription: 'Limited access to Payments Hub. Full access to the Payanywhere app. As an Owner or Admin, you must grant the Manager role access to additional permissions, such as Activity, Virtual Terminal, and Invoices.',
  EmployeeManagerOptsActivityDescription: 'Allow Managers to access Sales, Deposits, Reports, and Statements in {{labelText}} and view Sales Trends in the Payanywhere app.',
  EmployeeManagerOptsOnlinePaymentsDescription: 'Allow Managers to run credit card transactions using the virtual terminal in {{labelText}}.',
  EmployeeManagerOptsInvoiceActionsDescription: 'Allow Managers to create and manage invoices in {{labelText}} and in the Payanywhere app.',
  EmployeeManagerOptsPaymentLinkActionsDescription: 'Allow Managers to create and manage payment links in {{labelText}}.',
  EmployeeManagerOptsReputationActionsDescription: 'Allow Managers to view customer reviews and draft responses that will be sent to your customers.',
  EmployeeReportDescription: 'Limited access to Payments Hub to view and run reports. No access to the Payanywhere app.',
  EmployeeCashierDescription: 'No access to Payments Hub. Limited access to the Payanywhere app. As an Owner or Admin, you can edit the Cashier role to set void and refund limits for Cashiers within the Payanywhere app.',
  EmployeeStaffDescription: 'No access to Payments Hub. Limited access to the Payanywhere app in order to use the Time Clock. Staff are restricted from accessing payment or reporting functionality within the Payanywhere app.',
  EmployeeCsAccess: 'Customer Support',
  EmployeeCsDescription: 'This user can access the {{labelText}} app with customer support permissions via customer support login, but cannot log into {{labelText}} Inside.',
  HomebaseEmployeeError: 'This employee was created on {{labelText}}, but could not be added to Homebase. Check to see if the employee\'\s email or phone number is already being used within Homebase. Contact Homebase at help@joinhomebase.com or 415.951.3830.',
  DuplicateEmployeeError: 'You\'ve\ entered an email address that already exists as a user for this account. Please try again with a different email.',
  InvoiceGeneratedNumber: 'If no invoice number is entered, one will be automatically generated.',
  InvoiceConfirmEndSeries: 'Are you sure you want to end the {{invoiceName}} series?',
  InvoiceSendToCustomer: 'Customer will receive a link to add payment details. This invoice will appear as unpaid until customer pays.',
  AutopayConfirmRemoveSeriesAutopay: 'Are you sure you want to unenroll from autopay for the {{invoiceName}} series?',
  AutopayConfirmRemoveAutopay: 'Are you sure you want to unenroll from autopay?',
  Validator: {
    RequireText: 'Required',
    AlphaNumericRequired: 'Field must be an alphanumeric value',
    IncorrectPartialAmount: 'Partial amount not valid',
    AccessMustBeSelected: 'Please select the access required',
    InvalidPhoneNumber: 'This phone number is invalid',
    InvalidMobileNumber: 'Invalid mobile number',
    customerEquityOwnershipMore: 'Equity ownership value cannot be more than 100%',
    customerEquityOwnershipLess: 'Equity ownership total must be greater than 75%',
    customerEquityOwnershipZero: 'Equity ownership value cannot be zero',
    customerEquityOwnershipPrimary: 'Primary equity ownership value must be greater than 75%',
    customerOwnershipPrimary: 'You must select a primary owner',
    InvalidEmail: 'This email address is invalid',
    InvalidEmailAmount: 'You can only add 5 emails',
    InvalidAmountRange: 'Invalid amount range',
    InvalidDateRange: 'Invalid date range',
    InvalidPreviousDateRange: 'Invalid previous date range',
    InvalidWebDomain: 'Invalid web domain',
    HttpsRequired: 'The URL must include https://',
    PreviousRangeIdentical: 'Previous range is identical',
    PasswordsDoNotMatch: 'New Password and Confirm Passwords do not match!',
    PasswordsDoNotMatchNew: 'Password and Verified Passwords do not match!',
    InvalidPassword: 'Passwords must have at least 8 characters and contain one uppercase, one lowercase letter, and one number or symbol (~!@#$%^&*()-=+).',
    EmailsDoNotMatch: 'New Email and Confirm Email Addresses do not match!',
    EmailOrPhoneRequired: 'Email address or phone number is required',
    AmountRequired: 'Amount is required',
    ExpirationDateInvalid: 'Expiration date is not valid',
    ExpirationDateRequired: 'Expiration date is required',
    ExpirationDateExpired: 'Expiration date is expired',
    CreditCardRequired: 'Credit card number is required',
    CreditCardInvalid: 'Credit card number is invalid',
    DebitCardRequired: 'Debit card number is required',
    DebitCardInvalid: 'Debit card number is invalid',
    DebitCardInvalidForInstantFunding: 'Instant Funding can only be processed using a Mastercard or a Visa Debit Card.',
    CreditCardInvalidForDebtRepayment: 'Debt Repayment transactions can only be processed using a Mastercard or a Visa.',
    SecurityCodeRequired: 'Security code is required',
    SecurityCodeInvalid: 'Security Code is invalid',
    BillingStreetNumberRequired: 'Credit card billing street number is required',
    BillingStreetNumberInvalid: 'Credit card billing street number is invalid',
    InvalidStreet1: 'Street 1 is not valid',
    RequiredStreet1: 'Street 1 is required',
    RequiredState: 'State is required',
    RequiredCity: 'City is required',
    ZipRequired: 'Zip is required',
    ZipInvalid: 'Zip is invalid',
    ZipRequiredWithZipPlusFour: 'Zip is required with Zip +4',
    ZipShouldBeFiveDigits: 'Zip should be 5 digits',
    ZipPlusFourShouldBeFourDigits: 'Zip +4 should be 4 digits',
    NameOnCardRequired: 'Name on card required',
    FirstAndLastNameRequired: 'First and last name required',
    FirstOrLastNameRequired: 'First or last name required',
    EmailRequired: 'Email required',
    DateCannotBeInPast: 'Date cannot be in past',
    RecurringEndRequiredAndNonZero: 'Must be greater than 0',
    RecurringRepeatRequiredAndNonZero: 'Must be greater than 0',
    RecurringEndLessThanStart: 'Must be greater than start date',
    InvalidName: 'Invalid name',
    InvalidPrice: 'Invalid price',
    InvalidQuantity: 'Track Item is enabled, a quantity is required',
    InvalidDate: 'Invalid date',
    InvalidOneOrMorePrice: 'Items require one or more prices',
    MissingModifiersError: 'Modifier Sets require one or more modifiers',
    TargetValueRequired: 'Required',
    ClosedAccount: 'This account has been closed and displays historical data only. Give us a call at {{phoneNumber}} if you need further assistance.',
    ClosedAccountTitle: 'Closed Account',
    InvalidAccountNumber: 'The account number cannot be less than 4 digits, and not greater than 17 digits, and not equal to 16 digits',
    AbaInvalidNumber: 'Invalid ABA routing number',
    InvalidRoutingNumber: 'The routing number must be 9 digits long',
    RoutingNumberNotFound: 'The routing number is invalid',
    InvalidSSN: 'The Social Security Number must be 9 characters long',
    InvalidSSNFormat: 'The Social Security Number is invalid',
    InvalidFFSSN: 'The Social Security Number must be 5 characters long',
    AccountNameLengthInvalid: 'A first name and a last name are required',
    AccountNumber: 'Required. Check your most recent statement for your Merchant ID/Account Number.',
    AccountNumberInvalid: 'Required. Check your most recent statement for your Merchant ID/Account Number.',
    BankingAccountNumberInvalid: 'Bank account number is invalid',
    RoutingNumberInvalid: 'Bank routing number is invalid',
    AlreadyAttachedToAccount: 'Already attached to this account.',
    SsnTaxId: 'One field is required (SSN or Tax id)',
    PrincipleNameError: 'Principle Name must contain a first and last name separated by a space',
    CashFixedAmount: 'Value range should be between $0.01 and $1',
    CashPercentAmount: 'Value range should be between 1% and 4%',
    InvalidOwnershipPercentage: 'Ownership percentage cannot be more than 100%',
    InvalidContactPriority: 'Value is already assigned to other owner',
    TaxAmountMustBeGreaterThanZero: 'Tax amount must be greater than zero if taxable selected',
    TaxAmountMustBeEqualGreaterThanZeroNoTaxable: 'Tax amount must be equal or greater than zero',
    TaxAmountMetaphorMustBeLessThanNinetyNine: 'Tax amount before decimal point can not be greater than 99',
    TaxAmountMantissaMustBeEqualOrLessThanThreeDigits: 'Tax amount after decimal point can only be 3 or less digits',
    InvalidDisputeNote: 'Your notes cannot be longer than 1000 characters. Use letters and numbers. ("-", ".", and spaces are allowed. Special characters like "#", "&", "$" are not allowed.)',
    InvalidDisputeDocumentDescription: 'Your description cannot be longer than 50 characters. Use letters and numbers. ("-", ".", and spaces are allowed. Special characters like "#", "&", "$" are not allowed.)',
    InvalidURL: 'URL is not in the correct format. Example: https://www.payanywhere.com',
    NorthInvalidURL: 'URL is not in the correct format. Example: https://www.north.com',
    InvalidSecureURL: 'Please provide a valid and secure HTTPS url. Example: https://www.payanywhere.com',
    InvalidFacebookHandle: 'Invalid Facebook handle',
    InvalidTwitterHandle: 'Invalid Twitter handle',
    InvalidInstagramHandle: 'Invalid Instagram handle',
    InvalidYelpHandle: 'Invalid Yelp handle',
    PaymentTypeRequired: 'Please select a payment type',
    PaymentInputTypeRequired: 'Please select a payment input method',
    CustomerCodeRequired: 'Customer Code is required.',
    TaxAmountRequired: 'Tax Amount is required.',
    TaxIdRequired: 'Tax ID is required.',
    InvalidTaxId: 'Invalid tax ID',
    ItemDescriptionRequired: 'Item Description is required.',
    ItemQuantityRequired: 'Item Quantity is required.',
    MeasureUnitRequired: 'Unit of Measure is required.',
    UnitPriceRequired: 'Price Unit is required.',
    ProductCodeRequired: 'Product Code is required.',
    CommodityCodeRequired: 'Commodity Code is required.',
    RequestorNameRequired: 'Requestor Name is required.',
    CompanyNameRequired: 'Company Name is required.',
    LengthOfFieldTooLong: 'Length of field cannot exceed {{maxLength}} characters',
    FirstFiveSSNShouldBeFiveDigits: 'Value should be 5 digits',
    TipType: 'Please select the type of the tip: $ or %.',
    TipValue: 'Please select the amount of the tip.',
    AmountMustBeInteger: 'Percentage amount must not have decimals',
    AmountMustBeGreaterThanZero: 'Amount must be greater than zero.',
    AmountMetaphorMustBeLessThanNinetyNine: 'Amount before decimal point can not be greater than 99.',
    AmountMantissaMustBeEqualOrLessThanThreeDigits: 'Amount after decimal point can only be 3 or less digits.',
    RefundQuantityExceedsOriginal: 'Cannot exceed item\'s original quantity.',
    RefundTipExceedsOriginal: 'Cannot exceed transaction\'s original tip.',
    UnSelectedCategory: 'Please select the category you want to merge',
    ItemDiscountExceedsTotal: 'Discount amount cannot exceed the item\'s total value.',
    ExpressItemNameRequired: 'Item name is required.',
    ExpressItemPriceRequired: 'Item price is required.',
    ExpressItemTaxRateRequired: 'Tax rate is required.',
    IntegratedTokenMustNotHaveSpaces: 'Integrated token must not have spaces.',
    DuplicateDiscount: 'Discount name is already taken. Enter a valid discount name.',
    DuplicateModifierSet: 'Modifier set name is already taken. Enter a valid modifier set name.',
    DuplicateIntegratedToken: 'Integrated token is already taken. Enter a valid integrated token.',
    CardInvalid: 'Card number invalid.',
    CardAlreadyExists: 'Card already exists.',
    CreditCardHolderNameRequired: 'Cardholder name is required',
    invalidBillingStreetNumber: 'Billing street number is invalid',
    InvalidCardNumber: '54 - Expired Card',
    RewardDollarAmountLimit: 'Reward Amount can not be greater than $1,000,000',
    RewardPercentAmountLimit: 'Reward Amount can not be greater than 100%',
    MinimumLargerThanMaximum: 'Maximum amount should be greater than minimum amount.',
    DuplicatedPaymentAmounts: 'Payment amounts cannot be duplicated.',
    AmountBetweenMinMax: 'Enter an amount between {{minimum}} and {{maximum}}',
    MinWithoutMax: 'Enter an amount that is {{minimum}} or above.',
    MaxAmountReached: 'Maximum amount {{limit}}.',
    AcceptTermsAndPrivacyPolicy: 'Please accept the terms and conditions and privacy policy.',
    InvalidPercent: 'Invalid percent',
    SignupFullNameRequired: 'Enter full name.',
    SignupInvalidEmail: 'Enter a valid email address.',
    SignupInvalidMobile: 'Enter a valid 10-digit U.S. mobile number.',
    SignupInvalidPassword: 'Passwords must have at least 8 characters and contain one uppercase, one lowercase letter, and one number or symbol (~!@#$%^&*()-=+).',
    SignupInvalidConfirmPassword: 'Re-enter password. New password and confirm password do not match.',
    SignupAcceptTerms: 'Please accept the Terms of Use.',
    SignupAcceptPrivacyPolicy: 'Please accept the Privacy Policy.'

  },
  Errors: {
    InternalProcessingError: 'Sorry, internal processing error.',
    VoidRefundError: 'Void / Refund Declined',
    CompletePreAuthError: 'Complete Pre Auth Declined',
    Activation: {
      Hardware: {
        MaxDevices: 'Please select no more than {{count}} devices.'
      },
      Submitted: 'You have already started an application.',
      MerchantApplication: 'Unable to retrieve merchant application PDF.',
      SubmitFailed: {
        Title: 'Unable to Proceed',
        Message: 'Please confirm your information is accurate and try again. If the problem persists, please contact cssales@north.com'
      },
      PublishFailed: {
        Title: 'Unable to Submit',
        Message: 'There was an error processing your application. Go back and edit your information, or exit the application and contact us at 866.611.4225',
        ExitAction: 'Exit Application',
        CancelAction: 'Back to Application'
      }
    },
    Avs: {
      Heading: 'Address Verification Failed',
      Message: 'The street number and/or zip code entered does not match the billing address of the cardholder. Would you like to continue with this transaction?',
      TryAnother: 'Please try a different method of payment.',
    },
    AvsPayInvoiceFailure: 'Your payment could not be processed. Please check the card number, expiration date, and that your billing address matches what\'s\ on file and try again.',
    PartialPayment: {
      Heading: 'Notice',
      Message: 'Unable to process transaction. Please make sure the card has sufficient funds or use a different method of payment.'
    },
    TwoFactor: {
      RateLimit: 'Too many attempts. You can try again in 10 min.',
      VerifyFailed: 'Sorry! Unable to verify your mobile phone.'
    },
    Funding: {
      BakingErrorTitle: 'Unable to update banking information.',
      BakingErrorDescription: 'Please make sure your information is accurate and try again.',
      RateLimit: 'Too many attempts. You can try again in 24 hours.',
    },
    SignupError: 'Email address and/or mobile number already exist.',
    InvoiceApiError: 'Unable to process payment. Please try again or contact the business if you believe there may be a system issue.',
    InvalidCSRF: 'We have updated our application. Please refresh your browser.',
    InvalidBusinessPercent: 'Total percentages must equal 100%',
  },
  NoData: {
    EmployeesToday: {
      Header: 'No employees.',
      Description: 'Add employees with diverse roles to accept payments on your behalf.',
      Link: 'Go to Employees'
    },
    StockToday: {
      Header: 'No stock.',
      Description: 'Enable stock tracking to manage your inventory and set up stock alerts.',
      Link: 'Go to Inventory'
    },
    ItemsToday: {
      Header: 'No items.',
      Description: 'Add products or services to your inventory for faster checkouts and valuable reporting.',
      Link: 'Go to Inventory'
    },
    BatchDeposits: 'Deposits will display after your transactions have settled. There may be a delay in viewing deposits if you have open batches or transactions are under review.',
    NoDeposits: 'No Deposits'
  },
  DAVOIntegration: {
    Title: 'Automate your sales tax with DAVO',
    LinkText: 'Get First Month Filing Free',
    Disclaimer: '*Only available with a paid plan.'
  },
  TapToPay: {
    Text: 'Accept contactless payments with only an iPhone',
  },
  ReportsInfo: {
    GrossSales: 'This is the amount that you sold. Includes your transactions.',
    NetSales: 'This is the amount that you earn as a business. Includes your transactions with discounts, refunds, and voids.',
    TotalCollected: 'This is the amount that you collect. Includes your transactions with discounts, refunds, voids, taxes, and tips.'
  },
  HistoricHardwareMode: {
    SwitchToTerminalView: 'Switch to Terminal View',
    Description: 'View activity for your traditional terminals. Some standard features will not be available.',
    TerminalView: 'Terminal View',
    NoticeInfo: 'You\'re viewing activity for your traditional terminals. Some standard features will not be available.',
    ExitTerminalView: 'Exit Terminal View',
    DashboardBanner: {
      Title: 'You\'re in Terminal View.',
      Description1: 'You\'re viewing activity for your traditional terminals.',
      Description2: 'To access your full feature set, exit Terminal View.'
    }
  },
  TodayFeedback: {
    Heading: 'Got feedback?',
    Text: 'We\'\d love to hear it! Tell us what you think about {{labelText}} with our brief survey.',
    LinkText: 'Take survey'
  },
  'ReputationProject': {
    'Title': 'Reputation Management',
    'GetStarted': 'Get started',
    'LearnMore': 'Learn more',
    Trends: {
      MostCommonWords: 'Most Common Words',
      CompetitorPerformance: {
        Heading: 'Competitor Performance',
        Reviews: 'reviews',
        Add: 'Add'
      },
      MostRecentReviews: {
        Heading: 'Most Recent'
      },
      NoDataMessage: 'No data available'
    },
    CompetitorPerformance: {
      AddCompetitor: 'Add a competitor',
      MilesAway: 'miles away',
      NoLongerAvailable: 'Competitor account no longer available.',
      Remove: 'Remove Competitor',
      Competitor: 'Competitor',
      Rating: 'Rating',
      Comments: 'Comments',
      ReviewDate: 'Review Date',
      NoData: 'No Competitor Reviews',
      SearchHintText: 'Search businesses on Google',
      RemoveCompetitorTitle: 'Remove competitor.',
      RemoveCompetitorConfirm: 'Yes, remove',
      RemoveCompetitorCancel: 'No, cancel',
      RemoveCompetitorText: 'Are you sure you want to remove {{business}}? You will no longer see ratings and reviews for this business.'
    },
    Settings: {
      Title: 'Connect account',
      Subtitle: 'Sign in to your Google account to connect your business and view and respond to customer reviews. Set up your Google Business Profile ',
      SetUpAlready: ' if you don’t have one already.',
      Disconnect: 'Disconnect',
      ReviewsTitle: 'Google Reviews',
      Connected: 'Connected',
      NotConnected: 'Not Connected',
      MyCompetitors: {
        Header: 'My Competitors',
        Text: 'Add up to three similar businesses in your area to see how you fare against the competition.'
      },
      EditLocations: 'Edit Locations',
      SelectLocation: 'Select a location.',
      LocationsErrorTitle: 'Error',
      LocationsErrorBody: 'No Google Business location was found. Please check your Google Business Profile.',
      UnnamedBusiness: 'Unnamed business',
      NoAddressInformation: 'No address information',
      EmailPhoneForm: {
        Title: 'Add or delete email addresses for notification.',
        EmailHint: 'Enter up to 5 emails',
        PhoneHint: 'Enter a mobile phone number',
      },
      Notifications: {
        Header: 'Notifications',
        Text: 'Get a daily email summary when you have new reviews for up to five (5) recipients.',
        AlertMe: 'Alert me of',
        SendAlerts: 'Send alerts to',
        Email: 'Email',
        SMS: 'SMS',
        AddRecipient: 'Modify email recipients'
      }
    },
    CustomerReviews: {
      Source: 'Source',
      Rating: 'Rating',
      Customer: 'Customer',
      Comments: 'Comments',
      ReviewDate: 'Review Date',
      Responded: 'Responded',
      NoData: 'No Customer Reviews',
      Details: {
        At: 'at',
        Review: '{{platform}} Review',
        Said: 'said',
        YouSaid: 'You said',
        EditResponse: 'Edit Response',
        RespondTo: 'Respond to',
        Clear: 'Clear',
        ResponseHint: 'Enter a custom response or select a quick reply below.',
        QuickReplies: 'Quick Replies',
        NoComment: 'This user did not leave a comment.'
      }
    }
  },
  Legal: {
    HeaderA: 'Review Terms.',
    PaCopy1: 'In order to continue using our payment processing services, you are required to review and agree to the terms and conditions.',
    HeaderRequired: 'Action Required',
    CopyRequired: 'To continue using {{labelText}}, the Business Principal/owner of this account must login and agree to the updated terms. Please contact your administrator.',
    UserAgree: 'I have reviewed and agree to the User Agreement.',
    LinkText: 'Agree',
    FDText: 'To view updated terms and conditions, click ',
    FDLink: ' here',
    VerificationAttempts: 'Verification attempts {{attempts}} of 3.',
    Or: ' or '
  },
  Supplies: {
    pa: {
      AgreeToDebit: 'I agree to let Payanywhere/North American Bancard debit the total fee from the bank account i have on file.'
    }
  },
  MBPRegistration: {
    AlreadyRegisteredHeader: 'This account has already been registered.',
    AlreadyRegisteredContent: 'Please go back to the login screen and use your username and password to login.',
    UnableToRegisterHeader: 'Unable to register.',
    UnableToRegisterContent: 'For assistance, please contact Customer Service at 877.464.4218 or support@paymentshub.com.',
    SuccessContent: 'Your account has been registered. Return to the login screen to access your account.',
    PartialMatchHeader: 'Sorry, the information you entered is incorrect.',
    PartialMatchContent: 'Please try again.',
    EmailSentHeader: 'Please check your email.',
    EmailSentContent: 'To continue with the registration, please follow the steps on the email we have sent you.',
    MidUsedHeader: 'This MID has already been registered.',
    MidUsedContent: 'Please try and login.',
    CanRegisterMid: [
      'This account number does not exist in our system',
      'This account number is already registered to another user',
      'Something went wrong while trying to verify this account number'
    ],
    Helper: [
      'Check your most recent statement for your Merchant ID/Account Number.',
      'Enter the first name of the principal owner associated with the account.',
      'Enter the last name of the principal owner associated with the account.',
      'This email address will be your new Payments Hub Login'
    ]
  },
  MBPMigration: {
    FormHeader: 'MyBizPerks User Registration.',
    FormSubHeader: 'Use your MBP username and password to activate your Payments Hub account. If you don\'t know your MBP information, please click ',
    SuccessContent: 'Your account has been registered and all MyBizPerks MIDs have been migrated to your account.',
    PartialSuccessHeader: 'Success. ',
    PartialSuccessContent: 'Your account has been registered but some MIDs were already migrated from MyBizPerks to this account: ',
    AlreadyMigratedHeader: 'This account has already registered and migrated all MIDs.',
    AlreadyMigratedContent: 'The MIDs from this account on MyBizPerks have already been migrated to this account.',
    FailedMigrationHeader: 'Sorry, this account failed to migrate.',
    FailedMigrationContent: 'The MIDs from this MyBizPerks account failed to migrate. For assistance, please contact Customer Service at 877.464.4218 or support@paymentshub.com.',
    NoMbpUser: 'Unauthorized',
    NoMbpUserMessage: 'We did not recognize the MyBizPerks credentials entered. Please create a Payments Hub username and password using the form below.',
    FieldLabels: {
      MbpUsername: {
        Label: 'MyBizPerks Username',
        Hint: 'Your MyBizPerks Username'
      },
      MbpPassword: {
        Label: 'MyBizPerks Password',
        Hint: 'Your MyBizPerks Password'
      },
      EmailAddress: {
        Hint: 'Email Address (this is your username)'
      },
      CreatePassword: {
        Label: 'Create Payments Hub Password',
        Hint: 'Create a new payments hub password'
      },
      VerifyCreatePassword: {
        Label: 'Verify Payments Hub Password',
        Hint: 'Verify the new payments hub password'
      }
    },
    Helper: {
      Option1: 'This is the username for your current MyBizPerks account',
      Option2: 'This is the password for your current MyBizPerks account',
      Option3: 'This email address will be your new Payments Hub Login'
    },
    UserAlreadyAttachedToMIDs: 'The requested MIDs are already attached to this user.'
  },
  RegisterMBPUserForm: {
    DescriptionPartOne: 'You should register for an account only if you applied using a paper application or have an account created prior to April 2018. ',
    DescriptionPartTwo: 'If you\'re not certain if you need to register, try resetting your password first ',
    Here: 'here',
    Fields: {
      AccountNumber: 'Merchant ID/Account Number',
      FirstName: 'First Name',
      LastName: 'Last Name',
      EmailAddressHintText: 'Email Address (this is your username)',
      NewPasswordHintText: 'Create Password'
    },
  },
  RegisterMbpUserAuthenticationForm: {
    Header: {
      Welcome: 'Welcome back.',
      Invalid: 'Invalid password.'
    },
    Description: 'This email address is already associated with a Payments Hub account. Please log in to link your new registration with your existing account. If you are unsure of your password, you can reset it below.',
    Or: ' OR ',
    ResetPassword: 'Reset Password'
  },
  AddAccountDialog: {
    DefaultError: 'We were unable to link the following merchant account(s) because of an unknown error: ',
    Helper: {
      Option1: 'The MID of the account you want to add.'
    },
    Title: 'Add an account',
    ConfirmText: 'Add Accounts',
    DialogSubtitle: 'Connect one or more additional merchant accounts to your existing login credentials. Once added, you can quickly switch between them from the Account Menu.',
    MidSuccessDialogWithMids: 'account(s) to be added',
    MidSuccessDialogWithoutMids: 'Your account(s) will show up here',
    MID: 'Merchant Account Number (MID)',
    Or: 'OR',
    GetLabelHeader: 'Get the {{labelText}} Merchant ID Template',
    GetLabelBody: 'Use our custom template to quickly create and import your merchant ids into your account.',
    Download: 'Download',
    UploadMerchantHeader: 'Upload your Merchant IDs',
    UploadMerchantBody: 'Drag your completed template into the space below, or click "Browse" to locate it. Must be a CSV file (.csv)',
    FileUpload: '<0>Drag files here or </0><1>browse</1>',
    SavedAccounts: 'Saved accounts ',
    ErrorsMap: {
      MissingMids: 'Please enter at least 1 merchant account id.',
      MissingUserData: 'Your session has timed out.',
      MidPrincipalsDoNotMatch: 'We are unable to link the following merchant account(s) because Owner Name, Owner SSN or Federal Tax ID do not match: ',
      MidAlreadyAttached: 'We are unable to link the following merchant account(s) because they are already attached to this user: ',
      MidDoesNotExist: 'We were unable to link the following merchant account(s) because they do not exist: '
    }
  },
  AccountSection: {
    Credentials: {
      ph: 'will change your login credentials for Payments Hub.',
      pa: 'will change your login credentials for both the Payanywhere app and Payanywhere Inside.',
      ps: 'will change your login credentials for both the PhoneSwipe app and PhoneSwipe Inside.'
    },
    TwoFactor: {
      HeaderText: 'Two-factor authentication is a security process used to help keep your account safe. When you make edits to your account information, you will be prompted for a unique code sent by SMS. Message and data rates may apply.',
      Description: 'For an added layer of security, require a unique code sent by SMS each time you log in, unless you select the “Remember this device” option. Message and data rates may apply.',
    },
    Giact: {
      AccountType: 'Unable to validate your banking information for {{accountType}}. You may upload a voided check for further validation. Upload as a jpg or png. Max file size 5 mb.',
      MissingVerification: 'Missing bank verification image'
    }
  },
  User: {
    RestrictedMbpAccess: 'You are currently registered for the reporting features of Payments Hub.'
  },
  LoadingReport: {
    Option1: 'Please wait.',
    Option2: 'Please wait..',
    Option3: 'Please wait...',
    Option4: 'Generating your report.',
    Option5: 'Generating your report..',
    Option6: 'Generating your report...'
  },
  TransactionInfo: {
    Title: 'Where are today\'s transactions?',
    Details: 'Transactions will display after you have batched. Click Open Batch to view a list of live credit card authorizations that have not yet settled. Does not include refunds, cash, and virtual terminal transactions.'
  },
  HomebaseTitle: {
    Option1: 'Try Homebase free.',
    Option2: 'You\'re\ enrolled in a 30-day free trial of Homebase.',
    Option3: 'Your Homebase trial is ending!'
  },
  ApiAccess: {
    Title: 'API Access',
    Credentials: 'API Credentials',
    JSKDomain: 'JS SDK Domain',
    MidRemoved: 'MID access removed',
    JSKDomainInfo: `Enter the domain you will be processing payments from.
    Please note that to use wildcard domains, you should not use the “*” character.
    If you would like to enable everything under mydomain.com, set the value to mydomain.com, and it will automatically include test.mydomain.com; mydomain.com; etc. Only one domain can be configured at a time.`,
    ApiAccessInfo: 'Access your production credentials and configuration options for technical products.',
    JSKInfo: 'The iFrame JavaScript SDK (JS SDK) is an ecommerce product that allows users to keep sensitive card data off your server environment.',
    InvoicingAPIInfo: 'The Invoicing API allows users to generate a link to send to a customer so they can make a payment.',
    SemiIntegratedAPI: 'Payanywhere Semi-Integrated API',
    SemiIntegratedAPIInfo: 'This product allows users to combine a web-based app with Payanywhere supported BBPOS card readers and PAX smart terminals to accept card-present payments.',
    WebHook: 'Enable Payanywhere Webhook',
    WebHookInfo: 'Configure a webhook so your server can be notified when a payment is approved or declined. Add the full URL, to which Payanywhere will post the payment result. This must be on https//: and your site must have a certification from a CA (not self-signed).',
    VerificationNotice: 'A verification code has been sent to the mobile number ending in {{number}}. Enter it below to reset your password.',
    ShowPassword: 'Show API Password',
    Important: 'Important: ',
    AreYouSure: 'Are you sure you want to reset the API password?',
    PasswordReset: 'Password Reset',
    PasswordResetContent: 'Resetting the API Password will break any existing integrations that use the API Password.',
    WebhookTitle: 'Webhook',
    AllowIncrementalAuthorization: 'Allow Incremental Authorization',
    AuthoInfo: 'Incremental Authorization allows you to adjust the authorized amount of a transaction one or more times before it is captured.',
    HoverRemove: 'Hover to Remove a MID',
    RemoveWarning: `Removing a MID will revoke credentials for that  account.
    To generate new credentials, log into the removed account and click 'Get Credentials' from the Integrations screen.`,
    NotActive: 'Use the Get Credentials button to complete your configuration.',
    NotActiveWebhook: 'Toggle the switch to complete your webhook configuration',
    ViewDocumentation: 'View documentation'
  },
  GetCredentials: {
    Title: 'Get Credentials',
    Button: 'Get credentials',
    Message: 'Would you like to get credentials for all of your MIDs, or restrict to the current MID: {{currentMid}}?',
    CurrentMidButton: 'Get for Current MID Only',
    AllMidsButton: 'Get for All MIDs'
  },
  ApiCredential: {
    Important: 'Important: ',
    ResetAPIPassword: 'Reset API Password',
    ApiPasswordContent: `Copy the API Password and save it in a safe place. The API Password will only be displayed the first time this screen is displayed.
    If you lose or forget your API Password, click the “Reset API Password” button and follow the instructions to authenticate your identity.`,
    APIInfo: `The API Password is the same for every product and can only be viewed once.
    If you lost or forgot your password, click Reset API Password. Doing this will reset the API Password for all products.`
  },
  JsSdkForm: {
    Title: 'JS SDK Domain',
    Important: 'Important: ',
    Message: 'Are you sure you want to edit the domain URL? This will break any existing integration with the current design.',
    Label: 'JS SDK Domain URL',
    EnterJsSdk: 'Enter the JS SDK Domain URL',
  },
  WebHookForm: {
    Title: 'Payanywhere Webhook',
    Important: 'Important: ',
    Message: 'Are you sure you want to edit the domain URL? This will break any existing integration with the current design.',
    Label: 'Webhook URL',
    EnterDomain: 'Enter the Webhook Domain URL',
  },
  HomebaseContent: {
    Option1: 'We\'re\ pleased to offer you a free 30-day trial of Homebase Plus.',
    Option2: 'Homebase is employee management software with scheduling and time clock functionality that integrates directly into Payanywhere.',
    Option3: 'After the trial expires, you will be automatically placed on the free Basic plan. Visit the Homebase site to manage your subscription.',
    Option4: 'To continue using Homebase, manage your subscription from the Integrations page.'
  },
  IntegrationsPage: {
    DialogHeader: 'Integration Details',
    SubText: 'Integrations are only available with a paid plan.',
    Feedback: 'Is there a third party service or integration you\'d like to see? Let us know at ',
    FeaturedIntegrations: 'Featured Integrations',
    FeaturedIntegration: 'Featured Integration',
    AllIntegrations: 'All Integrations',
    Tags: {
      BusinessListing: 'Business Listing',
      Accounting: 'Accounting',
      Tax: 'Tax',
      GiftCard: 'Gift Card',
      Payroll: 'Payroll',
      Scheduling: 'Scheduling',
      Staff: 'Staff',
      Reviews: 'Reviews',
      Partnership: 'Partnership',
      Marketing: 'Marketing',
      Reputation: 'Reputation',
      BusinessFunding: 'Business Funding',
      Insurance: 'Insurance'
    },
    planListDialog: {
      title: 'Upgrade your Payments Hub plan to get full access to Integrations.',
      subtitle: 'Integrations are only available with a paid plan.'
    },
    Homebase: {
      SubText: 'Employee scheduling, timesheets, and payroll for your employees.',
      UserInfo: ' page of {{labelText}}. They will automatically sync to Homebase.',
      TimeClockInfo: 'Your employees can access the Homebase time clock within the {{labelText}}.'
    },
    IntuitQuickBooksOnline: {
      SubText: 'Sync your transactions to your Intuit QuickBooks Online account.'
    },
    GiftCardInnerDetails: {
      Detail1: 'Subscribe to the Factor4 Gift Card Program.',
      Detail2: 'Once subscribed, Factor4 will reach out within 1-3 business days to complete the setup process. If you prefer, you can contact Factor4 directly at <1>sales@factor4gift.com</1> or {{contactPhone}}.',
      Detail3: 'Once you have gift cards, launch the Payanywhere app to start selling and accepting gift cards!'
    },
    GiftCardSubscriptionText: 'Unable to load your account. Please try again later or contact Customer Care at',
    SubscriptionPending: 'Pending subscription confirmation',
    IntegratedAccessKeys: {
      Title: 'Integrated Access keys',
      SubText: 'Integrate with our API by setting up access tokens'
    },
    TransactionsWebHook: {
      Title: 'Transactions Web Hook',
      SubText: 'Integrate a web hook for your transactions'
    },
    Chargezoom: {
      Card: {
        Title: 'Chargezoom',
        Text: 'Connect your payments to QuickBooks in seconds with Chargezoom.'
      },
      Details: {
        Connect: 'Connect',
        Connected: 'Connected',
        DialogTitle: 'Connect to Chargezoom?',
        DialogBody: 'This will allow Chargezoom to create a Chargezoom account for you and access your transactions, invoices, batches, and inventory information.',
        Frequency: 'Frequency',
        Data: 'Data',
        Includes: 'Includes',
        Activate: 'Activate Now',
        VisitChargezoom: 'Visit Chargezoom Website',
        VisitChargezoomPortal: 'Visit Chargezoom Portal',
        DetailsTitle: 'Details',
        Line1: 'Choose your plan and click Activate Now.',
        Line2: 'You will receive an email directly from Chargezoom to complete account activation.',
        Line3: 'Once activated, you\'ll connect to your Intuit Quickbooks account.'
      },
      Disconnect: {
        Title: 'Are you sure you want to disconnect?',
        Body: 'You will no longer have access to the Chargezoom Portal and your transactions will no longer be synced.'
      },
      BasicPlan: {
        Name: 'Basic Sync',
        Price: '$19.00/month',
        Frequency: 'Daily Sync',
        Data: 'Batch-Level',
        Includes: {
          CreditCardSales: 'Credit Card Sales',
          Credits: 'Credits',
          Taxes: 'Taxes',
          Tips: 'Tips',
          Refunds: 'Refunds'
        },
        IncludesFooter: '*per batch'
      },
      AdvancedPlan: {
        Name: 'Advanced Sync',
        Price: '$39.00/month',
        Frequency: '15-min Sync',
        Data: 'Transaction-Level',
        Includes: {
          Customers: 'Customers',
          CreditCardSales: 'Credit Card Sales',
          CashSales: 'Cash Sales',
          Credits: 'Credits',
          Taxes: 'Taxes',
          Tips: 'Tips',
          Refunds: 'Refunds'
        },
        IncludesFooter: '*per transaction'
      },
      errorMessage: 'Something went wrong while disconnecting, please try again'
    },
    Davo: {
      Card: {
        Text: 'DAVO sets aside sales tax daily and pays and files it, on time and in full. First monthly filing is free to try.'
      }
    },
    Yelp: {
      Card: {
        Text: 'Get found by new customers who are ready to buy by claiming your Yelp page.'
      },
      Dialog: {
        Description: 'Claim your Yelp listing and unlock paid features to attract more customers',
        ClickedDescription: 'Get found by new customers who are ready to buy by claiming your Yelp page.',
        Links: {
          ClaimYourBiz: 'Claim your free business listing',
          VisitYelp: 'Visit Yelp',
          TermsAndConditions: 'View Terms and Conditions',
          PrivacyPolicy: 'View Privacy Policy'
        },
        Features: {
          First: {
            Title: 'Why should business owners claim their Yelp page?',
            Description: 'Your business information is the heart of your Yelp Business Page, from your address to your specialties. Updating your page will make your business more attractive to customers.'
          },
          Second: {
            Title: 'Get found by new customers who are ready to buy by claiming your page.',
            Description: 'An average of 2.7M people visit Yelp each day to find home services, restaurants and other businesses. 4 out of 5 users hire or buy from a business they found on Yelp and Businesses with photos, website, phone number, and business hours listed on their Yelp Page averaged 7.6x more page views per month than businesses without that content.'
          },
          Third: {
            Title: 'Once verified, your business will be able to:',
            List: {
              First: {
                Bold: 'Receive inquiries',
                Regular: 'from potential new customers as they search.'
              },
              Second: {
                Bold: 'Update hours,',
                Regular: 'contact information, services and specialties.'
              },
              Third: {
                Bold: 'Track customer activity',
                Regular: 'and access competitive insights',
                SecondaryRegular:'Share photos with captions of your work.'
              },
              Fourth: {
                Bold: 'Respond to reviews,',
                Regular: 'and more'
              }
            }
          }
        }
      },
      TileText: 'Claim your free business listing'
    },
    Nav: {
      Card: {
        Text: 'Know what you can qualify for before you apply, from the largest network of small business financing solutions.'
      },
      IntegrationTile: {
        Text: 'Know Your Options; Get Funded - with Nav.',
        Action: 'Check it out'
      },
      Details: {
        Description: 'Know what you can qualify for before you apply, from the largest network of small business financing solutions.',
        Options: 'See your options on Nav',
        DetailedDescription: {
          TopTitle: 'Trusted by Over 2 Million Small Businesses',
          Title: 'Apply with Confidence: ',
          Text: 'Only the financing options that matter for your small business, without all the searching. Know where your business financial health stands and see what you can qualify for before you apply. Nav has the largest network of financial solutions including 160+ options from the most trusted names in small business lending and credit cards. No more wasted time and frustrating rejections.',
          List: {
            Title: 'Why Nav?',
            First: {
              Title: 'Trusted: ',
              Text: 'Nav has connected businesses to over 110,000,000 in funding and 500,000 credit cards in 2023 alone. We are the leading financial health platform for small businesses.'
            },
            Second: {
              Title: 'Transparent: ',
              Text: 'The average SMB spends 26 hours applying for credit. Save time by knowing what you can qualify for before you apply.'
            },
            Third: {
              Title: 'Extensive: ',
              Text: 'With over 160+ financing options and 220+ business solutions, you never have to go anywhere else to find the right solutions for your business.'
            }
          }
        },
        Actions: {
          VisitNavWebsite: 'Visit Nav Website',
          Connect: 'Connect',
          VisitNavPortal: 'Visit Nav Portal',
          ViewTermsAndConditions: 'View Terms & Conditions',
          ViewPrivacyPolicy: 'View Privacy Policy'
        }
      }
    },
    Next: {
      IntegrationTile: {
        Text: 'Business insurance from NEXT',
        Action: 'Learn More'
      },
      Card: {
        Text: 'Protect your business with simple, affordable business insurance from a company 100% dedicated to helping their customers thrive.'
      },
      Dialog: {
        Description: 'Next offers simple, affordable business insurance tailored for 1,300+ small business professions. Get zero hassle and big savings.',
        Subtext: 'Get a quote from NEXT below.',
        Links: {
          GetQuote: 'Get a Quote',
          VisitNextPortal: 'Visit Next Insurance Portal',
          VisitNextWebsite: 'Visit Next Insurance Website',
          TermsAndConditions: 'View Terms & Conditions',
          PrivacyPolicy: 'View Privacy Policy'
        },
        Features: {
          First: {
            Title: 'Business insurance matters',
            Description: 'Protecting your business and employees is critical. Business insurance provides a financial safety net in case your business is held responsible for accidents or mistakes. NEXT makes it easy for you to get the coverage you need to stay safe.'
          },
          Second: {
            Title: 'Get the coverage you need ',
            Description: 'You can get a quote and buy coverage from NEXT online in as little as 10 minutes. They offer general liability, workers’ compensation, commercial property, and other more important coverages for small businesses. Plus, your insurance can start immediately.'
          },
          Third: {
            Title: 'Why Next?',
            List: {
              First: {
                Bold: '100% dedicated to small business: ',
                Regular: 'Get affordable coverage built for companies like yours.'
              },
              Second: {
                Bold: 'The NEXT mobile app: ',
                Regular: 'Manage your coverage 24/7 using the app',
                SecondaryRegular: 'or your online customer account.'
              },
              Third: {
                Bold: 'Phenomenal service: ',
                Regular: 'Next is dedicated to helping customers thrive and',
                SecondaryRegular: 'has U.S.-based advisors when you need help.'
              },
              Fourth: {
                Bold: 'Trusted by 500,000 customers: ',
                Regular: 'Small businesses give Next 4.75 out of 5 stars in ratings.'
              }
            }
          }
        },
        ConnectButton: 'Get a Quote'
      }
    }
  },
  ImportItems: {
    UploadMessage: '',
    MaxFileSize: '5MB',
    ItemLimit: 'Recommended 3000 item limit.'
  },
  PaymentshubTier: {
    PremiumPrice: '$14.95/mo.',
    OptOutOfPremiumContent: 'By opting out, you are downgrading to the Free plan and will no longer receive access to invoicing, recurring invoicing, free supplies and shipping, and terminal warranty.',
    UpgradeToPremiumContent: 'Do you want to upgrade to Premium for {{premiumPrice}}?',
    AccessInvoiceContentOwner: 'This feature is exclusive to Premium Plan members. Upgrade now to send your first invoice.',
    AccessInvoiceContentNonOwner: 'This feature is exclusive to the Premium Plan. Please contact the Owner to upgrade and send your first invoice.',
    AccessSuppliesContent: 'This feature is exclusive to Premium Plan members. Upgrade now to get free supplies.',
    AccessReputationManagementContent: 'This feature is exclusive to Premium Plan members',
    AccessLoyaltyProgramContent: 'This feature is exclusive to Premium Plan members',
    NotificationTitle: 'You\'re\ enrolled in a 60-day free trial of Premium Plan.',
    NotificationEpxContent: 'Premium includes advanced features that can help power your business, including invoicing with recurring options, free supplies, and a warranty on payment terminals.',
    NotificationGlbContent: 'Premium includes advanced features that can help power your business, including free supplies and a warranty on payment terminals.',
    NotificationBaseContent: 'Once your free trial ends, you will be automatically enrolled into the Premium plan. If you would no longer like to receive the benefits offered through Premium Plan, you can opt out under Account.',
    SuccessfullyEnrolled: 'You have successfully upgraded your plan to Premium.',
    SuccessfullyOptedOut: 'You have successfully opted out of Premium and downgraded to the Free plan.'
  },
  CardPresent: {
    Activity: {
      ConnectionConnecting: 'Connecting...',
      MissingDriver: 'Driver not detected.',
      MissingDevice: 'Card reader not connected.',
      Connecting: 'Card reader is connecting.',
      Connected: 'Card reader is connected.',
      Disconnecting: 'Card reader is disconnecting.',
      Disconnected: 'Card reader is disconnected.',
      Unknown: 'Searching for card readers.',
      ReadyToUse: 'Ready to Use',
      UseCard: 'Insert, tap, or swipe card.',
      IsProcessing: 'Reading card data',
      NeedsInput: 'We need some info',
      Processed: 'Card successfully read',
      TransactionComplete: 'Transaction complete',
      LocalAction: 'Waiting on Response',
      NeedsUpgrade: 'The current version cannot be auto upgraded.'
    },
    ActivityDetails: {
      ConnectionConnecting: 'Attempting to securely connect to card reader',
      MissingDriver: 'Open the Payanywhere card reader app click “Download App” to use the black version of the Payanywhere 2-in-1 or 3-in-1 reader with the Virtual Terminal. The white version of the Payayanywhere 3-in-1 reader (D135) is not compatible.',
      MissingDevice: 'Be sure that your card reader is plugged in or turned on.',
      Connecting: 'Please hold on a moment while we connect to this reader.',
      Connected: 'Select "Start Reader" to accept a payment.',
      Disconnecting: 'This card reader is being disconnecting.',
      Disconnected: 'Be sure that your card reader is plugged in or turned on.',
      Unknown: 'Be sure that your card reader is plugged in or turned on.',
      ReadyToUse: 'Click "Start Reader" to start a transaction.',
      Processed: 'Please wait a moment while we complete this transaction.',
      LocalAction: 'We\'re waiting on a response from the card reader.',
      NeedsUpgrade: 'Please delete your current installed version and click the download button to get the latest version.'
    },
    ActivityActions: {
      Download: 'Download App',
      Connect: '(Driver Already Running?)',
      UpgradedConnect: '(Driver Already Updated?)',
      StartReader: 'Start Reader'
    }
  },
  PaymentSeetingsTitle: 'Payment Settings.',
  PaymentSeetingsSubTitle: 'Set account-level preferences such as default tax amount, AVS mismatch, etc. These settings apply to payments on Payanywhere, Virtual Terminal, and Invoice transactions.',
  ClosingManual: 'You have opted to manually close transactions for settlement each day. Your funding may be impacted depending on the time you close your transactions. To switch to Auto Close, contact Customer Service at 877.387.5640.',
  ClosingAuto: 'Your account is set to automatically send transactions for settlement each day at {{autoCaptureTime}} ET. To edit your closing time or switch to Manual Close contact Customer Service at 877.387.5640.',
  VirtualTerminal: {
    IsMobileTitle: 'Virtual Terminal not available',
    IsMobileContent: 'The Virtual Terminal is not supported on mobile devices. To accept a payment using the Virtual Terminal, log in on your desktop. Or, process payments on your mobile device with Payanywhere app.',
    CashDiscountInfo: 'The cash discounting amount will be automatically applied on the transaction. For example, if you enter in $100, with a 4% cash discounting amount, your customer will be charged for $104.'
  },
  RefreshTokenDialog: {
    Title: 'Your session is about to expire.',
    Content: 'To maintain security, your session is about to expire. Would you like to remain logged in?',
    TimeRemaining: 'Seconds Remaining'
  },
  RefreshInvoiceToken: {
    Title: 'Your session has expired.',
    Content: 'Click the button below to send a new invoice link to your email or mobile number.',
    ButtonLabel: 'Send new link'
  },
  RefreshInvoiceLinkSent: {
    Title: 'Link sent!',
    Content: 'Watch your inbox. Your new payment link is on the way.'
  },
  BizSettings: {
    FormTitle: 'Business profile.',
    FormSubtitle: 'You must verify your identity before editing your business profile.',
    SaveSettingsButton: 'Save settings'
  },
  AppSettings: {
    FormTitle: 'Payment Settings.',
    FormSubtitle: 'Set account-level preferences such as default tax amount, AVS mismatch, etc. These settings apply to payments on Payanywhere, Virtual Terminal, and Invoice transactions.',
    Closing: {
      Manual: 'You will manually close transactions for settlement each day. Your funding may be impacted depending on the time you close your transactions. Set an Auto Batch time below if you would like your transactions automatically closed for you.',
      Auto: 'Host close is the default setting for Payanywhere. This means that Payanywhere will automatically close your transactions based on your funding selection - Next Day Funding at 8:30 pm ET; Same Day Funding at 10:30 am ET.\n To switch to Manual Close, contact Customer Service at 877.387.5640.',
      ManualPrintReport: 'At 3 am local time, this device will automatically print out the transactions you closed. Transactions closed on other devices are not included. Visit Payments Hub for more detailed reporting.',
      AutoPrintReport: 'At 3 am local time, your device(s) will automatically print the most recent closing report. By default, this report includes all closed transactions across all devices on this account. This feature is available to Payanywhere Smart Solutions only. Go to the Payanywhere app →  Settings → Closing Settings for additional customization of this feature.',
      ManualCaptureTime: 'Enter a time that your transactions will automatically close in the event you choose not to manually close.'
    },
    CashDiscountDescription: 'Cash discount helps you reduce processing fees. This does not apply to split payment transactions. Be sure to inform your customers you have cash versus credit prices. Consult with your sales partner if you need to change your fixed amount/percentage or would like to participate in the program.',
    EdgeProgramDescription: 'The Edge program combines flat rate pricing with cash discounting to help you make the same profit margin on non-cash payments as you do on cash payments.',
    EdgeDialogFirst: 'Once you enable the Edge program, the following rules apply: Your pricing will be set at 3.8461%. A 4% cash discounting amount applies to non-cash transactions. Note: Any open transactions and batches must be closed prior to pricing and cash discounting changes going into effect.',
    EdgeDialogSecond: 'If you choose to disable this program, you will default to your previous pricing. Consult with your sales partner if you have questions regarding this program.',
    EdgeTerms: 'By tapping Submit, you are agreeing to the Edge program pricing terms. All other terms and conditions of your Merchant Application and Merchant Progressing Agreement apply.',
    EdgeDialogFirstOptOut: 'If you choose to disable this program, you will default to your previous pricing. Note: Any open transactions and batches must be closed prior to pricing and cash discounting changes going into effect.',
    EdgeTermsOptOut: 'By tapping Submit, you are agreeing to revert to your previous pricing and disabling cash discounting. All other terms and conditions of your Merchant Application and Merchant Progressing Agreement apply.',
    CantDoEdge: 'Consult with your sales partner or call Customer Service at 877.387.5640 if you would like to participate in this program.',
    CantDisableEdge: 'Consult with your sales partner or call Customer Service at 877.387.5640 in order to opt out of the Edge program.'
  },
  StoreSettings: {
    FormTitle: 'Online Store',
    FormSubtitlePartOne: 'Enable an online store to sell items from your inventory. Edit your logo, business contact information and social media usernames on the ',
    FormSubtitlePartTwo: ' page.',
    FormSubtitleLink: 'Receipt & Invoice settings',
    FormEnableStore: 'Enable online store',
    FormPreviewStore: 'Preview Store',
    FormStoreURL: 'Online store URL',
    FormBusinessName: 'business-name',
    FormEnableShippingOption: 'Enable Shipping option',
    FormShippingDisclaimer: 'You as the merchant are responsible for calculating shipping costs and fulfillment.',
    FormErrorsLabel: 'Form is incomplete. Please check your changes.',
    StorePreview: {
      Title: 'Online Store Preview',
    }
  },
  Tin: {
    TinContent: 'The Internal Revenue Service (IRS) requires the reporting of debit and credit card transactions for businesses on an annual basis using an IRS information form called 1099K. In order to make accurate filings with the IRS, your tax filing name and Tax Identification Number (TIN) must match. ',
    TinImportantText: 'Failure to provide information that matches IRS records may result in penalties or federally mandated backup withholding. State backup withholding may also apply.',
    K1099Primary: 'If you do not see any 1099Ks available for download, then your account either has no 1099Ks currently available or does not meet the IRS-mandated criteria.',
    K1099Secondary: 'If you are unable to obtain a copy of your Form 1099K, please contact Customer Service at 877.464.4218. ',
    K1099Contact: 'If you need a correction to your Form 1099K, please contact us at'
  },
  TinFaq: {
    Question1: 'When can I expect to receive my annual Form 1099-K?',
    Answer1: {
      Option1: '1099-K tax forms are mailed annually in January, postmarked no later than January 31, and mailed to the address on file for your account.',
      Option2: 'Copies of 1099-Ks are available in Payments Hub approximately mid-February.'
    },
    Question2: 'How can I receive one 1099-K for all of my accounts?',
    Answer2: {
      Option1: 'If you have multiple locations under the same tax filing name and the same taxpayer identification number, then only one Form 1099-K will be issued. In this case, it will include the combined processing volume for all of the locations.'
    },
    Question3: 'What do I need to know about my 1099-K?',
    Answer3: {
      Option1: 'The 1099-K document is based on gross sales and does not account for returns, refunds, chargebacks, reversals, fees or interchange. Because the 1099K is based on gross sales volume, the gross sales volume amounts on the 1099K may not match the deposit amounts appearing on your bank statement. This may account for any discrepancies you have found between your sales volume and the amounts reported on the 1099-K. If you still believe there is a discrepancy on your 1099-K, please provide specific details regarding the disputed month(s) and amount(s).'
    }
  },
  TwoFactorAuth: {
    Header: 'Verify it\'\s you',
    Verification: {
      MissingNumber: 'Looks like we do not have a mobile number on file for you. Please add your mobile number in Security Settings before making any changes to your account.',
      Error: 'You\'ve entered an incorrect verification code.',
      ErrorRetry: 'There was an issue validating your token, please request a new one.'
    }
  },
  Banking: {
    AccountTypeHint: 'Account Type',
    AccountTypeLabel: 'Account Type',
    BankAccountHint: '0000000000000',
    BankAccountLabel: 'Bank Account',
    BillingBankAccountTitle: 'Billing Bank Account',
    ChargebackBankAccountTitle: 'Chargeback Bank Account',
    Checking: 'Checking',
    EditBankAccount: 'Edit {{editButton}} bank account',
    EditBankingNote: 'You must verify your identity prior to editing your banking information. Note that changes made here are not reflected if your American Express or Discover payments are separated, or if you process using Authorize.net. Please contact American Express, Discover, and Authorize.net directly to update your banking information with those platforms.',
    EditButton: {
      Settlement: 'primary',
      Chargeback: 'chargeback',
      Billing: 'billing'
    },
    NoData: 'No Banking Information found - please contact Customer Service at {{phoneNumber}}',
    PrimaryBankAccountTitle: 'Primary Bank Account',
    RoutingNumberHint: '0000000000000',
    RoutingNumberLabel: 'Routing Number',
    Savings: 'Savings',
    Title: 'Banking.',
    UpdateAllBankAccountsNote: 'Use this account for all bank account types'
  },
  savings: 'savings',
  checking: 'checking',
  Tooltips: {
    Banking: {
      Settlement: 'This is the account where your funds from completed transactions are deposited',
      Chargeback: 'This is the account where funds are withdrawn when a customer disputes a transaction and a chargeback is initiated.',
      Billing: 'This is the account where charges to your account, such as equipment rentals, are withdrawn.'
    },
    Owners: {
      Support: 'To update, add, or delete Owner information, please contact your sales partner. If you are not associated with a sales partner, please contact 866.485.8999.'
    },
    PendingVerification: 'Click here to resend the validation email.',
    PhoneNumber: 'To edit the phone number you want to receive security codes on, click Enable Two Factor Authentication.',
    OutOfStock: 'This item is out of stock.',
    LowOfStock: 'This item is low in stock.',
    MoreOfStock: 'The items selected are more than what is in stock.',
    AccountClosed: 'This account is closed.'
  },
  OpenTransactions: {
    TipExceedsLimit: 'The tip amount exceeds the allowed limit.'
  },
  RefundsMessages: {
    RefundExceedsLimit: 'The total amount exceeds the allowed limit. Select "Refund All" or change the refund amount manually.',
    RefundCustomAmountExceedsLimit: 'The total amount exceeds the allowed limit.',
    RefundAll: 'Refund the original transaction\'s full amount, taking into account any discounts and existing partial refunds. If disabled, the refund amount is based only on individual items selected.'
  },
  Terms2FADialog: {
    Header: 'Verify It’s You',
    MerchantHaveNoPhoneNumber: 'We couldn\'t find any mobile number in our records associated with this account. Please contact Customer Care at ',
    DescriptionWithPhone: 'Please enter your full mobile number ending in {{lastDigits}} in order to receive a verification code. Once you’ve verified, you can accept online payments with our virtual terminal. Message and data rates may apply. ',
    DescriptionNoPhone: 'Please enter your mobile number in order to receive a verification code. Once you’ve verified, you can accept online payments with our virtual terminal. Message and data rates may apply.',
    ProcessFinishedSuccess: 'You’ve successfully verified your identity. Please log out and log in again to get access to the virtual terminal.',
    ProcessFinishedFailureByToken: 'You’ve reached the maximum number of attempts with the verification code. You will be logged out momentarily. Please contact Customer Care at ',
    ProcessFinishedFailureByTokenAdd: ' to verify your identity and get access to the virtual terminal.',
    ProcessFinishedFailureByPhone: 'The number you entered does not match our records. Please contact Customer Care at ',
    ProcessFinishedFailureByPhoneAdd: ' to add your mobile number and verify your identity.'
  },
  MerchantVerifyByCSDialog: {
    Title: 'Merchant verification',
    Text: 'Merchant has been verified by Customer Service?'
  },
  InvoiceDetailModal: {
    ResendInvoice: {
      Title: 'Resend invoice',
      CancelText: 'No, go back',
      ConfirmText: 'Yes, resend invoice',
      Children: 'Are you sure you want to resend this invoice?'
    },
    SendInvoice: {
      Title: 'Send invoice',
      CancelText: 'No, go back',
      ConfirmText: 'Yes, send invoice',
      Children: 'Are you sure you want to send this invoice?'
    },
    MarkAsPaid: {
      Title: 'Mark as paid',
      ConfirmText: 'Yes, mark as paid',
      CancelText: 'No, go back',
      Children: 'Are you sure? Payment will not be charged to your customer’s card. This action cannot be undone.'
    },
    EditPaymentAmount: {
      Title: 'Edit payment amount',
      SubTitle: 'Cash Discounting and Tax are not included when you edit the payment amount.',
      Children: null
    },
    CancelInvoice: {
      Title: 'Cancel invoice',
      ConfirmText: 'Yes, cancel',
      CancelText: 'No, do not cancel',
      Children: `
        Are you sure you want to cancel this invoice?
        This action cannot be undone.
        Your customer will be notified of this cancelation.
      `
    },
    CancelSeries: {
      Title: 'Cancel series',
      ConfirmText: 'Yes, cancel series',
      CancelText: 'No, do not cancel',
      Children: `
        Are you sure you want to cancel this recurring series of invoices?
        This action cannot be undone and will stop all future payments
        from being charged. Your customer will be notified of this cancellation
      `
    },
    ViewAllActivities: {
      Title: '{{section}} activity',
      ConfirmText: 'Close',
      CancelText: null,
      Children: null
    }
  },
  QuickbooksAccount: {
    Title: 'Check your QuickBooks account',
    Text: 'We\'ve detected an issue with your QuickBooks Online account. Check your account settings on the Quickbooks website.'
  },

  // Virtual Terminal Forms

  VirtualTerminalSettings: 'Virtual Terminal Settings',
  VirtualTerminalSettingsDescription: 'Customize and define a field for your Virtual Terminal. This applies to both custom and itemized Virtual Terminal. Your custom field is displayed in Transaction Details and Reports.',
  CustomFields: 'Custom Field',
  Field: 'Field',
  OpenField: 'Open Field (Text Input)',
  FieldName: 'Enter field name',
  AddSelection: 'Add Selection',
  IssueAcredit: 'Issue a credit',
  VirtualTerminalFormTitles: {
    ItemizedSale: 'Create an itemized sale.',
    OnePayment: 'Accept a custom amount.',
    PaymentLink: 'Create Payment Link'
  },
  VirtualTerminalFormHeading: 'PAYMENT TYPE',
  DebtRepayment: 'Debt Repayment',
  ItemizedSale: 'Itemized Sale',
  IncludeLevel2: 'Include Level 2 Data',
  IncludeLevel3: 'Include Level 3 Data',
  CardDetails: 'Enter Card Details',
  UseCardReader: 'Use a Card Reader',
  BillingStreet: {
    Label: 'Billing Street Number',
    HintText: 'Optional'
  },
  ZipCode: {
    Label: 'Zip',
    HintText: 'Optional'
  },
  ZipCodePlus: {
    Label: 'Zip + 4',
    HintText: 'Optional'
  },
  SaveCreditCard: {
    Label: 'Save this payment method to the customer profile for future purchases',
    Notice: {
      Responsibility: 'It is your responsibility to collect your customer\'s written permission to save their payment method.',
      Acknowledgement: 'By adding a payment method, you acknowledge you are responsible for any declined transactions or chargebacks.'
    }
  },
  AvsNotice: 'Entering street number and zip can help with potential customer disputes.',
  Category: {
    Label: 'Category',
    NoCategory: 'No Category'
  },
  CustomAmount: 'Custom Amount',
  SaleAmount: 'Sale Amount',
  InvoiceNumber: {
    Label: 'Invoice #',
    HintText: 'Optional'
  },
  Taxable: 'Taxable',
  CashDiscounting: 'Cash Discounting',
  Description: {
    Label: 'Description',
    HintText: 'Describe goods or services sold'
  },
  AdditionalData: 'Additional Data',
  UnitMeasure: 'Unit of Measure',
  RequestorName: 'Requestor Name',
  SelectCountry: 'Select country',
  ShipmentZipCode: 'Shipment Zip Code',
  DestinationZipCode: 'Destination Zip Code',
  EmailOrMobile: {
    Label: 'Email address or mobile number',
    HintText: 'Use a comma or space to add multiple'
  },
  SelectExistingCustomer: 'Select existing customer',
  SelectExistingPaymentMethod: 'Select Existing Payment Method',
  EnterCardDetails: 'Enter Card Details',
  CustomerName: 'Customer First Name',
  CustomerLastName: 'Customer Last Name',
  Purchases: 'Purchases',
  PurchasesItemizedSale: '(Click an item to edit)',
  Searching: 'Searching',
  NoItems: 'No items',
  NoRememberedDevices: 'No remembered devices',
  TaxNote: 'Default tax rate is set to {{taxRate}}. Configure tax in <3>Business Settings.</3>',
  AddExpressItem: 'Add express item',
  NoBarCode: 'No barcode',
  InStock: 'In stock',
  OutStock: 'Out of stock',
  NotTracked: 'Not tracked',

  // Disputes

  DisputesDetail: {
    NoticeDate: 'Notice Date {{date}}',
    DateFormat: 'MMM DD, YYYY',
    ActionRequired: 'Action required',
    ResponseRequired: 'A response is required by {{dueDate}} by you (Merchant) or this case will be automatically accepted on your behalf.',
    UnderReview: 'Under Review',
    NabReview: 'NAB is reviewing your response to submit to customer\'s bank. Watch out for updates emails as additional documents may be requested.',
    Closed: 'Dispute Closed',
    Reason: 'Reason',
    ReasonCode: '(Reason code: {{code}})',
    CaseNumber: 'Case number: {{case}}',
    Details: 'Details',
    OriginalTransaction: 'Original Transaction',
    TransactionAmount: 'Transaction Amount {{amount}}',
    InvoiceDate: 'Invoice on {{date}}',
    Documents: 'Documents',
    AssociatedNotices: 'Associated notices',
    AssociatedComments: 'Associated comments',
    CommentInfo: 'Date created: {{createdDate}} - Made by {{madeBy}}',
    DisputedAmount: 'Disputed Amount {{amount}}',
    CompellingEvidence: 'View compelling evidence FAQ',
    Invoice: 'Invoice {{number}}'

  },

  // Virtual Terminal Form Item Selection

  DisabledMultipleSelections: 'Multiple selections disabled',
  RemoveOrder: 'Remove from order',
  SelectPrice: 'Select price',
  ExpressModifiers: 'Express modifiers',
  AddExpressModifier: 'Add express modifier',
  AddExpressDiscount: 'Add express discount per item',
  Percent: 'Percent',
  ExpressItem: {
    Title: 'Express item',
    ItemNameLabel: 'Item Name',
    ItemNameHint: 'Describe goods or services sold',
    ItemPriceLabel: 'Item Price',
    ItemPriceHint: '$0.00',
    TaxRateHint: '00.000'
  },
  Order: 'order',

  // Virtual Terminal Out of Stock Dialog

  OutOfStockItemDialogTitle: 'Out of stock price',
  OutOfStockItemDialogBody: 'The selected price for this item is out of stock. Are you sure you want to add it to cart?',

  // Payment Method Select
  SelectPaymentMethod: 'Select Payment Method',
  NoPaymentMethodsAdded: 'No payment methods added',
  EnterCardManually: 'Enter card manually',
  SendToCustomer: 'Send to customer',
  AddBankingAccount: 'Add Bank Account',

  // Attach Files Form
  AttachFilesFormError: {
    GiactExceedsLimit: 'File size exceeds the limit',
    ExceedsLimit: 'File size exceeds the limit or file format is not accepted and cannot be uploaded.'
  },
  AttachFilesFormInputLabel: 'Attached files',
  IconHolderText: 'Drag here',
  Browse: 'Upload files',
  AcceptedFiles: 'Accepted file formats: doc or docx, xls or xlsx, ppt or pptx, jpg or jpeg, gif, tif or mtif, pdf, txt, html.\nMax file size 5 MB',
  DisputesFilesInputLabel: 'Comments',
  RebutDispute: 'Rebut Dispute',
  NoDisputes: 'No Disputes',

  Import: 'Import',
  Upload: 'Upload',
  ImportInvoices: 'Import Invoices',
  Download: 'Download',
  DownloadTemplate: 'Download the template.',
  MaxImageFileSize: 'Max file size for images',
  UploadFile: 'Upload the file.',
  UploadInventory: 'Upload your inventory',
  CompleteYourFile: 'Complete your file.',
  CompleteYourFileInstructions: 'Complete the template with your invoice data. Make sure you fill all fields and leave the first row as the header.',
  ViewInstructions: 'View Instructions.',
  DragTemplate: 'Drag your completed template into the space below, or click "Browse" to locate it. Must be a CSV file (.csv)',
  FileUploaded: 'File uploaded!',
  DropFile: 'Drop File or Choose File',
  DropFileFormat: '.CSV only, Max file size 5MB',
  UploadTemplate: 'Upload template',
  ScrollViewItems: ' Scroll to view all items.',
  Progress: 'Progress',
  ImportInventory: 'Import inventory',
  ImportCSV: 'Import using CSV',
  ImportedFileUploaded: 'File {{fileName}} Uploaded. Click Import',
  ImportInProgress: '{{fileName}} import in progress.',
  ImportSmallerFile: '{{fileName}} has more than 3000 items. Please upload a smaller file',
  ImportInvalidItem: '{{item}} contains invalid field data.',
  ImportFinished: 'Import finished.',
  ImportedSuccessfully: '{{dataName}} imported successfully',
  ImportFailed: '{{dataName}} import failed.',
  OnDropFile: {
    ImportFailed: 'Unable to import the file.',
    ExceedsSize: 'Unable to import the file. Exceeds the maximum file size allowed.',
    InvalidType: 'Unable to import the file. Invalid file format, only .csv files are allowed.',
  },
  ImportInvalid: 'Some imports failed. This might be related to the file format used. Please make sure you use the correct file format or download the template file.',
  RequiredName: 'Name column invalid or missing',
  RequiredFavorite: 'Favorite column invalid or missing',
  RequiredTaxable: 'Taxable invalid or missing',
  RequiredTrackable: 'Trackable column invalid or missing',
  RequiredStock: 'Out of stock column invalid or missing',
  RequiredPrice: 'One or more prices are required',
  RequiredPriceName: 'Price name and dollar amount are required for every items price',
  CreatedCategory: 'Category {{name}} was created',
  CreatingCategoryError: 'Error creating {{name}} category',

  // Receipt Settings
  Logo: 'Logo',
  UploadLogo: 'Upload logo',
  PreviewReceipt: 'Preview receipt',
  PreviewEmail: 'Preview email',
  PreviewPayment: 'Preview payment portal',
  BusinessInformation: 'Business information',
  BusinessDescription: 'Customize business information displayed on customer receipts, invoices, and customer-facing web pages.',
  IncompleteSettingsForm: 'Form is incomplete. Please check your changes.',
  SaveSettings: 'Save settings',

  // Receipt Settings Form
  ReceiptSettingsForm: {
    CustomerFaceLogo: 'Customer-facing display logo',
    ThisLogo: 'Use this logo for the customer-facing display',
    DifferentLogo: 'Use a different logo for the customer-facing display',
    BusinessName: 'Business Name',
    BusinessNameHint: 'name',
    BrandColor: 'Brand Color',
    StreetAddress: 'Street address',
    StreetAddressHint: 'address',
    ZipCode: 'Zip code',
    BusinessPhone: 'Business Phone',
    Website: 'Website',
    BusinessEmail: 'Business Email',
    FacebookUsername: 'Facebook Username',
    InstagramUsername: 'Instagram Username',
    XUsername: 'X Username',
    YelpUsername: 'Yelp Username',
    SocialHintText: 'Username',
    ReceiptSettings: 'Receipt settings',
    ReceiptSettingsDesc: 'Customize settings specific to digital and printed receipts.',
    BCCEmailReceipts: 'BCC email receipts',
    BCCEmailAddress: 'BCC Email Address',
    EmailHintText: 'Enter email address',
    CustomerRating: 'Enable customer ratings',
    LogoPrintedReceipt: 'Display logo on printed receipt',
    ESN: 'Require ESN or barcode number',
    SocialMediaButtons: 'Display social media buttons on digital receipt',
    DisplaySoldBy: 'Display sold by',
    CustomFooterMessage: 'Custom Footer Message',
    MessageHint: 'Message',
    Options: 'Receipt Screen Options',
    SelectOption: 'Please select an option',
    Display: 'Always display Receipt Options screen',
    SkipPrint: 'Skip Receipt Options screen and Print by Default',
    Skip: 'Skip Receipt Options screen and Do Not Print',
  },
  PaymentPortalPreview: {
    OpenInvoices: 'Open Invoices',
    PaymentMethods: 'Payment Methods',
    PaymentHistory: 'Payment History',
    SessionExpired: 'Session Expired',
    BackToInvoices: 'Back to open invoices',
    InvoiceAmount: 'Invoice Amount: $241.68',
    PayBy: 'Pay by June 10, 2021',
    PaymentMethod: 'Payment Method',
    PayWithCreditCard: 'Pay with Credit Card',
    CardHolderName: 'Cardholder Name',
    CardNumber: 'Card Number',
    Expiration: 'Expiration',
    SavePaymentMethodDesc: 'Save this payment method and automatically charge future payments in this series.',
    NoTip: 'No Tip',
    Other: 'Other',
    PayAmount: 'Pay $241.68',
    TravelPackage: 'Travel Package (3 of 12)',
    BillTo: 'Bill to Michael Benedict',
    InvoiceNumber: 'Invoice #1088',
    Date: 'May 10, 2021',
    RepeatsMonthly: 'Repeats monthly until 5/10/2022',
    MonthlyTravelKit: 'Monthly Travel Kit (1)',
    VacationPackagePayment: 'Vacation Package Payment (1)',
    VacationPlace: 'Rome & Amalfi Coast',
  },
  ReceiptPreview: {
    InvoiceNumber: 'Invoice 1807',
    InvoiceDate: '9/26/22 at 4:01 pm',
    CookiesItem: 'Cookies x1',
    SugarItem: 'Sugar',
    EspressoItem: 'Espresso x1',
    Double: 'Double',
    ConPana: 'Con Pana',
    SoldTo: 'Sold to James Centro',
    CardInfo: 'Visa 8339 (Contactless)',
    AuthCode: 'Auth Code 88381H',
    ExperienceQuestion: 'How was your experience?',
    LoyaltyHeaderFirstPart: 'You’ve earned a',
    LoyaltyHeaderSecondPart: 'off reward!',
    LoyaltyText: 'Present QR code or discount code at your next purchase.'
  },

  InvoicePreview: {
    BillTo: 'Bill to',
    InvoiceNumber: 'Invoice #',
    Invoice: 'Here\'s your invoice for',
    PayBy: 'Pay by',
    PayNow: 'Pay now',
    SaleAmount: 'Sale Amount',
    RepeatsEvery: 'Repeats every',
    RepeatsTime: 'on the',
    Description: 'Detail:'
  },

  // Dispute Detail
  UploadEvidence: 'Upload compelling evidence',

  // Client Service Form
  ClientServiceForm: {
    MidLabel: 'mid',
    MidHint: 'mid',
    KnowYouAreNow: 'You Are Now:',
    Back: 'Back',
    NoSprContainerError: 'Wrong Page.',
    NoSprContainerInstruction: 'This is not the page you are looking for',
    NotFound: 'Not Found',
    ViewingMid: 'Viewing MID',
    CsPortalImpersonation: 'CS Portal Impersonation',
  },

  // DemoMode
  DemoMode: {
    Title: 'Get your business up and running in minutes.',
    SubTitle: 'You\'re in Demo Mode. Activate your account to accept payments and use every feature.',
  },

  // Funding
  FundingComponent: {
    ConfirmFundingDialog: {
      Title: 'Change Funding?',
      Text: 'You are about to change your setting for funding.'
    },
    ImgVerificationDialog: {
      Text: 'You\'ve changed your {{type}} bank account. Edit the other account types as needed. Banking updates will take effect once verified.',
      Primary: 'primary',
      Chargeback: 'chargeback',
      Billing: 'billing'
    },
    AddFilesDialog: {
      Title: 'Bank Account Verification',
    },
    UploadErrorDialog: {
      Title: 'File upload error',
      Text: 'There was an error uploading your files, please try again'
    },
    SuccessfullySavedFundingDialog: {
      Title: 'Saved',
      Text: 'Successfully Saved Funding Option'
    }
  },

  // Funding Form
  FundingForm: {
    NameOnCard: 'Name on Card',
    DebitCardNumber: 'Debit Card Number',
    StreetNumber: 'Street Number',
    ZipLabel: 'ZIP',
    ZipHint: 'ZIP',
    InstantFundingLabel: 'Enable Instant Funding',
    InstantFundingSubText: 'Receive funds for up to $5,000 per day on a qualifying debit card within 15 minutes. Cost: 1% of transaction.',
    Heading: 'Funding.',
    SubText: 'Decide how you get your money and link your bank accounts.',
    SecondarySubText: 'Default Funding Method',
    NextDayFundingLabel: 'Next day funding',
    NextDayFundingLabelSubText: ' (default) ',
    NextDayFundingSubText: 'Receive funds by 10 am next day for transactions processed by 10 pm ET.',
    SameDayFundingLabel: 'Same day funding',
    SameDayFundingSubText: 'Receive funds same day for up to $50,000 per day. Exact deposit time may vary; contact your bank for information on their deposit schedule. Transactions must be processed and closed prior to 10:30 am ET / 7:30 am PT to qualify.'
  },

  // Last Login Activity
  LastLoginActivityHeader: 'Last login activity',

  // Employee Table Headers
  EmployeeTable: {
    Headers: {
      Status: 'Status',
      Name: 'Name',
      Role: 'Role',
      LastLogin: 'Last Login'
    }
  },

  // Employee Detail
  EmployeeDetailTitle: {
    ArchivedEmployee: 'Archived Employee',
    DetailsEmployee: 'Employee Details',
    EditEmployee: 'Edit Employee',
  },
  EmployeeDetailOption: {
    Reactivate: 'Reactivate',
    Archive: 'Archive'
  },
  EmployeeDetailResendInvoice: {
    Header: 'Not Activated',
    Description: 'This user has not activated their account. Click below to resend the invitation. Invitations are valid for 30 minutes.',
    Resend: 'Resend Now',
  },
  EmployeeDetailNewEmployee: {
    InfoHeader: 'Activating a New Employee Account',
    Description: 'New employees will receive a link via email to activate their account.  For security purposes, a new link must be resent if the account is not activated within 30 minutes.',
  },
  EmployeeDetailLastLogin: 'Last login location',
  EmployeeDetailDetail: 'Detail',
  EmployeeDetailAccountLocked: 'Account: Locked',
  EmployeeDetailAccountActive: 'Account: Active',
  EmployeeDetailAddedBy: 'by {{name}}',
  EmployeeDetailAddedDateBy: 'Added {{date}}',
  EmployeeDetailLastAppLogin: 'Last Mobile App Login {{date}} at {{time}}',
  EmployeeDetailLastUpdate: 'Last Update {{date}} at {{time}}',
  EmployeeDetailDuplicateErrorDialogTitle: 'User already exists',
  EmployeeDetailArchiveConfirmationDialog: {
    Title: 'Archive employee.',
    Text: 'Are you sure you want to move this employee to the archive? You can reactivate this employee at any time. This employee will still appear in historic reporting.',
  },
  EmployeeUnlockRateLimit: 'Unlock rate limiting',
  EmployeeDetailForm: {
    Save: 'Save',
    Role: 'Role',
    Roles: {
      Owner: 'Owner',
      Admin: 'Administrator',
      Manager: 'Manager',
      Cashier: 'Cashier',
      Reporter: 'Reporter',
      Report: 'Reporter',
      'Customer support': 'Customer Support',
    },
    FirstName: 'First Name',
    LastName: 'Last Name',
    PhoneNumber: 'Phone Number'
  },

  // Profile
  CsSettings: {
    AgentAccessStatus: 'Agent Access Status:',
    Cannabis: 'Cannabis:',
    CanViewApplication: 'Can View Application:',
    CashDiscountEnabled: 'Cash discount enabled:',
    CashDiscountFixedAmount: 'Cash discount fixed amount:',
    CashDiscountPercentAmount: 'Cash discount percent amount:',
    CBD: 'CBD:',
    CsSettingsWithToggle: 'Enable push notifications:',
    CsVerified: 'CS Verified',
    DebtRepaymentAllowed: 'Debt Repayment Allowed:',
    HasOptedOut: 'Has opted out:',
    HBT: 'HBT:',
    IsEdgeUser: 'Is Edge user:',
    IsISV: 'Is ISV:',
    AutoReportingEnabled: 'Auto Reporting Enabled:',
    LargeVolumeProcessor: 'Large Volume Processor:',
    PlanOptedOut: 'Plan opted out:',
    PlanType: 'Plan type:',
    PremiumFree: 'Premium free:',
    ProcessingLevel: 'Processing Level:',
    Salido: 'Salido:',
    SmsEnabled: 'SMS enabled:',
    VtEnabled: 'VT enabled:',
    ReputationManagement: 'Reputation Management Enabled:',
    LoyaltyVpc: 'Loyalty Program Enabled:',
    LoyaltyStatus: 'Loyalty Feature:',
    LoyaltyStatusWithToggle: 'Loyalty Feature Enabled:',
    LavaEnabled: 'Lava Enabled:',
    DAVOStatus: 'DAVO Integration Connection:',
    DAVOStatusWithToggle: 'DAVO Integration Access:'
  },
  CsSettingsTitle: 'Customer Service Related Info',
  EditPersonalProfile: 'Edit personal profile',
  EmailCommunicationDialogContent: 'Check your inbox at {{username}} and verify your email address in order to receive disputes communications by email.',
  Enrolled: 'Enrolled {{enrolledDate}}',
  Free: 'Free',
  MembershipDialogContent: 'Please contact the Owner.',
  MembershipRemaining: 'Trial days left: {{daysRemaining}} remaining',
  MerchantApplicationContent: 'Click to view your merchant application. If you have any questions regarding the accuracy or completeness of your application, please contact Customer Care at {{customerServicePhone}} or {{customerServiceEmail}}. If we do not hear from you, we will conclude by your continued processing of transactions that your merchant application is accurate and complete.',
  MerchantApplicationDownload: 'View your merchant application',
  MerchantProcessingAgreementDownload: 'View the Merchant Processing Agreement',
  MerchantApplicationHeading: 'Merchant application',
  MerchantProcessingAgreementHeading: 'Merchant Processing Agreement',
  OptedOut: 'Opted out',
  OptOutPremiumAccess: 'Opt out premium access',
  OptOutPremiumPlan: 'Opt out of premium plan',
  Premium: 'Premium',
  SecuritySettings: 'Security settings',
  UnableEdit: 'Unable to edit',
  UnableEditContent: 'We were unable to verify your identity. Please call Customer Care at {{phoneNumber}} in order to update your mobile number.',
  UpdateEmailAddress: 'Update email address',
  UpdateManageRememberedDevices: 'Manage Remembered Devices',
  UpdatePassword: 'Update password',
  UpdatesPendingContent: 'For security purposes, updates you’ve made to your account will take effect when verified.',
  UpdatesPendingTitle: 'Updates Pending',
  Upgraded: 'Upgraded',
  UpgradePremiumAccess: 'Upgrade to premium access',
  UpgradePremiumPlan: 'Upgrade to Premium Plan',
  UsernameLabel: 'Username',
  UserSettingsTitle: 'User settings',
  VerifyEmailAddress: 'Verify email address',
  UserLanguage: 'User Language',
  ManagePlan: 'Manage Plan',

  // Profile Form
  ProfileForm: {
    NameLabel: 'Name',
    NameHint: 'Name',
    DateOfBirthLabel: 'Date of Birth',
    DateOfBirthHint: 'Date of Birth',
    SsnLabel: 'SSN',
    SsnHint: 'SSN',
    HideSensitiveDataLabel: 'Hide sensitive data',
    AddressLabel: 'Address',
    AddressHint: 'Address',
    SuiteAptLabel: 'Suite/Apt.',
    SuiteAptHint: 'Suite/Apt.',
    CityLabel: 'City',
    CityHint: 'City',
    StateLabel: 'State',
    StateHint: 'State',
    ZipLabel: 'Zip',
    ZipHint: 'Zip'
  },

  // Manage Remembered Devices
  ManageRememberedDevices: {
    Title: 'Remembered Devices',
    Description: 'Manage the devices or browsers that you have chosen to remember and will not require a verification code on login.',
    DeviceType: 'Device Type',
    Location: 'Location',
    Added: 'Added',
    AddedWithDate: 'Added {{date}}',
    Forget: 'Forget'
  },

  // Update Email Form
  UpdateEmailForm: {
    Description: 'Modifying your email will change your login credentials for North, Payments Hub, and Payanywhere products.',
    NewEmailAddressLabel: 'New Email Address',
    NewEmailAddressHint: 'Enter New Email Address',
    ConfirmNewEmailAddressLabel: 'Confirm New Email Address',
    ConfirmNewEmailAddressHint: 'Confirm New Email Address',
    EnterPasswordLabel: 'Enter Password',
    EnterPasswordHint: 'Current Password'
  },

  // Update Password Form
  UpdatePasswordForm: {
    CreateNewPassword: 'Create New Password',
    Description: 'Modifying your password will change your login credentials for North, Payments Hub, and Payanywhere products.',
    CurrentPasswordLabel: 'Current Password',
    CurrentPasswordHint: 'Enter Current Password',
    NewPasswordLabel: 'New Password',
    NewPasswordHint: 'Enter New Password'
  },

  // Communication Settings Form
  CommunicationSettingsForm: {
    AlertError: 'Alerts communication error, please try refreshing again',
    Title: 'Communication settings.',
    SubTitle: 'Receive email alerts about your account activity. For detailed account information, please refer to your Daily Batch Activity Summary and/or Daily Snapshot emails.',
    SettingsErrorBottom: 'Form is incomplete. Please check your changes.',
    SubmitButton: 'Save settings',
    FeedbackTitle: 'Have a specific email alert you\'d like to see on Payments Hub?',
    FeedbackText: 'Send your suggestion to <anchor>{{feedbackEmail}}</anchor> and we will consider your request. Thank you for helping us continuously improve Payments Hub.',
    CustomEmailAlertsSection: {
      Title: 'Custom Email Alerts',
      DailySummaryAlert: 'Daily batch activity summary',
      CustomAlerts: 'No transaction activity',
      NoTransactionActivityHint: '0 Days',
      CustomEmailLabel: 'Send Custom Email Alerts To',
      CustomEmailHint: 'Email address',
      AdditionalEmailLabel: 'Additional Email Address',
      AdditionalEmailHint: 'Additional email address'
    },
    BasicAccountAlerts: {
      Title: 'Basic Account Alerts',
      SubTitle: 'Basic account alerts are sent to the business email address on this account only. To edit your business email address, contact Customer Service at <phone>{{phoneNumber}}</phone>.',
      BusinessEmailLabel: 'Business Email Address',
      DailySnapshotEmail: 'Daily Snapshot Email',
      CommunicationsItemsDailySnapshotEmail: {
        Option1: 'I do not want to receive this email.',
        Option2: 'Only send this email when I have a hold on my account.',
        Option3WithEmail: 'Send daily to email: {{emailAddress}}',
        Option3WithoutEmail: 'Send daily to email.'
      },
      ManualCaptureBatchNotification: 'Manual Capture Batch Notification',
      CommunicationsItemsClosingEmail: {
        Option1: 'I do not want to receive this email.',
        Option2: 'Send daily to email.'
      },
      TipAdjustment: 'Tip Adjustment',
      DisputeNotifications: 'Dispute Notifications',
      EnterEmail: 'Enter email or separate multiple emails with a comma',
      CommunicationsItemsDisputes: {
        Option1: 'Select communication method',
        Option2: 'Email: {{emailAddress}}',
        Option3: 'By Email.'
      },
      CommunicationItems: {
        Option1: 'Select communication method',
        Option2: 'Email: {{emailAddress}}',
        Option3: 'By Email.'
      },
      CommunicationDisclaimer: '*Option to receive paper statements will add a $1.00 fee to your account',
      InvoiceAlertsHeader: 'Invoice Alerts',
      InvoiceAlertsDescription: 'Receive a copy of all customer invoices and communications, with the exception of paid invoice receipts.',
      InvoiceSent: 'Invoice sent',
      InvoicePaid: 'Invoice paid',
      InvoiceCanceled: 'Invoice canceled',
      SeriesEnrolled: 'Series enrolled',
      PaymentMethodUpdated: 'Payment method updated',
      InvoicePaymentAmountUpdated: 'Invoice payment amount updated',
      PaymentPending: 'Payment pending',
      PaymentFailed: 'Payment failed',
      CardOnFileAdded: 'Card on file added',
      CardOnFileRemoved: 'Card on file removed',
      CommunicationLoginNotification: 'New Device Login Notification',
      CommunicationItemsLoginNotification: {
        OptedIn: 'Send when a new browser or device logs in.',
        OptedOut: 'I do not want to receive this email.',
      }
    }
  },
  VerifyEmailAddressContent: 'Check your inbox at {{userName}} and verify your email address in order to receive disputes communications by email.',

  // Integrated Access Keys
  IntegratedAccessKeys: {
    GoBackButton: 'All integrations',
    LinkLikeText: 'Developer Site',
    SubText: 'Integrate with our API by setting up access tokens',
    Title: 'Integrated Access Keys'
  },

  // Tokens And Passwords
  TokensAndPasswords: {
    AlreadyProvided: 'Already provided',
    JsSskTokenLabel: 'JS SDK Token:',
    ProxyAccessLabel: 'Proxy Access:',
    ProxyAppSourceHeaderLabel: 'Proxy App Source Header:',
    ProxyTokenLabel: 'Proxy Token:',
    RecreateProxyAccessButton: 'Recreate Proxy Access',
    TooltipText: 'This label is to be used with x-nabwss-appsource header value.'
  },

  // Access API Form
  AccessApiForm: {
    AccessJsSdkLabel: 'Access JS SDK',
    AccessOurApiLabel: 'Access our API',
    AccessPayNowLabel: 'Access Pay Now',
    JsSdkDomainLabel: 'JS-SDK Domain',
    SemiIntegrationLabel: 'Semi Integration'
  },

  // Recreate Proxy Access Dialog
  RecreateProxyAccessDialog: {
    Title: 'Recreate Proxy Access',
    ConfirmButton: 'Recreate',
    Message: 'Are you sure you would like to recreate the Proxy access?'
  },

  // Business Form
  BusinessForm: {
    BusinessFormTitle: 'Business Details',
    TransactionalTitle: 'Transactional Details',
    AverageMonthlyVolume: 'Average Monthly Volume: {{averageMonthlyVolume}}',
    AverageTicketAmount: 'Average Ticket Amount: ${{averageTicketAmount}}',
    BusinessContact: 'Business Contact',
    BusinessAddress: 'Business Address',
    SuiteApt: 'Suite/Apt.',
    City: 'City',
    State: 'State',
    Zip: 'Zip',
    Phone: 'Business Phone',
    Fax: 'Business Fax',
    AdditionalCorporateInfoMessage: 'I have a separate corporate address',
    CorporateAddress: 'Corporate Address',
    CorporateSuiteApt: 'Corporate Suite/Apt.',
    CorporateCity: 'Corporate City',
    CorporateZip: 'Corporate Zip',
    AdditionalShippingInfo: 'I have a separate shipping address',
    ShippingName: 'Shipping Name',
    ShippingContact: 'Shipping Contact',
    ShippingAddress: 'Shipping Address',
    ShippingSuiteApt: 'Shipping Suite/Apt.',
    ShippingCity: 'Shipping City',
    ShippingZip: 'Shipping Zip',
    SetClosingTimer: 'Set Auto Batch Time',
    SelectTime: 'Select Time'
  },

  // Owner
  Owners: {
    AddOwner: 'Add owner',
    PercentageOwnership: '% Ownership',
    PriorityContact: 'Priority contact:',
    Title: 'Owners',
    Update: 'UPDATE'
  },

  // Role Settings Form
  RoleSettingsHeading: 'Edit Role',
  RoleSettingsHeadingManager: 'Manager settings.',
  RoleSettingsDetailHeader: 'Options',
  RoleSettingsDetailHeaderManager: 'Managers have full access to the Payanywhere app and limited access to Payments Hub. This role cannot view personal or bussiness information tied to the account.',
  RoleSettingsFields: {
    RefundVoidEnabledFieldLabel: 'Allow Cashiers to Refund & Void',
    RefundVoidLimitFieldLabel: 'Refund & Void Limit',
    RefundVoidLimitFieldInfoText: 'Enter a maximum dollar amount that your cashiers are allowed to void or refund. Amount must be greater than $0.00.'
  },

  // Customers
  CustomerTable: {
    Email: 'Email',
    PhoneNumber: 'Phone Number',
    Unnamed: 'Unnamed Customer',
    CardOnFile: 'Saved Payment',
    ActiveReward: 'Active Reward'
  },

  // Customer Detail
  CustomerDetailForm: {
    NewCustomer: 'New Customer',
    CreateInvoice: 'Send an Invoice',
    EditCustomer: 'Edit Customer',
    Delete: 'Delete',
    TransactionHeader: 'Details',
    RatingHeader: 'Customer Experience',
    RatingText: 'Average Rating',
    RecurringHeader: 'Recurring',
    AddPaymentMethod: '+ Add payment method',
    SavedPaymentMethods: 'Saved Payment Methods',
    AddPaymentMethodSuccess: 'Payment Method Saved',
    SaveCustomerSuccess: 'Customer Saved',
    AddPaymentMethodErrorTitle: 'Unable to add payment method.',
    AddPaymentMethodError: 'Please confirm the information entered is correct',
    CardDeleteConfirmationDialog: {
      Title: 'Are you sure you want to delete this payment method?',
      Body: 'If this payment method is associated with any recurring payments, it will no longer be charged.'
    },
    CustomerDeleteConfirmationDialog: {
      Title: 'Delete customer.',
      Body: 'Are you sure you want to delete this customer? This action cannot be undone.'
    },
    Fields: {
      FirstName: 'First Name',
      LastName: 'Last Name',
      PhoneNumber: 'Phone Number',
      AccountNumber: 'Account Number (Optional)'
    },
    CustomerErrorDialog: {
      Title: 'Unable to create customer.',
      Content: 'A customer with this information already exists. Please enter unique customer information and try again.'
    },
    LoyaltyProgram: 'Loyalty Program',
    AddCC: 'To add a payment method, please register a valid email address and/or phone number with a first and/or last name.',
    SeeTransactions: 'See Transactions'
  },

  // Card On File
  CardOnFile: {
    Title: 'Add payment method',
    SubTitle: 'Save payment methods on file for recurring invoices and faster checkouts.',
    Acknowledgement: 'It is your responsibility to collect your customer’s written permission to save their card. By adding a card, you acknowledge you are responsible for any declined transactions or chargebacks.',
    Fields: {
      CardHolderName: 'Cardholder name',
      CDigits: 'Credit Card Number',
      EDate: 'Expiration',
      StreetNumber: 'Billing Street Number',
      CardZip: 'Zip',
      ZipPlus4: 'Zip + 4',
      Optional: 'Optional'
    }
  },

  // Owner Form
  OwnerForm: {
    FirstNameLabel: 'First Name',
    LastNameLabel: 'Last Name',
    SsnLabel: 'SSN',
    DateOfBirthLabel: 'Date of Birth',
    ContactPriorityLabel: 'Contact Priority',
    PercentageOfOwnership: 'Percentage of Ownership',
    TitleLabel: 'Title',
    OwnerLabel: 'Owner',
    PresidentLabel: 'President',
    CeoLabel: 'CEO',
    VicePresidentLabel: 'Vice President',
    PartnerLabel: 'Partner',
    ManagerLabel: 'Manager',
    EmailLabel: 'Email',
    PhoneNumberLabel: 'Phone Number',
    PhoneExtensionLabel: 'Phone Extension',
    AddressLabel: 'Address',
    SuitAptLabel: 'Suite/Apt.',
    CityLabel: 'City',
    StateLabel: 'State',
    ZipLabel: 'Zip',
    IsPersonalGuarantorLabel: 'Is Personal Guarantor?',
    IsPrimaryContactLabel: 'Is Primary Contact?'
  },

  // Webhook Form
  WebhookForm: {
    AccessWebhookLabel: 'Access Web Hook',
    WebhookDomainLabel: 'Web Hook URL'
  },

  // Transactions Webhook
  TransactionsWebhook: {
    TransactionsWebHookLabel: 'Transactions Web Hook',
    TransactionsWebHookSubtext: 'Integrate a web hook for your transactions'
  },

  // Merge Category Form
  MergeCategoryForm: {
    Legend: 'Merge "{{itemName}}" with another category',
    NewCategoryNameHint: 'Enter name of new category name',
    NewCategoryNameLabel: 'New category name',
    SelectCategory: 'Select Category',
    SelectLabel: 'Category'
  },

  // Items
  ItemsDetail: {
    AddPrice: 'Add Price',
    AddToCategory: 'Add to category',
    AddToFavorites: 'Add to favorites',
    AllowOutOfStockSalesLabel: 'Allow Out of Stock Sales',
    ArchiveItem: 'Archive Item',
    ArchiveItemTitle: 'This item is archived.',
    ArchiveItemDetail: 'Items in your archive will not be available to sell. Select \"Reactivate this item\" below to move this item back to your active inventory.',
    Barcode: 'Barcode',
    BarcodeNumber: 'Barcode Number',
    Cost: 'Cost',
    DeleteDialogTitle: 'Archive item.',
    DeleteDialogText_one: 'Are you sure you want to move {{selectedItems}} item to the archive? You can reactivate this item at any time. This item will still appear in historic reporting.',
    DeleteDialogText_plural: 'Are you sure you want to move {{selectedItems}} items to the archive? You can reactivate these items at any time. These items will still appear in historic reporting.',
    InStock: '{{itemsCount}} in stock',
    ItemNameLabel: 'Item Name',
    LowStockAlertHint: '0',
    LowStockAlertLabel: 'Low Stock Alert',
    Modifiers: 'Modifiers',
    NewItem: 'New Item',
    NotTracked: 'Not tracked',
    NumberInStock: '# In Stock',
    Options: 'options',
    OutOfStock: 'Out of stock',
    PermanentlyDeleteItem: 'Permanently delete item',
    Price: 'Price',
    Prices: 'Prices',
    PriceNameHint: 'Price Name',
    PriceNameLabel: 'Name',
    ReactivateLink: 'Reactivate this item',
    RemoveFromFavorites: 'Remove from favorites',
    SalesTaxLabel: 'Sales Tax',
    SearchItems: 'Search Items',
    SelectCategory: 'Select Category',
    TrackItemLabel: 'Track Item'
  },

  // Modifiers
  Modifiers: {
    AddModifier: 'Add Modifier',
    AllowMultipleSelections: 'Allow Multiple Selections',
    CountModifiers: '{{itemCount}} modifiers',
    DeleteConfirmationText: 'Are you sure you want to delete this modifier set?',
    DeleteModifierSet: 'Delete modifier set.',
    NewModifierSet: 'New Modifier Set',
    NoData: 'No Modifier Sets',
    NumberOfModifiers: '# of Modifiers',
    ModifierNameLabel: 'Modifier Name',
    ModifierSetName: 'Modifier Set Name',
    PriceLabel: 'Price',
    SearchModifiers: 'Search Modifiers'
  },

  // PayOrder Form
  PayOrderForm: {
    Expiration: 'Expiration',
    BillingStreetNumber: 'Billing Street Number',
    Zip: 'ZIP',
    ZipPlus: 'ZIP + 4'
  },

  InvoiceForm: {
    AllowCustomerTip: 'Allow customer to add tip',
  },

  //PayInvoiceForm
  PayInvoiceForm: {
    ReceiptSent: ' A receipt will be emailed to', // A receipt will be emailed to
    PaymentReceived: 'Your payment has been received.', //Your payment has been received.
    Paid: 'Paid',
    CreditCard: 'Credit Card Number',
    Due: 'Due',
    PayBy: 'Pay by',
    Expiration: 'Expiration',
    BillingStreet: 'Billing Street Number',
    PayNow: 'Pay Now',
    InvoiceNotFound: 'Invoice Not Found',
    RepeatsEvery: 'Repeats every',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year',
    Until: 'until',
    Off: 'off',
    BillTo: 'Bill to',
    OrderSummary: 'Order Summary',
    InvoiceCancelled: 'This invoice is cancelled and cannot be paid.',
    CancelledInvoice: 'Cancelled Invoice',
    TooManyAttempts: 'Too many attempts',
    PaymentNotProcessed: 'Your payment could not be processed. Please contact',
    DirectlyInformation: 'directly for more information.',
    AutoPaySet: 'You have autopay set on this invoice',
    InvoiceProcess: 'Your invoice will process on',
    AutomaticCharge: 'Automatically charge this card for future payments in this series',
    AvsErrorText: 'Your payment could not be processed. An automatic notification has been sent to {{support}} to contact you; please contact the business directly for more information.'
  },

  CustomerPayment: {
    SomethingWentWrongError: 'Sorry, something went wrong, please try again',
    Error: 'Error: {{error}}',
    ApplePayError: 'Apple Pay cannot be used for this transaction. Please try another card.',
  },

  OnlinePayment: {
    ProcessingPaymentError: 'Error processing payment: {{error}}',
    SomethingWentWrongError: 'Sorry, something went wrong, please try again',
    Error: 'Error: {{error}}',
    ApplePayError: 'Apple Pay cannot be used for this transaction. Please try another card.',
    NoData: 'Invoice Not Found',
    ButtonAuthorize: 'Authorize now',
    ButtonPayNow: 'Pay now',
    BillTo: 'Bill to {{name}}',
    UnnamedCustomer: 'Unnamed Customer',
    InvoiceName: 'Invoice #{{invoice}}',
    DetailsDateFormat: 'MMMM DD, YYYY',
    OrderSummary: 'Order Summary',
    CanceledInvoiceBody: 'This invoice is cancelled and cannot be paid.',
    CanceledInvoiceTitle: 'Cancelled Invoice'
  },

  OnlinePaymentForm: {
    ThankYouText: 'Your payment has been received.',
    CompletedSaleId: 'completed - sale id: {{saleId}}',
    Processed: 'processed.',
    PaidWithDate: 'Paid: {{date}}',
    PaidDateFormat: 'MM/DD/YYYY',
    PreAuthorized: 'Pre-Authorized: ',
    PreAuthorization: 'Your Pre-Authorization has been ',
    AppleInvoice: 'Invoice {{invoice}} Subtotal',
    AppleTax: 'Tax',
    AppleTotal: 'Total',
    AppleTip: 'Tip',
    PreAuthorizationDue: 'Pre Authorization Due',
    Due: 'Due',
    Field: {
      TipAmountLabel: 'Add Tip',
      TipAmountHint: '$0.00',
      EDateLabel: 'Expiration',
      EDateHint: 'MM/YY',
      CvvLabel: 'CVV',
      CidLabel: 'CID',
      StreetNumber: 'Billing Street Number',
      Zip: 'Zip',
      ZipPlus4: 'Zip + 4',
      ZipPlus4Hint: 'Optional'
    },
    PayBy: 'Pay by {{date}}',
    PayByDateFormat: 'MMMM DD, YYYY',
    ViewOrderSummary: 'View order summary'
  },

  PaymentLinkForm: {
    Field: {
      AmountLabel: 'Enter Payment Amount',
      AmountHint: '$0.00',
      SelectAmountLabel: 'Select Payment Amount',
      SelectOtherAmount: 'Other',
      CardHolderLabel: 'Cardholder Name',
      CardNumberLabel: 'Card Number',
    },
    Pay: 'Pay',
    PayViaCreditCardOption: 'Pay via Credit Card',
    TotalMayInclude: 'Total may include tax and/or tip.',
    NotFoundTitle: 'Sigh.',
    NotFoundLine1: 'This payment link is no longer active or never existed.',
    NotFoundLine2: 'Things will get better.',
    Success: {
      Title: 'Thanks for your payment!',
      Subtitle: 'A receipt has been sent to {{email}}',
      DoAnother: 'Make Another Payment'
    },
    Declined: {
      Title: 'Payment Declined',
      Subtitle1: 'We were unable to complete the transaction.',
      Subtitle2: 'Please try another payment method.',
      LockedSubtitle: 'Please try again later.'
    },
    RedirectionText: 'You\'ll be redirected in',
  },

  //TwoFactorForm
  TwoFactorForm: {
    '4DigitCode': 'Verification code',
    Enter4DigitCode: 'Enter code',
    RequestNewCode: 'Request new code',
    MobileNumber: 'Mobile Number',
    EnterMobileNumber: 'Enter Mobile Number',
    EnableForLogin: 'Enable two-factor authentication for Login',
    VerificationNotice: 'Before making changes to your account, we’d like to verify your identity. We’ve sent a verification code to your mobile number ending in',
    EnterCode: 'Enter in the code sent to your mobile number'
  },

  // Server Messages
  Server: {
    // Error Messages `src/config/error.json`
    Error: {
      ApiAccessBadRequest: 'Issue with request - please try again, if this keeps happening please contact customer service',
      BadRequest: 'Bad Request, please check information submitted for duplicates or missing information',
      DuplicateEmployee: 'Duplicate Employee',
      DuplicateName: 'You’ve entered a name that already exists. Please try again with a different name.',
      CreateServerPdfFileError: 'Server Error: Creating PDF',
      EquipmentOrderError: 'There was an issue submitting Card Reader request please call Customer Service at 877.387.5640 for assistance.',
      NotFound: 'Portal: Not Found',
      Unauthorized: 'Unauthorized',
      NoUsername: 'No username supplied',
      NoCredentials: 'No credentials supplied',
      NoResponse: 'This service is currently unavailable. Please try again.',
      NoStatementsFound: 'No statements were found.',
      NoStatementPdfFound: 'There was an issue retrieving this statement.',
      NoMerchantApplicationPdfFound: 'No merchant application was found.',
      NoSchedulesFound: 'No schedules were found.',
      NoReceiptFound: 'Unable to find receipt at this time.',
      UnsuccessfulRequest: 'Credentials incorrect. Please try again.',
      UnsuccessfulSignInPA: 'Sorry, your username and/or password is incorrect. Please try again. Note: Cashiers cannot access Payanywhere Inside.',
      UnsuccessfulSignInPH: 'Sorry, your username and/or password is incorrect. Please try again. Note: Cashiers cannot access Payments Hub.',
      DuplicateEmail: 'The email is being using by another account. Please try again with a different email.',
      LockedAccount: 'This account is inactive and/or locked. Please call Customer Service.',
      LockedAccountRateLimit: 'This username has been temporarily locked for security reasons. You can try again in 30 minutes.',
      UnsuccessfulMBPMigration: 'Sorry, something went wrong while attempting to migrate your MBP data.',
      MidsAlreadyExistForUser: 'The requested MIDs are already attached to this user.',
      UnsuccessfulSignInNoMerchantAccounts: 'This username does not have access to this site.',
      UnsuccessfulReCapture: 'Invalid reCAPTURE entered, please try again.',
      UnsuccessfulReCaptchaV3: 'ReCaptcha has identified your actions as those of an automated bot. Please, try again.',
      UnsuccessfulInvoicePaymentToken: 'Declined: Error with token - please request new invoice.',
      NotImplemented: 'Not Implemented.',
      UnsuccessfulSendSms: 'Error: sending SMS',
      UnsuccessfulSendEmail: 'Error: sending Email',
      UnsuccessfulResetPassword: 'Account Number and/or SSN cannot be verified, or reset password token is no longer valid. Please try again.',
      UnsuccessfulSecurityPassword: 'Please choose another password. Password is too common or recently used.',
      UnsuccessfulSetPassword: 'Set password token is no longer valid. Please try again.',
      ValidateTokenError: 'Server Error: Validating Token',
      UpdateTransactionError: 'Server Error: Not able to update transaction',
      UpdateDisputeError: 'Server Error: Not able to update dispute',
      UpdateRoleError: 'Server Error: Not able to update role',
      ItemsPayloadTooLarge: 'ERROR: Upload too large: please try a smaller size',
      ItemsParsingError: 'ERROR: There was an issue delivering all your items',
      Idology: 'One or more answers were wrong, please try again',
      GetIdology: 'Unable to get questions',
      DecryptingTokenError: 'Server error decrypting token',
      CommunicationGetError: 'Unable to find communication preference.',
      CommunicationPostError: 'Please check your information and try again or contact Customer Service at 877.387.5640.',
      TokenValidationError: 'Token Validation Error',
      ApkChecksumError: 'Server Error: generating apk checksum ',
      ApkGetManifestInfoError: 'Server Error: generating apk getManifestInfo ',
      PlanUpgradeError: 'Server Error: Unable to upgrade your plan.',
      TryingForgotPassword: 'If this account exists an email has been sent, if you do not receive an email in the next 15 minutes try again.',
      TwoFactorLoginError: 'Error validating two factor code - please try again',
      InternalProcessingError: 'Sorry, internal processing error.',
      ExpiredToken: 'Expired Token',
      InvalidCSRF: 'We have updated our application. Please refresh your browser.',
      InvalidCharacters: 'Username only allows letters (a-z), numbers (0-9), periods (.), plus (+), underscores (_) and dash (-).'
    }
  },

  // PayAnywhere Supplies
  PayAnywhereSupplies: {
    AddToOrderLabel: 'Add to order',
    ErrorMessage: 'Error: Please enter a valid number within the limit.',
    NoSuppliesFoundLabel: 'No Supplies found',
    Order: 'Order:',
    OutOfStockLabel: 'Out of stock',
    ShowSuccessDialogTitle: 'Order Processed',
    SuccessDescription: 'Your payment has been received. A receipt will be emailed to {{username}}'
  },

  // Supplies Table
  AllotmentsTable: {
    ItemNameLabel: 'Item Name',
    OrderedLabel: 'Ordered',
    RemainingLabel: 'Remaining'
  },

  // Allotments
  AllotmentsPage: {
    NoAllotmentsFound: 'No Allotments found'
  },

  // Order History
  OrderHistoryPage: {
    NoOrdersFoundLabel: 'No Orders found',
    Orders: 'Orders',
    Items: 'items',
    OrderId: 'Order #{{id}}'
  },

  // Order History Table
  OrderHistoryTable: {
    ItemsOrderedLabel: 'Items Ordered',
    OrderDateLabel: 'Order Date',
    OrderIdLabel: 'Order #',
    OrderTotalLabel: 'Order Total',
    ShippingDateLabel: 'Shipping Date',
    TrackingLabel: 'Tracking',
  },

  // Order History Details
  OrderHistoryDetails: {
    Details: 'Details',
    ItemsOrdered: 'Items Ordered: {{itemsOrdered}}',
    NoData: 'No Orders Selected',
    OrderDate: 'Order Date: {{orderDate}}',
    OrderDetailsHeader: 'Order Details',
    OrderDetailsHeading: 'DELIVERY ADDRESS',
    OrderDetailsPurchases: 'Purchases',
    OrderInvoiceNumber: 'Invoice {{invoiceNumber}}',
    OrderNumber: 'Order #{{orderNumber}}',
    OrderShippingDate: 'Shipping Date: {{shippingDate}}',
    OrderTrackingNumber: 'Tracking #{{trackingNumber}}',
    OrderType: 'Order Type: {{orderType}}',
    Shipping: 'SHIPPING',
    TotalDue: 'Total Due'
  },

  // Order Summary
  OrderSummaryPage: {
    Checkout: 'Checkout: {{checkoutAmount}}',
    ClearCartButton: 'Clear Cart',
    OrderSummaryTitle: 'Order Summary'
  },

  // Checkout Supply
  CheckoutSupplyPage: {
    DebitMyAccountLabel: 'Debit My Account.',
    DebitMyAccountSubLabel: 'The total due will be debited from your merchant account.',
    DeliverTo: 'Deliver to',
    OrderCheckoutTitle: 'Checkout Supplies',
    PaymentMethod: 'Payment Method',
    PlaceOrderButton: 'Place Order',
    ShippingMethod: 'Shipping Method'
  },

  // ShippingAddress
  Business: 'Business',
  Corporate: 'Corporate',
  Shipping: 'Shipping',

  // Checkout Hardware
  CheckoutHardwarePage: {
    AmountDue: 'Amount Due: {{amountDue}}',
    OrderCheckoutTitle: 'Checkout Hardware',
    PayTitle: 'Pay by Card',
    PaymentError: 'Error processing payment: {{paymentError}}',
    SorryPaymentError: 'Sorry, something went wrong, please try again'
  },

  // Electronic Return Labels
  ElectronicReturnLabels: {
    Export: 'Export',
    NoData: 'No Return Labels',
    ReturnLabelName: 'Return Label #{{rmaId}}',
    ReturnLabelHeaderText: 'Place the old equipment in a box, print and place the Electronic Return Label on the box, and drop it off at a FedEx location.',
    ReturnLabelHeaderLocations: 'Find participating FedEx locations'
  },

  // Supplies Page
  SuppliesPage: {
    NoData: 'No available supplies',
    OrderProcessed: 'Order Processed',
    OrderSummaryDialogButton: 'Order: {{shoppingCartTotalAmount}}',
    Title: 'Hardware & Supplies',
    Total: 'Total: {{shoppingCartTotalAmount}}'
  },

  // Terminal Supplies
  TerminalSupplies: {
    AddToOrder: 'Add to order',
    ErrorMessage: 'Error: The limit for each supply is {{supplyLimit}}. Please enter a number within the limit.',
    NoData: 'No available supplies',
    RemainingAllotmentText: '{{count}} item remaining this quarter',
    RemainingAllotmentText_plural: '{{count}} items remaining this quarter'
  },

  // Reputation Project
  ReputationScreen: {
    QuickReplies: 'Quick replies',
    QuickRepliesDescription: 'Configure custom messages that can be selected when responding to customer reviews.',
    QuickReplyCreate: 'Create a quick reply',
    QuickRepliesPositive: 'Positive (4-5 stars)',
    QuickRepliesNeutral: 'Neutral (3 stars)',
    QuickRepliesNegative: 'Negative (1-2 stars)',
    QuickRepliesCreateTitle: 'Create a quick reply.',
    QuickRepliesCategory: 'Select Review Category',
    QuickRepliesMessage: 'Enter a Quick Reply Message',
    QuickRepliesMessageLimit: 'Limit {{limit}} characters.',
    QuickRepliesRemoveTitle: 'Remove quick reply.',
    QuickRepliesRemoveDescription: 'Are you sure you want to remove this quick reply?',
    QuickRepliesRemove: 'Yes, remove',
    QuickRepliesCancel: 'No, cancel',
    NoRatings: 'No ratings',
    Average: 'Average',
    Ratings: 'ratings',
    ReputationManagement: 'Reputation Management',
    Description: 'Connect your Google reviews and manage what people are saying about your business.',
    Hint: 'Don’t have a Google Business Profile? Set up your business on Google ',
    Here: 'here.',
    TakeMeLink: 'Take me to Reputation Management',
    Trends: {
      Title: 'See what customers are saying.',
      Subtitle: 'By connecting to your Google account, you’ll be able to see and manage your customers reviews.',
      Button: 'Get started',
      GetStarted: {
        View: 'View and respond to customer reviews.',
        Gain: 'Gain insights on positive versus negative review trends.',
        Add: 'Add your competition and see how you compare.'
      },
      ReputationChange: 'Reputation Change',
      ReputationChangeCaption: 'Trending {{trend}} from the previous period',
      ReputationChangeNoDataCaption: 'No data available for the selected range',
      Sentiment: 'Positive vs. Negative Reviews',
      SentimentCaption_zero: '',
      SentimentCaption: 'Reviews are {{count}}% positive',
      OverallSatisfaction: 'Overall Satisfaction Score',
      OverallSatisfactionCaption: 'Average from {{count}} total reviews',
    },
    BetaContainer: {
      Title: 'You\'re using a beta feature.',
      Description: 'This feature is currently undergoing pilot testing. Send your feedback to ',
      ThanksMessage: 'Thank you for your participation!'
    }
  },

  // Issue a credit
  CreditAmountLabel: 'Credit Amount',
  DebtRepaymentError: 'Please try another method of payment.',
  DuplicateConfirmationDialog: {
    Title: 'Duplicate Transaction',
    Description: 'A similar transaction with this card was recently authorized and continuing may result in a duplicate transaction. Continue with this transaction?'
  },
  IsIssueCreditDescription: 'Issuing a credit is not related to an existing transaction. For a refund or void related to an existing transaction, visit the <1>Transactions</1> page.',
  IssueCreditDialog: {
    Title: 'About issuing credits.',
    Description: 'Issue a credit that is not related to an existing transaction. For a refund or void related to an existing transaction, visit the Transactions page.',
    Note: 'Note that there are specific parameters surrounding this feature and your credit may be declined.'
  },
  IssueCreditButton: 'Issue credit',
  PaymentStatusMessageDialog: {
    SendReceiptButton: 'Send receipt',
    Title: 'Transaction has been successfully processed.',
    ViewInTransactionsButton: 'View in transactions',
    ContinueTransaction: 'Continue transaction',
    VoidTransaction: 'Void transaction',
    LoyaltyRewardEarned: '{{amount}} off reward earned!',
    LoyaltyRewardInfoFirst: 'of',
    LoyaltyRewardInfoSecond: '{{points_label}} earned towards {{amount}} off reward.'
  },
  InvoiceStatusMessageDialog: {
    Title: 'Invoice has been successfully created.',
    Error: 'Could not create invoice',
    ViewInInvoicesButton: 'View in invoices'
  },
  PrintReceiptButton: 'Print receipt',
  ProcessPaymentButton: 'Process payment',
  SubmitButtonClickedError: 'Please fill out all required fields.',
  RestrictedAccessDialog: {
    Title: 'Limited Access'
  },
  SaleAmountError: 'Sale amount must be greater than $0.00.',
  Summary: 'Summary',

  // Categories
  CategoriesPage: {
    CategoryName: 'Category Name',
    CategoryCount: '# of Items',
    Count: '{{count}} Item',
    Count_plural: '{{count}} Items',
    DeleteCategory: 'Delete category',
    MergeCategory: 'Merge category',
    NewCategory: 'New Category',
    NoData: 'No Categories',
    PageTitle: 'Categories',
    SearchCategories: 'Search Categories'
  },

  // Delete Confirmation Dialog
  DeleteConfirmationDialog: {
    ConfirmationText: 'Are you sure you want to delete this category? This action cannot be undone. Items within this group will not be deleted.',
    TitleText: 'Delete category.'
  },

  // Merge Category Dialog
  MergeCategoryDialog: {
    TitleText: 'Merge Categories',
    ConfirmText: 'Merge categories'
  },

  // Card Detail Form
  CardDetailForm: {
    CategoryNameLabel: 'Category Name',
    CategoryNameHint: 'Category Name'
  },

  // Create Menu
  CreateMenu: {
    NewCategory: 'New Category',
    NewDiscount: 'New Discount',
    NewItem: 'New Item',
    NewModifierSet: 'New Modifier set'
  },

  // Integrations - Gift Cards
  Factor4: {
    Details: 'By subscribing to Factor4 Gift Cards, you are adding the ability to run gift card transactions through your terminal. Factor4 will work with you to get your business set up with gift card processing and placing any gift card orders. By subscribing to Factor4, you agree to the processing fees of 3.5% and $0.50 per gift card issuance.',
    Title: 'Factor4 - Gift Cards',
    Description: 'Sell, redeem, and track gift cards to build brand awareness and increase revenue for your business.',
    PricePerLocation: '$X.XX/mo per location',
    PriceDetailsText: 'Pricing details here.',
    Subscribe: 'Subscribe to Factor 4 Gift Card Program',
    Unsubscribe: 'Unsubscribe from Factor 4 Gift Card Program',
    LaunchText: 'Launch factor4gift.com',
    HowItWorksText: 'See how it works'
  },

  // Devices
  DevicesPage: {
    Header: 'Grant sales partner devices access',
    Description: 'Allow my sales partner to view the account on my devices and update the payment and receipt settings on my behalf.',
    DenyTitle: 'Deny sales partner access.',
    ApproveTitle: 'Grant sales partner access',
    DenyText: 'Your sales partner will no longer be able to view or manage your devices. Are you sure?',
    ApproveText: 'Your sales partner will be able to view or manage your devices. Are you sure?',
    OkButtonConfirm: 'Yes, deny access',
    CloseButtonConfirm: 'No, allow access',
    CloseButtonDeny: 'No, do not grant access',
    OkButtonDeny: 'Yes, grant access',
    DeviceModel: 'Device model',
    DeviceType: 'Device type',
    PaVersion: 'PA version',
    PayanywhereVersion: 'Payanywhere version {{paVersion}}',
    Serial: 'Serial #{{serialNumber}}',
    LastHeartbeat: 'Last heartbeat',
    SearchDevices: 'Search Devices',
    LastHeartBeatDate: 'MM/DD/YYYY',
    LastHeartBeatText: 'Last heartbeat {{lastHeartBeatDate}} at {{lastHeartBeatTime}}',
    LastHeartBeatTextNotApply: 'Last heartbeat N/A',
    DeviceLocation: 'Location {{deviceLocation}}',
    NoDevicesConnected: 'No connected devices were found',
    DevicesConnected: 'Connected devices',
    BatteryPercentage: 'Battery {{batteryPercentage}}%',
    Config: 'Config {{config}}',
    TapToPayEnabled: 'Tap to Pay on iPhone Enabled'
  },
  CardinalDirections: {
    North: 'N',
    East: 'E',
    West: 'W',
    South: 'S'
  },

  //Beta Disclaimer
  BetaDisclaimer: {
    Title: 'You\'re using a beta feature.',
    Description: 'Submit feedback ',
    Link: 'here',
    Thanks: 'Thank you for your participation!'
  },

  //New Feature Announce
  NewFeature: {
    Title: 'Check this out!',
    Description: 'Tell us what you think. Submit feedback ',
    Link: 'here',
  },

  // Select a Language Dialog
  SelectLanguage: 'Select a language',

  // Sales Partner Notification
  SalesPartnerNotification: {
    Text: 'Your sales partner is set to view and manage devices for this account. You can change access in Business Settings - Devices',
    ManageAccess: 'Manage Access',
    GotItAccess: 'Got it'
  },

  // Loyalty Program
  LoyaltyPrograms: {
    Title: 'Loyalty Program',
    TitleSingular: 'Loyalty Program',
    LoyaltyStatus: '{{points_earned}} of {{points_to_earn_reward}} {{points_label}} earned towards a {{dollar}}{{reward_amount}}{{percent}} off reward',
    DashboardCard: {
      Title: 'Loyalty',
      Heading: 'Create more loyalty customers with our new rewards program. Join the Pilot today! ',
      CtaLabel: 'I\'m interested'
    },
    Previews: {
      Receipt: 'Receipt with Reward',
      Email: 'Rewards Email'
    },
    CustomMessageFooter: 'Appears on receipts and emails sent to customers who have earned a reward.',
    EnableVirtualCard: 'Enable Payanywhere Loyalty Program',
    VirtualCardDescription: '<p>Create a loyalty program by defining how your customers earn points and how they get rewarded.</p>',
    LoyaltyTermsLinkText: 'By enabling, you agree to the',
    LoyaltyTermsLink: 'Payanywhere Loyalty Program Terms.',
    LoyaltyTermsCheckboxLabel: 'I have read and agree to the Payanywhere Loyalty Program Terms.',
    ProgramName: 'Program Name',
    HintProgramName: 'Loyalty Program',
    PointsName: 'Points Name',
    Points: 'points',
    HintPointsName: 'e.g. points, stars',
    NumberPoints: 'Points Needed to Redeem',
    MinimumSpend: 'Spend Requirement',
    RewardAmountType: 'Discount Type',
    RewardAmount: 'Discount Amount',
    EarnedExpiration: 'Earned reward expiration',
    ExpireIn3Months: '3 months',
    ExpireIn6Months: '6 months',
    ExpireIn9Months: '9 months',
    ExpireIn12Months: '12 months',
    NotExpired: 'Does not expire',
    CustomMessage: 'Custom Reward Message',
    ViewPreview: 'View Preview',
    ViewSampleReceipt: 'View Sample Receipt',
    DeleteTitle: 'Are you sure you want to disable your loyalty program?',
    DeleteContent: 'Customers will no longer accumulate points. Their existing reward will remain active, unless you have set an expiration on the earned reward.',
    Yes: 'Yes, disable',
    No: 'No',
    UpdateTitle: 'Are you sure you want to change the loyalty program?',
    UpdateContent: 'This will change the program for customers who are already earning points.',
    ReEnableTitle: 'Are you sure you want to enable the loyalty program?',
    ReEnableContent: 'This will reactivate points and rewards customers earned previously.',
    RewardEmailHeader: 'Erica, you’ve earned a reward!',
    RewardDiscountText: 'off your next purchase.',
    RewardQrText: 'Present QR code or discount code at the time of sale.',
    RewardExpires: 'Reward expires',
    RewardCustomMsg: 'Custom message here.',
    PreviewProgram: 'Preview your loyalty program',
    MarketingText: {
      First: 'Customers earn points automatically. No sign-up needed.',
      Second: 'Customize your own point structure and create multiple rewards.',
      Third: 'Provide customers with a digital loyalty card and a a personalized rewards site.'
    },
    ActivateLoyaltyAddOn: 'Activate Loyalty in User Settings',
    GeneralPointRuleDialog: {
      Title: 'Point Rules',
      Text: 'Point rules apply to all subsequent created rewards. Editing a point rule will not affect an existing reward.',
      GeneralPointRule: 'General Point Rule',
      EarnByVisit: 'Earn by Visit',
      DescriptionFirstPart: 'Earn 1 point by visit.',
      DescriptionSecondPart: 'minimum spend.',
      TapEdit: 'Tap "Edit" to Configure',
      Edit: 'Edit'
    },
    RewardDialog: {
      Title: 'Create a Reward',
      Text: 'Reward will be an entire purchase discount.',
      Rewards: 'Rewards',
      OffEntirePurchase: 'Off Entire Purchase',
      Expires: 'Expires',
      DoesNotExpire: 'Does not expire',
      NoRewards: 'No Rewards',
      Edit: 'Edit',
      Create: 'Create Reward'
    },
    CustomerDirectoryHeader: {
      TotalCustomers: 'Total Customers',
      RepeatCustomers: 'Repeat Customers',
      RewardsRedeemed: 'Rewards Redeemed',
      TotalRewardDiscountVolume: 'Total Reward Discount Volume'
    },
    Clp: {
      InvalidReward: 'The reward code you entered is invalid or has expired',
      DiscountOrReward: 'Discount or reward',
      RemoveReward: 'Remove',
      DiscountOrRewardCode: 'Discount or Reward Code',
      ApplyReward: 'Apply discount or reward code',
      Apply: 'Apply',
      Status: '{{points_earned}} of {{points_to_earn_reward}} {{points_label}} earned towards your {{dollar}}{{reward_amount}}{{percent}} off reward',
      PresentText: 'Present QR code or discount code at the time of your next sale. Find it on your receipt or in your email.',
      Earned: 'You’ve earned a {{dollar}}{{reward_amount}}{{percent}} off reward!',
      Error: 'Error validating the code',
      HintText: 'Enter reward code'
    },
    LoyaltyExpires: '| Expires',
    RewardEarned: 'Reward Earned',
    ResendEmail: 'Resend Reward Email',
    SendEmailSuccess: 'Reward Email Sent',
    SendEmailFailure: 'There was a problem sending your Reward Email',
    CharactersLeft: ' characters left.'
  },

  LoyaltyProgramSettings: {
    Title: 'Loyalty Program Settings',
    Text: 'Preview your loyalty program',
    View: 'View',
    TollFreeNumber: {
      Title: 'Create Loyalty Toll-Free Number',
      Text: 'Create an autogenerated toll-free number to send your customers SMS messages with their loyalty point status and reward information.',
      DisclaimerPartOne: 'Some business types may not be eligible to send SMS under the ',
      DisclaimerPartTwo: ' (sex, hate, alcohol, firmwares, and tobacco/cannabis rule).',
      Number: 'Your Toll Free Number'
    },
    Website: {
      Title: 'Business Website (Required)',
      DisclaimerPartOne: 'A business website is required for a toll free number. Adding or editing your business website will also update the field on ',
      DisclaimerPartTwo: 'Receipt Settings.',
      Button: 'Generate Toll Free Number'
    },
    VerificationPending: {
      Title: 'Your Toll Free Number is Pending',
      Text: 'This number is pending verification and may take up to 3 weeks to complete the verification process. Messages will not be sent until your toll-free number is verified.'
    },
    VerificationRejected: {
      Title: 'Your Toll Free Number was Rejected',
      TextPartOne: 'We were unable to generate a toll-free number based on the information provided. To learn more, click ',
      TextPartTwo: 'here.'
    }
  },

  TermsMerchantError: {
    ModalTitle: 'Error retrieving merchant',
    ModalText: 'Unable to retrieve merchant information. Please contact Customer Service at 877.464.4218 or support@paymentshub.com to update your information. Click Okay to log out.'
  },

  // Scheduled Reports
  ScheduledReports: {
    Scheduled: 'Scheduled',
    EditSchedule: 'Edit Schedule',
    Schedule: 'Schedule',
    Title: 'Schedule Report.',
    Text: 'Automatically receive this report via email for the frequency and date range that you choose. Time range is based on your global settings.',
    ScheduleReport: 'Schedule',
    Report: 'report',
    SendTo: 'Send To',
    AdditionalEmail: 'Additional Recipient (optional)',
    AdditionalEmailHint: 'Enter email address',
    Frequency: 'Frequency',
    SendOnThe: 'Send on the',
    SendOn: 'Send on',
    DailyReport: 'Daily Report',
    WeeklyReport: 'Weekly Report',
    MonthlyReport: 'Monthly Report',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    '1st': '1st',
    '2nd': '2nd',
    '3rd': '3rd',
    '4th': '4th',
    '5th': '5th',
    '6th': '6th',
    '7th': '7th',
    '8th': '8th',
    '9th': '9th',
    '10th': '10th',
    '11th': '11th',
    '12th': '12th',
    '13th': '13th',
    '14th': '14th',
    '15th': '15th',
    '16th': '16th',
    '17th': '17th',
    '18th': '18th',
    '19th': '19th',
    '20th': '20th',
    '21st': '21st',
    '22nd': '22nd',
    '23rd': '23rd',
    '24th': '24th',
    '25th': '25th',
    '26th': '26th',
    '27th': '27th',
    '28th': '28th',
    '29th': '29th',
    '30th': '30th',
    '31st': '31st',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'
  },

  // Frequency
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',

  // No Data Messages
  NoDataMessage: 'No Data',
  NoDataAccountSummary: 'No Account Summary Data',
  NoDataBatchReport: 'No Batch Report',
  NoDataBatchSummaryReport: 'No Batches Summary',
  NoDataCardBrandSummaryReport: 'No Card Brand Summary for this period',
  NoDataCustomerExport: 'No Customers',
  NoDataLoyaltyExport: 'No Loyalty Data',
  NoDataLoyaltyNeverEnabled: 'Set up your loyalty program today under Customers',
  NoDataCustomerExperienceReport: 'No Customer Ratings',
  NoDataCustomerPerformanceReport: 'No Customer Performance for this period',
  NoDataDeclinedTransactionExport: 'No Transactions',
  NoDataDeclinedTransactionExportIsLargeProcessor: 'Unable to display the amount of data returned in your selection. Please click export to view your report.',
  NoDataDepositDetailsReport: 'No Deposit Details',
  NoDataDepositSummaryReport: 'No Deposits',
  NoDataDepositTransactionsReport: 'Unable to display the amount of data returned in your selection. Please click export to view your report.',
  NoDataDialog: {
    Title: 'No Report Generated',
    Message: 'No data for this period of time'
  },
  NoDataDisputesReport: 'No Disputes',
  NoDataEmployeePerformanceReport: 'No Employee Performance data for this period',
  NoDataExpandedTransactionReport: 'No Transactions',
  NoDataExpandedTransactionReportIsLargeProcessor: 'Unable to display the amount of data returned in your selection. Please click export to view your report.',
  NoDataFlashReport: 'No Flash Report Data',
  NoDataFraudReport: 'No Fraud Report Data',
  NoDataItemExport: 'No Items',
  NoDataItemizedExport: 'No Itemized Report Data',
  NoDataModifierPerformance: 'No Modifier Performance data for this period',
  NoDataPaymentTypeSummary: 'No Card Brand Summary for this period',
  NoDataProductSalesReport: 'No Product Sales Report Data',
  NoDataRefundSummaryReport: 'No Refunds',
  NoDataTaxExport: 'No Taxes for this period',
  NoDataTransactionExport: 'No Transactions',
  NoDataTransactionExportIsLargeProcessor: 'Unable to display the amount of data returned in your selection. Please click export to view your report.',
  NoDataTransactionsCategoryExport: 'No Transactions',
  NoDataTransactionsItemExport: 'No Transactions',
  NoReports: 'No Reports',

  UpgradePremiumPlus: {
    ModalTitle: 'Manage your Payments Hub plan.',
    TitlePremium: 'is a Premium feature.',
    TitlePremiumPlus: 'is a Premium Plus feature.',
    UpgradeButtonPremiumPlus: 'Upgrade to Premium Plus',
    UpgradeButtonPremiumPlusLoyalty: 'Try out Loyalty',
    UpgradeButtonPremium: 'Upgrade to Premium',
    PlansButton: 'See All Plans',
    ChooseButton: 'Choose',
    UpdateButton: 'Update Plan',
    CurrentPlan: 'Current Plan',
    Loyalty: {
      TopLeft: 'Build incentives that keep customers coming back.',
      TopRight: 'Customers enroll and earn points on qualified purchases.',
      BottomLeft: 'Customize your point structure and program name.',
      BottomRight: 'Rewards are sent automatically and visible at the time of sale.'
    },
    Reputation: {
      TopLeft: 'See what your customers are saying.',
      TopRight: 'View and respond to customer reviews.',
      BottomLeft: 'Gain insights on positive versus negative review trends.',
      BottomRight: 'Add your competition and see how you compare.'
    },
    EnhancedInvoices: {
      TopLeft: 'Send one time or recurring invoices and track with ease.',
      TopRight: 'Customers can pay without creating an account.',
      BottomLeft: 'Schedule invoices, save cards on file, and enable autopay.',
      BottomRight: 'Brand your own invoices and customer payment portal.'
    },
    PaymentLinks: {
      TopLeft: 'A simple way for customers to pay, from any place and device.',
      TopRight: 'Paste a link, embed a button, or share a QR code.',
      BottomLeft: 'Brand your own payment page with an endless amount of uses.',
      BottomRight: 'Customize multiple campaigns and track by usage and volume.'
    }
  },

  Plan: {
    Free: {
      Label: 'Free',
      Price: '$0.00/month',
      SubHeading: 'Basic account management.',
      BulletOne: {
        One: 'Transaction Activity',
        Two: 'Statements',
        Three: 'Chargeback Management',
        Four: 'Virtual Terminal'
      }
    },
    Premium: {
      Label: 'Premium',
      Price: '$14.95/month',
      SubHeading: 'Account management plus powerful business tools.',
      BulletOne: {
        One: 'Free Supplies',
        Two: 'Terminal Management',
        Three: 'Factor 4 Gift Card',
        Four: 'Reputation Management'
      },
      BulletTwo: {
        One: 'Transaction Activity',
        Two: 'Statements',
        Three: 'Chargeback Management',
        Four: 'Virtual Terminal'
      }
    },
    Paid_Plus: {
      Heading: 'Recommended',
      Label: 'Premium Plus',
      Price: '$29.95/month',
      SubHeading: 'All of Premium plus advanced billing tools.',
      SwitchText: 'With Payanywhere Loyalty',
      TooltipText: 'Consult with your Sales Partner if you would like to use Payanywhere Loyalty.',
      SwitchSubtext: '+ $15.00/month',
      BulletOne: {
        One: 'Enhanced Invoicing',
        Two: 'Recurring Payments',
        Three: 'Save Cards on File'
      },
      BulletTwo: {
        One: 'Free Supplies',
        Two: 'Terminal Replacement',
        Three: 'Factor 4 Gift Card',
        Four: 'Reputation Management'
      },
      BulletThree: {
        One: 'Transaction Activity',
        Two: 'Statements',
        Three: 'Chargeback Management',
        Four: 'Virtual Terminal'
      }
    },
    Paid_Plus_Loyalty: {
      Heading: 'Recommended',
      Label: 'Premium Plus',
      Price: '$29.95/month',
      SubHeading: 'All of Premium plus advanced billing tools.',
      SwitchText: 'With Payanywhere Loyalty',
      TooltipText: 'Consult with your Sales Partner if you would like to use Payanywhere Loyalty.',
      SwitchSubtext: '+ $15.00/month',
      BulletOne: {
        One: 'Enhanced Invoicing',
        Two: 'Recurring Payments',
        Three: 'Save Cards on File'
      },
      BulletTwo: {
        One: 'Free Supplies',
        Two: 'Terminal Replacement',
        Three: 'Factor 4 Gift Card',
        Four: 'Reputation Management'
      },
      BulletThree: {
        One: 'Transaction Activity',
        Two: 'Statements',
        Three: 'Chargeback Management',
        Four: 'Virtual Terminal'
      }
    },
  },

  UpgradePlanModal: {
    Title: 'Upgrade Plan',
    Body: 'Are you sure you want to upgrade to {{plan}} for {{price}}/mo.?',
    Button: 'Upgrade'
  },

  UpgradePlanModalLoyalty: {
    Title: 'Add Loyalty',
    Body: 'Are you sure you want to add Loyalty?',
    Button: 'Add Loyalty'
  },

  DowngradePlanModalLoyalty: {
    Title: 'Downgrade Plan',
    Body: 'You are removing loyalty from Premium Plus. You will no longer have access to the Payanywhere loyalty program. Are you sure you want to continue?',
    Button: 'Downgrade'
  },

  DowngradePlanModal: {
    Title: 'Downgrade Plan',
    Body: 'You are downgrading to {{plan}} for {{price}}/month. You will no longer have access to invoicing, recurring billing, and card on file, and integrations. Are you sure you want to continue?',
    Button: 'Downgrade'
  },

  SuccessUpgradePlanModal: {
    Title: 'Plan Upgraded',
    Body: 'You have successfully switched your plan to {{plan}}.'
  },

  SuccessDowngradePlanModal: {
    Title: 'Plan Updated',
    Body: 'You have successfully opted out of {{oldPlan}} and downgraded to the {{plan}} plan.'
  },

  ErrorPlanModal: {
    Title: 'Plan Update Error',
    Body: 'There was an error updating your plan. Please contact Customer Service at {{phone}} or {{email}} or try again later.'
  },
  'Payment Links': 'Payment Links',
  PaymentLink: 'Payment Link',
  NoPaymentLinks: 'No Payment Links',
  CreatePaymentLink: 'Create Payment Link',
  ActiveLinks: 'Active Links',
  InactiveLinks: 'Inactive Links',
  'Active Payment Links': 'Active Payment Links',
  'Inactive Payment Links': 'Inactive Payment Links',
  TotalUses: 'Total Uses',
  TotalVolumeGenerated: 'Total Volume Generated',
  PaymentLinksColumns: {
    Title: 'Link Title',
    Type: 'Type',
    Created: 'Created',
    Amount: 'Amount',
    Uses: 'Uses',
    VolumeGenerated: 'Volume Generated'
  },
  PaymentLinkTypes: {
    multipleAmount: 'Multiple Amounts',
    multiple: 'Multiple Amounts',
    openEnded: 'Open Ended',
    open: 'Open Ended',
    fixedAmount: 'Fixed Amount',
    fixed: 'Fixed Amount'
  },
  PaymentLinks: {
    CreateForm: {
      TitleLabel: 'Title',
      TitleHint: 'E.g. "Donations"',
      SampleTitle: 'Donations',
      DescriptionLabel: 'Description (Optional)',
      DescriptionHint: 'Enter a custom message for your customers.',
      ImageLabel: 'Image (Optional)',
      ImageUpload: 'Drop Image or Choose File',
      PaymentTypeLabel: 'Payment Type',
      PaymentTypes: {
        FixedAmount: 'Fixed Amount',
        FixedAmountDescription: 'Define a single payment amount that every customer pays.',
        MultipleAmount: 'Multiple Amount',
        MultipleAmountDescription: 'Define up to three dollar amounts that customers select from.',
        OpenEnded: 'Open Ended',
        OpenEndedDescription: 'Customers define the payment amount through an open field.'
      },
      PaymentAmountLabel: 'Payment Amount',
      PaymentAmountLabelOptional: 'Payment Amount (Optional)',
      MinimumAmount: 'Minimum Payment Amount (Optional)',
      MaximumAmount: 'Maximum Payment Amount (Optional)',
      EnableTaxLabel: 'Enable Tax',
      AcceptTipsLabel: 'Accept Tips',
      CashDiscountingLabel: 'Cash Discounting',
      CustomFieldLabel: 'Add Custom Field (Optional)',
      CustomFieldHint: 'Field Label',
      CustomFieldRequired: 'Required Field',
      RedirectUrlLabel: 'Redirect URL (Optional)',
      RedirectUrlHint: 'E.g. "Your Business Website URL"',
      RedirectUrlDescription: 'Redirect customers to your website after a succesful payment or landing on a deactivated link.',
      CustomAmountLabel: 'Allow custom amount',
      Preview: 'Preview',
      Cancel: 'Cancel',
      CreateLink: 'Create Link',
      Success: 'Payment Link Successfully Created',
      ViewDetails: 'View Payment Link Details',
      ToggleTaxDescription: 'You can manually set a tax rate or auto-detect by location of transaction. Manage tax options in <1>Business Settings</1>.'
    },
    Sharing: {
      Title: 'Sharing Payment Links',
      CopyUrl: 'Copy and paste your Payment Link URL and share it via email, SMS text message, or social media post.',
      Embed: 'Embed a Payment Link Button onto your website by copying and pasting the autogenerated code.',
      QRCode: 'Download the Payment Link QR code and have customers scan to pay with their mobile devices.',
      Manage: 'Manage the payment links you have created on the <1>Payment Links</1> page.'
    },
    Detail: {
      Heading: 'Payment Link Details',
      DownloadQR: 'Download QR Code',
      CopyURL: 'Copy Payment Link URL',
      ViewTransactions: 'View Transactions',
      Deactivate: 'Deactivate Payment Link',
      Reactivate: 'Reactivate Payment Link',
      LinkInformation: 'Link Information',
      NoDescription: 'No description',
      PaymentInformation: 'Payment Information',
      TotalUses: 'Total Uses',
      TotalVolumeGenerated: 'Total Volume Generated',
      PaymentLinkButton: 'Payment Link Button',
      Preview: 'Preview',
      PayNow: 'Pay Now',
      Created: 'Created {{date}} at {{time}} by {{createdBy}}',
      Cancel: 'Cancel',
      SaveChanges: 'Save Changes',
      CDEnabled: 'Cash Discounting Enabled',
      InactiveLink: 'Inactive Link',
      DeactivateTitle: 'Deactivate Payment Link',
      DeactivateDescription: 'Are you sure you want to deactivate this payment link? Customers will no longer be able to access your payment page and make a payment. You can reactivate this link later from the "Inactive Payment Links" filter.'
    },
    PaymentLinkTitle: 'Payment Link Title',
    PaymentLinkURL: 'Payment Link URL',
    CopyURLSuccess: 'Payment Link URL copied successfully.',
    CopyURLError: 'Payment Link URL could not be copied.',
    DownloadQRError: 'QR code cannot be downloaded.',
    CopyCodeSuccess: 'Payment Link Button copied successfully.',
    CopyCodeError: 'Payment Link Button could not be copied.',
    FirstPaymentLink: {
      PageTitle: 'Create your first Payment Link.',
      Card1: {
        Title: 'Click the "Create Payment Link" button.',
        Text: 'Generate a branded payment page and create a simple way for customers to pay from any place and device.'
      },
      Card2: {
        Title: 'Share your Payment Link.',
        Text: 'Once created, you can paste your link, embed a button, or share a QR code to drive customers to your payment page.'
      },
      Card3: {
        Title: 'Create another!',
        Text: 'Customize multiple campaigns and track by usage and volume. You can manage all your payments links from this page.'
      }
    }
  },

  ExitAction: 'Exit',

  Activation: {
    Title: 'Activate Account',
    AccountAlreadyStartedAnApplication: 'You have already started an application. To activate your account, please contact your Sales Partner.',
    ActivationPending: 'Activation Pending',
    ShowLessHardware: 'Show Less Hardware',
    ShowMoreHardware: 'Show More Hardware',
    ProceedNoHardware: 'Proceed without Hardware',
    MaxDevices: 'Max {{maxDevices}} Devices',
    Flow: {
      Step1: {
        Title: 'Let\'s get to know you.',
        Action: 'Save and Continue',
        OwnerName: 'Owner Full Name',
        Dob: 'Date of Birth',
        SocialNumber: 'Social Security Number',
        Ownership: 'Equity Ownership',
        Address: 'Home Address',
        Apt: 'Apt. or Suite',
        City: 'City',
        State: 'State',
        Zip: 'Zip Code',
        Owner: 'Owner',
        PrimaryOwner: 'This owner is the primary contact',
        AddOwner: 'Add Another Owner',
        RemoveOwner: 'Remove owner',
        Hint: {
          Dob: 'MM/DD/YY',
          SocialNumber: '000 00 0000',
          MobileNumber: 'Enter your mobile number',
          Ownership: '1-100%',
          Address: 'Enter street address',
          Apt: 'Optional',
          City: 'Enter city',
          State: 'Select',
          Zip: 'Enter zip'
        }
      },
      Step2: {
        Title: 'Tell us about your business.',
        SecondTitle: 'How do you want to get paid?',
        Action: 'Save and Continue',
        LegalBusinessName: 'Legal Business Name',
        BusinessType: 'Business Type',
        FederalTaxIDSoleProprietor: 'SSN',
        FederalTaxID: 'Federal Tax ID',
        MerchantCategoryCode: 'Merchant Category Code',
        BusinessEmail: 'Business Email Address',
        BusinessPhone: 'Business Phone Number',
        DepositAccountType: 'Deposit Account Type',
        DepositAccountDescription: 'Used to deposit the money you make on each credit card sale and debit your service costs.',
        DepositToolTip: 'On the bottom of a check, find the routing number on the left and the bank account number on the right.',
        TaxIdToolTip: 'If you are a Sole Proprietor who uses your SSN as your Federal Tax ID, enter your SSN here.',
        PrimaryBusinessModel: 'Primary Business Model',
        CardPresent: 'Card Present',
        Keyed: 'Keyed',
        Moto: 'Moto',
        Internet: 'Internet',
        BusinessWebsite: 'Business Website',
        Hint: {
          LegalBusinessName: 'Enter legal business name',
          DBAName: 'Enter "doing business as" name',
          FederalTaxID: '00-0000000',
          FederalTaxIDSoleProprietor: '000-00-0000',
          MerchantCategoryCode: 'Search for your specific industry...',
          BusinessEmail: 'Enter business email address',
          AccountNumber: '4-17 digits',
          RoutingNumber: '9 digits',
          BusinessWebsite: 'Enter a valid website'
        }
      },
      Step3: {
        Title: 'Get a 60-day free trial of Premium Plus.',
        SubTitle: 'You can change your plan when your trial period has expired.',
        Action: 'Add Hardware'
      },
      Step4: {
        Title: 'Add Hardware',
        SubTitle: 'Optional. You can add hardware and accessories later.',
        Action: 'Review and Finish',
        ActionWithoutHardware: 'Proceed without Hardware',
      },
      Step5: {
        Title: 'Review and Finish',
        MerchantApplicationTitle: 'Merchant Application',
        MerchantApplicationCheckboxLabel: 'I have reviewed and confirm the accuracy of the information provided as part of the Merchant Processing Application, including any guaranty therein.',
        MerchantProcessingTitle: 'Merchant Processing Agreement',
        MerchantProcessingSubTitle: 'Updated 11/22/2022',
        MerchantProcessingCheckboxLabel: 'I have reviewed and agree to the Merchant Processing Agreement Terms, including the class action waiver and arbitration provision.',
        ScrollDown: 'Scroll to bottom to continue',
        Pricing: 'per dip, tap, or swipe',
        SeePricingDetails: 'See Pricing Details',
        SeeDevicePricingDetails: 'See Device Pricing',
        PlanType: 'Premium Plus Plan',
        PlanTypePricing: '60-day free trial, then $29.95/mo',
        Device: '{{count}} Device',
        Device_plural: '{{count}} Devices',
        Finish: 'Finish',
        DownloadMerchantApplication: 'Download Merchant Application'
      }
    },
    BankAccountVerificationModal: {
      Title: 'Bank Account Verification',
      Description: 'We were unable to verify the banking information you have provided. Please try again or upload an image of a voided check with your banking information clearly visible.',
      DropFile: 'Drop File or ',
      ChooseFile: 'Choose File',
      DropFileFormat: '.jpeg, .png, .gif, .tiff, .pdf Max file size 5MB',
      Uploading: 'Uploading...',
      ContactDescription: 'We were unable to verify the banking information you have provided. If you believe this is in error, please contact',
      ContactEmail: 'cssales@north.com'
    },
    Close: {
      Title: 'Confirm Exit',
      Message: 'We\'ll save your progress so you can come back to complete your account activation.',
      Proceed: 'Save and Close'
    },
    Button: {
      Activate: 'Activate',
      ActivateAccount: 'Activate Account',
      ActivateNow: 'Activate Now'
    }
  },

  // Add-ons
  AddOns: {
    Title: 'Add Ons (Requires Premium Plus Plan)',
    Loyalty: {
      Title: 'Payanywhere Loyalty',
      Pricing: '$15/month',
      FreeTrialExpiration: 'Free Trial ends ',
      Activated: 'Activated',
      GoToLoyalty: 'Go to Loyalty',
      Deactivate: 'Deactivate',
      Activate: 'Activate',
      ActivationDialog: {
        Title: 'Activate Payanywhere Loyalty',
        BodyFirstPart: 'Do you want to add Payanywhere Loyalty to your Premium Plus Plan for an additional $15/month?',
        BodySecondPart: 'Payanywhere Loyalty is only available to merchants on Payments Hub Premium Plus with Payanywhere equipment processing on EPX.'
      },
      DeactivationDialog: {
        Title: 'Deactivate Payanywhere Loyalty',
        BodyFirstPart: 'Are you sure you want to remove Payanywhere Loyalty from your Premium Plus Plan? You will no longer be able to access this feature.',
        BodySecondPart: 'The additional $15/month for Payanywhere Loyalty will cease billing after your next billing cycle.'
      }
    }
  },

  PlanDisclaimer: {
    SeeDetails: 'See All Plan Details',
    Compare: 'Compare Plans',
    Free: '*Some features are only available to merchants with certain equipment and processors. Contact your sales rep or call {{phone}} to see which features you are eligible for or to update your account/equipment.',
    PremiumPlus: '**Payanywhere Loyalty is available at an additional cost to merchants on Payments Hub Premium Plus with Payanywhere equipment processing on EPX.'
  },
  'or': 'or',
  LoyaltyActivity: {
    Title: 'Activity & Reports',
    DataError: 'An error occurred while loading the data',
    TopLoyaltyCustomers: 'Top Loyalty Customers',
    RewardPerformance: 'Reward Performance',
    DoesNotExpire: 'Does not expire',
    TotalCustomersTitle: 'Total Loyalty Customers',
    TotalCustomersFooter: 'Customers Participating in Loyalty',
    LoyaltyPointsTitle: 'Loyalty Points',
    LoyaltyPointsFooter: 'Accumulated vs. Redeemed',
    RedemptionsTitle: 'Redemptions',
    RedemptionsBody: 'Rewards',
    RedemptionsFooter: 'Total Value',
    RepeatCustomersTitle: 'Repeat Customers',
    RepeatCustomersUpward: 'Trending Upward',
    RepeatCustomersDownward: 'Trending Downward',
    RepeatCustomersNoData: 'No data available for the selected range',
    LoyaltySpendTitle: 'Loyalty Spend',
    LoyaltySpendFooter: 'Transactions with Loyalty',
    AverageSpendTitle: 'Average Spend',
    AverageSpendFooter: 'Per Transaction with Loyalty',
    Name: 'Name',
    Points: 'Points',
    Redemptions: 'Redemptions',
    Transactions: 'Transactions',
    VolumeSpent: 'Volume Spent',
    RewardName: 'Reward Name',
    Expires: 'Expires',
    VolumeRedeemed: 'Volume Redeemed'
  },

  EnrollInLoyalty: {
    TitleFirstPart: 'earn',
    TitleSecondPart: 'on every purchase.',
    Subtitle: 'Earn rewards with',
    Enroll: 'Enroll in Loyalty Program',
    ButtonText: 'Enroll',
    FormField: 'Loyalty Mobile Number',
    FormPlaceholder: 'Enter Mobile Number',
    FormDisclaimer: 'I agree to receive receipts and loyalty status (if enrolled), including points and rewards earned via SMS text. Message frequency varies. Msg & data rates may apply. Reply STOP to stop or HELP for help.'
  },

  ApplePayErrorDialog: {
    Title: 'Apple Pay Error',
    Body: 'Something went wrong with Apple Pay. Please refresh the browser if the issue persists.'
  },

  NorthGatekeeper: {
    Title: 'Activate your account for access to payments and much more.',
    Description: 'You’re in demo mode. Activate now to get full access North’s payment and business tools.',
    ActivateYourAccount: 'Activate Your Account'
  },

  NorthDashboardBanner: {
    Activate: 'Activate',
    Approved: {
      Title: 'Welcome to North',
      MobileTitle: 'Welcome to North',
      Description: {
        Default: 'Your application was approved and you are now able to accept payments.',
        PendingLogOut: 'Your application was approved. Log out and log back in to start accepting payments.',
      },
      MobileDescription: {
        Default: 'You are now able to accept payments.',
        PendingLogOut: 'Log out and log back in to start accepting payments.',
      },
      Action: {
        Default: 'Got it',
        PendingLogOut: 'Log Out',
      },
    },

    Declined: {
      Title: 'There was a problem with your application.',
      Description: 'We were unable to approve your application. Please contact us for more information.',
      MobileTitle: 'We were unable to approve your application',
      MobileDescription: 'Please contact us for more information.',
      Action: 'Contact Us'
    },
    GetPayanywhere: 'Get Payanywhere',
    FirstLanding: {
      Title: 'Get your business up and running in minutes.',
      Description: 'You’re in Demo Mode. Activate your account to accept payments and use every feature.',
      MobileTitle: 'You’re in Demo Mode',
      MobileDescription: 'Activate your account to accept payments.',
    },
    DemoPayment: {
      Title: 'Go beyond Demo Mode. Accept a real payment.',
      Description: 'Activate your account to enable payments and complete your transaction.',
      MobileTitle: 'Go beyond Demo Mode',
      MobileDescription: 'Activate your account to enable payments.',
    },
    IncompleteApplication: {
      Title: 'Continue where you left off.',
      Description: 'You\'re just minutes away from full access to payment acceptance and business tools.',
      MobileTitle: 'Continue where you left off',
      MobileDescription: 'You\'re just minutes away from full access to payment acceptance and business tools.'
    },
    HighRiskApplication: {
      Title: 'We’ll talk soon!',
      Description: 'Your business types requires some extra verification. We’ll reach out within 24 hours, or give us a call at 866.611.4225.',
      MobileTitle: 'Your business types requires some extra verification',
      MobileDescription: 'We’ll reach out within 24 hours, or give us a call at 866.611.4225.',
    },
    PendingApproval: {
      Title: 'Watch your inbox!',
      Description: 'Your application was submitted. You’ll receive an email with your approval status within 24 hours.',
      MobileTitle: 'Your application was submitted',
      MobileDescription: 'You’ll receive an email with your approval status within 24 hours.',
    },
    DemoPaymentCard: 'Demo Payment',
    ChooseAPlan: 'Choose a Plan',
    AddBusinessInfo: 'Add Business Info',
    AddHardware: 'Add Hardware'
  },

  PageNotFound: {
    Title: 'Page Not Found',
    Subtitle: 'Sorry, but this page does not exist.'
  },

  Signup: {
    Label: {
      FullName: 'Full Name',
      EmailAddress: 'Email Address',
      MobileNumber: 'Mobile Number',
      Password: 'Password',
      ConfirmPassword: 'Confirm Password',
    },
    Hint: {
      EnterFullName: 'Enter your full name',
      EnterEmailAddress: 'Enter your email address',
      EnterMobileNumber: 'Enter your mobile number',
      CreatePassword: 'Create a password',
      ConfirmPassword: 'Confirm password',
    },
    Link: {
      ExistingAccount: 'Log In Existing Account',
      NorthDeveloper: 'Go to North Developer'
    },
    Heading: 'Create an account for instant access.',
    CreateAccount: 'Create Account',
    CreatingAccount: 'Creating',
    TsAndCs: {
      Start: 'I have read and agreed to the',
      Terms: 'Terms of Use',
      And: 'and the ',
      PrivacyPolicy: 'Privacy Policy'
    },
    Mfa: {
      Title: 'Enter verification code.',
      Description: 'Enter the verification code sent to your mobile number ending in {{phoneNumLastFourDigits}}. Request a new code or try another verification method if needed.',
      ResendCode: 'Resend code',
      PhoneVerify: 'Get Code by Voice Call',
      EmailVerify: 'Get Code by Email'
    }
  },

  ActivationPlans: {
    Footer: 'Design a pricing plan specific to your business.',
    FooterLink: 'Build a Custom Plan',
    Free: {
      Type: 'Free',
      Price: '$0.00/mo',
      Heading: 'Basic Payment Acceptance',
      SubHeading: '2.69% per dip, tap, or swipe',
      Feature1: 'In-Person Payments',
      Feature2: 'Next Day Funding',
      Feature3: 'Tap to Pay on iPhone',
      Feature4: 'Virtual Terminal',
      Feature5: 'Chargeback Management',
      Feature6: 'Inventory Management',
      Feature7: 'Unlimited Users',
      Feature8: 'Access to Developer APIs'
    },
    Premium: {
      Type: 'Premium',
      Price: '$14.95/mo',
      Heading: 'Powerful Business Tools',
      SubHeading: '2.69% per dip, tap, or swipe',
      Feature1: 'Free Supplies',
      Feature2: 'Terminal Replacement',
      Feature3: 'Full Integrations Access',
      Feature4: 'Reputation Management',
      Feature5: 'Plus all Free features',
      SeeMore: {
        Heading: 'See More',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
      }
    },
    PremiumPlus: {
      Type: 'Premium Plus',
      Price: '$29.95/mo',
      Heading: 'Advanced Ecommerce Tools',
      SubHeading: '2.69% per dip, tap, or swipe',
      Feature1: 'Enhanced Invoicing',
      Feature2: 'Recurring Payments',
      Feature3: 'Save Cards on File',
      Feature4: 'Loyalty Program (+$15/mo)',
      Feature5: 'Plus all Premium features',
      SeeMore: {
        Heading: 'See More',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
      }
    }
  },
  ActivationHardware: {
    Devices: {
      SmartFlex: {
        Title: 'Smart Flex',
      },
      SmartTerminal: {
        Title: 'Smart Terminal',
      },
      SmartTerminalMini: {
        Title: 'Smart Terminal Mini',
      },
      SmartPos: {
        Title: 'Smart POS+'
      },
      SmartPinPadPro: {
        Title: 'Smart PINPad Pro'
      },
      '3in1Reader': {
        Title: '3-in-1 Reader',
      }
    },
    MonthlyPrice: '{{price}}/mo',
    SetupPrice: '+{{price}} setup fee'
  },
  TerminalsDetails: {
    Details: {
      Detail1: 'Dual HD touchscreens.',
      Detail2: 'Accept contactless payments, chip cards, and magstripe cards.',
      Detail3: 'Integrated barcode scanner.',
      Detail4: 'Printed and digital receipts.',
      Detail5: '4G + wifi with offline transactions.',
      Detail6: 'Connects to your smartphone or tablet via Bluetooth.',
      Detail7: 'Digital receipts.',
      Detail8: 'External PIN Pad.',
      Detail9: 'Wifi with offline transactions.'
    }
  },
  DraftNotification: {
    Title: 'Draft Saved',
    Description: 'Find it under Invoices > Drafts.'
  },
  BatchesTable: {
    Date: 'Date',
    BatchNumber: 'Batch #',
    Sales: 'Sales',
    SalesAmount: 'Sales Amount',
    Refunds: 'Refunds',
    RefundsAmount: 'Refunds Amount',
    TotalTransactions: 'Total Transactions',
    GrossAmount: 'Gross Amount',
    NetAmount: 'Net Amount'
  },
  BatchDetailsTable: {
    Date: 'Date',
    Time: 'Time',
    Brand: 'Brand',
    Card: 'Card',
    Type: 'Type',
    Process: 'Process',
    Total: 'Total',
    ProcessType: {
      Sale: 'Sale',
      Refund: 'Refund'
    }
  },
  BatchDetailsSummary: {
    ViewBatchDetails: 'View batch details',
    ExportBatchDetails: 'Export batch details',
    PrintBatchDetails: 'Print batch details',
    CardTypeBreakdown: 'Card Type Breakdown',
    PaymentMethodBreakdown: 'Payment Method Breakdown',
    Visa: 'Visa',
    Mastercard: 'Mastercard',
    AmericanExpress: 'American Express',
    Discover: 'Discover',
    Debit: 'Debit',
    EBT: 'EBT',
    Other: 'Other',
    Swiped: 'Swiped',
    Keyed: 'Keyed',
    Voice: 'Voice',
    Online: 'Online',
    TooManyTransactionsWarning: 'This batch contains too many transactions to display the card type and payment method breakdown summaries. To view those breakdown summaries, please use the Reports section.'
  },
  BulkInvoices: {
    CreateBulkInvoices: 'Create invoices in bulk.',
    DownloadOurTemplate: 'Download our template to prepare your invoice file.',
    DownloadOurTemplateInstructions: 'For each invoice to create, simply add a new row to the spreadsheet.',
    ImportFile: 'Import the invoice file.',
    ImportFileInstructions: 'Use the Import Invoices link on this page to upload your invoice file. Invoices will be created automatically.',
    SendWithOneClick: 'Send with one click.',
    SendWithOneClickInstructions: 'Review imported invoices as drafts. When ready, send in bulk with just one click.',
    BulkInvoicing: 'Bulk invoicing',
    ReviewInvoices: 'Review invoices.',
    DiscardUnsentInvoices: 'Discard Unsent Invoices',
    SendInvoicesButton: 'Send {{total}} Invoices',
    UploadInProgress: 'Upload in Progress.',
    Uploading: 'Uploading.',
    UploadComplete: 'Upload Complete.',
    UploadFailed: 'Upload Failed.',
    MissingHeaderRow: 'Header row is missing.',
    MissingHeaderRowDescription: 'The file you uploaded does not have a header row. Please upload it again once errors are corrected.',
    MissingColumns: 'Missing Columns.',
    MissingColumnsDescription: 'The file you uploaded is missing one or more columns. Please check your file and upload again.',
    BulkInvoicesId: 'Bulk Invoices',
    CreatedAt: 'Created {{date}}',
    InvoicesReadyToSend: 'All {{count}} invoices are ready to send.',
    InvoicesError: 'We detected errors with {{count}} invoices.',
    InvoicesErrorDescription: 'However, you can still send the {{count}} successfully imported invoices.',
    ReadyToSend: 'Ready to send',
    Errors: 'Errors',
    ErrorDescription: 'For easier error fixing, download the file and re-upload it once the errors are corrected. We recommend sending the successfully imported invoices from the “Ready to send” tab first, then uploading the corrected invoices in a separate task.',
    DownloadWithErrors: 'Download Invoices with Errors',
    UploadErrorDescription: 'There was an error processing your file. Please make sure your file is using the provided template. Do not add or remove columns, or change column names. Please upload again once the errors are corrected.',
    ErrorHeaders: {
      'Customer Email Address': 'Customer Email Address',
      'Customer Phone Number': 'Customer Phone Number',
      'Customer First Name': 'Customer First Name',
      'Customer Last Name': 'Customer Last Name',
      'Invoice Name': 'Invoice Name',
      'Invoice Number': 'Invoice Number',
      'Description': 'Description',
      'Payment Amount': 'Payment Amount',
      'Tax Rate': 'Tax Rate',
      'Cash Discounting': 'Cash Discounting',
      'Allow customer to add tip': 'Allow customer to add tip',
      'Frequency': 'Frequency',
      'Send Date': 'Send Date',
      'Last Send Date': 'Last Send Date',
      'Repeat Every': 'Repeat Every',
      'Cadence': 'Cadence',
      'Due Date': 'Due Date',
      'Payment Method': 'Payment Method'
    },
    ConfirmationDialogDescription: 'Once you click send, all invoices will be sent to the customers and this action cannot be undone. Are you sure you want to proceed?',
    ConfirmationDialogSendButton: 'Yes, Send {{total}} invoices',
    ConfirmationDialogNotSendButton: 'No, Do Not Send',
    ConfirmationPageHeader: '{{total}} invoices are being created.',
    ConfirmationPageDescription: 'Invoices with a send date of today will be sent to customers immediately. Invoices with a future send date will be scheduled. When all invoices are created, you can view them in',
    ConfirmationPageDescriptionSecondLine: 'You may click "Done" now to create a new bulk.',
    ConfirmationPageCardInvoices:'Invoices Imported',
    ConfirmationPageCardInvoicesSending: 'Invoices Sending',
    ConfirmationPageCardCustomers: 'New Customers Created',
    ConfirmationPageCardVolume: 'Total Invoice Volume',
    ConfirmationPageCardErrorCount: '{{count}} Invoices were not sent due to errors.',
    ConfirmationPageCardErrorDescription: 'Download the error file below. After correcting the errors, upload it in a separate task.',
    ProgressDialogDescription: 'Please wait while we import your data. You will be able to review imported invoices before sending to customers.',
    ProgressDialogCancelButton: 'Cancel Import',
    DiscardDialogDescription: 'Discard unsent invoices will clear all imported invoices on this page. Are you sure you want to proceed?',
    DiscardDialogOkButton: 'Yes, Discard Unsent Invoices',
    DiscardDialogCancelSendButton: 'No, Do Not Discard',
    BulkInvoicesTooltip: 'Create Bulk Invoices',
    BulkInvoicesTooltipText: 'Bulk Invoices allows you to create and send multiple invoices at once by uploading a file or using our template. If you need to create lots of invoices, this will save you time!',
    Retrieving: 'Retrieving'
  }
};
