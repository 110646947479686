/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import FileInput from '../FileInput';
import Spinner from '../UpdateSpinner';
import { addAccountsToUser } from '../../actions/userActions';
import {
  addMidErrorForUI,
  addMidForUI,
  clearMidMessagesForUI,
  removeMidForUI,
  setOpenAddAccountDialog
} from '../../actions/userExperienceActions.js';
import Loading from '../Loading';
import CustomTextField from '../shared/TextField';
import { Field, FieldArray, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import Validator from '../util/Validator';
import messages from '../../constants/messages';

import MerchantAccountUtil from '../util/MerchantAccountUtil';
import FileUtil from '../util/FileUtil';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Button from '../shared/Button';
import Modal from '../shared/Modal';
import { Trans } from 'react-i18next';

import _ from 'lodash';
import attachFileOptions from '../../constants/attachFileOptions';

const validate = values => {
  return Validator.validateAddAccountForm(values);
};

const styles = {
  regular: {},
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subtitle: {
    margin: '15px 0'
  }
};

export class AddAccountDialog extends Component {

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleAddFiles = this.handleAddFiles.bind(this);

    this.state = {
      open: this.props.userExperience.openAddAccountDialog,
      loading: false,
      mids: [],
      saved: {},
      textFieldFocus: false
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({});
  }

  UNSAFE_componentWillMount() {
    this.setState({});
  }

  handleClose() {
    this.props.dispatch(clearMidMessagesForUI());
    return this.props.dispatch(setOpenAddAccountDialog(false));
  }

  handleAddFiles(files) {

    this.setState({loading: true});

    return Promise.all(files.map((f) => {
      return FileUtil.getCsvData(f.file, ['mid']);
    })).then((results) => {
      let addMidsResults = [];
      results.forEach((result) => {
        let mids = _.uniq(result.csv.map(d => d.length > 0 ? d[0] : null).filter(d => !!((d || null) && (!isNaN(parseFloat(d)) && !isNaN(d - 0)))));
        for (const mid of mids) {
          addMidsResults.push(MerchantAccountUtil.addMidsToState(mid, addMidForUI, () => {
            return reset('addAccountDialogForm');
          }, this));
        }
      });
      this.setState({loading: false});
      return Promise.resolve(addMidsResults);
    }).catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  handleKeyDown(e, cb) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  }

  onAddMid(values) {
    return MerchantAccountUtil.addMidsToState(values.mid, addMidForUI, function () {
      return reset('addAccountDialogForm');
    }, this);
  }

  onRemoveMid(indx) {
    return MerchantAccountUtil.removeMidsFromStateByIndex(indx, removeMidForUI, function () {
      return reset('addAccountDialogForm');
    }, this);
  }

  onSubmit() {


    const { user, userExperience, auth, dispatch } = this.props;

    dispatch(reset('addAccountDialogForm'));

    return dispatch(addAccountsToUser(user, userExperience.addAccountDialog.mids)).then((res) => {
      if (auth.addAccountsError || null) {
        return dispatch(addMidErrorForUI(auth.addAccountsError));
      }
    });
  }

  accountSuccesses() {
    const {auth: {addAccountsData}} = this.props,
      {successes} = (addAccountsData || {});

    return (successes || []).map(success => success.mid);
  }

  accountErrors() {
    const {auth: {addAccountsData}, t} = this.props,
      {errors} = (addAccountsData || {});

    let mappedErrors = {};
    for (const error of (errors || [])) {
      (mappedErrors[error.error] || (mappedErrors[error.error] = [])).push(error.mid);
    }

    return _.transform(mappedErrors, (result, mids, mappedError) => {
      let error = (t(messages.addAccountDialog.errorMap[mappedError]) || t(messages.addAccountDialog.defaultError));
      if (!(_.find(messages.addAccountDialog.doNotAppendMids, (globalErrorsWithoutMids) => globalErrorsWithoutMids === mappedError))) {
        error += mids.join(', ');
      }
      result.push(error);
    }, []);
  }

  handleOnDrop = (acceptedFiles) => {
    let files = [];
    acceptedFiles.forEach(f => {
      files.push({
        name: f.name,
        file: f,
        description: '',
        clearing: ''
      })
    });
    this.handleAddFiles(files);
  };

  render() {

    const {auth, handleSubmit, userExperience: {addAccountDialog: {mids, errors}}, t} = this.props;
    const {isFetching} = auth;

    const midSuccesses = this.accountSuccesses();
    const midErrors = this.accountErrors();

    const infoIconPopover = (
      <div className='infoPopover'>
        <Button
          label={t('Add')}
          className='infoIcon'
          type={'submit'}
        />
      </div>
    );

    const label = LabelUtil.getLabel();
    const labelText = label.text;
    let headerText = t('AddAccountDialog.GetLabelHeader', { labelText }),
      templateDownloadLink = (
        <Link to='/files/ImportMIDsTemplate.csv' target='_blank'>
          <Button
            label={t('AddAccountDialog.Download')}
          />
        </Link>
      );

    const renderFiles = ({fields}) => (
      <div style={{padding: '20px'}} className='importItemsForm addAccountForm'>
        <ol>
          <li className='halfed'>
            <strong>{headerText}</strong>
            <p>{t('AddAccountDialog.GetLabelBody')}</p>
            <div className='templateDownload'>{templateDownloadLink}</div>
          </li>
          <li className='halfed'>
            <strong>{t('AddAccountDialog.UploadMerchantHeader')}</strong>
            <p>{t('AddAccountDialog.UploadMerchantBody')}</p>
            <FileInput
              name='dropzone'
              classNameLabel='fileInputLabel'
              className='fileInput'
              onDropFunction={this.handleOnDrop}
              dropzoneOptions={attachFileOptions.addAccountDropzoneOptions}
            >
              <div className='dropZoneText'>
                {IconUtils.getIcon('FileDownload', '#777')}<br/><br/>
                <div className='download'>
                <Trans i18nKey='AddAccountDialog.FileUpload'>
                  <span className='dialogSubtitle'>Drag files here or </span><span className='underline linkLike'>browse</span>
                </Trans>
                </div>
              </div>

            </FileInput>
          </li>
        </ol>
      </div>
    );

    const addAccountForm = (
      <form
        name={'addAccountDialogForm'}
        onSubmit={handleSubmit(this.onAddMid.bind(this))}
        onKeyDown={(e) => {
          this.handleKeyDown(e, handleSubmit(this.onAddMid.bind(this)));
        }}
      >
        <div className='addAccountDialogForm'>
          {infoIconPopover}
          <Field
            component={CustomTextField}
            className='textField alignBottom'
            hintText={this.state.textFieldFocus ? t('AddAccountDialog.Helper.Option1') : t('AddAccountDialog.MID')}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
            id='0'
            fullWidth={false}
            name='mid'
            tabIndex='1'
            onFocus={() => {
              this.setState({textFieldFocus: true});
            }}
            onBlur={() => {
              this.setState({textFieldFocus: false});
            }}
          />
          <h5 className={'centeredText'}>{t('AddAccountDialog.Or')}</h5>
          <Field name='files' component={renderFiles} />
        </div>
        <br/>
        <br/>
      </form>
    );

    const merchantAccountRows = mids && mids.length > 0 ? mids.map((merchantAccount, i) => {

      return (
        <Chip
          key={i}
          style={styles.chip}
          label={merchantAccount}
          onDelete={this.onRemoveMid.bind(this, i)}
        />
      )
    }) : null;

    const merchantAccountList = (
      <div id='merchantAccountList'>
        {midSuccesses && midSuccesses.length && mids.length > 0 ?
          (<div className='dialogSubtitle'>{t('AddAccountDialog.MidSuccessDialogWithMids')}<br/><br/></div>) :
          (<div className='dialogSubtitle'>{t('AddAccountDialog.MidSuccessDialogWithoutMids')}<br/><br/></div>)}
        <div style={styles.wrapper}>
          {merchantAccountRows}
        </div>
        {!isFetching && errors.global || null ?
          (<div
            className='errorText'>{errors.global !== t(messages.addAccountDialog.errorMap.missingUserData) ? errors.global + mids.join(', ') : errors.global}</div>) : ''}
        {!isFetching && midSuccesses && midSuccesses.length && midSuccesses.length > 0 ?
          (<div className='positive'>{t('AddAccountDialog.SavedAccounts') + midSuccesses.map(mid => mid).join(', ')}</div>) : ''}
        {!isFetching && midErrors && midErrors.length && midErrors.length > 0 ? midErrors.map((midError, i) => {
          return (<div className='errorText' key={i}>{midError}</div>)
        }) : ''}
      </div>
    );

    return (
      <div className='dialog'>
        <Modal
          maxWidth='md'
          title={t('AddAccountDialog.Title')}
          confirmText={t('AddAccountDialog.ConfirmText')}
          cancelText={t('Cancel')}
          open={this.props.userExperience.openAddAccountDialog}
          isConfirmDisabled={mids.length < 1 || auth.isFetching}
          onClose={this.handleClose}
          onConfirm={this.onSubmit.bind(this)}
        >
          {isFetching ? <Loading/> : ''}
          <div className='dialogSubtitle' style={styles.subtitle}>
            {t('AddAccountDialog.DialogSubtitle')}
          </div>
          {merchantAccountList}
          {addAccountForm}
          {(this.state.loading ? <Spinner/> : '')}
        </Modal>
      </div>
    )

  }
}

const AddAccountDialogForm = reduxForm({
  form: 'addAccountDialogForm',
  validate,
  fields: [
    'mid',
    'userMerchantAccounts',
    'isUpdating',
    'updateError',
    'files'],
  enableReinitialize: false,
})(AddAccountDialog);

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      userMerchantAccounts: ((ownProps.user || state.user || {}).data || {}).merchantAccounts
    },
    message: ownProps.message
  }
}

const AddAccountDialogConnection = connect(mapStateToProps)(AddAccountDialogForm);

export default AddAccountDialogConnection;

